"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Proxied_GetMultipleOutput = void 0;
var IpisFileV2_1 = require("../../domain/IpisFileV2");
var zod_1 = require("zod");
var Proxied_GetMultipleOutput;
(function (Proxied_GetMultipleOutput) {
    Proxied_GetMultipleOutput.Schema = zod_1.z.object({
        files: IpisFileV2_1.IpisFileV2.Schema.array(),
    });
})(Proxied_GetMultipleOutput || (Proxied_GetMultipleOutput = {}));
exports.Proxied_GetMultipleOutput = Proxied_GetMultipleOutput;
