"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabContext = void 0;
exports.useTabContext = useTabContext;
var react_1 = require("react");
exports.TabContext = (0, react_1.createContext)({});
function useTabContext() {
    var ctx = (0, react_1.useContext)(exports.TabContext);
    return ctx;
}
