"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var Company;
(function (Company) {
    Company.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string().min(5),
        name: centralized_zod_1.z.string().min(2),
        shorthand: centralized_zod_1.z.string().optional(),
    });
})(Company || (Company = {}));
exports.Company = Company;
