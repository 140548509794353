"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Handyman = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var HandymanProfession_1 = require("./HandymanProfession");
var Handyman;
(function (Handyman) {
    /*
      If we introduce other users this should be moved
    */
    var UserType;
    (function (UserType) {
        UserType["worker"] = "Worker";
        UserType["customer"] = "Customer";
    })(UserType = Handyman.UserType || (Handyman.UserType = {}));
    // Domain
    Handyman.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string().min(5),
        //username: z.string(),
        firstName: centralized_zod_1.z.string(),
        lastName: centralized_zod_1.z.string(),
        email: centralized_zod_1.z.string().email(),
        phone: centralized_zod_1.z.string(),
        city: centralized_zod_1.z.string(),
        street: centralized_zod_1.z.string(),
        country: centralized_zod_1.z.string(),
        postalCode: centralized_zod_1.z.string(),
        userType: centralized_zod_1.z.literal(UserType.worker),
        companyId: centralized_zod_1.z.string(),
        companyName: centralized_zod_1.z.string(),
        companyRole: centralized_zod_1.z.enum(["handyman", "supervisor"]),
        profession: HandymanProfession_1.HandymanProfession.Schema,
        activeAreas: centralized_zod_1.z.string().array(),
        qualifications: centralized_zod_1.z.string().array(),
        permittedToViewPrices: centralized_zod_1.z.boolean(),
        internalDescription: centralized_zod_1.z.string().nullable(),
        onDutyPrioritization: centralized_zod_1.z.enum(["prioritized", "notPrioritized"]),
    });
    Handyman.EditableSchema = Handyman.Schema.pick({
        firstName: true,
        lastName: true,
        phone: true,
        activeAreas: true,
    }).partial();
})(Handyman || (Handyman = {}));
exports.Handyman = Handyman;
