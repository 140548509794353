"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPrimitive = isPrimitive;
exports.isSingularPrimitive = isSingularPrimitive;
function isPrimitive(value) {
    var type = typeof value;
    var allowed = ["string", "number", "boolean"];
    return allowed.includes(type) || value === undefined;
}
function isSingularPrimitive(obj) {
    var t = typeof obj;
    return t === "string" || t === "number" || t === "boolean";
}
