"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisLoadingOverlay = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var IpisLoadingSpinner_1 = require("./IpisLoadingSpinner");
var IpisLoadingOverlay = function (props) {
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { children: props.isVisible && ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "fixed left-0 top-0 z-30 flex h-full w-full items-center justify-center bg-black/20", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, children: (0, jsx_runtime_1.jsx)(IpisLoadingSpinner_1.IpisLoadingSpinner, { className: "h-12 w-12" }) })) }));
};
exports.IpisLoadingOverlay = IpisLoadingOverlay;
