"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormInputDescription = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var client_utils_1 = require("../../../../utils/client-utils");
var RichTextPreTag_1 = require("./RichTextPreTag");
var IpisFormInputDescription = function (props) {
    return ((0, jsx_runtime_1.jsx)(RichTextPreTag_1.RichTextPreTag, { className: (0, client_utils_1.classNames)("whitespace-pre-wrap text-xs text-semi-faded sm:text-sm", props.className), html: props.html }));
};
exports.IpisFormInputDescription = IpisFormInputDescription;
