"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormInputShell = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var client_utils_1 = require("../../../../utils/client-utils");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var IpisFormInputLabel_1 = require("./IpisFormInputLabel");
var InformationTooltip_1 = require("../../../InformationTooltip");
var IpisFormInputDescription_1 = require("./IpisFormInputDescription");
var IpisButton_1 = require("../../../buttons/IpisButton");
var SlideUpModal_1 = require("../../../modals/SlideUpModal");
var RichTextPreTag_1 = require("./RichTextPreTag");
var IpisFormInputNote_1 = require("./IpisFormInputNote");
var IpisFormInputParanthesizedTooltip_1 = require("./IpisFormInputParanthesizedTooltip");
var IpisFormInputError_1 = require("./IpisFormInputError");
var FormInputShell = function (props) {
    var _a;
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var error = (0, form_hooks_1.useFormError)({ form: props.form, name: props.name });
    var _b = (0, react_1.useState)(false), popupOpen = _b[0], setPopupOpen = _b[1];
    var placeChildren = (_a = props.placeChildren) !== null && _a !== void 0 ? _a : "after-description";
    var tooltip = props.tooltip;
    var showTooltipAsInformationIcon = !!tooltip && placeChildren !== "top-right";
    var showTooltipAsAdditionalText = !!tooltip && placeChildren === "top-right";
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { className: (0, client_utils_1.classNames)("relative grid w-full grid-cols-[minmax(0,1fr),auto] gap-2", props.className), onBlur: props.onBlur, onFocus: props.onFocus, children: [!!props.label && ((0, jsx_runtime_1.jsx)(IpisFormInputLabel_1.IpisFormInputLabel, { id: props.id, label: props.label, required: props.required })), !!showTooltipAsInformationIcon && !(ctx === null || ctx === void 0 ? void 0 : ctx.omitTooltip) && ((0, jsx_runtime_1.jsx)(InformationTooltip_1.InformationTooltip, { open: "left", className: "col-start-2", content: tooltip, contentClassName: "w-[200px]" })), placeChildren === "top-right" && props.children, !!props.richTextDescription && !(ctx === null || ctx === void 0 ? void 0 : ctx.omitDescription) && ((0, jsx_runtime_1.jsx)(IpisFormInputDescription_1.IpisFormInputDescription, { className: "col-span-2 w-full", html: props.richTextDescription })), showTooltipAsAdditionalText && !(ctx === null || ctx === void 0 ? void 0 : ctx.omitTooltip) && ((0, jsx_runtime_1.jsx)(IpisFormInputParanthesizedTooltip_1.IpisFormInputParanthesizedTooltip, { html: tooltip })), placeChildren === "after-description" && props.children, !!props.slideUpElement && !(ctx === null || ctx === void 0 ? void 0 : ctx.omitSlideUpElement) && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { onClick: function () { return setPopupOpen(true); }, className: "col-span-2", label: props.slideUpElement.prompt, variant: "tonal", icon: {
                            position: "trailing",
                            type: "information",
                        } }), (0, jsx_runtime_1.jsx)(SlideUpModal_1.SlideUpModal, { isOpen: popupOpen, heading: props.slideUpElement.prompt, onClose: function () { return setPopupOpen(false); }, children: (0, jsx_runtime_1.jsx)(RichTextPreTag_1.RichTextPreTag, { html: props.slideUpElement.content }) })] })), !!props.richTextNote && !(ctx === null || ctx === void 0 ? void 0 : ctx.omitNote) && ((0, jsx_runtime_1.jsx)(IpisFormInputNote_1.IpisFormInputNote, { html: props.richTextNote, className: "col-span-2" })), !!error && !props.hideError && !(ctx === null || ctx === void 0 ? void 0 : ctx.omitError) && ((0, jsx_runtime_1.jsx)(IpisFormInputError_1.IpisFormInputError, { error: error, humanReadableName: props.label || props.name }))] }));
};
exports.FormInputShell = FormInputShell;
