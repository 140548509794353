"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderCategory = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderCategory;
(function (WorkOrderCategory) {
    WorkOrderCategory.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
    });
})(WorkOrderCategory || (WorkOrderCategory = {}));
exports.WorkOrderCategory = WorkOrderCategory;
