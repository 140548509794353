import { useEffect, useRef } from "react";
import { useManipulateBackButtonStack } from "./use-back-button-stack";

type CallbackReturnValue = (() => Promise<boolean>) | false;
type CurriedCallback = () => CallbackReturnValue;
type HookReturnValue = () => Promise<boolean>;

/* 
  This really is a mess
*/
const useBeforeBackButton = (callback: CurriedCallback): HookReturnValue => {
  const { stack } = useManipulateBackButtonStack();
  const returnValueRef = useRef<CallbackReturnValue>(false);
  const removeRef = useRef<() => void>(() => {});

  useEffect(() => {
    const value = callback();
    returnValueRef.current = value;
    let remove = () => {};
    if (value) {
      remove = stack.beforeBack(value);
    }
    removeRef.current = remove;
    return () => {
      remove();
      returnValueRef.current = false;
      removeRef.current = () => {};
    };
  });

  return async () => {
    const value = returnValueRef.current;
    if (value) {
      const callbackValue = await value();
      if (callbackValue) {
        removeRef.current();
        returnValueRef.current = false;
        removeRef.current = () => {};
      }

      return callbackValue;
    }
    return true;
  };
};

export default useBeforeBackButton;
