import { IpisFormElement } from "@eljouren/domain";
import { useChecklistPage } from "../../../../../hooks/checklist-hooks";
import ChecklistDecoupledNumberInputQuestion from "./ChecklistDecoupledNumberInputQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.NumberInputType;
}

const ChecklistNumberInputQuestion = (props: Props) => {
  const ctx = useChecklistPage();
  const q = props.question;

  return <ChecklistDecoupledNumberInputQuestion question={q} form={ctx.form} />;
};

export default ChecklistNumberInputQuestion;
