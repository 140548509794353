"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandymanCompanyInformation = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var Company_1 = require("../company/Company");
var CompanyPermissionLevel_1 = require("../company/CompanyPermissionLevel");
var HandymanCompanyInformation;
(function (HandymanCompanyInformation) {
    HandymanCompanyInformation.Schema = centralized_zod_1.z.object({
        permissionLevel: centralized_zod_1.z.nativeEnum(CompanyPermissionLevel_1.CompanyPermissionLevel.Enum),
        company: Company_1.Company.Schema,
    });
})(HandymanCompanyInformation || (HandymanCompanyInformation = {}));
exports.HandymanCompanyInformation = HandymanCompanyInformation;
