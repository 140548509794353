"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AlertDialog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var keyboard_event_hooks_1 = require("../../../hooks/keyboard-event-hooks");
var framer_motion_1 = require("framer-motion");
var client_utils_1 = require("../../../utils/client-utils");
var IpisButton_1 = require("../../buttons/IpisButton");
var AlertDialog = function (props) {
    var _a, _b, _c;
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    (0, keyboard_event_hooks_1.useOnEnterClick)({
        ref: ref,
        callback: function () {
            if (props.type === "alert") {
                props.close();
            }
        },
    });
    (0, keyboard_event_hooks_1.useOnEscapeClick)(function () {
        if (props.type === "alert") {
            props.close();
        }
    });
    var isConfirm = props.type === "confirm";
    var yesLabel = (isConfirm && props.args.confirmLabel) || "Ja";
    var noLabel = (isConfirm && props.args.rejectLabel) || "Nej";
    var maxButtonLength = Math.max(yesLabel.length, noLabel.length);
    var splitIntoRows = maxButtonLength > 12;
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "fixed z-[70] flex h-full w-full flex-col items-center justify-center bg-black/40 p-2 xs:p-4 sm:p-8", initial: {
            opacity: 0,
        }, animate: { opacity: 1 }, exit: {
            opacity: 0,
        }, role: "alertdialog", "aria-labelledby": "alertTitle", "aria-describedby": "alertPrompt", children: (0, jsx_runtime_1.jsxs)("div", { className: (0, client_utils_1.classNames)("pointer-events-auto flex w-[450px] max-w-full flex-col rounded border bg-light-background p-4 shadow-lg"), role: "document", tabIndex: 0, ref: ref, children: [(0, jsx_runtime_1.jsx)("h2", { id: "alertTitle", className: "break-words text-xl font-bold text-dark-950", children: props.args.title }), (0, jsx_runtime_1.jsx)("p", { id: "alertPrompt", className: "pt-4 text-base text-dark-800", children: props.args.prompt }), props.type === "alert" && ((0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { label: (_a = props.args.buttonLabel) !== null && _a !== void 0 ? _a : "OK", onClick: props.close, className: "ml-auto mt-2 min-w-[100px]" })), props.type === "confirm" && ((0, jsx_runtime_1.jsxs)("span", { className: (0, client_utils_1.classNames)("mt-4 flex flex-col gap-2 sm:mt-8 sm:grid", !splitIntoRows && "sm:grid-cols-2", splitIntoRows && "sm:grid-cols-1"), children: [(0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { label: (_b = props.args.rejectLabel) !== null && _b !== void 0 ? _b : "Nej", variant: "text", onClick: function () {
                                props.answer({ answer: false });
                            } }), (0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { label: (_c = props.args.confirmLabel) !== null && _c !== void 0 ? _c : "Ja", variant: "secondary-on-light-background", onClick: function () {
                                props.answer({ answer: true });
                            } })] }))] }) }));
};
exports.AlertDialog = AlertDialog;
