"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisLoadingSpinner = IpisLoadingSpinner;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var brand_hooks_1 = require("../../hooks/brand-hooks");
var client_utils_1 = require("../../utils/client-utils");
function IpisLoadingSpinner(props) {
    var reducedMotion = (0, framer_motion_1.useReducedMotion)();
    var theme = (0, brand_hooks_1.useExternalBrandTheme)().externalBrandTheme;
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { initial: true, children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { "data-test-id": "app-loader", className: (0, client_utils_1.classNames)("m-auto rounded-full border-4 border-solid border-primary-400", !reducedMotion && "border-b-transparent", props.className, !props.className && "h-12 w-12"), style: theme
                ? {
                    borderColor: theme.accentColor,
                    borderBottomColor: "transparent",
                }
                : undefined, animate: reducedMotion
                ? { opacity: [0.2, 1, 0.2] }
                : { rotate: 360, opacity: 1 }, transition: {
                repeat: Infinity,
                duration: reducedMotion ? 3 : 1.5,
                ease: "linear",
            } }) }));
}
