import { IpisFormElement } from "@eljouren/domain";
import { IpisTextInput, IpisTextInputCompact } from "@ipis/client-essentials";
import { FormInputShellPublicProps } from "@ipis/client-essentials/build/components/form/form-inputs/components/FormInputShell";
import { motion } from "framer-motion";
import { UseFormReturn } from "react-hook-form";
import { useChecklistRenderOptions } from "../../../../../hooks/checklist-hooks";
import ClientUtils from "../../../../../utils/ClientUtils";

interface Props {
  className?: string;
  question: IpisFormElement.TextInputType;
  form: UseFormReturn<any>;
  disabled?: boolean;
  inputProps?: Pick<FormInputShellPublicProps<any>, "onBlur" | "onFocus">;
}

const ChecklistDecoupledTextInputQuestion = (props: Props) => {
  const renderCtx = useChecklistRenderOptions();

  const q = props.question;
  return (
    <motion.div className={ClientUtils.classNames(props.className)}>
      {renderCtx?.useCompactLayout && (
        <IpisTextInputCompact
          id={q.id}
          name={`${q.id}.value`}
          required={q.required}
          label={q.title}
          form={props.form}
          disabled={props.disabled}
          minLength={q.minLength}
          maxLength={q.maxLength}
          placeholder={q.placeholder}
          onBlur={props.inputProps?.onBlur}
          onFocus={props.inputProps?.onFocus}
        />
      )}
      {!renderCtx?.useCompactLayout && (
        <IpisTextInput
          id={q.id}
          name={`${q.id}.value`}
          label={q.title}
          required={q.required}
          tooltip={q.tooltip}
          richTextDescription={q.description}
          richTextNote={q.note}
          form={props.form}
          disabled={props.disabled}
          minLength={q.minLength}
          maxLength={q.maxLength}
          placeholder={q.placeholder}
          onBlur={props.inputProps?.onBlur}
          onFocus={props.inputProps?.onFocus}
        />
      )}
    </motion.div>
  );
};

export default ChecklistDecoupledTextInputQuestion;
