import { IpisFormElement } from "@eljouren/domain";
import { useChecklistPage } from "../../../../../hooks/checklist-hooks";
import ChecklistDecoupledImageGroupQuestion from "./ChecklistDecoupledImageGroupQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.ImageGroupType;
}

const ChecklistImageGroupQuestion = (props: Props) => {
  const ctx = useChecklistPage();
  const form = ctx.form;

  return (
    <ChecklistDecoupledImageGroupQuestion
      form={form}
      question={props.question}
    />
  );
};

export default ChecklistImageGroupQuestion;
