"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var tab_context_1 = require("../../contexts/tab-context");
var keyboard_event_hooks_1 = require("../../hooks/keyboard-event-hooks");
var client_utils_1 = require("../../utils/client-utils");
var TabList = function (props) {
    var keyboardOpen = (0, keyboard_event_hooks_1.useDetectKeyboardOpen)();
    var ctx = (0, tab_context_1.useTabContext)();
    if (ctx.withoutControls) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    if (!ctx.showTabListOnKeyboardOpen && keyboardOpen) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {});
    }
    return ((0, jsx_runtime_1.jsxs)("div", { role: "tablist", className: (0, client_utils_1.classNames)("grid bg-white lg:border-t-0", props.className), style: {
            gridTemplateColumns: "repeat(".concat(ctx.tabs.length, ",1fr)"),
        }, children: [ctx.tabs.map(function (tab) { return ((0, jsx_runtime_1.jsxs)("button", { className: (0, client_utils_1.classNames)("flex grow flex-col items-center border-t-2 p-1 py-2 font-bold transition-all last:border-r-0 lg:flex-row lg:justify-center lg:gap-2 lg:border-b-2 lg:border-t-0 lg:p-2", "hover:border-tab-border-selected hover:text-black", ctx.selectedTab === tab && "border-tab-border-selected text-black", ctx.selectedTab !== tab &&
                    "border-tab-border-not-selected text-black/60"), onClick: function () { return ctx.onTabClick(tab); }, "aria-selected": ctx.selectedTab === tab, role: "tab", children: [ctx.icon && ctx.icon(tab), tab] }, tab)); }), props.children] }));
};
exports.default = TabList;
