"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisMainContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var client_utils_1 = require("../../utils/client-utils");
var IpisMainContent = function (props) {
    var variants = {
        hidden: { opacity: 0 },
        enter: { opacity: 1 },
        exit: { opacity: 0 },
    };
    return (
    //<AnimatePresence exitBeforeEnter initial={false}>
    (0, jsx_runtime_1.jsx)(framer_motion_1.motion.main, { className: (0, client_utils_1.classNames)("flex h-full flex-col overflow-y-auto", props.className), variants: variants, children: props.children })
    //</AnimatePresence>
    );
};
exports.IpisMainContent = IpisMainContent;
