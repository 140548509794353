import { WorkOrderLineItem } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect, useRef, useState } from "react";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import HandymanWorkOrderRouteContext from "../../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "../../../../../../utils/ClientUtils";
import { AppButton } from "../../../../../common/buttons/AppButton";
import Dropdown from "../../../../../common/dropdown/Dropdown";
import AppMinusIcon from "../../../../../icons/AppMinusIcon";
import AppPlusIcon from "../../../../../icons/AppPlusIcon";
import AppTrashIcon from "../../../../../icons/AppTrashIcon";
import { HandymanWorkOrderFileContext } from "../../../../files/DecoupledWorkOrderFileContextProvider";
import IpisFileDataList from "../../../files/IpisFileDataList";
import HandymanLineItemContext from "../../../line-items/HandymanLineItemContext";
import LineItemApprovalStateComponent from "./LineItemApprovalStateComponent";
import PriceCalculationComponent from "../../../../../PriceCalculationComponent";

const FormSchema = z.record(WorkOrderLineItem.HandymanSchema);

type FormValues = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  data: WorkOrderLineItem.HandymanType[];
  isLoading: boolean;
  onClose(): void;
  viewedByCustomer?: boolean;
}

function getDefaultValues(data: WorkOrderLineItem.HandymanType[]): FormValues {
  const res = Object.fromEntries(
    data
      .filter((el) => el.permissions.canEditQuantity)
      .map((el) => {
        return [el.id, el];
      })
  );
  return res;
}
const HandymanExpandedLineItemListSection = (props: Props) => {
  const lineItemCtx = useContext(HandymanLineItemContext);

  const quantityForm = useForm<FormValues>({
    defaultValues: getDefaultValues(props.data),
    resolver: zodResolver(FormSchema),
  });

  const [resetAfterSave, setResetAfterSave] = useState(false);
  const isLoading = lineItemCtx.lineItemRes.isLoading;
  const loadingStateConsumedRef = useRef(false);

  useEffect(() => {
    if (isLoading && resetAfterSave) {
      loadingStateConsumedRef.current = true;
    } else if (
      !isLoading &&
      loadingStateConsumedRef.current &&
      resetAfterSave
    ) {
      loadingStateConsumedRef.current = false;
      setResetAfterSave(false);
      quantityForm.reset(getDefaultValues(props.data));
    }
  }, [resetAfterSave, setResetAfterSave, isLoading, quantityForm, props.data]);

  async function onClose() {
    const isDirty = quantityForm.formState.isDirty;
    if (isDirty && !resetAfterSave) {
      const res = await window.modal.confirm({
        title: "Du har osparade ändringar",
        prompt: "Vill du spara ändringarna innan du går tillbaka?",
        yesLabel: "Spara ändringar",
        noLabel: "Gå tillbaka utan att spara",
      });

      if (res) {
        onSave();
      }
    }
    // Check if the form is dirty
    props.onClose();
  }

  function onSave() {
    const isDirty = quantityForm.formState.isDirty;
    if (!isDirty) {
      return;
    }
    const values = quantityForm.getValues();
    const arr = Object.values(values);
    const modifiedLineItems = arr.filter((el) => {
      return (
        el.quantity !== props.data.find((el2) => el2.id === el.id)?.quantity
      );
    });
    lineItemCtx.updateLineItemQuantities({
      lineItems: modifiedLineItems,
    });
    setResetAfterSave(true);
  }

  const canEdit = props.data.some(
    (el) =>
      el.permissions.canEditQuantity ||
      el.permissions.canBeCanceled ||
      el.permissions.canBeRemoved
  );

  return (
    <section className="grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto] gap-2">
      <header>
        <h3>Redigera orderrader</h3>
      </header>
      <main className="h-full overflow-y-auto">
        <ul
          className={ClientUtils.twClassNames(
            "flex w-full flex-col gap-2",
            props.isLoading && "cursor-wait"
          )}
        >
          {props.data.map((lineItem) => {
            return (
              <ExpandedListItem
                key={lineItem.id}
                lineItem={lineItem}
                viewedByCustomer={props.viewedByCustomer}
                form={quantityForm}
                isLoading={props.isLoading}
              />
            );
          })}
        </ul>
      </main>
      <footer className="flex flex-col gap-2">
        {canEdit && (
          <AppButton
            disabled={!quantityForm.formState.isDirty}
            onClick={onSave}
          >
            Spara ändringar
          </AppButton>
        )}
        <AppButton onClick={onClose}>Stäng fönster</AppButton>
        {/* 
        The calculator needs to have support for customer prices
      */}
        {/*   {canViewPrices && !props.useCustomerPrice && (
        <span className="ml-auto flex flex-col p-2 text-right text-sm">
          <span className="">
            Total ersättning för{" "}
            {props.type === "hours" ? "arbetstimmar" : "material"}
          </span>
          <span className="font-bold">
            {props.type === "material" ? calc.forMaterial : calc.forHours}:-
          </span>
        </span>
      )} */}
      </footer>
    </section>
  );
};

type ListItemProps = Pick<Props, "viewedByCustomer"> & {
  lineItem: WorkOrderLineItem.HandymanType;
  form: UseFormReturn<FormValues>;
  isLoading: boolean;
};

const ExpandedListItem = (props: ListItemProps) => {
  const lineItemCtx = useContext(HandymanLineItemContext);
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const order = ctx.order;

  const lineItem = props.lineItem;
  const { customerPriceDetails, quantity, compensationDetails } = lineItem;

  let hasUnitPrice: boolean;
  if (props.viewedByCustomer) {
    hasUnitPrice = customerPriceDetails.hasCustomerPrice;
  } else {
    hasUnitPrice = compensationDetails.showCompensation;
  }

  const canTrashOrCancel =
    lineItem.permissions.canBeCanceled || lineItem.permissions.canBeRemoved;

  return (
    <li
      className={ClientUtils.classNames(
        "relative flex flex-col rounded border bg-off-white/80 p-2 shadow",
        props.isLoading && "skeleton",
        lineItem.isInactive && "pointer-events-none opacity-50"
      )}
      key={lineItem.id}
      data-test-id="expanded-line-item"
      data-added-material-source={
        lineItem.addedByHandyman ? "handyman" : "staff"
      }
      data-quantity={quantity}
    >
      {lineItem.isInactive && (
        /* 
          ToDo: Add accessibility attributes
        */
        <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/40 text-sm font-bold text-black">
          Inaktiverat
        </div>
      )}
      <header
        className={ClientUtils.classNames(
          "grid items-center",
          canTrashOrCancel && "grid-cols-[minmax(0,1fr),auto]",
          !order.requiresMaterialToBeReviewed && "gap-2"
        )}
      >
        <h3 className="mt-auto text-sm font-normal">{lineItem.name} </h3>
        <LineItemApprovalStateComponent lineItem={lineItem} className="mb-2" />
        {canTrashOrCancel && (
          <button
            aria-label="Ta bort orderrad"
            className={ClientUtils.classNames(
              "col-start-2 row-span-2 row-start-1 mb-auto ml-auto text-red-700"
            )}
            onClick={() => lineItemCtx.deleteOrInactivateLineItem(lineItem)}
          >
            <AppTrashIcon />
          </button>
        )}
      </header>
      <main className={ClientUtils.classNames("")}>
        {hasUnitPrice && (
          <PriceComponent
            viewedByCustomer={props.viewedByCustomer}
            lineItem={lineItem}
          />
        )}
      </main>
      {(lineItem.permissions.canEditQuantity || !lineItem.isInactive) && (
        <footer className="flex flex-col py-2">
          {lineItem.permissions.canEditQuantity && (
            <div className="flex items-center gap-2">
              <Controller
                name={lineItem.id}
                control={props.form.control}
                render={(fieldProps) => {
                  const step = lineItem.unit === "h" ? 0.5 : 1;
                  const currentQuantity =
                    fieldProps.field.value?.quantity ?? step;

                  return (
                    <>
                      <button
                        disabled={currentQuantity <= step}
                        className="disabled:opacity-50"
                        onClick={() => {
                          const value = currentQuantity - 1;
                          fieldProps.field.onChange({
                            ...fieldProps.field.value,
                            quantity: value,
                          });
                        }}
                      >
                        <AppMinusIcon size="lg" variant="circle" />
                      </button>
                      <input
                        type="number"
                        className="w-20 rounded p-1"
                        min={step}
                        step={step}
                        value={currentQuantity}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          fieldProps.field.onChange({
                            ...fieldProps.field.value,
                            quantity: value,
                          });
                        }}
                      />
                      <button
                        onClick={() => {
                          const value = currentQuantity + 1;
                          fieldProps.field.onChange({
                            ...fieldProps.field.value,
                            quantity: value,
                          });
                        }}
                      >
                        <AppPlusIcon size="lg" variant="circle" />
                      </button>
                    </>
                  );
                }}
              />
            </div>
          )}
        </footer>
      )}
      <RelatedFiles lineItem={lineItem} className="col-span-2" />
    </li>
  );
};

const PriceComponent = (props: {
  className?: string;
  viewedByCustomer?: boolean;
  lineItem: WorkOrderLineItem.HandymanType;
}) => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const quantity = props.lineItem.quantity;
  const unit = props.lineItem.unit;

  const customerPriceDetails = props.lineItem.customerPriceDetails;
  const compensationDetails = props.lineItem.compensationDetails;
  const allowedToSeeCompensation = compensationDetails.showCompensation;
  const enableCustomerPrices = ctx.enableMaterialReview;

  const customerUnitPrice =
    customerPriceDetails.unitPriceIncludingVatWithReduction;
  const compensationUnitPrice = compensationDetails.compensationPerUnit;
  const showPrices = allowedToSeeCompensation || enableCustomerPrices;

  /* 
    This might need to take into account if the line item is material or hours
  */
  const showCompensation = allowedToSeeCompensation && !props.viewedByCustomer;
  const showCustomerPrice = enableCustomerPrices;
  const showLabels = !props.viewedByCustomer;

  if (!showPrices) {
    return (
      <span className="text-sm italic">
        {quantity}
        {unit}
      </span>
    );
  }

  return (
    <span className="flex flex-col">
      {showCompensation && (
        <PriceCalculationComponent
          quantity={quantity}
          price={compensationUnitPrice ?? 0}
          label={showLabels ? "Ersättning" : undefined}
        />
      )}
      {showCustomerPrice && (
        <PriceCalculationComponent
          quantity={quantity}
          price={customerUnitPrice ?? 0}
          label={showLabels ? "Kundpris" : undefined}
        />
      )}
    </span>
  );
};

const RelatedFiles = (props: {
  className?: string;
  lineItem: WorkOrderLineItem.HandymanType;
}) => {
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  const relatedFiles = fileCtx.allFiles.filter((file) => {
    return !!file.relations?.find((relation) => {
      const type = relation.type;
      if (type !== "workOrderLineItem") {
        return false;
      }

      return relation.lineItemId === props.lineItem.id;
    });
  });

  if (relatedFiles.length === 0) {
    return <></>;
  }

  return (
    <Dropdown
      title="Visa relaterade filer"
      className="my-2"
      buttonClassName="bg-transparent border-b w-60 p-1"
      preventHideOnOutsideClick
    >
      <IpisFileDataList
        onDelete={fileCtx.deleteFile}
        className="p-4"
        data={relatedFiles}
        filePermissionCallback={fileCtx.permissions.forFile}
      />
    </Dropdown>
  );
};

export default HandymanExpandedLineItemListSection;
