import {
  IpisPage,
  IpisPageProps,
  PageHeaderPortalProps,
} from "@ipis/client-essentials";

type Props = Omit<IpisPageProps, "headerProps"> & {
  headerProps: Omit<PageHeaderPortalProps, "logo">;
};

const IpisPageWithLogo = (props: Props) => {
  const headerProps: PageHeaderPortalProps = {
    ...props.headerProps,
    logo: {
      lightUrl: "/ipis-platform/logos/ipis_icon_light.svg",
      darkUrl: "/ipis-platform/logos/ipis_icon_dark.svg",
    },
  };

  return <IpisPage {...props} headerProps={headerProps} />;
};

export default IpisPageWithLogo;
