"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Proxied_DataUrlUploadInput = void 0;
var zod_1 = require("zod");
var SignUrl_UploadInput_1 = require("./SignUrl_UploadInput");
var Proxied_DataUrlUploadInput;
(function (Proxied_DataUrlUploadInput) {
    Proxied_DataUrlUploadInput.Schema = zod_1.z
        .object({
        dataUrl: zod_1.z.string(),
        name: zod_1.z.string().max(80).optional(),
        description: zod_1.z.string().max(250).optional(),
        clientId: zod_1.z.string().optional(),
    })
        .merge(SignUrl_UploadInput_1.SignUrl_UploadInput.Schema);
})(Proxied_DataUrlUploadInput || (Proxied_DataUrlUploadInput = {}));
exports.Proxied_DataUrlUploadInput = Proxied_DataUrlUploadInput;
