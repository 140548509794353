import { IpisForm, IpisFormElement, IpisFormPage } from "@eljouren/domain";
import { UUID } from "@eljouren/utils";

interface Props {
  currentForms: IpisForm.ShellType[] | undefined;
  target: IpisForm.ShellType;
}

export default class IpisFormCopier {
  private oldToNewIdMap: Map<string, string> = new Map();

  constructor(private props: Props) {
    this.validateInvariants();
  }

  private getMappedId(id: string) {
    const newId = this.oldToNewIdMap.get(id);
    if (!newId) {
      console.warn(`No new id found for ${id}`);
    }
    return newId ?? id;
  }

  private validateInvariants() {
    const allElements = this.props.target.pages.flatMap((p) => p.elements);
    const invalidTypes = allElements.some((e) => {
      return (
        e.typeOfQuestion === "rich-text" || e.typeOfQuestion === "repeater"
      );
    });

    if (invalidTypes) {
      throw new Error(
        "Rich text and repeater elements are only supported for internal forms and or not intended to be copied."
      );
    }
  }

  private findUniqueFormName(current: string) {
    const existingNames = this.props.currentForms?.map((f) => f.name) ?? [];
    let name = current;
    let i = 1;
    while (existingNames.includes(name)) {
      name = `${current} (${i})`;
      i++;
    }
    return name;
  }

  private copyElement(element: IpisFormElement.Type): IpisFormElement.Type {
    const newElementId = UUID.generate().value;
    this.oldToNewIdMap.set(element.id, newElementId);
    const newElement: IpisFormElement.Type = {
      ...element,
      id: newElementId,
      clientSideId: newElementId,
      conditionGroups: element.conditionGroups?.map((cg) => {
        const newConditionGroupId = UUID.generate().value;
        this.oldToNewIdMap.set(cg.id, newConditionGroupId);
        return {
          ...cg,
          id: newConditionGroupId,
          clientSideId: newConditionGroupId,
          conditions: cg.conditions.map((c) => {
            const newConditionId = UUID.generate().value;
            return {
              ...c,
              id: newConditionId,
              clientSideId: newConditionId,
            };
          }),
        };
      }),
    };

    if (newElement.typeOfQuestion === "multiple-choice") {
      // This will always be true, just for TypeScript
      if (element.typeOfQuestion === "multiple-choice") {
        newElement.options = element.options.map((o) => {
          const newOptionId = UUID.generate().value;
          this.oldToNewIdMap.set(o.id, newOptionId);
          return {
            ...o,
            id: newOptionId,
            clientSideId: newOptionId,
          };
        });
      }
    }

    if (newElement.typeOfQuestion === "image-group") {
      // This will always be true, just for TypeScript
      if (element.typeOfQuestion === "image-group") {
        newElement.imagePrompts = element.imagePrompts.map((i) => {
          const newPromptId = UUID.generate().value;
          this.oldToNewIdMap.set(i.id, newPromptId);
          return {
            ...i,
            id: newPromptId,
            clientSideId: newPromptId,
          };
        });
      }
    }

    return newElement;
  }

  private copyPage(
    page: IpisFormPage.WithoutAnswersType
  ): IpisFormPage.WithoutAnswersType {
    const newPageId = UUID.generate().value;
    this.oldToNewIdMap.set(page.id, newPageId);
    return {
      ...page,
      id: newPageId,
      clientSideId: newPageId,
      elements: page.elements.map((e) => this.copyElement(e)),
    };
  }

  private fixConditionReferences(form: IpisForm.ShellType): IpisForm.ShellType {
    const newForm = { ...form };
    // This will actually modify the input as well since it's not a deep copy
    // Doesn't really matter though
    const conditions = newForm.pages.flatMap((p) =>
      p.elements.flatMap((e) =>
        (e.conditionGroups ?? []).flatMap((cg) => cg.conditions)
      )
    );
    conditions.forEach((c) => {
      c.reference = this.getMappedId(c.reference);
      if (c.typeOfQuestion === "multiple-choice") {
        c.value = this.getMappedId(c.value);
      }
    });

    return newForm;
  }

  copy(): IpisForm.ShellType {
    const form = this.props.target;
    const name = this.findUniqueFormName(this.props.target.name);
    const newFormId = UUID.generate().value;
    this.oldToNewIdMap.set(this.props.target.id, newFormId);

    const newForm: IpisForm.ShellType = {
      id: newFormId,
      clientSideId: newFormId,
      name,
      type: form.type,
      hasAnswers: false,
      isInactive: false,
      pages: form.pages.map((p) => this.copyPage(p)),
    };

    const withFixedConditions = this.fixConditionReferences(newForm);

    return withFixedConditions;
  }
}
