import { TWorkerAssociation } from "./_model/repos/interfaces/IHandymanRepo";

const AppRoutes = {
  root: () => "/",
  signUp: {
    root: (signUpId?: string) => `/anslut/${signUpId ? signUpId : ":signUpId"}`,
  },
  resetPassword: {
    root: (signUpId?: string) =>
      `/aterstall/${signUpId ? signUpId : ":signUpId"}`,
  },
  customer: {
    v2: {
      confirmationFlow: (workOrderGuid?: string) =>
        `/kundv2/${workOrderGuid ? workOrderGuid : ":workOrderGuid"}`,
      preparationsFlow: (workOrderGuid?: string) =>
        /* 
          The formId should at a later stage be taken from the work order.
        */
        `/partnerkund/${
          workOrderGuid ? workOrderGuid : ":workOrderGuid"
        }/forberedelser`,
      brandedPreparationsFlow: (workOrderGuid?: string) =>
        `/partnerkund/:externalBrand/${
          workOrderGuid ? workOrderGuid : ":workOrderGuid"
        }/forberedelser`,
    },
    root: (workOrderGuid?: string) =>
      `/kund/${workOrderGuid ? workOrderGuid : ":workOrderGuid"}`,

    workOrderPayment: (args?: { workOrderGuid: string }) => {
      if (args) {
        return `/kund/${args.workOrderGuid}/betalning`;
      }
      return `/kund/:workOrderGuid/betalning`;
    },
  },
  externalCustomer: {
    v2: {
      orderLineReview: (workOrderGuid?: string) =>
        `/partnerkundv2/${
          workOrderGuid ? workOrderGuid : ":workOrderGuid"
        }/orderrader`,
      brandedOrderLineReview: (workOrderGuid?: string) =>
        `/partnerkundv2/:externalBrand/${
          workOrderGuid ? workOrderGuid : ":workOrderGuid"
        }/orderrader`,
    },
    orderLineReviewList: (args?: { workOrderGuid: string }) => {
      if (args) {
        return `/partnerkund/${args.workOrderGuid}/orderrader`;
      }
      return `/partnerkund/:workOrderGuid/orderrader`;
    },
  },
  partner: {
    root: (args: { userId?: string } = {}) =>
      args.userId ? `/partner/?id=${args.userId}` : "/partner",
    profile: () => "/partner/profil",
    customerOrder: (workerGuid?: string) =>
      `/partner/arbetsorder/${workerGuid ? workerGuid : ":workerGuid"}`,
    company: () => "/partner/foretag",
  },
  salesTeam: {
    customerOrder: (workerGuid?: string) =>
      `/salj/arbetsorder/${workerGuid ? workerGuid : ":salesGuid"}`,
    partnerList: () => `/salj/partners`,
    partnerListWithAssociation: (type?: TWorkerAssociation) =>
      `/salj/partners/${type || ":workerAssociation"}`,
    singlePartner: (id?: string) => `/salj/partner/${id || ":workerId"}`,
    bookingSuggestions: () => `/salj/bokningsforslag`,
  },
  staff: {
    root: (guid?: string) => `/personal/${guid ? guid : ":staffGuid"}`,
    test: (guid?: string) => `/personal/${guid ? guid : ":staffGuid"}/test`,
    formBuilder: (guid?: string) =>
      `/personal/${guid ? guid : ":staffGuid"}/formbuilder`,
  },
  admin: {
    errorLogs: () => `/admin/errors`,
  },
  test: {
    checklistBuilder: () => "/test/checklist-builder",
    navigation: () => "/test/navigation",
  },
};

export default AppRoutes;
