import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { FilePermissions } from "../../../../types/file-permissions";
import ClientUtils from "./../../../../utils/ClientUtils";

import { AppButton } from "../../../common/buttons/AppButton";
import AppTrashIcon from "../../../icons/AppTrashIcon";

interface Props {
  className?: string;
  file: IpisFileV2.Type;
  onRemove: (file: IpisFileV2.Type) => void;
  permissions: FilePermissions;
}

const ConditionalFileRemovalButton = (props: Props) => {
  const canBeRemoved = props.permissions.forFile(props.file).canDelete;

  function onRemove() {
    props.onRemove(props.file);
  }

  if (!canBeRemoved) {
    return <></>;
  }

  return (
    <AppButton
      contentClassName={ClientUtils.twClassNames(
        "flex gap-2 px-4 py-2",
        props.className
      )}
      onClick={onRemove}
      variant="fill-error"
    >
      <AppTrashIcon size={20} />
      <span>Ta bort fil</span>
    </AppButton>
  );
};

export default ConditionalFileRemovalButton;
