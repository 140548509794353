import { IconUserStar } from "@tabler/icons-react";
import { useContext } from "react";
import CustomerFormScheduledOnComponent from "../../components/CustomerFormScheduledOnComponent";
import CustomerFormSchedulingAndHandymanWrapper from "../../components/CustomerFormSchedulingAndHandymanWrapper";
import { CustomerWorkOrderFormContext } from "../CustomerWorkOrderFormSectionV2";

interface Props {
  className?: string;
}

const CustomerWorkOrderInformationPageSchedulingAndHandymanSection = (
  props: Props
) => {
  const ctx = useContext(CustomerWorkOrderFormContext);
  const wo = ctx.workOrder;

  return (
    <section className="flex flex-col gap-2">
      <CustomerFormScheduledOnComponent workOrder={wo} />
      {!!wo.assignedTo && (
        <CustomerFormSchedulingAndHandymanWrapper ariaLabelledBy="yourHandyman">
          <IconUserStar size="36" className="row-span-2" />
          <span
            id="yourHandyman"
            className="text-sm font-semibold text-dark-950"
          >
            Din hantverkare
          </span>
          <p className="text-sm text-dark-500">{wo.assignedTo.handymanName}</p>
        </CustomerFormSchedulingAndHandymanWrapper>
      )}
    </section>
  );
};

export default CustomerWorkOrderInformationPageSchedulingAndHandymanSection;
