import VanIcon from "../../../../components/icons/VanIcon";
import ClientUtils from "./../../../../utils/ClientUtils";
import { BookingSuggestions } from "@eljouren/domain";

interface Props {
  className?: string;
  travelInfo: BookingSuggestions.TravelInformationType | null;
}

const BookingSuggestionDistanceAndDurationIndicator = (props: Props) => {
  return (
    <div
      className={ClientUtils.twClassNames(
        "relative mb-auto flex h-1/2 w-[1px] flex-col bg-black text-xs",
        props.className
      )}
    >
      <span className="mt-auto flex h-20 w-20 -translate-x-1/2 translate-y-1/2 items-center justify-center rounded border bg-brand-blue-100">
        <VanIcon
          size={20}
          className={ClientUtils.twClassNames(
            !props.travelInfo && "opacity-50"
          )}
        />
      </span>
      <span className="absolute bottom-full flex -translate-x-1/2 flex-col whitespace-nowrap">
        {!!props.travelInfo && (
          <>
            <span>{props.travelInfo.distance.text}</span>
            <span>{props.travelInfo.duration.text}</span>
          </>
        )}
        {!props.travelInfo && (
          <>
            <span className="text-dark-gray text-opacity-80">
              Kan ej avgöra restid
            </span>
          </>
        )}
      </span>
    </div>
  );
};

export default BookingSuggestionDistanceAndDurationIndicator;
