"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderLineItem = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderLineItem;
(function (WorkOrderLineItem) {
    /*   const Test = z.any();
    type Test = z.infer<typeof Test>; */
    var ApprovalStateEnum = centralized_zod_1.z.enum([
        "notApproved",
        "pending",
        "approved",
        "approvedAndCanceled",
        "addedBeforeApprovalRequirements",
    ]);
    /*
      "Labor" would be a better name for "workingHour"
    */
    WorkOrderLineItem.TypeEnum = centralized_zod_1.z.enum(["material", "workingHour"]);
    8;
    WorkOrderLineItem.CustomerPriceDetailsSchema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            hasCustomerPrice: centralized_zod_1.z.literal(true),
            unitPriceExcludingVatWithoutReduction: centralized_zod_1.z.number(),
            unitPriceIncludingVatWithoutReduction: centralized_zod_1.z.number(),
            validForTaxReduction: centralized_zod_1.z.boolean(),
            unitPriceIncludingVatWithReduction: centralized_zod_1.z.number(),
            taxReductionType: centralized_zod_1.z.enum(["rot", "green"]).nullable(),
            vatPercentageDecimal: centralized_zod_1.z.number(),
            vatAmount: centralized_zod_1.z.number(),
        }),
        centralized_zod_1.z.object({
            hasCustomerPrice: centralized_zod_1.z.literal(false),
            unitPriceExcludingVatWithoutReduction: centralized_zod_1.z.literal(undefined).optional(),
            unitPriceIncludingVatWithoutReduction: centralized_zod_1.z.literal(undefined).optional(),
            validForTaxReduction: centralized_zod_1.z.literal(undefined).optional(),
            unitPriceIncludingVatWithReduction: centralized_zod_1.z.literal(undefined).optional(),
            taxReductionType: centralized_zod_1.z.literal(undefined).optional(),
            vatPercentageDecimal: centralized_zod_1.z.literal(undefined).optional(),
            vatAmount: centralized_zod_1.z.literal(undefined).optional(),
        }),
    ]);
    WorkOrderLineItem.HandymanCompensationDetails = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            showCompensation: centralized_zod_1.z.literal(true),
            compensationPerUnit: centralized_zod_1.z.number(),
        }),
        centralized_zod_1.z.object({
            showCompensation: centralized_zod_1.z.literal(false),
            compensationPerUnit: centralized_zod_1.z.literal(undefined).optional(),
        }),
    ]);
    WorkOrderLineItem.PermissionsSchema = centralized_zod_1.z.object({
        canBeRemoved: centralized_zod_1.z.boolean(),
        canBeCanceled: centralized_zod_1.z.boolean(),
        canEditQuantity: centralized_zod_1.z.boolean(),
    });
    WorkOrderLineItem.CustomerBaseSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        quantity: centralized_zod_1.z.number().refine(function (val) { return val > 0; }),
        createdDate: centralized_zod_1.z.date(),
        unit: centralized_zod_1.z.string(),
        approvalState: ApprovalStateEnum,
        lastModifiedDate: centralized_zod_1.z.date(),
        isInactive: centralized_zod_1.z.boolean(),
        type: WorkOrderLineItem.TypeEnum,
        customerPriceDetails: WorkOrderLineItem.CustomerPriceDetailsSchema,
        createdFor: centralized_zod_1.z.literal("customer"),
        addedByHandyman: centralized_zod_1.z.boolean(),
    });
    WorkOrderLineItem.HandymanBaseSchema = WorkOrderLineItem.CustomerBaseSchema.extend({
        createdFor: centralized_zod_1.z.literal("handyman"),
        compensationDetails: WorkOrderLineItem.HandymanCompensationDetails,
        linkedMaterialApprovalImages: centralized_zod_1.z
            .object({
            id: centralized_zod_1.z.string(),
            url: centralized_zod_1.z.string(),
        })
            .array(),
        permissions: WorkOrderLineItem.PermissionsSchema,
    });
    var CustomProductExtension = centralized_zod_1.z.object({
        isCustom: centralized_zod_1.z.literal(true),
        productId: centralized_zod_1.z.literal(undefined).optional(),
    });
    var NonCustomProductExtension = centralized_zod_1.z.object({
        isCustom: centralized_zod_1.z.literal(false),
        productId: centralized_zod_1.z.string(),
    });
    WorkOrderLineItem.HandymanSchema = centralized_zod_1.z.union([
        WorkOrderLineItem.HandymanBaseSchema.merge(NonCustomProductExtension),
        WorkOrderLineItem.HandymanBaseSchema.merge(CustomProductExtension),
    ]);
    WorkOrderLineItem.CustomerSchema = centralized_zod_1.z.union([
        WorkOrderLineItem.CustomerBaseSchema.merge(NonCustomProductExtension),
        WorkOrderLineItem.CustomerBaseSchema.merge(CustomProductExtension),
    ]);
    WorkOrderLineItem.NewEntrySchema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            productId: centralized_zod_1.z.string(),
            quantity: centralized_zod_1.z.number(),
            workOrderId: centralized_zod_1.z.string(),
            subject: centralized_zod_1.z.literal(undefined).optional(),
            unitPrice: centralized_zod_1.z.literal(undefined).optional(),
        }),
        centralized_zod_1.z.object({
            productId: centralized_zod_1.z.literal(undefined).optional(),
            subject: centralized_zod_1.z.string().min(1),
            unitPrice: centralized_zod_1.z.number(),
            quantity: centralized_zod_1.z.number(),
            workOrderId: centralized_zod_1.z.string(),
        }),
    ]);
})(WorkOrderLineItem || (WorkOrderLineItem = {}));
exports.WorkOrderLineItem = WorkOrderLineItem;
