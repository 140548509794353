"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisWindowModalsProvider = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var utils_1 = require("@eljouren/utils");
var ToastList_1 = require("./ToastList");
var AlertDialog_1 = require("./AlertDialog");
var SingleActionDialog_1 = require("./SingleActionDialog");
var IpisWindowModalsProvider = function () {
    var _a = (0, react_1.useState)(null), show = _a[0], setShow = _a[1];
    var showRef = (0, react_1.useRef)(show);
    var confirmPromiseResolveFunctionRef = (0, react_1.useRef)(null);
    function alert(args) {
        setShow({ type: "alert", args: args });
    }
    function confirm(args) {
        if (confirmPromiseResolveFunctionRef.current) {
            Promise.reject(confirmPromiseResolveFunctionRef.current);
        }
        var resolve;
        var promise = new Promise(function (res) {
            resolve = res;
        });
        confirmPromiseResolveFunctionRef.current = resolve;
        setShow({ type: "confirm", args: args });
        return promise;
    }
    function toast(args) {
        var _a;
        var id = (_a = args.id) !== null && _a !== void 0 ? _a : utils_1.UUID.generate().value;
        var current = showRef.current;
        var newToast = __assign(__assign({}, args), { id: id });
        if ((current === null || current === void 0 ? void 0 : current.type) === "toast") {
            if (current.toasts.find(function (toast) { return toast.id === id; })) {
                return;
            }
            var copy = __assign({}, current);
            copy.toasts.push(newToast);
            setShow(copy);
        }
        else {
            setShow({ type: "toast", toasts: [newToast] });
        }
    }
    function singleAction(args) {
        setShow({ type: "singleAction", args: args });
    }
    (0, react_1.useEffect)(function () {
        window.ipisModal = {
            alert: alert,
            confirm: confirm,
            toast: toast,
            singleAction: singleAction,
        };
    }, []);
    (0, react_1.useEffect)(function () {
        showRef.current = show;
    }, [show]);
    function closeAlert() {
        //window.error.consume();
        setShow(null);
    }
    function closeToast(toast) {
        if ((show === null || show === void 0 ? void 0 : show.type) !== "toast") {
            return;
        }
        var copy = __assign({}, show);
        var filtered = copy.toasts.filter(function (el) { return el.id !== toast.id; });
        copy.toasts = filtered;
        if (filtered.length > 0) {
            setShow(copy);
        }
        else {
            setShow(null);
        }
    }
    function answerConfirmDialog(args) {
        if ((show === null || show === void 0 ? void 0 : show.type) !== "confirm") {
            return;
        }
        if (show.args.callback) {
            show.args.callback(args);
        }
        if (confirmPromiseResolveFunctionRef.current) {
            confirmPromiseResolveFunctionRef.current(args.answer);
            confirmPromiseResolveFunctionRef.current = null;
        }
        setShow(null);
    }
    function confirmSingleAction() {
        if ((show === null || show === void 0 ? void 0 : show.type) !== "singleAction") {
            return;
        }
        show.args.callback();
        setShow(null);
    }
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.AnimatePresence, { children: [!show && (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, {}), (show === null || show === void 0 ? void 0 : show.type) === "alert" && ((0, jsx_runtime_1.jsx)(AlertDialog_1.AlertDialog, __assign({}, show, { close: closeAlert }), "alert")), (show === null || show === void 0 ? void 0 : show.type) === "confirm" && ((0, jsx_runtime_1.jsx)(AlertDialog_1.AlertDialog, __assign({}, show, { answer: answerConfirmDialog }), "confirm")), (show === null || show === void 0 ? void 0 : show.type) === "toast" && ((0, jsx_runtime_1.jsx)(ToastList_1.ToastList, { toasts: show.toasts, closeToast: closeToast }, "toastList")), (show === null || show === void 0 ? void 0 : show.type) === "singleAction" && ((0, jsx_runtime_1.jsx)(SingleActionDialog_1.SingleActionDialog, __assign({}, show, { confirm: confirmSingleAction }), "singleAction"))] }));
};
exports.IpisWindowModalsProvider = IpisWindowModalsProvider;
