import { IpisFormElement } from "@eljouren/domain";
import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ChecklistNumericInputBuilder from "./ChecklistNumericInputBuilder";

interface Props {
  className?: string;
  closeForm: () => void;
  question: IpisFormElement.NumberInputType;
}

const ChecklistNumericInputEditor = (props: Props) => {
  const ctx = useFormEditor();

  return (
    <ChecklistNumericInputBuilder
      closeForm={props.closeForm}
      onSubmit={(values) => {
        ctx.controls.editElement({ id: props.question.id, newValues: values });
        props.closeForm();
      }}
      defaultValues={props.question}
    />
  );
};

export default ChecklistNumericInputEditor;
