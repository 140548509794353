"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderProduct = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
/*
    ToDo: Better, more descriptive name
*/
var WorkOrderProduct;
(function (WorkOrderProduct) {
    WorkOrderProduct.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        unitPrice: centralized_zod_1.z.number().optional(),
        //priceBookEntryId: z.string(),
        //isCustom: z.boolean(),
    });
})(WorkOrderProduct || (WorkOrderProduct = {}));
exports.WorkOrderProduct = WorkOrderProduct;
