"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var editable_address_hooks_1 = require("./editable-address-hooks");
var FormEditableAddressFieldset_1 = require("./FormEditableAddressFieldset");
var IpisButton_1 = require("../../../buttons/IpisButton");
var client_utils_1 = require("../../../../utils/client-utils");
var FormEditableAddressFieldsetSection = function (props) {
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var _a = (0, react_1.useState)(false), _isEditing = _a[0], setIsEditing = _a[1];
    var Heading = props.heading.as;
    var hasErrors = (0, editable_address_hooks_1.useHasAddressErrors)(props);
    var readOnly = !!(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly);
    var isEditing = (_isEditing || hasErrors) && readOnly;
    (0, react_1.useEffect)(function () {
        if (hasErrors && !_isEditing) {
            setIsEditing(true);
        }
    }, [hasErrors, _isEditing]);
    function startEditing() {
        setIsEditing(true);
    }
    function stopEditing() {
        if (hasErrors) {
            return;
        }
        setIsEditing(false);
    }
    return ((0, jsx_runtime_1.jsxs)("section", { className: "flex flex-col gap-2", children: [(0, jsx_runtime_1.jsxs)("header", { className: "flex h-8 items-center justify-between", children: [(0, jsx_runtime_1.jsx)(Heading, { className: "text-lg text-dark-950", children: props.heading.title }), !isEditing && !readOnly && ((0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { onlyIcon: true, variant: "text", label: "Redigera adress", icon: {
                            type: "edit",
                            position: "trailing",
                        }, onClick: startEditing })), isEditing && !readOnly && ((0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { variant: "text", label: "Spara adress", underline: true, onClick: stopEditing, className: (0, client_utils_1.classNames)(hasErrors && "opacity-50") }))] }), (0, jsx_runtime_1.jsx)(FormEditableAddressFieldset_1.FormEditableAddressFieldset, { id: props.id, form: props.form, legend: props.legend, isEditing: isEditing, fieldMapping: props.fieldMapping, registerNested: props.registerNested, readOnly: readOnly })] }));
};
exports.default = FormEditableAddressFieldsetSection;
