import { IpisFormBoundaries, IpisFormPage } from "@eljouren/domain";
import ClientUtils from "../../../../utils/ClientUtils";
import EditableText from "../../../__v2__/utils/EditableText";

interface Props {
  className?: string;
  page: IpisFormPage.WithoutAnswersType;
  onRenameShorthand?: (newName: string) => void;
  onRename?: (newName: string) => void;
  onDescriptionChange?: (newDescription: string) => void;
  onPreparationsChange?: (newPreparations: string) => void;
  descriptionPlaceholder?: string;
  preparationsPlaceholder?: string;
}

const ChecklistPageHeader = (props: Props) => {
  const description =
    props.page.pageDescription || props.descriptionPlaceholder || "";
  const preparations =
    props.page.preparations || props.preparationsPlaceholder || "";

  return (
    <header
      className={ClientUtils.classNames("flex flex-col gap-2", props.className)}
    >
      {!!props.onRenameShorthand && (
        <EditableText
          className="font-italic text-base text-semi-faded"
          as="h3"
          value={props.page.pageTitleShorthand}
          onSubmit={props.onRenameShorthand}
          minLength={IpisFormBoundaries.checklistPage.shorthandTitle.minLength}
          maxLength={IpisFormBoundaries.checklistPage.shorthandTitle.maxLength}
        />
      )}
      {!!props.onPreparationsChange && (
        <EditableText
          className="font-italic text-base text-semi-faded"
          as="p"
          value={preparations}
          onSubmit={props.onPreparationsChange}
          minLength={IpisFormBoundaries.checklistPage.preparations.minLength}
          maxLength={IpisFormBoundaries.checklistPage.preparations.maxLength}
        />
      )}
      <EditableText
        className="text-2xl font-semibold text-dark-950"
        as="h2"
        value={props.page.pageTitle}
        onSubmit={props.onRename}
        minLength={IpisFormBoundaries.checklistPage.title.minLength}
        maxLength={IpisFormBoundaries.checklistPage.title.maxLength}
      />
      {/* <h2 className="text-2xl font-semibold text-dark-950">
        {props.page.pageTitle}
      </h2> */}
      {props.onDescriptionChange && (
        <EditableText
          className="text-base text-semi-faded"
          as="p"
          value={description}
          defaultValue={props.page.pageDescription ?? ""}
          onSubmit={props.onDescriptionChange}
          minLength={IpisFormBoundaries.checklistPage.description.minLength}
          maxLength={IpisFormBoundaries.checklistPage.description.maxLength}
        />
      )}

      <p className="text-sm text-semi-faded">
        <span className="text-error">*</span> indikerar ett obligatoriskt fält
      </p>
    </header>
  );
};

export default ChecklistPageHeader;
