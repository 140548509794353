import ClientUtils from "./../../../utils/ClientUtils";
import { Handyman, IpisBrand } from "@eljouren/domain/build";
import React, { useContext } from "react";
import { useBrand } from "../../../hooks/brand-hooks";
import AppRoutes from "../../../routes";
import { GlobalContext } from "../../../top-level-contexts";

import MyLink from "../../routing/MyLink";
import EljourenLogo from "./EljourenLogo";
import IpisLogo from "./IpisLogo";
import RorjourenLogo from "./RorjourenLogo";
import RormokareNuLogo from "./RormokareNuLogo";
import AiiLogo from "./AiiLogo";
import { motion } from "framer-motion";

interface Props {
  wrapperClassName?: string;
  logoClassName?: string;
  onlyIcon?: boolean;
  performAnimation?: boolean;
  delay?: number;
  useShadow?: boolean;
  dontUseLink?: boolean;
  center?: boolean;
  withColor?: boolean;
  brand?: IpisBrand.Type;
  onlyShowSetBrand?: boolean;
}

const Wrapper = (wrapperProps: {
  worker: Handyman.Type | null | undefined;
  children?: React.ReactNode;
  className?: string;
  dontUseLink: boolean;
  style?: React.CSSProperties;
  brand?: IpisBrand.Type;
  onlyShowSetBrand?: boolean;
}) => {
  const en = IpisBrand.ZodEnum.enum;
  const hidden = wrapperProps.onlyShowSetBrand && wrapperProps.brand === en.Aii;

  if (wrapperProps.worker && !wrapperProps.dontUseLink) {
    return (
      <MyLink
        data-test-id="brand-logo"
        to={AppRoutes.partner.root()}
        style={{
          ...wrapperProps.style,
          opacity: hidden ? 0 : wrapperProps.style?.opacity,
        }}
        aria-hidden={hidden}
        className={wrapperProps.className}
        aria={{
          "aria-hidden": hidden,
        }}
      >
        {wrapperProps.children}
      </MyLink>
    );
  } else {
    return (
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: hidden ? 0 : 1 }}
        /* transition={{ delay: 0.5 }} */
        data-test-id="brand-logo"
        className={wrapperProps.className}
        style={wrapperProps.style}
        aria-hidden={hidden}
      >
        {wrapperProps.children}
      </motion.span>
    );
  }
};

const Logo = (props: Props) => {
  const { signInState } = useContext(GlobalContext);
  const globalBrand = useBrand();
  const brand = props.brand ?? globalBrand;

  const signedInAsHandyman = signInState.signedInAs === "worker";
  const showAii =
    (signedInAsHandyman || !signInState.isSignedIn) && !props.brand;
  if (showAii) {
    return (
      <Wrapper
        className={ClientUtils.twClassNames(
          "flex justify-center overflow-visible",
          !props.wrapperClassName && "max-h-full max-w-full",
          //!props.className && "h-20 w-44",
          props.wrapperClassName
        )}
        worker={signInState.handyman}
        dontUseLink={!!props.dontUseLink}
        brand={brand}
        onlyShowSetBrand={!!props.onlyShowSetBrand}
      >
        <AiiLogo
          key="aii"
          className={props.logoClassName}
          withColor={props.withColor}
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper
      className={ClientUtils.twClassNames(
        //"h-full w-full border-4 border-red-400",
        "flex justify-center overflow-visible",
        !props.wrapperClassName && "max-h-full max-w-full",
        //!props.className && "h-20 w-44",
        props.wrapperClassName
      )}
      worker={signInState.handyman}
      dontUseLink={!!props.dontUseLink}
      brand={brand}
      onlyShowSetBrand={!!props.onlyShowSetBrand}
    >
      {brand === "Rörjour 247" && (
        <RorjourenLogo
          key={"plumber"}
          center={props.center}
          className={props.logoClassName}
          withColor={props.withColor}
        />
      )}
      {brand === "Svenska Eljouren" && (
        <EljourenLogo
          key="electrician"
          onlyIcon={props.onlyIcon}
          className={props.logoClassName}
          withColor={props.withColor}
        />
      )}
      {brand === "Rörmokare.nu" && (
        <RormokareNuLogo
          key="plumber"
          className={ClientUtils.twClassNames(
            "h-full w-full",
            props.logoClassName
          )}
          withColor={props.withColor}
        />
      )}
      {(brand === "IPIS" || brand === "IPIS Contract") && (
        <IpisLogo
          key="unauthenticated"
          className={props.logoClassName}
          withColor={props.withColor}
        />
      )}
    </Wrapper>
  );
};

export default Logo;
