import { useEffect, useState } from "react";

type ContextMenuState =
  | false
  | {
      x: number;
      y: number;
    };

export type UseContextMenuReturn = {
  listener: (e: React.MouseEvent) => void;
  style: React.CSSProperties;
  close: () => void;
} & (
  | {
      isVisible: true;
      state: {
        x: number;
        y: number;
      };
    }
  | {
      isVisible: false;
    }
);

export function useContextMenu(
  props: {
    preventCloseOnLeftClick?: boolean;
    openTo?: "left" | "right";
  } = {}
): UseContextMenuReturn {
  const [state, setState] = useState<ContextMenuState>(false);

  function listener(e: React.MouseEvent) {
    e.preventDefault();
    setState({ x: e.clientX, y: e.clientY });
  }

  const openTo = props.openTo ?? "right";

  const style: React.CSSProperties = {
    position: "fixed",
    top: state ? state.y : -1000,
    zIndex: 1000,
  };

  if (openTo === "right") {
    style.left = state ? state.x : -1000;
  } else {
    style.right = state ? window.innerWidth - state.x : -1000;
  }

  useEffect(() => {
    if (props.preventCloseOnLeftClick) {
      return;
    }

    const callback = () => {
      setState(false);
    };
    if (state) {
      window.addEventListener("click", callback);
    }

    return () => {
      window.removeEventListener("click", callback);
    };
  }, [state, props.preventCloseOnLeftClick]);

  if (state) {
    return {
      close: () => setState(false),
      state: state,
      listener,
      style,
      isVisible: true,
    };
  } else {
    return {
      close: () => setState(false),
      listener: (e) => listener(e),
      style,
      isVisible: false,
    };
  }
}
