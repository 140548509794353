"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLogSignInInfo = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var ErrorLogSignInInfo;
(function (ErrorLogSignInInfo) {
    ErrorLogSignInInfo.CustomerSchema = centralized_zod_1.z.object({
        signedInAs: centralized_zod_1.z.literal("customer"),
        customerId: centralized_zod_1.z.string(),
        relatedWorkOrderId: centralized_zod_1.z.string(),
    });
    ErrorLogSignInInfo.StaffSchema = centralized_zod_1.z.object({
        signedInAs: centralized_zod_1.z.literal("staff"),
        userId: centralized_zod_1.z.string(),
    });
    ErrorLogSignInInfo.HandymanSchema = centralized_zod_1.z.object({
        signedInAs: centralized_zod_1.z.literal("worker"),
        /*
            Contact ID, maybe
        */
        userId: centralized_zod_1.z.string(),
    });
    ErrorLogSignInInfo.NotSignedInSchema = centralized_zod_1.z.object({
        signedInAs: centralized_zod_1.z.literal("none"),
    });
    ErrorLogSignInInfo.Schema = centralized_zod_1.z.discriminatedUnion("signedInAs", [
        ErrorLogSignInInfo.CustomerSchema,
        ErrorLogSignInInfo.StaffSchema,
        ErrorLogSignInInfo.HandymanSchema,
        ErrorLogSignInInfo.NotSignedInSchema,
    ]);
})(ErrorLogSignInInfo || (ErrorLogSignInInfo = {}));
exports.ErrorLogSignInInfo = ErrorLogSignInInfo;
