"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentOrderLine = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
/*
    Copied from the ResursBank schemas,
    but extracted if we want to abstract it at a later point.
*/
var PaymentOrderLine;
(function (PaymentOrderLine) {
    PaymentOrderLine.LineItemTypeSchema = centralized_zod_1.z.enum([
        "NORMAL",
        "PHYSICAL_GOODS",
        "DIGITAL_GOODS",
        "DISCOUNT",
        "SHIPPING",
        "FEE",
        "GIFT_CARD",
        "OTHER_PAYMENT",
    ]);
    PaymentOrderLine.Schema = centralized_zod_1.z.object({
        description: centralized_zod_1.z.string().optional(),
        quantity: centralized_zod_1.z.number(),
        reference: centralized_zod_1.z.string().optional().describe("I.e. article number"),
        type: PaymentOrderLine.LineItemTypeSchema.optional(),
        quantityUnit: centralized_zod_1.z.string().min(0).max(50).describe("i.e. 'st'"),
        unitAmountIncludingVat: centralized_zod_1.z.number().optional(),
        vatRate: centralized_zod_1.z
            .number()
            .describe("Value Added Tax rate. (The rate is entered in percent)"),
        totalAmountIncludingVat: centralized_zod_1.z.number(),
        totalVatAmount: centralized_zod_1.z.number().optional(),
    });
})(PaymentOrderLine || (PaymentOrderLine = {}));
exports.PaymentOrderLine = PaymentOrderLine;
