import { UseFormReturn } from "react-hook-form";

export function useFormError(args: {
  form: UseFormReturn<any>;
  name: string;
}): string | undefined {
  const errors = args.form.formState.errors;
  const subpaths = args.name.split(".");

  const [first, ...rest] = subpaths;

  let _error: any = errors[first];
  for (const subpath of rest) {
    if (_error === undefined || typeof _error !== "object") {
      break;
    }
    _error = _error[subpath];
  }
  if (typeof _error === "object" && typeof _error.message === "string") {
    return _error.message;
  }
  return undefined;
}
