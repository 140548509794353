import { IpisForm, IpisFormElement, IpisFormPage } from "@eljouren/domain";
import { useBind } from "@ipis/client-essentials";
import isEqual from "lodash.isequal";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useRepos } from "../../../../../../hooks/use-repos";
import FormBuilderStateHandler from "../../../state/FormBuilderStateHandler";
import {
  FormBuilderEvent,
  RenameFormPageEvent,
  SetConditionGroupsEvent,
} from "../../../state/events/form-builder-events";
import FormBuilderContext from "../../FormBuilderContext";
import { ChecklistQuestionFormType } from "./AddQuestionListItem";
import EditFormElementEventSchema from "../../../state/events/EditFormElementEventSchema";

type Controls = {
  renameForm: (args: { newName: string }) => void;
  removeQuestion: (args: { questionId: string }) => void;
  moveQuestionToAnotherPage: (args: {
    questionId: string;
    targetPageId: string;
  }) => void;
  reorderPages: (args: { pages: IpisFormPage.WithoutAnswersType[] }) => void;
  addElement: (args: { element: Omit<IpisFormElement.Type, "id"> }) => void;
  editElement: (args: {
    id: string;
    newValues: EditFormElementEventSchema.NewValuesType;
  }) => void;
  reorderElements: (args: { elementIdsInNewOrder: string[] }) => void;
  addFormPage: (args: {
    pageTitle: string;
    pageTitleShorthand: string;
    pageDescription: string | undefined;
    pagePreparations: string | undefined;
  }) => void;
  renameFormPage: (
    args: Omit<RenameFormPageEvent, "formId" | "discriminator">
  ) => void;
  removePage: (args: { pageId: string }) => void;
  resetToLatestSnapshot: () => Promise<void>;
  setConditionGroups: (
    args: Omit<SetConditionGroupsEvent, "formId" | "pageId" | "discriminator">
  ) => void;
};

type FormEditorContextType = {
  pageIndex: number;
  form: IpisForm.ShellType;
  page: IpisFormPage.WithoutAnswersType | undefined;
  setPage: (page: IpisFormPage.WithoutAnswersType) => void;
  assertPage: () => IpisFormPage.WithoutAnswersType;
  nextPage: () => void;
  previousPage: () => void;
  controls: Controls;
  saveForm: () => void;
  isDirty: () => boolean;
  canUndo: boolean;
  undo: () => void;
  canRedo: boolean;
  redo: () => void;
  setShowEditQuestionForm: (question: IpisFormElement.Type | null) => void;
  showEditQuestionForm: IpisFormElement.Type | null;
  setShowConditionalLogicForm: (question: IpisFormElement.Type | null) => void;
  showConditonalLogicForm: IpisFormElement.Type | null;
  setShowNewQuestionForm: (question: ChecklistQuestionFormType | null) => void;
  showNewQuestionForm: null | ChecklistQuestionFormType;
  setShowPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  showPreviewModal: boolean;
  getLatestEvent: () => FormBuilderEvent | null;
};

export const FormEditorContext = React.createContext<FormEditorContextType>(
  undefined as never
);

interface Props {
  children?: React.ReactNode;
  staticForm: IpisForm.ShellType;
}

const FormEditorContextProvider = (props: Props) => {
  const { ipisFormRepo } = useRepos();
  const [stateHandler, setStateHandler] = useState<FormBuilderStateHandler>(
    new FormBuilderStateHandler({
      form: props.staticForm,
    })
  );
  const observedState = useBind(stateHandler.getObservable());
  const form = observedState.form;

  const [pageId, setPageId] = useState<string | undefined>(form.pages[0]?.id);
  const foundPage = form.pages.find((p) => p.id === pageId);
  const page = foundPage ?? form.pages[0];

  const [showNewQuestionForm, setShowNewQuestionForm] =
    useState<null | ChecklistQuestionFormType>(null);

  const [showEditQuestionForm, setShowEditQuestionForm] =
    useState<null | IpisFormElement.Type>(null);

  const [showConditonalLogicForm, setAddConditionalLogic] =
    useState<null | IpisFormElement.Type>(null);

  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const builderCtx = useContext(FormBuilderContext);

  let pageIndex = page ? form.pages.findIndex((el) => el.id === page.id) : 0;
  if (pageIndex === -1) {
    pageIndex = 0;
  }

  useEffect(() => {
    if (pageId && !foundPage) {
      setPageId(form.pages[0]?.id);
    }
  }, [pageId, foundPage, setPageId, form.pages]);

  const resetStateHandler = useCallback(() => {
    setStateHandler(
      new FormBuilderStateHandler({
        form: props.staticForm,
      })
    );
    setPageId(props.staticForm.pages[0]?.id);
  }, [props.staticForm]);

  const handlePotentialFormChange = useCallback(async () => {
    const formInProps = props.staticForm;
    const formFromSnapshot = stateHandler.getLatestSnapshot().state.form;

    if (formInProps.id !== formFromSnapshot.id) {
      throw new Error("Form IDs do not match");
    }

    const equal = isEqual(formInProps, formFromSnapshot);

    if (equal) {
      return;
    }

    if (!stateHandler.isDirty()) {
      resetStateHandler();
      return;
    }

    const replace = await window.ipisModal.confirm({
      title: "Formulär ändrat",
      prompt:
        "Det formulär du arbetar med har ändrats. Förmodligen så jobbar någon annan med samma formulär samtidigt som dig. Vill du ersätta det lokala med det nya?",
      confirmLabel: "Ja, ersätt",
      rejectLabel: "Nej, fortsätt med den lokala versionen",
    });

    if (replace) {
      resetStateHandler();
    }
  }, [props.staticForm, stateHandler, resetStateHandler]);

  useEffect(() => {
    const sameForm = stateHandler.form.id === props.staticForm.id;
    if (sameForm) {
      handlePotentialFormChange();
    } else {
      resetStateHandler();
    }
  }, [
    props.staticForm,
    stateHandler,
    resetStateHandler,
    handlePotentialFormChange,
  ]);

  useEffect(() => {
    const sameForm = stateHandler.form.id === props.staticForm.id;
    if (sameForm) {
      handlePotentialFormChange();
    } else {
      resetStateHandler();
    }
  }, [
    props.staticForm,
    stateHandler,
    setStateHandler,
    resetStateHandler,
    handlePotentialFormChange,
  ]);

  useEffect(() => {
    builderCtx.setSelectedFormIsDirty(stateHandler.isDirty());
  });

  function setPage(page: IpisFormPage.WithoutAnswersType) {
    setPageId(page.id);
  }

  function nextPage() {
    const index = pageIndex + 1;
    if (index < form.pages.length) {
      setPage(form.pages[index]);
    }
  }

  function previousPage() {
    const index = pageIndex - 1;
    if (index >= 0) {
      setPage(form.pages[index]);
    }
  }

  function assertPage() {
    if (!page) {
      throw new Error("No page found");
    }
    return page;
  }

  function renameForm(args: { newName: string }) {
    stateHandler.processEvent({
      discriminator: "renameFormEvent",
      formId: form.id,
      newName: args.newName,
    });
  }

  function removeQuestion(args: { questionId: string }) {
    const page = assertPage();
    stateHandler.processEvent({
      discriminator: "removeFormElementEvent",
      formId: form.id,
      pageId: page.id,
      elementId: args.questionId,
    });
  }

  function moveQuestionToAnotherPage(
    args: Parameters<Controls["moveQuestionToAnotherPage"]>[0]
  ) {
    stateHandler.processEvent({
      discriminator: "moveElementToAnotherPageEvent",
      formId: form.id,
      fromPageId: assertPage().id,
      elementId: args.questionId,
      toPageId: args.targetPageId,
    });
  }

  function reorderPages(args: Parameters<Controls["reorderPages"]>[0]) {
    stateHandler.processEvent({
      discriminator: "reorderFormPagesEvent",
      formId: form.id,
      pageIdsInNewOrder: args.pages.map((p) => p.id),
    });
  }

  function addElement(args: Parameters<Controls["addElement"]>[0]) {
    stateHandler.processEvent({
      discriminator: "addFormElementEvent",
      formId: form.id,
      pageId: assertPage().id,
      element: args.element,
    });
  }

  function removePage(args: Parameters<Controls["removePage"]>[0]) {
    stateHandler.processEvent({
      discriminator: "removeFormPageEvent",
      formId: form.id,
      pageId: args.pageId,
    });
  }

  function editElement(args: Parameters<Controls["editElement"]>[0]) {
    stateHandler.processEvent({
      discriminator: "editFormElementEvent",
      formId: form.id,
      elementId: args.id,
      newValues: args.newValues,
    });
  }

  function reorderElements(args: Parameters<Controls["reorderElements"]>[0]) {
    stateHandler.processEvent({
      discriminator: "reorderFormElementsEvent",
      formId: form.id,
      pageId: assertPage().id,
      elementIdsInNewOrder: args.elementIdsInNewOrder,
    });
  }

  function addFormPage(args: Parameters<Controls["addFormPage"]>[0]) {
    stateHandler.processEvent({
      discriminator: "addFormPageEvent",
      formId: form.id,
      ...args,
    });
  }

  function renameFormPage(args: Parameters<Controls["renameFormPage"]>[0]) {
    stateHandler.processEvent({
      discriminator: "renameFormPageEvent",
      formId: form.id,
      ...args,
    });
  }

  async function resetToLatestSnapshot() {
    const confirmed = await window.ipisModal.confirm({
      title: "Återställ formulär",
      prompt: "Vill du återställa formuläret till senaste sparade version?",
      confirmLabel: "Ja, återställ",
      rejectLabel: "Nej, avbryt",
    });

    if (confirmed) {
      stateHandler.processEvent({
        discriminator: "resetToLatestSnapshotEvent",
        formId: form.id,
      });
    }
  }

  function setConditionGroups(
    args: Parameters<Controls["setConditionGroups"]>[0]
  ) {
    stateHandler.processEvent({
      discriminator: "setConditionGroupsEvent",
      formId: form.id,
      pageId: assertPage().id,
      ...args,
    });
  }

  function canUndo() {
    const peek = stateHandler.peekPastEvents();
    return !!peek;
  }

  async function undo() {
    const peek = stateHandler.peekPastEvents();
    if (!peek) {
      window.modal.toast({
        title: "Inget att ångra",
        prompt: "Det finns inget att ångra",
        toastType: "success",
        timeVisibleInMs: 2000,
      });
    } else {
      const confirm = await confirmUndoEvent(peek);
      if (!confirm) {
        return;
      }
      stateHandler.undo();
    }
  }

  function canRedo() {
    const peek = stateHandler.peekFutureEvents();
    return !!peek;
  }

  async function redo() {
    const peek = stateHandler.peekFutureEvents();

    if (!peek) {
      window.modal.toast({
        title: "Inget att göra om",
        prompt: "Det finns inget att göra om",
        toastType: "success",
        timeVisibleInMs: 2000,
      });
    } else {
      stateHandler.redo();
    }
  }

  function getLatestEvent() {
    return stateHandler.getLatestEvent();
  }

  async function confirmUndoEvent(event: FormBuilderEvent): Promise<boolean> {
    return true;
  }

  async function saveForm() {
    try {
      await builderCtx.mutate(() => {
        return ipisFormRepo.saveForm({ form: stateHandler.form });
      });
      stateHandler.saveSnapshot();
    } catch (er) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kunde inte spara formuläret",
        typeOfAlert: "error",
      });
    }
  }

  return (
    <FormEditorContext.Provider
      value={{
        form: observedState.form,
        // Pagination
        page,
        pageIndex,
        setPage,
        nextPage,
        previousPage,
        assertPage,
        // Modals
        setShowEditQuestionForm,
        showEditQuestionForm,
        setShowNewQuestionForm,
        showNewQuestionForm,
        setShowPreviewModal,
        showPreviewModal,
        setShowConditionalLogicForm: setAddConditionalLogic,
        showConditonalLogicForm,
        // Form editing
        saveForm,
        isDirty: () => stateHandler.isDirty(),
        canUndo: canUndo(),
        undo,
        canRedo: canRedo(),
        redo,
        controls: {
          renameForm,
          removeQuestion,
          moveQuestionToAnotherPage,
          reorderPages,
          addElement,
          editElement,
          reorderElements,
          addFormPage,
          renameFormPage,
          removePage,
          resetToLatestSnapshot,
          setConditionGroups,
        },
        // Misc
        getLatestEvent,
      }}
    >
      {props.children}
    </FormEditorContext.Provider>
  );
};

export default FormEditorContextProvider;
