import { Checklist } from "@eljouren/domain";
import { UseFormReturn } from "react-hook-form";
import ClientUtils from "../../../../utils/ClientUtils";
import { ChecklistItemProps } from "./ChecklistItem";
import ChecklistAnsweredIndicator from "./ChecklistAnsweredIndicator";

interface Props extends ChecklistItemProps {
  className?: string;
}

const ChecklistItemYesNo = (props: Props) => {
  const isRequired = props.item.required;
  const formName = props.item.id + ".answer";
  const currentValue = props.form.watch(formName);
  const isAnswered = currentValue === "Ja" || currentValue === "Nej";
  return (
    <fieldset
      className={ClientUtils.twClassNames(
        "flex items-center gap-2",
        props.className
      )}
      data-checklist-item-type={"yes/no"}
    >
      <legend
        className={ClientUtils.classNames(
          "grid w-full grid-cols-[auto,1fr] grid-rows-1 items-center gap-2 border-b pb-1"
        )}
      >
        <span className="text-sm font-semibold md:text-base">
          {props.item.name}
        </span>
        <ChecklistAnsweredIndicator
          isAnswered={isAnswered}
          isRequired={isRequired}
        />
      </legend>
      <RadioBox type="yes" {...props} />
      <RadioBox type="no" {...props} />
    </fieldset>
  );
};

type RadioBoxProps = {
  type: "yes" | "no";
  form: UseFormReturn<any>;
  item: Checklist.ListItemType;
  readOnly?: boolean;
};

const RadioBox = (props: RadioBoxProps) => {
  const formId = props.item.id + ".answer";
  const formValue = props.type === "yes" ? "Ja" : "Nej";
  const label = props.type === "yes" ? "Ja" : "Nej";
  /*   const currentValue = props.form.watch(formId); */
  /*   const selected = currentValue === formValue; */
  const inputId = `${props.type}CheckboxFor${props.item.id}`;

  /*   let nonSelectedClassName: string;
  let selectedClassName: string;
  if (props.type === "yes") {
    nonSelectedClassName = "";
    selectedClassName = "border-green-400";
  } else {
    nonSelectedClassName = "";
    selectedClassName = "border-red-400";
  } */

  return (
    <p
      className={ClientUtils.classNames(
        "mt-2 flex items-center rounded pl-2 accent-slate-600"
        /*  selected && selectedClassName,
        !selected && nonSelectedClassName */
      )}
    >
      <input
        className="h-5 w-5 cursor-pointer"
        type="radio"
        id={inputId}
        value={formValue}
        {...props.form.register(formId)}
        //defaultValue={noOption}
        disabled={props.readOnly}
      />
      <label htmlFor={inputId} className="cursor-pointer px-4 py-2">
        {label}
      </label>
    </p>
  );
};

export default ChecklistItemYesNo;
