"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormProductPackage = void 0;
var zod_1 = require("zod");
var IpisFormProductPackage;
(function (IpisFormProductPackage) {
    IpisFormProductPackage.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        name: zod_1.z.string(),
        connectedFormId: zod_1.z.string().nullable(),
    });
})(IpisFormProductPackage || (IpisFormProductPackage = {}));
exports.IpisFormProductPackage = IpisFormProductPackage;
