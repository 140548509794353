import { HandymanWorkingHours } from "@eljouren/domain/build";
import { DateHelper, Utils } from "@eljouren/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { parse } from "date-fns";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import ClientUtils from "./../../utils/ClientUtils";

import GoBackButton from "../GoBackButton";
import { AppButton } from "../common/buttons/AppButton";
import ControlledTimePicker from "../common/time/ControlledTimePicker";
import HandymanContext from "../handyman-context/HandymanContext";

const FormSchema = z
  .object({
    start: z.date(),
    end: z.date(),
  })
  .refine(
    (val) => {
      return Number(val.end) > Number(val.start);
    },
    {
      message: "Sluttid måste vara efter starttid",
      path: ["end"],
    }
  );

type TFormSchema = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  dates: Record<string, true>;
  onFormSubmitted?(): void;
  goBack?(): void;
}

const WorkingHoursForm = (props: Props) => {
  const ctx = useContext(HandymanContext);
  const { handleSubmit, getValues, control, formState } = useForm<TFormSchema>({
    resolver: zodResolver(FormSchema),
    defaultValues: getDefaultValues(),
  });

  function getDefaultValues() {
    const data = ctx.workingHoursRes.data;
    if (!data) {
      return undefined;
    }
    const dates = Object.keys(props.dates);
    let firstNonOffDutyWorkingHour: { start: Date; end: Date } | undefined;
    dates.some((key) => {
      const saved = data[key];
      if (saved && saved.status !== "offWork") {
        firstNonOffDutyWorkingHour = saved;
        return true;
      }
      return false;
    });

    if (!firstNonOffDutyWorkingHour) {
      return undefined;
    }

    const first = firstNonOffDutyWorkingHour;

    const start = new DateHelper()
      .setHours(first.start.getHours())
      .setMinutes(first.start.getMinutes()).date;
    const end = new DateHelper()
      .setHours(first.end.getHours())
      .setMinutes(first.end.getMinutes()).date;

    return {
      start,
      end,
    };
  }

  async function onSubmit() {
    const values = getValues();
    const startHelper = new DateHelper(values.start);
    const endHelper = new DateHelper(values.end);
    const workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType =
      Utils.objectMap(props.dates, (value, key) => {
        const helper = new DateHelper(parse(key, "T", new Date()));

        const modifedStartHelper = helper
          .setHours(startHelper.hours)
          .setMinutes(startHelper.minutes);
        const end = helper
          .setHours(endHelper.hours)
          .setMinutes(endHelper.minutes).date;

        return {
          status: "reported",
          startOfDay: modifedStartHelper.startOfDay.date,
          start: modifedStartHelper.date,
          end,
        };
      });
    report(workingHours);
  }

  async function onOffDutyClick() {
    const workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType =
      Utils.objectMap(props.dates, (value, key) => {
        const start = new DateHelper(parse(key, "T", new Date())).startOfDay;
        const end = start.endOfDay;
        return {
          status: "offWork",
          start: start.date,
          end: end.date,
        };
      });
    report(workingHours);
  }

  function report(
    workingHours: HandymanWorkingHours.DictIndexedByStartOfDayType
  ) {
    ctx.saveWorkingHours.mutateAsync(workingHours).catch((er) => {
      window.modal.alert({
        title: "Det gick inte att rapportera arbetstider just nu",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    });

    props.onFormSubmitted && props.onFormSubmitted();
  }

  const disabled = !Object.values(props.dates).length || ctx.isLoading;
  const ordersPlanned = Object.keys(props.dates).some((timestamp) => {
    const entry = ctx.merger.getEntry(timestamp);
    const workOrders = entry.workOrders;
    return !!workOrders.length;
  });

  return (
    <form
      className={ClientUtils.twClassNames(
        "mx-auto flex h-full w-full flex-col items-center gap-2 overflow-auto p-4",
        props.className
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <fieldset className="mx-auto flex w-full flex-col gap-2 rounded border p-2">
        <legend className="mx-auto text-xl font-semibold">Jag jobbar</legend>
        <span className="mx-auto flex flex-col border-2 border-transparent md:items-center lg:grid lg:grid-cols-2">
          <label className="text-base" htmlFor="workingHoursStart">
            från
          </label>
          <ControlledTimePicker
            control={control}
            name="start"
            className="w-full p-2"
            htmlAttributes={{
              id: "workingHoursStart",
            }}
          />
        </span>
        <span className="mx-auto flex flex-col border-2 border-transparent md:items-center lg:grid lg:grid-cols-2">
          <label className="text-base" htmlFor="workingHoursEnd">
            till
          </label>
          <ControlledTimePicker
            control={control}
            name="end"
            className="w-full border-2 p-2 "
            htmlAttributes={{
              id: "workingHoursEnd",
            }}
          />
        </span>
        {formState.errors.end && formState.errors.end.type === "custom" && (
          <span className="pl-2 text-red-600">
            {formState.errors.end.message}
          </span>
        )}
        <AppButton
          requiresNetworkConnection
          type="submit"
          className="mt-8"
          disabled={disabled}
        >
          Rapportera tider
        </AppButton>
      </fieldset>

      <fieldset className="flex w-full flex-col gap-4 p-2">
        <AppButton
          requiresNetworkConnection
          contentClassName="w-full"
          variant="fill-warning"
          onClick={onOffDutyClick}
          disabled={disabled || ordersPlanned}
        >
          Jag är otillgänglig
        </AppButton>
        {!!props.goBack && (
          <GoBackButton
            iconSize={24}
            appButtonProps={{
              className: "w-full",
            }}
            onClick={props.goBack}
          />
        )}
      </fieldset>

      {ordersPlanned && (
        <span className="p-2 text-sm">
          Minst ett av dina valda datum har en arbetsorder inplanerad och det är
          därför ej möjligt att rapportera in otillgänglighet.
        </span>
      )}
    </form>
  );
};

export default WorkingHoursForm;
