"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyUploadMultipleFilesResponse = void 0;
var zod_1 = require("zod");
var ProxyUploadSingleFileResponse_1 = require("./ProxyUploadSingleFileResponse");
var ProxyUploadMultipleFilesResponse;
(function (ProxyUploadMultipleFilesResponse) {
    ProxyUploadMultipleFilesResponse.Schema = zod_1.z.object({
        results: zod_1.z
            .union([ProxyUploadSingleFileResponse_1.ProxyUploadSingleFileResponse.Schema, zod_1.z.literal(false)])
            .array(),
        successfulUploadsCount: zod_1.z.number(),
        successfulMetaCount: zod_1.z.number(),
        failedMetaCount: zod_1.z.number(),
        failedUploadsCount: zod_1.z.number(),
        message: zod_1.z.string().optional(),
    });
})(ProxyUploadMultipleFilesResponse || (ProxyUploadMultipleFilesResponse = {}));
exports.ProxyUploadMultipleFilesResponse = ProxyUploadMultipleFilesResponse;
