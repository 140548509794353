import { Editor } from "slate";

type ToggleAble = "bold" | "italic" | "underline";

namespace CustomSlateEditor {
  export function isMarkActive(editor, format: ToggleAble) {
    const marks = Editor.marks(editor);
    return !!marks?.[format];
  }

  export function toggleMark(editor, format: ToggleAble) {
    const isActive = CustomSlateEditor.isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  }
}

export default CustomSlateEditor;
