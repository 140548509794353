import { Reorder, motion } from "framer-motion";
import React, { useState } from "react";
import {
  UseContextMenuReturn,
  useContextMenu,
} from "../../../hooks/use-context-menu";
import {
  AppMenuItemButtonIconType,
  AppMenuItemButtonV2,
} from "../buttons/AppMenuItemButtonV2";
import AppContextMenu from "./AppContextMenu";

export type Menu = {
  onReorder?: (items: MenuItem[]) => void;
  items: MenuItem[];
};

type ButtonMenuItem = {
  id: string;
  type: "button";
  label: string;
  disabled?: boolean;
  selected?: boolean;
  subheading?: string;
  onClick?: () => void;
  onContextMenu?: MenuItem[];
} & (
  | {
      nestedMenu?: Menu;
      icon?: undefined;
    }
  | {
      nestedMenu?: undefined;
      icon: AppMenuItemButtonIconType;
    }
);

type LinkMenuItem = {
  id: string;
  type: "link";
  label: string;
  subheading?: string;
  href: string;
};

export type MenuItem = ButtonMenuItem | LinkMenuItem;

interface Props {
  className?: string;
  id: string;
  variant: "light" | "dark";
  menu: Menu;
  nestedCount?: number;
  handleSelectedStateInternally?: boolean;
}

const AppMenu = (props: Props) => {
  return (
    <ListWrapper
      className="flex w-full flex-col"
      onReorder={props.menu.onReorder}
      items={props.menu.items}
    >
      {props.menu.items.map((item, i) => {
        const key = `${props.id}-${item.id}`;
        return <ListItem key={key} item={item} parentProps={props} />;
      })}
    </ListWrapper>
  );
};

const ListItem = (props: { item: MenuItem; parentProps: Props }) => {
  const parent = props.parentProps;
  const item = props.item;

  const [internalSelected, setInternalSelected] = useState(false);
  const contextMenuReturn = useContextMenu();

  if (item.type === "link") {
    return <li>Links not yet supported</li>;
  }

  const key = `${parent.id}-${item.id}`;
  const nestedCount = parent.nestedCount ?? 0;
  const isSelected = parent.handleSelectedStateInternally
    ? internalSelected
    : !!item.selected;

  const hasContextMenu = item.type === "button" && item.onContextMenu;
  const contextMenu = hasContextMenu ? item.onContextMenu : undefined;
  const onContextMenu = hasContextMenu ? contextMenuReturn.listener : undefined;

  return (
    <ListItemWrapper
      canReorder={!!parent.menu.onReorder}
      value={item}
      className="flex w-full flex-col"
    >
      <AppMenuItemButtonV2
        disabled={item.disabled}
        nestedCount={parent.nestedCount}
        selected={!!isSelected}
        label={item.label}
        onContextMenu={onContextMenu}
        trailingIcon={item.icon ? { type: item.icon } : undefined}
        onClick={() => {
          if (parent.handleSelectedStateInternally) {
            setInternalSelected(!internalSelected);
          }
          if (item.onClick) {
            item.onClick();
          }
        }}
        variant={parent.variant === "light" ? "light-scheme" : "dark-scheme"}
        expandable={
          !!item.nestedMenu?.items.length
            ? { isExpanded: isSelected }
            : undefined
        }
      />
      {isSelected && item.nestedMenu && (
        <AppMenu
          id={key}
          variant={parent.variant}
          menu={item.nestedMenu}
          nestedCount={nestedCount + 1}
        />
      )}
      {contextMenu && contextMenuReturn.isVisible && (
        <AppContextMenu
          ctxMenu={contextMenuReturn}
          id={`context-menu-for-${item.id}`}
          variant="light"
          menu={{ items: contextMenu }}
        />
      )}
    </ListItemWrapper>
  );
};

const ListWrapper = (props: {
  onReorder?: (items: MenuItem[]) => void;
  children: React.ReactNode;
  items: MenuItem[];
  className?: string;
}) => {
  if (!!props.onReorder) {
    return (
      <Reorder.Group
        className={props.className}
        values={props.items}
        onReorder={props.onReorder}
      >
        {props.children}
      </Reorder.Group>
    );
  }

  return <motion.ul className={props.className}>{props.children}</motion.ul>;
};

const ListItemWrapper = (props: {
  children: React.ReactNode;
  canReorder: boolean;
  className?: string;
  value: MenuItem;
  onContextMenu?: UseContextMenuReturn["listener"];
}) => {
  if (props.canReorder) {
    return (
      <Reorder.Item
        className={props.className}
        value={props.value}
        onContextMenu={props.onContextMenu}
      >
        {props.children}
      </Reorder.Item>
    );
  }

  return (
    <motion.li className={props.className} onContextMenu={props.onContextMenu}>
      {props.children}
    </motion.li>
  );
};

export default AppMenu;
