import { WorkOrderConfirmation, WorkOrderContactInfo } from "@eljouren/domain";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import CustomerOrderContactInformationFieldset from "../../../../components/work-order/form-fields/CustomerOrderContactInformationFieldset";
import CustomerOrderDescriptionFieldset from "../../../../components/work-order/form-fields/CustomerOrderDescriptionFieldset";
import CustomerOrderTimeAndPlaceFieldset from "../../../../components/work-order/form-fields/CustomerOrderTimeAndPlaceFieldset";
import ClientUtils from "./../../../../utils/ClientUtils";
import CustomerWorkOrderContext from "../../CustomerWorkOrderContext";
import CustomerFormFooter from "../components/CustomerFormFooter";
import CustomerFormMainSection from "../components/CustomerFormMainSection";

/*
  Schema to validate that the phonenumber(s) starts with a +

  Not used in the "BaseFieldsSchema" as I don't want to disallow phonenumbers
  that don't follow this pattern coming directly from the server
*/
export const WorkOrderBaseFieldsConfirmationFormSchema =
  WorkOrderConfirmation.BaseFieldsSchema.merge(
    z.object({
      contact: WorkOrderContactInfo.Schema.merge(
        z.object({
          phone: z.string().regex(/^\+.*/),
          phone2: z
            .union([z.string().regex(/^\+.*/), z.string().length(0)])
            .optional(),
        })
      ),
    })
  );

interface Props {
  className?: string;
  //order: WorkOrder.Type;
  defaultValues?: WorkOrderConfirmation.BaseFieldsType;
  onSubmit(data: WorkOrderConfirmation.BaseFieldsType): void;
}

const CustomerWorkOrderBaseFieldsFormStep = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderContext);
  /*
    Using the whole order as default values without first parsing it (and stripping) using Zod for some reason
    doesn't work with error message I've never seen before, such as

    {
      someKey: {
        message: "expected 'never' received 'string'"
      }
    }

    I passed the whole order as default values before the proxy-bundling refactor which didn't cause any problems,
    and I can't find any answers while Googling about the above error
  */
  const form = useForm<WorkOrderConfirmation.BaseFieldsType>({
    defaultValues: props.defaultValues
      ? {
          ...WorkOrderConfirmation.BaseFieldsSchema.parse(ctx.workOrder),
          ...props.defaultValues,
        }
      : WorkOrderConfirmation.BaseFieldsSchema.parse(ctx.workOrder),

    resolver: zodResolver(WorkOrderBaseFieldsConfirmationFormSchema),
    //resolver: zodResolver(OrderConfirmationFormSchemaWithoutPhoneValidation),
  });
  const { register, handleSubmit } = form;

  function onSubmit(data: WorkOrderConfirmation.BaseFieldsType) {
    props.onSubmit(data);
  }

  function onError(errors: FieldErrors<WorkOrderConfirmation.BaseFieldsType>) {
    console.log(errors);
  }

  const formProps = {
    showPhoneNumberHelperLabels: true,
    order: ctx.workOrder,
    //@ts-ignore
    register: register,
    startDate: ctx.workOrder.startDate,
    //className="p-2"
    allowEditing: {
      "location.city": true,
      "location.country": true,
      "location.postalCode": true,
      "location.street": true,
      "location.doorCode": true,
      "location.floor": true,
      "contact.phone": true,
      "contact.phone2": true,
      "contact.email": true,
    },
  };

  const showCards = process.env.NODE_ENV === "development" && false;

  return (
    <CustomerFormMainSection
      className={ClientUtils.twClassNames(
        "grid h-full w-full grid-rows-[auto,minmax(0,1fr)] items-center gap-6 overflow-auto",
        props.className
      )}
    >
      <header className="flex flex-col items-center gap-2 py-6">
        <h2 className="pt-4 text-2xl">Tack för er bokning!</h2>
        <p>Vänligen kontrollera att information nedan stämmer</p>
      </header>
      {showCards && <></>}

      <main className="h-full">
        <form
          className="flex h-full flex-col gap-12 lg:p-8"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <CustomerOrderDescriptionFieldset {...formProps} />
          <CustomerOrderTimeAndPlaceFieldset {...formProps} />
          <CustomerOrderContactInformationFieldset {...formProps} />
          <CustomerFormFooter state="first" />
        </form>
      </main>
    </CustomerFormMainSection>
  );
};

/* 
    

*/

export default CustomerWorkOrderBaseFieldsFormStep;
