"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLog = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var ErrorLogAccessLevel_1 = require("./ErrorLogAccessLevel");
var ErrorLogLevel_1 = require("./ErrorLogLevel");
var ErrorLogSignInInfo_1 = require("./ErrorLogSignInInfo");
var ErrorLog;
(function (ErrorLog) {
    ErrorLog.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        publicId: centralized_zod_1.z.string(),
        permissionLevel: ErrorLogAccessLevel_1.ErrorLogAccessLevel.Schema,
        logLevel: ErrorLogLevel_1.ErrorLogLevel.Schema,
        origin: centralized_zod_1.z.enum(["Web App", "File Server", "Unknown"]),
        createdAt: centralized_zod_1.z.date(),
        tag: centralized_zod_1.z.string().optional(),
        title: centralized_zod_1.z.string(),
        signInInfo: ErrorLogSignInInfo_1.ErrorLogSignInInfo.Schema.nullable(),
        requestUrl: centralized_zod_1.z.string(),
        json: centralized_zod_1.z.record(centralized_zod_1.z.unknown()),
    });
})(ErrorLog || (ErrorLog = {}));
exports.ErrorLog = ErrorLog;
