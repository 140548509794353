import { WorkOrderLineItem } from "@eljouren/domain";
import { useContext } from "react";
import HandymanWorkOrderRouteContext from "../../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import AppCheckmarkIcon from "../../../../../icons/AppCheckmarkIcon";
import AppClockIcon from "../../../../../icons/AppClockIcon";
import AppXIcon from "../../../../../icons/AppXIcon";

interface Props {
  className?: string;
  lineItem: WorkOrderLineItem.HandymanType | WorkOrderLineItem.CustomerType;
}

const LineItemApprovalStateIcon = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const order = ctx.order;
  if (!order.requiresMaterialToBeReviewed) {
    return <></>;
  }

  const state = props.lineItem.approvalState;

  if (state === "addedBeforeApprovalRequirements" || state === "approved") {
    return (
      <AppCheckmarkIcon className="text-green-700" variant="circle" size={15} />
    );
  }

  if (state === "notApproved") {
    return <AppXIcon className="text-red-700" variant="circle" size={15} />;
  }

  if (state === "pending") {
    return <AppClockIcon className="text-yellow-600" size={15} />;
  }

  return <></>;
};

export default LineItemApprovalStateIcon;
