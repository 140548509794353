import { WorkOrderConfirmation } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import GDPRLinkAndConfirmationCheckbox from "../../../../components/common/gdpr/GDPRConfirmationCheckbox";
import ClientUtils from "./../../../../utils/ClientUtils";

import { CustomFileInputFile } from "../../../../components/files/FileInputButton";
import CustomerWorkOrderContext from "../../CustomerWorkOrderContext";
import CustomerWorkOrderOverviewSection from "../../customer-work-order-overview/CustomerWorkOrderOverviewSection";
import CustomerFormFooter from "../components/CustomerFormFooter";
import CustomerFormMainSection from "../components/CustomerFormMainSection";

export const CustomerWorkOrderOverviewFormSchema = z.object({
  gdprConfirmed: z.literal(true),
});

export type CustomerWorkOrderOverviewFormValues = z.infer<
  typeof CustomerWorkOrderOverviewFormSchema
>;

interface Props {
  className?: string;
  values: Omit<WorkOrderConfirmation.Type, "gdprConfirmed">;
  files: CustomFileInputFile[];
  onSubmit(values: Pick<WorkOrderConfirmation.Type, "gdprConfirmed">): void;
  onGoBack(): void;
}

const CustomerWorkOrderOverviewFormStep = (props: Props) => {
  const { workOrder } = useContext(CustomerWorkOrderContext);
  const form = useForm<CustomerWorkOrderOverviewFormValues>({
    resolver: zodResolver(CustomerWorkOrderOverviewFormSchema),
  });

  return (
    <CustomerFormMainSection
      className={ClientUtils.twClassNames(props.className, "flex flex-col")}
    >
      <form
        className={ClientUtils.twClassNames("flex flex-col gap-12 lg:p-8")}
        onSubmit={form.handleSubmit(props.onSubmit)}
      >
        <CustomerWorkOrderOverviewSection
          labelForFileUploading="Ladda upp"
          files={props.files}
          workOrder={{
            ...props.values,
            orderId: workOrder.orderId,
            startDate: workOrder.startDate,
            confirmedByCustomer: false,
            selectedHousingInformation: props.values.housing ?? null,
            selectedInvoiceInformation: props.values.invoice ?? null,
          }}
          allowFileHandling={false}
        />
        <CustomerFormFooter state="last" onGoBack={props.onGoBack}>
          <GDPRLinkAndConfirmationCheckbox form={form} />
        </CustomerFormFooter>
      </form>
    </CustomerFormMainSection>
  );
};

export default CustomerWorkOrderOverviewFormStep;
