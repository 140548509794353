import ClientUtils from "./../../../../utils/ClientUtils";

import CompletedWorkOrderIcon from "../../../../components/icons/composed/CompletedWorkOrderIcon";
import ServiceContractIcon from "../../../../components/icons/composed/ServiceContractIcon";
import { HandymanWorkOrderRouteContextType } from "../contexts/HandymanWorkOrderRouteContext";

interface Props {
  subheading?: string;
  headingClassName: string;
  subheadingClassName: string;
  context: HandymanWorkOrderRouteContextType;
}

const HandymanWorkOrderMobileHeadings = (props: Props) => {
  const order = props.context.order;
  const isFinished = order.isFinished;
  const failed = isFinished && !!order.workOrderStatus.unsuccessfulReason;

  return (
    <span className="grid grid-cols-[auto,min-content,minmax(0,1fr)] grid-rows-1 items-center gap-x-2 gap-y-1 lg:gap-x-4">
      <h1
        className={ClientUtils.twClassNames(
          props.headingClassName,
          "col-start-1 my-auto"
        )}
      >
        Arbetsorder
      </h1>
      {!order.isAssignedThisOrder && !!order.assignedTo && (
        <h2
          className={ClientUtils.twClassNames(
            props.subheadingClassName,
            "col-start-1"
          )}
        >
          {order.assignedTo.handymanName}
        </h2>
      )}
      {order.isFinished && (
        <span
          className={ClientUtils.twClassNames(
            "col-start-2 row-span-2 row-start-1 my-auto"
          )}
        >
          <span className="sr-only">Ordern är klarmarkerad</span>
          <CompletedWorkOrderIcon
            size={25}
            status={failed ? "failure" : "success"}
            aria-hidden="true"
          />
        </span>
      )}
      {order.serviceContract && (
        <span
          className={ClientUtils.twClassNames(
            "row-span-2 row-start-1 my-auto mr-auto rounded-full ",
            order.isFinished && "col-start-3",
            !order.isFinished && "col-start-2"
          )}
        >
          <span className="sr-only">Avtalskund</span>
          <ServiceContractIcon size={25} aria-hidden="true" />
        </span>
      )}
    </span>
  );
};

export default HandymanWorkOrderMobileHeadings;
