import { IpisForm } from "@eljouren/domain";
import { motion } from "framer-motion";
import { PreChecklistWorkOrder } from "./PreChecklistComponent";
import { IpisButton } from "@ipis/client-essentials";

interface Props {
  checklist: IpisForm.ShellType;
  workOrder: PreChecklistWorkOrder;
  continueToChecklist: () => void;
}

const PreChecklistPreview = (props: Props) => {
  return (
    <motion.section
      /* 
        Fadein out
      */
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mx-auto flex h-full w-full max-w-screen-md flex-col gap-8 p-4 pb-8 pt-16"
    >
      <header className="text-dark-950">
        <h2 className="text-2xl font-bold text-dark-950">
          Hej {props.workOrder.endCustomer.name},
        </h2>
        <p className="text-sm">
          Vi har tagit emot din order och skulle älska om du kunde fylla i lite
          fler detaljer kring ditt ärende för att försäkra att allting går så
          smidigt och effektivt som möjligt.
        </p>
      </header>
      <section className="flex flex-col gap-1">
        <header>
          <h3 className="text-sm">Vad kan du förvänta dig?</h3>
        </header>
        <ol className="flex flex-col gap-2">
          {props.checklist.pages.map((page, index) => {
            return (
              <li
                key={`page-${page.id}-preview`}
                className="grid grid-cols-[auto,minmax(0,1fr)] gap-x-2 rounded bg-secondary-50 p-1 text-sm"
              >
                <span className="flex h-6 w-6 items-center justify-center rounded border border-black p-2">
                  {index + 1}
                </span>
                <span>{page.pageTitle}</span>
                {page.preparations && (
                  <p className="col-start-2 text-dark-700">
                    {page.preparations}
                  </p>
                )}
              </li>
            );
          })}
        </ol>
      </section>
      <footer className="mt-auto flex flex-col">
        <IpisButton label="Sätt igång" onClick={props.continueToChecklist} />
      </footer>
    </motion.section>
  );
};

export default PreChecklistPreview;
