import { motion } from "framer-motion";
import { ToastWithId } from "./Modal";
import Toast from "./Toast";

type ToastListProps = {
  toasts: ToastWithId[];
  closeToast: (toast: ToastWithId) => void;
};

const ToastList = (props: ToastListProps) => {
  return (
    <motion.ul
      className="fixed z-50 mt-8 flex max-w-[min(90vw,500px)] flex-col-reverse gap-2 justify-self-center"
      exit={{
        opacity: 0.5,
      }}
    >
      {props.toasts.map((toast) => {
        return (
          <Toast
            key={`toastWithId${toast.id}`}
            {...toast}
            closeToast={props.closeToast}
          />
        );
      })}
    </motion.ul>
  );
};

export default ToastList;
