import { useEffect, useState } from "react";

export function useDelayRendering(ms: number): boolean {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    if (shouldRender) {
      return;
    }
    const timeout = setTimeout(() => {
      setShouldRender(true);
    }, ms);

    return () => {
      clearTimeout(timeout);
    };
  }, [ms, shouldRender]);

  return shouldRender;
}
