import { LockClosedIcon } from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppDoorCodeIcon = (props: Props) => {
  const { className, ...rest } = props;

  /* 
    Should rather be keypad but HeroIcons doesn't have that icon.
  */
  return <LockClosedIcon {...rest} className={props.className} />;
};
export default withIconSize(AppDoorCodeIcon);
