import { IpisFormElement, IpisForm } from "@eljouren/domain";
import { IpisFormAnswerValidator } from "@eljouren/domain-utils";
import React from "react";
import { UseFormReturn } from "react-hook-form";

type TransformQuestion<T> = Omit<
  T,
  "richTextBody" | "helperText" | "note" | "placeholder"
> & {
  walkthroughHeading: string;
  walkthroughSubheading: string | (() => JSX.Element);
};

type TextQuestionType = TransformQuestion<IpisFormElement.TextInputType>;
type NumberQuestionType = TransformQuestion<IpisFormElement.NumberInputType>;
type RadioQuestionType = TransformQuestion<IpisFormElement.MultipleChoiceType>;
type RepeaterQuestionType = TransformQuestion<IpisFormElement.RepeaterType>;
type RichTextQuestionType = TransformQuestion<IpisFormElement.RichTextType>;
type YesNoQuestionType = TransformQuestion<IpisFormElement.YesNoType>;

export type WizardFormElement =
  | RichTextQuestionType
  | TextQuestionType
  | NumberQuestionType
  | RadioQuestionType
  | RepeaterQuestionType
  | YesNoQuestionType;
type WizardFormContextType = {
  hasGoneThroughWalkthrough: boolean;
  form: UseFormReturn<any>;
  isFocused: (el: WizardFormElement) => boolean;
  setFocus: (el: WizardFormElement, focused: boolean) => void;
  checklist: IpisForm.ShellType;
  validatedChecklistDo: IpisFormAnswerValidator;
};

export const WizardFormContext = React.createContext<WizardFormContextType>(
  {} as never
);

export type WizardFormConfig = WizardFormElement[];
