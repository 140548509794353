import { StarIcon as StarIconOutline } from "@heroicons/react/24/outline";
import { StarIcon as StarIconFill } from "@heroicons/react/24/solid";
import ClientUtils from "../../utils/ClientUtils";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "outline" | "fill";
}

const AppStarIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "fill") {
    return (
      <StarIconFill
        {...rest}
        className={ClientUtils.twClassNames("", props.className)}
      />
    );
  }

  return (
    <StarIconOutline
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default withIconSize(AppStarIcon);
