import ClientUtils from "../../../utils/ClientUtils";
interface Props {
  className?: string;
  withColor?: boolean;
}

const AiiLogo = (props: Props) => {
  return (
    <img
      className={ClientUtils.twClassNames(
        "rounded",
        //!props.className && "h-40 max-h-full rounded",
        props.className
      )}
      src={
        props.withColor ? "/logos/aii-logo-color.png" : "/logos/aii-logo.png"
      }
      data-testid="aii-logo"
      alt="Logga för Aii"
    />
  );
};

export default AiiLogo;
