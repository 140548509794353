import { BankIdTransformedCollectResponse } from "@eljouren/bank-id-schemas";
import { useEffect, useRef } from "react";
import { useLoading } from "../../../hooks/hooks";
import useQueryWrapper from "../../../hooks/use-query-wrapper";
import { useRepos } from "../../../hooks/use-repos";

export enum BankIdCollectFailedReason {
  START_FAILED,
  POST_PROCESSING_FAILED,
  UNKNOWN,
}

export enum BankIdCollectFlowStep {
  WAITING_FOR_APP_OPEN,
  SIGN_STARTED,
  SIGN_COMPLETE,
}

type FlowState = {
  hasFailed:true;
  failedReason: BankIdCollectFailedReason;
  step?: undefined;
} | {
  hasFailed: false;
  step: BankIdCollectFlowStep;
  failedReason?: undefined;
}



type Args = {
  sessionId: string;
  onSignComplete?: () => void;
  onSignCompleteTimeoutInMs?: number;
};

type Return = {
  isPending: boolean;
  isCompletedAndHandled: boolean;
  isCompletedAndUnhandled: boolean;
  startFailed: boolean;
  isFailed: boolean;
  signStarted: boolean;
  isError: boolean;
  cancel: () => Promise<void>;
  isCanceling: boolean;
  isWaitingForSign: boolean;
  isCurrentlySigning: boolean;
  data: BankIdTransformedCollectResponse.ClientSafeTypeWithHandledStatus | undefined;
  
  /* 
    This object essentially makes the above boolean flags unnecessary,
    but will be left in for backwards compatibility
  */
  flowState: FlowState;
};

export function useBankIdCollect(args: Args): Return {
  const { bankIdRepo } = useRepos();
  const collectRes = useQueryWrapper({
    queryKey: ["collect", args.sessionId],
    queryFn: async () => {
      const res = await bankIdRepo.collect({
        sessionId: args.sessionId,
      });
      return res;
    },
    refetchInterval(data, query) {
      const isCompleted = data && data.status !== "pending";
      if (isCompleted) {
        return false;
      }
      const isError = !!query.state.error;
      if (isError) {
        return false;
      }

      return 2000;
    },
  });

  const afterFinishedTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { isLoading: isCanceling, loadWhileAsync: loadCancel } = useLoading();

  const data = collectRes.data;
  const isPending = data?.status === "pending";
  const isWaitingForAppOpen = isPending && data.hintCode === "outstandingTransaction";
  const isCurrentlySigning = isPending && data.hintCode === "userSign";
  
  
  const isCompletedAndHandled =
    data?.status === "complete" && data.handledStatus.handled;
  const isCompletedAndUnhandled =
    data?.status === "complete" && !data.handledStatus.handled;
  const isFailed = data?.status === "failed";
  
  const signStarted = isCurrentlySigning || data?.status === "complete" || data?.status === "failed"
  const startFailed = isFailed && data.hintCode === "startFailed";
  const isError = !!collectRes.error;

  function buildFlowState(): FlowState {
    if (isFailed) {
      if (startFailed) {
        return {
          hasFailed: true,
          failedReason: BankIdCollectFailedReason.START_FAILED,
        };
      }

      return {
        hasFailed: true,
        failedReason: BankIdCollectFailedReason.UNKNOWN
      };
    }

    if (isCompletedAndUnhandled) {
      return {
        hasFailed: true,
        failedReason: BankIdCollectFailedReason.POST_PROCESSING_FAILED,
      };
    }

    if (isCompletedAndHandled) {
      return {
        hasFailed: false,
        step: BankIdCollectFlowStep.SIGN_COMPLETE,
      };
    }

    if (isWaitingForAppOpen) {
      return {
        hasFailed: false,
        step: BankIdCollectFlowStep.WAITING_FOR_APP_OPEN,
      };
    }

    if (signStarted) {
      return {
        hasFailed: false,
        step: BankIdCollectFlowStep.SIGN_STARTED,
      };
    }

    if (isCompletedAndHandled) {
      return {
        hasFailed: false,
        step: BankIdCollectFlowStep.SIGN_COMPLETE,
      };
    }

    return {
      hasFailed: true,
      failedReason: BankIdCollectFailedReason.UNKNOWN,
    };


  }

  async function cancel() {
    try {
      collectRes.cancel();

      if (!isPending) {
        return;
      }

      loadCancel(async () => {
        if (collectRes.data?.status === "pending") {
          await bankIdRepo.cancel({
            sessionId: args.sessionId,
          });
        }
      });
    } catch (er) {
      console.log("Failed to cancel bankid signing");
      throw er;
    }
  }
  const onSignComplete = args.onSignComplete;
  const onSignCompleteTimeoutInMs = args.onSignCompleteTimeoutInMs;

  useEffect(() => {
    const clear = () => {
      if (afterFinishedTimeoutRef.current) {
        clearTimeout(afterFinishedTimeoutRef.current);
      }
    };
    clear();
    if (isCompletedAndHandled && onSignComplete) {
      if (onSignCompleteTimeoutInMs) {
        afterFinishedTimeoutRef.current = setTimeout(() => {
          onSignComplete();
        }, onSignCompleteTimeoutInMs);
      } else {
        onSignComplete();
      }
    }

    return clear;
  }, [isCompletedAndHandled, onSignComplete, onSignCompleteTimeoutInMs]);

  const state = buildFlowState();
  console.log(state);

  return {
    isWaitingForSign: isWaitingForAppOpen,
    isError,
    isPending,
    isCompletedAndHandled,
    isCompletedAndUnhandled,
    isCurrentlySigning,
    isFailed,
    signStarted,
    cancel,
    isCanceling,
    startFailed,
    flowState: state,
    data,
  };
}
