"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.classNames = classNames;
exports.renderOne = renderOne;
function classNames() {
    var classNames = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classNames[_i] = arguments[_i];
    }
    return (classNames.filter(function (str) { return typeof str === "string"; }).join(" ") || undefined);
}
function renderOne(args, renderFunctions) {
    for (var _i = 0, renderFunctions_1 = renderFunctions; _i < renderFunctions_1.length; _i++) {
        var renderFunction = renderFunctions_1[_i];
        var element = renderFunction(args);
        if (element) {
            return element;
        }
    }
    return null;
}
