import ClientUtils from "../../../utils/ClientUtils";
import MyLink from "../../routing/MyLink";

type Base = {
  className?: string;
  src: string;
  title: string;
  subtitle: string;
  description: string;
};

type Props = Base & {
  type: "link";
  href: string;
};

const AppCard = (props: Props) => {
  return (
    <MyLink
      to={props.href}
      className={ClientUtils.twClassNames(
        //props.className,
        "grid h-full w-full max-w-[400px] grid-cols-1 grid-rows-[1.5fr,1fr] overflow-hidden rounded  bg-slate-100/50 shadow transition-all hover:shadow-lg"
      )}
    >
      <img
        src={props.src}
        alt={props.title}
        className="h-full w-full overflow-hidden object-cover"
      />
      <div className="flex h-full w-full flex-col overflow-hidden  p-4">
        <h2 className="text-dark-950">{props.title}</h2>
        <h3 className="text-dark-400">{props.subtitle}</h3>
        <p className="pt-8 text-dark-500"> {props.description}</p>
      </div>
    </MyLink>
  );
};

export default AppCard;
