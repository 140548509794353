import React, { useState } from "react";
import ClientUtils from "./../../../utils/ClientUtils";

import { useOnOutsideClick } from "../../../hooks/hooks";
import AppCaretIcon from "../../icons/AppCaretIcon";

export interface DropdownProps {
  className?: string;
  title: string | (() => React.ReactNode);
  children?: React.ReactNode;
  openTop?: boolean;
  nonRound?: boolean;
  buttonClassName?: string;
  absolute?: boolean;
  preventHideOnOutsideClick?: boolean;
  contentClassName?: string;
  noCaret?: boolean;
  customStyling?: boolean;
  transparentToggle?: boolean;
}

/* 
  Deprecated, use the A11yDropdown instead
*/
const Dropdown = (props: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  useOnOutsideClick(ref, () => {
    if (props.preventHideOnOutsideClick) {
      return;
    }

    setIsOpen(false);
  });

  /* 
ToDo: Aria-attributes
  */
  return (
    <div
      className={ClientUtils.twClassNames(
        "relative flex flex-col",

        props.openTop && "grid grid-rows-[minmax(0,1fr),auto]",
        !props.openTop && "grid grid-rows-[auto,minmax(0,1fr)]",

        props.className
      )}
      ref={ref}
    >
      {isOpen && props.openTop && (
        <span
          className={ClientUtils.twClassNames(
            props.contentClassName,
            props.absolute &&
              "absolute bottom-full z-10 rounded-t bg-brand-blue-300"
          )}
        >
          {props.children}
        </span>
      )}

      <button
        onClick={() => setIsOpen(!isOpen)}
        className={ClientUtils.twClassNames(
          props.buttonClassName,
          !props.customStyling &&
            "flex cursor-pointer items-center gap-2 text-black",
          !props.transparentToggle && "bg-brand-blue-300",
          !props.customStyling && !props.nonRound && "rounded",
          !props.customStyling &&
            !props.buttonClassName &&
            "w-full justify-center p-2 text-sm",
          !props.customStyling &&
            props.absolute &&
            isOpen &&
            !props.openTop &&
            "rounded-b-none",
          !props.customStyling &&
            props.absolute &&
            isOpen &&
            props.openTop &&
            "rounded-t-none"
        )}
      >
        <span className="flex gap-2">
          {typeof props.title === "string" ? props.title : props.title()}
        </span>
        {!props.noCaret && (
          <AppCaretIcon
            direction={props.openTop ? "up" : "down"}
            size={"md"}
            className={ClientUtils.twClassNames(
              "transition-all",
              isOpen && "rotate-180"
            )}
          />
        )}
      </button>
      {isOpen && !props.openTop && (
        <span
          className={ClientUtils.twClassNames(
            props.absolute &&
              "absolute top-full z-50 rounded-b bg-brand-blue-300",
            props.contentClassName
          )}
        >
          {props.children}
        </span>
      )}
    </div>
  );
};

export default Dropdown;
