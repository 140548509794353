"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrdersAndWorkingHoursMerger = void 0;
var DateHelper_1 = require("../dates/DateHelper");
var utils_1 = require("../general/utils");
var Scheduler_1 = require("./Scheduler");
var WorkOrdersAndWorkingHoursMerger = /** @class */ (function () {
    function WorkOrdersAndWorkingHoursMerger(props) {
        var _this = this;
        this.dict = utils_1.Utils.objectMap(props.workingHours, function (val) { return ({
            workingHours: val,
            workOrders: [],
        }); });
        props.workOrders.forEach(function (order) {
            var helper = new DateHelper_1.DateHelper(order.startDate);
            var timestamp = helper.startOfDay.date.getTime();
            if (timestamp in _this.dict) {
                _this.dict[timestamp].workOrders.push(order);
            }
            else {
                _this.dict[timestamp] = {
                    workingHours: undefined,
                    workOrders: [order],
                };
            }
        });
    }
    WorkOrdersAndWorkingHoursMerger.prototype.isOnlyDigits = function (str) {
        return /^\d+$/.test(str);
    };
    WorkOrdersAndWorkingHoursMerger.prototype.getNumberFromTimeStamp = function (str) {
        if (!this.isOnlyDigits(str)) {
            return false;
        }
        var asInt = parseInt(str);
        if (isNaN(asInt)) {
            return false;
        }
        return asInt;
    };
    WorkOrdersAndWorkingHoursMerger.prototype.getDateFromTimestamp = function (timestamp) {
        if (typeof timestamp === "string") {
            var asInt = this.getNumberFromTimeStamp(timestamp);
            if (!asInt) {
                return new Date(timestamp);
            }
            else {
                return new Date(asInt);
            }
        }
        else {
            return timestamp;
        }
    };
    WorkOrdersAndWorkingHoursMerger.prototype.getEntry = function (timestamp) {
        var date = this.getDateFromTimestamp(timestamp);
        var key = new DateHelper_1.DateHelper(date).startOfDay.date.getTime();
        if (key in this.dict) {
            return this.dict[key];
        }
        else {
            return { workingHours: undefined, workOrders: [] };
        }
    };
    WorkOrdersAndWorkingHoursMerger.prototype.getScheduleGaps = function (timestamp, options) {
        var _a;
        if (options === void 0) { options = {}; }
        var date = this.getDateFromTimestamp(timestamp);
        var entry = this.getEntry(timestamp);
        var wh = entry.workingHours;
        if ((wh === null || wh === void 0 ? void 0 : wh.status) === "offWork") {
            return false;
        }
        var schedule = wh !== null && wh !== void 0 ? wh : options.defaultWorkingHours;
        if (!schedule) {
            return false;
        }
        console.log({
            workOrders: entry.workOrders,
        });
        /*
            Since the default working hours only take the time and not the date into account,
            we need to set the date to the same date as the timestamp.
        */
        var normalisedStartOfSchedule = new DateHelper_1.DateHelper(schedule.start).setSameDateAs(date).date;
        var normalisedEndOfSchedule = new DateHelper_1.DateHelper(schedule.end).setSameDateAs(date).date;
        schedule.start = normalisedStartOfSchedule;
        schedule.end = normalisedEndOfSchedule;
        /*
        console.log({
          context: "WorkOrdersAndWorkingHoursMerger.getScheduleGaps",
          timestamp,
          options,
          wh,
          schedule,
          defaultWorkingHours: options.defaultWorkingHours,
        }); */
        var minGapLengthInMinutes = (_a = options.minGapLengthInMinutes) !== null && _a !== void 0 ? _a : 120;
        var scheduler = new Scheduler_1.Scheduler();
        var gaps = scheduler.findGaps({
            boundaries: schedule,
            currentEvents: entry.workOrders.map(function (wo) { return (__assign(__assign({}, wo), { type: "event", start: wo.startDate, end: wo.endDate })); }),
            minimumLengthOfValidGapInMs: minGapLengthInMinutes * 60 * 1000,
        }).gaps;
        if (gaps.length === 0) {
            return false;
        }
        return gaps;
    };
    return WorkOrdersAndWorkingHoursMerger;
}());
exports.WorkOrdersAndWorkingHoursMerger = WorkOrdersAndWorkingHoursMerger;
