namespace DevConsole {
  export function log(...data: any[]) {
    if (process.env.NODE_ENV === "development") {
      try {
        if (data.length === 1) {
          console.log(
            "%c" + JSON.stringify(data[0], null, 2),
            "color: green; font-weight: bold; font-size: 16px;"
          );
        } else {
          console.log(
            "%c" + JSON.stringify(data, null, 2),
            "color: green; font-weight: bold; font-size: 16px;"
          );
        }
      } catch (e) {
        console.log(...data);
        console.log(
          "%c Failed to log data in a formatted way. The previous console.log will only be logged in a dev environment.",
          "color: red; font-weight: bold; font-size: 16px;"
        );
      }
    }
  }
}

export default DevConsole;
