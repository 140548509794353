import ClientUtils from "./../../utils/ClientUtils";
import { motion } from "framer-motion";

import IpisFileIcon from "./IpisFileIcon";
import { IpisFileWithAltText } from "./SelectableFileGrid";

interface Props {
  className?: string;
  file: IpisFileWithAltText;
  contain?: boolean;
  zoomOnHover?: boolean;
}

const FileContent = (props: Props) => {
  const isNonHeicImage =
    props.file.mimeType.startsWith("image") &&
    !props.file.mimeType.includes("heic") &&
    !props.file.mimeType.includes("heif");

  const className = ClientUtils.twClassNames(
    "h-full w-full",
    props.contain ? "object-contain" : "object-cover",
    props.zoomOnHover && "hover:scale-105 transition-transform z-10",
    props.className
  );
  if (isNonHeicImage) {
    return (
      <motion.img
        className={className}
        src={props.file.src}
        alt={props.file.alt}
      />
    );
  }

  return (
    <span
      className={ClientUtils.twClassNames(
        props.className,
        "flex aspect-square h-full w-full flex-col items-center justify-center gap-4"
      )}
    >
      <IpisFileIcon size={100} mimeType={props.file.mimeType} />
    </span>
  );
};

export default FileContent;
