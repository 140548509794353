import { MotionProps, motion } from "framer-motion";
import ResursbankLogo from "../../../../components/external-brands/resursbank/ResursbankLogo";
import PaymentMethodForm from "../payment-method-components/PaymentMethodForm";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import MyDialog from "../../../../components/common/MyDialog";
import InvoiceOverview from "../payment-method-components/InvoiceOverview";
import { useState } from "react";

interface Props {
  className?: string;
  motionProps: MotionProps;
  style: React.CSSProperties;
}

const InvoiceFailedPaymentView = (props: Props) => {
  const [overviewDialogOpen, setOverviewDialogOpen] = useState(false);

  function toggleOverviewDialog() {
    setOverviewDialogOpen(!overviewDialogOpen);
  }

  function closeOverviewDialog() {
    setOverviewDialogOpen(false);
  }

  return (
    <>
      <motion.section
        className="mx-auto flex h-full w-full flex-col items-center gap-8 overflow-auto pt-8"
        {...props.motionProps}
        style={props.style}
      >
        <header className="flex max-w-screen-sm flex-col gap-x-8 md:grid md:grid-cols-[minmax(0,1fr),auto]">
          <h3>Resurs Bank kunde inte godkänna ditt köp.</h3>
          <p className="text-sm">
            Vid frågor om varför, vänligen kontakta Resurs kundservice på
            telefonnummer{" "}
            <a className="text-blue-400" href="tel:+46771112233">
              0771-11 22 33
            </a>
          </p>
          <figure className="col-start-2 row-span-2 row-start-1 my-auto ml-auto py-2">
            <ResursbankLogo width={120} />
          </figure>
        </header>
        <main className="flex max-w-screen-sm flex-col gap-2">
          <PaymentMethodForm
            omitUserDetails
            omitPaymentHeading
            className="gap-4"
            skeletonCount={2}
            afterSubmitButton={() => {
              return (
                <>
                  <AppButton
                    onClick={toggleOverviewDialog}
                    variant="outline-default"
                  >
                    Öppna sammanställning
                  </AppButton>
                  <p className="text-sm text-dark-gray">
                    Om du inte väljer ett alternativ så kommer vi automatiskt
                    skicka en faktura till dig inom 2 kalenderdagar.
                  </p>
                </>
              );
            }}
          >
            <header>
              <h4>Alternativ för att gå vidare</h4>
            </header>
          </PaymentMethodForm>
        </main>
      </motion.section>
      <MyDialog isOpen={overviewDialogOpen} cancel={closeOverviewDialog}>
        <section className="flex h-full w-full flex-col items-center gap-4 bg-off-white/80 p-4 backdrop-blur">
          <main className="w-full max-w-screen-md">
            <InvoiceOverview />
          </main>
          <footer className="flex w-full max-w-screen-md justify-end">
            <AppButton onClick={closeOverviewDialog} variant="outline-default">
              Stäng sammanställning
            </AppButton>
          </footer>
        </section>
      </MyDialog>
    </>
  );
};

export default InvoiceFailedPaymentView;
