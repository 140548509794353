import { ErrorLog, ErrorLogLevel } from "@eljouren/domain/build";
import { DateHelper, Utils } from "@eljouren/utils";
import { useState } from "react";
import AppCaretIcon from "../icons/AppCaretIcon";
import ClientUtils from "../../utils/ClientUtils";
import ReactJson from "react18-json-view";
import { ErrorLogTypeGroup } from "../../routes/admin/ErrorLogsRoute";
import AppPages from "../common/pages/AppPages";
import AppArrowIcon from "../icons/AppArrowIcon";

interface Props {
  typeGroup: ErrorLogTypeGroup;
}

const ErrorLogGroupTypeListItem = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  const log = props.typeGroup.errorLogs[pageIndex];
  const partialLog: Partial<ErrorLog.Type> = structuredClone(log);
  delete partialLog["publicId"];
  delete partialLog["createdAt"];
  delete partialLog["logLevel"];
  delete partialLog["permissionLevel"];

  const json = structuredClone(log.json);
  delete json["title"];
  partialLog.json = json;

  return (
    <li className="flex flex-col gap-3 rounded border p-2">
      <header
        className="grid w-full cursor-pointer grid-cols-[minmax(0,1fr),auto,auto] items-center gap-2"
        onClick={() => setExpanded(!expanded)}
      >
        <span className="grid w-full grid-cols-[auto,auto,auto,minmax(0,1fr)] items-center gap-2">
          <OriginIndicator origin={log.origin} />
          <LogLevelIndicator level={log.logLevel} />
          <span className="text-sm italic">
            x{props.typeGroup.errorLogs.length}
          </span>
          <h2 className="truncate text-base">{log.title}</h2>
        </span>
        {/* <h3 className="text-xs font-semibold text-dark-gray text-opacity-80">
          {props.errorLog.publicId}
        </h3> */}
        <span className="text-xs italic text-dark-gray text-opacity-80">
          {new DateHelper(log.createdAt).readableDateTimeWithSeconds}
        </span>
        <AppCaretIcon
          direction="down"
          className={ClientUtils.twClassNames(
            "col-start-3 row-span-1 row-start-1 my-auto",
            expanded && "rotate-180"
          )}
        />
      </header>
      {expanded && (
        <div className="grid grid-cols-1 grid-rows-[auto,minmax(0,1fr)] gap-2 overflow-hidden">
          <header
            className={ClientUtils.classNames(
              "flex items-center gap-2 text-sm",
              props.typeGroup.errorLogs.length === 1 && "opacity-0"
            )}
          >
            <button
              disabled={pageIndex === 0}
              onClick={() => setPageIndex(pageIndex - 1)}
            >
              <AppArrowIcon direction="left" />
            </button>
            <span>
              {pageIndex + 1} / {props.typeGroup.errorLogs.length}
            </span>
            <button
              disabled={pageIndex === props.typeGroup.errorLogs.length - 1}
              onClick={() => setPageIndex(pageIndex + 1)}
            >
              <AppArrowIcon direction="right" />
            </button>
          </header>
          <AppPages pageIndex={pageIndex} onlyMain mainGridRow={2}>
            <div className="flex whitespace-pre-wrap rounded bg-slate-300 p-4 text-black">
              <ReactJson src={partialLog} />
            </div>
          </AppPages>
        </div>
      )}
    </li>
  );
};

const LogLevelIndicator = (props: { level: ErrorLogLevel.Type }) => {
  const text = Utils.capitalize(props.level);

  return (
    <span
      className={ClientUtils.twClassNames(
        "rounded-full bg-dark-gray px-2 py-1 text-xs font-semibold text-off-white",
        props.level === "error" && "bg-red-500",
        props.level === "warn" && "bg-yellow-500",
        props.level === "info" && "bg-blue-500",
        props.level === "debug" && "bg-green-500"
      )}
    >
      {text}
    </span>
  );
};
const OriginIndicator = (props: { origin: ErrorLog.Type["origin"] }) => {
  const text = props.origin;

  return (
    <span
      className={ClientUtils.twClassNames(
        "rounded-full bg-dark-gray px-2 py-1 text-xs font-semibold text-off-white",
        text === "Web App" && "bg-brand-light-blue text-black",
        text === "File Server" && "bg-main-bg-dark"
      )}
    >
      {text}
    </span>
  );
};

export default ErrorLogGroupTypeListItem;
