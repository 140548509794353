import { IpisFormElement } from "@eljouren/domain";
import ChecklistDecoupledInformationQuestion from "./ChecklistDecoupledInformationQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.InformationType;
}

const ChecklistInformationQuestion = (props: Props) => {
  const q = props.question;

  return (
    <ChecklistDecoupledInformationQuestion
      question={q}
      className={props.className}
    />
  );
};

export default ChecklistInformationQuestion;
