import ClientUtils from "../../../../../utils/ClientUtils";

interface Props {
  className?: string;
  html: string;
}

const RichTextPreTag = (props: Props) => {
  // whitespace-pre-wrap text-xs text-dark-500
  return (
    <pre
      className={ClientUtils.classNames(
        "whitespace-pre-wrap break-words",
        props.className
      )}
      dangerouslySetInnerHTML={{ __html: props.html }}
    />
  );
};

export default RichTextPreTag;
