import { IpisFormElement } from "@eljouren/domain";
import { IpisRadioGroup, IpisSelect } from "@ipis/client-essentials";
import { motion } from "framer-motion";
import { UseFormReturn } from "react-hook-form";
import ClientUtils from "../../../../../utils/ClientUtils";

interface Props {
  className?: string;
  question: IpisFormElement.MultipleChoiceType;
  form: UseFormReturn<any>;
  disabled?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
}

const ChecklistDecoupledMultipleChoiceQuestion = (props: Props) => {
  const q = props.question;
  return (
    <motion.div className={ClientUtils.classNames(props.className)}>
      {q.renderAs === "always-visible" && (
        <IpisRadioGroup
          id={q.id}
          name={`${q.id}.value`}
          required={q.required}
          label={q.title}
          tooltip={q.tooltip}
          richTextDescription={q.description}
          richTextNote={q.note}
          form={props.form}
          disabled={props.disabled}
          options={q.options.map((option) => ({
            value: option.value,
            label: option.value,
          }))}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          allowOther={q.allowOther}
          otherName={`${q.id}.specification`}
        />
      )}
      {q.renderAs === "dropdown" && (
        <IpisSelect
          id={q.id}
          name={`${q.id}.value`}
          required={q.required}
          label={q.title}
          tooltip={q.tooltip}
          richTextDescription={q.description}
          richTextNote={q.note}
          form={props.form}
          disabled={props.disabled}
          options={q.options.map((option) => ({
            value: option.value,
            label: option.value,
          }))}
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          allowOther={q.allowOther}
          otherName={`${q.id}.specification`}
        />
      )}
    </motion.div>
  );
};

export default ChecklistDecoupledMultipleChoiceQuestion;
