import {
  FormElementAnswer,
  IpisFormElement,
  IpisFormElementOption,
} from "@eljouren/domain";
import {
  FormInputContext,
  IpisFormInputError,
  IpisTextArea,
} from "@ipis/client-essentials";
import { motion } from "framer-motion";
import { useContext, useEffect } from "react";
import { Controller, Path, UseFormReturn } from "react-hook-form";
import { useFormError } from "../../../../../hooks/form-hooks";
import ClientUtils from "../../../../../utils/ClientUtils";
import ChecklistImageUploadComponent from "../../ChecklistImageUploadComponent";
import { ChecklistFormValues } from "../../checklist-page/ChecklistPageContext";

interface Props {
  className?: string;
  question: IpisFormElement.ImageGroupType;
  form: UseFormReturn<any>;
}

const ChecklistDecoupledImageGroupQuestion = (props: Props) => {
  const currentValue = props.form.watch(props.question.id);
  const files = (currentValue?.images ??
    {}) as FormElementAnswer.ImageGroupFormAnswerType["images"];

  const ctx = useContext(FormInputContext);
  const commentName: Path<ChecklistFormValues> = `${props.question.id}.imageUploadComment`;
  const currentCommentValue = props.form.watch(commentName);
  const showComment = !ctx?.readOnly || !!currentCommentValue;

  useEffect(() => {
    if (currentValue === undefined) {
      const newValue: FormElementAnswer.ImageGroupFormAnswerType = {
        images: {},
        imageUploadComment: props.form.getValues(commentName),
      };
      props.form.setValue(props.question.id, newValue);
    }
  }, [currentValue, props.form, props.question.id, commentName]);

  return (
    <motion.div layout className="flex flex-col gap-12">
      {props.question.imagePrompts.map((imagePrompt) => {
        return (
          <ControlledImageUploadComponent
            key={imagePrompt.id}
            form={props.form}
            question={props.question}
            currentFiles={files}
            prompt={imagePrompt}
          />
        );
      })}
      {showComment && (
        <IpisTextArea
          label="Eventuella kommentarer till bilderna"
          form={props.form}
          id={commentName}
          name={commentName}
          required={false}
          placeholder="Om det är något du vill tillägga eller förklara, skriv det här."
          rows={3}
          maxLength={500}
        />
      )}
    </motion.div>
  );
};

const ControlledImageUploadComponent = (props: {
  form: UseFormReturn<any>;
  question: IpisFormElement.ImageGroupType;
  currentFiles: FormElementAnswer.ImageGroupFormAnswerType["images"];
  prompt: IpisFormElementOption.ImagePromptType;
}) => {
  const q = props.question;
  const prompt = props.prompt;
  const name = `${q.id}.images.${prompt.id}`;

  const error = useFormError({
    form: props.form,
    name: name,
  });

  return (
    <Controller
      control={props.form.control}
      name={name}
      render={(renderProps) => {
        return (
          <div
            tabIndex={0}
            onBlur={renderProps.field.onBlur}
            ref={renderProps.field.ref}
            className={ClientUtils.classNames(
              !!error && "focus:outline focus:outline-border-color"
            )}
          >
            <ChecklistImageUploadComponent
              fileUploadProps={{
                onChange: (files) => {
                  renderProps.field.onChange(files);
                },
                currentFiles: props.currentFiles[prompt.id] ?? [],
                maxFileNameLength: 100,
              }}
              id={prompt.id}
              prompt={prompt}
              key={prompt.id}
            />
            {error && (
              <IpisFormInputError
                error={error}
                humanReadableName={prompt.promptText}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default ChecklistDecoupledImageGroupQuestion;
