"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Checklist = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var Checklist;
(function (Checklist) {
    var BaseListItemSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        shortName: centralized_zod_1.z.string(),
        type: centralized_zod_1.z.enum(["yes/no", "text", "multipleChoice", "image"]),
        rows: centralized_zod_1.z.number().nullable(),
        sortOrder: centralized_zod_1.z.number().nullable(),
        answer: centralized_zod_1.z.union([centralized_zod_1.z.string(), centralized_zod_1.z.boolean()]).nullable(),
        comment: centralized_zod_1.z.string().nullable(),
        allowComment: centralized_zod_1.z.boolean(),
        required: centralized_zod_1.z.boolean(),
        options: centralized_zod_1.z.string().array().nullable(),
        imageId: centralized_zod_1.z.string().nullable(),
        _isAnswered: centralized_zod_1.z.boolean(),
        _answerValid: centralized_zod_1.z.boolean(),
    });
    var AnsweredListItemSchema = BaseListItemSchema.extend({
        _isAnswered: centralized_zod_1.z.literal(true),
        _answerValid: centralized_zod_1.z.boolean(),
        answerId: centralized_zod_1.z.string(),
    });
    var UnansweredListItemSchema = BaseListItemSchema.extend({
        _isAnswered: centralized_zod_1.z.literal(false),
        _answerValid: centralized_zod_1.z.literal(false),
        answerId: centralized_zod_1.z.string().nullable(),
    });
    Checklist.ListItemSchema = centralized_zod_1.z.union([
        AnsweredListItemSchema,
        UnansweredListItemSchema,
    ]);
    Checklist.SectionSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        sortOrder: centralized_zod_1.z.number(),
        totalNumberOfElements: centralized_zod_1.z.number(),
        totalNumberOfAnsweredElements: centralized_zod_1.z.number(),
        allAnswered: centralized_zod_1.z.boolean(),
        allRequiredAnswered: centralized_zod_1.z.boolean(),
        items: Checklist.ListItemSchema.array(),
    });
    Checklist.Schema = centralized_zod_1.z.object({
        allRequiredAnswered: centralized_zod_1.z.boolean(),
        allAnswered: centralized_zod_1.z.boolean(),
        totalNumberOfElements: centralized_zod_1.z.number(),
        totalNumberOfAnsweredElements: centralized_zod_1.z.number(),
        questions: centralized_zod_1.z.object({
            allRequiredAnswered: centralized_zod_1.z.boolean(),
            allAnswered: centralized_zod_1.z.boolean(),
            totalNumberOfElements: centralized_zod_1.z.number(),
            totalNumberOfAnsweredElements: centralized_zod_1.z.number(),
            sections: Checklist.SectionSchema.array(),
        }),
        images: centralized_zod_1.z.object({
            allRequiredAnswered: centralized_zod_1.z.boolean(),
            allAnswered: centralized_zod_1.z.boolean(),
            totalNumberOfElements: centralized_zod_1.z.number(),
            totalNumberOfAnsweredElements: centralized_zod_1.z.number(),
            items: Checklist.ListItemSchema.array(),
        }),
    });
})(Checklist || (Checklist = {}));
exports.Checklist = Checklist;
