"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderFailedReport = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderFailedReport;
(function (WorkOrderFailedReport) {
    WorkOrderFailedReport.Schema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            workOrderId: centralized_zod_1.z.string(),
            reason: centralized_zod_1.z.literal("other"),
            comment: centralized_zod_1.z.string().min(1).max(255),
        }),
        centralized_zod_1.z.object({
            workOrderId: centralized_zod_1.z.string(),
            // Regex for everything except 'other'
            reason: centralized_zod_1.z.string().regex(/^(?!other$).+$/),
            comment: centralized_zod_1.z.string().max(255).optional(),
        }),
    ]);
})(WorkOrderFailedReport || (WorkOrderFailedReport = {}));
exports.WorkOrderFailedReport = WorkOrderFailedReport;
