"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderPaymentSummary = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderPaymentSummary;
(function (WorkOrderPaymentSummary) {
    WorkOrderPaymentSummary.VatPercentageSchema = centralized_zod_1.z
        .number()
        .min(0)
        .max(1)
        .refine(function (value) {
        var test = /^\d+(\.\d{1,2})?$/.test(value.toString());
        return test;
    }, {
        message: "The number must have at most two decimal places",
    });
    WorkOrderPaymentSummary.LineItem = centralized_zod_1.z.object({
        name: centralized_zod_1.z.string(),
        quantity: centralized_zod_1.z.number(),
        unitPrice: centralized_zod_1.z.number(),
        totalPriceWithoutVAT: centralized_zod_1.z.number(),
        totalPriceWithVAT: centralized_zod_1.z.number(),
        vat: centralized_zod_1.z.number(),
        vatPercentageDecimal: WorkOrderPaymentSummary.VatPercentageSchema,
        type: centralized_zod_1.z.enum(["hour", "material"]),
        discountWithVat: centralized_zod_1.z.number().nullable(),
        discountWithoutVat: centralized_zod_1.z.number().nullable(),
    });
    WorkOrderPaymentSummary.DiscountSchema = centralized_zod_1.z.object({
        type: centralized_zod_1.z.enum(["rot", "green", "unknown"]),
        negativeAmount: centralized_zod_1.z.number(),
    });
    WorkOrderPaymentSummary.Schema = centralized_zod_1.z.object({
        workOrderId: centralized_zod_1.z.string(),
        totalWithVAT: centralized_zod_1.z.number(),
        totalWithoutVAT: centralized_zod_1.z.number(),
        vat: centralized_zod_1.z.number(),
        pennyRounding: centralized_zod_1.z.number(),
        priceToPay: centralized_zod_1.z.number(),
        priceWithVATWithoutDiscount: centralized_zod_1.z.number(),
        priceWithoutVATWithoutDiscount: centralized_zod_1.z.number(),
        lineItems: centralized_zod_1.z.object({
            totalCostOfHours: centralized_zod_1.z.number(),
            totalCostOfMaterials: centralized_zod_1.z.number(),
            hours: WorkOrderPaymentSummary.LineItem.array(),
            materials: WorkOrderPaymentSummary.LineItem.array(),
        }),
        discount: WorkOrderPaymentSummary.DiscountSchema.nullable(),
    });
})(WorkOrderPaymentSummary || (WorkOrderPaymentSummary = {}));
exports.WorkOrderPaymentSummary = WorkOrderPaymentSummary;
