import { IpisForm } from "@eljouren/domain";
import { IpisButton } from "@ipis/client-essentials";
import { useContext } from "react";
import ClientUtils from "../../../../../../utils/ClientUtils";
import KeyboardShortcutAppButton from "../../../../../__v2__/buttons/KeyboardShortcutAppButton";
import FormBuilderContext from "../../FormBuilderContext";
import AddQuestionListItem, {
  ChecklistQuestionFormType,
} from "./AddQuestionListItem";
import { FormEditorContext } from "./FormEditorContextProvider";

interface Props {
  className?: string;
  form: IpisForm.ShellType | undefined | null;
  canAddQuestions: boolean;
  setShowNewQuestionForm: (form: null | ChecklistQuestionFormType) => void;
}

const AddQuestionButtonsSidebar = (props: Props) => {
  return (
    <div
      className={ClientUtils.classNames(
        "flex flex-col justify-between p-4",
        !props.canAddQuestions && "pointer-events-none opacity-50"
      )}
    >
      <ul className="flex flex-col gap-2">
        <AddQuestionListItem
          index={0}
          type="text"
          onClick={props.setShowNewQuestionForm}
        />
        <AddQuestionListItem
          index={1}
          type="number"
          onClick={props.setShowNewQuestionForm}
        />
        <AddQuestionListItem
          index={2}
          type="multiple-choice"
          onClick={props.setShowNewQuestionForm}
        />
        <AddQuestionListItem
          index={5}
          type="yes/no"
          onClick={props.setShowNewQuestionForm}
        />
        <AddQuestionListItem
          index={3}
          type="image-group"
          onClick={props.setShowNewQuestionForm}
        />
        <AddQuestionListItem
          index={4}
          type="information"
          onClick={props.setShowNewQuestionForm}
        />
      </ul>
      <EventHandling form={props.form} />
    </div>
  );
};

const EventHandling = (props: {
  form: IpisForm.ShellType | undefined | null;
}) => {
  const builderCtx = useContext(FormBuilderContext);
  const editorContext = useContext(FormEditorContext);

  return (
    <footer className="relative col-span-2 flex w-full flex-col gap-4 border-t-2 py-2">
      <div className="flex gap-2">
        <KeyboardShortcutAppButton
          className="grow"
          buttonProps={{
            className: "w-full",
            label: "Ångra",
            variant: "tonal",
            onClick: () => {
              editorContext.undo();
            },
            disabled: !editorContext.canUndo,
          }}
          keyboardShortcut={{
            key: "z",
            ctrlKey: true,
          }}
        />

        <KeyboardShortcutAppButton
          className="grow"
          buttonProps={{
            className: "w-full",
            label: "Gör om",
            variant: "tonal",
            onClick: () => {
              editorContext.redo();
            },
            disabled: !editorContext.canRedo,
          }}
          keyboardShortcut={{
            key: "z",
            shiftKey: true,
            ctrlKey: true,
          }}
        />
      </div>
      <IpisButton
        variant="outlined"
        /* icon={{
          position: "trailing",
          type: ""
        }} */
        label="Kopiera formulär"
        disabled={!props.form}
        onClick={() => {
          if (!props.form) {
            return;
          }
          builderCtx.copyForm({ formId: props.form.id });
        }}
      />
      <IpisButton
        variant="outlined"
        icon={{
          position: "trailing",
          type: "trash",
        }}
        label="Ta bort formulär"
        disabled={!props.form}
        onClick={() => {
          if (!props.form) {
            return;
          }
          builderCtx.deleteForm();
        }}
      />
    </footer>
  );
};

export default AddQuestionButtonsSidebar;
