import { IconInfoSquareRounded } from "@tabler/icons-react";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppInformationIcon = (props: Props) => {
  const { className, ...rest } = props;

  return <IconInfoSquareRounded {...rest} className={props.className} />;
};
export default withIconSize(AppInformationIcon);
