import { MotionProps, motion } from "framer-motion";
import { useContext } from "react";
import AppExternalLinkIcon from "../../../../components/icons/AppExternalLinkIcon";
import { useBrandName, useBrandWebsite } from "../../../../hooks/brand-hooks";
import ClientUtils from "../../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext from "../CustomerWorkOrderInvoiceContext";

interface Props {
  motionProps: MotionProps;
  style: React.CSSProperties;
}

const InvoiceSuccessfulPaymentView = (props: Props) => {
  const brandName = useBrandName();
  const website = useBrandWebsite();

  const ctx = useContext(CustomerWorkOrderInvoiceContext);
  const status = ctx.syncRes.data?.savedStatus;

  let isInternal = false;
  let isActivelyChosenByCustomer = true;
  let additionalInfo: string | undefined = undefined;

  if (status?.initiated) {
    isInternal = status.paymentType.isInternal;
    isActivelyChosenByCustomer = status.activelyChosenByCustomer;
  }

  const outOfTime = isInternal && !isActivelyChosenByCustomer;

  if (outOfTime) {
    additionalInfo =
      "Tiden för att välja betalningsalternativ har gått ut. Vi kommer att skicka en faktura till dig inom kort.";
  } else if (isInternal) {
    additionalInfo = "Vi kommer att skicka en faktura till dig inom kort.";
  }

  return (
    <motion.section
      {...props.motionProps}
      style={props.style}
      className={ClientUtils.twClassNames(
        "mx-auto grid h-full w-full max-w-lg grid-cols-1 grid-rows-[min-content,min-content,min-content] gap-8"
      )}
    >
      <header className="flex max-w-lg flex-col items-center gap-2 xs:p-4">
        {!outOfTime && (
          <motion.h3
            initial={{
              y: 50,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
            }}
            transition={{
              duration: 1,
              delay: 0.2,
            }}
            className="text-3xl font-semibold"
          >
            Tack så mycket!
          </motion.h3>
        )}
        <motion.h4
          className={ClientUtils.classNames(
            "font-semibold text-dark-gray text-opacity-70",
            !outOfTime && "text-center"
          )}
          initial={{
            y: 50,
            opacity: 0,
          }}
          animate={{
            y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 0.3,
          }}
        >
          {additionalInfo ?? "Ditt ärende är nu avslutat."}
        </motion.h4>
      </header>
      <main className="mx-auto flex w-full max-w-lg justify-center p-2 xs:p-4">
        <motion.p
          initial={{
            //y: 50,
            opacity: 0,
          }}
          animate={{
            //y: 0,
            opacity: 1,
          }}
          transition={{
            duration: 1,
            delay: 1.5,
          }}
        >
          Tack för att du har valt att anlita <b>{brandName}</b>. Vi hoppas att
          du är nöjd och välkomnar dig åter!
        </motion.p>
      </main>
      <motion.footer
        className="mx-auto flex w-full max-w-sm flex-col pt-8"
        initial={{
          //y: 50,
          opacity: 0,
        }}
        animate={{
          //y: 0,
          opacity: 1,
        }}
        transition={{
          duration: 1,
          delay: 1.5,
        }}
      >
        <a
          className="flex items-center justify-center gap-2 rounded border bg-main-bg-light p-4 text-white"
          target="_blank"
          rel="noreferrer"
          href={website}
        >
          <AppExternalLinkIcon />
          <span>Gå till vår hemsida</span>
        </a>
      </motion.footer>
    </motion.section>
  );
};

export default InvoiceSuccessfulPaymentView;
