import { MotionProps, motion } from "framer-motion";
import InvoiceViewCustomerServiceFooter from "../payment-method-components/InvoiceViewCustomerServiceFooter";
import { InvoiceRouteView } from "../CustomerWorkOrderInvoiceContext";

interface Props {
  className?: string;
  motionProps: MotionProps;
  style: React.CSSProperties;
  view:
    | InvoiceRouteView.workOrderNotReadyToPay
    | InvoiceRouteView.paymentFlowNotSupported
    | InvoiceRouteView.outOfTime;
}

const InvoiceInvalidView = (props: Props) => {
  if (props.view === InvoiceRouteView.workOrderNotReadyToPay) {
    return (
      <motion.section
        className="mx-auto w-full max-w-screen-sm rounded border p-4 shadow sm:p-4 md:p-8"
        {...props.motionProps}
        style={props.style}
      >
        <h2 className="text-lg">Ärendet är inte redo att betalas</h2>
        <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
          <p className="flex text-sm md:text-base">
            Vi skickar ut ett SMS till dig när ärendet är redo.
          </p>
        </main>
        <InvoiceViewCustomerServiceFooter
          mainActionCallback={() => {
            window.location.reload();
          }}
          mainActionLabel="Ladda om"
        />
      </motion.section>
    );
  }

  if (props.view === InvoiceRouteView.outOfTime) {
    return (
      <motion.section
        className="mx-auto w-full max-w-screen-sm rounded border p-4 shadow sm:p-4 md:p-8"
        {...props.motionProps}
        style={props.style}
      >
        <h2 className="text-lg">Ärendet betalas genom faktura</h2>
        <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
          <p className="flex text-sm md:text-base">
            Tiden för att välja betalningsmetod har tyvärr gått ut. Vi kommer
            att skicka en faktura till dig när ärendet är klart. Du kan stänga
            den här sidan.
          </p>
        </main>
      </motion.section>
    );
  }

  return (
    <motion.section
      className="mx-auto w-full max-w-screen-sm rounded border p-4 shadow sm:p-4 md:p-8"
      {...props.motionProps}
      style={props.style}
    >
      <h2 className="text-lg">Ärendet betalas genom faktura</h2>
      <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
        <p className="flex text-sm md:text-base">
          Vi kommer att skicka en faktura till dig när ärendet är klart. Du kan
          stänga den här sidan.
        </p>
      </main>
    </motion.section>
  );
};

export default InvoiceInvalidView;
