"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderFinishedReport = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderFinishedReport;
(function (WorkOrderFinishedReport) {
    WorkOrderFinishedReport.Schema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            workOrderId: centralized_zod_1.z.string(),
            rebooking: centralized_zod_1.z.literal("yes"),
            description: centralized_zod_1.z.string().min(1).max(255),
            timeEstimateInHours: centralized_zod_1.z.number().positive(),
            newDateBooked: centralized_zod_1.z.literal(true),
            date: centralized_zod_1.z.union([
                centralized_zod_1.z.string(),
                centralized_zod_1.z.date().transform(function (date) { return date.toISOString(); }),
            ]),
        }),
        centralized_zod_1.z.object({
            workOrderId: centralized_zod_1.z.string(),
            rebooking: centralized_zod_1.z.literal("yes"),
            description: centralized_zod_1.z.string().min(1).max(255),
            timeEstimateInHours: centralized_zod_1.z.number().positive(),
            newDateBooked: centralized_zod_1.z.literal(false),
            date: centralized_zod_1.z
                .union([centralized_zod_1.z.string(), centralized_zod_1.z.date().transform(function (date) { return date.toISOString(); })])
                .optional(),
        }),
        centralized_zod_1.z.object({
            workOrderId: centralized_zod_1.z.string(),
            rebooking: centralized_zod_1.z.literal("no"),
            date: centralized_zod_1.z
                .union([centralized_zod_1.z.string(), centralized_zod_1.z.date().transform(function (date) { return date.toISOString(); })])
                .optional(),
            description: centralized_zod_1.z.string().optional(),
            newDateBooked: centralized_zod_1.z.boolean().optional(),
        }),
    ]);
})(WorkOrderFinishedReport || (WorkOrderFinishedReport = {}));
exports.WorkOrderFinishedReport = WorkOrderFinishedReport;
