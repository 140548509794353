import { DateHelper } from "@eljouren/utils";
import { isValid } from "date-fns";
import { AnimatePresence } from "framer-motion";
import React, { useContext } from "react";
import CalendarEvent from "../../_model/helpers/calendar/CalendarEvent";
import CalendarHelper from "../../_model/helpers/calendar/CalendarHelper";
import { TCalendarEvent } from "../../_model/helpers/calendar/types";
import { usePrevious } from "../../hooks/hooks";
import { useBundledSs } from "../../hooks/session-storage-hooks";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import { useBundledCustomSearchParamStateNoHistory } from "../../hooks/use-search-params";

import ClientUtils from "../../utils/ClientUtils";
import MyDialog from "../common/MyDialog";
import AppTextButton from "../common/buttons/AppTextButton";
import AppArrowIcon from "../icons/AppArrowIcon";
import AppCalendarIcon from "../icons/AppCalendarIcon";
import AppListIcon from "../icons/AppListIcon";
import AppSettingsIcon from "../icons/AppSettingsIcon";
import CalendarGridView from "./CalendarGridView";
import CalendarListView from "./CalendarListView";
import CalendarDayView from "./day-view/CalendarDayView";

export type TCalendarView = "grid" | "list";
export type TRenderCalendarEvent = (props: {
  event: CalendarEvent;
}) => JSX.Element;
export type ListViewFilter = {
  id: string;
  label: string;
} & (
  | {
      type: "checkbox";
      filter: (event: CalendarEvent) => boolean;
    }
  | {
      type: "text";
      filter: (event: CalendarEvent, text: string) => boolean;
    }
);

export const CalendarContext = React.createContext<{
  calendarHelper: CalendarHelper;
  pageIndex: number;
  setWeeksInPeriod: (weeks: number) => void;
  weeksInPeriod: number;
  setPageIndex: (page: number) => void;
  //selectedDay: TUseBundledStateReturn<DateHelper | null>;
  previouslySelectedDay: DateHelper | null | undefined;
  RenderDayViewItem: TRenderCalendarEvent | undefined;
  currentView: TCalendarView;
  isLoading: boolean;
}>({} as never);

interface Props {
  className?: string;
  /*
  Not yet supported
  */
  hideWeekNumbers?: boolean;
  events?: TCalendarEvent[];
  onIndexChanged(index: number): void;
  setWeeksInPeriod(weeks: number): void;
  loading?: boolean;
  weeksInPeriod?: number;
  RenderDayViewItem?: TRenderCalendarEvent;
  defaultView?: TCalendarView;
  pageIndex: number;
}

const Calendar = (props: Props) => {
  const view = useBundledSs<TCalendarView>(
    "calendarView",
    props.defaultView || "list"
  );

  /*
  The day shown on the "dayview"
  */
  const selectedDay =
    useBundledCustomSearchParamStateNoHistory<DateHelper | null>(
      /*
    "Dag" is "day" in swedish
    */
      "dag",
      null,
      {
        toString: (obj) => {
          return obj ? obj.date.getTime().toString() : null;
        },
        fromString: (str) => {
          if (!str) {
            return null;
          }
          const date = new Date(Number.parseInt(str));
          if (isValid(date)) {
            return new DateHelper(date);
          } else {
            return null;
          }
        },
      }
    );
  /*
  The previously selected day, although I'm not sure what this is used for
  */
  const previouslySelectedDay = usePrevious(selectedDay.value);

  /*
  This should be moved to state if we want to be able to change i.e. whether or not to show/hide week numbers,
  or reflect changes when the events get updated
  */
  const helper = new CalendarHelper(props.pageIndex, props.events, {
    weeksInPeriod: props.weeksInPeriod,
  });

  function toggleView() {
    if (view.value === "grid") {
      view.set("list");
    } else {
      view.set("grid");
    }
  }

  return (
    <CalendarContext.Provider
      value={{
        isLoading: !!props.loading,
        weeksInPeriod: props.weeksInPeriod || 2,
        setWeeksInPeriod: props.setWeeksInPeriod,
        setPageIndex: props.onIndexChanged,
        currentView: view.value,
        calendarHelper: helper,
        pageIndex: props.pageIndex,
        //selectedDay,
        previouslySelectedDay,
        RenderDayViewItem: props.RenderDayViewItem,
      }}
    >
      <div
        className={ClientUtils.twClassNames(
          "relative grid h-full grid-rows-[auto,minmax(0,1fr)]",
          props.className
        )}
      >
        <CalendarHeader
          goBack={() => selectedDay.set(null)}
          selectedDay={selectedDay.value}
          //onActiveDateChanged={props.onIndexChanged}
          view={view.value}
          toggleView={toggleView}
        />
        <AnimatePresence initial={false} exitBeforeEnter>
          {!selectedDay.value && view.value === "grid" && (
            <CalendarGridView
              selectDay={(day) => selectedDay.set(day)}
              key={"grid"}
              {...props}
            />
          )}
          {!selectedDay.value && view.value === "list" && (
            <CalendarListView key={"list"} />
          )}

          {selectedDay.value && (
            <CalendarDayView
              key={"day"}
              selectedDay={selectedDay.value}
              goBack={() => selectedDay.set(null)}
              {...props}
            />
          )}
        </AnimatePresence>
      </div>
    </CalendarContext.Provider>
  );
};

const CalendarHeader = (props: {
  goBack(): void;
  selectedDay: DateHelper | null;
  //onActiveDateChanged?(index: number): void;
  view: TCalendarView;
  toggleView(): void;
}) => {
  const ctx = useContext(CalendarContext);
  const isLg = useMediaQuery(TailwindBreakpoint.lg);
  const [showSettings, setShowSettings] = React.useState(false);

  function setWeeksInPeriod(val: number) {
    ctx.setWeeksInPeriod(val);
    setShowSettings(false);
  }

  function toggleShowSettings() {
    setShowSettings(!showSettings);
  }

  function goToNextPage() {
    changeIndexBy(1);
  }
  function goToPreviousPage() {
    changeIndexBy(-1);
  }

  function changeIndexBy(index: number) {
    ctx.setPageIndex(ctx.pageIndex + index);
  }

  return (
    <header className="relative flex w-full items-center justify-between gap-2 bg-bg-base-layer px-2 py-3">
      {props.selectedDay && (
        <AppTextButton
          className="flex items-center gap-2"
          onClick={props.goBack}
        >
          <AppArrowIcon direction="left" size={30} />
          Måndadsvy
        </AppTextButton>
      )}
      {!props.selectedDay && (
        <>
          <div className="mr-auto flex items-center gap-4">
            <AppTextButton onClick={goToPreviousPage}>
              <AppArrowIcon direction="left" size={30} />
            </AppTextButton>
            <span className="m-auto text-base font-bold ">
              {ctx.calendarHelper.label(isLg)}
            </span>
            <AppTextButton onClick={goToNextPage}>
              <AppArrowIcon direction="right" size={30} />
            </AppTextButton>

            <button onClick={toggleShowSettings}>
              <AppSettingsIcon />
            </button>
          </div>
          <AppTextButton
            onClick={props.toggleView}
            className="ml-auto flex items-center gap-2 px-2"
          >
            {props.view === "grid" && (
              <>
                <span className="sr-only xs:not-sr-only">Visa listvy</span>
                <AppListIcon />
              </>
            )}
            {props.view === "list" && (
              <>
                <span className="sr-only xs:not-sr-only">Visa kalendervy</span>
                <AppCalendarIcon />
              </>
            )}
          </AppTextButton>
        </>
      )}
      <MyDialog isOpen={showSettings}>
        <aside className="flex h-full w-full flex-col items-center gap-4 bg-white/90 p-2 pt-[25vh] md:px-4 md:pb-4 md:pt-[10vh]">
          <p className="flex w-full max-w-[250px] flex-col gap-2">
            <label htmlFor="calendarWeeksInPeriod">Visa antal veckor</label>
            <select
              className="p-2"
              onChange={(e) => {
                const val = Number.parseInt(e.target.value);
                setWeeksInPeriod(val);
              }}
              value={ctx.weeksInPeriod}
              id="calendarWeeksInPeriod"
            >
              <option value="1">1 vecka</option>
              <option value="2">2 veckor</option>
            </select>
          </p>
          <AppTextButton
            onClick={toggleShowSettings}
            className="w-full max-w-[250px] text-end text-dark-gray text-opacity-80"
          >
            Gå tillbaka
          </AppTextButton>
        </aside>
      </MyDialog>
    </header>
  );
};

export default Calendar;
