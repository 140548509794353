"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var google_hooks_1 = require("../../hooks/google-hooks");
var client_utils_1 = require("../../utils/client-utils");
var GoogleAddressInput = function (props) {
    var inputRef = (0, react_1.useRef)(null);
    var places = (0, google_hooks_1.useGooglePlaces)({
        apiKey: props.apiKey,
    });
    var className = props.className, onAddressSelected = props.onAddressSelected, apiKey = props.apiKey, rest = __rest(props, ["className", "onAddressSelected", "apiKey"]);
    (0, react_1.useEffect)(function () {
        if (!places || !window.google || !inputRef.current) {
            return;
        }
        var autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            fields: ["geometry", "formatted_address", "address_components"],
        });
        function parseAddressComponents(components) {
            var address = {
                city: "",
                country: "",
                street: "",
                postalCode: "",
            };
            var streetNumber = null;
            components.forEach(function (component) {
                if (component.types.includes("street_number")) {
                    streetNumber = component.long_name;
                }
                if (component.types.includes("route") ||
                    component.types.includes("street_address")) {
                    address.street = component.long_name;
                }
                if (component.types.includes("postal_code")) {
                    address.postalCode = component.long_name;
                }
                if (component.types.includes("locality") ||
                    component.types.includes("postal_town")) {
                    address.city = component.long_name;
                }
                if (component.types.includes("country")) {
                    address.country = component.long_name;
                }
            });
            if (streetNumber) {
                address.street = "".concat(address.street, " ").concat(streetNumber);
            }
            return address;
        }
        var listener = autocomplete.addListener("place_changed", function () {
            var _a, _b;
            var place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
                var formattedAddress = (_a = place.formatted_address) !== null && _a !== void 0 ? _a : "";
                var latitude = place.geometry.location.lat();
                var longitude = place.geometry.location.lng();
                var addressComponents = parseAddressComponents((_b = place.address_components) !== null && _b !== void 0 ? _b : []);
                var values = __assign({ formattedAddress: formattedAddress, latitude: latitude, longitude: longitude }, addressComponents);
                onAddressSelected === null || onAddressSelected === void 0 ? void 0 : onAddressSelected(values);
            }
            else {
                window.alert("Inga detaljer hittades för den här adressen");
            }
        });
        return function () {
            // Remove the listener when the component is unmounted
            window.google.maps.event.removeListener(listener);
        };
    }, [onAddressSelected, places]);
    return ((0, jsx_runtime_1.jsx)("span", { className: (0, client_utils_1.classNames)(props.className, "grid grid-cols-[minmax(0,1fr),auto] rounded"), children: (0, jsx_runtime_1.jsx)("input", __assign({}, rest, { 
            /*
              Override the ref intentionally
            */
            ref: inputRef, className: "w-full  px-4 py-2", type: "text", placeholder: "Skriv in address", defaultValue: props.defaultValue, onChange: function (e) {
                //check if input is focused
                var ifFocused = document.activeElement === e.target;
                if (!ifFocused) {
                    e.target.focus();
                }
            }, autoComplete: "off" })) }));
};
exports.default = GoogleAddressInput;
