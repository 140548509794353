"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdSignResponse = void 0;
var zod_1 = require("zod");
var BankIdSignResponse;
(function (BankIdSignResponse) {
    BankIdSignResponse.Schema = zod_1.z.object({
        orderRef: zod_1.z.string(),
        autoStartToken: zod_1.z.string(),
        qrStartToken: zod_1.z.string(),
        qrStartSecret: zod_1.z.string(),
    });
    BankIdSignResponse.ClientResponseSchema = zod_1.z.object({
        sessionId: zod_1.z.string(),
        autoStartToken: zod_1.z.string(),
    });
})(BankIdSignResponse || (BankIdSignResponse = {}));
exports.BankIdSignResponse = BankIdSignResponse;
