import { IconTextRecognition } from "@tabler/icons-react";
//import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppTextFieldIcon = (props: Props) => {
  const { className, ...rest } = props;

  return <IconTextRecognition {...rest} className={props.className} />;
};
export default withIconSize(AppTextFieldIcon);
