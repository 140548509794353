import {
  PaymentOption,
  PaymentResponse,
  WorkOrderPaymentStatus,
  WorkOrderPaymentSummary,
  WorkOrderPaymentSyncResult,
} from "@eljouren/domain";
import IWorkOrderPaymentRepo, {
  CancelPendingPaymentReturnType,
} from "./interfaces/IWorkOrderPaymentRepo";
import trpcClient from "../../trpc-setup";

export default class WorkOrderPaymentRepo implements IWorkOrderPaymentRepo {
  getWorkOrderPaymentSyncResult(args: {
    expectedStatus: WorkOrderPaymentSyncResult.ExpectedStatusType;
  }): Promise<WorkOrderPaymentSyncResult.Type> {
    return trpcClient.workOrderPayment.workOrderPaymentSyncResults.query(args);
  }
  async getWorkOrderPaymentStatus(): Promise<WorkOrderPaymentStatus.ClientSafeType> {
    return trpcClient.workOrderPayment.workOrderPaymentStatus.query();
  }
  async getWorkOrderPaymentSummary(): Promise<WorkOrderPaymentSummary.Type> {
    return trpcClient.workOrderPayment.workOrderPaymentSummary.query();
  }
  async initialiseWithPaymentOptions(args: { amount: number }): Promise<{
    sessionId: string;
    paymentOptions: PaymentOption.Type[];
  }> {
    return trpcClient.workOrderPayment.initialiseWithPaymentOptions.query(args);
  }
  async initiatePayment(args: {
    sessionId: string;
    paymentOptionId: string;
    customerFailUrl: string;
    customerSuccessUrl: string;
    simulatePayment: "success" | "fail" | "inspection" | null;
  }): ReturnType<IWorkOrderPaymentRepo["initiatePayment"]> {
    return trpcClient.workOrderPayment.initiatePayment.mutate(args);
  }
  async getPaymentInSession(): Promise<PaymentResponse.Type | null> {
    return trpcClient.workOrderPayment.getPaymentInSession.query();
  }
  async resetWorkOrderPaymentStatus(): Promise<void> {
    if (process.env.NODE_ENV !== "development") {
      throw new Error("resetWorkOrderPaymentStatus is only available in dev");
    }
    return trpcClient.workOrderPayment.resetWorkOrderPaymentStatus.mutate();
  }
  async cancelPendingPayment(): Promise<CancelPendingPaymentReturnType> {
    return trpcClient.workOrderPayment.cancelPendingPayment.mutate();
  }
}
