"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisToggle = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_hook_form_1 = require("react-hook-form");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var tailwind_hooks_1 = require("../../../../hooks/tailwind-hooks");
var client_utils_1 = require("../../../../utils/client-utils");
var IpisToggle = function (props) {
    var colors = (0, tailwind_hooks_1.useTailwindColors)();
    var ctx = (0, form_input_context_1.useFormInputContext)();
    if (!props.form) {
        throw new Error("IpisToggle must does not yet have support for custom controls and must be used with a RHF form object");
    }
    return ((0, jsx_runtime_1.jsx)("div", { className: "flex items-center gap-2", children: (0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { control: props.form.control, name: props.name, render: function (renderProps) {
                var isChecked = renderProps.field.value;
                return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [!!props.label && ((0, jsx_runtime_1.jsx)("label", { htmlFor: props.name, className: "sr-only", children: props.label })), (0, jsx_runtime_1.jsx)("input", __assign({ type: "checkbox", id: props.name, className: "sr-only" }, renderProps.field, { onChange: function (e) {
                                if (ctx === null || ctx === void 0 ? void 0 : ctx.readOnly) {
                                    return;
                                }
                                renderProps.field.onChange(e.target.checked);
                            }, readOnly: !!(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly) })), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { tabIndex: 0, "aria-hidden": true, className: (0, client_utils_1.classNames)("flex h-6 w-12 items-center rounded p-[2px]", !(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly) && "cursor-pointer"), initial: {
                                backgroundColor: isChecked
                                    ? // dark-900, 40% opacity
                                        "#3d3d3d66"
                                    : colors.dark[400],
                            }, animate: {
                                backgroundColor: isChecked
                                    ? // dark-900, 40% opacity
                                        "#3d3d3d66"
                                    : colors.dark[400],
                            }, onClick: function () {
                                if (ctx === null || ctx === void 0 ? void 0 : ctx.readOnly) {
                                    return;
                                }
                                renderProps.field.onChange(!isChecked);
                            }, onBlur: renderProps.field.onBlur, children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.span, { initial: {
                                    x: isChecked ? "calc(3rem - 100% - 4px)" : 0,
                                    backgroundColor: isChecked ? colors.cta : colors.dark[200],
                                }, animate: {
                                    x: isChecked ? "calc(3rem - 100% - 4px)" : 0,
                                    backgroundColor: isChecked ? colors.cta : colors.dark[200],
                                }, className: "flex h-5 w-5 rounded" }) })] }));
            } }) }));
};
exports.IpisToggle = IpisToggle;
