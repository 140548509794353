import ClientUtils from "./../../utils/ClientUtils";
import { motion } from "framer-motion";
import { useContext } from "react";
import { GlobalContext } from "../../top-level-contexts";

import FileContent from "./FileContent";
import IpisUploadedByIcon from "../icons/composed/IpisUploadedByIcon";
import { IpisFileWithAltText } from "./SelectableFileGrid";

/* 
  This really needs to be refactored.
  Made specifically to be used with the SelectableFileGrid component.
*/

interface Props {
  className?: string;
  file: IpisFileWithAltText;
  onClick?(): void;
  customDimensions?: boolean;
  selected?: boolean;
}

const GeneralPurposeFileGridItem = (props: Props) => {
  const isNonHeicImage =
    props.file.mimeType.startsWith("image") &&
    !props.file.mimeType.includes("heic") &&
    !props.file.mimeType.includes("heif");
  const authCtx = useContext(GlobalContext);
  const showUploadedByIcon = authCtx.signInState.signedInAs !== "customer";

  const { className, customDimensions, ...rest } = props;

  const name = props.file.name;

  return (
    <motion.li
      key={props.file.src}
      className={ClientUtils.twClassNames(
        className,
        "group grid max-w-3xl grid-cols-1 grid-rows-[minmax(0,1fr)] overflow-hidden rounded border-2",
        !props.customDimensions && "h-full w-full",
        !!props.onClick && "cursor-pointer",
        !isNonHeicImage && "border",
        !props.selected && "border-transparent",
        props.selected && "border-brand-blue-600"
      )}
      layout
      onClick={props.onClick}
      {...rest}
    >
      <motion.header
        className={ClientUtils.twClassNames(
          "z-20 col-start-1 row-start-1 mb-auto flex w-full flex-col gap-x-4 p-2 text-white lg:grid lg:grid-cols-[minmax(0,1fr),auto,auto]",
          "bg-main-bg-light/30"
        )}
      >
        <motion.span
          className={ClientUtils.twClassNames(
            "my-auto mb-auto block w-full max-w-full items-center truncate  text-base font-bold [text-shadow:_0_2px_0_black] lg:text-lg"
          )}
        >
          {name}
        </motion.span>
      </motion.header>
      <motion.main
        className={ClientUtils.twClassNames(
          "col-start-1 row-start-1 grid h-full w-full grid-cols-1 grid-rows-1 items-center justify-center overflow-hidden rounded"
        )}
      >
        <FileContent
          file={props.file}
          className={ClientUtils.twClassNames("col-start-1 row-start-1")}
          zoomOnHover
        />

        <span className="col-start-1 row-start-1 mr-2 mt-auto flex w-full flex-row-reverse gap-2 p-2">
          {showUploadedByIcon && (
            <span className="mr-auto flex h-14 w-14 items-center justify-center rounded-full bg-main-bg-light/50 text-white">
              <IpisUploadedByIcon
                size={30}
                uploaderSource={props.file.uploadedBy}
              />
            </span>
          )}
        </span>
      </motion.main>
    </motion.li>
  );
};

export default GeneralPurposeFileGridItem;
