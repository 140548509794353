import { IpisFormElement } from "@eljouren/domain";
import MyDialog from "../../../../../common/MyDialog";
import ChecklistImageGroupEditor from "../../input-builders/image-group/ChecklistImageGroupEditor";
import ChecklistInformationElementEditor from "../../input-builders/information/ChecklistInformationElementEditor";
import ChecklistMultipleChoiceInputEditor from "../../input-builders/multiple-choice-input/ChecklistMultipleChoiceInputEditor";
import ChecklistTextInputEditor from "../../input-builders/text-input/ChecklistTextInputEditor";
import ChecklistYesNoInputEditor from "../../input-builders/yes-no/ChecklistYesNoInputEditor";
import ChecklistNumericInputEditor from "../../input-builders/numeric-input/ChecklistNumericInputEditor";

interface Props {
  element: IpisFormElement.Type | null;
  close: () => void;
}

const EditQuestionFormDialog = (props: Props) => {
  return (
    <MyDialog isOpen={!!props.element} cancel={() => props.close()}>
      {(props.element?.typeOfQuestion === "text" ||
        props.element?.typeOfQuestion === "textarea") && (
        <ChecklistTextInputEditor
          closeForm={() => props.close()}
          question={props.element}
        />
      )}
      {props.element?.typeOfQuestion === "multiple-choice" && (
        <ChecklistMultipleChoiceInputEditor
          closeForm={() => props.close()}
          question={props.element}
        />
      )}
      {props.element?.typeOfQuestion === "image-group" && (
        <ChecklistImageGroupEditor
          closeForm={() => props.close()}
          question={props.element}
        />
      )}

      {props.element?.typeOfQuestion === "information" && (
        <ChecklistInformationElementEditor
          closeForm={() => props.close()}
          question={props.element}
        />
      )}
      {props.element?.typeOfQuestion === "yes/no" && (
        <ChecklistYesNoInputEditor
          closeForm={() => props.close()}
          question={props.element}
        />
      )}
      {props.element?.typeOfQuestion === "number" && (
        <ChecklistNumericInputEditor
          closeForm={() => props.close()}
          question={props.element}
        />
      )}
    </MyDialog>
  );
};

export default EditQuestionFormDialog;
