"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisForm = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var IpisFormPage_1 = require("./IpisFormPage");
var FormBoundaries_1 = require("./FormBoundaries");
var IpisForm;
(function (IpisForm) {
    IpisForm.FormConnection = centralized_zod_1.z.object({
        type: centralized_zod_1.z.literal("PRODUCT_PACKAGE"),
        productPackageId: centralized_zod_1.z.string(),
        productPackageName: centralized_zod_1.z.string(),
    });
    var FormType = centralized_zod_1.z.enum([
        "internal-form",
        "EXTERNAL_CUSTOMER_WORK_ORDER_PREPARATION_FORM",
    ]);
    var boundaries = FormBoundaries_1.IpisFormBoundaries.checklist;
    IpisForm.IpisFormName = centralized_zod_1.z
        .string()
        .min(boundaries.name.minLength)
        .max(boundaries.name.maxLength);
    var BaseSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        clientSideId: centralized_zod_1.z.string().uuid().nullable(),
        name: IpisForm.IpisFormName,
        type: FormType,
    });
    IpisForm.ShellSchema = BaseSchema.extend({
        hasAnswers: centralized_zod_1.z.boolean(),
        isInactive: centralized_zod_1.z.boolean(),
        pages: IpisFormPage_1.IpisFormPage.WithoutAnswersSchema.array().min(1),
        connection: IpisForm.FormConnection.nullable().optional(),
    });
    IpisForm.ValidatedSchema = BaseSchema.extend({
        pages: IpisFormPage_1.IpisFormPage.WithStateSchema.array().min(1),
    });
    IpisForm.FullyValidatedSchema = BaseSchema.extend({
        pages: IpisFormPage_1.IpisFormPage.FullyValidatedSchema.array().min(1),
    });
})(IpisForm || (IpisForm = {}));
exports.IpisForm = IpisForm;
