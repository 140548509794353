import ClientUtils from './../../utils/ClientUtils';
import { useContext } from "react";
import { DateHelper } from "@eljouren/utils";

import { CalendarContext } from "./Calendar";
import { WorkOrder } from "@eljouren/domain/build";

interface Props {
  className?: string;
  dayHelper: DateHelper;
  index: number;
  selectDay(day: DateHelper): void;
}

const CalendarDayGridMobileItem = (props: Props) => {
  const { calendarHelper } = useContext(CalendarContext);
  const { dayHelper } = props;

  /*
  Perhaps move into the helper class
    */
  const isFirstColumn = props.index % 7 === 0;
  const isFirstRow = props.index < 7;

  return (
    <div
      onClick={() => props.selectDay(props.dayHelper)}
      className={ClientUtils.twClassNames(
        "flex h-full w-full cursor-pointer flex-col border p-1 text-xs hover:bg-main-bg-light/20",
        !isFirstColumn && "border-l-transparent",
        !isFirstRow && "border-t-transparent"
      )}
      style={{
        gridColumn: calendarHelper.gui.getGridColumn(dayHelper.date),
        gridRow: calendarHelper.gui.getGridRow(dayHelper.date),
      }}
    >
      <span className="w-full text-xs font-semibold">
        {dayHelper.dayOfMonth}
      </span>
      <ul className="flex grow flex-col gap-1 overflow-hidden">
        {calendarHelper.events.for(dayHelper.date).map((event) => {
          const wo = event.props
            .attachedData as WorkOrder.HandymanWithPermissionsType;

          const { start, end } = event.props;
          const startHelper = new DateHelper(start);
          const endHelper = new DateHelper(end);
          return (
            <li
              key={event.props.id}
              className={ClientUtils.twClassNames(
                "flex flex-col items-center rounded border p-1 text-sm",
                wo.isFinished && "border-green-400",
                !!wo.serviceContract && "text-orange-600"
              )}
            >
              <span className="text-2xs italic">
                {startHelper.hourAndMinutesLabel}
              </span>
              <span className="text-xs leading-3">|</span>
              <span className="text-2xs italic">
                {endHelper.hourAndMinutesLabel}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CalendarDayGridMobileItem;
