import ClientUtils from './../../../../utils/ClientUtils';
import { useContext } from "react";
import BookingSuggestionDistanceAndDurationIndicator from "./BookingSuggestionDistanceAndDurationIndicator";
import BookingSuggestionSlot from "./BookingSuggestionSlot";
import BookingSuggestionSurroundingEvent from "./BookingSuggestionSurroundingEvent";
import SingleBookingSuggestionContext from "./SingleBookingSuggestionContext";

interface Props {
  className?: string;
}

const SingleBookingSuggestionMain = (props: Props) => {
  const ctx = useContext(SingleBookingSuggestionContext);
  const slot = ctx.slot;

  return (
    <main className={ClientUtils.twClassNames("", props.className)}>
      {/* 
        Add extra horizontal padding to counteract the margins used by the "SurroundingEvent" components.
      */}
      <div className="rounded-full px-6 py-4">
        {/* 
			Background gradient styled in App.css
		*/}
        <div className="booking-suggestion-timeline flex h-32 items-center justify-between py-8">
          <BookingSuggestionSurroundingEvent
            workOrder={slot.before.event}
            timing="before"
          />
          <BookingSuggestionDistanceAndDurationIndicator
            travelInfo={slot.before.travelInfo}
          />
          <BookingSuggestionSlot />
          <BookingSuggestionDistanceAndDurationIndicator
            travelInfo={slot.after.travelInfo}
          />
          <BookingSuggestionSurroundingEvent
            workOrder={slot.after.event}
            timing="after"
          />
        </div>
      </div>
    </main>
  );
};

export default SingleBookingSuggestionMain;
