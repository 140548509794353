"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useManipulateBackButtonStack = exports.useAddToBackButtonStack = void 0;
exports.useIsPreviousPageFromCurrentSite = useIsPreviousPageFromCurrentSite;
var back_button_stack_context_1 = require("../contexts/back-button-stack-context");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
/*
  Buggy!

  Needs to be used as an effect instead so that we can cleanup the stack when the component unmounts.
*/
var useAddToBackButtonStack = function (callback) {
    var stack = (0, back_button_stack_context_1.useBackButtonStack)();
    (0, react_1.useEffect)(function () {
        var remove = stack.push(callback);
        return function () {
            remove();
        };
    });
};
exports.useAddToBackButtonStack = useAddToBackButtonStack;
var useManipulateBackButtonStack = function () {
    var stack = (0, back_button_stack_context_1.useBackButtonStack)();
    function handle() {
        return __awaiter(this, arguments, void 0, function (args) {
            var shouldGoBack, callback;
            if (args === void 0) { args = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!stack.beforeBackIsEmpty()) return [3 /*break*/, 2];
                        return [4 /*yield*/, stack.popBeforeBack()()];
                    case 1:
                        shouldGoBack = _a.sent();
                        if (!shouldGoBack) {
                            // We've handled the back button press, so we don't want to go back.
                            return [2 /*return*/, true];
                        }
                        _a.label = 2;
                    case 2:
                        if (!stack.isEmpty()) {
                            callback = stack.pop();
                            if (args.onlyHandleBeforeBack) {
                                return [2 /*return*/, false];
                            }
                            callback();
                            return [2 /*return*/, true];
                        }
                        return [2 /*return*/, false];
                }
            });
        });
    }
    return { handle: handle, stack: stack };
};
exports.useManipulateBackButtonStack = useManipulateBackButtonStack;
function useIsPreviousPageFromCurrentSite() {
    var location = (0, react_router_dom_1.useLocation)();
    var searchParams = new URLSearchParams(location.search);
    var internalNavigation = searchParams.get("internalNavigation");
    // If the internalNavigation flag is set, return true
    if (internalNavigation === "true") {
        return true;
    }
    var state = location.state;
    var previousLocation = state === null || state === void 0 ? void 0 : state.previousLocation;
    // If the previousLocation is available in state, compare origins
    if (previousLocation) {
        var currentOrigin = window.location.origin;
        var previousOrigin = new URL(previousLocation, currentOrigin).origin;
        // Return true if the origins match, otherwise return false
        return currentOrigin === previousOrigin;
    }
    // Return false if no previousLocation is available
    return false;
}
