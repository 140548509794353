"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderContactInfo = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderContactInfo;
(function (WorkOrderContactInfo) {
    WorkOrderContactInfo.Schema = centralized_zod_1.z.object({
        fullName: centralized_zod_1.z.string(),
        phone: centralized_zod_1.z.string(),
        phone2: centralized_zod_1.z.string().optional(),
        email: centralized_zod_1.z.string(),
    });
})(WorkOrderContactInfo || (WorkOrderContactInfo = {}));
exports.WorkOrderContactInfo = WorkOrderContactInfo;
