import { useEffect, useRef } from "react";
import {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  useMutation,
  useQueryClient,
} from "react-query";
import useQueryWrapper, { UseQueryWrapperResult } from "./use-query-wrapper";

type OptimisticUpdateOptions<T> =
  | {
      optimisticUpdate?: T;
      mutableOptimisticUpdate?: undefined;
      immutableOptimisticUpdate?: undefined;
    }
  | {
      optimisticUpdate?: undefined;
      mutableOptimisticUpdate?: (oldData: T | undefined) => void;
      immutableOptimisticUpdate?: undefined;
    }
  | {
      optimisticUpdate?: undefined;
      mutableOptimisticUpdate?: undefined;
      immutableOptimisticUpdate?: (oldData: T | undefined) => T;
    };

type MutationOptions<T> = OptimisticUpdateOptions<T> & {
  onSettled?: () => void;
  onError?: (err: unknown) => void;
};
type MutateArgs<T> = MutationOptions<T> & {
  callback: () => Promise<any>;
};

type MutateDebouncedArgs<T, Args> = {
  mutationKey: string;
  args: Args;
  callback: (args: Args[]) => Promise<any>;
  debounceTime?: number;
  options?: MutationOptions<T>;
};

export type UseMutableQueryResult<T> = {
  isLoading: boolean;
  query: UseQueryWrapperResult<T, unknown>;
  isInMutationProcess: boolean;
  mutation: Omit<
    UseMutationResult<unknown, unknown, unknown, unknown>,
    "mutate" | "mutateAsync"
  >;
  mutate: (args: MutateArgs<T>) => Promise<void>;
  mutateDebounced: <Args>(args: MutateDebouncedArgs<T, Args>) => Promise<void>;
  getCurrentData: () => T | undefined;
};

function useMutableQuery<T>(
  options: UseQueryOptions<T> & { queryKey: QueryKey }
): UseMutableQueryResult<T> {
  function saveOptimisticUpdate(data: T) {
    queryClient.setQueryData(options.queryKey, data);
    lastOptimisticUpdateRef.current = { data, date: new Date() };
  }
  const mutationCountRef = useRef(0);

  const queryClient = useQueryClient();
  const query = useQueryWrapper<T>({
    ...options,
    queryFn: options.queryFn
      ? async (ctx) => {
          if (mutationCountRef.current > 0 && lastOptimisticUpdateRef.current) {
            return lastOptimisticUpdateRef.current.data;
          }

          const data = await options.queryFn!(ctx);
          return data;
        }
      : undefined,
  });
  const lastOptimisticUpdateRef = useRef<{ data: T; date: Date } | undefined>(
    undefined
  );
  /* 
    For use in timeouts
  */
  const currentDataRef = useRef<T | undefined>(undefined);

  useEffect(() => {
    currentDataRef.current = query.data;
  }, [query.data]);

  function startMutation() {
    mutationCountRef.current++;
  }

  function endMutation() {
    mutationCountRef.current--;
  }

  const isInMutationProcess = mutationCountRef.current > 0;
  const mutation = useMutation(
    async (args: {
      callback: () => Promise<any>;
      mutationOptions?: MutationOptions<T>;
    }) => {
      const res = await args.callback();
      return res;
    },
    {
      onMutate: (args) => {
        startMutation();
        const mutateOptions = args.mutationOptions;
        handleOptimisticUpdate(mutateOptions);
      },

      onSettled: async (_, __, args) => {
        if (args.mutationOptions?.onSettled) {
          args.mutationOptions.onSettled();
        }
        endMutation();
        await query.refetch({
          throwOnError: false,
        });
      },
      onError: (err, args) => {
        if (args.mutationOptions?.onError) {
          args.mutationOptions.onError(err);
        }
      },
    }
  );

  function getCurrentData() {
    const data = currentDataRef.current;
    if (data !== undefined) {
      //Copy
      return structuredClone(data);
    }
    return undefined;
  }

  async function mutate(args: MutateArgs<T>): Promise<any> {
    queryClient.cancelQueries({ queryKey: options.queryKey });
    const res = await mutation.mutateAsync({
      callback: args.callback,
      mutationOptions: args,
    });

    return res;
  }

  function handleOptimisticUpdate(
    mutateOptions: MutationOptions<T> | undefined
  ) {
    if (mutateOptions?.optimisticUpdate) {
      saveOptimisticUpdate(mutateOptions.optimisticUpdate);
    } else if (mutateOptions?.mutableOptimisticUpdate) {
      const oldData = getCurrentData();
      mutateOptions.mutableOptimisticUpdate(oldData as T | undefined);
      saveOptimisticUpdate(oldData as T);
    } else if (mutateOptions?.immutableOptimisticUpdate) {
      const oldData = getCurrentData();
      const newData = mutateOptions.immutableOptimisticUpdate(
        oldData as T | undefined
      );

      saveOptimisticUpdate(newData);
    }
  }

  const mutationDebounceRef = useRef<{
    [mutationKey: string]: {
      timeout: NodeJS.Timeout;
      accumulator: any[];
    };
  }>({});

  /* 
    This won't catch errors properly
  */
  async function mutateDebounced<Args>(args: MutateDebouncedArgs<T, Args>) {
    const current = mutationDebounceRef.current;
    let accumulator: T[] = [];
    if (args.mutationKey in current) {
      clearTimeout(current[args.mutationKey].timeout);
      accumulator = current[args.mutationKey].accumulator;
    }

    const timeout = setTimeout(async () => {
      const element = current[args.mutationKey];
      delete current[args.mutationKey];
      const options = { ...args.options } as any;
      // Delete optimistic update info as that is already handled
      delete options.optimisticUpdate;
      delete options.mutableOptimisticUpdate;
      delete options.immutableOptimisticUpdate;

      await mutate({
        callback: async () => {
          await args.callback(element.accumulator);
        },
        ...options,
      });
    }, args.debounceTime ?? 1000);

    current[args.mutationKey] = {
      timeout,
      accumulator: [...accumulator, args.args],
    };

    mutationDebounceRef.current = current;

    handleOptimisticUpdate(args.options);
  }

  const { mutate: ignoredMutate, ...rest } = mutation;

  return {
    query,
    mutation: rest,
    isInMutationProcess,
    isLoading:
      mutation.isLoading || query.isLoading || query.isManuallyRefetching,
    mutate,
    mutateDebounced,
    getCurrentData,
  };
}

export default useMutableQuery;
