"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUrl_UploadInput = void 0;
var zod_1 = require("zod");
var UploaderSource_1 = require("../../../shared-schemas/UploaderSource");
var FileTag_1 = require("../../domain/FileTag");
var SignUrl_UploadInput;
(function (SignUrl_UploadInput) {
    SignUrl_UploadInput.Schema = zod_1.z.object({
        recordId: zod_1.z.string(),
        uploaderSource: UploaderSource_1.UploaderSource.Schema,
        signedInId: zod_1.z.string().nullable(),
        tag: FileTag_1.FileTag.Schema.optional(),
    });
})(SignUrl_UploadInput || (SignUrl_UploadInput = {}));
exports.SignUrl_UploadInput = SignUrl_UploadInput;
