import { useContext } from "react";
import withStaffGuid from "../../components/auth/hocs/withStaffGuid";
import FormBuilder from "../../components/checklist/v2/builder/FormBuilder";
import { GlobalContext } from "../../top-level-contexts";
import IpisPageWithLogo from "../../components/__v2__/layout-v2/IpisPageWithLogo";

const StaffFormBuilderRoute = () => {
  const { signInState } = useContext(GlobalContext);
  if (signInState.signedInAs !== "staff") {
    return <></>;
  }

  const name = `${signInState.firstName} ${signInState.lastName}`;

  return (
    <IpisPageWithLogo
      headerProps={{ heading: "Checklistebyggare", subHeading: name }}
    >
      <FormBuilder />
    </IpisPageWithLogo>
  );
};

export default withStaffGuid(StaffFormBuilderRoute);
