import React from "react";
import ClientUtils from "./../../../utils/ClientUtils";

import AppArrowIcon from "../../icons/AppArrowIcon";
import AppTextButton from "../buttons/AppTextButton";

interface Props {
  className?: string;
  canGoBack: boolean;
  canGoForward: boolean;
  pageIndex: number;
  maxPageIndex: number;
  onGoBack(e: React.MouseEvent): void;
  onGoForward(e: React.MouseEvent): void;
}

const PaginationControls = (props: Props) => {
  return (
    <div
      className={ClientUtils.twClassNames(
        "flex items-center gap-2",
        props.className
      )}
    >
      <AppTextButton onClick={props.onGoBack} disabled={!props.canGoBack}>
        <AppArrowIcon direction="left" size={30} />
      </AppTextButton>
      <span className="min-w-[45px] text-center font-bold tracking-widest">
        {props.pageIndex + 1} / {props.maxPageIndex + 1}
      </span>
      <AppTextButton onClick={props.onGoForward} disabled={!props.canGoForward}>
        <AppArrowIcon direction="right" size={30} />
      </AppTextButton>
    </div>
  );
};

export default PaginationControls;
