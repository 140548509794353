"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileRepoGetFilesResponse = void 0;
var zod_1 = require("zod");
var FileErrorReason_1 = require("./FileErrorReason");
var IpisFile_1 = require("../file/IpisFile");
var FileRepoGetFilesResponse;
(function (FileRepoGetFilesResponse) {
    var Success = zod_1.z.object({
        successState: zod_1.z.literal("complete-success"),
        files: IpisFile_1.IpisFile.WithMetaSchema.array(),
    });
    var Failure = zod_1.z.object({
        successState: zod_1.z.literal("failure"),
        reason: FileErrorReason_1.FileErrorReason.Schema,
        fileServiceJson: zod_1.z.record(zod_1.z.string(), zod_1.z.unknown()).optional(),
        error: zod_1.z.unknown().optional(),
    });
    FileRepoGetFilesResponse.Schema = zod_1.z.discriminatedUnion("successState", [
        Success,
        Failure,
    ]);
})(FileRepoGetFilesResponse || (FileRepoGetFilesResponse = {}));
exports.FileRepoGetFilesResponse = FileRepoGetFilesResponse;
