"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandymanWorkingHours = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var HandymanWorkingHours;
(function (HandymanWorkingHours) {
    HandymanWorkingHours.ReportedSchema = centralized_zod_1.z.object({
        status: centralized_zod_1.z.literal("reported"),
        //startOfDay: z.date(),
        start: centralized_zod_1.z.date(),
        end: centralized_zod_1.z.date(),
    });
    HandymanWorkingHours.OffWorkSchema = centralized_zod_1.z.object({
        status: centralized_zod_1.z.literal("offWork"),
        start: centralized_zod_1.z.date(),
        end: centralized_zod_1.z.date(),
        //startOfDay: z.date(),
    });
    HandymanWorkingHours.Schema = centralized_zod_1.z.discriminatedUnion("status", [
        HandymanWorkingHours.ReportedSchema,
        HandymanWorkingHours.OffWorkSchema,
    ]);
    HandymanWorkingHours.DictIndexedByStartOfDaySchema = centralized_zod_1.z.record(centralized_zod_1.z.string(), HandymanWorkingHours.Schema);
})(HandymanWorkingHours || (HandymanWorkingHours = {}));
exports.HandymanWorkingHours = HandymanWorkingHours;
