"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageHeader = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var brand_hooks_1 = require("../../hooks/brand-hooks");
var client_utils_1 = require("../../utils/client-utils");
var PageHeader = function (props) {
    var base = "#103236";
    var from = "#72cbd6";
    var to = base;
    var isOnline = !props.isOffline;
    var theme = (0, brand_hooks_1.useExternalBrandTheme)().externalBrandTheme;
    var loading = props.loading;
    var key;
    if (loading) {
        key = "loadingPageHeaderSmall";
    }
    else {
        key = "staticPageHeaderSmall";
    }
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.header, { id: "page-header", className: (0, client_utils_1.classNames)("relative min-h-[65px] items-center gap-x-2 gap-y-1 p-1 py-2", "bg-accent text-light-background", !isOnline && "pb-12", props.className), style: theme
            ? {
                background: theme.headerBackgroundColor,
                color: theme.headerTextColor,
            }
            : undefined, initial: loading
            ? {
                background: "linear-gradient(to bottom, transparent 4px, ".concat(base, " 4px),linear-gradient(to right, ").concat(from, " 0%, ").concat(to, " 1%)"),
            }
            : undefined, animate: loading
            ? {
                background: "linear-gradient(to bottom, transparent 4px, ".concat(base, " 4px), linear-gradient(to right, ").concat(from, " 100%, ").concat(to, " 100%)"),
            }
            : undefined, transition: loading
            ? {
                repeat: Infinity,
                repeatDelay: 0.8,
                duration: 0.8,
            }
            : undefined, children: !isOnline && ((0, jsx_runtime_1.jsx)("span", { className: "absolute bottom-0 left-0 col-span-3 flex w-full justify-center bg-error px-4 py-1 text-sm text-light-background", children: "Ingen internetuppkoppling" })) }, key));
};
exports.PageHeader = PageHeader;
