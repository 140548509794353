import React from "react";
import withSalesGuid from "../../../components/auth/hocs/withSalesGuid";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import { WorkOrder } from "@eljouren/domain";

import SalesTeamWorkOrderPage from "./SalesTeamWorkOrderPage";
import { useQuery, UseQueryResult } from "react-query";
import DecoupledWorkOrderFileContextProvider from "../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";

export const SalesTeamWorkOrderRouteContext = React.createContext<{
  orderRes: UseQueryResult<WorkOrder.CustomerType>;
  order: WorkOrder.CustomerType;
}>({} as never);

const SalesTeamWorkOrderRoute = () => {
  const { workOrderRepo } = useRepos();
  const res = useQuery("workOrderByStaffGuid", () =>
    workOrderRepo.getByStaffGuid()
  );

  const showError = res.isError || (!res.isLoading && !res.data);

  return (
    <LoadingContent
      loading={res.isFetching && !res.data}
      renderContentSeparately
    >
      {showError && (
        <SomethingWentWrong
          error={res.error}
          description="Denna order går inte att hämta just nu, vilket förmodligen betyder att den inte finns."
        />
      )}
      {!showError && res.data && (
        <SalesTeamWorkOrderRouteContext.Provider
          value={{ orderRes: res, order: res.data }}
        >
          <DecoupledWorkOrderFileContextProvider
            as="salesTeam"
            workOrder={res.data}
          >
            <SalesTeamWorkOrderPage />
          </DecoupledWorkOrderFileContextProvider>
        </SalesTeamWorkOrderRouteContext.Provider>
      )}
    </LoadingContent>
  );
};

export default withSalesGuid(SalesTeamWorkOrderRoute);
