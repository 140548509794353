import { BankIdSignResponse } from "@eljouren/bank-id-schemas/build";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import withCustomerGuid from "../../../components/auth/hocs/withCustomerGuid";
import Logo from "../../../components/common/brand/Logo";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import BankIdSigning from "../../../components/external-brands/bank-id/BankIdSigning";
import AppPage from "../../../components/layout/AppPage";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useLoading } from "../../../hooks/hooks";
import useMutableQuery from "../../../hooks/use-mutatable-query";
import useQueryWrapper from "../../../hooks/use-query-wrapper";
import { useRepos } from "../../../hooks/use-repos";
import ClientUtils from "./../../../utils/ClientUtils";
import OrderLineReviewListOverview from "./OrderLineReviewListOverview";

const CustomerMaterialReviewListRoute = () => {
  const { workOrderRepo } = useRepos();
  const { isLoading: isSigning, loadWhilePromise } = useLoading();
  const [isSuccessful, setIsSuccessful] = useState(false);

  const listRes = useMutableQuery({
    queryKey: ["customerMaterialReviewList", isSuccessful],
    queryFn: () => {
      return workOrderRepo.getMaterialReviewList();
    },
    // 30 seconds
    staleTime: 1000 * 30,
  });

  const workOrderRes = useQueryWrapper({
    queryKey: ["customerWorkOrder"],
    queryFn: () => {
      return workOrderRepo.getByCustomerGuid();
    },
  });

  const [signResponse, setSignResponse] =
    useState<BankIdSignResponse.ClientResponseType | null>(null);

  function onCancelAndRestart() {
    setIsSuccessful(false);
    setSignResponse(null);
    listRes.query.refetch();
  }

  function onSuccessfulSign() {
    setIsSuccessful(true);
  }

  const listIsLoading = listRes.isLoading;
  const list = listRes.query.data?.materialReviewListExists
    ? listRes.query.data.data
    : false;
  const workOrder = workOrderRes.data;
  const successfulButStillAListToSign =
    isSuccessful && !!list && !listIsLoading;

  const isError = listRes.query.isError || workOrderRes.isError;

  async function onSign() {
    try {
      if (!list) {
        return;
      }
      const res = await loadWhilePromise(
        workOrderRepo.signMaterialReviewList({
          hash: list.hash,
        })
      );
      if (res.success) {
        setSignResponse(res.bankIdResponse);
      } else {
        listRes.query.setData({
          materialReviewListExists: true,
          data: res.updatedList,
        });
        window.modal.alert({
          typeOfAlert: "notification",
          title: "Uppdaterad lista",
          prompt:
            "Listan över orderrader att godkänna har uppdaterats sen Ni började granska den. Kontrollera att allt stämmer på nytt och godkänn sedan listan så att arbetet kan fortsätta.",
          error: null,
        });
      }
    } catch (er) {
      window.modal.alert({
        typeOfAlert: "error",
        title: "Det gick inte att skriva under listan",
        prompt:
          "Okänt fel när underskriften skulle startas. Vänligen försök igen senare.",
        error: er,
      });
    }
  }

  if (isError) {
    return (
      <AppPage
        headerProps={{
          heading: "Bekräfta tillagda orderrader",
        }}
        className={ClientUtils.twClassNames(
          "mx-auto flex max-w-screen-sm flex-col gap-6 p-2 sm:p-4 md:p-8"
        )}
      >
        <SomethingWentWrong
          description="Det gick inte att hämta informationen som behövs för att granska listan."
          error={listRes.query.error || workOrderRes.error}
          /*  onTryAgain={() => {
            listRes.query.refetch();
          }} */
        />
      </AppPage>
    );
  }

  return (
    <AppPage
      headerProps={{
        heading: "Bekräfta tillagda orderrader",
      }}
      className={ClientUtils.twClassNames(
        "mx-auto flex w-full max-w-screen-md flex-col gap-6 overflow-auto p-2 sm:p-4 md:p-8"
      )}
    >
      <LoadingContent loading={listRes.isLoading} renderContentSeparately>
        <AnimatePresence exitBeforeEnter>
          {list === false && !isSuccessful && (
            <section className="flex h-full flex-col gap-4 overflow-y-auto">
              <header className="mx-auto flex max-w-md flex-col gap-4">
                <Logo
                  withColor
                  wrapperClassName="mx-auto max-w-[200px] w-full"
                />
                <span className="text-base">
                  Just nu finns det inga orderrader att granska. Kontakta oss om
                  Ni tror att det är fel.
                </span>
              </header>
              <main>
                {!workOrder && <AppLoader />}
                {/* {workOrder && paymentSummary && (
                  <WorkOrderPaymentSummaryComponent
                    omitLogo
                    omitAditionalInformation
                    summaryHeading="Sammanställning av nuvarande arbete:"
                    workOrder={workOrder}
                    paymentSummary={paymentSummary}
                  />
                )} */}
              </main>
            </section>
          )}
          {list && workOrder && !signResponse && !isSuccessful && (
            <OrderLineReviewListOverview
              onSign={onSign}
              isSigning={isSigning}
              workOrder={workOrder}
              list={list}
            />
          )}
          {isSuccessful && (
            <motion.section
              key="success"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="flex flex-col gap-4 p-2"
            >
              <header className="flex justify-center p-8">
                <Logo withColor wrapperClassName="max-w-full w-64" />
              </header>
              <main>
                {successfulButStillAListToSign && (
                  <p className="text-lg">
                    Tack för att Ni godkände tilläggen. Er hantverkare har lagt
                    till fler tillägg. Vänligen ladda om sidan med knappen nedan
                    för att granska tilläggen på nytt.
                  </p>
                )}
                {!successfulButStillAListToSign && (
                  <>
                    <p className="text-lg">
                      Tack för att Ni godkände tilläggen. Nu kan Er hantverkare
                      fortsätta med arbetet.
                    </p>
                    <p className="text-lg">
                      Om det under installationen uppkommer behov av ytterligare
                      tillägg kommer Ni att bli ombedd att godkänna dessa.
                    </p>
                  </>
                )}
              </main>
              {successfulButStillAListToSign && (
                <footer>
                  <AppButton
                    onClick={() => {
                      onCancelAndRestart();
                    }}
                  >
                    Granska listan på nytt
                  </AppButton>
                </footer>
              )}
            </motion.section>
          )}
          {signResponse && !isSuccessful && (
            <BankIdSigning
              response={signResponse}
              restart={() => {
                onCancelAndRestart();
              }}
              cancel={() => {
                onCancelAndRestart();
              }}
              afterSuccessfulSign={() => {
                onSuccessfulSign();
              }}
            />
          )}
        </AnimatePresence>
      </LoadingContent>
    </AppPage>
  );
};

export default withCustomerGuid(CustomerMaterialReviewListRoute);
