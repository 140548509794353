import { Handyman, SupplierInvoice } from "@eljouren/domain/build";
import trpcClient from "../../trpc-setup";
import ICompanyRepo from "./interfaces/ICompanyRepo";

export default class CompanyRepo implements ICompanyRepo {
  async fetchSupplierInvoices(args: {
    from: Date;
    to: Date;
  }): Promise<SupplierInvoice.Type[]> {
    const res = await trpcClient.company.supplierInvoices.query({
      from: {
        year: args.from.getFullYear(),
        month: args.from.getMonth(),
      },
      to: {
        year: args.to.getFullYear(),
        month: args.to.getMonth(),
      },
    });

    return res.map((invoice) => ({
      ...invoice,
      periodStart: new Date(invoice.periodStart),
      periodEnd: new Date(invoice.periodEnd),
    }));
  }

  async fetchEmployees(): Promise<Handyman.Type[]> {
    return trpcClient.company.employees.query();
  }

  async setEmployeePricePermissions(args: {
    employeeId: string;
    permittedToViewPrices: boolean;
  }): Promise<void> {
    return trpcClient.company.setEmployeePricePermissions.mutate(args);
  }
}
