import ClientUtils from "./../../../../utils/ClientUtils";
import OverviewCoordinatorSection from "../overview/OverviewCoordinatorSection";
import OverviewCustomerSection from "../overview/OverviewCustomerSection";
import OverviewHeader from "../overview/OverviewHeader";
import OverviewServiceContractSection from "../overview/OverviewServiceContractSection";
import OverviewTaskSection from "../overview/OverviewTaskSection";
import OverviewImportantInformationSection from "../overview/OverviewImportantInformationSection";
import OverviewAdminSection from "../overview/OverviewAdminSection";

interface Props {
  className?: string;
}

const HandymanWorkOrderDesktopOverviewSection = (props: Props) => {
  return (
    <section
      className={ClientUtils.twClassNames(
        props.className,
        "relative grid h-full grid-rows-[auto,minmax(0,1fr)] gap-4 overflow-auto bg-bg-base-layer p-4"
      )}
    >
      <OverviewHeader />
      <main
        className={ClientUtils.twClassNames(
          "mb-auto grid grid-cols-2 gap-4 pb-4"
        )}
      >
        <OverviewAdminSection className="col-span-2" />
        <OverviewTaskSection />
        <div className="flex flex-col gap-4">
          <OverviewImportantInformationSection />
          <OverviewCoordinatorSection />
          <OverviewCustomerSection />
          <OverviewServiceContractSection />
        </div>
      </main>
    </section>
  );
};

export default HandymanWorkOrderDesktopOverviewSection;
