import {
  IpisFormInputDescription,
  IpisFormInputLabel,
  IpisFormInputNote,
} from "@ipis/client-essentials";
import { motion } from "framer-motion";
import ClientUtils from "../../../../utils/ClientUtils";
import InformationTooltip from "../../../InformationTooltip";
import RichTextPreTag from "../../../checklist/v2/checklist-question-types/components/RichTextPreTag";
import ControlledSlideUpModal from "../../modals/ControlledSlideUpModal";

interface Props {
  id: string;
  label: string;
  tooltip?: string | null;
  richTextDescription?: string | null;
  richTextNote?: string | null;

  slideUpElement?: {
    prompt: string;
    content: string;
  } | null;
  slideUpAnchorId?: string;
  className?: string;
}
/* 
	This is not really a form input, but a component that displays information about the form.
*/
const AppFormInformation = (props: Props) => {
  return (
    <motion.div
      layout
      className={ClientUtils.classNames(
        "grid w-full grid-cols-[minmax(0,1fr),auto] gap-2",
        props.className
      )}
    >
      <IpisFormInputLabel
        id={props.id}
        label={props.label}
        bold
        required={false}
      />
      {!!props.tooltip && (
        <InformationTooltip
          open={"left"}
          content={props.tooltip}
          contentClassName="w-[200px]"
        />
      )}
      {!!props.richTextDescription && (
        <IpisFormInputDescription
          className="col-span-2 w-full"
          html={props.richTextDescription}
        />
      )}

      {!!props.slideUpElement && (
        <>
          <ControlledSlideUpModal
            heading={props.slideUpElement.prompt}
            className="col-span-2"
            anchorToId={props.slideUpAnchorId}
          >
            <RichTextPreTag html={props.slideUpElement.content} />
          </ControlledSlideUpModal>
        </>
      )}
      {!!props.richTextNote && (
        <IpisFormInputNote className="col-span-2" html={props.richTextNote} />
      )}
    </motion.div>
  );
};

export default AppFormInformation;
