"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toast = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var IpisXIcon_1 = require("../../icons/IpisXIcon");
var IpisCheckmarkIcon_1 = require("../../icons/IpisCheckmarkIcon");
var IpisErrorIcon_1 = require("../../icons/IpisErrorIcon");
var Toast = function (props) {
    var _a;
    var durationInMs = (_a = props.timeVisibleInMs) !== null && _a !== void 0 ? _a : 4000;
    var base = "#ffffff";
    var from = "#314a4e";
    var to = base;
    var timeoutRef = (0, react_1.useRef)(null);
    var timeLeftRef = (0, react_1.useRef)(durationInMs);
    var closeToast = props.closeToast, rest = __rest(props, ["closeToast"]);
    (0, react_1.useEffect)(function () {
        var now = new Date();
        if (!timeoutRef.current) {
            timeoutRef.current = setTimeout(function () {
                closeToast(rest);
            }, timeLeftRef.current);
        }
        return function () {
            if (timeoutRef.current) {
                var then = new Date();
                var timePassed = Number(then) - Number(now);
                var timeLeft = Math.max(timeLeftRef.current - timePassed, 0);
                clearTimeout(timeoutRef.current);
                timeoutRef.current = null;
                timeLeftRef.current = timeLeft;
            }
        };
    }, [closeToast, rest]);
    function close() {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
        props.closeToast(props);
    }
    var toastTitleId = "toastTitle".concat(props.id);
    var toastDescriptionId = "toastDescription".concat(props.id);
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { className: "flex flex-col gap-2 overflow-hidden rounded border bg-white shadow-lg", role: "alertdialog", layout: true, "aria-labelledby": toastTitleId, "aria-describedby": toastDescriptionId, initial: {
            y: -100,
        }, animate: {
            y: 0,
        }, exit: {
            x: "calc(60vw + 100%)",
        }, children: [(0, jsx_runtime_1.jsxs)("span", { className: "flex items-center justify-between gap-4 px-4 pt-4", children: [(0, jsx_runtime_1.jsx)("h2", { id: toastTitleId, className: "text-lg", children: props.title }), (0, jsx_runtime_1.jsx)("button", { onClick: function () { return close(); }, className: "z-40 col-start-1 row-start-1 mb-auto ml-auto", children: (0, jsx_runtime_1.jsx)(IpisXIcon_1.IpisXIcon, { size: 20 }) })] }), (0, jsx_runtime_1.jsxs)(framer_motion_1.motion.span, { className: "grid grid-cols-[auto,minmax(0,1fr)] items-center gap-4 px-4 pb-4", initial: {
                    background: "linear-gradient(to top, transparent 4px, ".concat(base, " 4px),linear-gradient(to left, ").concat(from, " 0%, ").concat(to, " 1%)"),
                }, animate: {
                    background: "linear-gradient(to top, transparent 4px, ".concat(base, " 4px), linear-gradient(to left, ").concat(from, " 100%, ").concat(to, " 100%)"),
                }, transition: {
                    duration: durationInMs / 1000,
                }, children: [props.toastType === "success" && ((0, jsx_runtime_1.jsx)(IpisCheckmarkIcon_1.IpisCheckmarkIcon, { size: 30, className: "text-green-600" })), props.toastType === "error" && ((0, jsx_runtime_1.jsx)(IpisErrorIcon_1.IpisErrorIcon, { size: 30, className: "text-red-600" })), (0, jsx_runtime_1.jsx)("p", { id: toastDescriptionId, className: "text-base text-gray-700", children: props.prompt })] })] }));
};
exports.Toast = Toast;
