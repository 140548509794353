import React from "react";
import { AppButton, AppButtonProps } from "./common/buttons/AppButton";
import AppArrowIcon from "./icons/AppArrowIcon";

interface Props {
  className?: string;
  children?: React.ReactNode;
  appButtonProps?: Omit<AppButtonProps, "onClick">;
  onClick(): void;
  iconSize?: number;
}

const GoBackButton: React.FC<Props> = (props) => {
  return (
    <AppButton
      className={props.className}
      variant="outline-default"
      {...props.appButtonProps}
      type="button"
      onClick={props.onClick}
    >
      <AppArrowIcon direction="left" size={props.iconSize ?? 20} />
      <span>{props.children ?? "Gå tillbaka"}</span>
    </AppButton>
  );
};

export default GoBackButton;
