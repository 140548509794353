import {
  BankIdSignResponse,
  BankIdUtils,
  BankIdTransformedCollectResponse,
} from "@eljouren/bank-id-schemas/build";

import { AnimatePresence, MotionProps, motion } from "framer-motion";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../hooks/use-media-query";
import ClientUtils from "../../../utils/ClientUtils";
import AnimatedCheckmark from "../../common/AnimatedCheckMark";
import { AppButton } from "../../common/buttons/AppButton";
import FormBreadcrumb from "../../forms/breadcrumb/FormBreadcrumb";
import { useBankIdCollect } from "./BankIdHooks";
import BankIDLogo from "./BankIdLogo";
import BankIdQRCode from "./BankIdQrCode";

const fadeInOutProps: MotionProps = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

interface Props {
  className?: string;
  response: BankIdSignResponse.ClientResponseType;
  restart: () => void;
  cancel: () => void;
  afterSuccessfulSign: () => void; //data: BankIdTransformedresponse.CompleteResponseWithHandledStatusType
}

const BankIdSigning = (props: Props) => {
  const isSm = useMediaQuery(TailwindBreakpoint.sm);
  const res = useBankIdCollect({
    sessionId: props.response.sessionId,
    onSignComplete: props.afterSuccessfulSign,
    onSignCompleteTimeoutInMs: 2500,
  });

  const {
    isCompletedAndHandled,
    isCompletedAndUnhandled,
    isFailed,
    signStarted,
  } = res;

  async function onCancel() {
    try {
      await res.cancel();
    } catch (er) {
      console.log("Failed to cancel bankid signing");
      props.cancel();
    }
  }

  const breadcrumbSteps = [
    isSm ? "Starta appen" : "Starta",
    "Skriv under",
    "Klart",
  ];

  let breadcrumbIndex = 0;
  if (signStarted) {
    breadcrumbIndex = 1;
  }
  if (isCompletedAndHandled) {
    breadcrumbIndex = 2;
  }

  return (
    <motion.section
      key="bankIdSigning"
      {...fadeInOutProps}
      className={ClientUtils.twClassNames(
        "flex flex-col gap-4 transition-all sm:p-2",
        res.isCanceling && "pointer-events-none opacity-50",
        props.className
      )}
    >
      <figure className="flex justify-center">
        <BankIDLogo size={isSm ? 250 : 150} />
      </figure>
      {!isFailed && !isCompletedAndUnhandled && !res.isError && (
        <header>
          <FormBreadcrumb steps={breadcrumbSteps} atIndex={breadcrumbIndex} />
        </header>
      )}
      <main className="flex flex-col items-center gap-4">
        <AnimatePresence exitBeforeEnter>
          {!res.isError && (
            <Content
              {...props}
              cancel={onCancel}
              response={res.data}
              signResponse={props.response}
            />
          )}
          {res.isError && (
            <span className="flex flex-col gap-2">
              <p className="p-2 text-red-600">
                Något gick fel. Vänligen försök igen om en liten stund.
              </p>
              <AppButton
                variant="outline-default"
                className="w-full"
                onClick={() => props.restart()}
              >
                <span>Försök igen</span>
              </AppButton>
            </span>
          )}
        </AnimatePresence>
      </main>
    </motion.section>
  );
};

const Content = (
  props: {
    response:
      | BankIdTransformedCollectResponse.ClientSafeTypeWithHandledStatus
      | undefined;
    signResponse: BankIdSignResponse.ClientResponseType;
  } & Pick<Props, "cancel" | "afterSuccessfulSign" | "restart">
) => {
  const renderOptions = BankIdUtils.getBankIdRenderOptions(props.response);

  if (renderOptions.status === "success") {
    return (
      <motion.section {...fadeInOutProps} key="complete">
        <AnimatedCheckmark text="Tack för att du skrev under!" />
      </motion.section>
    );
  }

  // array from set
  const actions = Array.from(new Set(renderOptions.actions));

  return (
    <motion.section
      {...fadeInOutProps}
      key={renderOptions.key}
      data-bank-id-section-key={renderOptions.key}
      className="flex flex-col gap-2"
    >
      {!!renderOptions.label && (
        <span className="flex p-2 font-semibold text-brand-blue-600">
          {renderOptions.label}
        </span>
      )}
      {actions.map((action) => {
        if (action === "openApp") {
          return <LinkQrToggle key="openApp" response={props.signResponse} />;
        }

        return (
          <AppButton
            key={renderOptions.key + action}
            variant="outline-default"
            contentClassName="w-full"
            onClick={() => {
              switch (action) {
                case "cancel":
                case "goBack":
                  props.cancel();
                  break;
                case "restart":
                  props.restart();
                  break;
              }
            }}
          >
            <span>
              {action === "cancel" && "Avbryt"}
              {action === "goBack" && "Gå tillbaka"}
              {action === "restart" && "Försök igen"}
            </span>
          </AppButton>
        );
      })}
      <AppButton
        variant="outline-default"
        contentClassName="w-full"
        onClick={props.cancel}
      >
        <span>Avbryt</span>
      </AppButton>
    </motion.section>
  );
};

interface LinkQrToggleProps {
  className?: string;
  response: BankIdSignResponse.ClientResponseType;
}

const LinkQrToggle = (props: LinkQrToggleProps) => {
  return (
    <span className="flex flex-col items-center gap-4">
      <a
        className="rounded border bg-brand-blue-600 p-2 text-center font-semibold text-white"
        href={`bankid:///?autostarttoken=${props.response.autoStartToken}&redirect=null`}
      >
        <span>Öppna BankID-appen på denna enhet</span>
      </a>
      <span className="pt-4 text-center text-sm">
        eller scanna nedan QR-kod på valfri enhet
      </span>
      <BankIdQRCode sessionId={props.response.sessionId} />
    </span>
  );
};

export default BankIdSigning;
