import { WorkOrderConfirmationV2 } from "@eljouren/domain";
import trpcClient from "../../trpc-setup";
import { IClientCustomerWorkOrderRepo  } from "./interfaces/IClientCustomerWorkOrderRepo";

export default class ClientCustomerWorkOrderRepo
  implements IClientCustomerWorkOrderRepo
{
  async confirmWorkOrder(args: {
    values: WorkOrderConfirmationV2.Type;
  }): Promise<void> {
    await trpcClient.v2CustomerWorkOrderRouter.confirmWorkOrder.mutate({
      values: args.values,
    });
  }
}
