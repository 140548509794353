"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderPaymentStatus = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderPaymentStatus;
(function (WorkOrderPaymentStatus) {
    WorkOrderPaymentStatus.StatusEnumSchema = centralized_zod_1.z.enum([
        "PENDING",
        "REJECTED",
        "ACCEPTED",
        "REFUNDED",
        "CANCELED",
        "INSPECTION",
        "CREDIT_APPROVED",
    ]);
    WorkOrderPaymentStatus.RejectedReasonSchema = centralized_zod_1.z.enum([
        "UNKNOWN",
        "TECHNICAL_ERROR",
        "CREDIT_DENIED",
        "PAYMENT_FROZEN",
        "TIMEOUT",
        "ABORTED_BY_CUSTOMER",
        "INSUFFICIENT_FUNDS",
        "CANCELED",
    ]);
    WorkOrderPaymentStatus.PaymentAlternativesSchema = centralized_zod_1.z.object({
        invoice: centralized_zod_1.z.boolean(),
        partialPayment: centralized_zod_1.z.boolean(),
        directPayment: centralized_zod_1.z.boolean(),
        internalInvoice: centralized_zod_1.z.boolean(),
    });
    var BaseSchema = centralized_zod_1.z.object({
        workOrderId: centralized_zod_1.z.string(),
        canStartProcessFromBeginning: centralized_zod_1.z.boolean(),
    });
    WorkOrderPaymentStatus.NonInitiatedSchema = BaseSchema.extend({
        initiated: centralized_zod_1.z.literal(false),
        availablePaymentAlternatives: centralized_zod_1.z.object({
            invoice: centralized_zod_1.z.literal(true),
            partialPayment: centralized_zod_1.z.literal(true),
            directPayment: centralized_zod_1.z.literal(true),
            internalInvoice: centralized_zod_1.z.literal(false),
        }),
    });
    WorkOrderPaymentStatus.PaymentTypeSchema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            isInternal: centralized_zod_1.z.literal(true),
            externalPaymentId: centralized_zod_1.z.literal("undefined").optional(),
        }),
        centralized_zod_1.z.object({
            isInternal: centralized_zod_1.z.literal(false),
            externalPaymentId: centralized_zod_1.z.string(),
        }),
    ]);
    var PaymentTypeWithoutPaymentIdSchema = centralized_zod_1.z.object({
        isInternal: centralized_zod_1.z.boolean(),
    });
    var InitiatedSchema = BaseSchema.extend({
        initiated: centralized_zod_1.z.literal(true),
        lastUpdated: centralized_zod_1.z.date(),
        status: WorkOrderPaymentStatus.StatusEnumSchema,
        rejectedReason: WorkOrderPaymentStatus.RejectedReasonSchema.nullable(),
        availablePaymentAlternatives: WorkOrderPaymentStatus.PaymentAlternativesSchema,
        paymentType: WorkOrderPaymentStatus.PaymentTypeSchema,
        activelyChosenByCustomer: centralized_zod_1.z.boolean(),
        customerRedirectUrl: centralized_zod_1.z.string().nullable(),
    });
    WorkOrderPaymentStatus.Schema = centralized_zod_1.z.union([WorkOrderPaymentStatus.NonInitiatedSchema, InitiatedSchema]);
    WorkOrderPaymentStatus.ClientSafeSchema = centralized_zod_1.z.union([
        WorkOrderPaymentStatus.NonInitiatedSchema,
        InitiatedSchema.extend({
            paymentType: PaymentTypeWithoutPaymentIdSchema,
        }),
    ]);
})(WorkOrderPaymentStatus || (WorkOrderPaymentStatus = {}));
exports.WorkOrderPaymentStatus = WorkOrderPaymentStatus;
