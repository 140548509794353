"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileInputInfo = void 0;
var zod_1 = require("zod");
var FileInputInfo;
(function (FileInputInfo) {
    FileInputInfo.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        extension: zod_1.z.string(),
        mimeType: zod_1.z.string(),
        originalExtension: zod_1.z.string(),
        originalMimeType: zod_1.z.string(),
        originalName: zod_1.z.string().optional(),
        providedName: zod_1.z.string().optional(),
        providedDescription: zod_1.z.string().optional(),
        providedClientId: zod_1.z.string().optional(),
        isCompressed: zod_1.z.boolean(),
        isConverted: zod_1.z.boolean(),
        size: zod_1.z.number(),
    });
})(FileInputInfo || (FileInputInfo = {}));
exports.FileInputInfo = FileInputInfo;
