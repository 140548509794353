import { zodResolver } from "@hookform/resolvers/zod";
import { AnimatePresence, MotionProps, motion } from "framer-motion";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import { useLoading } from "../../../../../hooks/hooks";
import useQueryWrapper from "../../../../../hooks/use-query-wrapper";
import { useRepos } from "../../../../../hooks/use-repos";
import HandymanWorkOrderRouteContext from "../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "../../../../../utils/ClientUtils";
import SaveAndGoBackFooter from "../../../../SaveAndGoBackFooter";
import MyDialog from "../../../../common/MyDialog";
import { AppButton } from "../../../../common/buttons/AppButton";
import LoadingModal from "../../../../common/loaders/LoadingModal";
import { AppFormTextField } from "../../../../common/text-fields/AppFormTextField";
import HandymanLineItemContext from "../../line-items/HandymanLineItemContext";
import WorkOrderMaterialReviewImageModal from "./extra-material/WorkOrderMaterialReviewImageModal";

const FormSchema = z.object({
  phoneNumber: z.string().regex(/^\+.*/),
});

type FormValues = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  goBack: () => void;
  asModal?: boolean;
}

const WorkOrderMaterialReviewSection = (props: Props) => {
  const { workOrderRepo } = useRepos();
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const lineItemCtx = useContext(HandymanLineItemContext);
  const order = ctx.order;
  const lineItemRes = lineItemCtx.lineItemRes;
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const bankIdLoader = useLoading();

  const lineItemHash = lineItemRes.query.data
    ?.map((el) => JSON.stringify(el))
    .join("");

  const materialReviewListRes = useQueryWrapper({
    queryKey: [
      "materialReviewListWithNotApprovedMaterial",
      order.orderId,
      lineItemHash,
      lineItemRes.isLoading,
    ],
    queryFn: () =>
      workOrderRepo.getMaterialReviewListIncludingNotApprovedLineItems({
        workOrderId: ctx.order.orderId,
      }),
    staleTime: 15,
    enabled: !lineItemRes.isLoading,
  });

  const form = useForm<FormValues>({
    defaultValues: {
      phoneNumber: ctx.order.endCustomer.phone ?? "",
    },
    resolver: zodResolver(FormSchema),
  });

  function toggleFileUploadModal() {
    setShowFileUploadModal(!showFileUploadModal);
  }

  function closeFileUploadModal() {
    setShowFileUploadModal(false);
  }

  const unapproved = lineItemCtx.getWithApprovalState("notApproved");
  const pending = lineItemCtx.getWithApprovalState("pending");
  const pendingOrUnapproved = lineItemCtx.getWithApprovalState(
    "notApproved",
    "pending"
  );

  const hasUnapproved = unapproved.exists;
  const hasPending = pending.exists;
  const enableBankIdButton = hasUnapproved || hasPending;

  function onImageUploadFinished() {
    props.goBack();
  }

  async function sendLineItemsInForApproval(values: FormValues) {
    if (!enableBankIdButton) {
      return;
    }

    if (!hasUnapproved) {
      const sendAnyway = await window.modal.confirm({
        title: "Länken har redan skickats ut",
        prompt: "Vill du skicka länken igen?",
        yesLabel: "Skicka länk igen",
        noLabel: "Avbryt",
      });

      if (!sendAnyway) {
        return;
      }
    }

    try {
      await bankIdLoader.loadWhileAsync(() =>
        lineItemRes.mutate({
          callback: () =>
            workOrderRepo.sendLineItemsInForReview({
              workOrderId: order.orderId,
              phoneNumber: values.phoneNumber,
            }),
        })
      );
      props.goBack();
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att skicka in material för granskning just nu.",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  /*  async function toggleRequiresMaterialToBeReviewed() {
    ctx.orderRes.mutate(() => {
      return workOrderRepo.setRequiresMaterialToBeReviewed({
        workOrderId: order.orderId,
        value: !order.requiresMaterialToBeReviewed,
      });
    });
  } */

  const modalId = "lineitem-review-loader";

  const motionProps: MotionProps = {};

  if (props.asModal) {
    motionProps.initial = { opacity: 0 };
    motionProps.animate = { opacity: 1 };
    motionProps.exit = { opacity: 0 };
  }

  const isFetching = materialReviewListRes.isLoading || lineItemRes.isLoading;

  const data = materialReviewListRes.data;
  const showMainContent =
    materialReviewListRes.isLoading || data?.materialReviewListExists;

  const showTaxReductionMismatch =
    data &&
    !data.materialReviewListExists &&
    data.reason === "TAX_REDUCTION_MISMATCH";

  const showError =
    materialReviewListRes.isError ||
    (data && !data.materialReviewListExists && !showTaxReductionMismatch);

  return (
    <>
      <motion.section
        {...motionProps}
        className={ClientUtils.twClassNames(
          props.className,
          "grid h-full w-full grid-rows-[auto,minmax(0,1fr),auto]",
          props.asModal &&
            "absolute z-50 grid h-full w-full bg-off-white/80 p-2 backdrop-blur",
          !props.asModal && "relative"
        )}
      >
        <AnimatePresence>
          {bankIdLoader.isLoading && (
            <LoadingModal
              id={modalId}
              labels={["Uppdaterar orderrader", "Skickar SMS till kund"]}
              stopOnLast
            />
          )}
          {isFetching && !bankIdLoader.isLoading && (
            <LoadingModal
              id={"material-review-list-loader"}
              labels={["Hämtar sammanfattning av orderrader"]}
              stopOnLast
            />
          )}
        </AnimatePresence>
        <header className="px-2 py-4 sm:px-4">
          <h2 className="text-lg font-bold">Få material godkänt av kund</h2>
          {!pendingOrUnapproved.exists && (
            <p>
              Just nu finns det inga orderrader som behöver godkännas av kunden.
            </p>
          )}
          {unapproved.exists && (
            <p>
              Just nu finns det {unapproved.count}{" "}
              {unapproved.count === 1 ? "orderrad" : "orderrader"} som behöver
              godkännas av kunden.
            </p>
          )}
          {pending.exists && (
            <p>
              Just nu finns det {pending.count}{" "}
              {pending.count === 1 ? "orderrad" : "orderrader"} som väntar på
              godkännande av kunden.
            </p>
          )}
        </header>
        <main className="flex h-full flex-col gap-8 overflow-auto p-2">
          {showTaxReductionMismatch && (
            <>
              <p className="p-2">
                Det verkar som att det har blivit något fel med orderraderna som
                har lagts till på arbetsordern. Vissa av orderraderna är
                kopplade till ROT-avdrag och andra till grön teknik. Innan
                orderraderna godkänns så måste det här åtgärdas. Vänligen
                kontakta kundservice på{" "}
                <a className="text-blue-400" href="tel:+46102881249">
                  010-2881249
                </a>
                .
              </p>
            </>
          )}
          {showError && (
            <p className="p-2">
              Något gick fel när vi försökte hämta en sammanställning av
              arbetsorders orderrader. Vänligen försök igen senare.
            </p>
          )}
          {showMainContent && (
            <>
              <form
                className="flex flex-col gap-4 rounded border bg-off-white p-4 shadow"
                onSubmit={form.handleSubmit(sendLineItemsInForApproval)}
              >
                <AppButton
                  type="submit"
                  disabled={!enableBankIdButton}
                  className="w-full"
                  aria-controls={modalId}
                >
                  {unapproved.exists
                    ? "Skicka BankID-länk"
                    : "Skicka BankID-länk på nytt"}
                </AppButton>
                <AppFormTextField
                  register={form.register}
                  name="phoneNumber"
                  label="Kundens telefonnummer"
                  helperText="Används för att skicka BankID-länk till kunden. Numret måste börja med + och innehålla landskod."
                />

                <section className="flex flex-col gap-2">
                  <h3 className="text-sm font-semibold text-faded-gray">
                    Använd denna knapp för att be om godkännande av orderrader
                    när kunden har tillgång till BankID.
                  </h3>
                  <p className="text-sm ">
                    Detta alternativ ska alltid användas när kunden har tillgång
                    till BankID.
                  </p>
                </section>
              </form>
              <section className="flex flex-col gap-4 rounded bg-off-white p-4 shadow">
                <AppButton
                  onClick={toggleFileUploadModal}
                  disabled={!pendingOrUnapproved.exists}
                  className="w-full"
                  variant="outline-default"
                >
                  Ladda upp medgivande
                </AppButton>
                <section className="flex flex-col gap-2">
                  <h3 className="text-sm font-semibold text-faded-gray">
                    Använd denna knapp endast när kunden inte har tillgång till
                    BankID.
                  </h3>
                  <p className="text-sm">
                    Ta bild på ett medgivande i pappersformat och ladda upp.
                    Medgivandet ska gälla samtliga orderrader som behöver
                    godkännas.
                  </p>
                </section>
              </section>
            </>
          )}
        </main>
        {props.asModal && (
          <footer className="flex flex-col">
            <AppButton onClick={props.goBack}>Stäng fönster</AppButton>
          </footer>
        )}
        {!props.asModal && (
          <SaveAndGoBackFooter
            onGoBack={props.goBack}
            readonly
            isDisabled={false}
            isLoading={false}
          />
        )}
      </motion.section>

      <MyDialog isOpen={showFileUploadModal} cancel={closeFileUploadModal}>
        {materialReviewListRes.data?.materialReviewListExists && (
          <WorkOrderMaterialReviewImageModal
            materialReviewList={materialReviewListRes.data.data}
            onFinished={onImageUploadFinished}
            closeFileUploadModal={closeFileUploadModal}
          />
        )}
      </MyDialog>
    </>
  );
};

export default WorkOrderMaterialReviewSection;
