"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandTheme = void 0;
var zod_1 = require("zod");
var BrandTheme;
(function (BrandTheme) {
    BrandTheme.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        brandName: zod_1.z.string(),
        headerBackgroundColor: zod_1.z.string(),
        headerTextColor: zod_1.z.string(),
        buttonTextColor: zod_1.z.string(),
        accentColor: zod_1.z.string(),
        logoUrl: zod_1.z.string(),
    });
})(BrandTheme || (BrandTheme = {}));
exports.BrandTheme = BrandTheme;
