import { useContext } from "react";
import { CustomFileInputFile } from "../../../files/FileInputButton";
import WorkOrderFileUploadForm, {
  WorkOrderFileUploadProps,
} from "../../files/WorkOrderFileUploadForm";
import { HandymanWorkOrderFileContext } from "../../files/DecoupledWorkOrderFileContextProvider";

type Props = Omit<WorkOrderFileUploadProps, "onSubmit">;

const HandymanWorkOrderFileUpload = (props: Props) => {
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  async function onSubmit(files: CustomFileInputFile[]) {
    fileCtx.uploadFiles(files);
  }

  return <WorkOrderFileUploadForm {...props} onSubmit={onSubmit} />;
};

export default HandymanWorkOrderFileUpload;
