import { useRef } from "react";
import ClientUtils from "./../../../utils/ClientUtils";
import { FieldValues, UseFormReturn } from "react-hook-form";
import SaveIcon from "../../icons/SaveIcon";
import AppEditIcon from "../../icons/AppEditIcon";

export type ProfileFormSingleInputVariant = "default" | "isolated";
export type ProfileFormButtonPosition = "right" | "bottom-right" | "top-right";

type StringKeyOf<T extends FieldValues> = Extract<keyof T, string>;

type Props<T extends FieldValues> = {
  variant?: ProfileFormSingleInputVariant;
  htmlAttributes?: Omit<
    JSX.IntrinsicElements["textarea"],
    "ref" | "className" | "id"
  >;
  buttonPosition?: ProfileFormButtonPosition;
  id: string;
  form?: UseFormReturn<T>;
  path?: StringKeyOf<T>;
  label?: string;
  useIcon?: boolean;
} & (
  | {
      editable: true;
      isEditing: boolean;
      onEdit(): void;
      onSave(): void;
      editLabel?: string;
      saveLabel?: string;
    }
  | {
      editable?: false;

      isEditing?: boolean;
      onEdit?(): void;
      onSave?(): void;
      editLabel?: string;
      saveLabel?: string;
    }
);

/* 
    This component as well as the ProfileFormMergedInput is really messy and should be refactored.
*/
const ProfileFormSingleInput = <T extends FieldValues>(props: Props<T>) => {
  /* 
      Not sure why I need the any cast
    */
  const register =
    props.form && props.path ? props.form.register(props.path as any) : {};
  const variant = props.variant ?? "default";
  const ref = useRef<HTMLTextAreaElement | null>(null);

  function getWrapperStyle(): string | undefined {
    switch (variant) {
      case "default":
        return "flex flex-col";
      case "isolated":
        return "flex flex-col gap-2";
    }
  }

  const buttonRight = !props.buttonPosition || props.buttonPosition === "right";
  const buttonBelow = props.buttonPosition === "bottom-right";
  const buttonTop = props.buttonPosition === "top-right";

  function getTextAreaStyle(): string | undefined {
    let common: string;
    if (buttonRight) {
      common = "grid grid-cols-[minmax(0,1fr),auto]";
    } else {
      common = "flex flex-col";
    }

    switch (variant) {
      case "default":
        return ClientUtils.twClassNames(
          common,
          "resize-none rounded border bg-transparent p-1 font-bold",
          props.isEditing && "border-bg-main-dark",
          !props.isEditing && "border-transparent"
        );
      case "isolated":
        return ClientUtils.twClassNames(
          common,
          /*   !props.isEditing && "bg-transparent", */
          "resize-none rounded border p-1"
        );
    }
  }

  return (
    <p className={getWrapperStyle()}>
      {(props.label || buttonTop) && (
        <span className="col-span-2 flex items-center justify-between gap-2">
          {props.label && (
            <label
              className={ClientUtils.classNames(
                "col-span-2 text-sm"
                //props.variant === "isolated" && "font-bold text-dark-gray"
              )}
              htmlFor={props.id}
            >
              {props.label}
            </label>
          )}
          {buttonTop && <EditSaveButton {...props} />}
        </span>
      )}
      <textarea
        className={getTextAreaStyle()}
        ref={ref}
        id={props.id}
        rows={1}
        readOnly={!props.isEditing}
        disabled={!props.isEditing}
        {...props.htmlAttributes}
        {...register}
      />
      {buttonRight || (buttonBelow && <EditSaveButton {...props} />)}
    </p>
  );
};

const EditSaveButton = <T extends FieldValues>(props: Props<T>) => {
  if (!props.editable) {
    return <></>;
  }

  const editText = props.editLabel ?? "Ändra";
  const saveText = props.saveLabel ?? "Spara";
  const label = props.isEditing ? saveText : editText;

  return (
    <button
      className="mb-1 ml-auto mt-auto flex items-center gap-2 text-sm font-semibold"
      onClick={(e) => {
        e.preventDefault();

        if (props.isEditing) {
          props.onSave();
        } else {
          props.onEdit();
        }
      }}
    >
      <span>{label}</span>
      {props.useIcon && props.isEditing && <SaveIcon size={20} />}
      {props.useIcon && !props.isEditing && <AppEditIcon size={20} />}
    </button>
  );
};

export default ProfileFormSingleInput;
