import { motion } from "framer-motion";
import ClientUtils from "./../../../../utils/ClientUtils";

import DecoupledWorkOrderDesktopFileExpandedSection from "../../../../components/work-order/files/DecoupledWorkOrderDesktopFileExpandedSection";
import HandymanWorkOrderDesktopFilePreviewSection from "./HandymanWorkOrderDesktopFilePreviewSection";

export interface HandyManWorkOrderDesktopFileProps {
  className?: string;
  onToggle?: () => void;
  isExpanded: boolean;
  workOrderSubject?: string;
}

const HandymanWorkOrderDesktopFileSection = (
  props: HandyManWorkOrderDesktopFileProps
) => {
  return (
    <motion.section
      layout
      className={ClientUtils.twClassNames(
        "z-10",
        !props.isExpanded && props.className,
        "grid h-full grid-rows-[auto,minmax(0,1fr)]",
        !props.isExpanded && "relative bg-white p-2",
        props.isExpanded &&
          "absolute left-0 top-0 h-full w-full bg-black/50 p-4 backdrop-blur"
      )}
    >
      {!props.isExpanded && (
        <HandymanWorkOrderDesktopFilePreviewSection {...props} />
      )}
      {props.isExpanded && (
        <DecoupledWorkOrderDesktopFileExpandedSection {...props} />
      )}
    </motion.section>
  );
};

export default HandymanWorkOrderDesktopFileSection;
