import ClientUtils from "./../../../utils/ClientUtils";
import { WorkOrder } from "@eljouren/domain";
import { Path } from "react-hook-form";

import { AppFormTextArea } from "../../common/text-areas/AppFormTextArea";
import { CustomerOrderDefaultFormFieldProps } from "./CustomerOrderDefaultFormFields";
import { AppFormTextField } from "../../common/text-fields/AppFormTextField";

type Props = CustomerOrderDefaultFormFieldProps & {
  mainClassName?: string;
};

const CustomerOrderDescriptionFieldset = (props: Props) => {
  const omit =
    props.omit || ({} as Partial<Record<keyof WorkOrder.Type, boolean>>);
  const allowEditing: Partial<Record<Path<WorkOrder.Type>, boolean>> =
    props.allowEditing || {};

  return (
    <fieldset
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2 pb-6",
        props.className
      )}
    >
      <header className="border-b pb-2">
        <h3 className="text-lg sm:text-xl">Arbetsorder</h3>
      </header>
      <main
        className={ClientUtils.twClassNames(
          "flex flex-col gap-2 md:gap-4",
          props.mainClassName
        )}
      >
        <AppFormTextField
          register={props.register}
          name="description"
          label="Beskrivning"
          htmlAttributes={{
            readOnly: !allowEditing.description,
            disabled: !allowEditing.description,
          }}
        />
        {!omit.additionalInformation && (
          <AppFormTextArea
            className="col-span-4"
            register={props.register}
            name="additionalInformation"
            label="Utförlig uppdragsbeskrivning"
            htmlAttributes={{
              readOnly: !allowEditing.additionalInformation,
              disabled: !allowEditing.additionalInformation,
              rows: props.textAreaRows || 4,
            }}
          />
        )}
      </main>
    </fieldset>
  );
};

export default CustomerOrderDescriptionFieldset;
