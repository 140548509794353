import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import ClientUtils from "../../utils/ClientUtils";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppExclamationIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <ExclamationCircleIcon
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default withIconSize(AppExclamationIcon);
