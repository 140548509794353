import { IpisFileV2 } from "@eljouren/file-schemas/";
import { DateHelper } from "@eljouren/utils";
import { FilePermissions } from "../../../types/file-permissions";
import ClientUtils from "../../../utils/ClientUtils";
import AppDescriptionIcon from "../../icons/AppDescriptionIcon";
import AppExternalLinkIcon from "../../icons/AppExternalLinkIcon";
import IpisUploadedByIcon from "../../icons/composed/IpisUploadedByIcon";
import ConditionalFileRemovalButton from "../../work-order/handyman/files/ConditionalFileRemovalButton";
import IpisFileRelationDiv from "../../work-order/handyman/files/IpisFileRelationFieldset";
import IpisFileIcon from "../IpisFileIcon";

interface Props {
  className?: string;
  file: IpisFileV2.Type;
  alt: string;
  onDelete: (file: IpisFileV2.Type) => void;
  permissions: FilePermissions;
}

const ExpandedFileDiv = (props: Props) => {
  const selectedFile = props.file;
  function getFileName() {
    return selectedFile.nameWithExtension;
  }

  const isNonHeicImage =
    selectedFile.mimeType.startsWith("image") &&
    !selectedFile.mimeType.includes("heic") &&
    !selectedFile.mimeType.includes("heif");

  return (
    <div
      className={ClientUtils.classNames(
        "flex h-full w-full flex-col overflow-auto rounded bg-gradient-to-br from-slate-100/50 to-slate-200/50 2xl:col-span-1",
        props.className
      )}
    >
      <figure
        className={ClientUtils.classNames("w-full", !isNonHeicImage && "p-8")}
      >
        {isNonHeicImage && (
          <img
            className="max-h-[30vh] w-full rounded object-contain"
            src={selectedFile.src}
            alt={props.alt}
          />
        )}
        {!isNonHeicImage && (
          <IpisFileIcon
            size={100}
            className="mx-auto"
            mimeType={selectedFile.mimeType}
          />
        )}
      </figure>
      <header className="z-20 grid grid-cols-[minmax(0,1fr),auto] grid-rows-1 items-center gap-2 p-4">
        <h3 className="w-full overflow-hidden break-words text-base">
          {getFileName()}
        </h3>
        <span className="text-sm font-normal italic text-dark-gray text-opacity-80">
          {new DateHelper(selectedFile.createdDate).readableDateTime}
        </span>
      </header>

      <span className="mb-8 flex min-h-[150px] flex-col gap-4 p-4">
        <fieldset className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-4">
          <span className="flex rounded-full bg-brand-blue-300 p-2">
            <AppDescriptionIcon size={30} />
          </span>
          <p>{selectedFile.description || "Ingen beskrivning"}</p>
        </fieldset>
        <fieldset className="flex items-center gap-4">
          <span className="flex rounded-full bg-brand-blue-300 p-2">
            <IpisUploadedByIcon
              size={30}
              uploaderSource={selectedFile.uploadedBy}
            />
          </span>
          <IpisFileRelationDiv file={selectedFile} labelClassName="text-base" />
        </fieldset>
      </span>
      <fieldset className="flex justify-end gap-2 p-2">
        <ConditionalFileRemovalButton
          onRemove={props.onDelete}
          file={selectedFile}
          permissions={props.permissions}
        />
        <a
          href={selectedFile.src}
          className="flex items-center gap-2 rounded bg-main-bg-dark px-4 py-2 text-white hover:bg-main-bg-light"
          target="_blank"
          rel="noopener noreferrer"
        >
          <AppExternalLinkIcon size={20} />
          <span>Öppna i ny flik</span>
        </a>
      </fieldset>
    </div>
  );
};

export default ExpandedFileDiv;
