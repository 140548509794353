import React, { useContext } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { WizardFormContext, WizardFormElement } from "./wizard-form-types";

interface Props {
  el: WizardFormElement;
  isFocused: boolean;
}

const WizardFormWalkthroughPopup = (props: Props) => {
  const ctx = useContext(WizardFormContext);
  if (ctx.hasGoneThroughWalkthrough) {
    return <></>;
  }

  const Subheading =
    typeof props.el.walkthroughSubheading === "string" ? (
      <p className="text-dark-gray/80">{props.el.walkthroughSubheading}</p>
    ) : (
      props.el.walkthroughSubheading()
    );

  return (
    <AnimatePresence>
      {props.isFocused && (
        <motion.header
          className="absolute bottom-full -translate-y-2 rounded bg-white/80  p-4 backdrop-blur"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <>
            <h3 className="text-xl">{props.el.walkthroughHeading}</h3>
            {Subheading}
          </>
        </motion.header>
      )}
    </AnimatePresence>
  );
};

export default WizardFormWalkthroughPopup;
