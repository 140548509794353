"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var client_utils_1 = require("../utils/client-utils");
var withIconSize = function (WrappedComponent) {
    return function (props) {
        var _a;
        var className = props.className;
        var sizeClassName;
        var style = props.style;
        var propsSize = props.size, rest = __rest(props, ["size"]);
        var size = (_a = props.size) !== null && _a !== void 0 ? _a : "md";
        var r = rest;
        if (typeof size === "number") {
            style = __assign(__assign({}, style), { height: size, width: size });
        }
        else {
            switch (size) {
                case "sm":
                    sizeClassName = "h-5 w-5";
                    break;
                case "md":
                    sizeClassName = "h-6 w-6";
                    break;
                case "lg":
                    sizeClassName = "h-7 w-7";
                    break;
            }
            className = (0, client_utils_1.classNames)(className, sizeClassName);
        }
        return (0, jsx_runtime_1.jsx)(WrappedComponent, __assign({}, r, { style: style, className: className }));
    };
};
exports.default = withIconSize;
