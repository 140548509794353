import {
  BookingSuggestions,
  Handyman,
  LiveBookingSuggestions,
} from "@eljouren/domain";
import { UUID } from "@eljouren/utils";

export type HandymanWithPriorizitation = Handyman.Type & {
  isPrioritized?: boolean;
};
/* 
  Needs a better name.
*/
export type SlotsWithAdjacentHandymenGrouped = {
  id: string;
  handyman: HandymanWithPriorizitation;
  slots: BookingSuggestions.SlotType[];
};

type GroupedByDate = {
  date: Date;
  slotsWithAdjacentHandymenGrouped: SlotsWithAdjacentHandymenGrouped[];
};

export default class SortedBookingSuggestions {
  readonly groupedByDate: GroupedByDate[];
  readonly bestFirst: SlotsWithAdjacentHandymenGrouped[];
  readonly groupedByHandyman: {
    handyman: Handyman.Type;
    slots: BookingSuggestions.SlotType[];
  }[];

  constructor(
    readonly props: {
      suggestions: LiveBookingSuggestions.Type;
    }
  ) {
    this.groupedByDate = this.mergeGroupedByDate();
    this.bestFirst = this.orderByBest();
    this.groupedByHandyman = this.groupByHandyman();
  }

  private mergeGroupedByDate(): GroupedByDate[] {
    const merged: GroupedByDate[] = [];

    this.props.suggestions.orderedSuggestions.forEach((suggestionObj) => {
      const date = suggestionObj.date;
      const slots = suggestionObj.slots;
      const groupedSlots: SlotsWithAdjacentHandymenGrouped[] = [];

      slots.forEach((slot) => {
        const last = groupedSlots[groupedSlots.length - 1];
        if (last && last.handyman.id === slot.resourceId) {
          last.slots.push(slot);
        } else {
          const id = UUID.generate().value;
          const info =
            this.props.suggestions.resourceInformation[slot.resourceId];
          const resource = info.resource;
          groupedSlots.push({
            id,
            handyman: resource,
            slots: [slot],
          });
        }
      });

      merged.push({
        date,
        slotsWithAdjacentHandymenGrouped: groupedSlots,
      });
    });

    return merged;
  }

  private orderByBest(): SlotsWithAdjacentHandymenGrouped[] {
    const sorted = this.props.suggestions.orderedSuggestions
      .flatMap((suggestionObj) => {
        return suggestionObj.slots;
      })

      /* 
        Sort by prioritized first
        If there is no prioritized, sort by extra travel time
			If there is no extra travel time, sort by total travel time
			If there is no total travel time, return 0
		  */
      .sort((a, b) => {
        const aResource =
          this.props.suggestions.resourceInformation[a.resourceId].resource;
        const bResource =
          this.props.suggestions.resourceInformation[b.resourceId].resource;
        if (aResource.isPrioritized && !bResource.isPrioritized) {
          return -1;
        }

        if (!aResource.isPrioritized && bResource.isPrioritized) {
          return 1;
        }
        const aExtraTravelTime =
          a.ifEventWasSkippedScenario?.additionalTravelTimeInMs;
        const bExtraTravelTime =
          b.ifEventWasSkippedScenario?.additionalTravelTimeInMs;

        const aHasExtraTravelTime = !!aExtraTravelTime;
        const bHasExtraTravelTime = !!bExtraTravelTime;

        if (aHasExtraTravelTime && bHasExtraTravelTime) {
          return aExtraTravelTime - bExtraTravelTime;
        } else if (aHasExtraTravelTime && !bHasExtraTravelTime) {
          return -1;
        } else if (!aHasExtraTravelTime && bHasExtraTravelTime) {
          return 1;
        }

        const aTravelTime = a.totalTravelTimeInMs;
        const bTravelTime = b.totalTravelTimeInMs;
        const aHasTravelTime = !!aTravelTime;
        const bHasTravelTime = !!bTravelTime;
        if (aHasTravelTime && bHasTravelTime) {
          return aTravelTime - bTravelTime;
        } else if (aHasTravelTime && !bHasTravelTime) {
          return -1;
        } else if (!aHasTravelTime && bHasTravelTime) {
          return 1;
        }

        return 0;
      });

    const grouped: SlotsWithAdjacentHandymenGrouped[] = [];

    sorted.forEach((slot) => {
      const last = grouped[grouped.length - 1];
      if (last && last.handyman.id === slot.resourceId) {
        last.slots.push(slot);
      } else {
        const id = UUID.generate().value;
        const info =
          this.props.suggestions.resourceInformation[slot.resourceId];
        const resource = info.resource;
        grouped.push({
          id,
          handyman: resource,
          slots: [slot],
        });
      }
    });

    return grouped;
  }

  private groupByHandyman(): {
    handyman: Handyman.Type;
    slots: BookingSuggestions.SlotType[];
  }[] {
    const groupedByHandyman: {
      handyman: Handyman.Type;
      slots: BookingSuggestions.SlotType[];
    }[] = [];

    const sorted = this.bestFirst.flatMap((group) => group.slots);

    sorted.forEach((slot) => {
      const handymanId = slot.resourceId;
      const handyman =
        this.props.suggestions.resourceInformation[handymanId].resource;

      const found = groupedByHandyman.find((group) => {
        return group.handyman.id === handyman.id;
      });

      if (found) {
        found.slots.push(slot);
      } else {
        groupedByHandyman.push({
          handyman,
          slots: [slot],
        });
      }
    });

    return groupedByHandyman;
  }
}
