import { IconTool } from "@tabler/icons-react";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppToolsIcon = (props: Props) => {
  const { className, ...rest } = props;

  return <IconTool {...rest} className={props.className} />;
};
export default withIconSize(AppToolsIcon);
