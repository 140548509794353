import { IpisFormElement } from "@eljouren/domain";
import { motion } from "framer-motion";
import { UseFormReturn } from "react-hook-form";
import ClientUtils from "../../../../../utils/ClientUtils";
import { IpisTextInput, IpisTextInputProps } from "@ipis/client-essentials";

interface Props {
  className?: string;
  question: IpisFormElement.NumberInputType;
  form: UseFormReturn<any>;
  disabled?: boolean;
  inputProps?: IpisTextInputProps;
}

const ChecklistDecoupledNumberInputQuestion = (props: Props) => {
  const q = props.question;
  return (
    <motion.div
      className={ClientUtils.classNames(
        "grid w-full grid-cols-[minmax(0,1fr),auto] gap-2",
        props.className
      )}
    >
      <IpisTextInput
        type={"number"}
        id={q.id}
        name={`${q.id}.value`}
        required={q.required}
        label={q.title}
        tooltip={q.tooltip}
        richTextDescription={q.description}
        richTextNote={q.note}
        form={props.form}
        disabled={props.disabled}
        min={q.min}
        max={q.max}
        inputProps={props.inputProps}
      />
    </motion.div>
  );
};

export default ChecklistDecoupledNumberInputQuestion;
