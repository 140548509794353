import React, { useContext } from "react";
import { IpisButton } from "@ipis/client-essentials";
import { WorkOrder } from "@eljouren/domain";
import { FormInputContext } from "@ipis/client-essentials";

interface Props {
  className?: string;
  workOrder: WorkOrder.CustomerType;
  readOnly?: boolean;
}

const CustomerFormDescriptionComponent = (props: Props) => {
  const wo = props.workOrder;

  const ctx = useContext(FormInputContext);
  const readOnly = props.readOnly || ctx?.readOnly;

  return (
    <section className="flex flex-col gap-2">
      <h2 className="font-semibold">Uppdragsbeskrivning</h2>
      <p className="rounded border border-border-color bg-input-background p-4">
        {wo.description}
      </p>
      {!readOnly && (
        <IpisButton
          variant="text"
          label="Lägg till mer information"
          size="sm"
          underline
          className="ml-auto"
        />
      )}
    </section>
  );
};

export default CustomerFormDescriptionComponent;
