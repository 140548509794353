import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";

import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import WorkOrderOverviewCard from "./WorkOrderOverviewCard";
import SupportAgentIcon from "../../../../components/icons/SupportAgentIcon";

interface Props {
  className?: string;
}

const OverviewCoordinatorSection = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);

  let coordinatorName = "Inget namn på uppdragssamordnare";
  if (order.coordinator.firstName && order.coordinator.lastName) {
    coordinatorName = `${order.coordinator.firstName} ${order.coordinator.lastName}`;
  }

  return (
    <WorkOrderOverviewCard
      className={ClientUtils.twClassNames(
        "grid grid-cols-[auto,minmax(0,1fr)]",
        props.className
      )}
    >
      <span className="my-auto flex rounded-full border bg-brand-blue-100 p-2 text-brand-blue-300">
        <SupportAgentIcon size={40} />
      </span>
      <div className="flex flex-col p-4">
        <span className="text-xs italic text-dark-gray text-opacity-80">
          Uppdragssamordnare
        </span>
        <h3 className="font-semibold">
          <span className="sr-only">Namn på uppdragssamordnare:</span>
          <span data-test-id="work-order-coordinator-name">
            {coordinatorName}
          </span>
        </h3>
        {!!order.coordinator.phone && (
          <a
            className="mr-auto text-blue-600 hover:text-blue-400"
            href={`tel:${order.coordinator.phone}`}
          >
            {order.coordinator.phone}
          </a>
        )}
        {!!order.coordinator.email && (
          <a
            className="mr-auto text-blue-600 hover:text-blue-400"
            href={`mailto:${order.coordinator.email}`}
          >
            {order.coordinator.email}
          </a>
        )}
      </div>
      {!!order.internalDescription && (
        <div className="col-span-2">
          <span className="sr-only">Kommentar från uppdragssamordnare:</span>
          <pre
            data-test-id="work-order-internal-description"
            className="whitespace-pre-wrap p-2"
          >
            {order.internalDescription}
          </pre>
        </div>
      )}
    </WorkOrderOverviewCard>
  );
};

export default OverviewCoordinatorSection;
