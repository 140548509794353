import { Checklist } from "@eljouren/domain";
import ClientUtils from "./../../../../utils/ClientUtils";

import { UseFormReturn } from "react-hook-form";
import A11yDropdown from "../../../../components/common/dropdown/A11YDropdown";
import { AppFormTextField } from "../../../../components/common/text-fields/AppFormTextField";
import ChecklistItemSelect from "./ChecklistItemSelect";
import ChecklistItemText from "./ChecklistItemText";
import ChecklistItemYesNo from "./ChecklistItemYesNo";

export interface ChecklistItemProps {
  className?: string;
  item: Checklist.ListItemType;
  form: UseFormReturn<any>;
  readOnly: boolean;
}

const ChecklistItem = (props: ChecklistItemProps) => {
  const { item } = props;
  return (
    <div
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2 px-2 py-4 even:bg-slate-200/30 md:px-4",
        props.className
      )}
      data-test-class="checklist-item"
      data-sort-order={item.sortOrder ?? "null"}
    >
      <MainAnswerElement {...props} />
      {/* 
		  This wont work for the image upload currently
		*/}
      {item.allowComment && props.item.type !== "image" && (
        <A11yDropdown
          wrapperId={`checklist-item-comment-dropdown-${item.id}`}
          contentId={`checklist-item-comment-${item.id}`}
          buttonClassName="text-sm italic ml-auto flex gap-2 items-center"
          title={"Visa kommentar"}
          contentWrapperClassName="pt-2"
          preventHideOnOutsideClick
        >
          <AppFormTextField
            inputClassName="bg-white/20 border-gray-300/40"
            labelClassName="italic text-sm"
            register={props.form.register}
            name={item.id + ".comment"}
            htmlAttributes={{
              placeholder: "Ange kommentar...",
              readOnly: props.readOnly,
            }}
            data={{
              "checklist-item-type": "comment",
            }}
          />
        </A11yDropdown>
      )}
    </div>
  );
};

const MainAnswerElement = (props: ChecklistItemProps) => {
  const { item } = props;
  if (item.type === "yes/no") {
    return <ChecklistItemYesNo {...props} />;
  } else if (item.type === "text") {
    return <ChecklistItemText {...props} />;
  } else if (item.type === "multipleChoice") {
    return (
      <ChecklistItemSelect
        {...props}
        options={item.options?.map((opt) => ({ value: opt, label: opt })) ?? []}
      />
    );
  }

  return <></>;
};

export default ChecklistItem;
