import {
  IconCaretDown,
  IconCaretUp,
  IconCaretLeft,
  IconCaretRight,
  IconCaretDownFilled,
  IconCaretUpFilled,
  IconCaretLeftFilled,
  IconCaretRightFilled,
} from "@tabler/icons-react";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  direction: "up" | "down" | "left" | "right";
  filled?: boolean;
}

const AppCaretIcon = (props: Props) => {
  const { className, direction, filled, ...rest } = props;

  if (filled) {
    switch (direction) {
      case "up":
        return <IconCaretUpFilled className={className} {...rest} />;
      case "down":
        return <IconCaretDownFilled className={className} {...rest} />;
      case "left":
        return <IconCaretLeftFilled className={className} {...rest} />;
      case "right":
        return <IconCaretRightFilled className={className} {...rest} />;
    }
  }

  switch (direction) {
    case "up":
      return <IconCaretUp className={className} {...rest} />;
    case "down":
      return <IconCaretDown className={className} {...rest} />;
    case "left":
      return <IconCaretLeft className={className} {...rest} />;
    case "right":
      return <IconCaretRight className={className} {...rest} />;
  }
};
export default withIconSize(AppCaretIcon);
