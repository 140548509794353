"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ToastList = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var Toast_1 = require("./Toast");
var ToastList = function (props) {
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.ul, { className: "fixed left-1/2 z-50 mt-8 flex max-w-[min(90vw,500px)] -translate-x-1/2 flex-col-reverse gap-2 justify-self-center", exit: {
            opacity: 0.5,
        }, children: props.toasts.map(function (toast) {
            return ((0, jsx_runtime_1.jsx)(Toast_1.Toast, __assign({}, toast, { closeToast: props.closeToast }), "toastWithId".concat(toast.id)));
        }) }));
};
exports.ToastList = ToastList;
