"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFileV2 = void 0;
var IpisFileRelation_1 = require("../../file/IpisFileRelation");
var CollectionType_1 = require("../../shared-schemas/CollectionType");
var UploaderSource_1 = require("../../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var FileTag_1 = require("./FileTag");
var IpisFileV2;
(function (IpisFileV2) {
    IpisFileV2.Schema = zod_1.z.object({
        guid: zod_1.z.string(),
        recordId: zod_1.z.string(),
        name: zod_1.z.string(),
        description: zod_1.z.string().nullable(),
        extension: zod_1.z.string(),
        nameWithExtension: zod_1.z.string(),
        mimeType: zod_1.z.string(),
        size: zod_1.z.number(),
        /*
          So that we can pass it to the client as a string
        */
        createdDate: zod_1.z.union([
            zod_1.z
                .string()
                .transform(function (val) { return new Date(val); })
                .refine(function (val) { return !isNaN(Number(val)); }),
            zod_1.z.date(),
        ]),
        uploadedBy: UploaderSource_1.UploaderSource.Schema,
        collectionType: CollectionType_1.CollectionType.Schema,
        src: zod_1.z.string(),
        relations: IpisFileRelation_1.IpisFileRelation.Schema.array().nullable(),
        tag: FileTag_1.FileTag.Schema.nullable(),
    });
})(IpisFileV2 || (IpisFileV2 = {}));
exports.IpisFileV2 = IpisFileV2;
