import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../hooks/use-media-query";
import HandymanDesktopLandingPage from "./desktop-specific/HandymanDesktopLandingPage";
import HandymanMobileLandingPage from "./mobile-specific/HandymanMobileLandingPage";

interface Props {
  className?: string;
}

export enum HandymanLandingPageTab {
  dashboard = "Översikt",
  workingHours = "Arbetstider",
  calendar = "Kalender",
}

const HandymanLandingPage = (props: Props) => {
  const isDesktop = useMediaQuery(TailwindBreakpoint.xl);
  if (isDesktop) {
    return <HandymanDesktopLandingPage {...props} />;
  }
  return <HandymanMobileLandingPage {...props} />;
};

export default HandymanLandingPage;
