"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadFilesOutput = void 0;
var zod_1 = require("zod");
var UploadedFileReturnInfo_1 = require("./UploadedFileReturnInfo");
var FileInputInfo_1 = require("./FileInputInfo");
var InvalidInputFile_1 = require("./InvalidInputFile");
var UploaderSource_1 = require("../../../../shared-schemas/UploaderSource");
var UploadFilesOutput;
(function (UploadFilesOutput) {
    var FailedFileSchema = zod_1.z.object({
        file: FileInputInfo_1.FileInputInfo.Schema,
        error: zod_1.z.string().nullable(),
    });
    var Base = zod_1.z.object({
        recordId: zod_1.z.string(),
        uploadedBy: UploaderSource_1.UploaderSource.Schema,
    });
    UploadFilesOutput.Rejected = zod_1.z.object({
        status: zod_1.z.literal("rejected"),
        statusCode: zod_1.z.literal(400),
        rejectedFiles: InvalidInputFile_1.InvalidInputFile.Schema.array(),
        failedFiles: FailedFileSchema.array().max(0),
        uploadedFiles: UploadedFileReturnInfo_1.UploadedFileReturnInfo.Schema.array().max(0),
    });
    UploadFilesOutput.RejectedAndFailed = Base.extend({
        status: zod_1.z.literal("rejectedAndFailed"),
        statusCode: zod_1.z.literal(500),
        rejectedFiles: InvalidInputFile_1.InvalidInputFile.Schema.array(),
        failedFiles: FailedFileSchema.array(),
        uploadedFiles: UploadedFileReturnInfo_1.UploadedFileReturnInfo.Schema.array().max(0),
    });
    UploadFilesOutput.Failed = Base.extend({
        status: zod_1.z.literal("failed"),
        statusCode: zod_1.z.literal(500),
        rejectedFiles: InvalidInputFile_1.InvalidInputFile.Schema.array().max(0),
        failedFiles: FailedFileSchema.array(),
        uploadedFiles: UploadedFileReturnInfo_1.UploadedFileReturnInfo.Schema.array().max(0),
    });
    UploadFilesOutput.Fulfilled = Base.extend({
        status: zod_1.z.literal("fulfilled"),
        statusCode: zod_1.z.literal(200),
        rejectedFiles: InvalidInputFile_1.InvalidInputFile.Schema.array().max(0),
        failedFiles: FailedFileSchema.array().max(0),
        uploadedFiles: UploadedFileReturnInfo_1.UploadedFileReturnInfo.Schema.array(),
    });
    UploadFilesOutput.PartiallyFulfilled = Base.extend({
        status: zod_1.z.literal("partiallyFulfilled"),
        statusCode: zod_1.z.literal(207),
        rejectedFiles: InvalidInputFile_1.InvalidInputFile.Schema.array(),
        failedFiles: FailedFileSchema.array(),
        uploadedFiles: UploadedFileReturnInfo_1.UploadedFileReturnInfo.Schema.array(),
    });
    UploadFilesOutput.Schema = zod_1.z.union([
        UploadFilesOutput.Rejected,
        UploadFilesOutput.RejectedAndFailed,
        UploadFilesOutput.Failed,
        UploadFilesOutput.Fulfilled,
        UploadFilesOutput.PartiallyFulfilled,
    ]);
})(UploadFilesOutput || (UploadFilesOutput = {}));
exports.UploadFilesOutput = UploadFilesOutput;
