"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentResponse = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var PaymentOrderLine_1 = require("./PaymentOrderLine");
var PaymentResponse;
(function (PaymentResponse) {
    var ActionSchema = centralized_zod_1.z.object({
        actionId: centralized_zod_1.z.string(),
        type: centralized_zod_1.z.enum(["CREATE", "MODIFY_ORDER", "CAPTURE", "REFUND", "CANCEL"]),
        created: centralized_zod_1.z.string(),
        creator: centralized_zod_1.z.string(),
        orderLines: PaymentOrderLine_1.PaymentOrderLine.Schema.array().min(1).max(1000),
        transactionId: centralized_zod_1.z.string().optional(),
    });
    var PossibleActionSchema = centralized_zod_1.z.object({
        action: centralized_zod_1.z.enum([
            "PARTIAL_CAPTURE",
            "CAPTURE",
            "PARTIAL_REFUND",
            "REFUND",
            "PARTIAL_CANCEL",
            "CANCEL",
        ]),
    });
    var PaymentStatusSchema = centralized_zod_1.z.enum([
        "TASK_REDIRECTION_REQUIRED",
        "INSPECTION",
        "FROZEN",
        "ACCEPTED",
        "REJECTED",
        "CREDIT_APPROVED",
        "INSPECTION",
    ]);
    var RejectedReasonEnum = centralized_zod_1.z.enum([
        "UNKNOWN",
        "TECHNICAL_ERROR",
        "CREDIT_DENIED",
        "PAYMENT_FROZEN",
        "TIMEOUT",
        "ABORTED_BY_CUSTOMER",
        "INSUFFICIENT_FUNDS",
        "CANCELED",
    ]);
    PaymentResponse.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        created: centralized_zod_1.z.string(),
        countryCode: centralized_zod_1.z.string(),
        storeId: centralized_zod_1.z.string(),
        paymentMethod: centralized_zod_1.z.object({
            name: centralized_zod_1.z.string(),
        }),
        customer: centralized_zod_1.z.object({
            customerType: centralized_zod_1.z.enum(["NATURAL", "LEGAL"]),
            deliveryAddress: centralized_zod_1.z.object({
                fullName: centralized_zod_1.z.string().min(0).max(50).optional(),
                firstName: centralized_zod_1.z.string().min(0).max(50).optional(),
                lastName: centralized_zod_1.z.string().min(0).max(50).optional(),
                addressRow1: centralized_zod_1.z.string().min(1).max(100),
                addressRow2: centralized_zod_1.z.string().min(0).max(100).optional(),
                postalArea: centralized_zod_1.z.string().min(1).max(50),
                postalCode: centralized_zod_1.z.string().min(1).max(10).describe("Pattern [ \\d]+"),
                countryCode: centralized_zod_1.z.string(),
            }),
            email: centralized_zod_1.z.string().email().optional(),
            governmentId: centralized_zod_1.z.string(),
            mobilePhone: centralized_zod_1.z.string().optional(),
            phone: centralized_zod_1.z.string().optional(),
        }),
        application: centralized_zod_1.z.object({
            approvedCreditLimit: centralized_zod_1.z.number().int().optional(),
            requestedCreditLimit: centralized_zod_1.z.number(),
            reference: centralized_zod_1.z.number().optional(),
        }),
        order: centralized_zod_1.z.object({
            orderReference: centralized_zod_1.z
                .string()
                .min(1)
                .max(32)
                .describe("Order reference, this is your internal order reference, if it's not provided then it will be set by Resurs Bank"),
            actionLog: ActionSchema.array(),
            possibleActions: PossibleActionSchema.array(),
            totalOrderAmount: centralized_zod_1.z.number(),
            canceledAmount: centralized_zod_1.z.number(),
            authorizedAmount: centralized_zod_1.z.number(),
            capturedAmount: centralized_zod_1.z.number(),
            refundedAmount: centralized_zod_1.z.number(),
        }),
        metadata: centralized_zod_1.z.object({
            creator: centralized_zod_1.z.string().min(1).max(50),
            externalCustomerId: centralized_zod_1.z.string().min(0).max(20).optional(),
            externalInvoiceReference: centralized_zod_1.z.string().min(0).max(20).optional(),
            custom: centralized_zod_1.z
                .object({
                key: centralized_zod_1.z.string().min(1).max(50),
                value: centralized_zod_1.z.string().min(0).max(1000),
            })
                .array()
                .optional(),
        }),
        status: PaymentStatusSchema,
        rejectedReason: centralized_zod_1.z
            .object({
            category: RejectedReasonEnum.optional(),
        })
            .optional(),
    });
})(PaymentResponse || (PaymentResponse = {}));
exports.PaymentResponse = PaymentResponse;
