import { AnimatePresence, MotionProps, motion } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import Logo from "../../../../components/common/brand/Logo";
import ClientUtils from "../../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext, {
  InvoiceRouteView,
} from "../CustomerWorkOrderInvoiceContext";
import InvoiceCanceledPaymentView from "../views/InvoiceCanceledPaymentView";
import InvoiceErrorView from "../views/InvoiceErrorView";
import InvoiceFailedPaymentView from "../views/InvoiceFailedPaymentView";
import InvoiceFailedToConfirmView from "../views/InvoiceFailedToConfirmView";
import InvoiceFormView from "../views/InvoiceFormView";
import InvoiceInvalidView from "../views/InvoiceInvalidView";
import InvoiceLoadingView from "../views/InvoiceLoadingView";
import InvoiceRefundedPaymentView from "../views/InvoiceRefundedPaymentView";
import InvoiceSuccessfulPaymentView from "../views/InvoiceSuccessfulPaymentView";
import InvoiceInspectionView from "../views/InvoiceInspectionView";
import InvoiceCreditApprovedView from "../views/InvoiceCreditApprovedView";

const motionProps: MotionProps = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    x: 1400,
  },
  transition: {
    duration: 1,
  },
};
const logoContainerHeight = 120;
const formMotionProps: MotionProps = {
  initial: {
    opacity: 0,
    y: 100 - logoContainerHeight,
  },
  animate: {
    opacity: 1,
    y: -logoContainerHeight,
  },
  exit: {
    opacity: 0,
    x: 1400,
    y: -logoContainerHeight,
  },
  transition: {
    duration: 1,
  },
};

const style: React.CSSProperties = {
  gridColumn: 1,
  gridRow: 1,
  marginBottom: "auto",
};

interface Props {
  className?: string;
}

const CustomerWorkOrderInvoiceViewRouter = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);

  const firstRenderDateRef = useRef(new Date());
  const [fakeLoading, setFakeLoading] = useState(true);
  const contextView = ctx.view;
  const mostRecentLoadingViewRef = useRef<InvoiceRouteView | null>(null);

  function isLoadingView(
    view: InvoiceRouteView
  ): view is
    | InvoiceRouteView.expectingAny
    | InvoiceRouteView.expectingFailure
    | InvoiceRouteView.expectingSuccess {
    return (
      view === InvoiceRouteView.expectingAny ||
      view === InvoiceRouteView.expectingFailure ||
      view === InvoiceRouteView.expectingSuccess
    );
  }

  if (isLoadingView(contextView)) {
    mostRecentLoadingViewRef.current = contextView;
  }

  function getView(): InvoiceRouteView {
    if (fakeLoading) {
      return mostRecentLoadingViewRef.current ?? InvoiceRouteView.expectingAny;
    }

    return contextView;
  }

  const view = getView();

  const isLoading =
    view === InvoiceRouteView.expectingFailure ||
    view === InvoiceRouteView.expectingSuccess ||
    view === InvoiceRouteView.expectingAny;

  const failedToConfirm =
    view === InvoiceRouteView.failedToConfirmFailure ||
    view === InvoiceRouteView.failedToConfirmSuccess;

  const isInvalid =
    view === InvoiceRouteView.workOrderNotReadyToPay ||
    view === InvoiceRouteView.paymentFlowNotSupported ||
    view === InvoiceRouteView.outOfTime;
  /* 
      Make sure that the loading screen is shown for at least 2 seconds.
  */
  useEffect(() => {
    if (!fakeLoading) {
      return;
    }

    const timeSinceFirstRender =
      new Date().getTime() - firstRenderDateRef.current.getTime();
    const timeoutDelay = 2000 - timeSinceFirstRender;
    const timeoutId = setTimeout(() => {
      setFakeLoading(false);
    }, timeoutDelay);

    return () => clearTimeout(timeoutId);
  }, [view, fakeLoading, setFakeLoading]);

  const showHeader = view !== InvoiceRouteView.paymentForm;

  return (
    <section
      className={ClientUtils.classNames(
        "grid h-full w-full grid-cols-1",
        !showHeader && "grid-rows-1",
        showHeader && "grid-rows-[auto,minmax(0,1fr)]"
      )}
      style={{
        paddingTop: showHeader ? 0 : logoContainerHeight,
      }}
    >
      {showHeader && (
        <motion.header
          className="flex justify-center overflow-visible py-8"
          style={{
            height: logoContainerHeight,
          }}
        >
          <motion.figure layoutId="invoiceLogo" layout>
            <Logo
              onlyShowSetBrand
              withColor
              wrapperClassName="w-48 mx-auto max-w-full"
            />
          </motion.figure>
        </motion.header>
      )}
      <main className="grid h-full w-full grid-cols-1 grid-rows-1">
        <AnimatePresence>
          {isLoading && (
            <InvoiceLoadingView
              motionProps={motionProps}
              view={view}
              key={view}
              style={style}
            />
          )}
          {failedToConfirm && (
            <InvoiceFailedToConfirmView
              motionProps={motionProps}
              view={view}
              key={view}
              style={style}
            />
          )}
          {isInvalid && (
            <InvoiceInvalidView
              motionProps={motionProps}
              style={style}
              view={view}
            />
          )}
          {view === InvoiceRouteView.paymentForm && (
            <InvoiceFormView
              motionProps={formMotionProps}
              style={{
                ...style,
                height: `calc(100% + ${logoContainerHeight}px)`,
              }}
            />
          )}
          {view === InvoiceRouteView.confirmedSuccess && (
            <InvoiceSuccessfulPaymentView
              motionProps={motionProps}
              style={style}
            />
          )}
          {view === InvoiceRouteView.confirmedCanceled && (
            <InvoiceCanceledPaymentView
              motionProps={motionProps}
              style={style}
            />
          )}
          {view === InvoiceRouteView.confirmedRefunded && (
            <InvoiceRefundedPaymentView
              motionProps={motionProps}
              style={style}
            />
          )}
          {view === InvoiceRouteView.confirmedFailure && (
            <InvoiceFailedPaymentView motionProps={motionProps} style={style} />
          )}
          {(view === InvoiceRouteView.failedToFetchRequiredData ||
            view === InvoiceRouteView.authenticationError) && (
            <InvoiceErrorView
              motionProps={motionProps}
              style={style}
              view={view}
            />
          )}
          {view === InvoiceRouteView.inspectionRequired && (
            <InvoiceInspectionView motionProps={motionProps} style={style} />
          )}
          {view === InvoiceRouteView.creditApproved && (
            <InvoiceCreditApprovedView
              motionProps={motionProps}
              style={style}
            />
          )}
        </AnimatePresence>
      </main>
    </section>
  );

  /*   if (status === "success") {
    return <WorkOrderInvoiceSuccessfulPayment />;
  }

  if (status === "fail") {
    return <WorkOrderInvoiceFailedPayment motionProps={motionProps} />;
  } */
};

export default CustomerWorkOrderInvoiceViewRouter;
