import { BankIdSignResponse } from "@eljouren/bank-id-schemas";
import { IpisButton } from "@ipis/client-essentials";
import { AppLoaderV2 } from "../../../components/__v2__/loaders/AppLoaderV2";
import {
  BankIdCollectFailedReason,
  BankIdCollectFlowStep,
  useBankIdCollect,
} from "../../../components/external-brands/bank-id/BankIdHooks";
import BankIdQRCodeV2 from "../../../components/external-brands/bank-id/BankIdQrCodeV2";
import { useTailwindColors } from "../../../hooks/tailwind-hooks";
import AppCheckmarkIcon from "../../../components/icons/AppCheckmarkIcon";

interface Props {
  className?: string;
  response: BankIdSignResponse.ClientResponseType;
  onCancel: () => void;
  afterSuccessfulSign: () => void;
}

const BankIdSignComponentV2 = (props: Props) => {
  const colors = useTailwindColors();
  const res = useBankIdCollect({
    sessionId: props.response.sessionId,
    //onSignComplete: props.afterSuccessfulSign,
    //onSignCompleteTimeoutInMs: 2500,
  });

  async function onCancel() {
    try {
      await res.cancel();
    } catch (er) {
      console.log("Failed to cancel bankid signing");
    }
    props.onCancel();
  }

  function onComplete() {
    props.afterSuccessfulSign();
  }

  function getHeading(): string {
    const state = res.flowState;
    if (state.hasFailed) {
      switch (state.failedReason) {
        case BankIdCollectFailedReason.START_FAILED:
          return "Det gick inte att starta signeringen";
        case BankIdCollectFailedReason.POST_PROCESSING_FAILED:
          return "Vi kunde inte spara signeringen";
        case BankIdCollectFailedReason.UNKNOWN:
          return "Signeringen misslyckades";
      }
    } else {
      switch (state.step) {
        case BankIdCollectFlowStep.WAITING_FOR_APP_OPEN:
          return "Öppna BankID-appen";
        case BankIdCollectFlowStep.SIGN_STARTED:
          return "Skriv under med BankID-appen";
        case BankIdCollectFlowStep.SIGN_COMPLETE:
          return "Signeringen är klar";
      }
    }
  }

  function getHeaderText(): string | undefined {
    const state = res.flowState;
    if (state.hasFailed) {
      if (
        state.failedReason === BankIdCollectFailedReason.POST_PROCESSING_FAILED
      ) {
        return "Vi kan för närvarande inte slutföra signeringen. Försök igen senare.";
      }

      if (state.failedReason === BankIdCollectFailedReason.UNKNOWN) {
        return "Det gick inte att slutföra signeringen. Försök igen senare.";
      }
    }

    if (state.step === BankIdCollectFlowStep.SIGN_STARTED) {
      return "Läs noga igenom texten i BankID-appen och signera för att slutföra.";
    }

    if (state.step === BankIdCollectFlowStep.SIGN_COMPLETE) {
      return "Signeringen är klar. Du kan nu stänga BankID-appen.";
    }

    return undefined;
  }

  const headerText = getHeaderText();

  console.log(res.data);

  return (
    <section className="flex flex-col gap-8 py-4">
      <header className="grid-cols grid gap-4 pb-8">
        <h3 className="text-xl">{getHeading()}</h3>
        {!!headerText && <p className="text-dark-500">{headerText}</p>}
        {/* {res.isCurrentlySigning && <p className="text-dark-500">
          Läs noga igenom texten i BankID-appen och signera för att slutföra.
        </p>}
        {res.isCompletedAndHandled && <p className="text-dark-500">
          Signeringen är klar. Du kan nu stänga BankID-appen.
          </p>} */}
      </header>

      {res.flowState.step === BankIdCollectFlowStep.SIGN_STARTED && (
        <AppLoaderV2 />
      )}
      {res.flowState.step === BankIdCollectFlowStep.SIGN_COMPLETE && (
        <AppCheckmarkIcon
          size={48}
          variant="circle"
          className="mx-auto fill-success text-light-background"
        />
      )}
      {res.flowState.step === BankIdCollectFlowStep.WAITING_FOR_APP_OPEN && (
        <>
          <a
            className="rounded bg-dark-950  px-2 py-4 text-center font-semibold text-light-background hover:bg-dark-800"
            href={`bankid:///?autostarttoken=${props.response.autoStartToken}&redirect=null`}
          >
            <span>Öppna BankID på denna enhet</span>
          </a>
          <div
            className="relative flex justify-center"
            style={{
              background: `linear-gradient(to bottom, #00000000 47%, ${colors["border-color"]} 47%, ${colors["border-color"]} 53%, #00000000 53%)`,
            }}
          >
            <span className="bg-white px-2 text-semi-faded">ELLER</span>
          </div>
          <section className="flex flex-col gap-2 text-lg">
            <h3>Har du BankID på en annan enhet?</h3>
            <p className="text-sm text-semi-faded">
              Öppna din BankID-app och klicka på QR-ikonen för att skanna
              QR-koden nedan.
            </p>
            <div className="flex justify-center py-4">
              <BankIdQRCodeV2 sessionId={props.response.sessionId} />
            </div>
          </section>
        </>
      )}
      <footer className="flex justify-center">
        {res.flowState.step === BankIdCollectFlowStep.SIGN_COMPLETE && (
          <IpisButton label="Slutför" onClick={onComplete} variant="text" />
        )}
        {res.flowState.step !== BankIdCollectFlowStep.SIGN_COMPLETE && (
          <IpisButton
            label="Avbryt och gå tillbaka"
            onClick={onCancel}
            variant="text"
          />
        )}
      </footer>
    </section>
  );
};

export default BankIdSignComponentV2;
