"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SlideUpModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var react_dom_1 = require("react-dom");
var user_interaction_hooks_1 = require("../../hooks/user-interaction-hooks");
var client_utils_1 = require("../../utils/client-utils");
var IpisButton_1 = require("../buttons/IpisButton");
var SlideUpModal = function (props) {
    if (props.anchorToId) {
        var element = document.getElementById(props.anchorToId);
        if (element) {
            return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(ActualSlideUpModal, __assign({}, props, { position: "absolute" })), element);
        }
    }
    return (0, jsx_runtime_1.jsx)(ActualSlideUpModal, __assign({}, props, { position: "fixed" }));
};
exports.SlideUpModal = SlideUpModal;
var ActualSlideUpModal = function (props) {
    var ref = (0, react_1.useRef)(null);
    (0, user_interaction_hooks_1.useOnOutsideClick)({
        ref: ref,
        callback: function () {
            var _a;
            if (props.isOpen && !props.preventCloseOnOutsideClick) {
                (_a = props.onClose) === null || _a === void 0 ? void 0 : _a.call(props);
            }
        },
    });
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { children: props.isOpen && ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [props.fadeOutOtherContent && ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "fixed left-0 top-0 z-20 h-full w-full bg-black bg-opacity-50", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 } })), (0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { ref: ref, className: (0, client_utils_1.classNames)("bottom-0 left-1/2 z-30 flex w-[600px] max-w-full -translate-x-1/2 flex-col gap-4 rounded-t-lg bg-light-background p-8 pb-16", props.position === "fixed" ? "fixed" : "absolute", props.className), initial: { y: "150%", x: "-50%", opacity: 0 }, animate: { y: "0%", x: "-50%", opacity: 1 }, exit: { y: "150%", x: "-50%", opacity: 0 }, transition: {
                        //remove spring
                        type: "tween",
                    }, children: [(!!props.heading || !!props.onClose) && ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.header, { className: "flex justify-between", children: [!!props.heading && ((0, jsx_runtime_1.jsx)("h3", { className: "text-xl", children: props.heading })), props.onClose && ((0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { onClick: props.onClose, onlyIcon: true, label: "", className: "ml-auto", variant: "text", icon: {
                                        position: "leading",
                                        type: "close",
                                    } }))] })), props.children] })] })) }));
};
