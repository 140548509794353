import ClientUtils from "./../../../utils/ClientUtils";
import { motion } from "framer-motion";

interface Props {
  className?: string;
  atIndex: number;
  onActiveClick?(index: number): void;
  steps: string[];
}

const FormBreadcrumb = (props: Props) => {
  return (
    <ol
      className={ClientUtils.twClassNames(
        "mx-auto flex w-full max-w-full justify-center gap-1 overflow-x-auto py-4 md:grid md:gap-2 md:overflow-hidden md:px-4",
        props.className
      )}
      data-test-id="form-breadcrumb"
      style={{
        gridTemplateColumns: `repeat(${props.steps.length}, minmax(0, 1fr))`,
      }}
    >
      {props.steps.map((step, index) => (
        <Item
          key={step}
          text={step}
          itemIndex={index}
          atIndex={props.atIndex}
          onActiveClick={props.onActiveClick}
        />
      ))}
    </ol>
  );
};

const Item = (props: {
  text: string;
  itemIndex: number;
  atIndex: number;
  onActiveClick?(index: number): void;
}) => {
  const isHighlighted = props.itemIndex <= props.atIndex;
  const isActiveStep = props.atIndex === props.itemIndex;
  const isPreviousStep = isHighlighted && !isActiveStep;

  return (
    <motion.li
      onClick={() => {
        if (isPreviousStep && props.onActiveClick) {
          props.onActiveClick(props.itemIndex);
        }
      }}
      className={ClientUtils.twClassNames(
        /* 
          Styling found in App.css
        */
        "form-breadcrumb-item relative text-sm md:text-base",
        isActiveStep && "active",
        "flex w-full gap-1 border-main-bg-light px-1 py-1 md:gap-2 md:px-4",
        isPreviousStep && !!props.onActiveClick && "cursor-pointer"
      )}
    >
      {isActiveStep && <span className="sr-only">Nuvarande steg:</span>}
      {isPreviousStep && <span className="sr-only">Avklarat steg:</span>}
      <span className="hidden 2xs:flex">{props.itemIndex + 1}.</span>
      <span>{props.text}</span>
    </motion.li>
  );
};

export default FormBreadcrumb;
