import { motion } from "framer-motion";
import { useContext } from "react";
import ChecklistDecoupledMultipleChoiceQuestion from "../checklist-question-types/multiple-choice/ChecklistDecoupledMultipleChoiceQuestion";
import ChecklistDecoupledNumberInputQuestion from "../checklist-question-types/number-input/ChecklistDecoupledNumberInputQuestion";
import ChecklistDecoupledRepeaterQuestion from "../checklist-question-types/repeater/ChecklistDecoupledRepeaterQuestion";
import ChecklistDecoupledRichTextQuestion from "../checklist-question-types/rich-text/ChecklistDecoupledRichTextQuestion";
import ChecklistDecoupledTextInputQuestion from "../checklist-question-types/text-input/ChecklistDecoupledTextInputQuestion";
import ChecklistDecoupledYesNoQuestion from "../checklist-question-types/yes-no/ChecklistDecoupledYesNoQuestion";
import WizardFormWalkthroughPopup from "./WizardFormWalkthroughPopup";
import { WizardFormContext, WizardFormElement } from "./wizard-form-types";

interface Props {
  element: WizardFormElement;
}

const WizardFormInputRenderer = (props: Props) => {
  const ctx = useContext(WizardFormContext);
  const el = props.element;
  const type = el.typeOfQuestion;
  const isFocused = ctx.isFocused(el);
  const walkthroughDone = ctx.hasGoneThroughWalkthrough;
  const obscure = !walkthroughDone && !isFocused;

  const withState = ctx.validatedChecklistDo.getElement(el.id);
  const conditionsMet = withState.state?.meetsConditions;

  if (!conditionsMet) {
    return <></>;
  }

  return (
    <motion.fieldset className="relative">
      <WizardFormWalkthroughPopup el={props.element} isFocused={isFocused} />
      <motion.div
        animate={{
          opacity: obscure ? 0.5 : 1,
          filter: obscure ? "blur(2px)" : "blur(0px)",
        }}
      >
        {type === "multiple-choice" && (
          <ChecklistDecoupledMultipleChoiceQuestion
            question={el}
            form={ctx.form}
            onBlur={() => ctx.setFocus(props.element, false)}
            onFocus={() => ctx.setFocus(props.element, true)}
          />
        )}
        {type === "number" && (
          <ChecklistDecoupledNumberInputQuestion
            question={el}
            form={ctx.form}
            inputProps={{
              onBlur: () => ctx.setFocus(props.element, false),
              onFocus: () => ctx.setFocus(props.element, true),
            }}
          />
        )}
        {type === "text" && (
          <ChecklistDecoupledTextInputQuestion
            question={el}
            form={ctx.form}
            inputProps={{
              onBlur: () => ctx.setFocus(props.element, false),
              onFocus: () => ctx.setFocus(props.element, true),
            }}
          />
        )}
        {type === "repeater" && (
          <ChecklistDecoupledRepeaterQuestion
            checklist={ctx.checklist}
            question={el}
            form={ctx.form}
            validatedChecklist={ctx.validatedChecklistDo}
            onBlur={() => ctx.setFocus(props.element, false)}
            onFocus={() => ctx.setFocus(props.element, true)}
          />
        )}
        {type === "rich-text" && (
          <ChecklistDecoupledRichTextQuestion
            question={el}
            form={ctx.form}
            setFocus={(focused) => ctx.setFocus(el, focused)}
          />
        )}
        {type === "yes/no" && (
          <ChecklistDecoupledYesNoQuestion
            question={el}
            form={ctx.form}
            onBlur={() => ctx.setFocus(props.element, false)}
            onFocus={() => ctx.setFocus(props.element, true)}
          />
        )}
      </motion.div>
    </motion.fieldset>
  );
};

export default WizardFormInputRenderer;
