"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RichTextPreTag = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var client_utils_1 = require("../../../../utils/client-utils");
var RichTextPreTag = function (props) {
    // whitespace-pre-wrap text-xs text-dark-500
    return ((0, jsx_runtime_1.jsx)("pre", { className: (0, client_utils_1.classNames)("whitespace-pre-wrap break-words", props.className), dangerouslySetInnerHTML: { __html: props.html } }));
};
exports.RichTextPreTag = RichTextPreTag;
