import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRepos } from "../../../hooks/use-repos";
import { GlobalContext } from "../../../top-level-contexts";
import LoadingContent from "../../common/loaders/LoadingContent";
import SomethingWentWrong from "../../on-error/SomethingWentWrong";
import ClientUtils from "../../../utils/ClientUtils";

export const StaffContext = React.createContext<{
  staffGuid: string;
}>({} as never);

const withStaffGuid = (WrappedComponent: () => React.ReactElement) => () => {
  const params = useParams();
  const staffGuid = params["staffGuid"] || "";
  const { authRepo } = useRepos();

  const { signInState } = useContext(GlobalContext);

  const res = useQuery(
    ["staffSignInState", staffGuid],
    () => {
      if (signInState.signedInAs === "staff") {
        return 200;
      }
      return authRepo.staffAuthentication(staffGuid);
    },
    {
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (signInState.signedInAs && signInState.signedInAs !== "staff") {
      authRepo.signOut();
    }
  }, [signInState.signedInAs, authRepo]);

  return (
    <LoadingContent loading={res.isLoading} renderContentSeparately>
      {ClientUtils.renderOne(res, [
        (res) =>
          res.isError && (
            <section className="mx-auto flex">
              <main>
                <SomethingWentWrong
                  error={res.error}
                  description="Länken är ogiltig."
                />
              </main>
            </section>
          ),
        (res) =>
          res.data === 404 && (
            <section className="mx-auto flex">
              <main>
                <SomethingWentWrong
                  error={res.error}
                  description="Länken är ogiltig."
                />
              </main>
            </section>
          ),
        (res) =>
          res.data === 200 &&
          signInState.signedInAs === "staff" && (
            <StaffContext.Provider
              value={{
                staffGuid,
              }}
            >
              <WrappedComponent />
            </StaffContext.Provider>
          ),
      ])}
    </LoadingContent>
  );
};

export default withStaffGuid;
