"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AllowedFileExtensions = void 0;
var zod_1 = require("zod");
var AllowedFileExtensions;
(function (AllowedFileExtensions) {
    AllowedFileExtensions.Image = zod_1.z.enum(["jpg", "jpeg", "png", "webp", "heic"]);
    AllowedFileExtensions.Document = zod_1.z.enum([
        "pdf",
        "doc",
        "docx",
        "xls",
        "xlsx",
        "ppt",
        "pptx",
        "rtf",
    ]);
    AllowedFileExtensions.All = zod_1.z.enum(__spreadArray(__spreadArray([], AllowedFileExtensions.Image.options, true), AllowedFileExtensions.Document.options, true));
})(AllowedFileExtensions || (AllowedFileExtensions = {}));
exports.AllowedFileExtensions = AllowedFileExtensions;
