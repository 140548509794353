import { AppButton } from "../../../../components/common/buttons/AppButton";
import AppExternalLinkIcon from "../../../../components/icons/AppExternalLinkIcon";
import AppMailIcon from "../../../../components/icons/AppMailIcon";
import AppPhoneIcon from "../../../../components/icons/AppPhoneIcon";

interface Props {
  close: () => void;
}

const PaymentFlowCustomerServiceModal = (props: Props) => {
  return (
    <aside className="flex h-full w-full flex-col gap-8 bg-white/90 px-2 py-8 backdrop-blur">
      <header className="mx-auto w-full max-w-lg">
        <h2 className="text-xl">Kontakta kundservice</h2>
      </header>
      <main className="mx-auto flex w-full max-w-lg flex-col gap-4">
        <p>
          Vi är anslutna till <b>IP i Sverige AB</b> som hanterar all
          fakturering, kundservice och ser till att du som kund till oss får
          bästa möjliga hjälp hela vägen.
        </p>
        <h3>Har du frågor och vill kontakta kundservice?</h3>
        <ul className="flex flex-col gap-2 p-2">
          <li className="flex items-center gap-4">
            <AppExternalLinkIcon />
            <a
              className="text-blue-600 hover:underline"
              href="https://ipisverige.se/kundservicesida"
              target="_blank"
              rel="noreferrer"
            >
              Besök vår kundservicesida
            </a>
          </li>
          <li className="flex items-center gap-4">
            <AppPhoneIcon />
            <a
              className="text-blue-600 hover:underline"
              href="tel:+46108887407"
            >
              Ring oss på 010-888 74 07 mellan 08:00-16:00 på vardagar
            </a>
          </li>
          <li className="flex items-center gap-4">
            <AppMailIcon />
            <a
              className="text-blue-600 hover:underline"
              href="mailto:kundservice@ipis.se"
            >
              Maila oss på kundservice@ipis.se
            </a>
          </li>
        </ul>
      </main>
      <footer className="mx-auto flex w-full max-w-lg justify-end">
        <AppButton onClick={props.close}>Stäng fönster</AppButton>
      </footer>
    </aside>
  );
};

export default PaymentFlowCustomerServiceModal;
