import { useContext } from "react";
import ClientUtils from "./../../../../utils/ClientUtils";

import { AppFormRadioButtons } from "../../../../components/common/checkboxes/AppFormRadioButtons";
import { AppFormSelect } from "../../../../components/common/select/AppFormSelect";
import { AppFormTextField } from "../../../../components/common/text-fields/AppFormTextField";
import RotAvdragImportantInformationSection from "../../../../components/rot-avdrag/RotAvdragImportantInformationSection";
import CustomerWorkOrderContext from "../../CustomerWorkOrderContext";
import PaymentAndHousingFormContext from "./PaymentAndHousingFormContext";

interface Props {
  className?: string;
}

const HousingFormSection = (props: Props) => {
  const workOrderCtx = useContext(CustomerWorkOrderContext);
  const formCtx = useContext(PaymentAndHousingFormContext);
  const housingType = formCtx.form.watch("housing.housingType");

  if (workOrderCtx.workOrder.isBusinessCustomer) {
    return <></>;
  }

  const chosenToSkipDiscount = formCtx.form.watch(
    "customerHasActivelyChosenToSkipDiscount"
  );
  const showDiscountControls = !chosenToSkipDiscount;

  return (
    <section
      className={ClientUtils.twClassNames(
        props.className,
        "flex flex-col gap-4 border-2 border-transparent",
        housingType === "insuranceMatter" && "pointer-events-none opacity-50",
        formCtx.showErrors &&
          formCtx.showErrors.housing &&
          "focus-within:border-orange-300"
      )}
    >
      <header className="grid border-b pb-2">
        <h2 className="text-lg sm:text-xl">
          Information för rotavdrag / grön teknik
        </h2>
        <AppFormRadioButtons
          id="skipDiscountRadioButtons"
          heading={(props) => {
            return (
              <h3 {...props} className="font-normal">
                Jag vill använda mig av rotavdrag / grön teknik
              </h3>
            );
          }}
          buttonFlow="row"
          control={formCtx.form.control}
          name="customerHasActivelyChosenToSkipDiscount"
          buttons={[
            {
              label: "Ja",
              value: false,
            },
            {
              label: "Nej",
              value: true,
            },
          ]}
        />
      </header>
      {showDiscountControls && (
        <>
          <main className="flex flex-col gap-4">
            <AppFormSelect
              register={formCtx.form.register}
              name="housing.housingType"
              label="Välj bostadstyp"
              options={[
                {
                  value: "house",
                  label: "Villa",
                },
                {
                  value: "condomonium",
                  label: "Bostadsrätt",
                },
                {
                  value: "rentalApartment",
                  label: "Hyreslägenhet",
                },
              ]}
            />

            {(housingType === "house" || housingType === "condomonium") && (
              <fieldset
                key={housingType}
                className="flex flex-col gap-2 rounded"
              >
                {housingType === "house" && (
                  <AppFormTextField
                    register={formCtx.form.register}
                    className="col-start-2"
                    label="Fastighetsbeteckning"
                    name="housing.propertyDesignation"
                  />
                )}
                {housingType === "condomonium" && (
                  <>
                    <AppFormTextField
                      register={formCtx.form.register}
                      className="col-start-2"
                      label="Organisationsnummer"
                      name="housing.housingCooperativeOrgNo"
                    />
                    <AppFormTextField
                      register={formCtx.form.register}
                      className="col-start-2"
                      label="Lägenhetsnummer"
                      name="housing.apartmentNumber"
                    />
                  </>
                )}
              </fieldset>
            )}
          </main>
          <footer className="py-4">
            <RotAvdragImportantInformationSection housingType={housingType} />
          </footer>
        </>
      )}
    </section>
  );
};

export default HousingFormSection;
