"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormTypeInference = void 0;
var IpisFormTypeInference;
(function (IpisFormTypeInference) {
    function inferForm(form) {
        return form;
    }
    IpisFormTypeInference.inferForm = inferForm;
    function inferQuestion(element) {
        return element;
    }
    IpisFormTypeInference.inferQuestion = inferQuestion;
})(IpisFormTypeInference = exports.IpisFormTypeInference || (exports.IpisFormTypeInference = {}));
