import ClientUtils from "./../../utils/ClientUtils";
import { WorkOrder } from "@eljouren/domain";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import HandymanContext from "../handyman-context/HandymanContext";
import HandymanWorkOrderListItem from "../work-order/handyman/HandymanWorkOrderListItem";
import Calendar, { TCalendarView } from "./Calendar";

interface Props {
  className?: string;
  defaultView?: TCalendarView;
}

/* 
  At the start, the Calendar was written to be generic, which is why the orders are converted to events and whatnot.

  This is no longer the case, but some things may seem weird because of this migration
*/
const HandymanCalendar = (props: Props) => {
  const nav = useNavigate();
  const ctx = useContext(HandymanContext);

  function onActiveDateChanged(index: number) {
    ctx.setPage(index);
  }

  return (
    <section
      className={ClientUtils.twClassNames("h-full w-full", props.className)}
    >
      <Calendar
        className="mx-auto"
        setWeeksInPeriod={ctx.setWeeksInPeriod}
        pageIndex={ctx.page}
        loading={ctx.workOrderRes.isLoading}
        onIndexChanged={onActiveDateChanged}
        defaultView={props.defaultView}
        events={ctx.workOrderRes.data?.map((order) => ({
          id: order.orderId,
          start: order.startDate,
          end: order.endDate,
          name: order.description,
          description: order.additionalInformation,
          attachedData: order,
          onClick: () => {
            nav(`/partner/arbetsorder/${order.workerGuid}`);
          },
        }))}
        weeksInPeriod={ctx.weeksInPeriod}
        RenderDayViewItem={(props) => {
          const order = props.event.props
            .attachedData as WorkOrder.HandymanWithPermissionsType;
          return (
            <HandymanWorkOrderListItem
              order={order}
              className="bg-bg-base-layer"
            />
          );
        }}
      />
    </section>
  );
};

export default HandymanCalendar;
