"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlledSlideUpModal = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var IpisButton_1 = require("../buttons/IpisButton");
var SlideUpModal_1 = require("./SlideUpModal");
var ControlledSlideUpModal = function (props) {
    var _a = (0, react_1.useState)(false), isOpen = _a[0], setIsOpen = _a[1];
    var className = props.className, heading = props.heading, children = props.children, rest = __rest(props, ["className", "heading", "children"]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { label: props.heading, variant: "tonal", className: props.className, onClick: function () { return setIsOpen(true); }, icon: {
                    position: "trailing",
                    type: "information",
                } }), (0, jsx_runtime_1.jsx)(SlideUpModal_1.SlideUpModal, __assign({ isOpen: isOpen, heading: props.heading, onClose: function () { return setIsOpen(false); } }, rest, { children: props.children }))] }));
};
exports.ControlledSlideUpModal = ControlledSlideUpModal;
