import { IpisFormPage } from "@eljouren/domain";
import {
  IpisFormAnswerValidator,
  IpisFormClientSchemaBuilder,
} from "@eljouren/domain-utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { FieldErrors, useForm } from "react-hook-form";
import { useIpisForm } from "../../../../hooks/checklist-hooks";
import ClientUtils from "../../../../utils/ClientUtils";
import ChecklistPageContext from "./ChecklistPageContext";
import ChecklistPageFooter from "./ChecklistPageFooter";
import ChecklistPageHeader from "./ChecklistPageHeader";
import ChecklistQuestionRouter from "./ChecklistQuestionRouter";
import DevConsole from "../../../../DevConsole";

interface Props {
  className?: string;
  page: IpisFormPage.WithoutAnswersType;
  isFinalPage: boolean;
  proceed: (values: Record<string, any>) => void;
  goBack: () => void;
  isFirstPage: boolean;
  otherPageValues: Record<string, any>;
}

const ChecklistPageComponent = (props: Props) => {
  const ctx = useIpisForm();

  function transformNullToUndefined(obj: any): any {
    if (obj === null) {
      return undefined;
    }

    if (Array.isArray(obj)) {
      return obj;
    }

    if (typeof obj === "object") {
      const newObj: { [key: string]: any } = {};
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          newObj[key] = transformNullToUndefined(obj[key]);
        }
      }
      return newObj;
    }

    return obj;
  }

  const form = useForm({
    defaultValues: getDefaultValues(),
    resolver: ctx.byPassSchemaValidation
      ? undefined
      : (_values, context, options) => {
          const values = transformNullToUndefined(_values);
          const validatedChecklist = new IpisFormAnswerValidator({
            form: ctx.form,
            currentFormValues: values,
          });

          const schema = new IpisFormClientSchemaBuilder({
            validated: validatedChecklist,
          }).forPage(props.page);
          return zodResolver(schema)(values, context, options);
        },
  });

  function getDefaultValues() {
    const base = { ...props.otherPageValues };
    props.page.elements.forEach((el) => {
      if (!base[el.id]) {
        base[el.id] = {
          value: undefined,
        };
      }
    });
    return base;
  }

  function onSubmit(values: any) {
    props.proceed(values);
  }

  function onError(errors: FieldErrors<any>) {
    DevConsole.log({ errors });
  }

  const validatedChecklist = new IpisFormAnswerValidator({
    form: ctx.form,
    currentFormValues: form.watch(),
  });

  return (
    <ChecklistPageContext.Provider value={{ form, validatedChecklist }}>
      <form
        className={ClientUtils.classNames(
          "flex flex-col gap-8 overflow-auto px-2 pb-8 pt-4 xs:px-4",
          props.className
        )}
        onSubmit={form.handleSubmit(onSubmit, onError)}
      >
        <ChecklistPageHeader page={props.page} />
        <motion.section className="flex flex-col gap-12">
          {props.page.elements.map((question) => {
            return (
              <ChecklistQuestionRouter key={question.id} question={question} />
            );
          })}
        </motion.section>
        <ChecklistPageFooter {...props} />
      </form>
    </ChecklistPageContext.Provider>
  );
};

export default ChecklistPageComponent;
