"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderLocation = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderLocation;
(function (WorkOrderLocation) {
    WorkOrderLocation.Schema = centralized_zod_1.z.object({
        city: centralized_zod_1.z.string(),
        street: centralized_zod_1.z.string(),
        country: centralized_zod_1.z.string(),
        postalCode: centralized_zod_1.z.string(),
        doorCode: centralized_zod_1.z.string().optional(),
        floor: centralized_zod_1.z.string().optional(),
    });
})(WorkOrderLocation || (WorkOrderLocation = {}));
exports.WorkOrderLocation = WorkOrderLocation;
