"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormPage = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var IpisFormElement_1 = require("./IpisFormElement");
var FormElementWithState_1 = require("./FormElementWithState");
var IpisFormPage;
(function (IpisFormPage) {
    var Base = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        clientSideId: centralized_zod_1.z.string().uuid().nullable(),
        pageTitle: centralized_zod_1.z.string(),
        pageTitleShorthand: centralized_zod_1.z.string(),
        pageDescription: centralized_zod_1.z.string().optional(),
        preparations: centralized_zod_1.z.string().optional(),
    });
    IpisFormPage.WithoutAnswersSchema = Base.extend({
        elements: IpisFormElement_1.IpisFormElement.Schema.array(),
    });
    IpisFormPage.WithStateSchema = Base.extend({
        elements: FormElementWithState_1.FormElementWithState.MaybeSchema.array(),
    });
    IpisFormPage.FullyValidatedSchema = Base.extend({
        elements: FormElementWithState_1.FormElementWithState.ValidSchema.array(),
    });
})(IpisFormPage = exports.IpisFormPage || (exports.IpisFormPage = {}));
