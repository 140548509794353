"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMetaV2 = void 0;
var zod_1 = require("zod");
var FileTag_1 = require("./FileTag");
var FileMetaV2;
(function (FileMetaV2) {
    FileMetaV2.Schema = zod_1.z.object({
        recordId: zod_1.z.string(),
        description: zod_1.z.string().optional().nullable(),
        customName: zod_1.z.string().max(255).optional().nullable(),
        createdDate: zod_1.z.date(),
        mimeType: zod_1.z.string().optional().nullable(),
        tag: FileTag_1.FileTag.Schema.optional().nullable(),
    });
    FileMetaV2.UserEditableFieldsSchema = FileMetaV2.Schema.pick({
        description: true,
        customName: true,
    });
})(FileMetaV2 || (FileMetaV2 = {}));
exports.FileMetaV2 = FileMetaV2;
