import ClientUtils from './../../../utils/ClientUtils';
import { DateHelper } from "@eljouren/utils";
import LabelAndText from "../../../components/common/LabelAndText";

import CustomerWorkOrderOverviewContext, {
  CustomerWorkOrderOverviewContextProps,
} from "./CustomerWorkOrderOverviewContext";
import CustomerWorkOrderOverviewInvoiceAndHousingSection from "./CustomerWorkOrderOverviewInvoiceAndHousingSection";

interface Props extends CustomerWorkOrderOverviewContextProps {
  className?: string;
}

/* 
  ToDo: Show payment and invoice info
*/
const CustomerWorkOrderOverviewSection = (props: Props) => {
  const workOrder = props.workOrder;

  function getLocationString() {
    return `${workOrder.location.street}, ${workOrder.location.postalCode} ${workOrder.location.city}`;
  }

  function getDateTimeString() {
    return new DateHelper(workOrder.startDate).dateTimeInputFormat.replace(
      "T",
      " "
    );
  }

  const phoneIconTexts: string[] = [workOrder.contact.phone];
  if (workOrder.contact.phone2) {
    phoneIconTexts.push(workOrder.contact.phone2);
  }

  return (
    <CustomerWorkOrderOverviewContext.Provider value={props}>
      <section
        //    as="section"
        className={ClientUtils.twClassNames(
          "mx-auto flex h-full w-full flex-col gap-16",
          props.className
        )}
      >
        {/* 
			Nested sections - not sure what element to use.

			Divs? Fieldsets? Something else?
		*/}
        <section className={ClientUtils.twClassNames("flex flex-col gap-4")}>
          <header>
            <h3 className="text-lg">Arbetsorder</h3>
          </header>
          <main className="flex flex-col gap-4">
            <LabelAndText
              id="orderDescription"
              label="Beskrivning"
              text={workOrder.description}
            />
            <LabelAndText
              id="orderAdditionalInformation"
              label="Utförlig uppdragsbeskrivning"
              text={workOrder.additionalInformation}
            />
          </main>
        </section>

        <section className="flex flex-col gap-4">
          <header>
            <h3 className="text-lg">Tid och plats</h3>
          </header>
          <main className="flex flex-col gap-4">
            <LabelAndText
              label="Datum och tid"
              id="orderTime"
              text={getDateTimeString()}
            />
            <LabelAndText
              label="Plats"
              id="orderLocation"
              text={getLocationString()}
            />
            {!!workOrder.location.doorCode && (
              <LabelAndText
                label="Portkod"
                id="orderDoorCode"
                text={workOrder.location.doorCode}
              />
            )}
            {!!workOrder.location.floor && (
              <LabelAndText
                label="Våning"
                id="orderFloor"
                text={workOrder.location.floor}
              />
            )}
          </main>
        </section>
        <section className="flex flex-col gap-4">
          <header>
            <h3 className="text-lg">Kontakt</h3>
          </header>
          <main className="flex flex-col gap-4">
            <LabelAndText
              label="Namn"
              id="orderCustomerName"
              text={workOrder.contact.fullName}
            />
            <LabelAndText
              label="E-post"
              id="orderCustomerMail"
              text={workOrder.contact.email}
            />

            <LabelAndText
              label="Telefonnummer"
              id="orderCustomerPhone"
              text={phoneIconTexts.join(", ")}
            />
          </main>
        </section>
        <CustomerWorkOrderOverviewInvoiceAndHousingSection className="pb-8" />
      </section>
    </CustomerWorkOrderOverviewContext.Provider>
  );
};

export default CustomerWorkOrderOverviewSection;
