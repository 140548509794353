import { WorkOrder } from "@eljouren/domain";
import { DateHelper } from "@eljouren/utils";
import { useContext } from "react";
import AppRoutes from "../../../routes";
import ClientUtils from "./../../../utils/ClientUtils";
import CompletedWorkOrderIcon from "../../icons/composed/CompletedWorkOrderIcon";
import ServiceContractIcon from "../../icons/composed/ServiceContractIcon";
import MyLink from "../../routing/MyLink";
import HandymanWorkOrderScaledDownFileContextProvider, {
  HandymanWorkOrderScaledDownFileContext,
} from "./files/HandymanWorkOrderScaledDownFileContextProvider";
import AppImageIcon from "../../icons/AppImageIcon";
import AppMapPinIcon from "../../icons/AppMapPinIcon";
import MidDot from "../../common/MidDot";

interface Props {
  className?: string;
  order: WorkOrder.HandymanType;
  goBackTo?: string;
  goBackToLabel?: string;
  //noShadow?: boolean;
}

const HandymanWorkOrderListItem = (props: Props) => {
  return (
    <HandymanWorkOrderScaledDownFileContextProvider order={props.order}>
      <WrappedWithImages {...props} />
    </HandymanWorkOrderScaledDownFileContextProvider>
  );
};

const WrappedWithImages = (props: Props) => {
  const { workOrderFileRes } = useContext(
    HandymanWorkOrderScaledDownFileContext
  );
  const hasPassed = new DateHelper(props.order.endDate).isBefore(new Date());

  function getImageCountString() {
    if (workOrderFileRes.isLoading) {
      return "...";
    }

    const workerLength = workOrderFileRes.query.data?.length || 0;

    return `x${workerLength}`;
  }

  function addressString(): string | false {
    const { city, street, postalCode } = props.order.location;
    if (!city || !street || !postalCode) {
      return false;
    }
    return `${street}, ${postalCode} ${city}`;
  }

  const startHelper = new DateHelper(props.order.startDate);
  const endHelper = new DateHelper(props.order.endDate);

  function googleMapsUrl(): string {
    const address = addressString();
    if (!address) {
      return "";
    }
    const base = "https://www.google.com/maps/search/?";
    const params = new URLSearchParams({
      api: "1",
      query: address,
    });
    return `${base}${params}`;
  }

  const address = addressString();

  const isFinished = props.order.isFinished;
  const success = isFinished && !props.order.workOrderStatus.unsuccessfulReason;

  return (
    <li
      key={props.order.orderId}
      className={ClientUtils.twClassNames(
        "grid grid-cols-[minmax(0,1fr),auto] grid-rows-[minmax(0,1fr),auto] rounded border shadow",
        props.className
      )}
      //onClick={props.event.props.onClick}
      //style={{ background: props.background }}
    >
      <aside className="col-start-2 row-span-2 row-start-1 flex h-full w-14 flex-col items-center gap-2 bg-brand-blue-100 p-2">
        <div className="flex items-center">
          <AppImageIcon size={15} />
          <span className="text-sm">{getImageCountString()}</span>
        </div>
        {!!props.order.serviceContract && <ServiceContractIcon size={24} />}
        {props.order.isFinished && (
          <CompletedWorkOrderIcon
            size={24}
            status={success ? "success" : "failure"}
          />
        )}
      </aside>
      <MyLink
        to={AppRoutes.partner.customerOrder(props.order.workerGuid)}
        state={{
          order: props.order,
          goBackTo: props.goBackTo,
          goBackToLabel: props.goBackToLabel,
        }}
        className="flex cursor-pointer flex-col pb-0 transition-colors "
      >
        <div className="row-start-1 flex flex-col p-2">
          <span className="grid grid-cols-[minmax(0,1fr),auto,auto,auto] gap-1">
            <h4 className="truncate text-sm">{props.order.description}</h4>
          </span>
          <div className="flex justify-between pt-1">
            <div className="flex flex-col gap-1 xs:flex-row ">
              <span className="text-xs italic text-dark-gray text-opacity-90">
                #{props.order.serialNumber}
              </span>
              <MidDot className="hidden xs:flex" />
              <span className="text-xs text-dark-gray text-opacity-90">
                {props.order.typeOfService.name}
              </span>
            </div>

            <span
              className={ClientUtils.twClassNames(
                "text-xs italic",
                hasPassed && "text-red-600"
              )}
            >{`${startHelper.hourAndMinutesLabel} - ${endHelper.hourAndMinutesLabel}`}</span>
          </div>

          <span className="my-1 line-clamp-2 overflow-hidden text-sm">
            {props.order.additionalInformation || "Ingen beskrivning angedd"}
          </span>
        </div>
      </MyLink>
      <span className="mx-2 flex flex-col border-t p-2">
        {!address && (
          <span className={ClientUtils.twClassNames("flex text-xs")}>
            {"Okänd address"}
          </span>
        )}
        {address && (
          <a
            className={ClientUtils.twClassNames(
              "mr-auto grid grid-cols-[auto,minmax(0,1fr)] grid-rows-1 items-center gap-2 text-xs hover:underline"
            )}
            href={googleMapsUrl()}
            target="_blank"
            rel="noreferrer"
            onClick={(e) => e.stopPropagation()}
          >
            <AppMapPinIcon size={15} />
            <span>{address}</span>
          </a>
        )}
      </span>
    </li>
  );
};

export default HandymanWorkOrderListItem;
