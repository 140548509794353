import React from "react";

interface BankIDLogoProps {
  width?: number;
  height?: number;
}

const ResursbankLogo: React.FC<BankIDLogoProps> = (props) => {
  return (
    <img
      style={{
        width: props.width ?? 60,
        height: props.height ?? 40,
      }}
      className="object-contain"
      src={`external-logos/resursbank/resursbank.png`}
      alt="Logga för BankID"
    />
  );
};

export default ResursbankLogo;
