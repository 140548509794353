import { IpisFormElement } from "@eljouren/domain";
import { useChecklistPage } from "../../../../../hooks/checklist-hooks";
import ChecklistDecoupledTextInputQuestion from "./ChecklistDecoupledTextInputQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.TextInputType;
}

const ChecklistTextInputQuestion = (props: Props) => {
  const ctx = useChecklistPage();
  const q = props.question;

  return <ChecklistDecoupledTextInputQuestion question={q} form={ctx.form} />;
};

export default ChecklistTextInputQuestion;
