import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";
import PaymentAndHousingFormContext from "./PaymentAndHousingFormContext";
import { AppFormSelect } from "../../../../components/common/select/AppFormSelect";
import { AnimatePresence, motion } from "framer-motion";
import { AppFormTextField } from "../../../../components/common/text-fields/AppFormTextField";
import { AppTextField } from "../../../../components/common/text-fields/AppTextField";
import { AppFormTextArea } from "../../../../components/common/text-areas/AppFormTextArea";
import CustomerWorkOrderContext from "../../CustomerWorkOrderContext";
import ResursBankCooperationInfo from "../../ResursBankCooperationInfo";

interface Props {
  className?: string;
}

const InvoiceInformationFormSection = (props: Props) => {
  const workOrderCtx = useContext(CustomerWorkOrderContext);
  const formCtx = useContext(PaymentAndHousingFormContext);
  const invoiceMethod = formCtx.form.watch("invoice.invoiceMethod");

  const wo = workOrderCtx.workOrder;
  if (wo.paymentDetails.externalPaymentFlowAllowed) {
    return <ResursBankCooperationInfo />;
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2 border-2 border-transparent",
        props.className,
        formCtx.showErrors &&
          formCtx.showErrors.housing &&
          "focus-within:border-orange-300"
      )}
    >
      <header>
        <h2 className="text-xl">Faktureringsinformation</h2>
      </header>
      <main className="flex flex-col gap-4 pb-2">
        <AppFormSelect
          register={formCtx.form.register}
          name="invoice.invoiceMethod"
          label="Välj faktureringsmetod"
          options={[
            {
              value: "email",
              label: "E-post",
            },
            {
              value: "postal",
              label: "Pappersfaktura (avgift 50:-)",
            },
          ]}
        />

        <AnimatePresence exitBeforeEnter>
          <motion.fieldset
            key={invoiceMethod}
            className="flex flex-col gap-2 rounded"
            initial={{
              opacity: 0,
            }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {invoiceMethod === "email" && (
              <>
                <AppFormTextField
                  register={formCtx.form.register}
                  name="invoice.email"
                  label="E-post för faktura"
                  htmlAttributes={{
                    type: "email",
                  }}
                ></AppFormTextField>
              </>
            )}
            {invoiceMethod === "postal" && (
              <>
                <AppTextField
                  htmlAttributes={{
                    disabled: true,
                    readOnly: true,
                    value:
                      workOrderCtx.workOrder.customerDetails?.street ??
                      undefined,
                    name: "invoice.street",
                  }}
                  label="Gata"
                  className="col-start-2 row-start-1"
                />
                <AppTextField
                  htmlAttributes={{
                    disabled: true,
                    readOnly: true,
                    name: "invoice.postalCode",
                    value:
                      workOrderCtx.workOrder.customerDetails?.postalCode ??
                      undefined,
                  }}
                  className="col-start-2"
                  label="Postnummer"
                />
                <AppTextField
                  className="col-start-2"
                  htmlAttributes={{
                    disabled: true,
                    readOnly: true,
                    name: "invoice.city",
                    value:
                      workOrderCtx.workOrder.customerDetails?.city ?? undefined,
                  }}
                  label="Stad"
                />
              </>
            )}
          </motion.fieldset>
        </AnimatePresence>
        <AppFormTextArea
          register={formCtx.form.register}
          name="invoice.additionalInformation"
          label="Ytterligare faktureringsinformation"
          htmlAttributes={{
            placeholder: "Finns det något mer vi behöver veta?",
            rows: 5,
          }}
        />
      </main>
    </section>
  );
};

export default InvoiceInformationFormSection;
