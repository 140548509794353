"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnOutsideClick = useOnOutsideClick;
var react_1 = require("react");
/**
 * Hook that alerts clicks outside of the passed ref
 * https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
function useOnOutsideClick(args) {
    var ref = args.ref, callback = args.callback, _a = args.exclude, exclude = _a === void 0 ? [] : _a;
    (0, react_1.useEffect)(function () {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                var inExcludedArea = exclude.some(function (excludeRef) {
                    return (excludeRef === null || excludeRef === void 0 ? void 0 : excludeRef.current) && excludeRef.current.contains(event.target);
                });
                if (!inExcludedArea) {
                    callback();
                }
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, callback, exclude]);
}
