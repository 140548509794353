import { ErrorLog, ErrorLogLevel } from "@eljouren/domain/build";
import { DateHelper, Utils } from "@eljouren/utils";
import { useState } from "react";
import AppCaretIcon from "../icons/AppCaretIcon";
import ClientUtils from "./../../utils/ClientUtils";
import ReactJson from "react18-json-view";

interface Props {
  errorLog: ErrorLog.Type;
}

const ErrorLogListItem = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  const partialLog: Partial<ErrorLog.Type> = structuredClone(props.errorLog);
  delete partialLog["publicId"];
  delete partialLog["createdAt"];
  delete partialLog["logLevel"];
  delete partialLog["permissionLevel"];

  const json = structuredClone(props.errorLog.json);
  delete json["title"];
  partialLog.json = json;

  return (
    <li className="flex flex-col gap-3 rounded border p-2">
      <header
        className="grid w-full cursor-pointer grid-cols-[minmax(0,1fr),auto,auto] items-center gap-2"
        onClick={() => setExpanded(!expanded)}
      >
        <span className="flex w-full items-center gap-2">
          <LogLevelIndicator level={props.errorLog.logLevel} />
          <h2 className="grow truncate text-base">{props.errorLog.title}</h2>
        </span>
        {/* <h3 className="text-xs font-semibold text-dark-gray text-opacity-80">
          {props.errorLog.publicId}
        </h3> */}
        <span className="text-xs italic text-dark-gray text-opacity-80">
          {new DateHelper(props.errorLog.createdAt).readableDateTimeWithSeconds}
        </span>
        <AppCaretIcon
          direction="down"
          className={ClientUtils.twClassNames(
            "col-start-3 row-span-1 row-start-1 my-auto",
            expanded && "rotate-180"
          )}
        />
      </header>
      {expanded && (
        <div>
          <div className="flex whitespace-pre-wrap rounded bg-slate-300 p-4 text-black">
            <ReactJson src={partialLog} />
          </div>
        </div>
      )}
    </li>
  );
};

const LogLevelIndicator = (props: { level: ErrorLogLevel.Type }) => {
  const text = Utils.capitalize(props.level);

  return (
    <span
      className={ClientUtils.twClassNames(
        "rounded-full bg-dark-gray px-2 py-1 text-xs font-semibold text-off-white",
        props.level === "error" && "bg-red-500",
        props.level === "warn" && "bg-yellow-500",
        props.level === "info" && "bg-blue-500",
        props.level === "debug" && "bg-green-500"
      )}
    >
      {text}
    </span>
  );
};

export default ErrorLogListItem;
