import { useContext, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { IpisButton } from "@ipis/client-essentials";
import FormEditableAddressFieldset, {
  FormEditableAddressFieldProps,
} from "./FormEditableAddressFieldset";
import { useHasAddressErrors } from "./editable-address-hooks";
import ClientUtils from "../../../../utils/ClientUtils";
import { FormInputContext } from "@ipis/client-essentials";

interface Props<T extends FieldValues>
  extends Omit<FormEditableAddressFieldProps<T>, "isEditing"> {
  heading: {
    title: string;
    as: "h2" | "h3";
  };
}

const FormEditableAddressFieldsetSection = <T extends FieldValues>(
  props: Props<T>
) => {
  const ctx = useContext(FormInputContext);
  const [_isEditing, setIsEditing] = useState(false);
  const Heading = props.heading.as;

  const hasErrors = useHasAddressErrors(props);
  const readOnly = !!ctx?.readOnly;
  const isEditing = (_isEditing || hasErrors) && readOnly;

  useEffect(() => {
    if (hasErrors && !_isEditing) {
      setIsEditing(true);
    }
  }, [hasErrors, _isEditing]);

  function startEditing() {
    setIsEditing(true);
  }

  function stopEditing() {
    if (hasErrors) {
      return;
    }
    setIsEditing(false);
  }

  return (
    <section className="flex flex-col gap-2">
      <header className="flex h-8 items-center justify-between">
        <Heading className="text-lg text-dark-950">
          {props.heading.title}
        </Heading>
        {!isEditing && !readOnly && (
          <IpisButton
            onlyIcon
            variant="text"
            label="Redigera adress"
            icon={{
              type: "edit",
              position: "trailing",
            }}
            onClick={startEditing}
          />
        )}
        {isEditing && !readOnly && (
          <IpisButton
            variant="text"
            label="Spara adress"
            underline
            onClick={stopEditing}
            className={ClientUtils.classNames(hasErrors && "opacity-50")}
            /*  icon={{
              type: "save",
              position: "trailing",
            }} */
          />
        )}
      </header>
      <FormEditableAddressFieldset
        id={props.id}
        form={props.form}
        legend={props.legend}
        isEditing={isEditing}
        fieldMapping={props.fieldMapping}
        registerNested={props.registerNested}
        readOnly={readOnly}
      />
    </section>
  );
};

export default FormEditableAddressFieldsetSection;
