import { IpisFormElement } from "@eljouren/domain";
import ChecklistDecoupledNumberInputQuestion from "../../../checklist-question-types/number-input/ChecklistDecoupledNumberInputQuestion";
import WizardForm from "../../../wizard-form/WizardForm";
import { useWizardForm } from "../../../wizard-form/use-wizard-form";
import InputBuilderLayout from "../InputBuilderLayout";
import {
  WizardDescriptionField,
  WizardFormInference,
  WizardHelperTextField,
  WizardMaxValueField,
  WizardMinValueField,
  WizardNoteField,
  WizardRequiredField,
  WizardTitleField,
} from "../common-wizard-fields";

const page1 = WizardFormInference.inferQuestionArray([
  WizardTitleField,
  WizardDescriptionField,
  WizardHelperTextField,
  WizardNoteField,
  WizardRequiredField,
]);

const page2 = WizardFormInference.inferQuestionArray([
  WizardMinValueField,
  WizardMaxValueField,
]);

const configs = [page1, page2];

type FormValues = WizardFormInference.FormValues<typeof configs>;

type SubmitValues = Omit<IpisFormElement.NumberInputType, "id">;
interface Props {
  className?: string;
  defaultValues?: SubmitValues;
  onSubmit: (data: SubmitValues) => void;
  closeForm: () => void;
}

const ChecklistNumericInputBuilder = (props: Props) => {
  function submitValuesToFormValues(
    values: SubmitValues | undefined
  ): Partial<FormValues> {
    if (!values) {
      return {};
    }
    const partial: Partial<FormValues> = {
      title: { value: values.title },
      richTextBody: {
        value: values.description ?? "",
      },
      helperText: {
        value: values.tooltip ?? "",
      },
      note: { value: values.note ?? "" },
    };

    return partial;
  }

  const wizardHook = useWizardForm({
    formId: "checklist-text-input-builder",
    configs,
    onSubmit: onSubmit,
    defaultValues: {
      ...submitValuesToFormValues(props.defaultValues),
    },
  });

  const question = buildQuestion(wizardHook.form.watch());

  function buildQuestion(values: FormValues): IpisFormElement.NumberInputType {
    return {
      id: "generator",
      clientSideId: null,
      typeOfQuestion: "number",
      type: "question",
      required: values["required"]?.value ?? false,
      title: values["title"]?.value ?? "",
      description: values["richTextBody"]?.value,
      tooltip: values["helperText"]?.value,
      note: values["note"]?.value,
      min: values["min"]?.value,
      max: values["max"]?.value,
    };
  }

  const atLeastOneQuestionAnswered = Object.values(
    wizardHook.form.getValues()
  ).some((value) => !!value);

  function onSubmit(data: FormValues) {
    props.onSubmit(buildQuestion(data));
  }

  return (
    <InputBuilderLayout
      closeForm={props.closeForm}
      heading="Skapa ett numeriskt fält"
      description="Här kan du skapa ett fält där användaren kan skriva in ett numeriskt värde. Du kan ställa in min- och maxvärde för att begränsa vilka värden som kan skrivas in på andra sidan."
      atLeastOneQuestionAnswered={atLeastOneQuestionAnswered}
      Form={<WizardForm hookReturn={wizardHook} />}
      PreviewComponent={
        <ChecklistDecoupledNumberInputQuestion
          disabled
          form={wizardHook.form}
          question={question}
        />
      }
    />
  );
};

export default ChecklistNumericInputBuilder;
