import { useContext } from "react";
import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";
import RichTextEditorContext from "./RichTextEditorContext";

export function useEditor(id: string): BaseEditor & ReactEditor {
  const ctx = useContext(RichTextEditorContext);

  if (!ctx.editors[id]) {
    throw new Error(`Editor with id ${id} not found`);
  }

  return ctx.editors[id];
}
