import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ChecklistInformationElementBuilder from "./ChecklistInformationElementBuilder";

interface Props {
  className?: string;
  closeForm: () => void;
}

const ChecklistInformationElementCreator = (props: Props) => {
  const ctx = useFormEditor();

  return (
    <ChecklistInformationElementBuilder
      closeForm={props.closeForm}
      onSubmit={(values) => {
        ctx.controls.addElement({ element: values });
        props.closeForm();
      }}
    />
  );
};

export default ChecklistInformationElementCreator;
