import { MotionProps, motion } from "framer-motion";
import InvoiceViewCustomerServiceFooter from "../payment-method-components/InvoiceViewCustomerServiceFooter";
import { InvoiceRouteView } from "../CustomerWorkOrderInvoiceContext";

interface Props {
  className?: string;
  motionProps: MotionProps;
  style: React.CSSProperties;
  view:
    | InvoiceRouteView.authenticationError
    | InvoiceRouteView.failedToFetchRequiredData;
}

const InvoiceErrorView = (props: Props) => {
  if (props.view === InvoiceRouteView.authenticationError) {
    return (
      <motion.section
        className="mx-auto w-full max-w-screen-sm rounded border p-4 shadow sm:p-4 md:p-8"
        {...props.motionProps}
        style={props.style}
      >
        <h2 className="text-lg">Något gick fel</h2>
        <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
          <p className="flex">
            Vi kan för närvarande inte bekräfta att du har rättighet att visa
            denna sida. Om du tror att det här är ett fel, vänligen kontakta
            kundservice.
          </p>
        </main>
        <InvoiceViewCustomerServiceFooter
          mainActionCallback={() => {
            window.location.reload();
          }}
          mainActionLabel="Ladda om"
        />
      </motion.section>
    );
  }
  return (
    <motion.section
      className="mx-auto w-full max-w-screen-sm rounded border p-4 shadow sm:p-4 md:p-8"
      {...props.motionProps}
      style={props.style}
    >
      <h2 className="text-lg">Något gick fel</h2>
      <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
        <p className="flex text-sm md:text-base">
          Vi kunde inte hämta informationen som behövs för att genomföra din
          betalning. Vänligen försök igen senare.
        </p>
      </main>
      <InvoiceViewCustomerServiceFooter
        mainActionCallback={() => {
          window.location.reload();
        }}
        mainActionLabel="Ladda om"
      />
    </motion.section>
  );
};

export default InvoiceErrorView;
