import NetworkRequestError from "./NetworkRequestError";

type Params = {
  response: Response;
  json: {
    error?: {
      handled?: boolean;
      publicId?: string;
      [key: string]: any;
    };
    [key: string]: any;
  } | null;
  context: string;
};

export default class FetchResponseFailureError extends NetworkRequestError {
  constructor(private readonly params: Params) {
    const message = `Error while fetching in context: ${params.context}, status: ${params.response.status}`;
    super(message);
    Object.setPrototypeOf(this, FetchResponseFailureError.prototype);
  }

  getPublicId(): string | undefined {
    return this.params.json?.error?.publicId;
  }
  isHandled(): boolean {
    return !!this.params.json?.error?.handled;
  }
}
