import { useExternalBrandTheme } from "@ipis/client-essentials";
import ClientUtils from "../../../utils/ClientUtils";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";

interface Props {
  className?: string;
}

export function AppLoaderV2(props: Props) {
  const reducedMotion = useReducedMotion();

  const theme = useExternalBrandTheme().externalBrandTheme;

  return (
    <AnimatePresence initial>
      <motion.div
        data-test-id="app-loader"
        className={ClientUtils.twClassNames(
          "m-auto rounded-full border-4 border-solid border-primary-400",
          !reducedMotion && "border-b-transparent",
          props.className,
          !props.className && "h-12 w-12"
        )}
        style={
          theme
            ? {
                borderColor: theme.accentColor,
                borderBottomColor: "transparent",
              }
            : undefined
        }
        animate={
          reducedMotion
            ? { opacity: [0.2, 1, 0.2] }
            : { rotate: 360, opacity: 1 }
        }
        transition={{
          repeat: Infinity,
          duration: reducedMotion ? 3 : 1.5,
          ease: "linear",
        }}
      />
    </AnimatePresence>
  );
}
