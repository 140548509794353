import ClientUtils from "./../../utils/ClientUtils";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { usePublicErrorId } from "../../hooks/hooks";
import {
  useOnEnterClick,
  useOnEscapeClick,
} from "../../hooks/keyboard-event-hooks";
import ErrorUtil from "../../utils/errors/ErrorUtil";
import { AppButton } from "../common/buttons/AppButton";
import { AlertState, ConfirmDialogAnswerArgs, ConfirmState } from "./Modal";

type AlertDialogProps =
  | (AlertState & { close: () => void })
  | (ConfirmState & { answer: (args: ConfirmDialogAnswerArgs) => void });

const AlertDialog = (props: AlertDialogProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const publicId = usePublicErrorId();

  useEffect(() => {
    ref.current?.focus();
  });
  useOnEnterClick({
    ref: ref,
    callback: () => {
      if (props.type === "alert") {
        props.close();
      }
    },
  });
  useOnEscapeClick(() => {
    if (props.type === "alert") {
      props.close();
    }
  });

  const publicErrorProps = ErrorUtil.getShortHand(publicId);

  const isConfirm = props.type === "confirm";
  const yesLabel = (isConfirm && props.args.yesLabel) || "Ja";
  const noLabel = (isConfirm && props.args.noLabel) || "Nej";
  const maxButtonLength = Math.max(yesLabel.length, noLabel.length);
  const splitIntoRows = maxButtonLength > 12;

  return (
    <motion.div
      className="fixed z-[70] flex h-full w-full flex-col items-center justify-center bg-bg-base-layer/50 p-2 xs:p-4 sm:p-8"
      initial={{
        opacity: 0,
      }}
      animate={{ opacity: 1 }}
      exit={{
        opacity: 0,
      }}
      role="alertdialog"
      aria-labelledby="alertTitle"
      aria-describedby="alertPrompt"
    >
      <div
        className={ClientUtils.twClassNames(
          "pointer-events-auto flex w-full max-w-xl flex-col rounded border bg-white/90 p-4 shadow-lg"
          //props.typeOfAlert === "notification" && "border-green-400",
          //props.typeOfAlert === "error" && "border-red-400"
        )}
        role="document"
        tabIndex={0}
        ref={ref}
      >
        <h2 id="alertTitle" className="break-words text-lg leading-6">
          {props.args.title}
        </h2>
        {!!publicErrorProps && (
          <h3 className="font-opacity-80 text-sm font-normal italic text-dark-gray">
            {publicErrorProps.publicIdShorthand}
          </h3>
        )}
        <p id="alertPrompt" className="text-base text-gray-700">
          {props.args.prompt}
        </p>
        {props.type === "alert" && (
          <AppButton
            requiresNetworkConnection={false}
            onClick={props.close}
            className="ml-auto mt-2 min-w-[100px]"
          >
            {props.args.buttonLabel ?? "OK"}
          </AppButton>
        )}
        {props.type === "confirm" && (
          <span
            className={ClientUtils.classNames(
              "mt-4 flex flex-col gap-2 sm:mt-8 sm:grid",
              !splitIntoRows && "sm:grid-cols-2",
              splitIntoRows && "sm:grid-cols-1"
            )}
          >
            <AppButton
              requiresNetworkConnection={false}
              variant="outline-default"
              onClick={() => {
                props.answer({ answer: false });
              }}
            >
              {props.args.noLabel ?? "Nej"}
            </AppButton>
            <AppButton
              requiresNetworkConnection={false}
              onClick={() => {
                props.answer({ answer: true });
              }}
            >
              {props.args.yesLabel ?? "Ja"}
            </AppButton>
          </span>
        )}
      </div>
    </motion.div>
  );
};

export default AlertDialog;
