import { IpisFormConditionGroup, IpisFormElement } from "@eljouren/domain";
import ChecklistDecoupledInformationQuestion from "../../../checklist-question-types/information/ChecklistDecoupledInformationQuestion";
import WizardForm from "../../../wizard-form/WizardForm";
import { useWizardForm } from "../../../wizard-form/use-wizard-form";
import InputBuilderLayout from "../InputBuilderLayout";
import {
  WizardDescriptionField,
  WizardFormInference,
  WizardHelperTextField,
  WizardNoteField,
  WizardTitleField,
} from "../common-wizard-fields";

const slideUpConditionGroups: IpisFormConditionGroup.QuestionType[] = [
  {
    id: "useSlideUp-conditional-group",
    clientSideId: null,
    relationship: "and",
    conditions: [
      {
        id: "useSlideUp-conditional",
        clientSideId: null,
        typeOfQuestion: "yes/no",
        referenceType: "question",
        reference: "useSlideUp",
        comparison: "equals",
        value: true,
      },
    ],
  },
];

const page = WizardFormInference.inferQuestionArray([
  WizardTitleField,
  WizardDescriptionField,
  WizardHelperTextField,
  WizardNoteField,
  {
    id: "useSlideUp",
    clientSideId: null,
    type: "question",
    typeOfQuestion: "yes/no",
    title: "Vill du ta med en längre text som kan visas vid knapptryck?",
    required: true,
    renderAs: "toggle",
    walkthroughHeading: "Längre text",
    walkthroughSubheading: "Längre text",
  },
  {
    id: "slideUpButtonPrompt",
    clientSideId: null,
    type: "question",
    typeOfQuestion: "text",
    title: "Text på knappen",
    required: true,
    conditionGroups: slideUpConditionGroups,
    walkthroughHeading: "Längre text",
    walkthroughSubheading: "Längre text",
  },
  {
    id: "slideUpRichText",
    clientSideId: null,
    type: "question",
    typeOfQuestion: "rich-text",
    title: "Längre text",
    required: true,
    conditionGroups: slideUpConditionGroups,
    walkthroughHeading: "Längre text",
    walkthroughSubheading: "Längre text",
  },
]);

const configs = [page];

type FormValues = WizardFormInference.FormValues<typeof configs>;

type SubmitValues = Omit<IpisFormElement.InformationType, "id">;
interface Props {
  className?: string;
  defaultValues?: SubmitValues;
  onSubmit: (data: SubmitValues) => void;
  closeForm: () => void;
}

const ChecklistInformationElementBuilder = (props: Props) => {
  function submitValuesToFormValues(
    values: SubmitValues | undefined
  ): Partial<FormValues> {
    if (!values) {
      return {};
    }

    const partial: Partial<FormValues> = {
      title: { value: values.title },
      richTextBody: {
        value: values.description ?? "",
      },
      helperText: {
        value: values.tooltip ?? "",
      },
      note: { value: values.note ?? "" },
    };
    return partial;
  }

  const wizardHook = useWizardForm({
    formId: "checklist-text-input-builder",
    configs,
    onSubmit: onSubmit,
    defaultValues: {
      ...submitValuesToFormValues(props.defaultValues),
    },
  });

  const question = buildQuestion(wizardHook.form.watch());

  function buildQuestion(values: FormValues): IpisFormElement.InformationType {
    let slideUpElement: IpisFormElement.Type["slideUpElement"] | undefined;
    if (values["useSlideUp"]?.value) {
      slideUpElement = {
        prompt: values["slideUpButtonPrompt"]?.value ?? "",
        content: values["slideUpRichText"]?.value ?? "",
      };
    }

    return {
      id: "generator",
      clientSideId: null,
      type: "question",
      typeOfQuestion: "information",
      title: values["title"]?.value ?? "",
      description: values["richTextBody"]?.value,
      tooltip: values["helperText"]?.value,
      note: values["note"]?.value,
      slideUpElement,
    };
  }

  const atLeastOneQuestionAnswered = Object.values(
    wizardHook.form.getValues()
  ).some((value) => !!value);

  function onSubmit(data: FormValues) {
    props.onSubmit(buildQuestion(data));
  }

  return (
    <InputBuilderLayout
      closeForm={props.closeForm}
      heading="Skapa fält som endast är till för att visa information"
      description="Försök att hålla texten så kort och koncis som möjligt för att
            användaren ska kunna förstå informationen på en gång. Om du kräver
            lång text så går det att gömma den bakom en knapp, som gör att
            texten glider upp från botten av skärmen."
      atLeastOneQuestionAnswered={atLeastOneQuestionAnswered}
      Form={<WizardForm hookReturn={wizardHook} />}
      PreviewComponent={
        <ChecklistDecoupledInformationQuestion
          question={question}
          slideUpAnchorId="preview"
        />
      }
    />
  );
};

export default ChecklistInformationElementBuilder;
