import ClientUtils from "./../../../../utils/ClientUtils";
import { ChecklistItemProps } from "./ChecklistItem";
import ChecklistAnsweredIndicator from "./ChecklistAnsweredIndicator";
import { Utils } from "@eljouren/utils";

/* 
	This is hardcoded both here and in the checklist module, should be moved to a shared place
*/
const noOption = "no-option";

interface Props extends ChecklistItemProps {
  className?: string;
  options: { value: string; label: string }[];
}

const ChecklistItemSelect = (props: Props) => {
  const label = props.item.name;

  const isRequired = props.item.required;
  const currentValue = props.form.watch(props.item.id + ".answer");
  const isAnswered =
    Utils.isNotNullOrUndefined(currentValue) && currentValue !== noOption;

  if (props.options.length > 5) {
    return (
      <p
        className={ClientUtils.twClassNames(
          "flex flex-col pb-2",
          props.className
        )}
      >
        <span className="grid grid-cols-[auto,1fr] items-center gap-2">
          <label
            htmlFor={`checkboxFor${props.item.id}`}
            className={ClientUtils.classNames(
              "rounded text-sm font-semibold md:text-base",
              !isRequired && "border-b pb-1"
            )}
          >
            {label}
          </label>
          <ChecklistAnsweredIndicator
            isAnswered={isAnswered}
            className="border-b pb-1"
            isRequired={isRequired}
          />
        </span>

        <select
          className="my-auto mt-2 w-full cursor-pointer rounded border bg-white/40 p-2"
          id={`checkboxFor${props.item.id}`}
          {...props.form.register(props.item.id + ".answer", {
            required: true,
          })}
          defaultValue={noOption}
          disabled={props.readOnly}
          data-checklist-item-type={"multipleChoiceSelect"}
        >
          <option disabled value={noOption}>
            Välj
          </option>
          {props.options.map((opt, i) => {
            const key = opt.value + props.item.id + i;
            return (
              <option key={key} value={opt.value}>
                {opt.label}
              </option>
            );
          })}
        </select>
      </p>
    );
  }

  return (
    <fieldset
      className={ClientUtils.classNames("flex flex-col", props.className)}
      data-checklist-item-type={"multipleChoiceRadio"}
    >
      <legend className="mb-1 grid w-full grid-cols-[auto,1fr] items-center gap-2 border-b pb-1 text-sm font-semibold md:text-base">
        <span>{label}</span>
        <ChecklistAnsweredIndicator
          isAnswered={isAnswered}
          isRequired={isRequired}
        />
      </legend>
      {props.options.map((opt, i) => {
        const key = opt.value + props.item.id + i;
        return (
          <p key={key} className="flex items-center gap-2 p-2">
            <input
              className="h-5 w-5  cursor-pointer accent-slate-600"
              type="radio"
              id={key}
              value={opt.value}
              {...props.form.register(props.item.id + ".answer")}
              disabled={props.readOnly}
            />
            <label htmlFor={key} className="grow cursor-pointer">
              {opt.label}
            </label>
          </p>
        );
      })}
    </fieldset>
  );
};

export default ChecklistItemSelect;
