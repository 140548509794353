import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { AppLoader } from "../../../../components/common/loaders/AppLoader";
import AppPage from "../../../../components/layout/AppPage";
import SomethingWentWrong from "../../../../components/on-error/SomethingWentWrong";

import SalesTeamWorkOrderFileTab from "../SalesTeamWorkOrderFileTab";
import { SalesTeamWorkOrderRouteContext } from "../SalesTeamWorkOrderRoute";

interface Props {
  className?: string;
}

const SalesTeamWorkOrderMobilePage = (props: Props) => {
  const { orderRes, order } = useContext(SalesTeamWorkOrderRouteContext);

  return (
    <AppPage
      className={ClientUtils.twClassNames(
        "row-span-full mx-auto flex h-full w-full grow flex-col gap-2 overflow-y-auto overflow-x-hidden px-2 pt-1",
        props.className
      )}
      headerProps={{
        RenderHeadings: (props) => {
          return (
            <>
              <h2
                className={ClientUtils.twClassNames(
                  "col-span-3 text-base",
                  props.headingClassName
                )}
              >
                {order.description}
              </h2>
              <h3
                className={ClientUtils.twClassNames(
                  "col-span-3 text-sm font-normal italic",
                  props.subheadingClassName
                )}
              >
                #{order.serialNumber}
              </h3>
              {order.isFinished && (
                <h4 className="col-span-3 text-sm text-green-600">
                  Klarmarkerad
                </h4>
              )}
              {orderRes.isLoading && (
                <AppLoader className="col-start-4 row-span-2 row-start-2 my-auto ml-auto h-6 w-6 p-2" />
              )}
            </>
          );
        },
      }}
    >
      <ErrorBoundary
        fallbackRender={(props) => (
          <SomethingWentWrong
            error={props.error}
            onTryAgain={() => orderRes.refetch()}
            tryAgainLabel="Hämta på nytt"
            description="Det gick inte att hämta detta uppdrag just nu."
          />
        )}
      >
        <SalesTeamWorkOrderFileTab className="h-full" />
      </ErrorBoundary>
    </AppPage>
  );
};

export default SalesTeamWorkOrderMobilePage;
