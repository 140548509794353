import ClientUtils from "./../../../../utils/ClientUtils";
import OverviewCoordinatorSection from "../overview/OverviewCoordinatorSection";
import OverviewCustomerSection from "../overview/OverviewCustomerSection";
import OverviewHeader from "../overview/OverviewHeader";
import OverviewServiceContractSection from "../overview/OverviewServiceContractSection";
import OverviewTaskSection from "../overview/OverviewTaskSection";
import OverviewImportantInformationSection from "../overview/OverviewImportantInformationSection";

interface Props {
  className?: string;
}

const HandymanWorkOrderMobileOverviewSection = (props: Props) => {
  return (
    <section
      data-test-id="handyman-work-order-mobile-overview-section"
      className={ClientUtils.twClassNames(
        "flex min-h-[100%] flex-col gap-2 bg-bg-base-layer p-2",

        props.className
      )}
    >
      <OverviewHeader />
      <main className="flex flex-col gap-2 bg-bg-base-layer pb-2">
        <OverviewImportantInformationSection />
        <OverviewTaskSection />
        <OverviewCoordinatorSection />
        <OverviewCustomerSection />
        <OverviewServiceContractSection />
      </main>
    </section>
  );
};

export default HandymanWorkOrderMobileOverviewSection;
