import { UUID } from "@eljouren/utils";
import { useRef } from "react";
import ClientUtils from "./../../../utils/ClientUtils";

interface Props {
  label?: string;
  errorMessage?: string;
  className?: string;
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
}
export function AppTextField(props: Props) {
  let srOnlyLabel: string | undefined;
  if (!props.label && props.htmlAttributes?.placeholder) {
    srOnlyLabel = props.htmlAttributes.placeholder;
  }

  const id = useRef(UUID.generate().value).current;

  return (
    <p
      className={ClientUtils.twClassNames(
        "flex w-full flex-col gap-1",
        props.className
      )}
    >
      {props.label && (
        <label htmlFor={id} className="text-sm">
          {props.label}
        </label>
      )}
      {srOnlyLabel && (
        <label htmlFor={id} className="sr-only">
          {srOnlyLabel}
        </label>
      )}
      <input
        className={ClientUtils.twClassNames(
          "disabled:opacity-1 opacity-1 flex w-full rounded border fill-black p-2 text-black placeholder:text-sm placeholder:italic placeholder:text-black/60",
          props.htmlAttributes?.readOnly
            ? "border-form-border-disabled"
            : "border-form-border"
        )}
        id={id}
        {...props.htmlAttributes}
      ></input>
      {props.errorMessage && <span>{props.errorMessage}</span>}
    </p>
  );
}
