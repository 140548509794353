import { DateHelper } from "@eljouren/utils";
import { useContext } from "react";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import ClientUtils from "./../../../../utils/ClientUtils";

import Logo from "../../../../components/common/brand/Logo";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import AppCalendarIcon from "../../../../components/icons/AppCalendarIcon";
import AppMapPinIcon from "../../../../components/icons/AppMapPinIcon";
import ConditionalCheckInOutButton from "../ConditionalCheckInOutButton";
import HandymanWorkOrderCheckInOutContext from "../contexts/HandymanWorkOrderCheckInOutContext";
import HandymanWorkOrderRouteContext, {
  WorkOrderControlModule,
  WorkOrderTabs,
} from "../contexts/HandymanWorkOrderRouteContext";
import HandymanWorkOrderPreparationFormComponent from "../HandymanWorkOrderPreparationFormComponent";

interface Props {
  className?: string;
}

const OverviewHeader = (props: Props) => {
  const routeCtx = useContext(HandymanWorkOrderRouteContext);
  const isSm = useMediaQuery(TailwindBreakpoint.sm);
  const isDesktop = useMediaQuery(TailwindBreakpoint.xl);
  const checkInOutCtx = useContext(HandymanWorkOrderCheckInOutContext);

  function getLocationString() {
    return `${routeCtx.order.location.street}, ${routeCtx.order.location.postalCode} ${routeCtx.order.location.city}`;
  }
  function googleMapsUrl(): string {
    const address = getLocationString();
    const base = "https://www.google.com/maps/search/?";
    const params = new URLSearchParams({
      api: "1",
      query: address,
    });
    return `${base}${params}`;
  }
  function getDateTimeString() {
    return new DateHelper(routeCtx.order.startDate).dateTimeInputFormat.replace(
      "T",
      " "
    );
  }

  function showCheckInOutInfo() {
    routeCtx.navigateTo({
      tab: WorkOrderTabs.handle,
      module: WorkOrderControlModule.checkInOut,
    });
  }

  const brand = routeCtx.order.brand;

  const status = routeCtx.order.workOrderStatus;
  const unsuccessfulReason = status.unsuccessfulReason;
  const unsuccessfulComment = status.unsuccessfulComment;
  const standardisedUnuccessfulReason =
    unsuccessfulReason === "other" ? "Annan anledning" : unsuccessfulReason;

  return (
    <header
      data-test-id="handyman-work-order-overview-header"
      className={ClientUtils.twClassNames(
        "flex flex-col gap-4 rounded border-b bg-off-white p-2 shadow md:grid md:grid-cols-[minmax(0,1fr),auto] lg:p-4",
        props.className
      )}
    >
      <div className="flex flex-col">
        <span className="grid grid-cols-[minmax(0,1fr),auto] grid-rows-1 items-center justify-between gap-4">
          <h3 className="text-lg font-bold xl:text-xl">
            {routeCtx.order.description}
          </h3>
          <Logo brand={brand} dontUseLink wrapperClassName="w-20" withColor />
        </span>
        {!!unsuccessfulReason && (
          <span className="flex flex-col">
            <h4 className="italic text-red-700">
              {standardisedUnuccessfulReason}
            </h4>
            {!!unsuccessfulComment && (
              <p className="text-sm italic text-dark-gray text-opacity-80">
                {unsuccessfulComment}
              </p>
            )}
          </span>
        )}
        <a
          data-test-id="work-order-location-link"
          className="group mr-auto grid grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2 py-2"
          href={googleMapsUrl()}
        >
          <AppMapPinIcon size={20} className="row-span-2" />
          <span className="sr-only">Adress för arbetsorder:</span>
          {!isSm && (
            <>
              <span
                data-test-id="work-order-street"
                className="text-blue-600 group-hover:text-blue-400"
              >
                {routeCtx.order.location.street}
              </span>
              <span
                data-test-id="work-order-postal-code-and-city"
                className="text-blue-600 group-hover:text-blue-400"
              >
                {routeCtx.order.location.postalCode}{" "}
                {routeCtx.order.location.city}
              </span>
            </>
          )}
          {isSm && (
            <span
              data-test-id="work-order-location"
              className="text-blue-600 group-hover:text-blue-400 xl:text-lg"
            >
              {getLocationString()}
            </span>
          )}
        </a>
        <p
          data-test-id="work-order-date-and-time-wrapper"
          className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2 xl:text-lg"
        >
          <AppCalendarIcon size={20} />
          <span className="sr-only">Datum och tid för arbetsorder:</span>
          <span data-test-id="work-order-date-and-time">
            {getDateTimeString()}
          </span>
        </p>
      </div>
      <div className="col-start-1 w-full">
        <HandymanWorkOrderPreparationFormComponent className="w-full" />
      </div>
      {checkInOutCtx.canCheckInOut && !isDesktop && (
        <div
          data-test-id="work-order-overview-check-in-out-fieldset"
          className="flex flex-col gap-2"
        >
          <ConditionalCheckInOutButton />
          <AppButton variant="outline-default" onClick={showCheckInOutInfo}>
            Visa mer information
          </AppButton>
        </div>
      )}
    </header>
  );
};

export default OverviewHeader;
