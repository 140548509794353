"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdUtils = void 0;
var BankIdUtils;
(function (BankIdUtils) {
    function isFinal(response) {
        if (isFailed(response)) {
            return true;
        }
        if ("status" in response) {
            return response.status === "complete";
        }
        /*
          Not 100% sure this is accurate all of the time
        */
        return false;
    }
    BankIdUtils.isFinal = isFinal;
    function isFailed(response) {
        if ("status" in response) {
            return response.status === "failed";
        }
        if ("errorCode" in response) {
            return true;
        }
        return false;
    }
    function getBankIdRenderOptions(response) {
        var data = response;
        var isPending = (data === null || data === void 0 ? void 0 : data.status) === "pending";
        var isComplete = (data === null || data === void 0 ? void 0 : data.status) === "complete" && data.handledStatus.handled;
        var isUnhandledComplete = (data === null || data === void 0 ? void 0 : data.status) === "complete" && !data.handledStatus.handled;
        var isFailed = (data === null || data === void 0 ? void 0 : data.status) === "failed";
        var isWaitingForAppToStart = !data ||
            (isPending &&
                (data.hintCode === "outstandingTransaction" ||
                    data.hintCode === "noClient"));
        var isUnknownPending = isPending && data.isUnknown;
        var isUnknownFailed = isFailed && data.isUnknown;
        var isUnknownWithoutStatus = (data === null || data === void 0 ? void 0 : data.isUnknown) && !data.hasStatus;
        if (isPending) {
            switch (data.hintCode) {
                case "started":
                    return {
                        key: "started",
                        actions: ["cancel"],
                        label: "Söker efter BankID, det kan ta en liten stund… Om det har gått några sekunder och inget BankID har hittats har du sannolikt inget BankID som går att använda för den aktuella identifieringen/underskriften i den här enheten. Om du inte har något BankID kan du skaffa ett hos din bank. Om du har ett BankID på en annan enhet kan du starta din BankID-app där.",
                        status: "pending",
                    };
                case "userSign":
                    return {
                        key: "signStarted",
                        actions: [],
                        label: "Underskrift påbörjad i BankID-appen",
                        status: "pending",
                    };
            }
        }
        if (data === null || data === void 0 ? void 0 : data.hasErrorCode) {
            switch (data.errorCode) {
                case "cancelled":
                    return {
                        key: "cancelled",
                        actions: ["restart"],
                        label: "Åtgärden avbruten. Försök igen.",
                        status: "failedOrError",
                    };
                case "alreadyInProgress":
                    return {
                        key: "alreadyInProgress",
                        actions: ["restart"],
                        label: "En identifiering eller underskrift för det här personnumret är redan påbörjad. Försök igen.",
                        status: "failedOrError",
                    };
                case "requestTimeout":
                case "maintenance":
                case "internalError":
                default:
                    return {
                        key: "internalError",
                        actions: ["restart"],
                        label: "Internt tekniskt fel. Försök igen.",
                        status: "failedOrError",
                    };
            }
        }
        if (isFailed) {
            switch (data.hintCode) {
                case "userCancel":
                    return {
                        key: "userCancel",
                        actions: ["goBack"],
                        label: "Åtgärden avbruten.",
                        status: "failedOrError",
                    };
                case "expiredTransaction":
                    return {
                        key: "expiredTransaction",
                        actions: ["restart"],
                        label: "BankID-appen svarar inte. Kontrollera att den är startad och att du har internetanslutning. Om du inte har något giltigt BankID kan du skaffa ett hos din bank. Försök sedan igen.",
                        status: "failedOrError",
                    };
                case "certificateErr":
                    return {
                        key: "certificateErr",
                        actions: ["goBack"],
                        label: "Det BankID du försöker använda är för gammalt eller spärrat. Använd ett annat BankID eller skaffa ett nytt hos din bank.",
                        status: "failedOrError",
                    };
                case "startFailed":
                    return {
                        key: "startFailed",
                        actions: ["restart"],
                        label: "BankID-appen verkar inte finnas i din dator eller mobil. Installera den och skaffa ett BankID hos din bank. Installera appen från din appbutik eller https://install.bankid.com",
                        status: "failedOrError",
                    };
            }
        }
        if (isWaitingForAppToStart || isUnknownPending) {
            return {
                key: "signInstructions",
                actions: ["openApp"],
                //label: "",
                status: "pending",
            };
        }
        if (isUnknownFailed || isUnknownWithoutStatus) {
            return {
                key: "unknownFailed",
                actions: ["restart"],
                label: "Just nu gick det inte att genomföra din underskrift. Försök igen senare.",
                status: "failedOrError",
            };
        }
        if (isComplete) {
            return {
                key: "complete",
                status: "success",
            };
        }
        if (isUnhandledComplete) {
            /*
              This will always be true, but we need to check it to make typescript happy
            */
            if (!data.handledStatus.handled) {
                var reason = data.handledStatus.reason;
                switch (reason) {
                    case "FAILED_TO_SAVE":
                        return {
                            key: "unhandledComplete",
                            actions: ["restart"],
                            label: "Underskriften misslyckades att sparas. Vänligen försök igen senare.",
                            status: "failedOrError",
                        };
                    case "RESOURCE_OUTDATED":
                        return {
                            key: "unhandledComplete",
                            actions: ["restart"],
                            label: "Er hantverkare verkar ha gjort ändringar i ärendet sen underskriften påbörjades och Er underskrift har därför inte sparats. Vänligen kontrollera att allt stämmer på nytt och försök igen.",
                            status: "failedOrError",
                        };
                    case "NOTHING_TO_SIGN":
                    case "UNKNOWN":
                    /*
                          Don't return anything, show the unknown error message instead
                        */
                }
            }
        }
        /*
            ToDo:
             * Figure out when this happens
          */
        return {
            key: "unknown",
            actions: ["restart"],
            label: "Okänt fel. Försök igen.",
            status: "failedOrError",
        };
    }
    BankIdUtils.getBankIdRenderOptions = getBankIdRenderOptions;
})(BankIdUtils || (BankIdUtils = {}));
exports.BankIdUtils = BankIdUtils;
