"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormInputNote = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var client_utils_1 = require("../../../../utils/client-utils");
var RichTextPreTag_1 = require("./RichTextPreTag");
var IpisFormInputNote = function (props) {
    return ((0, jsx_runtime_1.jsx)(RichTextPreTag_1.RichTextPreTag, { html: props.html, className: (0, client_utils_1.classNames)(props.className, "text-xs text-dark-500 sm:text-sm") }));
};
exports.IpisFormInputNote = IpisFormInputNote;
