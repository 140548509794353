"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisIconRouter = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var IpisCaretIcon_1 = require("../IpisCaretIcon");
var IpisEditIcon_1 = require("../IpisEditIcon");
var IpisImageGroupFieldIcon_1 = require("../IpisImageGroupFieldIcon");
var IpisInformationIcon_1 = require("../IpisInformationIcon");
var IpisMailIcon_1 = require("../IpisMailIcon");
var IpisMultipleChoiceFieldIcon_1 = require("../IpisMultipleChoiceFieldIcon");
var IpisNumberFieldIcon_1 = require("../IpisNumberFieldIcon");
var IpisPlusIcon_1 = require("../IpisPlusIcon");
var IpisProfileIcon_1 = require("../IpisProfileIcon");
var IpisTextFieldIcon_1 = require("../IpisTextFieldIcon");
var IpisTrashIcon_1 = require("../IpisTrashIcon");
var IpisXIcon_1 = require("../IpisXIcon");
var IpisYesNoFieldIcon_1 = require("../IpisYesNoFieldIcon");
var IpisIconRouter = function (props) {
    var icon = props.icon, rest = __rest(props, ["icon"]);
    switch (icon) {
        case "plus":
            return (0, jsx_runtime_1.jsx)(IpisPlusIcon_1.IpisPlusIcon, __assign({}, rest));
        case "trash":
            return (0, jsx_runtime_1.jsx)(IpisTrashIcon_1.IpisTrashIcon, __assign({}, rest));
        case "information":
            return (0, jsx_runtime_1.jsx)(IpisInformationIcon_1.IpisInformationIcon, __assign({}, rest));
        case "close":
            return (0, jsx_runtime_1.jsx)(IpisXIcon_1.IpisXIcon, __assign({}, rest));
        case "text-field":
            return (0, jsx_runtime_1.jsx)(IpisTextFieldIcon_1.IpisTextFieldIcon, __assign({}, rest));
        case "number-field":
            return (0, jsx_runtime_1.jsx)(IpisNumberFieldIcon_1.IpisNumberFieldIcon, __assign({}, rest));
        case "multiple-choice-field":
            return (0, jsx_runtime_1.jsx)(IpisMultipleChoiceFieldIcon_1.IpisMultipleChoiceFieldIcon, __assign({}, rest));
        case "image-group-field":
            return (0, jsx_runtime_1.jsx)(IpisImageGroupFieldIcon_1.IpisImageGroupFieldIcon, __assign({}, rest));
        case "yes-no-field":
            return (0, jsx_runtime_1.jsx)(IpisYesNoFieldIcon_1.IpisYesNoFieldIcon, __assign({}, rest));
        case "edit":
            return (0, jsx_runtime_1.jsx)(IpisEditIcon_1.IpisEditIcon, __assign({}, rest));
        case "caret-left":
            return (0, jsx_runtime_1.jsx)(IpisCaretIcon_1.IpisCaretIcon, __assign({}, rest, { direction: "left" }));
        case "mail":
            return (0, jsx_runtime_1.jsx)(IpisMailIcon_1.IpisMailIcon, __assign({}, rest));
        case "profile":
            return (0, jsx_runtime_1.jsx)(IpisProfileIcon_1.IpisProfileIcon, __assign({}, rest));
    }
};
exports.IpisIconRouter = IpisIconRouter;
