import React, { useEffect, useState } from "react";

type Dimensions = {
  width: number | null;
  height: number | null;
  initialised: boolean;
  supported: boolean;
};

function useElementDimensions(ref: React.RefObject<any>): Dimensions {
  const supported = "ResizeObserver" in window;
  const [dimensions, setDimensions] = useState<Dimensions>({
    width: null,
    height: null,
    initialised: !supported,
    supported,
  });

  /*  useEffect(() => {
    console.log("new render", dimensions);

    const supported = "ResizeObserver" in window;
    const current = ref.current;

    if (!current || !supported) {
      return;
    }

    const differentWidth = dimensions.width !== current.offsetWidth;
    const differentHeight = dimensions.height !== current.offsetHeight;
    const differentDimensions = differentWidth || differentHeight;

    console.log("differentDimensions", differentDimensions);

    if (!dimensions.initialised || differentDimensions) {
      setDimensions((prev) => ({
        ...prev,
        initialised: true,
        width: current.offsetWidth,
        height: current.offsetHeight,
      }));
    }
  }, [dimensions.width, dimensions.height, dimensions.initialised]); */

  useEffect(() => {
    const supported = "ResizeObserver" in window;

    const current = ref.current;
    if (current && supported) {
      const updateDimensions = () => {
        const differentWidth = dimensions.width !== current.offsetWidth;
        const differentHeight = dimensions.height !== current.offsetHeight;
        const differentDimensions = differentWidth || differentHeight;

        if (differentDimensions) {
          setDimensions((prev) => ({
            ...prev,
            initialised: true,
            width: ref.current?.offsetWidth ?? null,
            height: ref.current?.offsetHeight ?? null,
          }));
        }
      };

      const resizeObserver = new ResizeObserver(() => {
        updateDimensions();
      });

      resizeObserver.observe(ref.current);

      updateDimensions();

      // Cleanup observer on component unmount
      return () => resizeObserver.disconnect();
    }
  });

  return dimensions;
}

export default useElementDimensions;
