import { useContext } from "react";
import { CustomerWorkOrderFormContext } from "../CustomerWorkOrderFormSectionV2";

interface Props {
  className?: string;
}

const CustomerWorkOrderInformationPageIntroductoryHeader = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderFormContext);
  const workOrder = ctx.workOrder;

  const coordinator = workOrder.coordinator;
  const coordinatorFullName = `${coordinator.firstName} ${coordinator.lastName}`;
  return (
    <header className="grid grid-cols-[1fr,auto] grid-rows-[min-content,min-content,min-content] gap-x-4">
      <h2 className="pb-2 text-2xl text-dark-950">
        <span>Hej</span>{" "}
        <span className="text-dark-500">{workOrder.contact.fullName}</span>,
      </h2>
      <h3>Mitt namn är {coordinatorFullName}, din bokare:</h3>
      <p className="text-dark-800">
        Vi har tagit emot din order och skulle älska om du kunde fylla i lite
        information angående din arbetsorder för att försäkra att vi kan ge dig
        bästa möjliga service.
      </p>
      <img
        className="col-start-2 row-span-3 row-start-1 m-auto h-20 w-20 rounded object-cover"
        alt={`Din bokare, ${coordinatorFullName}`}
        src="https://images.pexels.com/photos/614810/pexels-photo-614810.jpeg?cs=srgb&dl=pexels-simon-robben-55958-614810.jpg&fm=jpg"
      />
    </header>
  );
};

export default CustomerWorkOrderInformationPageIntroductoryHeader;
