import React, { useEffect, useState } from "react";
import { useOnOutsideClick } from "../../../hooks/hooks";
import ClientUtils from "../../../utils/ClientUtils";
import AppCaretIcon from "../../icons/AppCaretIcon";
import { SvgIconSize } from "../../icons/types/SvgIconProps";

type Direction = "top" | "bottom";

type HtmlProps = React.HTMLAttributes<Element>;
type AriaKeys = {
  [K in keyof HtmlProps]: K extends `aria-${string}` ? K : never;
}[keyof HtmlProps];
type AriaAttributes = Pick<HtmlProps, Exclude<AriaKeys, undefined>>;
type DataAttributes = {
  [K in `data-${string}`]?: string;
};

export interface DropdownProps {
  className?: string;
  contentId: string;
  wrapperId?: string;
  title: string | (() => React.ReactNode);
  children?: React.ReactNode;
  direction?: Direction;
  buttonClassName?: string;
  popup?: boolean;
  preventHideOnOutsideClick?: boolean;
  contentWrapperClassName?: string;
  hideCaret?: boolean;
  variant?: "transparent";
  caretSize?: SvgIconSize;
  initiallyOpen?: boolean;
  aria?: AriaAttributes;
  data?: DataAttributes;
  scrollIntoView?: boolean;
}

/**
 * [BUG]: Dropdown Component Closing Issue
 * [Description]: When multiple dropdown components are used in the layout,
 *                clicking a dropdown (let’s call it Dropdown B) that is
 *                positioned below an already open dropdown (Dropdown A),
 *                results in Dropdown B not opening. This issue arises
 *                because Dropdown A collapses, causing a layout shift
 *                that impacts the interaction with Dropdown B.
 *
 *                This issue occurs due to the click event on Dropdown B
 *                being registered after the layout shift caused by the
 *                closing of Dropdown A when "preventHideOnOutsideClick"
 *                prop is set to true.
 *
 * [Status]: Pending - awaiting a fix
 * [Priority]: Fix as soon as multiple dropdowns with "preventHideOnOutsideClick" is needed in the GUI
 * [Potential Fix]: Adjust the event sequence or the handling of outside
 *                   clicks to ensure that the click on Dropdown B is
 *                   registered before the layout shift.
 * [Note]: A temporary solution could involve using `mousedown` or
 *         `touchstart` events, but due consideration must be given to
 *         accessibility and cross-browser compatibility.
 */

const A11yDropdown = (props: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(!!props.initiallyOpen);
  const ref = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLSpanElement>(null);

  useOnOutsideClick(ref, () => {
    if (props.preventHideOnOutsideClick) {
      return;
    }
    setIsOpen(false);
  });

  useEffect(() => {
    if (isOpen && props.scrollIntoView && ref.current) {
      ref.current.scrollIntoView({
        block: "nearest",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  }, [isOpen, props.scrollIntoView]);

  function onButtonClick() {
    const newValue = !isOpen;
    setIsOpen(newValue);

    /*   if (props.scroll && newValue) {
      const { scrollContainerRef, scrollIntoView } = props.scroll;
      if (scrollContainerRef.current && scrollIntoView) {
        scrollContainerRef.current.scrollIntoView({
          block: "nearest",
          inline: "nearest",
          behavior: "smooth",
        });
      }
    } */
  }

  const openBottom = !props.direction || props.direction === "bottom";
  const openTop = props.direction === "top";
  //const transparent = !props.variant || props.variant === "transparent";
  const controlsId = props.contentId + "-controls";

  return (
    <div
      id={props.wrapperId}
      className={ClientUtils.twClassNames(
        "relative grid",
        openBottom && "grid-rows-[auto,minmax(0,1fr)]",
        openTop && "grid-rows-[minmax(0,1fr),auto]",
        props.className
      )}
      ref={ref}
      {...props.aria}
      {...props.data}
      data-test-class="a11y-dropdown-wrapper"
    >
      <button
        id={controlsId}
        type="button"
        aria-haspopup={!!props.popup}
        aria-controls={props.contentId}
        aria-expanded={isOpen}
        onClick={onButtonClick}
        //onMouseDown={onButtonClick}
        //onTouchStart={onButtonClick}
        className={ClientUtils.twClassNames(
          openBottom && "row-start-1",
          openTop && "row-start-2",
          "flex items-center justify-between",
          props.buttonClassName
        )}
      >
        {typeof props.title !== "string" && props.title()}
        {typeof props.title === "string" && (
          <span className="grow-gap-2 flex">{props.title}</span>
        )}
        {!props.hideCaret && (
          <AppCaretIcon
            direction={openTop ? "up" : "down"}
            size={props.caretSize ?? "md"}
            className={ClientUtils.twClassNames(
              "my-auto ml-auto transition-all",
              isOpen && "rotate-180"
            )}
          />
        )}
      </button>
      {isOpen && (
        <span
          ref={contentRef}
          aria-labelledby={controlsId}
          id={props.contentId}
          className={ClientUtils.twClassNames(
            "cursor-auto",
            openBottom && "row-start-2",
            openTop && "row-start-1",
            props.popup && "absolute z-40",
            props.popup && openBottom && "bottom-full rounded-t",
            props.popup && openTop && "top-full rounded-b",
            props.contentWrapperClassName
          )}
        >
          {props.children}
        </span>
      )}
    </div>
  );
};

export default A11yDropdown;
