import { IpisFormPage } from "@eljouren/domain";
import { FormProgressIndicatorListItem } from "@ipis/client-essentials";
import { Reorder, useAnimation } from "framer-motion";
import { useEffect } from "react";
import { useFormEditor } from "../../../../../hooks/checklist-hooks";
import { usePrevious } from "../../../../../hooks/hooks";
import { useContextMenu } from "../../../../../hooks/use-context-menu";
import AppContextMenu from "../../../../__v2__/menu/AppContextMenu";
import { Menu } from "../../../../__v2__/menu/AppMenu";

interface Props {
  className?: string;
  pages: IpisFormPage.WithoutAnswersType[];
  currentStep: number;
}

const EditableChecklistProgressIndicator = (props: Props) => {
  const selectedCtx = useFormEditor();

  return (
    <Reorder.Group
      axis="x"
      values={props.pages}
      onReorder={(pages) => {
        selectedCtx.controls.reorderPages({ pages });
      }}
      className="grid w-full gap-x-2 p-2"
      style={{
        gridTemplateColumns: `repeat(${props.pages.length}, minmax(0, 1fr))`,
      }}
    >
      {props.pages.map((page, index) => {
        return (
          <ReorderListItem
            pageCount={props.pages.length}
            page={page}
            index={index}
            currentStep={props.currentStep}
            key={page.id}
          />
        );
      })}
    </Reorder.Group>
  );
};

const ReorderListItem = (props: {
  pageCount: number;
  page: IpisFormPage.WithoutAnswersType;
  index: number;
  currentStep: number;
}) => {
  const contextMenuReturn = useContextMenu();
  const editorCtx = useFormEditor();

  const contextMenu: Menu = {
    items: [
      {
        id: "delete-page",
        type: "button",
        label: "Ta bort sida",
        icon: "trash",
        disabled: props.pageCount === 1,
        onClick: () => {
          editorCtx.controls.removePage({
            pageId: props.page.id,
          });
        },
      },
    ],
  };

  const latestEvent = editorCtx.getLatestEvent();
  const controls = useAnimation();

  const toPageId =
    latestEvent?.discriminator === "moveElementToAnotherPageEvent"
      ? latestEvent.toPageId
      : null;
  const previousId = usePrevious(latestEvent?.id);
  const sameId = latestEvent?.id === previousId;

  const shouldBlink = toPageId === props.page.id && !sameId;

  useEffect(() => {
    if (shouldBlink) {
      controls.start({
        opacity: [1, 0.5, 1, 0.5, 1],
        transition: { duration: 1.5 },
      });
    }
  }, [latestEvent, shouldBlink, controls]); // Depend on latestEvent to re-trigger the effect

  const { page, index, currentStep } = props;

  return (
    <Reorder.Item
      value={page}
      animate={controls}
      onClick={() => editorCtx.setPage(page)}
      onContextMenu={contextMenuReturn.listener}
      className="cursor-move  hover:bg-gray-300"
    >
      <FormProgressIndicatorListItem
        label={page.pageTitleShorthand}
        as="div"
        index={index}
        currentStep={currentStep}
        fixedAtTop
      />
      {contextMenuReturn && contextMenuReturn.isVisible && (
        <AppContextMenu
          ctxMenu={contextMenuReturn}
          id={`context-menu-for-${page.id}`}
          variant="light"
          menu={contextMenu}
        />
      )}
    </Reorder.Item>
  );
};

export default EditableChecklistProgressIndicator;
