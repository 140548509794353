"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TailwindBreakpoint = void 0;
exports.useMediaQueries = useMediaQueries;
var react_1 = require("react");
var utils_1 = require("@eljouren/utils");
var TailwindBreakpoint;
(function (TailwindBreakpoint) {
    TailwindBreakpoint[TailwindBreakpoint["xs"] = 475] = "xs";
    TailwindBreakpoint[TailwindBreakpoint["sm"] = 640] = "sm";
    TailwindBreakpoint[TailwindBreakpoint["md"] = 768] = "md";
    TailwindBreakpoint[TailwindBreakpoint["lg"] = 1024] = "lg";
    TailwindBreakpoint[TailwindBreakpoint["xl"] = 1280] = "xl";
    TailwindBreakpoint[TailwindBreakpoint["xxl"] = 1536] = "xxl";
})(TailwindBreakpoint || (exports.TailwindBreakpoint = TailwindBreakpoint = {}));
function constructQueryString(obj) {
    return utils_1.Utils.entries(obj)
        .filter(function (entry) { return entry !== undefined; })
        .map(function (entry) {
        if (entry === undefined) {
            return "";
        }
        var key = entry[0], val = entry[1];
        switch (key) {
            case "minWidth":
                return "(min-width: ".concat(val, "px)");
            case "maxWidth":
                return "(max-width: ".concat(val, "px)");
            case "orientation":
                return "(orientation: ".concat(val, ")");
            default:
                return "";
        }
    })
        .join(" AND ");
}
function queryObjectFromTailwindBreakpoint(breakpoint) {
    return {
        minWidth: breakpoint,
    };
}
function useMediaQueries(breakpoints) {
    var _a = (0, react_1.useState)("default"), activeBreakPoint = _a[0], setActiveBreakPoint = _a[1];
    (0, react_1.useEffect)(function () {
        var mediaQueryLists = breakpoints.map(function (bp) {
            var breakpoint = TailwindBreakpoint[bp];
            var obj = queryObjectFromTailwindBreakpoint(breakpoint);
            var queryString = constructQueryString(obj);
            return window.matchMedia(queryString);
        });
        function determineActiveBreakpoint() {
            for (var i = 0; i < mediaQueryLists.length; i++) {
                if (mediaQueryLists[i].matches) {
                    return breakpoints[i];
                }
            }
            return "default";
        }
        function updateActiveBreakpoint() {
            var newBreakpoint = determineActiveBreakpoint();
            if (newBreakpoint !== activeBreakPoint) {
                setActiveBreakPoint(newBreakpoint);
            }
        }
        mediaQueryLists.forEach(function (mql) {
            return mql.addEventListener("change", updateActiveBreakpoint);
        });
        updateActiveBreakpoint(); // Set the initial value
        return function () {
            mediaQueryLists.forEach(function (mql) {
                return mql.removeEventListener("change", updateActiveBreakpoint);
            });
        };
    }, [breakpoints, activeBreakPoint]);
    return { activeBreakPoint: activeBreakPoint };
}
