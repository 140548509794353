import { WorkOrder } from "@eljouren/domain";
import { DateHelper } from "@eljouren/utils";
import { useRef, useState } from "react";
import WorkOrderIcon from "../../../../components/icons/WorkOrderIcon";
import MyLink from "../../../../components/routing/MyLink";
import { useOnOutsideClick } from "../../../../hooks/hooks";
import AppRoutes from "../../../../routes";
import ClientUtils from "./../../../../utils/ClientUtils";
import HomeIcon from "../../../../components/icons/AppHomeIcon";

interface Props {
  className?: string;
  workOrder: WorkOrder.HandymanWithPermissionsType | null | undefined;
  timing: "before" | "after";
}

const BookingSuggestionSurroundingEvent = (props: Props) => {
  const [showEventDetails, setShowEventDetails] = useState(false);

  const mainDivRef = useRef<HTMLDivElement>(null);
  const moreInfoRef = useRef<HTMLDivElement>(null);

  useOnOutsideClick(
    moreInfoRef,
    () => {
      setShowEventDetails(false);
    },
    mainDivRef
  );

  function onClick() {
    if (!props.workOrder) {
      return;
    }
    setShowEventDetails(!showEventDetails);
  }

  return (
    <div
      className={ClientUtils.twClassNames(
        "relative h-16 w-16 rounded-full border-2 border-brand-blue-100 bg-brand-blue-100",
        props.timing === "after" && "translate-x-2",
        props.timing === "before" && "-translate-x-2",
        !!props.workOrder && "cursor-pointer  hover:border-brand-blue-300",
        props.className
      )}
      ref={mainDivRef}
    >
      <span
        className={ClientUtils.twClassNames(
          "flex h-full w-full items-center justify-center"
        )}
        ref={moreInfoRef}
        onClick={onClick}
      >
        {!!props.workOrder && (
          <WorkOrderIcon
            size={24}
            className={ClientUtils.twClassNames(
              !!props.workOrder.serviceContract && "text-orange-600"
            )}
          />
        )}
        {!props.workOrder && <HomeIcon size={24} />}
      </span>
      {!!props.workOrder && !showEventDetails && (
        <span className="absolute left-1/2 top-full mt-2 -translate-x-1/2 whitespace-nowrap text-xs">
          {new DateHelper(props.workOrder.startDate).timeInputFormat}-
          {new DateHelper(props.workOrder.endDate).timeInputFormat}
        </span>
      )}

      {/* 
        Todo:
        * Extract to component
        * Make link to work order
      */}
      {!!props.workOrder && showEventDetails && (
        <MyLink
          to={AppRoutes.partner.customerOrder(props.workOrder.workerGuid)}
          blank
          className={ClientUtils.twClassNames(
            "absolute top-full mt-2 rounded bg-brand-blue-100 p-4",
            props.timing === "before" && "left-0",
            props.timing === "after" && "right-0 "
          )}
        >
          <header>
            <h3 className="text-base">{props.workOrder.description}</h3>
            <h4 className="whitespace-nowrap text-sm font-normal italic text-dark-gray text-opacity-80">
              {new DateHelper(props.workOrder.startDate).timeInputFormat}-
              {new DateHelper(props.workOrder.endDate).timeInputFormat}
            </h4>
          </header>
          <main>
            <span className="whitespace-nowrap text-xs">
              {props.workOrder.location.street}, {props.workOrder.location.city}{" "}
              {props.workOrder.location.postalCode}
            </span>
          </main>
        </MyLink>
      )}
    </div>
  );
};

export default BookingSuggestionSurroundingEvent;
