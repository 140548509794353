import { IpisFormElement } from "@eljouren/domain";
import { useChecklistConditions } from "../../../../hooks/checklist-hooks";
import ChecklistImageGroupQuestion from "../checklist-question-types/image-group/ChecklistImageGroupQuestion";
import ChecklistInformationQuestion from "../checklist-question-types/information/ChecklistInformationQuestion";
import ChecklistMultipleChoiceQuestion from "../checklist-question-types/multiple-choice/ChecklistMultipleChoiceQuestion";
import ChecklistNumberInputQuestion from "../checklist-question-types/number-input/ChecklistNumberInputQuestion";
import ChecklistRepeaterQuestion from "../checklist-question-types/repeater/ChecklistRepeaterQuestion";
import ChecklistTextAreaQuestion from "../checklist-question-types/text-area/ChecklistTextAreaQuestion";
import ChecklistTextInputQuestion from "../checklist-question-types/text-input/ChecklistTextInputQuestion";
import ChecklistYesNoQuestion from "../checklist-question-types/yes-no/ChecklistYesNoQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.Type;
}

const ChecklistQuestionRouter = (props: Props) => {
  const { question } = props;

  const q = props.question;
  const conditionsMet = useChecklistConditions(q);
  if (!conditionsMet) {
    return <></>;
  }

  if (question.typeOfQuestion === "text") {
    return <ChecklistTextInputQuestion question={question} />;
  }

  if (question.typeOfQuestion === "textarea") {
    return <ChecklistTextAreaQuestion question={question} />;
  }

  if (question.typeOfQuestion === "number") {
    return <ChecklistNumberInputQuestion question={question} />;
  }

  if (question.typeOfQuestion === "image-group") {
    return <ChecklistImageGroupQuestion question={question} />;
  }

  if (question.typeOfQuestion === "yes/no") {
    return <ChecklistYesNoQuestion question={question} />;
  }

  if (question.typeOfQuestion === "information") {
    return <ChecklistInformationQuestion question={question} />;
  }

  if (question.typeOfQuestion === "repeater") {
    return <ChecklistRepeaterQuestion question={question} />;
  }

  if (question.typeOfQuestion === "multiple-choice") {
    return <ChecklistMultipleChoiceQuestion question={question} />;
  }

  console.warn(
    "Question type not supported",
    question.typeOfQuestion,
    question
  );
  return <></>;
};

export default ChecklistQuestionRouter;
