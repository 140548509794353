import { IpisForm } from "@eljouren/domain";
import { UUID } from "@eljouren/utils";
import { IpisLoadingOverlay } from "@ipis/client-essentials";
import { useEffect, useRef, useState } from "react";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import useMutableQuery from "../../../../hooks/use-mutatable-query";
import { useRepos } from "../../../../hooks/use-repos";
import IpisPageWithLogo from "../../../__v2__/layout-v2/IpisPageWithLogo";
import MyDialog from "../../../common/MyDialog";
import SomethingWentWrong from "../../../on-error/SomethingWentWrong";
import FormBuilderContext, { CreateNewFormArgs } from "./FormBuilderContext";
import IpisFormCopier from "./IpisFormCopier";
import IpisFormCreator from "./input-builders/checklist/IpisFormCreator";
import FormEditor from "./layout/editor/FormEditor";
import FormEditorContextProvider from "./layout/editor/FormEditorContextProvider";
import FormSelectionSidebar from "./layout/sidebar/FormSelectionSidebar";

interface Props {
  className?: string;
  usePage?: boolean;
}

const FormBuilder = (props: Props) => {
  const screenSizeBigEnough = useMediaQuery(TailwindBreakpoint.lg);

  if (props.usePage) {
    return (
      <>
        {!screenSizeBigEnough && <ScreenSizeOverlay />}
        <IpisPageWithLogo
          headerProps={{
            heading: "Formulärbyggare",
            subHeading: "Bygg dina egna formulär",
          }}
        >
          <AfterScreenSizeCheck {...props} />
        </IpisPageWithLogo>
      </>
    );
  }

  return (
    <>
      {!screenSizeBigEnough && <ScreenSizeOverlay />}
      <AfterScreenSizeCheck {...props} />;
    </>
  );
};

const ScreenSizeOverlay = () => {
  return (
    <aside className="pointer-events-none fixed left-0 top-0 z-50 flex h-full w-full flex-col items-center justify-center bg-black text-white">
      <h2 className="max-w-screen-sm text-lg">
        Vänligen gör webbläsarfönstret större för att fortsätta.
      </h2>
    </aside>
  );
};

const AfterScreenSizeCheck = (props: Props) => {
  const { ipisFormRepo } = useRepos();

  const formRes = useMutableQuery({
    queryKey: "ipis-forms",
    queryFn: async () => {
      return ipisFormRepo.getAll();
    },
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  /* 
    Not used yet
  */
  /* const validConnections = useQueryWrapper({
    queryKey: "valid-connections",
    queryFn: async () => {
      return ipisFormRepo.getAllValidFormConnections();
    },
  }); */

  const forms = formRes.query.data;
  const [currentFormId, setCurrentFormId] = useState<string | null>(null);
  const currentForm = forms?.find((f) => f.id === currentFormId);

  const selectedIsDirtyRef = useRef(false);

  /* useEffect(() => {
    console.log({
      isFetching: formRes.query.isFetching,
    })
  }) */

  useEffect(() => {
    if (currentFormId === null) {
      return;
    }

    const formInArray = forms?.find((f) => f.id === currentFormId);

    if (!formInArray) {
      window.ipisModal.alert({
        title: "Formulär borttaget",
        prompt:
          "Det valda formuläret har tagits bort. Välj ett annat formulär.",
        typeOfAlert: "error",
      });
      setCurrentFormId(null);
    }
  }, [forms, currentFormId]);

  function setSelectedFormIsDirty(isDirty: boolean) {
    selectedIsDirtyRef.current = isDirty;
  }

  async function createNewForm(args: CreateNewFormArgs): Promise<void> {
    try {
      const id = UUID.generate().value;
      const form: IpisForm.ShellType = {
        id,
        clientSideId: id,
        type: "EXTERNAL_CUSTOMER_WORK_ORDER_PREPARATION_FORM",
        name: args.name,
        pages: args.pages.map((p) => {
          const pageId = UUID.generate().value;
          return {
            ...p,
            id: pageId,
            clientSideId: pageId,
            elements: [],
          };
        }),
        hasAnswers: false,
        isInactive: false,
      };

      console.log(form);

      await formRes.mutate({
        callback: () => {
          return ipisFormRepo.saveForm({ form });
        },
      });
    } catch (er) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kunde inte skapa formuläret",
        typeOfAlert: "error",
      });
    }
  }

  async function deleteForm(): Promise<void> {
    try {
      if (!currentForm) {
        throw new Error("No form selected");
      }
      const confirm = await window.ipisModal.confirm({
        title: "Ta bort formulär",
        prompt: "Är du säker på att du vill ta bort formuläret?",
        confirmLabel: "Ja, ta bort",
        rejectLabel: "Nej, lämna kvar",
      });

      if (!confirm) {
        return;
      }

      await formRes.mutate({
        callback: () => {
          return ipisFormRepo.delete({ formId: currentForm.id });
        },
      });

      setCurrentFormId(null);
    } catch (er) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kunde inte ta bort formuläret",
        typeOfAlert: "error",
      });
    }
  }

  async function copyForm(args: { formId: string }) {
    try {
      const form = forms?.find((f) => f.id === args.formId);

      if (!form) {
        window.ipisModal.alert({
          title: "Formulär saknas",
          prompt: "Kunde inte hitta formuläret som skulle kopieras",
          typeOfAlert: "error",
        });
        return;
      }

      const confirm = await window.ipisModal.confirm({
        title:
          "Denna funktion kopierar den senaste sparade versionen av formuläret, och skippar villkorsstyrd logik.",
        prompt:
          "Om du har osparade ändringar som du vill ska komma med i kopieringen, spara formuläret först. Villkorsstyrd logik kopieras inte i nuläget. Vill du fortsätta?",
        confirmLabel: "Ja, kopiera",
        rejectLabel: "Nej, avbryt så att jag kan spara först",
      });

      if (!confirm) {
        return;
      }

      const copier = new IpisFormCopier({
        currentForms: forms,
        target: form,
      });

      const newForm = copier.copy();

      await formRes.mutate({
        callback: () => {
          return ipisFormRepo.saveForm({ form: newForm });
        },
      });
    } catch (er) {
      window.ipisModal.alert({
        title: "Något gick fel",
        prompt: "Kunde inte kopiera formuläret",
        typeOfAlert: "error",
      });
    }
  }

  function selectForm(form: IpisForm.ShellType) {
    if (currentForm?.id === form.id) {
      return;
    }

    if (selectedIsDirtyRef.current) {
      /* 
        Need to be able to restore changes to the active form here
      */
      window.modal.alert({
        title: "Osparade ändringar",
        prompt:
          "Det finns osparade ändringar i det aktiva formuläret. Spara eller ångra ändringarna innan du byter formulär.",
        typeOfAlert: "error",
      });
      return;
    }

    setCurrentFormId(form.id);
    selectedIsDirtyRef.current = false;
  }

  function mutate(cb: () => Promise<any>): Promise<void> {
    return formRes.mutate({
      callback: cb,
    });
  }

  const [showNewChecklistForm, setShowNewChecklistForm] = useState(false);
  const isError = formRes.query.isError;

  return (
    <FormBuilderContext.Provider
      value={{
        forms: formRes.query.data ?? [],
        staticSelectedForm: currentForm ?? null,
        setSelectedForm: selectForm,
        createNewForm,
        deleteForm,
        copyForm,
        mutate,
        setSelectedFormIsDirty,
        isLoading: formRes.isLoading,
      }}
    >
      <ConditionalFormEditorContext form={currentForm}>
        <IpisLoadingOverlay
          isVisible={formRes.query.isLoading || formRes.mutation.isLoading}
        />
        <section className="grid h-full w-full grid-cols-[275px,1fr] grid-rows-[minmax(0,1fr)] overflow-hidden">
          {isError && (
            <div className="absolute left-0 top-0 z-50 h-full w-full bg-white/80 backdrop-blur">
              <SomethingWentWrong
                error={formRes.query.error}
                description="Kunde inte hämta formulären. Försök igen senare."
              />
            </div>
          )}

          <FormSelectionSidebar
            isLoading={formRes.query.isLoading}
            setShowNewChecklistForm={setShowNewChecklistForm}
          />
          {currentForm && <FormEditor />}
        </section>
        <MyDialog
          isOpen={showNewChecklistForm}
          cancel={() => setShowNewChecklistForm(false)}
        >
          <IpisFormCreator closeForm={() => setShowNewChecklistForm(false)} />
        </MyDialog>
      </ConditionalFormEditorContext>
    </FormBuilderContext.Provider>
  );
};

const ConditionalFormEditorContext = (props: {
  children?: React.ReactNode;
  form: IpisForm.ShellType | undefined;
}) => {
  if (!props.form) {
    return <>{props.children}</>;
  }

  return (
    <FormEditorContextProvider staticForm={props.form}>
      {props.children}
    </FormEditorContextProvider>
  );
};

export default FormBuilder;
