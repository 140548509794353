"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdTransformedCollectResponse = void 0;
var zod_1 = require("zod");
var BankIdCollectResponse_1 = require("./BankIdCollectResponse");
/*
  Considering putting the transformations in another file, to separate the schemas that actually
  gets returned by the BankID api from the ones that are used internally.
*/
var BankIdTransformedCollectResponse;
(function (BankIdTransformedCollectResponse) {
    BankIdTransformedCollectResponse.Pending = BankIdCollectResponse_1.BankIdCollectResponse.Pending.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: true, hasErrorCode: false, errorCode: undefined, isUnknown: false, handled: false, isFinal: false });
    });
    BankIdTransformedCollectResponse.UnknownPending = BankIdCollectResponse_1.BankIdCollectResponse.UnknownPending.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: true, hasErrorCode: false, errorCode: undefined, isUnknown: true, handled: false, isFinal: false });
    });
    BankIdTransformedCollectResponse.Failed = BankIdCollectResponse_1.BankIdCollectResponse.Failed.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: true, hasErrorCode: false, errorCode: undefined, isUnknown: false, handled: false, isFinal: true });
    });
    BankIdTransformedCollectResponse.UnknownFailed = BankIdCollectResponse_1.BankIdCollectResponse.UnknownFailed.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: true, hasErrorCode: false, errorCode: undefined, isUnknown: true, handled: false, isFinal: true });
    });
    BankIdTransformedCollectResponse.Error = BankIdCollectResponse_1.BankIdCollectResponse.Error.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: false, hasErrorCode: true, status: undefined, isUnknown: false, handled: false, isFinal: true });
    });
    BankIdTransformedCollectResponse.UnknownError = BankIdCollectResponse_1.BankIdCollectResponse.UnknownError.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: false, hasErrorCode: true, status: undefined, isUnknown: true, handled: false, isFinal: true });
    });
    BankIdTransformedCollectResponse.Complete = BankIdCollectResponse_1.BankIdCollectResponse.Complete.transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: true, hasErrorCode: false, errorCode: undefined, isUnknown: false, isFinal: true });
    });
    BankIdTransformedCollectResponse.ClientSafeComplete = BankIdCollectResponse_1.BankIdCollectResponse.Complete.transform(function (obj) {
        return {
            status: obj.status,
            hasStatus: true,
            hasErrorCode: false,
            errorCode: undefined,
            isUnknown: false,
            isFinal: true,
        };
    });
    BankIdTransformedCollectResponse.CompletionHandledStatus = zod_1.z.union([
        zod_1.z.object({
            handled: zod_1.z.literal(true),
        }),
        zod_1.z.object({
            handled: zod_1.z.literal(false),
            reason: zod_1.z.enum([
                "FAILED_TO_SAVE",
                "RESOURCE_OUTDATED",
                "NOTHING_TO_SIGN",
                "UNKNOWN",
            ]),
        }),
    ]);
    BankIdTransformedCollectResponse.CompleteResponseWithHandledStatusSchema = BankIdCollectResponse_1.BankIdCollectResponse.Complete.extend({
        handledStatus: BankIdTransformedCollectResponse.CompletionHandledStatus,
    }).transform(function (obj) {
        return __assign(__assign({}, obj), { hasStatus: true, hasErrorCode: false, errorCode: undefined, isUnknown: false, isFinal: true });
    });
    BankIdTransformedCollectResponse.ClientSafeCompleteResponseWithHandledStatusSchema = BankIdCollectResponse_1.BankIdCollectResponse.Complete.extend({
        handledStatus: BankIdTransformedCollectResponse.CompletionHandledStatus,
    }).transform(function (obj) {
        return {
            status: obj.status,
            handledStatus: obj.handledStatus,
            hasStatus: true,
            hasErrorCode: false,
            errorCode: undefined,
            isUnknown: false,
            isFinal: true,
        };
    });
    BankIdTransformedCollectResponse.Schema = zod_1.z.union([
        BankIdTransformedCollectResponse.Pending,
        BankIdTransformedCollectResponse.UnknownPending,
        BankIdTransformedCollectResponse.Failed,
        BankIdTransformedCollectResponse.UnknownFailed,
        BankIdTransformedCollectResponse.Error,
        BankIdTransformedCollectResponse.UnknownError,
        BankIdTransformedCollectResponse.Complete,
    ]);
    BankIdTransformedCollectResponse.ClientSafeSchema = zod_1.z.union([
        BankIdTransformedCollectResponse.Pending,
        BankIdTransformedCollectResponse.UnknownPending,
        BankIdTransformedCollectResponse.Failed,
        BankIdTransformedCollectResponse.UnknownFailed,
        BankIdTransformedCollectResponse.Error,
        BankIdTransformedCollectResponse.UnknownError,
        BankIdTransformedCollectResponse.ClientSafeComplete,
    ]);
    BankIdTransformedCollectResponse.NonPendingSchema = zod_1.z.union([
        BankIdTransformedCollectResponse.Failed,
        BankIdTransformedCollectResponse.UnknownFailed,
        BankIdTransformedCollectResponse.Error,
        BankIdTransformedCollectResponse.UnknownError,
        BankIdTransformedCollectResponse.Complete,
    ]);
    BankIdTransformedCollectResponse.SchemaWithHandledStatus = zod_1.z.union([
        BankIdTransformedCollectResponse.Pending,
        BankIdTransformedCollectResponse.UnknownPending,
        BankIdTransformedCollectResponse.Failed,
        BankIdTransformedCollectResponse.UnknownFailed,
        BankIdTransformedCollectResponse.Error,
        BankIdTransformedCollectResponse.UnknownError,
        BankIdTransformedCollectResponse.CompleteResponseWithHandledStatusSchema,
    ]);
    BankIdTransformedCollectResponse.ClientSafeSchemaWithHandledStatus = zod_1.z.union([
        BankIdTransformedCollectResponse.Pending,
        BankIdTransformedCollectResponse.UnknownPending,
        BankIdTransformedCollectResponse.Failed,
        BankIdTransformedCollectResponse.UnknownFailed,
        BankIdTransformedCollectResponse.Error,
        BankIdTransformedCollectResponse.UnknownError,
        BankIdTransformedCollectResponse.ClientSafeCompleteResponseWithHandledStatusSchema,
    ]);
})(BankIdTransformedCollectResponse || (BankIdTransformedCollectResponse = {}));
exports.BankIdTransformedCollectResponse = BankIdTransformedCollectResponse;
