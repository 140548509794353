/* import defaultTheme from "tailwindcss/defaultTheme";
import plugin from "tailwindcss/plugin"; */


  const defaultTheme = require("tailwindcss/defaultTheme");
  const plugin = require("tailwindcss/plugin");

const config = {
  mode: "jit",
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  plugins: [
    plugin(function ({ addUtilities, theme }) {
      const widthClasses = theme("width");
      const screenSizes = theme("screens");
      let newUtilities = {};

      Object.keys(widthClasses)
        .map((el) => el.replace("/", "\\/"))
        .forEach((key) => {
          newUtilities[`.children-w-${key} > *`] = {
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: widthClasses[key],
          };
        });

      Object.keys(screenSizes)
        .map((el) => el.replace("/", "\\/"))
        .forEach((screen) => {
          newUtilities[`.children-w-screen-${screen} > *`] = {
            width: "100%",
            marginLeft: "auto",
            marginRight: "auto",
            maxWidth: screenSizes[screen],
          };
        });

      addUtilities(newUtilities, ["responsive"]);
    }),
  ],
  theme: {
    extend: {
      gridTemplateRows: {
        hmf: "auto minmax(0, 1fr) auto",
      },
      colors: {
        /* 
          New color scheme:
        */
        cta: "#e0ff01",
        "light-background": "#fefff9",
        "semi-faded": "#6d6d6d", // dark-500
        error: "#e53a2b",
        success: "#A8EA29",
        focus: "#015FCC",
        accent: "#3d3d3d",
        //"accent": "#E0FF01",
        "input-background": "#f8f8f2", // secondary-50
        "border-color": "#d1d1d1", // dark-200
        "border-radius": "4px",
        /* button: {
          primary: "#e1ff00",
          secondary: "#eceddd",
        }, */
        dark: {
          200: "#d1d1d1",
          300: "#b0b0b0",
          400: "#888888",
          500: "#6d6d6d",
          600: "#5d5d5d",
          700: "#4f4f4f",
          800: "#454545",
          900: "#3d3d3d",
          950: "#1c1c1c",
        },
        primary: {
          100: "#FDFFC4",
          300: "#f0ff50",
          400: "#e0ff01",
          500: "#c4e600",
        },
        secondary: {
          50: "#f8f8f2",
          100: "#eceddd",
          200: "#d5d6b2",
          300: "#bfbf85",
          400: "#aca863",
          500: "#9d9655",
        },

        /* 
          Legacy
        */
        "dark-gray": "#333",
        "off-white": "#f9f9f9",
        "faded-gray": "#4b5563",
        "form-border": "#00000060",
        "form-border-disabled": "#00000040",
        /* Figure out better names for these colors... */
        "main-bg-light": "#314a4e",
        "main-bg-dark": "#103236",
        "bg-base-layer": "#f7f7f7",
        "tab-border-selected": "#324b4f",
        "tab-border-not-selected": "#dbdbdb",
        "button-color": "#324b4f",
        "brand-light-blue": "#72CBD6",
        "brand-blue": {
          50: "#f5f7f8",
          100: "#ddeaea",
          200: "#bad4d5",
          300: "#8fb7b9",
          400: "#689499",
          500: "#4e797e",
          600: "#3d5f64",
          700: "#314a4e",
          800: "#2c4043",
          900: "#283639",
          950: "#131d20",
        },
      },
      borderRadius: {
        none: "0",
        sm: "0.125rem",
        DEFAULT: "4px",
        md: "0.375rem",
        lg: "0.5rem",
        full: "9999px",
        large: "12px",
      },
      fontSize: {
        "2xs": "0.625rem",
        xs: "0.75rem",
        sm: "0.875rem",
        base: "1rem",
        lg: "1.125rem",
        xl: "1.25rem",
        "2xl": "1.5rem",
        "3xl": "2rem",
        "4xl": "2.625rem",
        "5xl": "3.375rem",
      },
      screens: {
        "2xs": "350px",
        xs: "475px",
        "3xl": "1920px",
        "4xl": "2560px",
        ...defaultTheme.screens,
      },
    },
  },
};

module.exports = config;

/* export default config; */