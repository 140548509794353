import {
  createTRPCProxyClient,
  httpBatchLink,
  HTTPHeaders,
} from "@trpc/client";
import type { TDomainAppRouter } from "../../server/dist/routes/domain/DomainAppRouter";
import { API } from "./_model/api/API";
import SuperJSON from "superjson";

const trpcClient = createTRPCProxyClient<TDomainAppRouter>({
  transformer: SuperJSON,
  links: [
    httpBatchLink({
      url: process.env.REACT_APP_PROXY_URL + "/trpc",
      headers: () => {
        const headers: HTTPHeaders | undefined = API.getOptionalAuthHeader();

        function getCookie(cookieName: string) {
          const cookieArray = document.cookie.split(";");

          for (let i = 0; i < cookieArray.length; i++) {
            let cookie = cookieArray[i].trim();

            if (cookie.indexOf(cookieName + "=") === 0) {
              return cookie.substring((cookieName + "=").length);
            }
          }

          return null;
        }

        const csrfToken = getCookie("csrf-token") ?? "";
        const withCsrf = {
          ...(headers ?? {}),
          "x-csrf-token": csrfToken,
        };

        return withCsrf;
      },
      fetch(url, options) {
        return fetch(url, {
          ...options,
          credentials: "include",
        });
      },
    }),
  ],
});

export default trpcClient;
