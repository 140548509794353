"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBind = useBind;
exports.useBindWithPrevious = useBindWithPrevious;
var react_1 = require("react");
function useBind(obs, onChange) {
    var _a = (0, react_1.useState)(obs.value), state = _a[0], setState = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = obs.subscribeWithInitial(function (newValue) {
            if (onChange) {
                onChange(newValue, state);
            }
            setState(newValue);
            return true;
        });
        return unsubscribe;
    }, [obs, onChange, setState]);
    return state;
}
function useBindWithPrevious(obs) {
    var _a = (0, react_1.useState)({ current: obs.value }), state = _a[0], setState = _a[1];
    (0, react_1.useEffect)(function () {
        var unsubscribe = obs.subscribeWithInitial(function (newValue, oldValue) {
            setState({
                current: newValue,
                previous: oldValue,
            });
            return true;
        });
        return unsubscribe;
    });
    return state;
}
