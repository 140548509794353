"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExternalBookingAgent = void 0;
var zod_1 = require("zod");
var ExternalBookingAgent;
(function (ExternalBookingAgent) {
    ExternalBookingAgent.Schema = zod_1.z.object({
        id: zod_1.z.string(),
        role: zod_1.z.literal("external-booking-agent"),
        firstName: zod_1.z.string(),
        lastName: zod_1.z.string(),
    });
})(ExternalBookingAgent || (ExternalBookingAgent = {}));
exports.ExternalBookingAgent = ExternalBookingAgent;
