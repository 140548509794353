import ClientUtils from "./../../utils/ClientUtils";
interface Props {
  className?: string;
  size?: number;
}

const MidDot = (props: Props) => {
  return (
    <span aria-hidden className={ClientUtils.twClassNames(props.className)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.size ?? 16}
        height={props.size ?? 16}
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
      </svg>
    </span>
  );
};

export default MidDot;
