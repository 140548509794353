interface Props {
  housingType:
    | "house"
    | "condomonium"
    | "rentalApartment"
    | "insuranceMatter"
    | undefined;
}

const RotAvdragImportantInformationSection = (props: Props) => {
  return (
    <section className="sm:p-2">
      <header className="font-semibold">
        <h4 className="text-base">Viktig information angående rotavdraget</h4>
      </header>
      <main className="flex flex-col gap-4 py-4">
        <ul className="flex list-disc flex-col gap-2 pl-6 text-sm sm:text-base">
          <li>Du ska äga fastigheten när arbetet utförs</li>
          <li>Du ska ha rotavdrag kvar att utnyttja</li>
          {props.housingType === "house" && (
            <li>Viktigt att det är rätt ifylld fastighetsbeteckning</li>
          )}
          {props.housingType === "condomonium" && (
            <li>
              Viktigt att det är rätt ifyllt organisations- och lägenhetsnummer
            </li>
          )}
        </ul>
        <p className="text-sm">
          Om skatteverket skulle neka rotavdraget har vi rätten att fakturera
          den delen till dig som kund. Så dubbelkolla att ni har fyllt i rätt
          uppgifter. Om vi behöver ändra uppgifter, skapa en ny faktura eller
          skicka nya uppgifter till skatteverket tar vi ut en administrativ
          avgift på 250:- inkl. moms.
        </p>
      </main>
    </section>
  );
};

export default RotAvdragImportantInformationSection;
