"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var tab_context_1 = require("../../contexts/tab-context");
var react_router_dom_1 = require("react-router-dom");
var TabContextProvider = function (props) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_router_dom_1.useSearchParams)(), params = _a[0], setSearchParams = _a[1];
    // Handle tab click events
    function onTabClick(tab) {
        // Return early if controls are disabled
        if (props.withoutControls) {
            return;
        }
        // If searchParams is defined, update search parameters
        if ("searchParams" in props) {
            setSearchParams(__assign(__assign(__assign({}, Object.fromEntries(params.entries())), props.searchParams(tab)), { internalNavigation: "true" }));
        }
        else if ("href" in props) {
            // If href is defined, navigate to the new URL
            var href = props.href(tab);
            // Pass the previous location in the state to allow the hook to determine if the navigation is internal or external
            navigate(href, { state: { previousLocation: window.location.href } });
        }
        else {
            // Otherwise, call the onSelect callback
            props.onSelect(tab);
        }
    }
    return ((0, jsx_runtime_1.jsx)(tab_context_1.TabContext.Provider, { value: __assign(__assign({}, props), { onTabClick: function (str) { return onTabClick(str); } }), children: props.children }));
};
exports.default = TabContextProvider;
