import { startOfDay } from "date-fns";
import CalendarEvent from "./CalendarEvent";
import { TCalendarEvent } from "./types";

export default class CalendarEvents {
  private readonly record: Record<string, CalendarEvent[]> = {};

  constructor(events: TCalendarEvent[] | undefined) {
    if (events) {
      events.forEach((event) => {
        const key = startOfDay(event.start).getTime();
        if (!(key in this.record)) {
          this.record[key] = [];
        }

        const current = this.record[key];

        let index = current.findIndex((el) => el.props.start > event.start);
        if (index === -1) {
          index = current.length;
        }
        current.splice(index, 0, new CalendarEvent(event));
      });
    }
  }

  for(date: Date) {
    const start = startOfDay(date);
    return this.record[start.getTime()] || [];
  }
}
