import React, { useContext } from "react";
import HandymanWorkOrderCheckInOutContext from "./HandymanWorkOrderCheckInOutContext";
import { formatDuration, intervalToDuration } from "date-fns";
import { sv } from "date-fns/locale";
import { SignedInContext } from "../../../../components/auth/hocs/withWorkerCredentials";
import HandymanWorkOrderRouteContext from "./HandymanWorkOrderRouteContext";
import { useRepos } from "../../../../hooks/use-repos";
import { Utils } from "@eljouren/utils";

interface Props {
  children: React.ReactNode;
}

const HandymanWorkOrderCheckInOutContextProvider = (props: Props) => {
  const { handyman } = useContext(SignedInContext);
  const { order, orderRes } = useContext(HandymanWorkOrderRouteContext);
  const { workOrderRepo } = useRepos();

  const latest = order.workPeriods.at(-1);

  async function onCheckInOut() {
    const latest = order.workPeriods.at(-1);
    const type = !latest || !!latest.checkOut ? "in" : "out";
    let errorHandled = false;
    try {
      await orderRes.mutate({
        callback: async () => {
          if (!("geolocation" in navigator)) {
            window.modal.alert({
              title: "Position ej påslaget",
              prompt: "Du behöver ha position påslaget för påbörja ärendet",
              typeOfAlert: "error",
              error: false,
            });
            errorHandled = true;
            throw new Error("Geolocation not available");
          }

          const position = await new Promise<GeolocationPosition>(
            (resolve, reject) => {
              navigator.geolocation.getCurrentPosition(resolve, (er) => {
                if (process.env.NODE_ENV === "development") {
                  resolve({
                    coords: {
                      accuracy: 0,
                      altitude: null,
                      altitudeAccuracy: null,
                      heading: null,
                      latitude: 0,
                      longitude: 0,
                      speed: null,
                    },
                    timestamp: 0,
                  });
                } else {
                  onGeolocationError(er);
                  errorHandled = true;
                  reject(er);
                }
              });
            }
          );

          await workOrderRepo.checkInOut(
            {
              workerId: handyman.id,
              orderId: order.orderId,
              position,
            },
            type
          );
        },
      });
    } catch (ex) {
      if (!errorHandled) {
        window.modal.alert({
          title: "Det gick inte att checka in/ut",
          prompt:
            "Detta kan bero på att din data har hamnat i osynk med servern. Pröva gärna att ladda om sidan och försök igen.",
          typeOfAlert: "error",
          error: ex,
        });
      }
    }
  }

  function onGeolocationError(error: GeolocationPositionError) {
    switch (error.code) {
      case GeolocationPositionError.PERMISSION_DENIED:
        window.modal.alert({
          title: "Position ej påslaget",
          prompt: "Du behöver ha position påslaget för påbörja ärendet",
          typeOfAlert: "error",
          error: false,
        });
        break;
      case GeolocationPositionError.POSITION_UNAVAILABLE:
      case GeolocationPositionError.TIMEOUT:
        window.modal.alert({
          title: "Det gick inte att hämta platsinformation",
          prompt: "Vänligen försök igen senare.",
          typeOfAlert: "error",
          error: false,
        });
        break;
    }
  }

  /* 
	  Calculate total duration of all work periods in unit of milliseconds
	*/
  const totalDurationInMs = order.workPeriods.reduce((acc, curr) => {
    if (!curr.checkOut) return acc;
    return acc + Number(curr.checkOut.date) - Number(curr.checkIn.date);
  }, 0);

  /* 
	  Format total duration to readable format
	*/
  const duration = intervalToDuration({ start: 0, end: totalDurationInMs });
  const formatedTotalDuration = Utils.capitalize(
    formatDuration(duration, { locale: sv })
  );

  const lastDoesntHaveCheckout = !!latest && !latest.checkOut;
  const canCheckInOut =
    (!order.isFinished || lastDoesntHaveCheckout) && order.allowedToHandleOrder;

  const isCheckingIn = !latest || !!latest.checkOut;
  const isCheckingInAnew = isCheckingIn && !!latest;
  const isCheckingOut = !isCheckingIn;

  return (
    <HandymanWorkOrderCheckInOutContext.Provider
      value={{
        totalDurationInMs,
        formatedTotalDuration,
        onCheckInOut,
        canCheckInOut,
        isCheckingIn,
        isCheckingInAnew,
        isCheckingOut,
      }}
    >
      {props.children}
    </HandymanWorkOrderCheckInOutContext.Provider>
  );
};

export default HandymanWorkOrderCheckInOutContextProvider;
