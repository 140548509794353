import { IpisFormElement } from "@eljouren/domain";
import ChecklistDecoupledYesNoQuestion from "../../../checklist-question-types/yes-no/ChecklistDecoupledYesNoQuestion";
import WizardForm from "../../../wizard-form/WizardForm";
import { useWizardForm } from "../../../wizard-form/use-wizard-form";
import InputBuilderLayout from "../InputBuilderLayout";
import {
  WizardDescriptionField,
  WizardFormInference,
  WizardHelperTextField,
  WizardNoteField,
  WizardRequiredField,
  WizardTitleField,
} from "../common-wizard-fields";

const page1 = WizardFormInference.inferQuestionArray([
  WizardTitleField,
  WizardDescriptionField,
  WizardHelperTextField,
  WizardNoteField,
  {
    walkthroughHeading: "Text input type",
    walkthroughSubheading: "Text input type",
    id: "renderAs",
    clientSideId: null,
    allowOther: false,
    typeOfQuestion: "multiple-choice",
    renderAs: "always-visible",
    multiple: false,
    required: true,
    type: "question",
    title: "Vilken typ av knappar vill du använda?",
    options: [
      {
        id: "single",
        clientSideId: null,
        value: "Två knappar",
      },
      {
        id: "multi",
        clientSideId: null,
        value: "En toggle-knapp",
      },
    ],
  },
  WizardRequiredField,
]);

const configs = [page1];

type FormValues = WizardFormInference.FormValues<typeof configs>;

type SubmitValues = Omit<IpisFormElement.YesNoType, "id">;
interface Props {
  className?: string;
  defaultValues?: SubmitValues;
  onSubmit: (data: SubmitValues) => void;
  closeForm: () => void;
}

const ChecklistYesNoInputBuilder = (props: Props) => {
  function submitValuesToFormValues(
    values: SubmitValues | undefined
  ): Partial<FormValues> {
    if (!values) {
      return {};
    }
    const partial: Partial<FormValues> = {
      title: { value: values.title },
      richTextBody: {
        value: values.description ?? "",
      },
      helperText: {
        value: values.tooltip ?? "",
      },
      note: { value: values.note ?? "" },
      renderAs: {
        value: values.renderAs === "toggle" ? "En toggle-knapp" : "Två knappar",
      },
    };

    return partial;
  }

  const wizardHook = useWizardForm({
    formId: "checklist-text-input-builder",
    configs,
    onSubmit: onSubmit,
    defaultValues: {
      renderAs: { value: "Två knappar" },
      ...submitValuesToFormValues(props.defaultValues),
    },
  });

  const question = buildQuestion(wizardHook.form.watch());

  function buildQuestion(values: FormValues): IpisFormElement.YesNoType {
    return {
      id: "generator",
      clientSideId: null,
      typeOfQuestion: "yes/no",
      renderAs:
        values["renderAs"]?.value === "En toggle-knapp" ? "toggle" : "buttons",
      type: "question",
      required: values["required"]?.value ?? false,
      title: values["title"]?.value ?? "",
      description: values["richTextBody"]?.value,
      tooltip: values["helperText"]?.value,
      note: values["note"]?.value,
    };
  }

  const atLeastOneQuestionAnswered = Object.values(
    wizardHook.form.getValues()
  ).some((value) => !!value);

  function onSubmit(data: FormValues) {
    props.onSubmit(buildQuestion(data));
  }

  return (
    <InputBuilderLayout
      closeForm={props.closeForm}
      heading="Skapa en ja/nej fråga"
      description="Skapa en fråga där användaren kan svara ja eller nej"
      atLeastOneQuestionAnswered={atLeastOneQuestionAnswered}
      Form={<WizardForm hookReturn={wizardHook} />}
      PreviewComponent={
        <ChecklistDecoupledYesNoQuestion
          form={wizardHook.form}
          question={question}
        />
      }
    />
  );
};

export default ChecklistYesNoInputBuilder;
