import ClientUtils from "./../../utils/ClientUtils";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import withCustomerGuid from "../../components/auth/hocs/withCustomerGuid";
import AnimatedCheckmark from "../../components/common/AnimatedCheckMark";
import { AppButton } from "../../components/common/buttons/AppButton";
import LoadingContent from "../../components/common/loaders/LoadingContent";
import AppPage from "../../components/layout/AppPage";
import SomethingWentWrong from "../../components/on-error/SomethingWentWrong";
import CustomerWorkOrderFileContextProvider from "./CustomerWorkOrderFileContextProvider";
import useMutableQuery from "../../hooks/use-mutatable-query";
import { useRepos } from "../../hooks/use-repos";
import CustomerWorkOrderContext, {
  CustomerWorkOrderTabs,
} from "./CustomerWorkOrderContext";
import CustomerWorkOrderOverviewSection from "./customer-work-order-overview/CustomerWorkOrderOverviewSection";
import CustomerWorkOrderStepBasedForm from "./step-based-form/CustomerWorkOrderStepBasedForm";

import TabPanel from "../../components/common/tabs/TabPanel";
import CustomerWorkOrderFileTab from "./CustomerWorkOrderFileTab";

const CustomerWorkOrderRoute = () => {
  const [tab, setTab] = useState(CustomerWorkOrderTabs.overview);
  const { workOrderRepo } = useRepos();

  const workOrderRes = useMutableQuery({
    queryKey: ["customerOrderInJwt"],
    queryFn: () => {
      return workOrderRepo.getByCustomerGuid();
    },
  });

  if (workOrderRes.query.isError) {
    return (
      <SomethingWentWrong
        error={workOrderRes.query.error}
        description="Det gick inte att hämta denna order, vilket förmodligen betyder att det inte finns."
      />
    );
  }

  if (workOrderRes.query.error) {
    return (
      <SomethingWentWrong
        error={null}
        description="Det gick inte att hämta denna order just nu"
      />
    );
  }
  if (!workOrderRes.query.data && !workOrderRes.query.isLoading) {
    return (
      <SomethingWentWrong
        error={null}
        description="Det gick inte att hämta denna order just nu"
      />
    );
  }

  const confirmed = !!workOrderRes.query.data?.confirmedByCustomer;

  return (
    <LoadingContent
      renderContentSeparately
      className="lg:col-start-1 lg:row-start-3"
      loading={
        workOrderRes.isLoading || workOrderRes.query.isManuallyRefetching
      }
    >
      <ErrorBoundary
        fallbackRender={(props) => <SomethingWentWrong error={props.error} />}
      >
        {workOrderRes.query.data && (
          <CustomerWorkOrderContext.Provider
            value={{
              workOrder: workOrderRes.query.data,
              workOrderRes: workOrderRes,
              tab: tab,
            }}
          >
            <CustomerWorkOrderFileContextProvider>
              <AppPage
                className={ClientUtils.twClassNames(
                  "grid grid-cols-1 overflow-y-auto overflow-x-hidden",
                  !confirmed && "p-2 sm:p-4",

                  confirmed && "gap-8 md:p-16"
                )}
                noLayoutStyling
                headerProps={{
                  heading: "Uppdrag: " + workOrderRes.query.data.serialNumber,
                  noBackButton: true,
                  loading: workOrderRes.query.isFetching,
                }}
                tabProps={
                  confirmed
                    ? {
                        tabs: Object.values(CustomerWorkOrderTabs),
                        selectedTab: tab,
                        onSelect: (tab) => setTab(tab as CustomerWorkOrderTabs),
                      }
                    : undefined
                }
              >
                <MainContent />
              </AppPage>
            </CustomerWorkOrderFileContextProvider>
          </CustomerWorkOrderContext.Provider>
        )}
      </ErrorBoundary>
    </LoadingContent>
  );
};

const MainContent = (props: {}) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const { workOrderRepo } = useRepos();
  function onReset() {
    ctx.workOrderRes.mutate({
      callback: () => workOrderRepo.reset(ctx.workOrderRes.query.data!),
    });
  }

  if (!ctx.workOrder.confirmedByCustomer) {
    return <CustomerWorkOrderStepBasedForm />;
  }

  return (
    <AnimatePresence exitBeforeEnter>
      <TabPanel key={ctx.tab} className="flex flex-col gap-12 p-4 md:p-8">
        {ctx.tab === CustomerWorkOrderTabs.overview && (
          <>
            <section className="mx-auto grid w-full max-w-screen-md grid-cols-1 gap-2 rounded py-4">
              <header className="flex flex-col items-center gap-8 p-4">
                <AnimatedCheckmark />
                <motion.h2
                  className="text-xl"
                  initial={{ y: -10, opacity: 0 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.8 }}
                >
                  Hurra!
                </motion.h2>
              </header>
              <motion.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.3 }}
              >
                <p className="mx-auto max-w-lg p-2 text-center text-sm">
                  Just nu finns det inget mer du behöver göra - vi kontaktar dig
                  om vi behöver kompletterande information!
                </p>
              </motion.main>

              {(process.env.NODE_ENV === "development" ||
                process.env.REACT_APP_CUSTOM_ENVIRONMENT === "development") && (
                <fieldset className="border-2 p-4">
                  <legend>För testningssyfte</legend>
                  <AppButton requiresNetworkConnection onClick={onReset}>
                    Återställ uppdraget till obekräftat
                  </AppButton>
                </fieldset>
              )}
            </section>
            <CustomerWorkOrderOverviewSection
              className="max-w-screen-md"
              labelForFileUploading="Ladda upp"
              workOrder={ctx.workOrder}
              files={"useFileContext"}
              allowFileHandling={!ctx.workOrder.isFinished}
            />
          </>
        )}
        {ctx.tab === CustomerWorkOrderTabs.files && (
          <section className="mx-auto h-full w-full max-w-screen-xl">
            <CustomerWorkOrderFileTab />
          </section>
        )}
      </TabPanel>
    </AnimatePresence>
  );
};

export default withCustomerGuid(CustomerWorkOrderRoute);
