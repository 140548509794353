import MyDialog from "../../../../../common/MyDialog";
import ChecklistImageGroupCreator from "../../input-builders/image-group/ChecklistImageGroupCreator";
import ChecklistInformationElementCreator from "../../input-builders/information/ChecklistInformationElementCreator";
import ChecklistMultipleChoiceInputCreator from "../../input-builders/multiple-choice-input/ChecklistMultipleChoiceInputCreator";
import ChecklistNumericInputCreator from "../../input-builders/numeric-input/ChecklistNumericInputCreator";
import ChecklistTextInputCreator from "../../input-builders/text-input/ChecklistTextInputCreator";
import ChecklistYesNoInputCreator from "../../input-builders/yes-no/ChecklistYesNoInputCreator";
import { ChecklistQuestionFormType } from "./AddQuestionListItem";

interface Props {
  questionType: null | ChecklistQuestionFormType;
  close: () => void;
}

const NewQuestionFormDialog = (props: Props) => {
  return (
    <MyDialog isOpen={!!props.questionType} cancel={() => props.close()}>
      {props.questionType === "text" && (
        <ChecklistTextInputCreator closeForm={() => props.close()} />
      )}
      {props.questionType === "multiple-choice" && (
        <ChecklistMultipleChoiceInputCreator closeForm={() => props.close()} />
      )}
      {props.questionType === "image-group" && (
        <ChecklistImageGroupCreator closeForm={() => props.close()} />
      )}

      {props.questionType === "information" && (
        <ChecklistInformationElementCreator closeForm={() => props.close()} />
      )}
      {props.questionType === "yes/no" && (
        <ChecklistYesNoInputCreator closeForm={() => props.close()} />
      )}
      {props.questionType === "number" && (
        <ChecklistNumericInputCreator closeForm={() => props.close()} />
      )}
    </MyDialog>
  );
};

export default NewQuestionFormDialog;
