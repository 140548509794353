import { IpisForm } from "@eljouren/domain";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import { IpisButton, IpisTextInputCompact } from "@ipis/client-essentials";
import { DefaultValues, useForm } from "react-hook-form";
import ClientUtils from "../../../../../../utils/ClientUtils";

const FormSchema = z.object({
  name: z.string(),
  productPackageId: z.string().optional().nullable(),
});

type FormValues = z.infer<typeof FormSchema>;

export type IpisFormBuilderSubmitValues = Pick<
  IpisForm.ShellType,
  "name" | "connection"
>;

interface Props {
  className?: string;
  onSubmit(values: IpisFormBuilderSubmitValues): void;
  edit?: IpisForm.ShellType;
  availableConnections: IpisForm.FormConnectionType[];
  closeForm: () => void;
}

const IpisFormBuilder = (props: Props) => {
  const form = useForm<FormValues>({
    resolver: zodResolver(FormSchema),
    defaultValues: buildDefaultValues(),
  });

  function buildDefaultValues(): DefaultValues<FormValues> {
    if (!props.edit) {
      return {
        name: "",
        productPackageId: null,
      };
    }

    return {
      name: props.edit.name,
      productPackageId: props.edit.connection?.productPackageId ?? null,
    };
  }

  function onSubmit(values: FormValues) {
    props.onSubmit(values);
    props.closeForm();
  }

  return (
    <section
      className={ClientUtils.classNames(
        "flex h-full w-full max-w-screen-md flex-col gap-12 overflow-auto p-8",
        props.className
      )}
    >
      <h2 className="text-2xl text-dark-950">Skapa nytt formulär</h2>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <IpisTextInputCompact
          id="name"
          name="name"
          label="Vad ska formuläret heta?"
          form={form}
          required
        />
        <IpisButton label="Skapa formulär" type="submit" />
      </form>
    </section>
  );
};

export default IpisFormBuilder;
