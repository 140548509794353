import { useEffect } from "react";
import { useRef } from "react";
import useIsScrollable, {
  UseIsScrollableReturn,
} from "../hooks/use-is-scrollable";
import { FlexibleElementProps, FlexibleElementTag } from "./FlexibleElement";
import ClientUtils from "../utils/ClientUtils";

type ScrollPosition = {
  x: number;
  y: number;
};

type Props<T extends FlexibleElementTag> = FlexibleElementProps<T> & {
  children?: React.ReactNode;
  innerClassName?: string;
  wrapperClassName?: string;
  assumeScrollable?: boolean;
  onScrollPositionChanged?: (scrollable: UseIsScrollableReturn) => void;
  initialScrollPosition?: ScrollPosition | null;
};

const ScrollIndicatorContainer = <T extends FlexibleElementTag>(
  props: Props<T>
) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const {
    y: scrollable,
    isAtTop,
    isAtBottom,
    hasInitialised,
  } = useIsScrollable(ref, props.onScrollPositionChanged);

  useEffect(() => {
    if (props.initialScrollPosition && ref.current) {
      ref.current.scrollLeft = props.initialScrollPosition.x;
      ref.current.scrollTop = props.initialScrollPosition.y;
    }
  }, [props.initialScrollPosition]);

  const assumeScrollable =
    scrollable || (props.assumeScrollable && !hasInitialised);

  /* 
  Should use the flexible element later
  const { as, children, className, ...rest } = props;
	*/

  /* 
		Styling in App.css	

    Since we removed the arrow indicators, we might not need the div wrapper anymore
	*/

  return (
    <div
      className={ClientUtils.twClassNames(
        //"relative h-full overflow-hidden rounded",
        !assumeScrollable && "p-2",
        assumeScrollable &&
          "border border-main-bg-light/30 py-2 shadow-black/40 ",
        assumeScrollable && !isAtTop && "border-t-0",
        assumeScrollable && !isAtBottom && "border-b-0",
        "scroll-indicator",
        assumeScrollable && "scrollable",
        assumeScrollable && !isAtTop && "scroll-indicator-not-at-top",
        assumeScrollable && !isAtBottom && "scroll-indicator-not-at-bottom",
        props.wrapperClassName
      )}
    >
      <div
        ref={ref}
        className={ClientUtils.twClassNames(
          props.innerClassName,
          "h-full overflow-auto",
          assumeScrollable && "p-2"
        )}
      >
        {props.children}
      </div>
    </div>
  );
};

export default ScrollIndicatorContainer;
