import React from "react";

type HandymanWorkOrderCheckInOutContextType = {
  totalDurationInMs: number;
  formatedTotalDuration: string;
  onCheckInOut: () => void;
  canCheckInOut: boolean;
  isCheckingIn: boolean;
  isCheckingOut: boolean;
  isCheckingInAnew: boolean;
};
const HandymanWorkOrderCheckInOutContext =
  React.createContext<HandymanWorkOrderCheckInOutContextType>({} as never);

export default HandymanWorkOrderCheckInOutContext;
