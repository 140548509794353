import { AnimatePresence, motion } from "framer-motion";
import Logo from "../../../../../components/common/brand/Logo";
import ClientUtils from "../../../../../utils/ClientUtils";
import InvoiceOverview from "../../payment-method-components/InvoiceOverview";
import InvoiceViewCustomerServiceFooter from "../../payment-method-components/InvoiceViewCustomerServiceFooter";

interface Props {
  className?: string;
  proceed: () => void;
}

const InvoiceOverviewFormStep = (props: Props) => {
  return (
    <section
      className={ClientUtils.classNames(
        "flex w-full max-w-xl flex-col gap-8",
        props.className
      )}
    >
      <header className="flex justify-center">
        <AnimatePresence>
          <motion.figure
            className="z-50"
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              delay: 0.5,
            }}
          >
            <Logo withColor onlyShowSetBrand wrapperClassName="max-w-[200px]" />
          </motion.figure>
        </AnimatePresence>
      </header>
      <InvoiceOverview />
      <InvoiceViewCustomerServiceFooter
        mainActionCallback={props.proceed}
        mainActionLabel="Nästa steg"
      />
    </section>
  );
};

export default InvoiceOverviewFormStep;
