import { LiveBookingSuggestions } from "@eljouren/domain";
import ClientUtils from "./../../../utils/ClientUtils";

import { AnimatePresence, Variants, motion } from "framer-motion";
import { useEffect, useState } from "react";
import withSalesCredentials from "../../../components/auth/hocs/withSalesCredentials";
import AppPage from "../../../components/layout/AppPage";
import { useCustomSearchParamState } from "../../../hooks/use-search-params";
import BookingSuggestionsComponent from "./BookingSuggestionsComponent";
import SalesTeamBookingSuggestionsForm from "./SalesTeamBookingSuggestionsForm";

const variants: Variants = {
  closed: { clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)", opacity: 0 },
  open: { clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)", opacity: 1 },
  exit: {
    opacity: 0,
    // Animating to same as open apparently doesn't work
    clipPath: "polygon(0 1%, 100% 1%, 100% 1%, 0 1%)",
    //clipPath: "polygon(0 0, 100% 0, 100% 0, 0 0)",
  },
};

const SalesTeamBookingSuggestionsRoute = () => {
  const [result, setResult] = useState<LiveBookingSuggestions.Type | null>(
    null
  );
  const [showResult, setShowResult] = useCustomSearchParamState(
    "results",
    false,
    {
      fromString: (value) => value === "true",
      toString: (value) => (value ? "true" : "false"),
    }
  );

  useEffect(() => {
    if (showResult && !result) {
      setShowResult(false);
    }
  }, [result, showResult, setShowResult]);

  function onBookingSuggestionsReturned(
    bookingSuggestion: LiveBookingSuggestions.Type
  ) {
    setResult(bookingSuggestion);
    setShowResult(true);
  }

  const renderResults = showResult && result;

  return (
    <AppPage
      headerProps={{
        heading: "Hitta bokningsförslag",
      }}
      className="mx-auto w-full rounded p-8"
    >
      <AnimatePresence exitBeforeEnter>
        {!renderResults && (
          <motion.section
            initial="closed"
            animate="open"
            exit="exit"
            key="form"
            variants={variants}
            className={ClientUtils.twClassNames(
              "mx-auto grid h-full w-full max-w-screen-lg grid-cols-1 grid-rows-1",
              result && "grid-rows-[auto,minmax(0,1fr)]"
            )}
            transition={{
              duration: 0.3,
            }}
          >
            <SalesTeamBookingSuggestionsForm
              onBookingSuggestionsReturned={onBookingSuggestionsReturned}
            />
          </motion.section>
        )}
        {renderResults && (
          <motion.section
            key="result"
            variants={variants}
            initial="closed"
            animate="open"
            exit="exit"
            transition={{
              duration: 0.3,
            }}
            className={ClientUtils.twClassNames(
              "grid h-full w-full grid-cols-1 grid-rows-1"
            )}
          >
            <main>
              <BookingSuggestionsComponent
                suggestions={result}
                newSearch={() => {
                  setResult(null);
                }}
              />
            </main>
          </motion.section>
        )}
      </AnimatePresence>
    </AppPage>
  );
};

export default withSalesCredentials(SalesTeamBookingSuggestionsRoute);
