import React, { useState } from "react";
import { IpisButton } from "@ipis/client-essentials";
import SlideUpModal, { PublicSlideUpModalProps } from "./SlideUpModal";

interface Props extends Omit<PublicSlideUpModalProps, "isOpen" | "onClose"> {
  className?: string;
  heading: string;
  children?: React.ReactNode;
}

const ControlledSlideUpModal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { className, heading, children, ...rest } = props;

  return (
    <>
      <IpisButton
        label={props.heading}
        variant="tonal"
        className={props.className}
        onClick={() => setIsOpen(true)}
        icon={{
          position: "trailing",
          type: "information",
        }}
      />
      <SlideUpModal
        isOpen={isOpen}
        heading={props.heading}
        onClose={() => setIsOpen(false)}
        {...rest}
      >
        {props.children}
      </SlideUpModal>
    </>
  );
};

export default ControlledSlideUpModal;
