import { IpisFileV2 } from "@eljouren/file-schemas/build";
import ClientUtils from "./../../../../utils/ClientUtils";

import IpisFileRelationLabel from "./IpisFileRelationLabel";

interface Props {
  className?: string;
  labelClassName?: string;
  file: IpisFileV2.Type;
}

const IpisFileRelationDiv = (props: Props) => {
  if (!props.file.relations?.length) {
    return <></>;
  }

  if (props.file.relations.length === 1) {
    return (
      <IpisFileRelationLabel
        relation={props.file.relations[0]}
        file={props.file}
        className={props.labelClassName}
      />
    );
  }

  return (
    <div
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2 border border-slate-100 p-2",
        props.className
      )}
    >
      <span className="text-sm font-semibold">Relationer</span>
      <span className="flex flex-col">
        {props.file.relations.map((relation, index) => {
          return (
            <IpisFileRelationLabel
              key={index + props.file.guid}
              relation={relation}
              file={props.file}
              className={props.labelClassName}
            />
          );
        })}
      </span>
    </div>
  );
};

export default IpisFileRelationDiv;
