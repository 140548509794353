import { FaRegHandshake } from "react-icons/fa";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppHandshakeIcon = (props: Props) => {
  const { className, ...rest } = props;

  /* 
    Does not exist in the HeroIcons set, so we temporarily use the React Icons set.
  */
  return <FaRegHandshake {...rest} className={props.className} />;
};
export default withIconSize(AppHandshakeIcon);
