import { IpisFormElement } from "@eljouren/domain";
import ChecklistDecoupledTextAreaQuestion from "../../../checklist-question-types/text-area/ChecklistDecoupledTextAreaQuestion";
import ChecklistDecoupledTextInputQuestion from "../../../checklist-question-types/text-input/ChecklistDecoupledTextInputQuestion";
import WizardForm from "../../../wizard-form/WizardForm";
import { useWizardForm } from "../../../wizard-form/use-wizard-form";
import InputBuilderLayout from "../InputBuilderLayout";
import {
  WizardDescriptionField,
  WizardFormInference,
  WizardHelperTextField,
  WizardMaxLengthField,
  WizardMinLengthField,
  WizardNoteField,
  WizardPlaceHolderField,
  WizardRequiredField,
  WizardTitleField,
} from "../common-wizard-fields";

const page1 = WizardFormInference.inferQuestionArray([
  WizardTitleField,
  WizardDescriptionField,
  WizardPlaceHolderField,
  WizardHelperTextField,
  WizardNoteField,
  {
    walkthroughHeading: "Text input type",
    walkthroughSubheading: "Text input type",
    id: "typeOfQuestion",
    clientSideId: null,
    allowOther: false,
    typeOfQuestion: "multiple-choice",
    renderAs: "always-visible",
    multiple: false,
    required: true,
    type: "question",
    title: "Vilken typ av textinmatning vill du ha?",
    options: [
      {
        id: "single",
        clientSideId: null,
        value: "Enkel",
      },
      {
        id: "multi",
        clientSideId: null,
        value: "Flerradig",
      },
    ],
  },
  WizardRequiredField,
]);

const page2 = WizardFormInference.inferQuestionArray([
  WizardMinLengthField,
  WizardMaxLengthField,
  {
    id: "rows",
    clientSideId: null,
    typeOfQuestion: "number",
    type: "question",
    required: true,
    title: "Antal rader",
    walkthroughHeading: "Sätt antal rader",
    walkthroughSubheading: "Antal rader som ska visas i fältet.",
    conditionGroups: [
      {
        id: "multi",
        clientSideId: null,
        relationship: "and",
        conditions: [
          {
            id: "type-condition",
            clientSideId: null,
            reference: "typeOfQuestion",
            referenceType: "question",
            value: "textarea",
            typeOfQuestion: "multiple-choice",
            comparison: "equals",
            action: "show",
          },
        ],
      },
    ],
  },
]);

const configs = [page1, page2];

type FormValues = WizardFormInference.FormValues<typeof configs>;

type SubmitValues =
  | Omit<IpisFormElement.TextInputType, "id">
  | Omit<IpisFormElement.TextAreaType, "id">;
interface Props {
  className?: string;
  defaultValues?: SubmitValues;
  onSubmit: (data: SubmitValues) => void;
  closeForm: () => void;
}

const ChecklistTextInputBuilder = (props: Props) => {
  function submitValuesToFormValues(
    values: SubmitValues | undefined
  ): Partial<FormValues> {
    if (!values) {
      return {};
    }
    const partial: Partial<FormValues> = {
      title: { value: values.title },
      richTextBody: {
        value: values.description ?? "",
      },
      placeholder: { value: values.placeholder },
      helperText: {
        value: values.tooltip ?? "",
      },
      note: { value: values.note ?? "" },
      typeOfQuestion: {
        value: values.typeOfQuestion === "text" ? "Enkel" : "Flerradig",
      },
    };

    if (values.typeOfQuestion === "textarea") {
      partial.rows = { value: values.rows };
    }

    return partial;
  }

  const wizardHook = useWizardForm({
    formId: "checklist-text-input-builder",
    configs,
    onSubmit: onSubmit,
    defaultValues: {
      typeOfQuestion: { value: "Enkel" },
      ...submitValuesToFormValues(props.defaultValues),
    },
  });

  const question = buildQuestion(wizardHook.form.watch());

  function buildQuestion(
    values: FormValues
  ): IpisFormElement.TextInputType | IpisFormElement.TextAreaType {
    return {
      id: "generator",
      clientSideId: null,
      typeOfQuestion:
        values["typeOfQuestion"]?.value === "Flerradig" ? "textarea" : "text",
      type: "question",
      required: values["required"]?.value ?? false,
      title: values["title"]?.value ?? "",
      placeholder: values["placeholder"]?.value,
      description: values["richTextBody"]?.value,
      tooltip: values["helperText"]?.value,
      note: values["note"]?.value,
      rows: values["rows"]?.value ?? 1,
      minLength: values["minLength"]?.value,
      maxLength: values["maxLength"]?.value,
    };
  }

  const atLeastOneQuestionAnswered = Object.values(
    wizardHook.form.getValues()
  ).some((value) => !!value);

  function onSubmit(data: FormValues) {
    const question = buildQuestion(data);
    props.onSubmit(question);
  }

  return (
    <InputBuilderLayout
      closeForm={props.closeForm}
      heading="Skapa fält för textinmatning"
      description="Välj mellan enkelt textfält eller flerradigt textfält."
      Form={<WizardForm hookReturn={wizardHook} />}
      PreviewComponent={
        question.typeOfQuestion === "text" ? (
          <ChecklistDecoupledTextInputQuestion
            disabled
            form={wizardHook.form}
            question={question}
          />
        ) : (
          <ChecklistDecoupledTextAreaQuestion
            question={question}
            disabled
            form={wizardHook.form}
          />
        )
      }
      atLeastOneQuestionAnswered={atLeastOneQuestionAnswered}
    />
  );
};

export default ChecklistTextInputBuilder;
