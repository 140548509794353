"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormCondition = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var FormCondition;
(function (FormCondition) {
    var BaseSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        clientSideId: centralized_zod_1.z.string().uuid().nullable(),
        reference: centralized_zod_1.z.string(),
        referenceType: centralized_zod_1.z.enum(["question", "repeater-item"]),
    });
    var QuestionBaseSchema = BaseSchema.extend({
        referenceType: centralized_zod_1.z.literal("question"),
    });
    var RepeaterItemBaseSchema = BaseSchema.extend({
        referenceType: centralized_zod_1.z.literal("repeater-item"),
    });
    FormCondition.FormConditionComparisonSchema = centralized_zod_1.z.enum(["equals", "not-equals"]);
    FormCondition.YesNoBaseSchema = BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("yes/no"),
        comparison: FormCondition.FormConditionComparisonSchema,
        value: centralized_zod_1.z.boolean(),
    });
    FormCondition.YesNoQuestionSchema = FormCondition.YesNoBaseSchema.merge(QuestionBaseSchema);
    FormCondition.YesNoRepeaterItemSchema = FormCondition.YesNoBaseSchema.merge(RepeaterItemBaseSchema);
    FormCondition.MultipleChoiceBaseSchema = BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("multiple-choice"),
        comparison: FormCondition.FormConditionComparisonSchema,
        value: centralized_zod_1.z.string(),
    });
    FormCondition.MultipleChoiceQuestionSchema = FormCondition.MultipleChoiceBaseSchema.merge(QuestionBaseSchema);
    FormCondition.MultipleChoiceRepeaterItemSchema = FormCondition.MultipleChoiceBaseSchema.merge(RepeaterItemBaseSchema);
    FormCondition.Schema = centralized_zod_1.z.union([
        FormCondition.YesNoQuestionSchema,
        FormCondition.YesNoRepeaterItemSchema,
        FormCondition.MultipleChoiceQuestionSchema,
        FormCondition.MultipleChoiceRepeaterItemSchema,
    ]);
    FormCondition.RepeaterItemSchema = centralized_zod_1.z.union([
        FormCondition.YesNoRepeaterItemSchema,
        FormCondition.MultipleChoiceRepeaterItemSchema,
    ]);
    FormCondition.QuestionSchema = centralized_zod_1.z.union([
        FormCondition.YesNoQuestionSchema,
        FormCondition.MultipleChoiceQuestionSchema,
    ]);
})(FormCondition || (FormCondition = {}));
exports.FormCondition = FormCondition;
