import { IpisFormElement } from "@eljouren/domain";
import {
  useChecklistError,
  useChecklistPage,
  useIpisForm,
} from "../../../../../hooks/checklist-hooks";
import ChecklistDecoupledRepeaterQuestion from "./ChecklistDecoupledRepeaterQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.RepeaterType;
}

const ChecklistRepeaterQuestion = (props: Props) => {
  const ctx = useIpisForm();
  const pageCtx = useChecklistPage();
  const error = useChecklistError(props.question);
  const q = props.question;

  return (
    <ChecklistDecoupledRepeaterQuestion
      checklist={ctx.form}
      validatedChecklist={pageCtx.validatedChecklist}
      question={q}
      form={pageCtx.form}
      error={error}
    />
  );
};

export default ChecklistRepeaterQuestion;
