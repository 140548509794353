"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisSelect = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var domain_1 = require("@eljouren/domain");
var framer_motion_1 = require("framer-motion");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var client_utils_1 = require("../../../../utils/client-utils");
var FormInputShell_1 = require("../components/FormInputShell");
var IpisTextInputCompact_1 = require("../text-input/IpisTextInputCompact");
var OTHER = domain_1.FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION;
var IpisSelect = function (props) {
    var options = __spreadArray([], props.options, true);
    var allowOther = props.allowOther && !!props.otherName;
    if (allowOther) {
        console.log({ allowOther: allowOther });
        options.push({ value: OTHER, label: "Annat" });
    }
    var currentValue = (0, form_hooks_1.useFormValue)(props);
    var registerProps = (0, form_hooks_1.useFormRegisterProps)({
        props: props,
        type: "select",
    });
    var currentOption = options.find(function (o) { return o.value === currentValue; });
    var clarificationName = props.otherName;
    var otherIsSelected = (currentOption === null || currentOption === void 0 ? void 0 : currentOption.value) === OTHER;
    var requireClarification = !!clarificationName && allowOther && otherIsSelected;
    /* console.log({
      clarificationName,
      otherIsSelected,
      allowOther,
      requireClarification,
    }); */
    return ((0, jsx_runtime_1.jsx)(FormInputShell_1.FormInputShell, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { className: "col-span-2 flex w-full flex-col gap-8", children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.select, __assign({ className: (0, client_utils_1.classNames)("grid grid-cols-2 gap-2 rounded p-2", !props.transparent && "bg-input-background", props.transparent && "bg-transparent", props.onDarkBackground && "text-white", !props.onDarkBackground && "text-black") }, registerProps, { children: options.map(function (option, i) {
                        var key = "".concat(props.id, "-").concat(option.value, "-").concat(i);
                        return ((0, jsx_runtime_1.jsx)(OptionComponent, { index: i, option: option, id: props.id, currentValue: currentValue, name: props.name }, key));
                    }) })), requireClarification && ((0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: clarificationName, name: clarificationName, label: "Vänligen specificera", form: props.form, className: "col-span-2" }))] }) })));
};
exports.IpisSelect = IpisSelect;
var OptionComponent = function (props) {
    var id = "".concat(props.id, "-").concat(props.option.value, "-").concat(props.index);
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.option, { className: "text-black focus:outline-4 focus:outline-red-400", value: props.option.value, label: props.option.label }, id));
};
