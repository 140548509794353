import { BookingSuggestions, Handyman } from "@eljouren/domain";
import React from "react";

export type SingleBookingSuggestionContextType = {
  slot: BookingSuggestions.SlotType;
  handyman: Handyman.Type;
  /*   travelInfoFromHome: BookingSuggestions.TravelInformationType | null; */
  /*   workingHours: {
    start: Date;
    end: Date;
  }; */
  showDate?: boolean;
};

const SingleBookingSuggestionContext =
  React.createContext<SingleBookingSuggestionContextType>({} as never);

export default SingleBookingSuggestionContext;
