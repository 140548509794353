"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUrl_UploadOutput = void 0;
var zod_1 = require("zod");
var SignUrl_UploadOutput;
(function (SignUrl_UploadOutput) {
    SignUrl_UploadOutput.Schema = zod_1.z.object({
        url: zod_1.z.string(),
        expires: zod_1.z.union([
            zod_1.z
                .string()
                .transform(function (val) { return new Date(val); })
                .refine(function (val) { return !isNaN(Number(val)); }),
            zod_1.z.date(),
        ]),
    });
})(SignUrl_UploadOutput || (SignUrl_UploadOutput = {}));
exports.SignUrl_UploadOutput = SignUrl_UploadOutput;
