import ClientUtils from "./../../../utils/ClientUtils";
import { motion } from "framer-motion";
import React, { useContext } from "react";

import TabContext from "./TabContext";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const TabPanel = (props: Props) => {
  const ctx = useContext(TabContext);

  return (
    <motion.div
      role="tabpanel"
      className={ClientUtils.twClassNames(
        "flex h-full flex-col overflow-y-auto overflow-x-hidden",
        ctx.addPadding && "p-2",
        ctx.mainClassName,
        props.className
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.15,
      }}
      aria-labelledby={ctx.selectedTab}
    >
      {props.children}
    </motion.div>
  );
};

export default TabPanel;
