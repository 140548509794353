import { useFormBuilder } from "../../../../../../hooks/checklist-hooks";
import IpisFormBuilder, {
  IpisFormBuilderSubmitValues,
} from "./IpisFormBuilder";

interface Props {
  closeForm: () => void;
}

const IpisFormCreator = (props: Props) => {
  const ctx = useFormBuilder();

  function onSubmit(values: IpisFormBuilderSubmitValues) {
    ctx.createNewForm({
      name: values.name,
      pages: [
        {
          pageTitle: "Titel på första sidan",
          pageTitleShorthand: "Kort titel",
        },
      ],
    });
  }

  return (
    <IpisFormBuilder
      onSubmit={onSubmit}
      availableConnections={[]}
      closeForm={props.closeForm}
    />
  );
};

export default IpisFormCreator;
