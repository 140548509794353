import { z } from "@ipis/centralized-zod";
import { MotionConfig } from "framer-motion";
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter } from "react-router-dom";
import "react18-json-view/src/style.css";
import { zodI18nMap } from "zod-i18n-map";
import AppRoutesComponent from "./AppRoutesComponent";
import BankIdRepo from "./_model/repos/BankIdRepo";
import BookingsRepo from "./_model/repos/BookingsRepo";
import { ClientAuthRepo } from "./_model/repos/ClientAuthRepo";
import ClientCustomerWorkOrderRepo from "./_model/repos/ClientCustomerWorkOrderRepo";
import ClientWorkOrderFileRepoV2 from "./_model/repos/ClientWorkOrderFileRepoV2";
import CompanyRepo from "./_model/repos/CompanyRepo";
import ErrorLogRepo from "./_model/repos/ErrorLogRepo";
import HandymanRepo from "./_model/repos/HandymanRepo";
import IpisFormRepo from "./_model/repos/IpisFormRepo";
import WorkOrderPaymentRepo from "./_model/repos/WorkOrderPaymentRepo";
import { WorkOrderRepo } from "./_model/repos/WorkOrderRepo";
import { RepoContext, TRepoContextValue } from "./top-level-contexts";
// Import your language translation files
import translation from "zod-i18n-map/locales/sv/zod.json";
/* translation: {
        ...translation,
        min: "Texten måste innehålla mins {{minimum}} tecken",
        max: "Texten får inte innehålla fler än {{maximum}} tecken",
        length: "Texten måste innehålla exakt {{length}} tecken",
      }, */

i18next.init({
  lng: "sv",
  resources: {
    sv: {
      zod: {
        ...translation,
        errors: {
          ...translation.errors,
          too_small: {
            ...translation.errors["too_small"],
            array: {
              exact: "Listan måste innehålla exakt {{minimum}} element",
              inclusive: "Listan måste innehålla minst {{minimum}} element",
              not_inclusive:
                "Listan måste innehålla fler än {{minimum}} element",
            },

            string: {
              exact: "Texten måste innehålla exakt {{minimum}} tecken",
              inclusive: "Texten måste innehålla minst {{minimum}} tecken",
              not_inclusive:
                "Texten måste innehålla fler än {{minimum}} tecken",
            },
          },
          too_big: {
            ...translation.errors["too_big"],
            array: {
              exact: "Listan måste innehålla exakt {{maximum}} element",
              inclusive: "Listan måste innehålla högst {{maximum}} element",
              not_inclusive:
                "Listan måste innehålla färre än {{maximum}} element",
            },
            string: {
              exact: "Texten måste innehålla exakt {{maximum}} tecken",
              inclusive: "Texten måste innehålla högst {{maximum}} tecken",
              not_inclusive:
                "Texten måste innehålla färre än {{maximum}} tecken",
            },
          },
        },
        types: {
          ...translation.types,
          string: "text",
          array: "lista",
        },
      },
    },
  },
});

z.setErrorMap(zodI18nMap);

/* const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (issue.code === z.ZodIssueCode.too_small) {
    if (issue.type === "string") {
      return { message: `Minst ${issue.minimum} tecken!` };
    }
  }

  if (issue.code === z.ZodIssueCode.too_big) {
    if (issue.type === "string") {
      return { message: `Max ${issue.maximum} tecken!` };
    }
  }

  // New invalid_type condition for translating "Required" message
  if (issue.code === z.ZodIssueCode.invalid_type) {
    if (issue.expected !== "undefined" && issue.received === "undefined") {
      return { message: "Obligatorisk" }; // Translation for "Required"
    }
  }

  return { message: ctx.defaultError };
}; */

let repoContextValue: TRepoContextValue;
const workerRepo = new HandymanRepo();
const authRepo = new ClientAuthRepo(workerRepo);
repoContextValue = {
  authRepo,
  handymanRepo: workerRepo,
  companyRepo: new CompanyRepo(),
  workOrderRepo: new WorkOrderRepo(authRepo),
  errorLogRepo: new ErrorLogRepo(),
  bankIdRepo: new BankIdRepo(),
  bookingsRepo: new BookingsRepo(),
  workOrderPaymentRepo: new WorkOrderPaymentRepo(),
  workOrderFileRepo: new ClientWorkOrderFileRepoV2({
    authRepo,
  }),
  ipisFormRepo: new IpisFormRepo(),
  customerWorkOrderRepo: new ClientCustomerWorkOrderRepo(),
};
repoContextValue.authRepo.signInWithToken();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

//const baseName = process.env.NODE_ENV === "development" ? "/" : "/eljourenapp";

const now = new Date();
const deadline = new Date("2024-07-15");
const beforeDeadline = now < deadline;
if (process.env.NODE_ENV === "development" && beforeDeadline) {
  // Remove strictmode
  root.render(
    <MotionConfig reducedMotion="user">
      <RepoContext.Provider value={repoContextValue}>
        <BrowserRouter basename={"/"}>
          <AppRoutesComponent />
        </BrowserRouter>
      </RepoContext.Provider>
    </MotionConfig>
  );
} else {
  root.render(
    <React.StrictMode>
      <MotionConfig reducedMotion="user">
        <RepoContext.Provider value={repoContextValue}>
          <BrowserRouter basename={"/"}>
            <AppRoutesComponent />
          </BrowserRouter>
        </RepoContext.Provider>
      </MotionConfig>
    </React.StrictMode>
  );
}
