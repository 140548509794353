import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import AppTextButton from "../../../../components/common/buttons/AppTextButton";
import { AppFormSelect } from "../../../../components/common/select/AppFormSelect";
import AppCaretIcon from "../../../../components/icons/AppCaretIcon";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import ClientUtils from "../../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext, {
  PaymentOptionsFormSchema,
  PaymentOptionsFormValues,
} from "../CustomerWorkOrderInvoiceContext";
import InvoicePaymentOptionsFormMain from "../InvoicePaymentOptionsFormMain";
import InvoicePaymentYourDetailsMain from "../form-steps/payment-form-step/InvoicePaymentYourDetailsMain";
interface Props {
  className?: string;
  children?: React.ReactNode;
  goBack?: () => void;
  omitUserDetails?: boolean;
  omitPaymentHeading?: boolean;
  skeletonCount?: number;
  afterSubmitButton?: () => React.ReactNode;
}

const PaymentMethodForm = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);
  const defaultValuesSetRef = useRef(false);
  const form = useForm<PaymentOptionsFormValues>({
    resolver: zodResolver(PaymentOptionsFormSchema),
    defaultValues: {
      invoiceEmail:
        ctx.workOrder?.customerDetails.assumedInvoiceEmail ?? undefined,
      simulatePayment: "success",
    },
  });

  const { setValue } = form;
  useEffect(() => {
    if (ctx.workOrder && !defaultValuesSetRef.current) {
      defaultValuesSetRef.current = true;
      setValue(
        "invoiceEmail",
        ctx.workOrder.customerDetails.assumedInvoiceEmail ?? undefined
      );
    }
  }, [ctx.workOrder, setValue]);

  const invoiceContext = useContext(CustomerWorkOrderInvoiceContext);
  const paymentSummary = invoiceContext.paymentSummary;

  const isSubmitting = ctx.isSubmitting;
  const isSm = useMediaQuery(TailwindBreakpoint.sm);

  return (
    <motion.form
      className={ClientUtils.classNames(
        "relative flex flex-col transition-all",
        isSubmitting && "pointer-events-none",
        //isSubmitting && "skeleton",
        props.className
      )}
      onSubmit={form.handleSubmit(ctx.onSubmit, ctx.onError)}
      animate={{
        opacity: isSubmitting ? 0.3 : 1,
      }}
      transition={{
        duration: 2,
      }}
    >
      {props.children}
      <main
        className={ClientUtils.classNames(
          "flex w-full flex-col gap-8",
          ctx.isSubmitting && "opacity-30"
        )}
      >
        {!props.omitUserDetails && (
          <section className="flex flex-col gap-4">
            <header>
              <h3 className="text-lg">Dina uppgifter</h3>
            </header>
            <main>
              <InvoicePaymentYourDetailsMain />
            </main>
          </section>
        )}
        <section className="flex flex-col gap-4">
          {!props.omitPaymentHeading && (
            <header>
              <h3 className="text-lg">Betalsätt</h3>
            </header>
          )}
          <InvoicePaymentOptionsFormMain
            form={form}
            skeletonCount={props.skeletonCount}
          />
        </section>
      </main>
      <footer
        className={ClientUtils.classNames(
          "flex flex-col gap-4 py-4",
          ctx.isSubmitting && "opacity-30"
        )}
      >
        {isSm && (
          <>
            <p className="mx-auto flex flex-col">
              <label
                htmlFor="paymentSum"
                className="mx-auto font-semibold text-dark-gray text-opacity-70"
              >
                Summa att betala
              </label>
              {!paymentSummary && <Skeleton height={25} />}
              {paymentSummary && (
                <input
                  id="paymentSum"
                  type="text"
                  className="mx-auto flex bg-transparent text-center text-2xl font-bold"
                  readOnly
                  value={`${paymentSummary.priceToPay}:-`}
                />
              )}
            </p>
            <AppButton
              contentClassName="py-4"
              type="submit"
              disabled={isSubmitting}
              loading={isSubmitting}
            >
              Genomför köp
            </AppButton>
          </>
        )}
        {props.afterSubmitButton && props.afterSubmitButton()}
        {process.env.NODE_ENV === "development" && (
          <AppFormSelect
            register={form.register}
            name="simulatePayment"
            options={[
              {
                label: "Lyckad",
                value: "success",
              },
              {
                label: "Misslyckad",
                value: "fail",
              },
              {
                label: "Inspektion",
                value: "inspection",
              },
            ]}
          />
        )}
        {props.goBack && (
          <AppTextButton
            onClick={props.goBack}
            className="mr-auto flex items-center gap-2"
          >
            <AppCaretIcon direction="down" size={"md"} className="rotate-90" />
            <span>Föregående steg</span>
          </AppTextButton>
        )}
      </footer>
    </motion.form>
  );
};

export default PaymentMethodForm;
