import ClientUtils from "./../../utils/ClientUtils";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { FieldErrors, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "@ipis/centralized-zod";
import { useBundledState, useLoading } from "../../hooks/hooks";
import { useRepos } from "../../hooks/use-repos";

import { TSignUpIdType } from "../../_model/repos/interfaces/IAuthRepo";
import AnimatedCheckmark from "../common/AnimatedCheckMark";
import { AppButton } from "../common/buttons/AppButton";
import { AppFormTextField } from "../common/text-fields/AppFormTextField";

const FormSchema = z
  .object({
    password: z.string().min(1),
    confirmPassword: z.string().min(1),
  })
  .refine((values) => values.confirmPassword === values.password, {
    message: "no_match",
    path: ["confirmPassword"],
  });

type FormData = z.infer<typeof FormSchema>;

interface Props {
  className?: string;
  type: TSignUpIdType;
}

const SignUpForm = (props: Props) => {
  const { signUpId } = useParams();
  const { register, handleSubmit } = useForm<FormData>({
    mode: "onChange",
    resolver: zodResolver(FormSchema),
  });
  const { isLoading, loadWhilePromise } = useLoading();
  const { authRepo } = useRepos();

  const showDoesntMatch = useBundledState(false);
  const showCheckmark = useBundledState(false);

  const nav = useNavigate();

  async function onSubmit(values: FormData) {
    const { password } = values;
    showDoesntMatch.set(false);
    try {
      const res = await loadWhilePromise(
        authRepo.choosePassword({
          signUpId: signUpId!,
          password,
          type: props.type,
        })
      );

      if (res) {
        showCheckmark.set(true);
      }
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att välja lösenord just nu",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }
  }
  async function onError(errors: FieldErrors<FormData>) {
    if (errors.confirmPassword) {
      if (errors.confirmPassword.type === "custom") {
        showDoesntMatch.set(true);
      }
    }
  }

  return (
    <form
      className={ClientUtils.twClassNames(
        "relative mx-auto mb-auto grid w-80 max-w-[90vw] ",
        props.className
      )}
    >
      {showCheckmark.value && (
        <AnimatedCheckmark
          className="absolute z-50 self-center justify-self-center"
          onAnimationEnd={() => {
            nav("/partner");
          }}
        />
      )}
      <motion.fieldset
        className={ClientUtils.twClassNames(
          "grid grid-cols-2 gap-6 p-2",
          showCheckmark.value && "pointer-events-none"
        )}
        animate={{
          opacity: showCheckmark.value ? 0 : 1,
        }}
        transition={{
          duration: 1,
        }}
      >
        <h3 className="col-span-2 py-4 text-xl">
          {props.type === "set" ? "Välj lösenord" : "Välj nytt lösenord"}
        </h3>

        <AppFormTextField
          register={register}
          name="password"
          className="col-span-2"
          label="Lösenord"
          htmlAttributes={{
            type: "password",
            disabled: isLoading,
          }}
        />
        <AppFormTextField
          register={register}
          label="Bekräfta lösenord"
          name="confirmPassword"
          className={ClientUtils.twClassNames(
            "col-span-2",
            !showDoesntMatch.value && "mb-12"
          )}
          htmlAttributes={{
            type: "password",
            disabled: isLoading,
          }}
        />
        {showDoesntMatch.value && (
          <span className="col-span-2 ml-auto h-6 text-red-600">
            Lösenorden matchar inte!
          </span>
        )}
        <AppButton
          requiresNetworkConnection
          className="col-start-2 self-end p-1 text-sm"
          onClick={handleSubmit(onSubmit, onError)}
          loading={isLoading ? 1 : 0}
        >
          Spara
        </AppButton>
      </motion.fieldset>
    </form>
  );
};

export default SignUpForm;
