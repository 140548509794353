import { IconBaseProps } from "react-icons/lib";
import { MdOutlineAssignment } from "react-icons/md";
import ClientUtils from "../../utils/ClientUtils";

interface Props extends IconBaseProps {
  className?: string;
}

const TaskIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <MdOutlineAssignment
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default TaskIcon;
