import ClientUtils from './../../utils/ClientUtils';
import React from "react";

interface Props {
  className?: string;
  children?: React.ReactNode;
  as: "main" | "section" | "div" | "aside";
  largeScreenDesignWhenMd?: boolean;
}

const PageSection = (props: Props) => {
  const { className, largeScreenDesignWhenMd, ...rest } = props;

  return (
    <Wrapper
      {...rest}
      className={ClientUtils.twClassNames(
        "overflow-auto p-2 2xl:py-12",
        "lg:rounded lg:bg-white lg:p-8 lg:py-10 lg:shadow",
        props.largeScreenDesignWhenMd &&
          "md:rounded md:bg-white md:p-8 md:py-10 md:shadow",
        props.className
      )}
    >
      {props.children}
    </Wrapper>
  );
};

const Wrapper = (props: Props) => {
  switch (props.as) {
    case "main":
      return <main {...props}>{props.children}</main>;
    case "section":
      return <section {...props}>{props.children}</section>;
    case "div":
      return <div {...props}>{props.children}</div>;
    case "aside":
      return <aside {...props}>{props.children}</aside>;
  }
};

export default PageSection;
