import { ListBulletIcon } from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppListIcon = (props: Props) => {
  const { className, ...rest } = props;
  return <ListBulletIcon {...rest} className={className} />;
};
export default withIconSize(AppListIcon);
