"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisTextArea = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var FormInputShell_1 = require("../components/FormInputShell");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var IpisTextArea = function (props) {
    var _a;
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var currentValue = (0, form_hooks_1.useFormValue)(props);
    var regProps = (0, form_hooks_1.useFormRegisterProps)({
        props: props,
        type: "textarea",
    });
    var currentLength = currentValue === null || currentValue === void 0 ? void 0 : currentValue.length;
    var readOnly = (ctx === null || ctx === void 0 ? void 0 : ctx.readOnly) || ((_a = props.inputProps) === null || _a === void 0 ? void 0 : _a.readOnly);
    return ((0, jsx_runtime_1.jsx)(FormInputShell_1.FormInputShell, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)("div", { className: "col-span-2 grid w-full grid-cols-1 grid-rows-[minmax(0,1fr),auto] rounded border border-border-color bg-input-background outline-1 focus-within:outline", children: [(0, jsx_runtime_1.jsx)("textarea", __assign({ id: props.id, "aria-placeholder": props.placeholder, className: "col-span-2 w-full  bg-input-background p-4 focus:outline-0 focus:outline-black" }, regProps, { disabled: !!props.disabled, readOnly: !!(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly), rows: props.rows }, props.inputProps)), !readOnly && ((0, jsx_runtime_1.jsxs)("div", { className: "grid grid-cols-[minmax(0,1fr),auto] grid-rows-1 gap-2 p-4 text-xs text-semi-faded", onClick: function () { var _a; return (_a = document.getElementById(props.id)) === null || _a === void 0 ? void 0 : _a.focus(); }, children: [(0, jsx_runtime_1.jsx)("p", { "aria-hidden": true, className: "", children: props.placeholder }), !!props.maxLength && ((0, jsx_runtime_1.jsxs)("p", { children: [(0, jsx_runtime_1.jsx)("span", { "aria-label": "Antal tecken", children: currentLength !== null && currentLength !== void 0 ? currentLength : 0 }), (0, jsx_runtime_1.jsx)("span", { "aria-hidden": true, children: "/" }), (0, jsx_runtime_1.jsx)("span", { "aria-label": "Max antal tecken", children: props.maxLength })] }))] }))] }) })));
};
exports.IpisTextArea = IpisTextArea;
