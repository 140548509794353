import React from "react";
import AppMobileSidebar from "./AppMobileSidebar";

interface Props {
  className?: string;
}

export const AppSidebar: React.FC<Props> = (props) => {
  return <AppMobileSidebar />;
};

export default AppSidebar;
