import React from "react";
import BackButtonStackContext, {
  BackButtonStackCallback,
  BeforeBackCallback,
} from "./BackButtonStackContext";

interface Props {
  children?: React.ReactNode;
}

type Stack = BackButtonStackCallback[];
type BeforeBackStack = BeforeBackCallback[];

const BackButtonStackContextProvider = (props: Props) => {
  const stack = React.useRef<Stack>([]);
  const beforeBackStack = React.useRef<BeforeBackStack>([]);

  return (
    <BackButtonStackContext.Provider
      value={{
        push: (callback: BackButtonStackCallback) => {
          stack.current.push(callback);

          return () => {
            stack.current = stack.current.filter((el) => el !== callback);
          };
        },
        pop: () => {
          /* 
            Because of the nature of useEffect, the first element will be the one furthest down
            the React Tree.
          */
          const last = stack.current.shift();
          if (!last) {
            throw new Error(
              "Go back stack is empty - check this before popping"
            );
          }
          return last;
        },
        isEmpty: () => {
          return stack.current.length === 0;
        },
        beforeBack: (element: BeforeBackCallback) => {
          beforeBackStack.current.push(element);

          return () => {
            beforeBackStack.current = beforeBackStack.current.filter(
              (el) => el !== element
            );
          };
        },
        popBeforeBack: () => {
          /* 
            Because of the nature of useEffect, the first element will be the one furthest down
            the React Tree.
          */
          const last = beforeBackStack.current.shift();
          if (!last) {
            throw new Error(
              "Go back stack is empty - check this before popping"
            );
          }
          return last;
        },
        beforeBackIsEmpty: () => {
          return beforeBackStack.current.length === 0;
        },
      }}
    >
      {props.children}
    </BackButtonStackContext.Provider>
  );
};

export default BackButtonStackContextProvider;
