import { IpisButton } from "@ipis/client-essentials";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  heading: string;
  description: string;
  Form: JSX.Element;
  PreviewComponent: JSX.Element;
  atLeastOneQuestionAnswered: boolean;
  closeForm: () => void;
}

const InputBuilderLayout = (props: Props) => {
  const { Form, PreviewComponent } = props;

  return (
    <section
      className={
        "mx-auto grid h-full w-full grid-cols-2 grid-rows-[auto,minmax(0,1fr)] divide-x rounded p-8"
      }
    >
      <header className="col-span-2 flex justify-end">
        <IpisButton
          onlyIcon
          variant="text"
          label="Stäng förhandsvisning"
          onClick={props.closeForm}
          icon={{
            position: "trailing",
            type: "close",
          }}
        />
      </header>
      <section className="flex h-full flex-col gap-4 overflow-auto pl-4 pr-24">
        <header>
          <h2 className="text-2xl font-bold">{props.heading}</h2>
          <p className="text-sm text-semi-faded">
            {props.description}
            {}
          </p>
        </header>
        {Form}
      </section>
      <section className="h-full pl-24 pt-32" id="preview">
        <AnimatePresence>
          {props.atLeastOneQuestionAnswered && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="h-full overflow-auto"
            >
              {PreviewComponent}
            </motion.div>
          )}
        </AnimatePresence>
      </section>
    </section>
  );
};

export default InputBuilderLayout;
