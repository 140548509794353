"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentOption = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
/*
    Could potentially be generalised and moved out of the work order folder
*/
var PaymentOption;
(function (PaymentOption) {
    var NativeEnum;
    (function (NativeEnum) {
        /* swish = "swish",
        trustly = "trustly", */
        NativeEnum["invoice"] = "invoice";
        NativeEnum["creditCard"] = "creditCard";
        NativeEnum["partialPayment"] = "partialPayment";
        NativeEnum["directPayment"] = "directPayment";
        NativeEnum["unknown"] = "unknown";
    })(NativeEnum = PaymentOption.NativeEnum || (PaymentOption.NativeEnum = {}));
    var BrandEnum;
    (function (BrandEnum) {
        BrandEnum["swish"] = "swish";
        BrandEnum["trustly"] = "trustly";
        BrandEnum["resursbank"] = "resursbank";
        BrandEnum["ipis"] = "ipis";
        BrandEnum["unknown"] = "unknown";
    })(BrandEnum = PaymentOption.BrandEnum || (PaymentOption.BrandEnum = {}));
    PaymentOption.BaseSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        linkToTerms: centralized_zod_1.z.string().optional(),
        //brand: z.nativeEnum(BrandEnum),
        //type: z.nativeEnum(NativeEnum),
    });
    PaymentOption.SwishSchema = PaymentOption.BaseSchema.extend({
        brand: centralized_zod_1.z.literal(BrandEnum.swish),
        type: centralized_zod_1.z.literal(NativeEnum.directPayment),
    });
    PaymentOption.TrustlySchema = PaymentOption.BaseSchema.extend({
        brand: centralized_zod_1.z.literal(BrandEnum.trustly),
        type: centralized_zod_1.z.literal(NativeEnum.directPayment),
    });
    PaymentOption.InvoiceSchema = PaymentOption.BaseSchema.extend({
        brand: centralized_zod_1.z.nativeEnum(BrandEnum),
        type: centralized_zod_1.z.literal(NativeEnum.invoice),
    });
    PaymentOption.CreditCardSchema = PaymentOption.BaseSchema.extend({
        brand: centralized_zod_1.z.nativeEnum(BrandEnum),
        type: centralized_zod_1.z.literal(NativeEnum.creditCard),
        isDirectPayment: centralized_zod_1.z.literal(true),
    });
    PaymentOption.UnknownSchema = PaymentOption.BaseSchema.extend({
        brand: centralized_zod_1.z.literal(BrandEnum.unknown),
        type: centralized_zod_1.z.literal(NativeEnum.unknown),
    });
    PaymentOption.PartialPaymentSchema = PaymentOption.BaseSchema.extend({
        brand: centralized_zod_1.z.literal(BrandEnum.resursbank),
        type: centralized_zod_1.z.literal(NativeEnum.partialPayment),
        variant: centralized_zod_1.z.enum(["FIXED_PRICE_1500", "FIXED_PRICE_2000", "12_MONTHS"]),
    });
    PaymentOption.Schema = centralized_zod_1.z.union([
        PaymentOption.SwishSchema,
        PaymentOption.TrustlySchema,
        PaymentOption.InvoiceSchema,
        PaymentOption.CreditCardSchema,
        PaymentOption.UnknownSchema,
        PaymentOption.PartialPaymentSchema,
    ]);
})(PaymentOption || (PaymentOption = {}));
exports.PaymentOption = PaymentOption;
