import { IpisFormElement, IpisForm } from "@eljouren/domain";
import { Path, UseFormReturn } from "react-hook-form";
import ClientUtils from "../../../../../utils/ClientUtils";
import InformationTooltip from "../../../../InformationTooltip";
import { IpisFormInputError } from "@ipis/client-essentials";
import { IpisFormInputLabel } from "@ipis/client-essentials";
import { IpisFormInputNote } from "@ipis/client-essentials";
import RichTextPreTag from "../components/RichTextPreTag";
import { IpisFormAnswerValidator } from "@eljouren/domain-utils";
import { UUID } from "@eljouren/utils";
import { motion } from "framer-motion";
import { useState } from "react";
import { IpisButton, IpisTextInput } from "@ipis/client-essentials";
import { IpisRadioGroup } from "@ipis/client-essentials";
import { IpisToggleInput } from "@ipis/client-essentials";
import { ChecklistFormValues } from "../../checklist-page/ChecklistPageContext";

interface Props {
  className?: string;
  question: IpisFormElement.RepeaterType;
  form: UseFormReturn<ChecklistFormValues>;
  error?: string;
  disabled?: boolean;
  bypassConditionCheck?: boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  validatedChecklist: IpisFormAnswerValidator;
  onBlur?: () => void;
  onFocus?: () => void;
  checklist: IpisForm.ShellType;
}

const ChecklistDecoupledRepeaterQuestion = (props: Props) => {
  const [groupIds, setGroupIds] = useState(getInitialGroupIds());

  function getInitialGroupIds() {
    const name = `${props.question.id}.repeaterItems`;
    const current = props.form.getValues(name);
    if (!current) {
      return [UUID.generate().value];
    } else {
      return Object.keys(current);
    }
  }

  function addGroup() {
    setGroupIds([...groupIds, UUID.generate().value]);
  }

  function removeGroup(id: string) {
    const current = props.form.getValues();
    const newValues = { ...current };
    const repeaterItems = newValues[props.question.id]?.repeaterItems;
    delete repeaterItems?.[id];
    props.form.setValue(`${props.question.id}.repeaterItems`, repeaterItems);
    setGroupIds(groupIds.filter((gid) => gid !== id));
  }

  const q = props.question;

  return (
    <motion.div
      id={q.id}
      className={ClientUtils.classNames(
        "grid w-full grid-cols-[minmax(0,1fr),auto] gap-4 ",
        props.className
      )}
      onBlur={props.onBlur}
      onFocus={props.onFocus}
    >
      <IpisFormInputLabel label={q.title} id={q.id} required={q.required} />
      {!!q.tooltip && (
        <InformationTooltip
          open={"left"}
          content={q.tooltip}
          contentClassName="w-[200px]"
        />
      )}
      {!!q.description && (
        <RichTextPreTag
          className="col-span-2 w-full whitespace-pre-wrap text-dark-gray/80"
          html={q.description}
        />
      )}
      {groupIds.map((groupId, groupIndex) => {
        const legendText = `${props.question.repeaterGroup.name} ${
          groupIndex + 1
        }`;
        const items = props.question.repeaterGroup.items;
        return (
          <motion.fieldset
            key={groupId}
            className="relative col-span-2 flex flex-col gap-6 rounded border border-border-color bg-secondary-50 p-4 shadow"
            onBlur={props.onBlur}
            onFocus={props.onFocus}
          >
            <motion.h3 className="text-lg font-bold">{legendText}</motion.h3>
            <motion.span className="absolute right-0 top-0">
              <IpisButton
                label="Ta bort"
                onClick={() => removeGroup(groupId)}
                variant="tonal"
                icon={{
                  type: "trash",
                  position: "leading",
                }}
                onlyIcon
              />
            </motion.span>
            {items.map((item, itemIndex) => {
              const key = `${q.id}-${itemIndex}-item-${itemIndex}`;
              const isFirstElement = itemIndex === 0 && groupIndex === 0;

              return (
                <RenderItem
                  checklist={props.checklist}
                  parentQuestion={q}
                  key={key}
                  item={item}
                  groupId={groupId}
                  form={props.form}
                  validatedChecklistDO={props.validatedChecklist}
                  isFirst={isFirstElement}
                />
              );
            })}
          </motion.fieldset>
        );
      })}
      {!!q.note && <IpisFormInputNote html={q.note} />}
      {!!props.error && (
        <IpisFormInputError humanReadableName={q.title} error={props.error} />
      )}
      <IpisButton
        className="col-span-2"
        label="Lägg till alternativ"
        onClick={addGroup}
        variant="tonal"
        icon={{
          type: "plus",
          position: "trailing",
        }}
      />
    </motion.div>
  );
};

const RenderItem = (props: {
  parentQuestion: IpisFormElement.RepeaterType;
  item: IpisFormElement.RepeaterItemType;
  groupId: string;
  form: UseFormReturn<ChecklistFormValues>;
  validatedChecklistDO: IpisFormAnswerValidator;
  checklist: IpisForm.ShellType;
  bypassConditionCheck?: boolean;
  //isLast: boolean;
  isFirst: boolean;
}) => {
  /* 
  props.parentQuestion,
    {
      item: props.item,
      groupId: props.groupId,
    }
  */
  const validationWithoutAutomaticPass =
    props.validatedChecklistDO.shouldShowRepeaterItem({
      parentQuestion: props.parentQuestion,
      repeaterItem: props.item,
      repeaterGroupId: props.groupId,
    });
  const meetsConditions =
    props.bypassConditionCheck ?? validationWithoutAutomaticPass;
  const hideItem = !validationWithoutAutomaticPass;
  const showConditionalOverlay =
    validationWithoutAutomaticPass && !meetsConditions;

  if (hideItem) {
    return <></>;
  }

  const form = props.form;
  const i = props.item;
  const name: Path<ChecklistFormValues> = `${props.parentQuestion.id}.repeaterItems[${props.groupId}][${props.item.id}].value`;
  switch (i.typeOfQuestion) {
    case "text":
      return (
        <ItemWrapper showConditionalOverlay={showConditionalOverlay}>
          <IpisTextInput
            required={i.required}
            //label={i.title}
            placeholder={i.placeholder}
            id={i.id}
            name={name}
            form={form}
            richTextDescription={i.description}
            richTextNote={i.note}
          />
        </ItemWrapper>
      );
    case "yes/no":
      if (i.renderAs === "buttons") {
        return (
          <ItemWrapper showConditionalOverlay={showConditionalOverlay}>
            <IpisRadioGroup
              required={i.required}
              label={i.title}
              id={i.id}
              name={name}
              richTextDescription={i.description}
              richTextNote={i.note}
              form={form}
              options={[
                { value: "yes", label: "Yes" },
                { value: "no", label: "No" },
              ]}
            />
          </ItemWrapper>
        );
      } else {
        return (
          <ItemWrapper showConditionalOverlay={showConditionalOverlay}>
            <IpisToggleInput
              required={i.required}
              name={name}
              form={form}
              label={i.title}
              id={i.id}
              richTextDescription={i.description}
              richTextNote={i.note}
            />
          </ItemWrapper>
        );
      }
    case "number":
    case "textarea":
    default:
      return <div>Not yet supported</div>;
  }
};

const ItemWrapper = (props: {
  showConditionalOverlay: boolean;
  children?: React.ReactNode;
}) => {
  if (props.showConditionalOverlay) {
    return (
      <div className="relative">
        <div className="absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center text-2xl font-bold uppercase backdrop-blur">
          Conditional
        </div>
        {props.children}
      </div>
    );
  }

  return <>{props.children}</>;
};

export default ChecklistDecoupledRepeaterQuestion;
