import { UploaderSource } from "@eljouren/file-schemas/build";
import { BsHeadset } from "react-icons/bs";
import { IoMdCart } from "react-icons/io";
import AppHandymanIcon from "../AppHandymanIcon";

interface Props {
  size: number;
  uploaderSource: UploaderSource.Type;
}

const IpisUploadedByIcon = (props: Props) => {
  switch (props.uploaderSource) {
    case "customer":
      return <IoMdCart size={props.size} />;
    case "staff":
      return <BsHeadset size={props.size} />;
    case "worker":
      return <AppHandymanIcon size={props.size} />;
  }

  return <></>;
};

export default IpisUploadedByIcon;
