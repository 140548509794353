import { useContext } from "react";
import BookingSuggestionsContext from "../BookingSuggestionsContext";
import GroupedBookingSuggestion from "../single-booking-suggestion/GroupedBookingSuggestion";

const BookingSuggestionsByScore = () => {
  const ctx = useContext(BookingSuggestionsContext);

  return (
    <ul className="flex flex-col gap-4">
      {ctx.sortedBookingSuggestions.bestFirst.map((groupedSuggestion, i) => {
        return (
          <GroupedBookingSuggestion
            showDate={true}
            {...groupedSuggestion}
            key={groupedSuggestion.id}
          />
        );
      })}
    </ul>
  );
};

export default BookingSuggestionsByScore;
