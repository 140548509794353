import ClientUtils from './../../utils/ClientUtils';
//https://codesandbox.io/s/assignment-12-1-animate-checkmark-solution-forked-3z7m1w?file=/src/index.tsx

import { motion } from "framer-motion";

interface Props {
  className?: string;
  onAnimationEnd?(): void;
  text?: string;
}

function AnimatedCheckmark(props: Props) {
  return (
    <div
      className={ClientUtils.twClassNames(
        props.className,
        "flex flex-col items-center gap-2"
      )}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="124"
        height="124"
        viewBox="0 0 258 258"
      >
        {/* Circle */}
        <motion.path
          d="M 130 6 C 198.483 6 254 61.517 254 130 C 254 198.483 198.483 254 130 254 C 61.517 254 6 198.483 6 130 C 6 61.517 61.517 6 130 6 Z"
          strokeWidth="8"
          initial={{
            pathLength: 0,
            stroke: "#FFCC66",
            fill: "#ffffff",
          }}
          animate={{
            pathLength: 1,
            stroke: ["#FFCC66", "#FFCC66", "#66BB66"],
            fill: ["#ffffff", "#FFCC66", "#66BB66"],
          }}
          transition={{
            duration: 1,
            fill: {
              times: [0, 0.6, 1],
            },
            stroke: {
              times: [0, 0.6, 1],
            },
            //repeat: Infinity,
          }}
          //fill={"transparent"}
          /*  style={{
          fill: circleFill,
          //pathLength: circleLength,
        }} */
        />
        {/* Check mark  */}
        <motion.path
          transform="translate(60 85)"
          d="M3 50L45 92L134 3"
          fill={"transparent"}
          stroke="#ffffff"
          initial={{
            pathLength: 0,
          }}
          animate={{
            pathLength: 1,
          }}
          transition={{
            duration: 0.2,
            delay: 0.9,
          }}
          strokeWidth={12}
          onAnimationComplete={() => {
            setTimeout(() => {
              if (props.onAnimationEnd) {
                props.onAnimationEnd();
              }
            }, 1500);
          }}
        />
      </motion.svg>
      {props.text && (
        <motion.span
          className="text-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.9, duration: 0.4 }}
        >
          {props.text}
        </motion.span>
      )}
    </div>
  );
}

export default AnimatedCheckmark;
