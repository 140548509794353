"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormClientSchemaBuilder = void 0;
var domain_1 = require("@eljouren/domain");
var zod_1 = require("zod");
var IpisFormClientSchemaBuilder = /** @class */ (function () {
    function IpisFormClientSchemaBuilder(props) {
        this.props = props;
    }
    IpisFormClientSchemaBuilder.prototype.forChecklist = function (checklist) {
        var schema = zod_1.z.object({});
        for (var _i = 0, _a = checklist.pages; _i < _a.length; _i++) {
            var page = _a[_i];
            schema = schema.merge(this.forPage(page));
        }
        return schema;
    };
    IpisFormClientSchemaBuilder.prototype.forPage = function (page) {
        var _a;
        var _b;
        var schema = zod_1.z.object({});
        for (var _i = 0, _c = page.elements; _i < _c.length; _i++) {
            var question = _c[_i];
            if (question.typeOfQuestion === "information") {
                continue;
            }
            var withAnswerState = this.props.validated.getElement(question.id);
            if (!((_b = withAnswerState.state) === null || _b === void 0 ? void 0 : _b.meetsConditions)) {
                // Skip this question if it doesn't pass the condition, since this question is not visible
                continue;
            }
            var fieldSchema = domain_1.FormElementAnswer.getAnswerSchemaFromQuestion(question);
            schema = schema.extend((_a = {},
                _a[question.id] = fieldSchema,
                _a));
        }
        return schema;
    };
    return IpisFormClientSchemaBuilder;
}());
exports.IpisFormClientSchemaBuilder = IpisFormClientSchemaBuilder;
