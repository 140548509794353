"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProvinceWithCountiesSchema = exports.WorkingHoursSchema = exports.OffWorkWorkingHoursSchema = exports.ReportedWorkingHoursSchema = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
/*
WORKING HOURS
*/
exports.ReportedWorkingHoursSchema = centralized_zod_1.z.object({
    status: centralized_zod_1.z.literal("reported"),
    startOfDay: centralized_zod_1.z.date(),
    start: centralized_zod_1.z.date(),
    end: centralized_zod_1.z.date(),
});
exports.OffWorkWorkingHoursSchema = centralized_zod_1.z.object({
    status: centralized_zod_1.z.literal("offWork"),
    startOfDay: centralized_zod_1.z.date(),
});
exports.WorkingHoursSchema = centralized_zod_1.z.discriminatedUnion("status", [
    exports.ReportedWorkingHoursSchema,
    exports.OffWorkWorkingHoursSchema,
]);
/*
  Lmao this name
*/
exports.ProvinceWithCountiesSchema = centralized_zod_1.z.object({
    name: centralized_zod_1.z.string(),
    counties: centralized_zod_1.z.string().array(),
});
