"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorLogAccessLevel = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var ErrorLogAccessLevel;
(function (ErrorLogAccessLevel) {
    var Enum;
    (function (Enum) {
        Enum["ADMIN"] = "admin";
        Enum["NONE"] = "none";
    })(Enum = ErrorLogAccessLevel.Enum || (ErrorLogAccessLevel.Enum = {}));
    ErrorLogAccessLevel.Schema = centralized_zod_1.z.nativeEnum(Enum);
})(ErrorLogAccessLevel || (ErrorLogAccessLevel = {}));
exports.ErrorLogAccessLevel = ErrorLogAccessLevel;
