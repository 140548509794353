import { IpisForm } from "@eljouren/domain";
import { useRef } from "react";
import { z } from "@ipis/centralized-zod";
import { useFormEditor } from "../../../../../hooks/checklist-hooks";
import AppPages from "../../../../common/pages/AppPages";
import EditableFormPageComponent from "./EditableFormPageComponent";
import EditableChecklistProgressIndicator from "./EditableChecklistProgressIndicator";
import { IpisFormTypeInference } from "@eljouren/domain-utils";
import { FormContext } from "../../ChecklistContexts";

interface Props<T extends IpisForm.ShellType> {
  className?: string;
  onSubmit: (values: IpisFormTypeInference.InferredFormValues<T>) => void;
}

const EditableFormComponent = <T extends IpisForm.ShellType>(
  props: Props<T>
) => {
  const pageValues = useRef<
    Partial<IpisFormTypeInference.InferredFormValues<T>>
  >({});
  const ctx = useFormEditor();
  const isFinalPage = ctx.pageIndex === ctx.form.pages.length - 1;
  const page = ctx.page;

  /* 
    Bypass validation, can probably be done in a better way
  */
  const pageSchema = z.object({});

  /* function onSubmit(values: IpisFormTypeInference.InferredFormValues<T>) {
    props.onSubmit(values);
  } */

  function proceed(values: { [key: string]: any }) {
    pageValues.current = { ...pageValues.current, ...values };
    if (ctx.pageIndex < ctx.form.pages.length - 1) {
      ctx.nextPage();
    } else {
      props.onSubmit(
        pageValues.current as IpisFormTypeInference.InferredFormValues<T>
      );
    }
  }

  function goBack() {
    ctx.previousPage();
  }

  return (
    <FormContext.Provider
      value={{
        byPassSchemaValidation: true,
        byPassConditionalValidation: true,
        form: ctx.form,
      }}
    >
      <section className="grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)] ">
        {ctx.form.pages.length > 1 && (
          <header>
            <EditableChecklistProgressIndicator
              pages={ctx.form.pages}
              currentStep={ctx.pageIndex}
            />
          </header>
        )}

        {page && (
          <AppPages
            pageIndex={ctx.pageIndex}
            onlyMain
            mainGridRow={2}
            className="h-full overflow-y-auto"
          >
            <EditableFormPageComponent
              otherPageValues={pageValues.current}
              page={page}
              isFinalPage={isFinalPage}
              proceed={proceed}
              goBack={goBack}
              isFirstPage={ctx.pageIndex === 0}
              schema={pageSchema}
            />
          </AppPages>
        )}
        {!page && (
          <p className="m-auto p-8 text-xl font-bold">
            Formuläret är tomt. Lägg till en sida för att börja.
          </p>
        )}
      </section>
    </FormContext.Provider>
  );
};

export default EditableFormComponent;
