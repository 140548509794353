import ClientUtils from "./../../../../utils/ClientUtils";

import CompletedWorkOrderIcon from "../../../../components/icons/composed/CompletedWorkOrderIcon";
import ServiceContractIcon from "../../../../components/icons/composed/ServiceContractIcon";
import { HandymanWorkOrderRouteContextType } from "../contexts/HandymanWorkOrderRouteContext";

interface Props {
  subheading?: string;
  headingClassName: string;
  subheadingClassName: string;
  context: HandymanWorkOrderRouteContextType;
}

const HandymanWorkOrderDesktopHeadings = (props: Props) => {
  const order = props.context.order;
  const isFinished = order.isFinished;
  const failed = isFinished && !!order.workOrderStatus.unsuccessfulReason;

  return (
    <>
      <span className="flex items-center gap-4">
        <span className="flex flex-col">
          <h3 className="text-xl font-bold">Arbetsorder</h3>
          {!order.isAssignedThisOrder && !!order.assignedTo && (
            <h4>{order.assignedTo.handymanName}</h4>
          )}
        </span>
        <fieldset className="mr-auto flex gap-4">
          {order.isFinished && (
            <span className={ClientUtils.twClassNames("my-auto")}>
              <span className="sr-only">Ordern är klarmarkerad</span>
              <CompletedWorkOrderIcon
                status={failed ? "failure" : "success"}
                size={25}
                aria-hidden="true"
              />
            </span>
          )}
          {order.serviceContract && (
            <span
              className={ClientUtils.twClassNames(
                "my-auto mr-auto",
                order.isFinished && "col-start-3",
                !order.isFinished && "col-start-2"
              )}
            >
              <span className="sr-only">Avtalskund</span>
              <ServiceContractIcon size={25} aria-hidden="true" />
            </span>
          )}
        </fieldset>
      </span>
    </>
  );
};

export default HandymanWorkOrderDesktopHeadings;
