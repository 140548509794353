import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { HandyManWorkOrderDesktopFileProps } from "../../../routes/worker/order/desktop-specific/HandymanWorkOrderDesktopFileSection";
import ClientUtils from "../../../utils/ClientUtils";
import { AppButton } from "../../common/buttons/AppButton";
import GeneralPurposeFileGridItem from "../../files/GeneralPurposeFileGridItem";
import { IpisFileWithAltText } from "../../files/SelectableFileGrid";
import ExpandedFileDiv from "../../files/desktop-file-section/ExpandedFileDiv";
import AppDocumentIcon from "../../icons/AppDocumentIcon";
import AppImageIcon from "../../icons/AppImageIcon";
import AppMinimizeIcon from "../../icons/AppMinimizeIcon";
import AppUploadIcon from "../../icons/AppUploadIcon";
import AppXIcon from "../../icons/AppXIcon";
import HandymanConditionalFileErrorDropdown from "../handyman/files/HandymanConditionalFileErrorDropdown";
import HandymanWorkOrderFileUpload from "../handyman/files/HandymanWorkOrderFileUpload";
import IpisFileDataList from "../handyman/files/IpisFileDataList";
import {
  HandymanWorkOrderFileContext,
  THandymanFileFilters,
} from "./DecoupledWorkOrderFileContextProvider";
import DecoupledWorkOrderFileFilterDropdown from "./DecoupledWorkOrderFileFilterDropdown";

type Props = Omit<HandyManWorkOrderDesktopFileProps, "isExpanded">;

const DecoupledWorkOrderDesktopFileExpandedSection = (props: Props) => {
  const [selectedFile, setSelectedFile] = useState<IpisFileWithAltText | null>(
    null
  );
  const [isUploading, setIsUploading] = useState(false);
  const ctx = useContext(HandymanWorkOrderFileContext);
  const form = useForm<THandymanFileFilters>({
    defaultValues: ctx.defaultFilterValues,
  });

  useEffect(() => {
    if (!selectedFile) {
      return;
    }

    const file = ctx.allFiles.find((f) => f.guid === selectedFile.guid);

    if (!file) {
      setSelectedFile(null);
    }
  }, [selectedFile, ctx.workOrderFileRes.query.data, ctx.allFiles]);

  form.watch();
  const { images, documents } = ctx.applyFilters(form.getValues());

  async function onDelete(file: IpisFileV2.Type) {
    const confirm = await window.modal.confirm({
      title: "Ta bort fil",
      prompt: `Är du säker på att du vill ta bort '${file.nameWithExtension}'?`,
    });
    if (confirm) {
      ctx.deleteFile(file);
    }
  }

  return (
    <>
      <header
        className={ClientUtils.twClassNames("grid grid-cols-6 gap-8 py-2")}
      >
        <span
          className={ClientUtils.classNames(
            "col-span-6 flex items-center justify-end gap-2 rounded bg-white/60 p-2 shadow shadow-black  2xl:col-span-4 2xl:col-start-2",

            ctx.workOrderFileRes.query.isLoading && "skeleton"
          )}
        >
          {props.workOrderSubject && (
            <h3 className="mr-auto pl-4 text-xl font-bold">
              {props.workOrderSubject}
            </h3>
          )}
          <HandymanConditionalFileErrorDropdown absolute />
          <DecoupledWorkOrderFileFilterDropdown className="w-80" form={form} />

          {ctx.permissions.canUpload && (
            <AppButton
              onClick={() => setIsUploading(!isUploading)}
              contentClassName="flex gap-2"
              /* variant={isUploading ? "fill-error" : "fill-default"} */
            >
              {!isUploading && <AppUploadIcon size={24} />}
              {isUploading && <AppXIcon size={24} />}
              <span>
                {isUploading
                  ? "Stäng uppladdningsformulär"
                  : "Ladda upp nya filer"}
              </span>
            </AppButton>
          )}
          {!!props.onToggle && (
            <AppButton onClick={props.onToggle} contentClassName="flex gap-2">
              <AppMinimizeIcon size={24} />
              <span>Minimera</span>
            </AppButton>
          )}
        </span>
      </header>
      <main
        className={ClientUtils.twClassNames(
          "relative grid h-full grid-cols-3 grid-rows-1 items-center gap-4"
        )}
      >
        <motion.section
          className={ClientUtils.twClassNames(
            "grid h-full w-full grid-rows-[auto,minmax(0,1fr)] gap-y-4 rounded bg-white/60 p-4",
            isUploading && "pointer-events-none"
          )}
          animate={{
            opacity: isUploading ? 0.3 : 1,
          }}
        >
          <header className="flex items-center gap-2">
            <AppDocumentIcon size={24} />
            <h3 className="text-xl">Dokument</h3>
            <span>|</span>
            <span>{documents.length}</span>
          </header>
          <main
            className={ClientUtils.twClassNames(
              "h-full p-4",
              isUploading && "overflow-hidden",
              !isUploading && "overflow-auto"
            )}
          >
            {documents && (
              <IpisFileDataList
                onDelete={onDelete}
                filePermissionCallback={ctx.permissions.forFile}
                data={documents}
              />
            )}
          </main>
        </motion.section>
        <AnimatePresence>
          {isUploading && (
            <motion.section
              className="col-start-2 row-start-1 grid h-full w-full grid-cols-1 grid-rows-1 gap-y-4 rounded bg-white/90"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <main className="h-full w-full overflow-y-auto p-4">
                <HandymanWorkOrderFileUpload
                  resetAfterSubmit
                  uploadLabel="Ladda upp filer"
                  requireAtLeastOneFile={true}
                />
              </main>
            </motion.section>
          )}
        </AnimatePresence>

        <motion.section
          className={ClientUtils.twClassNames(
            "col-start-2 row-start-1 grid h-full w-full grid-rows-[min-content,minmax(0,1fr)] gap-x-8 gap-y-4 rounded bg-white/60 p-4",
            !isUploading && "col-span-2 grid-cols-2",
            isUploading && "pointer-events-none col-start-3"
          )}
          layout
          animate={{
            opacity: isUploading ? 0.3 : 1,
          }}
        >
          <header className="col-start-1 row-start-1 flex items-center gap-2">
            <AppImageIcon size={24} />
            <h3 className="text-xl">Bilder</h3>
            <span>|</span>
            <span>{images.length}</span>
          </header>
          <main
            className={ClientUtils.twClassNames(
              "col-start-1 row-start-2 grid grid-rows-1 gap-4 2xl:gap-8"
            )}
          >
            {!images.length && (
              <p className="p-4">Det finns inga bilder att visa</p>
            )}
            {!!images.length && (
              <section className="h-full w-full overflow-auto">
                <motion.ul
                  className={ClientUtils.twClassNames(
                    "grid w-full grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2",
                    isUploading && "overflow-hidden",
                    !isUploading && "overflow-auto"
                  )}
                >
                  {images
                    .map((file) => ({
                      ...file,
                      alt: "Fil uppladdad till arbetsorder",
                    }))
                    .map((file) => {
                      return (
                        <GeneralPurposeFileGridItem
                          key={file.src}
                          file={file}
                          customDimensions
                          onClick={() => {
                            setSelectedFile(file);
                          }}
                          className="h-64 w-full "
                          selected={selectedFile?.src === file.src}
                        />
                      );
                    })}
                </motion.ul>
              </section>
            )}
          </main>
          {!isUploading && !selectedFile && !!images.length && (
            <p className="col-start-2 row-start-2 p-2 text-xl font-semibold 2xl:col-span-1">
              Klicka på en bild för att förstora
            </p>
          )}
          {!isUploading && !!selectedFile && (
            <ExpandedFileDiv
              onDelete={onDelete}
              file={selectedFile}
              alt="Fil uppladdad till arbetsorder"
              className="col-start-2 row-span-2 row-start-1"
              permissions={ctx.permissions}
            />
          )}
        </motion.section>
      </main>
    </>
  );
};

export default DecoupledWorkOrderDesktopFileExpandedSection;
