"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateHelper = void 0;
var date_fns_1 = require("date-fns");
var locale_1 = require("date-fns/locale");
var DateHelper = /** @class */ (function () {
    function DateHelper(date, locale) {
        this.date = date || new Date();
        this.locale = locale || locale_1.sv;
    }
    Object.defineProperty(DateHelper.prototype, "year", {
        /*
            FINAL
            */
        get: function () {
            return (0, date_fns_1.getYear)(this.date);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "month", {
        get: function () {
            return (0, date_fns_1.getMonth)(this.date);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "week", {
        get: function () {
            return (0, date_fns_1.getWeek)(this.date, { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "hours", {
        get: function () {
            return (0, date_fns_1.getHours)(this.date);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "minutes", {
        get: function () {
            return (0, date_fns_1.getMinutes)(this.date);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "dayOfMonth", {
        get: function () {
            return Number.parseInt((0, date_fns_1.format)(this.date, "d", { locale: this.locale }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "dayOfWeekStartingAt1", {
        get: function () {
            return Number.parseInt((0, date_fns_1.format)(this.date, "i", { locale: this.locale }));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "dayOfWeekIndex", {
        get: function () {
            return this.dayOfWeekStartingAt1 - 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "dayOfWeek", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "EEEE", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    DateHelper.prototype.differenceInMonths = function (date) {
        return (0, date_fns_1.differenceInMonths)(this.date, date);
    };
    Object.defineProperty(DateHelper.prototype, "isWeekend", {
        get: function () {
            return (0, date_fns_1.isWeekend)(this.date);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "isToday", {
        get: function () {
            return (0, date_fns_1.isSameDay)(this.date, new Date());
        },
        enumerable: false,
        configurable: true
    });
    DateHelper.prototype.isBefore = function (date) {
        return (0, date_fns_1.isBefore)(this.date, date);
    };
    DateHelper.prototype.isAfter = function (date) {
        return (0, date_fns_1.isAfter)(this.date, date);
    };
    Object.defineProperty(DateHelper.prototype, "monthLabel", {
        /*
            FORMATS
            */
        get: function () {
            return (0, date_fns_1.format)(this.date, "LLLL", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "yearLabel", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "y", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "hourLabel", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "HH", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "hourAndMinutesLabel", {
        get: function () {
            return this.timeInputFormat;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "readableDateTime", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "yyyy-MM-dd HH:mm", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "readableDateTimeWithSeconds", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "yyyy-MM-dd HH:mm:ss", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "readableDate", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "yyyy-MM-dd", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "dateTimeInputFormat", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "yyyy-MM-dd'T'HH:mm", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "timeInputFormat", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "HH:mm", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "dateInputFormat", {
        get: function () {
            return (0, date_fns_1.format)(this.date, "yyyy-MM-dd", { locale: this.locale });
        },
        enumerable: false,
        configurable: true
    });
    DateHelper.prototype.format = function (formatStr) {
        return (0, date_fns_1.format)(this.date, formatStr, { locale: this.locale });
    };
    DateHelper.prototype.formatDistance = function (other) {
        return (0, date_fns_1.formatDistance)(this.date, other, { locale: this.locale });
    };
    DateHelper.prototype.differenceIn = function (timeUnit, date, roundingMethod) {
        if (roundingMethod === void 0) { roundingMethod = "trunc"; }
        switch (timeUnit) {
            case "ms":
                return (0, date_fns_1.differenceInMilliseconds)(this.date, date);
            case "seconds":
                return (0, date_fns_1.differenceInSeconds)(this.date, date, { roundingMethod: roundingMethod });
            case "minutes":
                return (0, date_fns_1.differenceInMinutes)(this.date, date, { roundingMethod: roundingMethod });
            case "hours":
                return (0, date_fns_1.differenceInHours)(this.date, date, { roundingMethod: roundingMethod });
            case "days":
                return (0, date_fns_1.differenceInDays)(this.date, date);
            case "weeks":
                return (0, date_fns_1.differenceInWeeks)(this.date, date, { roundingMethod: roundingMethod });
            case "months":
                return (0, date_fns_1.differenceInMonths)(this.date, date);
            case "years":
                return (0, date_fns_1.differenceInYears)(this.date, date);
        }
    };
    Object.defineProperty(DateHelper.prototype, "startOfMonth", {
        /*
            RECURSIVE
            */
        get: function () {
            return new DateHelper((0, date_fns_1.startOfMonth)(this.date), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "startOfYear", {
        get: function () {
            return new DateHelper((0, date_fns_1.startOfYear)(this.date), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "endOfYear", {
        get: function () {
            return new DateHelper((0, date_fns_1.endOfYear)(this.date), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "endOfMonth", {
        get: function () {
            return new DateHelper((0, date_fns_1.endOfMonth)(this.date), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "startOfWeek", {
        get: function () {
            return new DateHelper((0, date_fns_1.startOfWeek)(this.date, { locale: this.locale }), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "endOfWeek", {
        get: function () {
            return new DateHelper((0, date_fns_1.endOfWeek)(this.date, { locale: this.locale }), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "startOfDay", {
        get: function () {
            return new DateHelper((0, date_fns_1.startOfDay)(this.date), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DateHelper.prototype, "endOfDay", {
        get: function () {
            return new DateHelper((0, date_fns_1.endOfDay)(this.date), this.locale);
        },
        enumerable: false,
        configurable: true
    });
    DateHelper.prototype.setYear = function (hours) {
        return new DateHelper((0, date_fns_1.setYear)(this.date, hours), this.locale);
    };
    DateHelper.prototype.setMonth = function (hours) {
        return new DateHelper((0, date_fns_1.setMonth)(this.date, hours), this.locale);
    };
    DateHelper.prototype.setHours = function (hours) {
        return new DateHelper((0, date_fns_1.setHours)(this.date, hours), this.locale);
    };
    DateHelper.prototype.setMinutes = function (minutes) {
        return new DateHelper((0, date_fns_1.setMinutes)(this.date, minutes), this.locale);
    };
    DateHelper.prototype.setWeek = function (minutes) {
        return new DateHelper((0, date_fns_1.setWeek)(this.date, minutes, {
            locale: this.locale,
        }), this.locale);
    };
    DateHelper.prototype.set = function (type, amount) {
        switch (type) {
            case "ms":
                return new DateHelper((0, date_fns_1.setMilliseconds)(this.date, amount));
            case "seconds":
                return new DateHelper((0, date_fns_1.setSeconds)(this.date, amount));
            case "minutes":
                return new DateHelper((0, date_fns_1.setMinutes)(this.date, amount));
            case "hours":
                return new DateHelper((0, date_fns_1.setHours)(this.date, amount));
            case "days":
                return new DateHelper((0, date_fns_1.setDate)(this.date, amount));
            case "weeks":
                return new DateHelper((0, date_fns_1.setWeek)(this.date, amount, {
                    locale: this.locale,
                }), this.locale);
            case "months":
                return new DateHelper((0, date_fns_1.setMonth)(this.date, amount));
            case "years":
                return new DateHelper((0, date_fns_1.setYear)(this.date, amount));
        }
        //throw new Error(`Invalid modtype '${type}'`);
    };
    /*
        RECURSIVE CUSTOMISATIONS
        */
    DateHelper.prototype.add = function (type, amount) {
        switch (type) {
            case "ms":
                return new DateHelper((0, date_fns_1.addMilliseconds)(this.date, amount));
            case "seconds":
                return new DateHelper((0, date_fns_1.addSeconds)(this.date, amount));
            case "minutes":
                return new DateHelper((0, date_fns_1.addMinutes)(this.date, amount));
            case "hours":
                return new DateHelper((0, date_fns_1.addHours)(this.date, amount));
            case "days":
                return new DateHelper((0, date_fns_1.addDays)(this.date, amount));
            case "weeks":
                return new DateHelper((0, date_fns_1.addWeeks)(this.date, amount));
            case "months":
                return new DateHelper((0, date_fns_1.addMonths)(this.date, amount));
            case "years":
                return new DateHelper((0, date_fns_1.addYears)(this.date, amount));
        }
        //throw new Error(`Invalid modtype '${type}'`);
    };
    DateHelper.prototype.sub = function (type, amount) {
        return this.add(type, -amount);
    };
    DateHelper.prototype.setSameDateAs = function (other) {
        var newDate = (0, date_fns_1.set)(this.date, {
            year: (0, date_fns_1.getYear)(other),
            month: (0, date_fns_1.getMonth)(other),
            date: (0, date_fns_1.getDate)(other),
        });
        return new DateHelper(newDate);
    };
    /*
        COMPARISONS
        */
    DateHelper.prototype.isSameMonth = function (comparisonDate) {
        return (0, date_fns_1.isSameMonth)(this.date, comparisonDate);
    };
    DateHelper.prototype.isSameDay = function (comparisonDate) {
        return (0, date_fns_1.isSameDay)(this.date, comparisonDate);
    };
    return DateHelper;
}());
exports.DateHelper = DateHelper;
