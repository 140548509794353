import { useEffect, useRef, useState, useCallback } from "react";

export type UseIsScrollableReturn = {
  x: boolean;
  y: boolean;
  isAtTop: boolean;
  isAtBottom: boolean;
  scrollX: number;
  scrollY: number;
};

function checkScrollable(el: HTMLElement | null): UseIsScrollableReturn {
  if (!el) {
    return {
      x: false,
      y: false,
      isAtTop: true,
      isAtBottom: false,
      scrollX: 0,
      scrollY: 0,
    };
  }
  const isAtTop = el.scrollTop === 0;
  const isAtBottom =
    Math.abs(el.scrollHeight - el.scrollTop - el.clientHeight) < 2;
  const x = el.scrollWidth > el.clientWidth;
  const y = el.scrollHeight > el.clientHeight;
  const scrollX = el.scrollLeft;
  const scrollY = el.scrollTop;
  return { x, y, isAtTop, isAtBottom, scrollX, scrollY };
}

function useIsScrollable(
  ref: React.RefObject<HTMLElement | null>,
  onScrollPositionChanged?: (scrollable: UseIsScrollableReturn) => void
): UseIsScrollableReturn & { hasInitialised: boolean } {
  const hasInitialised = useRef(false);
  const [isScrollable, setIsScrollable] = useState<UseIsScrollableReturn>({
    x: false,
    y: false,
    isAtTop: true,
    isAtBottom: false,
    scrollX: 0,
    scrollY: 0,
  });

  const current = ref.current;

  useEffect(() => {
    const scrollable = checkScrollable(current);
    hasInitialised.current = current !== null;
    setIsScrollable(scrollable);
    onScrollPositionChanged?.(scrollable);
  }, [current, onScrollPositionChanged]);

  const callback = useCallback(() => {
    const scrollable = checkScrollable(current);
    setIsScrollable(scrollable);
    onScrollPositionChanged?.(scrollable);
  }, [current, onScrollPositionChanged]);

  useEffect(() => {
    current?.addEventListener("scroll", callback);

    window.addEventListener("resize", callback);

    return () => {
      current?.removeEventListener("scroll", callback);
      window.removeEventListener("resize", callback);
    };
  }, [callback, current]);

  return { ...isScrollable, hasInitialised: hasInitialised.current };
}

export default useIsScrollable;
