import { IpisFormElement } from "@eljouren/domain";
import { motion } from "framer-motion";
import { UseFormReturn } from "react-hook-form";
import ClientUtils from "../../../../../utils/ClientUtils";
import { IpisToggleInput } from "@ipis/client-essentials";
import { IpisRadioGroup } from "@ipis/client-essentials";

interface Props {
  className?: string;
  question: IpisFormElement.YesNoType;
  form: UseFormReturn<any>;
  onBlur?: () => void;
  onFocus?: () => void;
}

const ChecklistDecoupledYesNoQuestion = (props: Props) => {
  const q = props.question;

  /*   console.log("Rendering question ", q); */

  return (
    <motion.div
      className={ClientUtils.classNames(
        "grid w-full grid-cols-[minmax(0,1fr),auto] gap-4",
        props.className
      )}
    >
      {q.renderAs === "toggle" && (
        <IpisToggleInput
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          id={q.id}
          name={`${q.id}.value`}
          required={q.required}
          label={q.title}
          form={props.form}
          tooltip={q.tooltip}
          richTextDescription={q.description}
          richTextNote={q.note}
          slideUpElement={q.slideUpElement}
        />
      )}
      {q.renderAs === "buttons" && (
        <IpisRadioGroup
          onBlur={props.onBlur}
          onFocus={props.onFocus}
          id={q.id}
          name={`${q.id}.value`}
          required={q.required}
          label={q.title}
          form={props.form}
          tooltip={q.tooltip}
          richTextDescription={q.description}
          richTextNote={q.note}
          slideUpElement={q.slideUpElement}
          useUniformWidth
          options={[
            { label: "Ja", value: true },
            { label: "Nej", value: false },
          ]}
        />
      )}
    </motion.div>
  );
};

export default ChecklistDecoupledYesNoQuestion;
