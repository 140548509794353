import { useContext } from "react";
import AppCard from "../../components/__v2__/cards/AppCard";
import withStaffGuid, {
  StaffContext,
} from "../../components/auth/hocs/withStaffGuid";
import AppRoutes from "../../routes";
import { GlobalContext } from "../../top-level-contexts";
import ClientUtils from "./../../utils/ClientUtils";
import IpisPageWithLogo from "../../components/__v2__/layout-v2/IpisPageWithLogo";

const StaffRoot = () => {
  const staffContext = useContext(StaffContext);
  const { signInState } = useContext(GlobalContext);

  if (signInState.signedInAs !== "staff") {
    return <></>;
  }

  const name = `${signInState.firstName} ${signInState.lastName}`;

  return (
    <IpisPageWithLogo
      headerProps={{ heading: "Personalinlogg", subHeading: name }}
    >
      <section
        className={ClientUtils.twClassNames(
          "grid h-[650px] grid-cols-[repeat(auto-fill,minmax(350px,1fr))] gap-4 p-8"
        )}
      >
        <AppCard
          type="link"
          href={AppRoutes.staff.formBuilder(staffContext.staffGuid)}
          src="https://2.img-dpreview.com/files/p/E~C1000x0S4000x4000T1200x1200~articles/3925134721/0266554465.jpeg"
          title="Checklistebyggare"
          subtitle="Bygg checklistor åt kunder"
          description="För närvarande finns det endast support för prechecklistor. "
        />
      </section>
    </IpisPageWithLogo>
  );
};

export default withStaffGuid(StaffRoot);
