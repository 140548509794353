import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import WorkOrderOverviewCard from "./WorkOrderOverviewCard";
import AppPhoneIcon from "../../../../components/icons/AppPhoneIcon";
import AppMailIcon from "../../../../components/icons/AppMailIcon";
import AppDoorCodeIcon from "../../../../components/icons/AppDoorCodeIcon";
import FloorIcon from "../../../../components/icons/FloorIcon";
import AppPersonIcon from "../../../../components/icons/AppPersonIcon";

interface Props {
  className?: string;
}

const OverviewCustomerSection = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);

  const hasEndCustomerContactInfo =
    !!order.endCustomer.name ||
    !!order.endCustomer.phone ||
    !!order.endCustomer.email;

  return (
    <WorkOrderOverviewCard
      className={ClientUtils.twClassNames(
        props.className,
        "grid grid-cols-[auto,minmax(0,1fr)] p-2"
      )}
      data-has-any-end-customer-contact-info={hasEndCustomerContactInfo}
      data-has-end-customer-name={!!order.endCustomer.name}
      data-has-end-customer-phone={!!order.endCustomer.phone}
      data-has-end-customer-email={!!order.endCustomer.email}
    >
      <span className="my-auto flex rounded-full border bg-brand-blue-100 p-2 text-brand-blue-300">
        <AppPersonIcon size={40} />
      </span>
      <div className="flex flex-col p-4">
        <span className="text-xs italic text-dark-gray text-opacity-80">
          Kund
        </span>
        {hasEndCustomerContactInfo && (
          <>
            <span className="font-semibold" aria-label="Namn på kund">
              {order.endCustomer.name}
            </span>
          </>
        )}
        {!hasEndCustomerContactInfo && (
          <span className="font-semibold">Kontakta IPIS för kunduppgifter</span>
        )}
      </div>
      {hasEndCustomerContactInfo && (
        <div className="col-span-2 flex flex-col gap-2 py-4 pl-2">
          {!!order.endCustomer.phone && (
            <a
              aria-label="Telefonnummer till kund"
              className="mr-auto flex items-center gap-2 text-blue-600 hover:text-blue-400"
              href={`tel:${order.endCustomer.phone}`}
            >
              <AppPhoneIcon size={20} className="text-slate-500" />
              <span>{order.endCustomer.phone}</span>
            </a>
          )}
          {!!order.endCustomer.email && (
            <a
              aria-label="E-postadress till kund"
              className="mr-auto flex items-center gap-2 text-blue-600 hover:text-blue-400"
              href={`mailto:${order.endCustomer.email}`}
            >
              <AppMailIcon size={20} className="text-slate-500" />
              <span>{order.endCustomer.email}</span>
            </a>
          )}
          {order.location.doorCode && (
            <p className="col-span-2 flex items-center gap-2 p-2">
              <AppDoorCodeIcon size={20} className="text-black" />
              <span>{order.location.doorCode}</span>
            </p>
          )}
          {order.location.floor && (
            <p className="col-span-2 flex items-center gap-2 p-2">
              <FloorIcon size={20} className="text-black" />
              <span>{order.location.floor}</span>
            </p>
          )}
        </div>
      )}
    </WorkOrderOverviewCard>
  );
};

export default OverviewCustomerSection;
