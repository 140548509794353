import { PaymentOption } from "@eljouren/domain";
import ResursbankLogo from "../../../../components/external-brands/resursbank/ResursbankLogo";
import SwishLogo from "../../../../components/external-brands/swish/SwishLogo";
import TrustlyLogo from "../../../../components/external-brands/trustly/TrustlyLogo";
import ClientUtils from "../../../../utils/ClientUtils";
import Logo from "../../../../components/common/brand/Logo";

interface Props {
  className?: string;
  method: PaymentOption.Type;
}

const PaymentMethodLogoAndTerms = (props: Props) => {
  if (props.method.brand === "unknown") {
    return <></>;
  }

  return (
    <fieldset
      className={ClientUtils.classNames("flex flex-col gap-2", props.className)}
    >
      {props.method.brand === "resursbank" && <ResursbankLogo height={40} />}
      {props.method.brand === "swish" && <SwishLogo size={40} />}
      {props.method.brand === "trustly" && <TrustlyLogo width={60} />}
      {props.method.brand === "ipis" && (
        <Logo withColor wrapperClassName="w-[60px]" />
      )}
    </fieldset>
  );
};

export default PaymentMethodLogoAndTerms;
