import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "@ipis/centralized-zod";
import { useContext } from "react";
import { Controller, DeepPartial, useForm } from "react-hook-form";
import { IpisButton } from "@ipis/client-essentials";
import SimpleImageUploadComponent from "../../../../../components/__v2__/files/SimpleImageUploadComponent";
import FormEditableAddressFieldsetSection from "../../../../../components/__v2__/form-components/address/FormEditableAddressFieldsetSection";
import { IpisTextInputCompact } from "@ipis/client-essentials";
import { FileUploadHookFileSchema } from "../../../../../hooks/file-upload-hooks";
import CustomerFormDescriptionComponent from "../../components/CustomerFormDescriptionComponent";
import { CustomerWorkOrderFormContext } from "../CustomerWorkOrderFormSectionV2";
import CustomerWorkOrderInformationPageIntroductoryHeader from "./CustomerWorkOrderInformationPageIntroductoryHeader";
import CustomerWorkOrderInformationPageSchedulingAndHandymanSection from "./CustomerWorkOrderInformationPageSchedulingAndHandymanSection";

export const CustomerWorkOrderFormInformationPageFormSchema = z.object({
  images: FileUploadHookFileSchema.array(),
  location: z.object({
    street: z.string().min(1),
    city: z.string().min(1),
    postalCode: z.string().min(1),
    doorCode: z.string().optional(),
    floor: z.string().optional(),
    country: z.string(),
  }),
  contact: z.object({
    fullName: z.string().min(1),
    email: z.string().email(),
    phone: z.string().min(1),
  }),
});

export type CustomerWorkOrderFormInformationPageFormValues = z.infer<
  typeof CustomerWorkOrderFormInformationPageFormSchema
>;

interface Props {
  className?: string;
  defaultValues?: DeepPartial<CustomerWorkOrderFormInformationPageFormValues>;
  onSubmit?: (values: CustomerWorkOrderFormInformationPageFormValues) => void;
  omit?: {
    introduction?: boolean;
  };
}

const CustomerWorkOrderFormInformationPage = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderFormContext);
  const wo = ctx.workOrder;

  const form = useForm<CustomerWorkOrderFormInformationPageFormValues>({
    defaultValues: props.defaultValues,
    resolver: zodResolver(CustomerWorkOrderFormInformationPageFormSchema),
  });

  function onSubmit(values: CustomerWorkOrderFormInformationPageFormValues) {
    props.onSubmit?.(values);
  }

  return (
    <form
      className="flex h-full flex-col gap-8"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      {!props.omit?.introduction && (
        <CustomerWorkOrderInformationPageIntroductoryHeader />
      )}
      <CustomerWorkOrderInformationPageSchedulingAndHandymanSection />
      {/* 
        Description
      */}
      <CustomerFormDescriptionComponent workOrder={wo} />
      {/* 
        Image uploading
      */}
      <Controller
        name="images"
        control={form.control}
        render={({ field }) => (
          <SimpleImageUploadComponent
            id="customer-work-order-form-information-page-image-upload"
            heading="Lägg till bilder för att underlätta för hantverkaren"
            fileUploadProps={{
              //filter: "onlyNew",
              onChange: (files) => {
                field.onChange(files);
              },
              currentFiles: field.value ?? [],
              maxFileNameLength: 100,
            }}
          />
        )}
      />

      {/* 
        Address information
      */}
      <FormEditableAddressFieldsetSection
        id="customer-work-order-form-information-page-address"
        heading={{
          title: "Adress för utförande",
          as: "h2",
        }}
        form={form}
        legend="Adress"
        registerNested="location"
      />
      {/* 
        Contact
      */}
      <section className="flex flex-col gap-4">
        <h2 className="text-lg text-dark-950">Kontaktinformation</h2>

        <IpisTextInputCompact
          id="customer-work-order-form-information-page-contact-email"
          form={form}
          size="md"
          label="Ditt fullständiga namn"
          name="contact.fullName"
          required
        />
        <IpisTextInputCompact
          id="customer-work-order-form-information-page-contact-email"
          form={form}
          size="md"
          label="E-postadress"
          name="contact.email"
          required
        />
        <IpisTextInputCompact
          id="customer-work-order-form-information-page-contact-phone"
          form={form}
          size="md"
          label="Telefonnummer"
          name="contact.phone"
          required
        />
      </section>
      {!!props.onSubmit && (
        <footer className="flex flex-col gap-2 pt-4">
          <IpisButton type="submit" label="Fortsätt till fakturering" />
          <IpisButton variant="text" label="Kontakta oss" />
        </footer>
      )}
    </form>
  );
};

export default CustomerWorkOrderFormInformationPage;
