import { WorkOrder } from "@eljouren/domain/build";
import React from "react";
import { CustomFileInputFile } from "../../../components/files/FileInputButton";

export interface CustomerWorkOrderOverviewContextProps {
  workOrder: Pick<
    WorkOrder.CustomerType,
    | "description"
    | "additionalInformation"
    | "startDate"
    | "location"
    | "contact"
    | "confirmedByCustomer"
    | "orderId"
    | "selectedHousingInformation"
    | "selectedInvoiceInformation"
  >;
  files: CustomFileInputFile[] | "useFileContext";
  allowFileHandling: boolean;
  labelForFileUploading: string;
}

const CustomerWorkOrderOverviewContext =
  React.createContext<CustomerWorkOrderOverviewContextProps>({} as never);
export default CustomerWorkOrderOverviewContext;
