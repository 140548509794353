import { IpisButton } from "@ipis/client-essentials";

interface Props {
  className?: string;
  isFirstPage: boolean;
  isFinalPage: boolean;
  goBack: () => void;
  hideSubmitButton?: boolean;
}

const ChecklistPageFooter = (props: Props) => {
  const hideSubmitButton = !!props.hideSubmitButton && props.isFinalPage;
  return (
    <footer className="flex flex-col gap-2 pt-6">
      {!hideSubmitButton && (
        <IpisButton
          type="submit"
          label={props.isFinalPage ? "Spara" : "Fortsätt"}
        />
      )}
      {!props.isFirstPage && (
        <IpisButton
          type="button"
          variant="text"
          label="Gå Tillbaka"
          onClick={props.goBack}
        />
      )}
    </footer>
  );
};

export default ChecklistPageFooter;
