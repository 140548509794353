import { IpisFormElement } from "@eljouren/domain";
import { useChecklistPage } from "../../../../../hooks/checklist-hooks";
import ChecklistDecoupledYesNoQuestion from "./ChecklistDecoupledYesNoQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.YesNoType;
}

const ChecklistYesNoQuestion = (props: Props) => {
  const ctx = useChecklistPage();
  const q = props.question;
  return <ChecklistDecoupledYesNoQuestion question={q} form={ctx.form} />;
};

export default ChecklistYesNoQuestion;
