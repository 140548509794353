import ClientUtils from "./../../../utils/ClientUtils";
import { LiveBookingSuggestions } from "@eljouren/domain";

import { useRef } from "react";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppFormCheckbox } from "../../../components/common/checkboxes/AppFormCheckbox";
import { AppFormSelect } from "../../../components/common/select/AppFormSelect";
import { useSsForm } from "../../../hooks/session-storage-hooks";
import BookingSuggestionsContext, {
  BookingSuggestionsSortOption,
} from "./BookingSuggestionsContext";
import SortedBookingSuggestions from "./SortedBookingSuggestions";
import BookingSuggestionsByDate from "./sorting-options/BookingSuggestionsByDate";
import BookingSuggestionsByHandyman from "./sorting-options/BookingSuggestionsByHandyman";
import BookingSuggestionsByScore from "./sorting-options/BookingSuggestionsByScore";

interface Props {
  className?: string;
  suggestions: LiveBookingSuggestions.Type;
  newSearch(): void;
}

const BookingSuggestionsComponent = (props: Props) => {
  const isOnDuty = props.suggestions.isOnDuty;
  const optionsForm = useSsForm<{
    sortOption: BookingSuggestionsSortOption;
    simplifiedView: boolean;
  }>("booking-suggestions-form", {
    defaultValues: {
      sortOption: isOnDuty ? "handymanThenScore" : "scoreFirst",
      simplifiedView: true,
    },
  });

  const sortedBookingSuggestions = useRef(
    new SortedBookingSuggestions({
      suggestions: props.suggestions,
    })
  ).current;

  const sortOption = optionsForm.watch("sortOption");

  return (
    <BookingSuggestionsContext.Provider
      value={{
        sortedBookingSuggestions,
        sortOption,
        simplifiedView: optionsForm.watch("simplifiedView"),
        suggestions: props.suggestions,
      }}
    >
      <section
        className={ClientUtils.twClassNames(
          "mx-auto grid h-full w-full max-w-screen-xl grid-rows-[auto,minmax(0,1fr)]",
          props.className
        )}
      >
        <header className="grid grid-cols-[minmax(0,1fr),auto,auto] grid-rows-[min-content,min-content,min-content] gap-1 border-b py-2">
          <h3 className="col-start-1 text-lg font-normal">Bokningsförslag</h3>
          <h4 className="col-start-1 text-sm font-normal italic text-opacity-80">
            {props.suggestions.address.name}
          </h4>
          <div className="col-start-1 flex gap-2">
            <span className="rounded border bg-brand-blue-100/50 p-2 text-sm">
              {props.suggestions.qualification}
            </span>
            <span className="rounded border bg-brand-blue-100/50 p-2 text-sm">
              Vecka {props.suggestions.week}
            </span>
            <span className="rounded border bg-brand-blue-100/50 p-2 text-sm">
              {props.suggestions.timeEstimateInMinutes}m beräknad uppdragstid
            </span>
          </div>
          <AppFormCheckbox
            register={optionsForm.register}
            name="simplifiedView"
            type="checkbox"
            label="Förenklad vy"
            className="col-span-2 col-start-2 row-start-1 ml-auto"
          />
          <AppFormSelect
            register={optionsForm.register}
            name="sortOption"
            htmlAttributes={{
              "aria-label": "Sorteringsalternativ",
            }}
            className="col-start-2 row-span-2 row-start-2 my-auto"
            options={[
              {
                label: "Bäst först",
                value: "scoreFirst",
              },
              {
                label: "Dela upp i datum",
                value: "dateThenScore",
              },
              {
                label: "Dela upp i hantverkare",
                value: "handymanThenScore",
              },
            ]}
          ></AppFormSelect>
          <AppButton
            className="col-start-3 row-span-2 row-start-2 my-auto"
            onClick={props.newSearch}
          >
            Ny sökning
          </AppButton>
        </header>

        <main className="flex flex-col gap-6 overflow-auto">
          {sortOption === "scoreFirst" && <BookingSuggestionsByScore />}
          {sortOption === "dateThenScore" && <BookingSuggestionsByDate />}
          {sortOption === "handymanThenScore" && (
            <BookingSuggestionsByHandyman />
          )}
        </main>
      </section>
    </BookingSuggestionsContext.Provider>
  );
};

export default BookingSuggestionsComponent;
