import { BankIdTransformedCollectResponse } from "@eljouren/bank-id-schemas/build";
import trpcClient from "../../trpc-setup";
import IBankIdRepo from "./interfaces/IBankIdRepo";

export default class BankIdRepo implements IBankIdRepo {
  async collect(args: {
    sessionId: string;
  }): Promise<BankIdTransformedCollectResponse.ClientSafeTypeWithHandledStatus> {
    const testMode = !!(window as any).Cypress;
    return trpcClient.bankId.collect.query({
      ...args,
      testMode,
    });
  }

  cancel(args: { sessionId: string }): Promise<void> {
    return trpcClient.bankId.cancel.mutate(args);
  }

  async getQrCode(): Promise<string> {
    const testMode = !!(window as any).Cypress;
    return trpcClient.bankId.generateQrCode.query({
      testMode,
    });
  }
}
