import { WorkOrderConfirmation } from "@eljouren/domain/build";
import React from "react";
import { FieldErrors, UseFormReturn } from "react-hook-form";

const PaymentAndHousingFormContext = React.createContext<{
  showErrors: false | FieldErrors<WorkOrderConfirmation.PaymentInfoType>;
  form: UseFormReturn<WorkOrderConfirmation.PaymentInfoType>;
}>({} as never);

export default PaymentAndHousingFormContext;
