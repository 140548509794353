import { HandymanWorkingHours } from "@eljouren/domain/build";
import { isWeekend } from "date-fns";
import { useContext } from "react";
import DatePeriod from "../../../../_model/helpers/AvailabilityPeriod";
import WorkerDateHelper from "../../../../_model/helpers/WorkerDateHelper";
import ScrollIndicatorContainer from "../../../../components/ScrollIndicatorContainer";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import AppTextButton from "../../../../components/common/buttons/AppTextButton";
import HandymanContext from "../../../../components/handyman-context/HandymanContext";
import AppArrowIcon from "../../../../components/icons/AppArrowIcon";
import { TUseImmutableReturn } from "../../../../hooks/hooks";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import ClientUtils from "./../../../../utils/ClientUtils";

interface Props {
  period: DatePeriod;
  nextPage?(): void;
  className?: string;
  selectedDates: TUseImmutableReturn<Record<string, true>>;
  noEdit: boolean;
}

const HandymanWorkingHoursSelectDatesSection = (props: Props) => {
  const ctx = useContext(HandymanContext);
  const isLg = useMediaQuery(TailwindBreakpoint.lg);

  function activeDates() {
    //return props.period.getPeriodAtIndexWithoutPastDates(ctx.page);
    return props.period.getPeriodAtIndex(ctx.page);
  }

  function selectWeekdays() {
    select((date) => !isWeekend(date));
  }

  function selectWeekends() {
    select(isWeekend);
  }

  function select(predicate: (date: Date) => boolean) {
    const entries = activeDates()
      .filter(predicate)
      .map((date) => [date.getTime(), true]);
    const dict = Object.fromEntries(entries);
    props.selectedDates.override(dict);
  }

  function onDateClick(date: Date) {
    const time = date.getTime();
    props.selectedDates.modify((current) => {
      if (time in current) {
        delete current[time];
      } else {
        current[time] = true;
      }
    });
  }

  const availabilityString = (
    date: Date,
    availability: HandymanWorkingHours.DictIndexedByStartOfDayType
  ): string => {
    const item = availability[date.getTime()];
    const string = WorkerDateHelper.fromTo(item, {
      whenNotSpecified: "Ej rapporterat",
      whenOffDuty: "Otillgänglig",
      format: "$start - $end",
    });
    return string;
  };

  const headerText = (isLarge: boolean): string => {
    const weeks = props.period.getWeeksInPeriod(activeDates());
    return weeks.map((week) => (isLarge ? "V " : "V") + week).join(", ");
  };

  return (
    <section
      className={ClientUtils.twClassNames(
        "grid h-full grid-rows-[auto,minmax(0,1fr)]"
      )}
    >
      <header className="grid grid-cols-2 gap-y-4 p-2">
        <div className="flex items-center gap-4">
          <AppTextButton
            disabled={ctx.page === 0}
            onClick={ctx.decrementPage}
            aria-label="Gå till föregående period"
          >
            <AppArrowIcon direction="left" size={30} />
          </AppTextButton>
          <span className="text-base font-bold">{headerText(isLg)}</span>
          <AppTextButton
            onClick={ctx.incrementPage}
            aria-label="Gå till nästa period"
          >
            <AppArrowIcon direction="right" size={30} />
          </AppTextButton>
        </div>
        {!!props.nextPage && (
          <AppButton
            loading={ctx.isLoading}
            className="ml-auto"
            contentClassName="flex items-center gap-1"
            disabled={!props.selectedDates.values.length}
            onClick={props.nextPage}
            requiresNetworkConnection={false}
          >
            Rapportera
          </AppButton>
        )}

        {ctx.workingHoursRes.isError && (
          <span className="col-span-2 text-red-600">
            Det gick inte att hämta arbetstider just nu
          </span>
        )}
      </header>

      <ScrollIndicatorContainer
        as="main"
        innerClassName={ClientUtils.twClassNames(
          "flex h-full flex-col gap-2",
          ctx.workingHoursRes.isError && "pointer-events-none opacity-80"
        )}
      >
        {!props.noEdit && (
          <span className="flex gap-4 border-b px-2 pb-2">
            <AppTextButton
              className="col-start-2 text-base lg:text-lg"
              onClick={selectWeekdays}
            >
              Vardagar
            </AppTextButton>
            <AppTextButton
              className="col-start-2 text-base lg:text-lg"
              onClick={selectWeekends}
            >
              Helger
            </AppTextButton>
            <AppTextButton
              className="col-start-3 text-base text-opacity-50 lg:text-lg"
              onClick={() => {
                props.selectedDates.override({});
              }}
            >
              <span className="text-dark-gray text-opacity-60">Rensa</span>
            </AppTextButton>
          </span>
        )}

        <ul className="flex grow flex-col" role={"listbox"}>
          {activeDates().map((date) => {
            const time = date.getTime();
            const dateStr = new WorkerDateHelper(
              date
            ).weekdayDayOfMonthAndMonth();

            const merge = ctx.merger.getEntry(date);
            const selected = time in props.selectedDates.dict;

            const data = ctx.workingHoursRes.data;
            const item = data?.[date.getTime()];
            const notReported = !item;
            const isWorking = item?.status === "reported";

            return (
              <li
                className={ClientUtils.twClassNames(
                  "grid cursor-pointer grid-cols-[minmax(0,1fr),auto] items-center border-b p-1 py-2 transition-colors",
                  selected && " bg-main-bg-light/20"
                )}
                key={time}
                onClick={() => {
                  onDateClick(date);
                }}
                aria-selected={selected}
                role="option"
              >
                <span
                  className={ClientUtils.twClassNames(
                    "text-base",
                    isWorking && "font-semibold"
                  )}
                >
                  {dateStr}
                </span>
                <span
                  className={ClientUtils.twClassNames(
                    "text-sm text-dark-gray",
                    notReported && "text-opacity-70",
                    isWorking && "font-semibold"
                  )}
                >
                  {ctx.workingHoursRes.data &&
                    availabilityString(date, ctx.workingHoursRes.data)}
                </span>
                {!!merge.workOrders.length && (
                  <span className="text-xs italic text-dark-gray text-opacity-80">
                    Arbetsorder inplanerad
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </ScrollIndicatorContainer>
    </section>
  );
};

export default HandymanWorkingHoursSelectDatesSection;
