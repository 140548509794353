import { useContext, useRef } from "react";
import {
  FileUploadFilter,
  UseFileUploadProps,
  useFileInputAccept,
  useFileUpload,
} from "../../../hooks/file-upload-hooks";
import { IpisButton } from "@ipis/client-essentials";
import DelayedRenderImage from "../utils/DelayedRenderImage";
import AppMinusIcon from "../../icons/AppMinusIcon";
import ExtensionsAndFileSizeSpan from "../form-components/ExtensionsAndFileSizeSpan";
import { FormInputContext } from "@ipis/client-essentials";

interface Props<F extends FileUploadFilter = "all"> {
  heading?: string;
  id: string;
  as?: "div" | "section";
  fileUploadProps: Omit<UseFileUploadProps<F>, "ref">;
  placeholders?: number;
}

const SimpleImageUploadComponent = <F extends FileUploadFilter>(
  props: Props<F>
) => {
  const ctx = useContext(FormInputContext);
  const ref = useRef<HTMLInputElement | null>(null);
  const fileUploadReturn = useFileUpload({
    ...props.fileUploadProps,
    ref,
  });

  const accept = useFileInputAccept({
    allow: "images",
  });

  const As = props.as ?? "section";
  const buttonId = props.id + "-file-input-button";
  const hasPhotos = fileUploadReturn.hasFiles;
  const current = fileUploadReturn.currentFiles;

  const rawPlaceholderCount = props.placeholders ?? 0;
  const placeholderCount = Math.max(0, rawPlaceholderCount - current.length);
  const hasPlaceholders = placeholderCount > 0;
  const placeholders = Array.from({ length: placeholderCount }).map(
    (_, i) => i
  );

  const readOnly = !!props.fileUploadProps.onChange || ctx?.readOnly;

  if (readOnly && !hasPhotos) {
    return <></>;
  }

  return (
    <As className={"flex flex-col gap-2"}>
      {!readOnly && (
        <input
          id={props.id + "-file-input"}
          aria-describedby={buttonId}
          ref={ref}
          type="file"
          className="hidden"
          accept={accept}
          multiple
        />
      )}
      {!!props.heading && <h2 className="text-dark-950">{props.heading}</h2>}
      {!readOnly && (
        <IpisButton
          variant="tonal"
          className="mr-auto"
          label="Ladda upp bilder"
          icon={{
            type: "plus",
            position: "leading",
          }}
          onClick={fileUploadReturn.trigger}
        />
      )}
      {(hasPhotos || hasPlaceholders) && (
        <ul
          className="grid grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-2"
          aria-label="Uppladdade bilder"
        >
          {current.map((file, index) => {
            return (
              <li key={file.guid} className="relative">
                {file.state === "preupload" && (
                  <DelayedRenderImage
                    placeholderHeightInPx={128}
                    delayInMs={1000}
                    src={file.dataUrl}
                    alt={file.meta?.name ?? "Uppladdad bild"}
                    className="h-32 w-full object-cover"
                  />
                )}
                {file.state === "postupload" && (
                  <DelayedRenderImage
                    placeholderHeightInPx={128}
                    delayInMs={1000}
                    src={file.src}
                    alt={file.name}
                    className="h-32 w-full object-cover"
                  />
                )}
                {!readOnly && (
                  <button
                    className="absolute right-0 top-0 rounded-bl bg-white p-1"
                    onClick={() => {
                      fileUploadReturn.removeFile(file);
                    }}
                    type="button"
                    aria-label="Ta bort bild"
                  >
                    <AppMinusIcon className="rounded-full border-2 border-black" />
                  </button>
                )}
              </li>
            );
          })}
          {placeholders.map((_, i) => {
            return (
              <li key={i} aria-hidden className="h-32 w-full bg-gray-200" />
            );
          })}
        </ul>
      )}
      {!readOnly && (
        <ExtensionsAndFileSizeSpan
          accept={accept}
          maxSizeString={fileUploadReturn.maxSizeStr}
        />
      )}
    </As>
  );
};

export default SimpleImageUploadComponent;
