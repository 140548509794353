"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utils = void 0;
/*
  Not tree-shakeable, could be refactored.
*/
var Utils;
(function (Utils) {
    /*
      ARRAYS
    */
    function asArray(value) {
        return [].concat(value);
    }
    Utils.asArray = asArray;
    function intersection(a1, a2) {
        return a1.filter(function (value) { return a2.includes(value); });
    }
    Utils.intersection = intersection;
    function isArray(obj) {
        return Array.isArray(obj);
    }
    Utils.isArray = isArray;
    function arrayCombine(arrays) {
        return arrays.reduce(function (acc, curr) { return acc.concat(curr); }, []);
    }
    Utils.arrayCombine = arrayCombine;
    function groupBy(array, callback) {
        return array.reduce(function (acc, curr) {
            var key = callback(curr);
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        }, {});
    }
    Utils.groupBy = groupBy;
    function join(array, options) {
        var len = array.length;
        if (len === 0) {
            return "";
        }
        var separator = options.separator, beforeLast = options.beforeLast, oxford = options.oxford, custom = options.custom;
        return array.slice(1).reduce(function (accumulator, current, index) {
            if (custom) {
                var customReturn = custom(current, index, accumulator);
                if (customReturn !== undefined) {
                    return accumulator + customReturn + current;
                }
            }
            var lastElem = index === len - 2;
            if (lastElem && beforeLast) {
                if (oxford) {
                    return accumulator + separator + beforeLast + current;
                }
                else {
                    return accumulator + beforeLast + current;
                }
            }
            else {
                return accumulator + separator + current;
            }
        }, "" + array[0]);
    }
    Utils.join = join;
    /*
    OBJECTS
    */
    function objectCombine(objects) {
        return objects.reduce(function (acc, curr) { return Object.assign(acc, curr); }, {});
    }
    Utils.objectCombine = objectCombine;
    function entries(obj) {
        return Object.entries(obj);
    }
    Utils.entries = entries;
    function objectMap(obj, callback) {
        var entries = Utils.entries(obj).map(function (_a, i) {
            var k = _a[0], v = _a[1];
            return [k, callback(v, k, i)];
        });
        return Object.fromEntries(entries);
    }
    Utils.objectMap = objectMap;
    function objectKeyMap(obj, callback) {
        var entries = Utils.entries(obj).map(function (_a, i) {
            var k = _a[0], v = _a[1];
            return [callback(k, v, i), v];
        });
        return Object.fromEntries(entries);
    }
    Utils.objectKeyMap = objectKeyMap;
    function objectFilter(obj, callback) {
        var entries = Utils.entries(obj).filter(function (_a, i) {
            var k = _a[0], v = _a[1];
            return callback(v, k, i);
        });
        return Object.fromEntries(entries);
    }
    Utils.objectFilter = objectFilter;
    function isNotNullOrUndefined(obj) {
        var type = typeof obj;
        if (type === "boolean")
            return true;
        if (type === "number")
            return true;
        if (type === "string")
            return true;
        return !!obj;
    }
    Utils.isNotNullOrUndefined = isNotNullOrUndefined;
    function isNullOrUndefined(obj) {
        return !isNotNullOrUndefined(obj);
    }
    Utils.isNullOrUndefined = isNullOrUndefined;
    /*
    STRINGS
    */
    function capitalize(str) {
        return (str.charAt(0).toUpperCase() + str.slice(1));
    }
    Utils.capitalize = capitalize;
    /*
      Formatting
    */
    function formatPrice(price, props) {
        if (props === void 0) { props = {}; }
        var str = price.toFixed(2);
        if (props.useVerbosePricing) {
            var _a = str.split("."), whole = _a[0], decimal = _a[1];
            if (!decimal || decimal === "00") {
                return "".concat(whole, " kr");
            }
            else {
                return "".concat(whole, ",").concat(decimal, " kr");
            }
        }
        else {
            return "".concat(str, ":-");
        }
    }
    Utils.formatPrice = formatPrice;
})(Utils || (exports.Utils = Utils = {}));
