import ResursbankLogo from "../../components/external-brands/resursbank/ResursbankLogo";
import AppExternalLinkIcon from "../../components/icons/AppExternalLinkIcon";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import ClientUtils from "../../utils/ClientUtils";

interface Props {
  className?: string;
}

const ResursBankCooperationInfo = (props: Props) => {
  const website = "https://ipisverige.se/betalningsalternativ";
  const isSm = useMediaQuery(TailwindBreakpoint.sm);

  return (
    <section
      className={ClientUtils.classNames("flex flex-col gap-4", props.className)}
    >
      <header className="flex flex-col items-center border-b pb-2 sm:flex-row-reverse sm:justify-between">
        <ResursbankLogo width={isSm ? 100 : 80} />
        <h2 className="text-lg sm:text-xl">Samarbete med Resurs Bank</h2>
      </header>
      <main className="flex flex-col gap-4">
        <p>
          I samarbete med Resurs Bank erbjuder vi olika betalningssätt för
          privatpersoner. Du kan välja att dela upp din betalning, betala direkt
          via din bank eller få en faktura med upp till 60 dagars betalningstid.
          Oavsett vilket alternativ du väljer, är det alltid räntefritt hos oss.
        </p>
        <section className="flex flex-col gap-4">
          <h3 className="text-base sm:text-lg">Så här fungerar det</h3>
          <ul className="flex list-decimal flex-col gap-2 pl-6">
            <li>Vi utför ditt bokade uppdrag.</li>
            <li>Du får en sammanställning av det utförda arbetet.</li>
            <li>Välj betalsätt genom att följa länken i sammanställningen.</li>
          </ul>
          <footer className="flex flex-col gap-2 text-sm sm:flex-row sm:gap-4">
            <p>Vill du lära dig mer om våra betalningssätt?</p>
            <a
              className="flex items-center gap-2 text-blue-400"
              target="_blank"
              rel="noreferrer"
              href={website}
            >
              <AppExternalLinkIcon size={"sm"} />
              <span>Besök vår hemsida!</span>
            </a>
          </footer>
        </section>
      </main>
    </section>
  );
};

export default ResursBankCooperationInfo;
