"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandymanProvince = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var HandymanProvince;
(function (HandymanProvince) {
    HandymanProvince.Schema = centralized_zod_1.z.object({
        name: centralized_zod_1.z.string(),
        counties: centralized_zod_1.z.string().array(),
    });
})(HandymanProvince || (HandymanProvince = {}));
exports.HandymanProvince = HandymanProvince;
