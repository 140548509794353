import { useContext } from "react";
import TaskIcon from "../../../../components/icons/TaskIcon";
import ClientUtils from "../../../../utils/ClientUtils";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import WorkOrderOverviewCard from "./WorkOrderOverviewCard";

interface Props {
  className?: string;
}

const OverviewTaskSection = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);

  return (
    <WorkOrderOverviewCard
      className={ClientUtils.twClassNames(
        "grid h-full grid-cols-[auto,minmax(0,1fr)] grid-rows-[auto,minmax(0,1fr)] items-center gap-y-2 text-dark-gray",
        props.className
      )}
    >
      <span className="opacity flex rounded-full border bg-brand-blue-100 p-2 text-brand-blue-400">
        <TaskIcon size={40} />
      </span>
      <div className="flex flex-col p-4">
        <p className="text-sm">
          <span className="sr-only">Serienummer:</span>
          <span
            className="text-xs italic text-dark-gray text-opacity-80"
            data-test-id="work-order-serial-number"
          >
            #{order.serialNumber}
          </span>
        </p>

        <h3 className="text-base font-semibold">
          <span>Uppdrag</span>
        </h3>

        <p className="text-sm">
          <span className="sr-only">Typ av tjänst:</span>
          <span data-test-id="work-order-type-of-service">
            {order.typeOfService.name}
          </span>
        </p>
      </div>

      <div className="col-span-2 mb-auto">
        <span className="sr-only">Utförlig uppdragsbeskrivning:</span>
        <pre
          data-test-id="work-order-additional-information"
          className={ClientUtils.twClassNames(
            "overflow-auto whitespace-pre-wrap p-2",
            !order.additionalInformation &&
              "text-sm text-dark-gray text-opacity-90"
          )}
        >
          {order.additionalInformation ||
            "Ingen ytterligare information angedd"}
        </pre>
      </div>
    </WorkOrderOverviewCard>
  );
};

export default OverviewTaskSection;
