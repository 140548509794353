import { HandymanWorkingHours, WorkOrder } from "@eljouren/domain/build";
import { WorkOrdersAndWorkingHoursMerger } from "@eljouren/utils/build";
import React from "react";
import { UseMutationResult, UseQueryResult } from "react-query";

const HandymanContext = React.createContext<{
  weeksInPeriod: number;
  workOrderRes: UseQueryResult<WorkOrder.HandymanWithPermissionsType[]>;
  workingHoursRes: UseQueryResult<
    HandymanWorkingHours.DictIndexedByStartOfDayType,
    unknown
  >;
  saveWorkingHours: UseMutationResult<
    void,
    unknown,
    HandymanWorkingHours.DictIndexedByStartOfDayType,
    unknown
  >;
  isLoading: boolean;
  setPage(page: number): void;
  setWeeksInPeriod(weeksInPeriod: number): void;
  incrementPage(): void;
  decrementPage(): void;
  page: number;
  merger: WorkOrdersAndWorkingHoursMerger;
}>({} as never);

export default HandymanContext;
