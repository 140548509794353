import { WorkOrder } from "@eljouren/domain/build";
import { DateHelper, Utils } from "@eljouren/utils";
import { useContext } from "react";
import HandymanWorkOrderCheckInOutContext from "../../../../../routes/worker/order/contexts/HandymanWorkOrderCheckInOutContext";
import HandymanWorkOrderRouteContext from "../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "./../../../../../utils/ClientUtils";

import ConditionalCheckInOutButton from "../../../../../routes/worker/order/ConditionalCheckInOutButton";
import SaveAndGoBackFooter from "../../../../SaveAndGoBackFooter";
import { useAddToBackButtonStack } from "../../../../common/navigation/use-back-button-stack";
import AppClockIcon from "../../../../icons/AppClockIcon";
import AppDoorIcon from "../../../../icons/AppDoorIcon";

interface Props {
  className?: string;
  goBack(): void;
}

const WorkOrderCheckInOutSection = (props: Props) => {
  useAddToBackButtonStack(props.goBack);
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const ctx = useContext(HandymanWorkOrderCheckInOutContext);
  const latest = order.workPeriods.at(-1);

  const showCheckInOutButton = !order.isFinished && ctx.canCheckInOut;
  const showLatest = !!latest;
  const showTopSection = showCheckInOutButton || showLatest;
  let rows = 1;
  if (showTopSection) {
    rows++;
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        props.className,
        "grid h-full w-full grid-rows-[minmax(0,1fr),auto]"
      )}
    >
      {/* <header>
          <h3 className="text-lg font-bold">In-/utcheckningar</h3>
        </header> */}
      <main
        className={ClientUtils.classNames(
          "grid h-full w-full grid-cols-1 p-4",
          rows === 1 && "grid-rows-1",
          rows === 2 && "grid-rows-[auto,minmax(0,1fr)]"
        )}
      >
        {showTopSection && (
          <section className="flex flex-col">
            {showCheckInOutButton && (
              <ConditionalCheckInOutButton
                className={ClientUtils.twClassNames(
                  !!latest && "rounded-b-none"
                )}
              />
            )}
            {!!latest && (
              <span
                className={ClientUtils.twClassNames(
                  "flex flex-col gap-2 rounded-b border border-main-bg-light p-4",
                  !ctx.canCheckInOut && "rounded-t border-t",
                  ctx.canCheckInOut && "border-t-0"
                )}
                id="latest-work-period"
              >
                <span
                  className="flex items-center gap-2"
                  id="latest-work-period-check-in"
                >
                  <AppDoorIcon direction="enter" size={20} />
                  <span>
                    {new DateHelper(latest.checkIn.date).readableDateTime}
                  </span>
                </span>
                <span
                  className="flex items-center gap-2"
                  id="latest-work-period-check-out"
                >
                  <AppDoorIcon direction="exit" size={20} />
                  <span>
                    {!latest.checkOut && "Ej utcheckad"}
                    {!!latest.checkOut &&
                      new DateHelper(latest.checkOut.date).readableDateTime}
                  </span>
                </span>
                <DurationSpan period={latest} />
              </span>
            )}
          </section>
        )}
        <section className="grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto] gap-2 pt-4">
          <header className="border-b pb-1">
            <h3>Alla in-/utcheckningar</h3>
          </header>
          <main className="h-full overflow-auto">
            {order.workPeriods.length === 0 && order.isFinished && (
              <p>Inga in-/utcheckningar registrerade.</p>
            )}
            {order.workPeriods.length === 0 && !order.isFinished && (
              <p>Inga in-/utcheckningar registrerade hittils.</p>
            )}
            {order.workPeriods.length > 1 && (
              <ul className="flex h-full flex-col rounded">
                {order.workPeriods.map((period, i) => {
                  const readableCheckIn = new DateHelper(period.checkIn.date)
                    .readableDateTime;
                  const readableCheckOut = period.checkOut
                    ? new DateHelper(period.checkOut.date).readableDateTime
                    : "Ej utcheckad";

                  const duration =
                    !!period.checkOut?.date &&
                    new DateHelper(period.checkIn.date).formatDistance(
                      period.checkOut.date
                    );

                  return (
                    <li
                      key={i}
                      data-test-type="work-period-item"
                      className={ClientUtils.twClassNames(
                        "grid grid-cols-[min-content,min-content,min-content] grid-rows-[auto,auto] gap-1 p-2",
                        "border-x last:border-b odd:bg-gray-300"
                      )}
                    >
                      <span
                        className="whitespace-nowrap text-sm font-semibold"
                        data-test-type="work-period-item-check-in"
                      >
                        {readableCheckIn}
                      </span>
                      <span className="text-sm">-</span>
                      <span
                        className="whitespace-nowrap text-sm font-semibold"
                        data-test-type="work-period-item-check-out"
                      >
                        {readableCheckOut}
                      </span>
                      {duration && (
                        <span className="col-span-3 text-sm">
                          {Utils.capitalize(duration)}
                        </span>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </main>
          {order.workPeriods.length > 1 && (
            <footer>
              <span className="text-sm font-semibold">Total tid:</span>
              <span className="text-sm"> {ctx.formatedTotalDuration}</span>
            </footer>
          )}
        </section>
      </main>
      <SaveAndGoBackFooter
        onGoBack={props.goBack}
        readonly
        isDisabled={false}
        isLoading={false}
      />
    </section>
  );
};

const DurationSpan = (props: { period: WorkOrder.WorkPeriodType }) => {
  const { period } = props;

  if (!period.checkOut) {
    return <></>;
  }

  const duration = new DateHelper(period.checkIn.date).formatDistance(
    period.checkOut.date
  );

  return (
    <span className="flex items-center gap-2">
      <AppClockIcon size={20} />
      <span>{duration}</span>
    </span>
  );
};

export default WorkOrderCheckInOutSection;
