"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadedFileReturnInfo = void 0;
var zod_1 = require("zod");
var FileInputInfo_1 = require("./FileInputInfo");
var CollectionType_1 = require("../../../../shared-schemas/CollectionType");
var FileTag_1 = require("../../../domain/FileTag");
var UploadedFileReturnInfo;
(function (UploadedFileReturnInfo) {
    UploadedFileReturnInfo.Schema = FileInputInfo_1.FileInputInfo.Schema.extend({
        url: zod_1.z.string(),
        idWithExtension: zod_1.z.string(),
        collectionType: CollectionType_1.CollectionType.Schema,
        tag: FileTag_1.FileTag.Schema.nullable(),
        size: zod_1.z.number(),
    });
})(UploadedFileReturnInfo || (UploadedFileReturnInfo = {}));
exports.UploadedFileReturnInfo = UploadedFileReturnInfo;
