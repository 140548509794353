import { useContext } from "react";
import { useQuery } from "react-query";
import { SignedInContext } from "../../../../components/auth/hocs/withWorkerCredentials";
import MyLink from "../../../../components/routing/MyLink";
import { useRepos } from "../../../../hooks/use-repos";
import AppRoutes from "../../../../routes";
import AppCompanyIcon from "../../../../components/icons/AppCompanyIcon";

interface Props {
  className?: string;
}

const LandingPageCompanyWidget = (props: Props) => {
  const { companyRepo } = useRepos();
  const ctx = useContext(SignedInContext);

  const employeeRes = useQuery(
    [
      "employees",
      ctx.handyman.companyId,
      ctx.handyman.id,
      ctx.handyman.companyRole,
    ],
    () => {
      if (ctx.handyman.companyRole !== "supervisor") {
        return [];
      }
      return companyRepo.fetchEmployees();
    }
  );

  if (ctx.handyman.companyRole !== "supervisor") {
    return <></>;
  }

  return (
    <MyLink
      to={AppRoutes.partner.company()}
      className="hidden grid-cols-[auto,auto] grid-rows-[auto,auto,auto] items-center gap-x-4 rounded p-2 3xl:grid"
    >
      <AppCompanyIcon
        size={50}
        className="row-span-full rounded-full border bg-slate-100 p-2"
      />
      <h3 className="text-base">{ctx.handyman.companyName}</h3>
      {employeeRes.data && (
        <span className="text-sm">{employeeRes.data.length} anställda</span>
      )}
    </MyLink>
  );
};

export default LandingPageCompanyWidget;
