import {
  IconChevronDown,
  IconChevronUp,
  IconChevronLeft,
  IconChevronRight,
} from "@tabler/icons-react";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  direction: "up" | "down" | "left" | "right";
}

const AppChevronIcon = (props: Props) => {
  const { className, direction, ...rest } = props;

  switch (direction) {
    case "up":
      return <IconChevronUp className={className} {...rest} />;
    case "down":
      return <IconChevronDown className={className} {...rest} />;
    case "left":
      return <IconChevronLeft className={className} {...rest} />;
    case "right":
      return <IconChevronRight className={className} {...rest} />;
  }
};
export default withIconSize(AppChevronIcon);
