"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileMetaInput = void 0;
var zod_1 = require("zod");
var FileMetaInput;
(function (FileMetaInput) {
    /*
      Needs to add min and max lengths
    */
    FileMetaInput.Schema = zod_1.z.object({
        name: zod_1.z.string().optional(),
        description: zod_1.z.string().optional(),
        customName: zod_1.z.string().optional(),
        //tag: FileMeta.FileTagSchema.optional(),
    });
})(FileMetaInput || (FileMetaInput = {}));
exports.FileMetaInput = FileMetaInput;
