"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyUploadSingleFileResponse = void 0;
var FileServerUploadSingleFileResponse_1 = require("../../file-server-routes/output/FileServerUploadSingleFileResponse");
var FileMetaInput_1 = require("../../shared-schemas/FileMetaInput");
var OperationResults_1 = require("../../shared-schemas/OperationResults");
var zod_1 = require("zod");
var ProxyUploadSingleFileResponse;
(function (ProxyUploadSingleFileResponse) {
    ProxyUploadSingleFileResponse.Schema = FileServerUploadSingleFileResponse_1.FileServerUploadSingleFileResponse.Schema.extend({
        operations: OperationResults_1.OperationResults.UploadSchema,
        meta: FileMetaInput_1.FileMetaInput.Schema.optional(),
        message: zod_1.z.string().optional(),
        metaErrorMessage: zod_1.z.string().optional(),
    });
})(ProxyUploadSingleFileResponse || (ProxyUploadSingleFileResponse = {}));
exports.ProxyUploadSingleFileResponse = ProxyUploadSingleFileResponse;
