"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdTestOptions = void 0;
var BankIdCollectResponse_1 = require("../BankIdCollectResponse");
var zod_1 = require("zod");
var BankIdTestOptions;
(function (BankIdTestOptions) {
    var BaseResponse = zod_1.z.object({
        response: zod_1.z.enum(["success", "thrownError"]),
    });
    var CollectResponse = zod_1.z.union([
        zod_1.z.literal("thrownError"),
        BankIdCollectResponse_1.BankIdCollectResponse.Schema,
    ]);
    BankIdTestOptions.Schema = zod_1.z.object({
        requestDelay: zod_1.z.number().optional(),
        cancel: BaseResponse.optional(),
        sign: BaseResponse.optional(),
        auth: BaseResponse.optional(),
        /*
          We should make sure only the responses that makes sense for each particular stage is allowed
        */
        collect: zod_1.z
            .object({
            immediate: zod_1.z
                .object({
                response: CollectResponse,
            })
                .optional(),
            afterOpenApp: zod_1.z
                .object({
                response: CollectResponse,
            })
                .optional(),
            afterSign: zod_1.z
                .object({
                response: CollectResponse,
            })
                .optional(),
        })
            .optional(),
    });
})(BankIdTestOptions || (BankIdTestOptions = {}));
exports.BankIdTestOptions = BankIdTestOptions;
