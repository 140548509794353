import KeyboardShortcutAppTileButton from "../../../../../__v2__/buttons/KeyboardShortcutAppTileButton";
import { AppIconIdentifier } from "../../../../../icons/AppIconRouter";

export type ChecklistQuestionFormType =
  | "text"
  | "multiple-choice"
  | "image-group"
  | "information"
  | "yes/no"
  | "number";

interface Props {
  className?: string;
  type: ChecklistQuestionFormType;
  onClick: (type: ChecklistQuestionFormType) => void;
  index: number;
}

const AddQuestionListItem = (props: Props) => {
  function getLabel() {
    switch (props.type) {
      case "text":
        return "Textfält";
      case "multiple-choice":
        return "Flervalsalternativ";
      case "image-group":
        return "Bildgrupp";
      case "information":
        return "Informationsfält";
      case "yes/no":
        return "Ja/Nej";
      case "number":
        return "Nummerfält";
    }
  }

  function getIcon(): AppIconIdentifier {
    switch (props.type) {
      case "text":
        return "text-field";
      case "multiple-choice":
        return "multiple-choice-field";
      case "image-group":
        return "image-group-field";
      case "information":
        return "information";
      case "yes/no":
        return "yes-no-field";
      case "number":
        return "number-field";
    }
  }

  return (
    <li className="flex w-full flex-col">
      <KeyboardShortcutAppTileButton
        keyboardShortcut={{
          key: (props.index + 1).toString() as any,
          ctrlKey: true,
          preventDefault: true,
        }}
        buttonProps={{
          label: getLabel(),
          onClick: () => props.onClick(props.type),
          icon: { type: getIcon(), position: "leading" },
          className: "w-full",
        }}
      />
    </li>
  );
};

export default AddQuestionListItem;
