import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import withHandymanCredentials from "../../../components/auth/hocs/withWorkerCredentials";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import AppPage from "../../../components/layout/AppPage";
import { useBundledState } from "../../../hooks/hooks";
import AppRoutes from "../../../routes";
import HandymanCompanyEmployeeTab from "./HandymanCompanyEmployeeTab";
import HandymanCompanyInvoiceTab from "./HandymanCompanyInvoiceTab";
import HandymanCompanyRouteContext from "./HandymanCompanyRouteContext";
import TabPanel from "../../../components/common/tabs/TabPanel";
import { AnimatePresence } from "framer-motion";

enum Tab {
  employees = "Anställda",
  invoiceBasis = "Fakturaunderlag",
}

interface Props extends TSignedInWorkerState {
  className?: string;
}

const HandymanCompanyRoute = (props: Props) => {
  const inCharge = props.handyman.companyRole === "supervisor";

  const navigate = useNavigate();

  useEffect(() => {
    if (!inCharge) {
      navigate(AppRoutes.partner.root());
    }
  }, [inCharge, navigate]);

  if (inCharge) {
    return (
      <HandymanCompanyRouteContext.Provider value={props}>
        <HandymanCompanyRouteInCharge {...props} />
      </HandymanCompanyRouteContext.Provider>
    );
  } else {
    return <AppLoader />;
  }
};

interface InChargeProps extends TSignedInWorkerState {}

const HandymanCompanyRouteInCharge = (props: InChargeProps) => {
  const tab = useBundledState(Tab.employees);
  const { companyName } = props.handyman;

  return (
    <AppPage
      headerProps={{
        heading: tab.value,
        subheading: companyName,
      }}
      tabProps={{
        tabs: Object.values(Tab),
        selectedTab: tab.value,
        onSelect: (newTab) => tab.set(newTab as Tab),
      }}
    >
      <AnimatePresence exitBeforeEnter initial={false}>
        <TabPanel key={tab.value}>
          {tab.value === Tab.employees && <HandymanCompanyEmployeeTab />}
          {tab.value === Tab.invoiceBasis && <HandymanCompanyInvoiceTab />}
        </TabPanel>
      </AnimatePresence>
    </AppPage>
  );
};

export default withHandymanCredentials(HandymanCompanyRoute);
