import { IconBaseProps } from "react-icons/lib";
import { MdSupportAgent } from "react-icons/md";

interface Props extends IconBaseProps {
  className?: string;
}

/* 
  Can't find a support agent icon in heroicons, so we use the support agent icon from react-icons temporarily
*/
const SupportAgentIcon = (props: Props) => {
  const { className, ...rest } = props;

  return <MdSupportAgent {...rest} className={props.className} />;
};
export default SupportAgentIcon;
