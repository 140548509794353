import React from "react";
import MyDialog from "../common/MyDialog";
import { useSessionActive } from "../../hooks/session-hooks";
import { useRepos } from "../../hooks/use-repos";
import { AppButton } from "../common/buttons/AppButton";

interface Props {
  children?: React.ReactNode;
}

const SessionTimeoutOverlay = (props: Props) => {
  const { authRepo } = useRepos();
  const activeSession = useSessionActive();

  function onClick() {
    authRepo.signOut();
  }

  const disabled = true;
  if (disabled) {
    return <>{props.children}</>;
  }

  if (process.env.NODE_ENV !== "development") {
    return <>{props.children}</>;
  }

  return (
    <>
      {props.children}
      <MyDialog isOpen={!activeSession}>
        <section className="mx-4 flex flex-col gap-2 rounded bg-white/80 p-4 text-black backdrop-blur md:p-8">
          <h2 className="sm:text-lg md:text-xl">
            Du verkar ha blivit utloggad
          </h2>
          <AppButton onClick={onClick}>Logga in på nytt</AppButton>
        </section>
      </MyDialog>
    </>
  );
};

export default SessionTimeoutOverlay;
