import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import MyDialog from "../../../../../components/common/MyDialog";
import CustomerWorkOrderInvoiceContext from "../../CustomerWorkOrderInvoiceContext";
import PaymentFlowCustomerServiceModal from "../../payment-method-components/PaymentFlowCustomerServiceModal";

const InvoicePaymentYourDetailsMain = () => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);
  const workOrder = ctx.workOrder;
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  function closeModal() {
    setShowModal(false);
  }

  if (!workOrder) {
    return <Skeleton height={200} />;
  }

  const contact = workOrder.contact;

  /* 
	  Location is the location of the work order and might not be the address
	  for the customer
	*/
  /* const address = `${location.street}, ${location.postalCode} ${location.city}`; */

  return (
    <main className="border-rounded flex flex-col rounded border p-4 sm:grid sm:grid-cols-[minmax(0,1fr),auto] sm:grid-rows-[auto,minmax(0,1fr)]">
      <h3 className="col-start-1">{contact.fullName}</h3>

      <p className="col-span-2 col-start-1 row-start-2 text-sm font-semibold text-dark-gray text-opacity-70 sm:text-base">
        Inte du? För att eventuellt rot- eller grön teknik-avdrag ska gälla
        behöver betalningen ske av samma person som avdraget är beviljat för.
      </p>
      <button
        type="button"
        onClick={toggleModal}
        className="col-start-2 row-start-1 ml-auto pt-4 text-sm text-black underline sm:pt-0"
      >
        Kontakta kundservice
      </button>
      <MyDialog isOpen={showModal} cancel={closeModal}>
        <PaymentFlowCustomerServiceModal close={closeModal} />
      </MyDialog>
    </main>
  );
};

export default InvoicePaymentYourDetailsMain;
