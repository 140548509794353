import React from "react";
import SortedBookingSuggestions from "./SortedBookingSuggestions";
import { BookingSuggestions } from "@eljouren/domain";

export type BookingSuggestionsSortOption =
  | "dateThenScore"
  | "scoreFirst"
  | "handymanThenScore";

const BookingSuggestionsContext = React.createContext<{
  sortedBookingSuggestions: SortedBookingSuggestions;
  suggestions: BookingSuggestions.BaseType;
  sortOption: BookingSuggestionsSortOption;
  simplifiedView: boolean;
}>({} as never);

export default BookingSuggestionsContext;
