import { PaymentOption } from "@eljouren/domain";
import { UseFormReturn } from "react-hook-form";
import InvoicePaymentOptionUnboundFormElement from "./InvoicePaymentUnboundFormElement";
import { PaymentOptionsFormValues } from "../CustomerWorkOrderInvoiceContext";
import { AppFormTextField } from "../../../../components/common/text-fields/AppFormTextField";

interface Props {
  option: PaymentOption.Type;
  form: UseFormReturn<PaymentOptionsFormValues>;
}

const InvoicePaymentOptionFormElement = (props: Props) => {
  const isSelected = props.form.watch("selectedOptionId") === props.option.id;
  const isInternal = props.option.brand === "ipis";

  return (
    <InvoicePaymentOptionUnboundFormElement
      id={props.option.id}
      inputProps={{
        ...props.form.register("selectedOptionId"),
        value: props.option.id,
      }}
      isSelected={isSelected}
      method={props.option}
      select={() => {
        props.form.setValue("selectedOptionId", props.option.id);
      }}
      AdditionalInput={
        isInternal
          ? () => {
              return (
                <AppFormTextField
                  htmlAttributes={{
                    type: "email",
                  }}
                  register={props.form.register}
                  name="invoiceEmail"
                  label="E-post för faktura"
                />
              );
            }
          : undefined
      }
    />
  );
};

export default InvoicePaymentOptionFormElement;
