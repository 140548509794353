import React from "react";
import A11yDropdown from "../common/dropdown/A11YDropdown";
import { Utils } from "@eljouren/utils";
import ClientUtils from "../../utils/ClientUtils";

interface Props {
  label: string;
  price: number;
  final?: boolean;
  children?: React.ReactNode;
  openByDefault?: boolean;
  useVerbosePricing?: boolean;
}

const ExpandablePriceListItem = (props: Props) => {
  const isNegative = props.price < 0;
  const hasChildren = !!props.children;

  return (
    <li
      className={ClientUtils.classNames(
        "grid grid-cols-[1fr,auto,50px] border-b px-2 py-2 text-sm last:border-b-0 xs:text-base",
        //props.useVerbosePricing && "grid-rows-2",
        props.final && "font-semibold",
        hasChildren && "cursor-pointer"
      )}
    >
      {hasChildren && (
        <A11yDropdown
          preventHideOnOutsideClick
          contentId="additional-information-dropdown"
          className="col-span-3 w-full gap-2"
          buttonClassName="grid grid-cols-[1fr,50px]"
          initiallyOpen={props.openByDefault}
          title={() => {
            return (
              <span
                className={ClientUtils.classNames(
                  "grid w-full grow grid-cols-[1fr,auto]",
                  isNegative && "text-green-700"
                )}
              >
                <span
                  className={ClientUtils.classNames(
                    props.useVerbosePricing && "col-span-2 mr-auto"
                  )}
                >
                  {props.label}
                </span>
                <span
                  className={ClientUtils.classNames(
                    "font-opacity-80 italic text-dark-gray",
                    props.useVerbosePricing &&
                      "col-span-2 col-start-1 row-start-2 mr-auto"
                  )}
                >
                  {Utils.formatPrice(props.price, {
                    useVerbosePricing: props.useVerbosePricing,
                  })}
                </span>
              </span>
            );
          }}
          contentWrapperClassName="text-sm"
        >
          {props.children}
        </A11yDropdown>
      )}

      {!hasChildren && (
        <>
          <span
            className={ClientUtils.classNames(
              isNegative && "text-green-700",
              props.useVerbosePricing && "col-span-2 mr-auto"
            )}
          >
            {props.label}
          </span>
          <span
            className={ClientUtils.classNames(
              isNegative && "text-green-700",
              "font-opacity-80 italic text-dark-gray",
              props.useVerbosePricing &&
                "col-span-2 col-start-1 row-start-2 mr-auto"
            )}
          >
            {Utils.formatPrice(props.price, {
              useVerbosePricing: props.useVerbosePricing,
            })}
          </span>
        </>
      )}
    </li>
  );
};

export default ExpandablePriceListItem;
