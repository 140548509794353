import { MotionProps, motion } from "framer-motion";
import { InvoiceRouteView } from "../CustomerWorkOrderInvoiceContext";
import InvoiceViewCustomerServiceFooter from "../payment-method-components/InvoiceViewCustomerServiceFooter";

interface Props {
  className?: string;
  motionProps: MotionProps;
  view:
    | InvoiceRouteView.failedToConfirmFailure
    | InvoiceRouteView.failedToConfirmSuccess;
  style: React.CSSProperties;
}

const InvoiceFailedToConfirmView = (props: Props) => {
  if (props.view === InvoiceRouteView.failedToConfirmFailure) {
    return (
      <motion.section
        className="mx-auto flex w-full max-w-screen-sm flex-col gap-4 rounded border p-4 shadow sm:p-4 md:p-8"
        {...props.motionProps}
        style={props.style}
      >
        <h2 className="text-lg">
          Vi kan inte bekräfta att din betalning inte gick igenom.
        </h2>
        <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
          <p>Om du tror att det är ett fel, vänligen kontakta kundservice.</p>
        </main>
        <InvoiceViewCustomerServiceFooter
          mainActionLabel="Börja om"
          mainActionCallback={"restart"}
        />
      </motion.section>
    );
  }

  return (
    <motion.section
      className="mx-auto flex w-full max-w-screen-sm flex-col gap-4 rounded border p-4 shadow sm:p-4 md:p-8"
      {...props.motionProps}
      style={props.style}
    >
      <h2 className="text-lg">
        Vi kan inte bekräfta att din betalning gick igenom som den skulle.
      </h2>
      <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
        <p>Om du tror att det är ett fel, vänligen kontakta kundservice.</p>
      </main>
      <InvoiceViewCustomerServiceFooter
        mainActionLabel="Börja om"
        mainActionCallback={"restart"}
      />
    </motion.section>
  );
};

export default InvoiceFailedToConfirmView;
