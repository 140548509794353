import { Handyman } from "@eljouren/domain/build";
import { useContext } from "react";
import WorkOrderChecklistImageModule from "../../../../routes/worker/order/checklist-images/WorkOrderChecklistImagesModule";
import HandymanWorkOrderChecklistModule from "../../../../routes/worker/order/checklist/HandymanWorkOrderChecklistModule";
import HandymanWorkOrderRouteContext, {
  WorkOrderControlModule,
  WorkOrderTabs,
} from "../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import AppPages from "../../../common/pages/AppPages";
import AppArrowIcon from "../../../icons/AppArrowIcon";
import AppCheckmarkIcon from "../../../icons/AppCheckmarkIcon";
import AppXIcon from "../../../icons/AppXIcon";
import ClientUtils from "./../../../../utils/ClientUtils";
import WorkOrderControlsFooter from "./WorkOrderControlsFooter";
import WorkOrderCheckInOutSection from "./modules/WorkOrderCheckInOutSection";
import WorkOrderControlsActionSection from "./modules/WorkOrderControlsActionSection";
import WorkOrderExternalReferenceSection from "./modules/WorkOrderExternalReferenceSection";
import WorkOrderLineItemsSection from "./modules/extra-material/WorkOrderLineItemsSection";

export type CustomerOrderWorkerControlsProps = {
  className?: string;
  //order: WorkOrder.HandymanType;
  worker?: Handyman.Type;
  checkIn?(): void;
  checkOut?(): void;
};

const WorkOrderControls = (props: CustomerOrderWorkerControlsProps) => {
  const ctx = useContext(HandymanWorkOrderRouteContext);

  function goBack() {
    ctx.navigateTo({
      tab: WorkOrderTabs.handle,
      module: WorkOrderControlModule.home,
    });
  }

  function setPage(page: WorkOrderControlModule) {
    ctx.navigateTo({
      tab: WorkOrderTabs.handle,
      module: page,
    });
  }

  const page = ctx.currentView.module ?? WorkOrderControlModule.home;

  const imageElementCount =
    ctx.checklistRes.query.data?.images.totalNumberOfElements;
  const checklistHasImages = imageElementCount && imageElementCount > 0;

  return (
    <section
      className={ClientUtils.twClassNames(
        "grid h-full w-full grid-cols-1 grid-rows-1 overflow-x-hidden xl:border-l xl:bg-slate-100",
        props.className
      )}
    >
      <AppPages
        pageIndex={page}
        onlyMain
        mainGridRow={1}
        className={ClientUtils.twClassNames(
          "flex h-full w-full flex-col",
          props.className
        )}
      >
        {page === WorkOrderControlModule.home && (
          <>
            <main className="flex flex-col pb-4">
              <ul className="flex flex-col divide-y border-b">
                <CheckInOutNavigationItem onClick={setPage} />
                <ActionNavigationItem onClick={setPage} />
                <OrderlinesNavigationItem onClick={setPage} />
                <ChecklistNavigationItem onClick={setPage} />
                {!!checklistHasImages && (
                  <RequiredImagesNavigationItem onClick={setPage} />
                )}
                {!!ctx.order.serviceContract && (
                  <ExternalReferenceNavigationItem onClick={setPage} />
                )}
              </ul>
            </main>
            <WorkOrderControlsFooter />
          </>
        )}
        {page === WorkOrderControlModule.checkInOut && (
          <WorkOrderCheckInOutSection goBack={goBack} />
        )}
        {page === WorkOrderControlModule.action && (
          <WorkOrderControlsActionSection goBack={goBack} />
        )}
        {/* {page === WorkOrderControlModule.hours && (
          <WorkOrderExtraHoursSection goBack={goBack} />
        )} */}
        {page === WorkOrderControlModule.lineItems && (
          <WorkOrderLineItemsSection goBack={goBack} />
        )}
        {/*   {page === WorkOrderControlModule.materialReview && (
          <WorkOrderMaterialReviewSection goBack={goBack} />
        )} */}
        {page === WorkOrderControlModule.checklist && (
          <HandymanWorkOrderChecklistModule goBack={goBack} />
        )}
        {page === WorkOrderControlModule.requiredImages && (
          <WorkOrderChecklistImageModule goBack={goBack} />
        )}
        {page === WorkOrderControlModule.externalReference && (
          <WorkOrderExternalReferenceSection goBack={goBack} />
        )}
      </AppPages>
    </section>
  );
};

interface NavigationItemProps {
  page: WorkOrderControlModule;
  label: string;
  icon: "finished" | "in-progress" | "none";
  //subtitle?: string | (() => JSX.Element) | JSX.Element;
  onClick(page: WorkOrderControlModule): void;
  isError?: boolean;
}

type KnownNavigationItemProps = Pick<NavigationItemProps, "onClick">;

const CheckInOutNavigationItem = (props: KnownNavigationItemProps) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const latest = order.workPeriods.at(-1);
  const atleastOneWorkPeriod = order.workPeriods.length > 0;
  const isCheckingOut = !!latest && !latest.checkOut;

  const isSuccess = atleastOneWorkPeriod && !isCheckingOut;
  const isInProgress = !atleastOneWorkPeriod || isCheckingOut;

  return (
    <NavigationItem
      page={WorkOrderControlModule.checkInOut}
      onClick={props.onClick}
      label="In- och utcheckning"
      icon={isSuccess ? "finished" : isInProgress ? "in-progress" : "none"}
      //subtitle={checkInOutSubtitle}
    />
  );
};

const ActionNavigationItem = (props: KnownNavigationItemProps) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const action = order.actionDescription;
  const note = order.internalActionNote;

  const bothSubmitted = !!action && !!note;
  const onlyActionSubmitted = !!action && !note;
  const isSuccessful = bothSubmitted || onlyActionSubmitted;

  return (
    <NavigationItem
      page={WorkOrderControlModule.action}
      onClick={props.onClick}
      icon={isSuccessful ? "finished" : "in-progress"}
      label="Åtgärd"
      //subtitle={actionSubtitle}
    />
  );
};

const OrderlinesNavigationItem = (props: KnownNavigationItemProps) => {
  return (
    <NavigationItem
      page={WorkOrderControlModule.lineItems}
      onClick={props.onClick}
      //subtitle={subtitle}
      label="Orderrader"
      icon="none"
    />
  );
};

const ChecklistNavigationItem = (props: KnownNavigationItemProps) => {
  const { checklistRes, order } = useContext(HandymanWorkOrderRouteContext);

  if (!order.checklist) {
    return <></>;
  }

  const isError = checklistRes.query.isError;

  const questions = checklistRes.query.data?.questions;
  const allRequiredQuestionsAnswered = questions?.allRequiredAnswered;
  const atLeastOneQuestionAnswered = !!questions?.totalNumberOfAnsweredElements;
  const count = questions?.totalNumberOfElements;
  const answeredCount = questions?.totalNumberOfAnsweredElements;

  let label: string;
  if (allRequiredQuestionsAnswered || !questions) {
    label = `Checklista`;
  } else {
    label = `Checklista (${answeredCount}/${count})`;
  }

  let icon: NavigationItemProps["icon"] = "none";
  if (allRequiredQuestionsAnswered && atLeastOneQuestionAnswered) {
    icon = "finished";
  } else if (!allRequiredQuestionsAnswered) {
    icon = "in-progress";
  }

  return (
    <NavigationItem
      isError={isError}
      onClick={props.onClick}
      page={WorkOrderControlModule.checklist}
      //subtitle={subtitle}
      label={label}
      icon={icon}
    />
  );
};

const RequiredImagesNavigationItem = (props: KnownNavigationItemProps) => {
  const checklistRes = useContext(HandymanWorkOrderRouteContext).checklistRes;

  const images = checklistRes.query.data?.images;
  const allRequiredImagesAnswered = images?.allRequiredAnswered;
  const atLeastOneImageAnswered = !!images?.totalNumberOfAnsweredElements;
  const count = images?.totalNumberOfElements;
  const answeredCount = images?.totalNumberOfAnsweredElements;

  let label: string;
  if (allRequiredImagesAnswered) {
    label = `Bilduppladdning`;
  } else {
    label = `Bilduppladdning (${answeredCount}/${count})`;
  }

  let icon: NavigationItemProps["icon"] = "none";
  if (allRequiredImagesAnswered && atLeastOneImageAnswered) {
    icon = "finished";
  } else if (!allRequiredImagesAnswered) {
    icon = "in-progress";
  }

  return (
    <NavigationItem
      onClick={props.onClick}
      page={WorkOrderControlModule.requiredImages}
      label={label}
      icon={icon}
    />
  );
};

const ExternalReferenceNavigationItem = (props: KnownNavigationItemProps) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);

  return (
    <NavigationItem
      onClick={props.onClick}
      page={WorkOrderControlModule.externalReference}
      label="Externt referensnummer"
      icon={!!order.externalReference ? "finished" : "none"}
    />
  );
};

const NavigationItem = (props: NavigationItemProps) => {
  return (
    <li className="w-full">
      <button
        className={ClientUtils.classNames(
          "grid w-full cursor-pointer grid-rows-[min-content,min-content] items-center gap-x-4 border-b p-2 py-3 last:border-b-0 hover:bg-main-bg-light/20 md:p-4",
          "grid-cols-[minmax(0,1fr),min-content]"
        )}
        onClick={() => props.onClick(props.page)}
      >
        <span
          className={ClientUtils.twClassNames(
            "flex items-center gap-2 font-semibold"
          )}
        >
          <span>{props.label}</span>
          {props.icon === "finished" && (
            <AppCheckmarkIcon className="row-span-2" size={"sm"} />
          )}
          {props.icon === "in-progress" && (
            <AppXIcon className="row-span-2" size="sm" />
          )}
        </span>
        <AppArrowIcon direction="right" size={20} className="row-span-2" />
      </button>
    </li>
  );
};

export default WorkOrderControls;
