import { PaymentOption, WorkOrderPaymentSyncResult } from "@eljouren/domain";
import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { FieldErrors } from "react-hook-form";
import { useParams, useSearchParams } from "react-router-dom";
import withCustomerGuidV2, {
  CustomerAuthContext,
} from "../../../components/auth/hocs/withCustomerGuidV2";
import Logo from "../../../components/common/brand/Logo";
import { AppButton } from "../../../components/common/buttons/AppButton";
import AppPage from "../../../components/layout/AppPage";
import MyLink from "../../../components/routing/MyLink";
import { useLoading } from "../../../hooks/hooks";
import useQueryWrapper from "../../../hooks/use-query-wrapper";
import { useRepos } from "../../../hooks/use-repos";
import AppRoutes from "../../../routes";
import ClientUtils from "../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext, {
  InvoiceRouteView,
  PaymentOptionsFormValues,
} from "./CustomerWorkOrderInvoiceContext";
import CustomerWorkOrderInvoiceViewRouter from "./router/CustomerWorkOrderInvoiceViewRouter";

const CustomerWorkOrderPaymentRoute = () => {
  const { workOrderPaymentRepo } = useRepos();

  const params = useParams();
  const workOrderGuid = params["workOrderGuid"] || "";

  const [search, setSearchParams] = useSearchParams();
  const queryStatus = search.get("s");
  const { successParam, failParam } = buildSuccessAndFailParams();
  const expectingSuccess = queryStatus === successParam;
  const expectingFail = queryStatus === failParam;
  const expectingAny = !expectingSuccess && !expectingFail;
  const { isLoading: isSubmitting, loadWhilePromise } = useLoading();
  const { isLoading: isCanceling, loadWhilePromise: loadCancel } = useLoading();

  function buildSuccessAndFailParams(): {
    successParam: string;
    failParam: string;
  } {
    const guid = workOrderGuid;
    const firstSuccessIndex = 0;
    const lastSuccessIndex = Math.max(guid.length - 1, 0);

    const firstFailIndex = Math.min(1, guid.length - 1);
    const lastFailIndex = Math.max(guid.length - 2, 0);

    const successParam =
      "1" + guid.charAt(firstSuccessIndex) + guid.charAt(lastSuccessIndex);
    const failParam =
      "0" + guid.charAt(firstFailIndex) + guid.charAt(lastFailIndex);

    return {
      successParam,
      failParam,
    };
  }

  const authCtx = useContext(CustomerAuthContext);

  const wo = authCtx.workOrder;
  const enableFetching =
    !authCtx.isLoading &&
    wo?.paymentDetails.externalPaymentFlowAllowed &&
    wo?.isReadyToPay;

  const syncRes = useQueryWrapper({
    enabled: enableFetching,
    queryKey: [
      "workOrderPaymentSync",
      workOrderGuid,
      expectingSuccess,
      expectingFail,
    ],
    queryFn: () => {
      let status: WorkOrderPaymentSyncResult.ExpectedStatusType;
      if (expectingSuccess) {
        status = "ACCEPTED";
      } else if (expectingFail) {
        status = "REJECTED";
      } else {
        status = "ANY";
      }

      return workOrderPaymentRepo.getWorkOrderPaymentSyncResult({
        expectedStatus: status,
      });
    },
  });

  const paymentSummaryRes = useQueryWrapper({
    enabled: enableFetching,
    queryKey: ["customerWorkOrderPaymentSummary", workOrderGuid],
    queryFn: () => {
      return workOrderPaymentRepo.getWorkOrderPaymentSummary();
    },
  });

  const paymentInitRes = useQueryWrapper({
    enabled: enableFetching,
    queryKey: [
      "customerWorkOrderPaymentInit",
      authCtx.workOrder?.orderId,
      paymentSummaryRes.data,
    ],
    queryFn: async () => {
      if (!paymentSummaryRes.data) {
        return null;
      }
      return workOrderPaymentRepo.initialiseWithPaymentOptions({
        amount: paymentSummaryRes.data.priceToPay,
      });
    },
  });

  async function onError(errors: FieldErrors<PaymentOptionsFormValues>) {
    if (errors.invoiceEmail) {
      window.modal.alert({
        title: "E-postadress saknas",
        prompt: "Vänligen ange en e-postadress",
        typeOfAlert: "error",
      });
    } else if (errors.selectedOptionId) {
      window.modal.alert({
        title: "Betalningsmetod saknas",
        prompt: "Vänligen välj en betalningsmetod",
        typeOfAlert: "error",
      });
    }
  }

  async function onSubmit(values: PaymentOptionsFormValues) {
    if (isSubmitting) {
      return;
    }

    try {
      const sessionId = paymentInitRes.data?.sessionId;

      if (!sessionId) {
        throw new Error("No sessionId");
      }

      const paymentOptionId = values.selectedOptionId;

      const fullUrl = window.location.origin + window.location.pathname;
      const customerSuccessUrl = fullUrl + `?s=${successParam}`;
      const customerFailUrl = fullUrl + `?s=${failParam}`;

      const res = await loadWhilePromise(
        workOrderPaymentRepo.initiatePayment({
          sessionId,
          paymentOptionId,
          customerSuccessUrl,
          customerFailUrl,
          simulatePayment: values.simulatePayment,
        })
      );

      if (res.action.type === "redirect") {
        window.location.href = res.action.url;
      } else {
        const success = res.action.success;
        if (success) {
          setToExpectTrue();
          // Refetch all
          authCtx.refetch();
          paymentSummaryRes.refetch();
          paymentInitRes.refetch();

          /* 
            This will be refetched when we reset the searchParams,
            if we refetch here the first query might have the wrong status sent as the query param
            won't be cleared until the next render
          */
          //syncRes.refetch();
        } else {
          window.modal.alert({
            title: "Något gick fel",
            prompt:
              "Det gick inte att genomföra betalningen just nu. Vänligen försök igen senare.",
            typeOfAlert: "error",
          });
        }
      }
    } catch (er) {
      window.modal.alert({
        title: "Något gick fel",
        prompt:
          "Det gick inte starta betalningen just nu. Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  const workOrder = authCtx.workOrder;
  const isFinished =
    workOrder?.isFinished || process.env.NODE_ENV === "development" || true;

  /* const inDevelopment = process.env.NODE_ENV === "development";
  if (!inDevelopment) {
    return <></>;
  } */

  const showContent = isFinished;

  function getView(): InvoiceRouteView {
    const isLoading = syncRes.isLoading || authCtx.isLoading;
    const data = syncRes.data;
    const isAuthError = authCtx.isError;
    const isDataFetchError =
      !isLoading &&
      (syncRes.isError ||
        !data ||
        authCtx.isError ||
        paymentSummaryRes.isError ||
        paymentInitRes.isError);

    const paymentFlowNotAllowed =
      workOrder && !workOrder.paymentDetails.externalPaymentFlowAllowed;
    const showNotReadyToPay = workOrder && !workOrder.isReadyToPay;

    let deadlineWithinOneHour = false;
    if (workOrder?.paymentDetails.externalPaymentFlowDeadline) {
      const deadline = workOrder.paymentDetails.externalPaymentFlowDeadline;
      const now = new Date();
      const diff = deadline.getTime() - now.getTime();
      const diffInHours = diff / (1000 * 3600);
      deadlineWithinOneHour = diffInHours < 1;
    }

    if (isAuthError) {
      return InvoiceRouteView.authenticationError;
    }

    if (paymentFlowNotAllowed) {
      return InvoiceRouteView.paymentFlowNotSupported;
    }

    if (showNotReadyToPay) {
      return InvoiceRouteView.workOrderNotReadyToPay;
    }

    if (isDataFetchError) {
      return InvoiceRouteView.failedToFetchRequiredData;
    }

    if (isLoading && expectingFail) {
      return InvoiceRouteView.expectingFailure;
    }

    if (isLoading && expectingSuccess) {
      return InvoiceRouteView.expectingSuccess;
    }

    if (isLoading && expectingAny) {
      return InvoiceRouteView.expectingAny;
    }

    const confirmedData = data!;

    if (expectingFail) {
      if (confirmedData.statusAsExpected) {
        return InvoiceRouteView.confirmedFailure;
      } else {
        return InvoiceRouteView.failedToConfirmFailure;
      }
    }

    if (expectingSuccess) {
      if (confirmedData.statusAsExpected) {
        return InvoiceRouteView.confirmedSuccess;
      } else {
        return InvoiceRouteView.failedToConfirmSuccess;
      }
    }

    if (expectingAny) {
      const s = confirmedData.savedStatus;

      if (deadlineWithinOneHour) {
        return InvoiceRouteView.outOfTime;
      }

      if (!s.initiated || s.canStartProcessFromBeginning) {
        return InvoiceRouteView.paymentForm;
      }

      if (s.status === "ACCEPTED") {
        return InvoiceRouteView.confirmedSuccess;
      }

      if (s.status === "REJECTED") {
        return InvoiceRouteView.confirmedFailure;
      }

      if (s.status === "REFUNDED") {
        return InvoiceRouteView.confirmedRefunded;
      }

      if (s.status === "CANCELED") {
        return InvoiceRouteView.confirmedCanceled;
      }

      if (s.status === "INSPECTION") {
        return InvoiceRouteView.inspectionRequired;
      }

      if (s.status === "CREDIT_APPROVED") {
        return InvoiceRouteView.creditApproved;
      }
    }

    return InvoiceRouteView.paymentForm;
  }

  const view = getView();
  const inDevelopment = process.env.NODE_ENV === "development";

  function clearSearchParams() {
    setSearchParams({});
  }

  function restartPaymentFlow() {
    if (!syncRes.data?.savedStatus.canStartProcessFromBeginning) {
      return;
    }

    clearSearchParams();
    authCtx.refetch();
    paymentSummaryRes.refetch();
    paymentInitRes.refetch();
    /* 
      This will be refetched when we reset the searchParams,
      if we refetch here the first query might have the wrong status sent as the query param
      won't be cleared until the next render
    */
    //syncRes.refetch();
  }

  function setToExpectTrue() {
    setSearchParams({ s: successParam });
  }

  async function reset() {
    try {
      if (process.env.NODE_ENV !== "development") {
        throw new Error("Not in development");
      }
      await workOrderPaymentRepo.resetWorkOrderPaymentStatus();

      clearAndRefetch();
    } catch (error) {
      console.error(error);
      window.alert("Kunde inte återställa betalningen");
    }
  }

  function clearAndRefetch() {
    clearSearchParams();
    authCtx.refetch();
    paymentSummaryRes.refetch();
    paymentInitRes.refetch();
    syncRes.refetch();
  }

  async function cancelPendingPayment() {
    try {
      if (process.env.NODE_ENV !== "development") {
        throw new Error("Not in development");
      }
      const res = await loadCancel(workOrderPaymentRepo.cancelPendingPayment());

      if (res.success) {
        clearAndRefetch();
      } else {
        if (res.reason === "paymentNotPending") {
          const confirm = await window.modal.confirm({
            title: "Betalningen är inte pågående",
            prompt:
              "Det verkar som att betalningen inte längre är pågående. Vill du ladda om sidan?",
          });
          if (confirm) {
            clearAndRefetch();
          }
        } else {
          throw new Error(res.reason);
        }
      }
    } catch (error) {
      console.error(error);
      window.modal.alert({
        title: "Något gick fel",
        prompt:
          "Det gick inte att avbryta betalningen just nu. Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  function filterPaymentOptions(): PaymentOption.Type[] {
    const initData = paymentInitRes.data;
    const syncData = syncRes.data;
    if (!initData || !syncData) {
      return [];
    }

    return initData.paymentOptions
      .filter((opt) => {
        const allowedPayments =
          syncData.savedStatus.availablePaymentAlternatives;

        if (opt.brand === "ipis") {
          return allowedPayments.internalInvoice;
        }

        switch (opt.type) {
          case "directPayment":
            return allowedPayments.directPayment;
          case "invoice":
            return allowedPayments.invoice;
          case "partialPayment":
            return allowedPayments.partialPayment;
          default:
            return false;
        }
      })
      .sort((a, b) => {
        if (a.type === "directPayment") {
          return -1;
        }
        if (b.type === "directPayment") {
          return 1;
        }

        return 0;
      });
  }

  return (
    <AppPage
      headerProps={{
        RenderHeadings: ({ headingClassName }) => (
          <>
            <h1 className={headingClassName}>Betalning</h1>
            {inDevelopment && (
              <AppButton className="ml-auto" onClick={reset}>
                Återställ
              </AppButton>
            )}
          </>
        ),
      }}
      className={ClientUtils.twClassNames(
        "mx-auto h-full overflow-hidden p-2 sm:p-4",
        !isFinished && "flex max-w-2xl flex-col gap-8 py-8"
      )}
    >
      <AnimatePresence exitBeforeEnter>
        {/* 
          Perhaps add this as a view instead
        */}
        {!isFinished && (
          <>
            <Logo withColor wrapperClassName="mx-auto max-w-full w-[200px]" />
            <span className="text-lg">
              Arbetsordern är inte klar än. Betalningsinformationen kommer synas
              här när arbetet är klart.
            </span>
            <MyLink
              to={AppRoutes.customer.root(workOrderGuid)}
              className="ml-auto mt-6 flex items-center rounded border bg-brand-blue-600 px-4 py-2 text-white"
            >
              Tillbaka till arbetsorder
            </MyLink>
          </>
        )}

        {showContent && (
          <CustomerWorkOrderInvoiceContext.Provider
            value={{
              restartPaymentFlow,
              view,
              workOrderGuid,
              workOrder,
              paymentSummary: paymentSummaryRes.data,
              paymentInitRes: paymentInitRes,
              syncRes,
              paymentOptions: filterPaymentOptions(),
              isSubmitting,
              onSubmit,
              onError,
              cancelPendingPayment,
              isCancelingPendingPayment: isCanceling,
            }}
          >
            <CustomerWorkOrderInvoiceViewRouter />
          </CustomerWorkOrderInvoiceContext.Provider>
        )}
      </AnimatePresence>
    </AppPage>
  );
};

export default withCustomerGuidV2(CustomerWorkOrderPaymentRoute);
