"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Proxied_GetMultipleInput = void 0;
var SignUrl_GetMultipleInput_1 = require("./SignUrl_GetMultipleInput");
var Proxied_GetMultipleInput;
(function (Proxied_GetMultipleInput) {
    Proxied_GetMultipleInput.Schema = SignUrl_GetMultipleInput_1.SignUrl_GetMultipleInput.Schema.pick({
        recordIds: true,
        uploaderSource: true,
        collectionType: true,
    });
})(Proxied_GetMultipleInput || (Proxied_GetMultipleInput = {}));
exports.Proxied_GetMultipleInput = Proxied_GetMultipleInput;
