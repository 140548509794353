import MaybeTrpcError from "./MaybeTrpcError";
import NetworkRequestError from "./NetworkRequestError";

namespace ErrorUtil {
  export function getShortHand(publicId: string | undefined):
    | {
        publicId: string;
        publicIdShorthand: string;
      }
    | undefined {
    if (!publicId) {
      return undefined;
    }
    const split = publicId.split("-");
    const publicIdShorthand = split[split.length - 1];

    return {
      publicId,
      publicIdShorthand,
    };
  }

  export function getErrorProps(error: unknown):
    | {
        publicId: string;
        publicIdShorthand: string;
      }
    | undefined {
    let publicId: string | undefined;
    if (error instanceof NetworkRequestError) {
      publicId = error.getPublicId();
    } else {
      const maybeTrpcError = new MaybeTrpcError(error);
      publicId = maybeTrpcError.getPublicId();
    }

    if (!publicId) {
      return undefined;
    }
    const split = publicId.split("-");
    const publicIdShorthand = split[split.length - 1];

    return {
      publicId,
      publicIdShorthand,
    };
  }
}

export default ErrorUtil;
