import ClientUtils from './../../../utils/ClientUtils';
import { parse } from "date-fns";

import { DateHelper } from "@eljouren/utils";

interface Props {
  id?: string;
  className?: string;
  type: "time" | "datetime-local" | "date";
  htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
  date: Date;
  onChange?(date: Date): void;
}

function DatePicker(props: Props) {
  const attributes = props.htmlAttributes || {};

  function getFormat() {
    switch (props.type) {
      case "date":
        return "yyyy-MM-dd";
      case "datetime-local":
        return "yyyy-MM-dd'T'HH:mm";
      case "time":
        return "k:m";
    }
  }

  function getValue() {
    const helper = new DateHelper(props.date);
    switch (props.type) {
      case "date":
        return helper.dateInputFormat;
      case "datetime-local":
        return helper.dateTimeInputFormat;
      case "time":
        return helper.timeInputFormat;
    }
  }

  return (
    <input
      className={ClientUtils.twClassNames(
        "rounded border border-gray-300 p-1",
        props.htmlAttributes?.readOnly ? "border-gray-200" : "border-blue-300",
        props.className
      )}
      id={props.id}
      type={props.type}
      value={getValue()}
      onChange={(e) => {
        const value = e.target.value;
        const date = parse(value, getFormat(), new Date());
        props.onChange && props.onChange(date);
      }}
      {...attributes}
    />
  );
}

export default DatePicker;
