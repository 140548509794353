import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";
import AppPage from "../../../../components/layout/AppPage";
import { HandymanWorkOrderFileContext } from "../../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import WorkOrderControls from "../../../../components/work-order/handyman/handle-tab/WorkOrderControls";

import HandymanWorkOrderRouteContext, {
  WorkOrderTabs,
} from "../contexts/HandymanWorkOrderRouteContext";
import HandymanWorkOrderDesktopFileSection from "./HandymanWorkOrderDesktopFileSection";
import HandymanWorkOrderDesktopHeadings from "./HandymanWorkOrderDesktopHeadings";
import HandymanWorkOrderDesktopOverviewSection from "./HandymanWorkOrderDesktopOverviewSection";
import AppRoutes from "../../../../routes";

const HandymanWorkOrderDesktopPage = () => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  const filesExpanded = ctx.currentView.tab === WorkOrderTabs.files;
  function setFilesExpanded(value: boolean) {
    ctx.navigateTo({
      tab: value ? WorkOrderTabs.files : WorkOrderTabs.overview,
    });
  }

  function onToggleFileExpansion() {
    setFilesExpanded(!filesExpanded);
  }

  const showPreview = !fileCtx.isLoading && !!fileCtx.allImages.length;
  const hidePreview = fileCtx.isLoading || !fileCtx.allImages.length;
  let overrideLink: string;
  if (ctx.order.assignedTo?.handymanId) {
    overrideLink =
      AppRoutes.partner.root({
        userId: ctx.order.assignedTo.handymanId,
      }) + "&sida=Kalender";
  } else {
    overrideLink = AppRoutes.partner.root() + "&sida=Kalender";
  }
  return (
    <AppPage
      headerProps={{
        loading: ctx.orderRes.query.isFetching,
        RenderHeadings: (props) => {
          return <HandymanWorkOrderDesktopHeadings {...props} context={ctx} />;
        },
        overrideLinkWhenNoHistory: overrideLink,
      }}
      className={ClientUtils.twClassNames(
        "relative grid bg-bg-base-layer",
        ctx.order.allowedToHandleOrder && "grid-cols-3",
        !ctx.order.allowedToHandleOrder && "grid-cols-2",
        showPreview && "grid-rows-[minmax(0,1fr),225px]",
        /* 
          This magic 60px should preferably use min-content, but that doesn't work as intended on Safari.        
        */
        hidePreview && "grid-rows-[minmax(0,1fr),60px]"
      )}
    >
      <HandymanWorkOrderDesktopOverviewSection className="col-span-2 row-start-1" />
      {ctx.order.allowedToHandleOrder && (
        <WorkOrderControls className="col-start-3 row-span-2 row-start-1" />
      )}
      <HandymanWorkOrderDesktopFileSection
        className="z-10 col-span-2 col-start-1 row-start-2 h-full w-full"
        onToggle={onToggleFileExpansion}
        isExpanded={filesExpanded}
      />
    </AppPage>
  );
};

export default HandymanWorkOrderDesktopPage;
