"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyboardShortcuts = useKeyboardShortcuts;
exports.useRedo = useRedo;
exports.useUndo = useUndo;
exports.usePaste = usePaste;
exports.useCut = useCut;
exports.useOnEscapeClick = useOnEscapeClick;
exports.useDisableEnterClickListeners = useDisableEnterClickListeners;
exports.useOnEnterClick = useOnEnterClick;
exports.useDetectKeyboardOpen = useDetectKeyboardOpen;
var react_1 = require("react");
function useKeyboardShortcuts(options, dependencies) {
    if (dependencies === void 0) { dependencies = []; }
    (0, react_1.useEffect)(function () {
        var handleKeyDown = function (event) {
            /* console.log({
            key: event.key,
            ctrlKey: event.ctrlKey,
            altKey: event.altKey,
            shiftKey: event.shiftKey,
            metaKey: event.metaKey,
          }); */
            var key = options.key, ctrlKey = options.ctrlKey, altKey = options.altKey, shiftKey = options.shiftKey, metaKey = options.metaKey;
            if (!event.key) {
                return;
            }
            if (event.key.toLowerCase() === key.toLowerCase() &&
                event.ctrlKey === !!ctrlKey &&
                event.altKey === !!altKey &&
                event.shiftKey === !!shiftKey &&
                event.metaKey === !!metaKey) {
                if (options.preventDefault) {
                    event.preventDefault();
                }
                options.callback(event);
            }
        };
        document.addEventListener("keydown", handleKeyDown);
        return function () { return document.removeEventListener("keydown", handleKeyDown); };
    }, __spreadArray([options, options.callback], dependencies, true));
}
function useRedo(callback) {
    useKeyboardShortcuts({ key: "z", ctrlKey: true, shiftKey: true, callback: callback });
}
function useUndo(callback) {
    useKeyboardShortcuts({ key: "z", ctrlKey: true, callback: callback });
}
function usePaste(callback) {
    useKeyboardShortcuts({ key: "v", ctrlKey: true, callback: callback });
}
function useCut(callback) {
    useKeyboardShortcuts({ key: "x", ctrlKey: true, callback: callback });
}
function useOnEscapeClick(callback) {
    useOnClick("Escape", callback);
}
/*
  Deprecated in favor of useKeyboardShortcuts
*/
function useOnClick(key, callback) {
    var onKeyDown = function (ev) {
        if (ev.key === key) {
            callback();
        }
    };
    (0, react_1.useEffect)(function () {
        document.addEventListener("keydown", onKeyDown);
        return function () {
            document.removeEventListener("keydown", onKeyDown);
        };
    });
}
var disabledEnterClickListeners = false;
function useDisableEnterClickListeners() {
    (0, react_1.useEffect)(function () {
        disabledEnterClickListeners = true;
        return function () {
            disabledEnterClickListeners = false;
        };
    });
}
function useOnEnterClick(args) {
    (0, react_1.useEffect)(function () {
        var ref = args.ref, callback = args.callback;
        var _callback = function (e) {
            if (disabledEnterClickListeners) {
                return;
            }
            onEnterClick(e);
        };
        if (ref.current && callback) {
            var current = ref.current;
            current.addEventListener("keypress", _callback);
        }
        return function () {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.removeEventListener("keypress", _callback);
        };
    });
    var onEnterClick = function (event) {
        if (event.code === "Enter") {
            // Not sure what the default is in this case
            event.preventDefault();
            args.callback && args.callback();
        }
    };
}
function useDetectKeyboardOpen(minKeyboardHeight, defaultValue) {
    if (minKeyboardHeight === void 0) { minKeyboardHeight = 300; }
    if (defaultValue === void 0) { defaultValue = false; }
    var _a = (0, react_1.useState)(defaultValue), isKeyboardOpen = _a[0], setIsKeyboardOpen = _a[1];
    (0, react_1.useEffect)(function () {
        var _a;
        var listener = function () {
            var _a;
            var newState = window.screen.height - minKeyboardHeight >
                (((_a = window.visualViewport) === null || _a === void 0 ? void 0 : _a.height) || 0);
            setIsKeyboardOpen(newState);
        };
        (_a = window.visualViewport) === null || _a === void 0 ? void 0 : _a.addEventListener("resize", listener);
        return function () {
            var _a;
            (_a = window.visualViewport) === null || _a === void 0 ? void 0 : _a.removeEventListener("resize", listener);
        };
    }, [minKeyboardHeight]);
    return isKeyboardOpen;
}
