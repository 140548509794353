import { IpisFormElement } from "@eljouren/domain";
import { motion } from "framer-motion";
import AppFormInformation from "../../../../__v2__/form-inputs/app-form-information/AppFormInformation";

interface Props {
  className?: string;
  question: IpisFormElement.InformationType;
  slideUpAnchorId?: string;
}

const ChecklistDecoupledInformationQuestion = (props: Props) => {
  const q = props.question;

  return (
    <motion.div className={props.className}>
      <AppFormInformation
        id={q.id}
        label={q.title}
        tooltip={q.tooltip}
        richTextDescription={q.description}
        richTextNote={q.note}
        slideUpElement={q.slideUpElement}
        slideUpAnchorId={props.slideUpAnchorId}
      />
    </motion.div>
  );
};

export default ChecklistDecoupledInformationQuestion;
