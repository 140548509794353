import { IpisFormElement } from "@eljouren/domain";
import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ChecklistTextInputBuilder from "./ChecklistTextInputBuilder";

interface Props {
  className?: string;
  closeForm: () => void;
  question: IpisFormElement.TextInputType | IpisFormElement.TextAreaType;
}

const ChecklistTextInputEditor = (props: Props) => {
  const ctx = useFormEditor();

  return (
    <ChecklistTextInputBuilder
      closeForm={props.closeForm}
      onSubmit={(values) => {
        console.log(values);
        ctx.controls.editElement({ id: props.question.id, newValues: values });
        props.closeForm();
      }}
      defaultValues={props.question}
    />
  );
};

export default ChecklistTextInputEditor;
