import { MotionProps, motion } from "framer-motion";
import { InvoiceRouteView } from "../CustomerWorkOrderInvoiceContext";

interface Props {
  className?: string;
  motionProps: MotionProps;
  style: React.CSSProperties;
  view:
    | InvoiceRouteView.expectingAny
    | InvoiceRouteView.expectingFailure
    | InvoiceRouteView.expectingSuccess;
}

const InvoiceLoadingView = (props: Props) => {
  if (props.view === InvoiceRouteView.expectingFailure) {
    return (
      <motion.section
        className="skeleton mx-auto w-full max-w-screen-sm rounded border p-4 shadow md:p-8"
        {...props.motionProps}
        style={props.style}
      >
        <h2 className="text-lg">
          Det verkar som att betalningen inte gick igenom...
        </h2>
        <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
          <p className="flex text-sm md:text-base">
            Vänligen vänta medan vi tar reda på varför
          </p>
        </main>
      </motion.section>
    );
  }

  if (props.view === InvoiceRouteView.expectingSuccess) {
    return (
      <motion.section
        className="skeleton mx-auto w-full max-w-screen-sm rounded border p-4 shadow md:p-8"
        {...props.motionProps}
        style={props.style}
      >
        <h2 className="text-lg">Vänligen vänta...</h2>
        <main className="font-opacity-80 flex items-center gap-2 text-sm text-dark-gray md:text-base">
          <p className="flex">Vi bekräftar att din betalning gick igenom</p>
        </main>
      </motion.section>
    );
  }

  return (
    <motion.section
      className="skeleton relative mx-auto w-full max-w-screen-sm rounded border p-4 shadow md:p-8"
      {...props.motionProps}
      style={props.style}
    >
      <h2 className="text-lg">Vänligen vänta...</h2>
      <main className="font-opacity-80 flex items-center gap-2 text-dark-gray">
        <p className="flex text-sm md:text-base">
          Vi hämtar informationen som behövs för att genomföra din betalning
        </p>
      </main>
    </motion.section>
  );
};

export default InvoiceLoadingView;
