import { Utils } from "@eljouren/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ClientUtils from "../../../utils/ClientUtils";

interface Props {
  className?: string;
  id: string;
  labels: string | string[];
  interval?: number;
  stopOnLast?: boolean;
}

const LoadingModal = (props: Props) => {
  const labels = Utils.asArray(props.labels);
  const [labelIndex, setLabelIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (props.stopOnLast && labelIndex === labels.length - 1) {
      return;
    }

    const interval = props.interval ?? 2500;
    intervalRef.current = setInterval(() => {
      setLabelIndex((prev) => (prev + 1) % labels.length);
    }, interval);
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [labels, props.interval, props.stopOnLast, labelIndex]);

  const text = labels[labelIndex];

  return (
    <motion.div
      className={ClientUtils.classNames(
        "absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center gap-2 bg-white/80 xs:w-full xs:max-w-full",
        props.className
      )}
      id={props.id}
      aria-labelledby="label"
      //aria-live="polite"
      aria-busy="true"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <header className="grid w-full grid-cols-1 grid-rows-1">
        <AnimatePresence initial={false}>
          <motion.h3
            id="label"
            key={text}
            className="col-start-1 row-start-1 text-center text-2xl"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {text}
          </motion.h3>
        </AnimatePresence>
      </header>
      <div aria-hidden className="skeleton h-2 w-[calc(100%-2rem)] rounded" />
    </motion.div>
  );
};

export default LoadingModal;
