import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { UUID } from "@eljouren/utils";
import AlertDialog from "./AlertDialog";
import ToastList from "./ToastList";

declare global {
  interface Window {
    modal: {
      alert(args: AlertArgs): void;
      confirm(args: ConfirmArgs): Promise<boolean>;
      toast(args: ToastArgs): void;
    };
  }
}

/* 
  The blow types should probably be divided into separate files
*/

export type AlertArgs = {
  title: string;
  prompt: string;
  typeOfAlert: "notification" | "error";
  buttonLabel?: string;
  error?: unknown;
};

export type AlertState = {
  type: "alert";
  args: AlertArgs;
};

export type ConfirmArgs = {
  title: string;
  prompt: string;
  yesLabel?: string;
  noLabel?: string;
  callback?: (args: { answer: boolean }) => void;
};

export type ConfirmState = {
  type: "confirm";
  args: ConfirmArgs;
};

export type ToastArgs = {
  id?: string;
  title: string;
  prompt: string;
  timeVisibleInMs?: number;
  toastType: "success" | "error";
};

export type ToastWithId = ToastArgs & { id: string };

export type ToastState = {
  type: "toast";
  toasts: ToastWithId[];
};

export type State = AlertState | ConfirmState | ToastState;

export type ConfirmDialogAnswerArgs = { answer: boolean };

const Modal = () => {
  const [show, setShow] = useState<State | null>(null);
  const showRef = useRef<State | null>(show);
  const confirmPromiseResolveFunctionRef = useRef<
    ((answer: boolean) => void) | null
  >(null);

  useEffect(() => {
    window.modal = {
      alert: (args) => {
        setShow({ type: "alert", args });
      },
      confirm: (args) => {
        if (confirmPromiseResolveFunctionRef.current) {
          Promise.reject(confirmPromiseResolveFunctionRef.current);
        }

        let resolve: (value: boolean) => void;
        const promise = new Promise<boolean>((res, rej) => {
          resolve = res;
        });

        confirmPromiseResolveFunctionRef.current = resolve!;
        setShow({ type: "confirm", args });
        return promise;
      },
      toast: (args) => {
        const id = args.id ?? UUID.generate().value;
        const current = showRef.current;
        const newToast = { ...args, id };

        if (current?.type === "toast") {
          if (current.toasts.find((toast) => toast.id === id)) {
            return;
          }

          const copy = { ...current };
          copy.toasts.push(newToast);
          setShow(copy);
        } else {
          setShow({ type: "toast", toasts: [newToast] });
        }
      },
    };
  }, []);

  useEffect(() => {
    showRef.current = show;
  }, [show]);

  function closeAlert() {
    window.error.consume();
    setShow(null);
  }

  function closeToast(toast: ToastWithId) {
    if (show?.type !== "toast") {
      return;
    }
    const copy = { ...show };
    const filtered = copy.toasts.filter((el) => el.id !== toast.id);
    copy.toasts = filtered;

    if (filtered.length > 0) {
      setShow(copy);
    } else {
      setShow(null);
    }
  }

  function answerConfirmDialog(args: ConfirmDialogAnswerArgs) {
    if (show?.type !== "confirm") {
      return;
    }
    if (show.args.callback) {
      show.args.callback(args);
    }

    if (confirmPromiseResolveFunctionRef.current) {
      confirmPromiseResolveFunctionRef.current(args.answer);
      confirmPromiseResolveFunctionRef.current = null;
    }

    setShow(null);
  }

  return (
    <AnimatePresence>
      {!show && <></>}
      {show?.type === "alert" && (
        <AlertDialog key="alert" {...show} close={closeAlert} />
      )}
      {show?.type === "confirm" && (
        <AlertDialog key="confirm" {...show} answer={answerConfirmDialog} />
      )}
      {show?.type === "toast" && (
        <ToastList
          key="toastList"
          toasts={show.toasts}
          closeToast={closeToast}
        />
      )}
    </AnimatePresence>
  );
};

export default Modal;
