"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderHousingType = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderHousingType;
(function (WorkOrderHousingType) {
    WorkOrderHousingType.Schema = centralized_zod_1.z.discriminatedUnion("housingType", [
        centralized_zod_1.z.object({
            housingType: centralized_zod_1.z.literal("house"),
            propertyDesignation: centralized_zod_1.z.string().optional().nullable(),
        }),
        centralized_zod_1.z.object({
            housingType: centralized_zod_1.z.literal("condomonium"),
            housingCooperativeOrgNo: centralized_zod_1.z.string().optional().nullable(),
            apartmentNumber: centralized_zod_1.z.string().optional().nullable(),
        }),
        centralized_zod_1.z.object({
            housingType: centralized_zod_1.z.literal("rentalApartment"),
        }),
        centralized_zod_1.z.object({
            housingType: centralized_zod_1.z.literal("insuranceMatter"),
        }),
    ]);
})(WorkOrderHousingType || (WorkOrderHousingType = {}));
exports.WorkOrderHousingType = WorkOrderHousingType;
