import { createContext } from "react";

export type BackButtonStackCallback = () => void;
export type BeforeBackCallback = () => Promise<boolean>;
export type RemoveFunction = () => void;

/* 
  Buggy!
*/
const BackButtonStackContext = createContext<{
  push: (callback: BackButtonStackCallback) => RemoveFunction;
  pop: () => BackButtonStackCallback;
  isEmpty: () => boolean;
  beforeBack: (element: BeforeBackCallback) => RemoveFunction;
  popBeforeBack: () => BeforeBackCallback;
  beforeBackIsEmpty: () => boolean;
}>({} as never);

export default BackButtonStackContext;
