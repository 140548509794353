import ClientUtils from "../../utils/ClientUtils";
import { FaStairs } from "react-icons/fa6";
import { IconBaseProps } from "react-icons/lib";

interface Props extends IconBaseProps {
  className?: string;
}

/* 
  Cant find heroicons version of this icon
*/
const FloorIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <FaStairs
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default FloorIcon;
