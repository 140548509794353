import ClientUtils from "./../../../utils/ClientUtils";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useManipulateBackButtonStack } from "./use-back-button-stack";
import AppArrowIcon from "../../icons/AppArrowIcon";

interface Props {
  className?: string;
  label?: string;
  overrideLink?: string;
}

const HistoryBackButton = (props: Props) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const goBackTo = params.get("goBackTo");
  const { handle } = useManipulateBackButtonStack();

  return (
    <button
      className={ClientUtils.twClassNames(
        "group flex items-center gap-2 p-2",
        props.className
      )}
      onClick={async (e) => {
        e.preventDefault();

        const handled = await handle();
        if (handled) {
          return;
        }

        console.log("overrideLink", props.overrideLink);
        console.log("goBackTo", goBackTo);
        if (props.overrideLink) {
          navigate(props.overrideLink);
        } else if (goBackTo) {
          navigate(goBackTo);
        } else {
          navigate(-1);
        }
      }}
    >
      <AppArrowIcon
        direction="left"
        size={30}
        className="scale-90 group-hover:scale-100"
      />
      {props.label}
    </button>
  );
};

export default HistoryBackButton;
