import React, { useState } from "react";
import ClientUtils from "../../../../../utils/ClientUtils";
import A11yDropdown from "../../../../../components/common/dropdown/A11YDropdown";

interface Props {
  label: string;
  price: number;
  final?: boolean;
  children?: React.ReactNode;
  subheading?: string;
}

const InvoiceOverviewListItem = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isNegative = props.price < 0;
  const hasChildren = !!props.children;

  function toggleExpanded() {
    setIsExpanded(!isExpanded);
  }

  return (
    <li
      className={ClientUtils.classNames(
        "grid grid-cols-[1fr,auto,40px] border-b py-2 last:border-b-0 md:px-4 md:text-base",
        !props.final && "text-sm",
        props.final && "font-semibold",
        hasChildren && "cursor-pointer"
      )}
      onClick={hasChildren ? toggleExpanded : undefined}
    >
      {hasChildren && (
        <A11yDropdown
          preventHideOnOutsideClick
          contentId="additional-information-dropdown"
          className="col-span-3 w-full"
          buttonClassName="grid grid-cols-[1fr,40px]"
          title={() => {
            return (
              <span
                className={ClientUtils.classNames(
                  "grid w-full grow grid-cols-[minmax(0,1fr),auto]",
                  isNegative && "text-green-700"
                )}
              >
                <span className="flex">{props.label}</span>
                <span className="flex">{props.price}:-</span>
                {!!props.subheading && (
                  <span className="col-span-2 flex text-sm text-dark-gray/80">
                    {props.subheading}
                  </span>
                )}
              </span>
            );
          }}
          contentWrapperClassName="p-2 text-sm"
        >
          {props.children}
        </A11yDropdown>
      )}

      {!hasChildren && (
        <>
          <span>{props.label}</span>
          <span className="ml-auto">{props.price}:-</span>
          {!!props.subheading && (
            <span className="col-span-2 text-sm text-dark-gray/80">
              {props.subheading}
            </span>
          )}
        </>
      )}
    </li>
  );
};

export default InvoiceOverviewListItem;
