import React from "react";

interface Props {
  children?: React.ReactNode;
  ariaLabelledBy?: string;
}

const CustomerFormSchedulingAndHandymanWrapper = (props: Props) => {
  return (
    <div
      className="grid grid-cols-[auto,1fr] grid-rows-[auto,auto] items-center gap-x-4"
      aria-labelledby={props.ariaLabelledBy}
    >
      {props.children}
    </div>
  );
};

export default CustomerFormSchedulingAndHandymanWrapper;
