import { MinusCircleIcon, MinusIcon } from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "default" | "circle";
}

const AppMinusIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "circle") {
    return <MinusCircleIcon {...rest} className={props.className} />;
  }

  return <MinusIcon {...rest} className={props.className} />;
};
export default withIconSize(AppMinusIcon);
