import ClientUtils from "./../../utils/ClientUtils";
import { motion } from "framer-motion";
import { useContext } from "react";

import { DateHelper } from "@eljouren/utils";
import { AppLoader } from "../common/loaders/AppLoader";
import { CalendarContext } from "./Calendar";
import CalendarDayGridDesktopItem from "./CalendarDayGridDesktopItem";
import { TailwindBreakpoint, useMediaQuery } from "../../hooks/use-media-query";
import CalendarDayGridMobileItem from "./CalendarDayGridMobileItem";

interface Props {
  className?: string;
  hideWeekNumbers?: boolean;
  selectDay(day: DateHelper): void;
}

const CalendarGridView = (props: Props) => {
  const isDesktop = useMediaQuery(TailwindBreakpoint.lg);

  if (isDesktop) {
    return <DesktopComponent {...props} />;
  } else {
    return <MobileComponent {...props} />;
  }
};

const DesktopComponent = (props: Props) => {
  const { calendarHelper, isLoading } = useContext(CalendarContext);

  return (
    <motion.main
      className={ClientUtils.twClassNames(
        "relative col-start-1 col-end-1 row-start-2 row-end-2 mr-auto flex h-full w-full overflow-x-auto p-2",
        isLoading && "pointer-events-none"
      )}
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoading ? 0.5 : 1 }}
      exit={{ opacity: 0 }}
    >
      {isLoading && (
        <AppLoader className="absolute left-[calc(50%-1rem)] top-1/3 mx-auto h-8 w-8" />
      )}
      {calendarHelper.calendarDays.map((dayHelper, i) => (
        <CalendarDayGridDesktopItem
          key={`dayOfMonth${dayHelper.dayOfMonth}${dayHelper.month}${dayHelper.yearLabel}`}
          index={i}
          dayHelper={dayHelper}
        />
      ))}
    </motion.main>
  );
};

const MobileComponent = (props: Props) => {
  const { calendarHelper, isLoading } = useContext(CalendarContext);

  return (
    <motion.main
      className={ClientUtils.twClassNames(
        "col-start-1 col-end-1 row-start-2 row-end-2 h-full p-2",
        isLoading && "pointer-events-none"
      )}
      style={calendarHelper.gui.gridStyle}
      initial={{ opacity: 0 }}
      animate={{ opacity: isLoading ? 0.5 : 1 }}
      exit={{ opacity: 0 }}
    >
      {isLoading && (
        <AppLoader className="absolute left-[calc(50%-1rem)] top-1/3 mx-auto h-8 w-8" />
      )}

      {calendarHelper.daysOfWeek.map((day, i) => (
        <label
          key={`weekDay${i}`}
          className="text-sm font-bold"
          style={{
            gridRow: 1,
            gridColumn: i + 1,
          }}
        >
          {day.shorthand}
        </label>
      ))}
      {calendarHelper.calendarDays.map((dayHelper, i) => (
        <CalendarDayGridMobileItem
          selectDay={props.selectDay}
          key={`dayOfMonth${dayHelper.dayOfMonth}${dayHelper.month}${dayHelper.yearLabel}`}
          index={i}
          dayHelper={dayHelper}
        />
      ))}
    </motion.main>
  );
};

export default CalendarGridView;
