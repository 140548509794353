import { IpisForm } from "@eljouren/domain";
import React from "react";

type NewFormPage = {
  pageTitle: string;
  pageTitleShorthand: string;
  pageDescription?: string;
  pagePreparations?: string;
};

export type CreateNewFormArgs = {
  name: string;
  pages: NewFormPage[];
};

type FormBuilderContextType = {
  forms: IpisForm.ShellType[];
  staticSelectedForm: IpisForm.ShellType | null;
  setSelectedForm: (form: IpisForm.ShellType) => void;
  createNewForm: (args: CreateNewFormArgs) => Promise<void>;
  deleteForm: () => Promise<void>;
  copyForm: (args: { formId: string }) => Promise<void>;
  mutate: (cb: () => Promise<any>) => Promise<void>;
  setSelectedFormIsDirty: (isDirty: boolean) => void;
  isLoading: boolean;
};
const FormBuilderContext = React.createContext<FormBuilderContextType>(
  {} as never
);

export default FormBuilderContext;
