"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMargin = useMargin;
function useMargin(options) {
    var _a = options !== null && options !== void 0 ? options : {}, m = _a.m, mt = _a.mt, mr = _a.mr, mb = _a.mb, ml = _a.ml, mx = _a.mx, my = _a.my;
    return {
        margin: m,
        marginTop: mt !== null && mt !== void 0 ? mt : my,
        marginRight: mr !== null && mr !== void 0 ? mr : mx,
        marginBottom: mb !== null && mb !== void 0 ? mb : my,
        marginLeft: ml !== null && ml !== void 0 ? ml : mx,
    };
}
