import { WorkOrder } from "@eljouren/domain/build";
import { DateHelper } from "@eljouren/utils";
import { motion } from "framer-motion";
import React, { useContext, useEffect, useRef } from "react";
import { TUseBundledStateReturn, useBundledState } from "../../hooks/hooks";
import { useBundledSs } from "../../hooks/session-storage-hooks";
import ClientUtils from "./../../utils/ClientUtils";

import Dropdown from "../common/dropdown/Dropdown";
import { AppLoader } from "../common/loaders/AppLoader";
import HandymanContext from "../handyman-context/HandymanContext";
import HandymanWorkOrderListItem from "../work-order/handyman/HandymanWorkOrderListItem";
import { CalendarContext } from "./Calendar";
import debounce from "lodash/debounce";

const ListViewContext = React.createContext<{
  showDaysWithoutOrdersBundle: TUseBundledStateReturn<boolean>;
  hideFinishedOrdersBundle: TUseBundledStateReturn<boolean>;
  textFilterBundle: TUseBundledStateReturn<string>;
}>({} as never);

interface Props {
  className?: string;
}

const CalendarListView = (props: Props) => {
  const showDaysWithoutOrdersBundle = useBundledSs(
    "showDaysWithoutOrders",
    false,
    {
      decode: (str) => (str === "true" ? true : false),
      encode: (bool) => (bool ? "true" : "false"),
    }
  );

  const hideFinishedOrdersBundle = useBundledSs("hideFinishedOrders", false, {
    decode: (str) => (str === "true" ? true : false),
    encode: (bool) => (bool ? "true" : "false"),
  });

  const textFilterBundle = useBundledState("");
  const { isLoading } = useContext(CalendarContext);

  const { calendarHelper } = useContext(CalendarContext);
  const ulRef = useRef<HTMLUListElement>(null); // Reference to the <ul> element
  const scrollTopRef = useRef<number>(0); // Reference to the scroll position

  const firstCalendarDay = calendarHelper.calendarDays[0];
  const lastCalendarDay =
    calendarHelper.calendarDays[calendarHelper.calendarDays.length - 1];
  const intervalString = `${firstCalendarDay.startOfDay.date.toISOString()}-${lastCalendarDay.endOfDay.date.toISOString()}`;
  const sessionStorageKey = `list-view-scroll-position-${intervalString}`;

  useEffect(() => {
    const handleScroll = debounce((...args) => {
      const e = args[0];
      scrollTopRef.current = e.target.scrollTop;
      sessionStorage.setItem(
        sessionStorageKey,
        scrollTopRef.current.toString()
      );
    }, 100); // Adjust debounce time as needed

    const current = ulRef.current;
    if (current) {
      const savedScrollPosition = sessionStorage.getItem(sessionStorageKey);
      if (savedScrollPosition) {
        current.scrollTop = parseInt(savedScrollPosition, 10);
      } else {
        current.scrollTop = 0;
      }

      current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (current) {
        current.removeEventListener("scroll", handleScroll);
        handleScroll.cancel();
      }
    };
  }, [sessionStorageKey]);

  /* useEffect(() => {
    const current = ulRef.current;

    const firstCalendarDay = calendarHelper.calendarDays[0];
    const lastCalendarDay =
      calendarHelper.calendarDays[calendarHelper.calendarDays.length - 1];
    const intervalString = `${firstCalendarDay.startOfDay.date.toISOString()}-${lastCalendarDay.endOfDay.date.toISOString()}`;
    const sessionStorageKey = `list-view-scroll-position-${intervalString}`;
    const savedScrollPosition = sessionStorage.getItem(sessionStorageKey);
    const scrollPosition = scrollTopRef.current ?? 0;
    if (current) {
      console.log({
        context: "CalendarListView useEffect",
        savedScrollPosition,
        scrollPosition,
      });
      if (savedScrollPosition) {
        current.scrollTop = parseInt(savedScrollPosition, 10);
      } else {
        current.scrollTop = 0;
      }
    }

    // Cleanup function for saving scroll position on unmount
    return () => {
      if (current) {
        const refScrollTop = scrollTopRef.current;

        //const scrollTop = scrollPosition.toString();
        console.log({
          context: "CalendarListView cleanup",
          refScrollTop,
        });
        sessionStorage.setItem(sessionStorageKey, refScrollTop.toString());
      }
    };
  }); */

  return (
    <ListViewContext.Provider
      value={{
        showDaysWithoutOrdersBundle,
        hideFinishedOrdersBundle,
        textFilterBundle,
      }}
    >
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: isLoading ? 0.5 : 1 }}
        exit={{ opacity: 0 }}
        className={ClientUtils.twClassNames(
          "col-start-1 col-end-1 row-start-2 row-end-2 grid h-full grid-rows-[minmax(0,1fr),auto]",
          isLoading && "pointer-events-none",
          props.className
        )}
      >
        {isLoading && (
          <AppLoader className="absolute left-[calc(50%-1rem)] top-1/3 mx-auto h-8 w-8" />
        )}

        <ul
          className={ClientUtils.classNames(
            "flex flex-col gap-8 overflow-auto",
            intervalString
          )}
          onScroll={(e) => {
            //console.log(e.currentTarget.scrollTop);
            scrollTopRef.current = e.currentTarget.scrollTop;
          }}
          ref={ulRef}
          key={intervalString}
        >
          <Events />
        </ul>
        <footer>
          <FilterDropdown />
        </footer>
      </motion.main>
    </ListViewContext.Provider>
  );
};

const FilterDropdown = () => {
  const {
    showDaysWithoutOrdersBundle,
    hideFinishedOrdersBundle,
    textFilterBundle,
  } = useContext(ListViewContext);

  return (
    <Dropdown title="Visa filter" nonRound openTop>
      <main className="bg-brand-blue-100 p-2">
        <p className="flex gap-3 p-2">
          <input
            className="cursor-pointer"
            type="checkbox"
            id="showDaysWithoutEvents"
            checked={showDaysWithoutOrdersBundle.value}
            onChange={(e) => showDaysWithoutOrdersBundle.set(e.target.checked)}
          />
          <label
            htmlFor="showDaysWithoutEvents"
            className="cursor-pointer text-sm"
          >
            Visa dagar utan arbetsordrar
          </label>
        </p>
        <p className="flex gap-3 p-2">
          <input
            className="cursor-pointer"
            type="checkbox"
            id="hideFinishedOrders"
            checked={hideFinishedOrdersBundle.value}
            onChange={(e) => hideFinishedOrdersBundle.set(e.target.checked)}
          />
          <label
            htmlFor="hideFinishedOrders"
            className="cursor-pointer text-sm"
          >
            Göm klarmarkerade ordrar
          </label>
        </p>
        <p className="p-2">
          <input
            type="text"
            placeholder="Filtrera"
            onChange={(e) => textFilterBundle.set(e.target.value)}
            className="w-full rounded p-2"
          />
        </p>
      </main>
    </Dropdown>
  );
};

const Events = () => {
  const { calendarHelper, isLoading } = useContext(CalendarContext);
  const {
    showDaysWithoutOrdersBundle,
    hideFinishedOrdersBundle,
    textFilterBundle,
  } = useContext(ListViewContext);

  const applyFilters = (
    workOrders: WorkOrder.HandymanWithPermissionsType[]
  ) => {
    return workOrders.filter((wo) => {
      if (hideFinishedOrdersBundle.value && wo.isFinished) {
        return false;
      }

      const textFilter = textFilterBundle.value;
      if (!!textFilter) {
        const filterBy = [
          wo.orderId,
          `#${wo.serialNumber}`,
          wo.description,
          wo.additionalInformation,
          wo.typeOfService.name,
          wo.contact.email,
          wo.contact.fullName,
          wo.contact.phone,
          wo.contact.phone2,
          wo.location.city,
          wo.location.country,
          wo.location.street,
          wo.location.postalCode,
          wo.internalDescription,
          wo.category.name,
          new DateHelper(wo.startDate).dateTimeInputFormat,
          new DateHelper(wo.endDate).dateTimeInputFormat,
        ];
        return textFilter.split(" ").every((str) => {
          return filterBy.some((filter) => {
            if (!filter) {
              return false;
            }
            return filter.toLowerCase().includes(str.toLowerCase());
          });
        });
      }

      return true;
    });
  };

  const handymanCtx = useContext(HandymanContext);

  let daysAndWorkOrders: {
    dayHelper: DateHelper;
    workOrders: WorkOrder.HandymanWithPermissionsType[];
  }[] = [];

  calendarHelper.calendarDays.forEach((dayHelper) => {
    const { workOrders } = handymanCtx.merger.getEntry(dayHelper.date);
    const filteredWorkOrders = applyFilters(workOrders);

    if (!!filteredWorkOrders.length || showDaysWithoutOrdersBundle.value) {
      daysAndWorkOrders.push({ dayHelper, workOrders: filteredWorkOrders });
    }
  }, []);

  return (
    <>
      {!showDaysWithoutOrdersBundle.value &&
        !daysAndWorkOrders.length &&
        !isLoading && (
          <p className="p-2">
            Det finns inga dagar med evenemang under detta tidsspan
          </p>
        )}
      {daysAndWorkOrders.map((element) => {
        /*   const changeOfMonth = mostRecentMonth !== dayHelper.month;
        mostRecentMonth = dayHelper.month; */

        const label = `${element.dayHelper.dayOfWeek}, ${element.dayHelper.dayOfMonth} ${element.dayHelper.monthLabel}`;

        return (
          <li
            className="flex flex-col justify-between"
            key={element.dayHelper.date.getTime()}
          >
            <header className="col-span-1 border-b p-2">
              <span className={ClientUtils.twClassNames("text-base")}>
                {label}
              </span>
            </header>
            <main className="col-span-3">
              <ul className="flex w-full flex-col gap-2 p-2">
                {element.workOrders.map((workOrder, i) => {
                  let key = workOrder.orderId;
                  if (process.env.NODE_ENV === "development") {
                    key += i;
                  }
                  return (
                    <HandymanWorkOrderListItem key={key} order={workOrder} />
                  );
                })}
              </ul>
            </main>
          </li>
        );
      })}
    </>
  );
};

export default CalendarListView;
