import ClientUtils from "./../../utils/ClientUtils";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { useIsPreviousPageFromCurrentSite } from "../../hooks/hooks";
import { GlobalContext } from "../../top-level-contexts";

import HistoryBackButton from "../common/navigation/HistoryBackButton";

export type PageHeaderProps = {
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  noBackButton?: boolean;
  hideOnKeyboardOpen?: boolean;
  overrideLink?: string;
  overrideLinkWhenNoHistory?: string;
} & (
  | {
      heading: string;
      subheading?: string;
      RenderHeadings?: undefined;
    }
  | {
      heading?: undefined;
      subheading?: undefined;
      RenderHeadings: (props: {
        headingClassName: string;
        subheadingClassName: string;
      }) => React.ReactElement;
    }
);

const PageHeader = (props: PageHeaderProps) => {
  /*  const isLg = useMediaQuery(TailwindBreakpoint.lg); */
  const base = "#103236";
  const from = "#72cbd6";
  const to = base;
  const { isOnline } = useContext(GlobalContext);

  const previousPageIsTheSame = useIsPreviousPageFromCurrentSite();
  const [params] = useSearchParams();
  const goBackTo = params.get("goBackTo");
  const { RenderHeadings } = props;
  const loading = props.loading;

  let key: string;
  if (loading) {
    key = "loadingPageHeaderSmall";
  } else {
    key = "staticPageHeaderSmall";
  }

  const headingClassName = "text-base leading-5 md:text-xl lg:text-2xl";
  const subheadingClassName = "text-sm leading-4";

  let overrideLink: string | undefined;
  if (props.overrideLink) {
    overrideLink = props.overrideLink;
  } else if (!previousPageIsTheSame) {
    overrideLink = props.overrideLinkWhenNoHistory;
  }

  const customBack = overrideLink || !!goBackTo;
  const showBackButton =
    !props.noBackButton && (previousPageIsTheSame || customBack);

  return (
    <motion.header
      id="page-header"
      key={key}
      className={ClientUtils.twClassNames(
        "relative min-h-[65px] items-center gap-x-2 gap-y-1 p-1 py-2 pr-[70px] text-white",
        "bg-gradient-to-br from-main-bg-light to-main-bg-dark",
        "grid grid-cols-[auto,minmax(0,1fr)] lg:grid-cols-[auto,minmax(0,1fr),auto]",
        !showBackButton && "pl-5",
        !isOnline && "pb-12",
        props.className
      )}
      initial={
        loading
          ? {
              background: `linear-gradient(to bottom, transparent 4px, ${base} 4px),linear-gradient(to right, ${from} 0%, ${to} 1%)`,
            }
          : undefined
      }
      animate={
        loading
          ? {
              background: `linear-gradient(to bottom, transparent 4px, ${base} 4px), linear-gradient(to right, ${from} 100%, ${to} 100%)`,
            }
          : undefined
      }
      transition={
        loading
          ? {
              repeat: Infinity,
              repeatDelay: 0.8,
              duration: 0.8,
            }
          : undefined
      }
    >
      {showBackButton && (
        <span className="flex w-12">
          <HistoryBackButton overrideLink={overrideLink} />
        </span>
      )}
      {RenderHeadings && (
        <RenderHeadings
          headingClassName={headingClassName}
          subheadingClassName={subheadingClassName}
        />
      )}
      {!RenderHeadings && (
        <span className="flex flex-col gap-1">
          <>
            <h1 className={headingClassName}>{props.heading}</h1>
            {props.subheading && (
              <h2 className={subheadingClassName}>{props.subheading}</h2>
            )}
          </>
        </span>
      )}

      {props.children}
      {!isOnline && (
        <span className="absolute bottom-0 left-0 col-span-3 flex w-full justify-center bg-red-800 px-4 py-1 text-sm text-white">
          Ingen internetuppkoppling
        </span>
      )}
    </motion.header>
  );
};

export default PageHeader;
