import { Controller, UseFormReturn } from "react-hook-form";
import { useFormError } from "../../../../../hooks/form-hooks";
import { IpisFormInputError } from "@ipis/client-essentials";
import SimpleRichTextEditor from "../../../../rich-text-editor/SimpleRichTextEditor";
import { IpisFormElement } from "@eljouren/domain";

interface Props {
  className?: string;
  question: IpisFormElement.RichTextType;
  form: UseFormReturn<any>;
  setFocus?: (focused: boolean) => void;
}

const ChecklistDecoupledRichTextQuestion = (props: Props) => {
  const q = props.question;
  const name = `${q.id}.value`;
  const error = useFormError({ form: props.form, name });

  return (
    <Controller
      control={props.form.control}
      name={name}
      render={(renderProps) => {
        return (
          <div className="col flex flex-col gap-2">
            <SimpleRichTextEditor
              label={q.title}
              useCharacterCount
              refCallback={renderProps.field.ref}
              defaultHtml={renderProps.field.value}
              //required={!el.optional}
              /* motionProps={{
					  style: animate,
					  whileFocus: whileFocus,
					}} */
              editorId={q.id}
              setFocus={(focused) => {
                props.setFocus?.(focused);
              }}
              onChange={(stringifiedSlateObject) => {
                renderProps.field.onChange(stringifiedSlateObject);
              }}
            />
            {error && (
              <IpisFormInputError error={error} humanReadableName={q.title} />
            )}
          </div>
        );
      }}
    />
  );
};

export default ChecklistDecoupledRichTextQuestion;
