import React, { useRef, useState } from "react";
import QueueContext from "./QueueContext";
import ProcessQueue from "../../utils/process/ProcessQueue";
import Process from "../../utils/process/Process";

interface Props {
  children?: React.ReactNode;
}

const QueueContextProvider = (props: Props) => {
  const [queue, setQueue] = useState<ProcessQueue | null>(null);
  const previousQueueRef = useRef<ProcessQueue | null>(null);

  function addToQueue(process: Process) {
    if (!queue || queue.isFinished() || queue.isCanceled()) {
      previousQueueRef.current = queue;
      const newQueue = new ProcessQueue({ processes: [process] });
      newQueue.run();
      setQueue(newQueue);
    } else {
      const processWithSameId = queue.findProcessById(process.id);
      if (processWithSameId && !processWithSameId.isFinished()) {
        processWithSameId.addTasks(process.getTasks());
      } else {
        const newQueue = queue.addProcesses([process]);
        newQueue.run();
        setQueue(newQueue);
      }
    }
  }
  function cleanUp() {
    if (queue?.isFinished() || queue?.isCanceled()) {
      previousQueueRef.current = queue;
      setQueue(null);
    }
  }

  return (
    <QueueContext.Provider
      value={{
        addToQueue,
        queue,
        previousQueue: previousQueueRef.current,
        cleanUp,
      }}
    >
      {props.children}
    </QueueContext.Provider>
  );
};

export default QueueContextProvider;
