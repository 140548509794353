"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderConfirmation = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrder_1 = require("./WorkOrder");
var WorkOrderHousingType_1 = require("./WorkOrderHousingType");
var WorkOrderInvoice_1 = require("./WorkOrderInvoice");
var WorkOrderConfirmation;
(function (WorkOrderConfirmation) {
    var PaymentInfoCompanySchema = function (args) {
        return centralized_zod_1.z.object({
            isBusinessCustomer: centralized_zod_1.z.literal(true),
            invoice: args.paymentFlowAllowed
                ? WorkOrderInvoice_1.WorkOrderInvoice.PartialSchema.optional()
                : WorkOrderInvoice_1.WorkOrderInvoice.Schema,
            housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema.optional(),
            customerHasActivelyChosenToSkipDiscount: centralized_zod_1.z.boolean(),
            paymentFlowAllowed: centralized_zod_1.z.literal(args.paymentFlowAllowed),
        });
    };
    var PaymentInfoPrivateSchema = function (args) {
        return centralized_zod_1.z.object({
            isBusinessCustomer: centralized_zod_1.z.literal(false),
            invoice: args.paymentFlowAllowed
                ? WorkOrderInvoice_1.WorkOrderInvoice.PartialSchema.optional()
                : WorkOrderInvoice_1.WorkOrderInvoice.Schema,
            housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema,
            customerHasActivelyChosenToSkipDiscount: centralized_zod_1.z.boolean(),
            paymentFlowAllowed: centralized_zod_1.z.literal(args.paymentFlowAllowed),
        });
    };
    WorkOrderConfirmation.PaymentInfoSchema = function (args) {
        return centralized_zod_1.z.union([PaymentInfoCompanySchema(args), PaymentInfoPrivateSchema(args)]);
    };
    WorkOrderConfirmation.BaseFieldsSchema = WorkOrder_1.WorkOrder.CustomerSchema.pick({
        description: true,
        additionalInformation: true,
        location: true,
        contact: true,
    });
    WorkOrderConfirmation.BaseFieldsWithGdpr = WorkOrderConfirmation.BaseFieldsSchema.merge(centralized_zod_1.z.object({
        gdprConfirmed: centralized_zod_1.z.literal(true),
    }));
    WorkOrderConfirmation.Schema = function (args) {
        return centralized_zod_1.z.union([
            WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoCompanySchema(args)),
            WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoPrivateSchema(args)),
        ]);
    };
    WorkOrderConfirmation.UnknownSchema = centralized_zod_1.z.union([
        WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoCompanySchema({ paymentFlowAllowed: false })),
        WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoPrivateSchema({ paymentFlowAllowed: false })),
        WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoPrivateSchema({ paymentFlowAllowed: true })),
        WorkOrderConfirmation.BaseFieldsWithGdpr.merge(PaymentInfoCompanySchema({ paymentFlowAllowed: true })),
    ]);
})(WorkOrderConfirmation || (WorkOrderConfirmation = {}));
exports.WorkOrderConfirmation = WorkOrderConfirmation;
