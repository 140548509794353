import { Where } from "./api-helper-types";
import WhereLogic from "./WhereLogic";

export default class SearchParams extends URLSearchParams {
  fields(fields: string[]): this {
    fields.forEach((field) => this.append("fields", field));

    return this;
  }

  append(...args: Parameters<URLSearchParams["append"]>): this {
    super.append(...args);
    return this;
  }

  where(where: Where): this {
    this.append("where_fields", where.field);
    this.append("where_operators", where.operator);
    this.append("where_values", where.value);

    return this;
  }

  private value<T extends any>(val: T): T {
    if (typeof val === "string") {
      return `'${val}'` as T;
    }
    return val;
  }

  paginate(args: { perPage: number; pageIndex: number }): this {
    this.limit(args.perPage);
    this.offset(args.perPage * args.pageIndex);
    return this;
  }
  limit(val: number): this {
    this.append("limit", val.toString());
    return this;
  }
  offset(val: number): this {
    this.append("offset", val.toString());
    return this;
  }

  search(search: any, fields: string[] | "allFields"): this {
    let array: string[];
    if (fields === "allFields") {
      array = this.getAll(fields);
    } else {
      array = fields;
    }
    array.forEach((field) => {
      this.where({
        field,
        operator: "like",
        value: this.value(`%${search}%`),
      });
    });

    return this;
  }

  whereLogic(logic: string | WhereLogic): this {
    if (typeof logic === "string") {
      this.append("where_logic", logic);
    } else {
      this.append("where_logic", logic.build());
    }
    return this;
  }

  orderBy(field: string): this {
    this.set("order", field);
    return this;
  }

  direction(dir: "desc" | "asc"): this {
    this.set("direction", dir);
    return this;
  }
}
