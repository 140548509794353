import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import React from "react";
import { createPortal } from "react-dom";
import { useOnEscapeClick } from "../../hooks/keyboard-event-hooks";
import { IpisButton, classNames } from "@ipis/client-essentials";

interface MyDialogComponentProps extends HTMLMotionProps<"div"> {
  children?: React.ReactNode;
  isOpen: boolean;
  cancel?: () => void;
  addCloseButton?: boolean;
}

const MyDialog: React.FC<MyDialogComponentProps> = (props) => {
  const { children, isOpen, className, cancel, addCloseButton, ...rest } =
    props;

  useOnEscapeClick(() => {
    if (props.cancel) {
      props.cancel();
    }
  });

  const addClose = props.addCloseButton && props.cancel;
  return createPortal(
    <AnimatePresence>
      {props.isOpen && (
        <motion.div
          className="fixed inset-0 z-[60] flex h-full w-full items-center justify-center bg-dark-900 bg-opacity-60 lg:p-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          role="dialog"
          aria-modal="true"
          {...rest}
        >
          <motion.div
            className={classNames(
              "relative h-full w-full rounded bg-light-background",
              addClose && "pt-4"
            )}
          >
            {addClose && (
              <div className="absolute right-4 top-4">
                <IpisButton
                  onlyIcon
                  variant="text"
                  label="Stäng förhandsvisning"
                  onClick={props.cancel}
                  icon={{
                    position: "trailing",
                    type: "close",
                  }}
                />
              </div>
            )}
            {props.children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default MyDialog;
