import { AnimatePresence, motion, Transition, Variants } from "framer-motion";
import { useContext, useEffect, useRef, useState } from "react";
import { useOnOutsideClick } from "../../hooks/hooks";
import { GlobalContext } from "../../top-level-contexts";
import ClientUtils from "./../../utils/ClientUtils";

import Logo from "../common/brand/Logo";
import AppMenuIcon from "../icons/AppMenuIcon";
import AppXIcon from "../icons/AppXIcon";
import AppNavLinks from "./AppNavLinks";

const navVariants: Variants = {};

const transition: Transition = {
  type: "just",
};

const mainVariants: Variants = {
  open: {
    x: 0,
    transition,
  },
  closed: {
    x: "100%",
    transition,
  },
};

interface Props {
  className?: string;
  size?: number;
}

/* 
  You can sometimes scroll outside of the viewport when the sidebar is open - not sure why
*/
const AppMobileSidebar = (props: Props) => {
  const { location, signInState } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const ref = useRef<HTMLElement | null>(null);

  useOnOutsideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  function onToggleClick() {
    setIsOpen(!isOpen);
  }

  if (!signInState.isSignedIn) {
    return <></>;
  }

  return (
    <motion.nav
      ref={ref}
      className={ClientUtils.twClassNames(
        "absolute right-0 top-0 z-50 flex h-full flex-col"
      )}
      role="navigation"
      variants={navVariants}
      initial="closed"
      animate={"open"}
      exit="closed"
    >
      <AnimatePresence initial exitBeforeEnter>
        <motion.button
          key={isOpen ? "open" : "closed"}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            duration: 0.25,
          }}
          data-testid="sidebar-toggle"
          aria-label="Meny"
          className={ClientUtils.twClassNames(
            "absolute right-2 top-2  z-50 ml-auto items-center justify-center rounded-full p-2 text-white",
            props.className
          )}
          onClick={onToggleClick}
        >
          {!isOpen && <AppMenuIcon size={props.size || 28} />}
          {isOpen && <AppXIcon size={props.size || 28} />}
        </motion.button>
      </AnimatePresence>
      <AnimatePresence>
        {isOpen && (
          <motion.main
            variants={mainVariants}
            initial="closed"
            animate="open"
            exit="closed"
            className="z-40 mx-auto flex h-full w-72 max-w-[100vw] flex-col gap-4 bg-gradient-to-br from-main-bg-light to-main-bg-dark py-16"
          >
            <AppNavLinks className="justify-self-end" />
            <Logo wrapperClassName="mt-auto max-w-[150px] mx-auto" center />
          </motion.main>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

export default AppMobileSidebar;
