import { twMerge } from "tailwind-merge";

namespace ClientUtils {
  export function renderOne<T>(
    args: T,
    renderFunctions: ((
      props: T
    ) => React.ReactElement | null | undefined | false)[]
  ): React.ReactNode {
    for (const renderFunction of renderFunctions) {
      const element = renderFunction(args);
      if (element) {
        return element;
      }
    }

    return null;
  }

  /*
    REACT
  */
  export function classNames(...classNames: any[]): string | undefined {
    return (
      classNames.filter((str) => typeof str === "string").join(" ") || undefined
    );
  }
  export function twClassNames(...classNames: any[]): string | undefined {
    const filtered = classNames.filter((str) => typeof str === "string");
    if (!filtered.length) {
      return undefined;
    }
    return twMerge(filtered);
  }

  export type UnionToIntersection<U> = (
    U extends any ? (k: U) => void : never
  ) extends (k: infer I) => void
    ? I
    : never;
}

export default ClientUtils;
