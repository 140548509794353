"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisTextInputCompact = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var client_utils_1 = require("../../../../utils/client-utils");
var FauxFieldsetWrapper_1 = require("../components/FauxFieldsetWrapper");
var IpisFormInputError_1 = require("../components/IpisFormInputError");
var IpisFormInputNote_1 = require("../components/IpisFormInputNote");
var IpisTextInputCompact = function (props) {
    var _a, _b;
    var _c = (0, react_1.useState)(false), isFocused = _c[0], setIsFocused = _c[1];
    var _d = (0, react_1.useState)(false), isHovered = _d[0], setIsHovered = _d[1];
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var error = (0, form_hooks_1.useFormError)({ form: props.form, name: props.name });
    var inputProps = props.inputProps, label = props.label;
    var size = (_a = props.size) !== null && _a !== void 0 ? _a : "sm";
    var registerProps = (0, form_hooks_1.useFormRegisterProps)({
        props: props,
        type: props.type === "number" ? "number" : "text",
    });
    var readOnly = props.readOnly || !!(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly);
    var tabIndexProps = {};
    if (readOnly) {
        tabIndexProps.tabIndex = -1;
    }
    return ((0, jsx_runtime_1.jsxs)(FauxFieldsetWrapper_1.FauxFieldsetWrapper, __assign({ className: (0, client_utils_1.classNames)("w-full", props.className), leftSidePaddingInPixels: 16, onBlur: props.onBlur, onFocus: props.onFocus, isFocused: isFocused, isHovered: isHovered, size: size, readOnly: readOnly, label: {
            text: label,
        }, required: props.required, onDarkBackground: props.onDarkBackground }, inputProps, { children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.input, __assign({ type: (_b = props.type) !== null && _b !== void 0 ? _b : "text", id: props.id, placeholder: props.placeholder, style: {}, className: (0, client_utils_1.classNames)("col-span-3 col-start-1 row-start-1 w-full rounded bg-transparent p-4 placeholder-semi-faded focus:outline-none", props.onDarkBackground && !readOnly && "text-light-background", props.onDarkBackground && readOnly && "text-light-background/80", readOnly && "text-semi-faded") }, registerProps, { onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); }, disabled: !!props.disabled, readOnly: readOnly }, props.inputProps, tabIndexProps, { onFocus: function (e) {
                    var _a;
                    setIsFocused(true);
                    (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
                }, onBlur: function (e) {
                    var _a, _b;
                    setIsFocused(false);
                    (_a = registerProps.onBlur) === null || _a === void 0 ? void 0 : _a.call(registerProps, e);
                    (_b = props === null || props === void 0 ? void 0 : props.onBlur) === null || _b === void 0 ? void 0 : _b.call(props, e);
                }, required: !!props.required })), !!props.htmlNote && ((0, jsx_runtime_1.jsx)(IpisFormInputNote_1.IpisFormInputNote, { html: props.htmlNote, className: "col-span-3" })), !!error && ((0, jsx_runtime_1.jsx)(IpisFormInputError_1.IpisFormInputError, { error: error, humanReadableName: props.label || props.name, className: "col-span-3" }))] })));
};
exports.IpisTextInputCompact = IpisTextInputCompact;
