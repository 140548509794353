"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisDialog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_dom_1 = require("react-dom");
var keyboard_event_hooks_1 = require("../../hooks/keyboard-event-hooks");
var IpisDialog = function (props) {
    var children = props.children, isOpen = props.isOpen, className = props.className, cancel = props.cancel, rest = __rest(props, ["children", "isOpen", "className", "cancel"]);
    (0, keyboard_event_hooks_1.useOnEscapeClick)(function () {
        if (props.cancel) {
            props.cancel();
        }
    });
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { children: props.isOpen && ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, __assign({ className: "fixed inset-0 z-[60] flex h-full w-full items-center justify-center bg-dark-900 bg-opacity-60 lg:p-8", initial: { opacity: 0 }, animate: { opacity: 1 }, exit: { opacity: 0 }, role: "dialog", "aria-modal": "true" }, rest, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "h-full w-full rounded  bg-light-background backdrop-blur", children: props.children }) }))) }), document.body);
};
exports.IpisDialog = IpisDialog;
