import ClientUtils from "./../../../../utils/ClientUtils";
import ScrollIndicatorContainer from "../../../../components/ScrollIndicatorContainer";

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const CustomerFormMainSection = (props: Props) => {
  return (
    <ScrollIndicatorContainer
      as="section"
      innerClassName={ClientUtils.twClassNames("", props.className)}
    >
      {props.children}
    </ScrollIndicatorContainer>
  );
};

export default CustomerFormMainSection;
