"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Presigned_DataUrlUploadInput = void 0;
var zod_1 = require("zod");
var Presigned_DataUrlUploadInput;
(function (Presigned_DataUrlUploadInput) {
    Presigned_DataUrlUploadInput.Schema = zod_1.z.object({
        dataUrl: zod_1.z.string(),
        name: zod_1.z.string().max(80).optional(),
        description: zod_1.z.string().max(250).optional(),
        clientId: zod_1.z.string().optional(),
    });
})(Presigned_DataUrlUploadInput || (Presigned_DataUrlUploadInput = {}));
exports.Presigned_DataUrlUploadInput = Presigned_DataUrlUploadInput;
