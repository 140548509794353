import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import ClientUtils from "./../../../utils/ClientUtils";

import { useQuery } from "react-query";
import { useBundledState } from "../../../hooks/hooks";

import AppSearchIcon from "../../icons/AppSearchIcon";
import AppXIcon from "../../icons/AppXIcon";
import { AppLoader } from "../loaders/AppLoader";
import ContextLikeMenu from "../menu/ContextLikeMenu";

interface Props<T> {
  className?: string;
  label?: string;
  placeholder?: string;
  id: string;
  fetch(search: string): Promise<T>;
  Render(props: { data: T; close(): void }): JSX.Element;
  disabled?: boolean;
  inputClassName?: string;
  inputWrapperClassName?: string;
}

/*
  ToDo:
  - fix a bug where this stops searching after the first error:ed query
*/
const SearchFieldSlideDown = <T,>(props: Props<T>) => {
  const search = useBundledState("");
  const showResults = useBundledState(false);
  const [customLoadingState, setCustomLoadingState] = useState(false);
  const searchRef = useRef(search.value);

  const res = useQuery(
    ["searchResults", props.id],
    () => {
      return props.fetch(searchRef.current);
    },
    {
      enabled: false,
      retry: false,
    }
  );

  const timeoutDelay = 400;

  const { Render } = props;
  useEffect(() => {
    searchRef.current = search.value;
  }, [search.value]);

  const { refetch } = res;
  const { set: setShowResults } = showResults;
  useEffect(() => {
    const searchQueryEntered = search.value.length > 0;
    setCustomLoadingState(searchQueryEntered);
    setShowResults(searchQueryEntered);

    const timeout = setTimeout(() => {
      if (searchQueryEntered) {
        refetch();
      }
      setCustomLoadingState(false);
    }, timeoutDelay);

    return () => {
      clearTimeout(timeout);
    };
  }, [search.value, refetch, setShowResults]);

  const isLoading = res.isLoading || customLoadingState;
  const showLoadingIndicator =
    res.isLoading || (res.data === undefined && customLoadingState);

  return (
    <div
      className={ClientUtils.twClassNames(
        "relative max-w-full",
        props.disabled && "pointer-events-none opacity-80",
        props.className
      )}
    >
      <p className={"flex w-full flex-col gap-2"}>
        {props.label && <label htmlFor={props.id}>{props.label}</label>}

        <span
          className={ClientUtils.twClassNames(
            "grid w-full grid-cols-[auto,minmax(0,1fr),auto] items-center justify-between gap-2 rounded border border-main-bg-light border-transparent bg-bg-base-layer px-4 pr-2 focus-within:border-black lg:border-bg-base-layer",
            props.inputWrapperClassName
          )}
        >
          <AppSearchIcon size={24} />
          <input
            className={ClientUtils.twClassNames(
              "max-w-full bg-bg-base-layer p-2 focus:outline-none",
              props.inputClassName
            )}
            id={props.id}
            onFocus={() => showResults.set(search.value.length > 0)}
            type="text"
            value={search.value}
            onChange={(e) => search.set(e.target.value)}
            placeholder={props.placeholder}
            disabled={props.disabled}
            aria-label={props.label ? undefined : props.placeholder}
          />
          {showResults.value && (
            <button className="pr-2" onClick={() => showResults.set(false)}>
              <AppXIcon size={24} />
            </button>
          )}
        </span>
      </p>
      <div aria-live="polite">
        <AnimatePresence>
          {showResults.value && (
            <ContextLikeMenu
              onOutsideClick={() => showResults.set(false)}
              className="absolute flex min-h-[100px] w-full justify-center border-2"
            >
              {showLoadingIndicator && (
                <AppLoader className="absolute h-10 w-10 border" />
              )}
              <motion.span
                data-test-id="search-results"
                data-search-results-for={props.id}
                aria-label="Sökresultat"
                className="h-full w-full"
                animate={{
                  opacity: isLoading ? 0.4 : 1,
                }}
              >
                {!res.isError && res.data && (
                  <Render
                    data={res.data}
                    close={() => showResults.set(false)}
                  />
                )}
                {res.isError && <p>Något gick fel vid sökningen</p>}
              </motion.span>
            </ContextLikeMenu>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default SearchFieldSlideDown;
