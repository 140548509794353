import React, { useRef } from "react";
import ClientUtils from "../utils/ClientUtils";
import useElementDimensions from "../hooks/use-element-dimensions";
import { AnimatePresence } from "framer-motion";

export interface RenderPlaceholderProps<PassThrough> {
  style: React.CSSProperties;
  passThroughProps: PassThrough;
}

export interface RenderItemProps<T, PassThrough> {
  item: T;
  style: React.CSSProperties;
  passThroughProps: PassThrough;
  index: number;
  totalItemCount: number;
}

interface Props<T, PassThrough> {
  id: string;
  minItemWidth?: number;
  gap?: number;
  passThroughProps: PassThrough;
  padding?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
    x?: number;
    y?: number;
    all?: number;
  };
  items: T[];
  RenderPlaceholder: (
    props: RenderPlaceholderProps<PassThrough>
  ) => JSX.Element;
  RenderItem: (props: RenderItemProps<T, PassThrough>) => JSX.Element;
  itemHeight: number;
}

/* 
	Rerender issues
*/
const PlaceholderGridList = <T, PassThrough>(props: Props<T, PassThrough>) => {
  const { RenderPlaceholder, RenderItem } = props;
  const listRef = useRef<HTMLUListElement | null>(null);
  const listDimensions = useElementDimensions(listRef);

  const listWidth = listDimensions.width ?? 0;
  const minColumnWidth = props.minItemWidth ?? 200;
  const columnCount = Math.floor(listWidth / minColumnWidth);
  const useSetColumns = listDimensions.initialised && listDimensions.supported;
  const basePlaceholderCount = useSetColumns ? columnCount : 0;
  const placeholderImageCount = Math.max(
    0,
    basePlaceholderCount - props.items.length
  );
  const mappableArray = Array.from({ length: placeholderImageCount }).fill(
    null
  );

  const paddingLeft =
    props.padding?.left ?? props.padding?.x ?? props.padding?.all ?? 0;
  const paddingRight =
    props.padding?.right ?? props.padding?.x ?? props.padding?.all ?? 0;
  const paddingTop =
    props.padding?.top ?? props.padding?.y ?? props.padding?.all ?? 0;
  const paddingBottom =
    props.padding?.bottom ?? props.padding?.y ?? props.padding?.all ?? 0;

  const style: React.CSSProperties = {
    gridTemplateColumns: useSetColumns
      ? `repeat(${columnCount}, minmax(0, 1fr))`
      : `repeat(auto-fill, minmax(${minColumnWidth}, 1fr)))`,
    minHeight: props.itemHeight + paddingTop + paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    gap: props.gap,
    //boxSizing: "content-box",
  };

  /* if (useSetColumns) {
    return <div>{columnCount}</div>;
  } */

  return (
    <ul
      ref={listRef}
      className={ClientUtils.twClassNames("relative grid")}
      style={style}
    >
      <AnimatePresence initial>
        {props.items.map((item, index) => {
          const key = props.id + index;
          return (
            <RenderItem
              passThroughProps={props.passThroughProps}
              style={{
                height: props.itemHeight,
              }}
              key={key}
              item={item}
              index={index}
              totalItemCount={props.items.length}
            />
          );
        })}

        {useSetColumns && !!placeholderImageCount && !!RenderPlaceholder && (
          <div
            key={props.id + "placeholder"}
            className={"absolute grid overflow-hidden"}
            aria-hidden="true"
            style={{
              ...style,
              width: "100%",
            }}
          >
            {mappableArray.map((_, index) => {
              const gridColumn = index + props.items.length + 1;
              const key = props.id + gridColumn + "placeholder";
              const style: React.CSSProperties = {
                gridColumn,
                height: props.itemHeight,
              };

              return (
                <RenderPlaceholder
                  passThroughProps={props.passThroughProps}
                  key={key}
                  style={style}
                />
              );
            })}
          </div>
        )}
      </AnimatePresence>
    </ul>
  );
};

export default PlaceholderGridList;
