"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChecklistAnswers = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var ChecklistAnswers;
(function (ChecklistAnswers) {
    /*
      Single answers
    */
    ChecklistAnswers.SingleAnswerSchema = centralized_zod_1.z.object({
        answer: centralized_zod_1.z.string(),
        comment: centralized_zod_1.z.string().nullable(),
        checklistItemId: centralized_zod_1.z.string(),
    });
    /*
      Complete answers
    */
    // Domain
    ChecklistAnswers.Schema = ChecklistAnswers.SingleAnswerSchema.array();
})(ChecklistAnswers || (ChecklistAnswers = {}));
exports.ChecklistAnswers = ChecklistAnswers;
