import { motion } from "framer-motion";
import { useEffect, useRef } from "react";
import CheckmarkIcon from "../icons/AppCheckmarkIcon";
import AppErrorIcon from "../icons/AppErrorIcon";
import { ToastWithId } from "./Modal";
import AppXIcon from "../icons/AppXIcon";

type ToastProps = ToastWithId & { closeToast(toast: ToastWithId): void };

const Toast = (props: ToastProps) => {
  const durationInMs = props.timeVisibleInMs ?? 4000;
  const base = "#ffffff";
  const from = "#314a4e";
  const to = base;

  const timeoutRef = useRef<null | NodeJS.Timeout>(null);
  const timeLeftRef = useRef(durationInMs);

  const { closeToast, ...rest } = props;

  useEffect(() => {
    const now = new Date();
    if (!timeoutRef.current) {
      timeoutRef.current = setTimeout(() => {
        closeToast(rest);
      }, timeLeftRef.current);
    }

    return () => {
      if (timeoutRef.current) {
        const then = new Date();
        const timePassed = Number(then) - Number(now);
        const timeLeft = Math.max(timeLeftRef.current - timePassed, 0);
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
        timeLeftRef.current = timeLeft;
      }
    };
  }, [closeToast, rest]);

  function close() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    props.closeToast(props);
  }

  const toastTitleId = `toastTitle${props.id}`;
  const toastDescriptionId = `toastDescription${props.id}`;

  return (
    <motion.div
      className="flex flex-col gap-2 overflow-hidden rounded border bg-white shadow-lg"
      role="alertdialog"
      layout
      aria-labelledby={toastTitleId}
      aria-describedby={toastDescriptionId}
      initial={{
        y: -100,
      }}
      animate={{
        y: 0,
      }}
      exit={{
        x: "calc(60vw + 100%)",
      }}
    >
      <span className="flex items-center justify-between gap-4 px-4 pt-4">
        <h2 id={toastTitleId} className="text-lg">
          {props.title}
        </h2>

        <button
          onClick={() => close()}
          className="z-40 col-start-1 row-start-1 mb-auto ml-auto"
        >
          <AppXIcon size={20} />
        </button>
      </span>
      <motion.span
        className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-4 px-4 pb-4"
        initial={{
          background: `linear-gradient(to top, transparent 4px, ${base} 4px),linear-gradient(to left, ${from} 0%, ${to} 1%)`,
        }}
        animate={{
          background: `linear-gradient(to top, transparent 4px, ${base} 4px), linear-gradient(to left, ${from} 100%, ${to} 100%)`,
        }}
        transition={{
          duration: durationInMs / 1000,
        }}
      >
        {props.toastType === "success" && (
          <CheckmarkIcon size={30} className="text-green-600" />
        )}
        {props.toastType === "error" && (
          <AppErrorIcon size={30} className="text-red-600" />
        )}
        <p id={toastDescriptionId} className="text-base text-gray-700">
          {props.prompt}
        </p>
      </motion.span>
    </motion.div>
  );
};

export default Toast;
