import { Utils } from "@eljouren/utils";
import { DeepPartial } from "@trpc/server";
import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import { FieldValues, UseFormProps, useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ZodSchema } from "zod";

type SearchParamConversion = {
  key: string;
} & (
  | {
      treatAs: "string" | "number" | "boolean";
    }
  | {
      treatAs: "array";
      separator: string;
    }
  | {
      treatAs: "custom";
      convertFromString: (str: string | null) => any;
      convertToString: (obj: any) => string | null;
    }
);

type CustomFormProps<T extends FieldValues> = Omit<
  UseFormProps<T>,
  "defaultValues"
> & {
  defaultValues?: DeepPartial<T>;
};
type ActualDefault<T extends FieldValues> = UseFormProps<T>["defaultValues"];
type Default<T extends FieldValues> = Partial<T>;

export function useSearchParamForm<T extends FieldValues>(args: {
  map: {
    [key in keyof T]?: SearchParamConversion;
  };
  formProps?: CustomFormProps<T>;
  valueSanitizer?: ZodSchema<any>;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const form = useForm<T>({
    ...args.formProps,
    defaultValues: (getSavedValues() ?? args.formProps?.defaultValues) as
      | ActualDefault<T>
      | undefined,
  });

  function getSavedValues(): Default<T> | undefined {
    if (Object.values(args.map).length === 0) {
      return undefined;
    }

    const saved = Utils.objectMap(args.map, (conversion, key) => {
      if (conversion === undefined) {
        return undefined;
      }

      const value = searchParams.get(conversion.key);

      if (value === null) {
        return undefined;
      }

      const decodedValue = decodeURIComponent(value);

      switch (conversion.treatAs) {
        case "string":
          return decodedValue;
        case "number":
          return Number(decodedValue);
        case "boolean":
          return decodedValue === "true";
        case "array":
          /* 
            This is very limited as it only supports string arrays
          */
          return decodedValue.split(conversion.separator);
        case "custom":
          return conversion.convertFromString(decodedValue);
      }
    });

    const allUndefined = Object.values(saved).every(
      (value) => value === undefined
    );

    if (allUndefined) {
      return undefined;
    }

    return {
      ...args.formProps?.defaultValues,
      ...saved,
    } as Default<T>;
  }

  const formValues = form.watch();
  const savedValues = getSavedValues();

  useEffect(() => {
    /*  if (process.env.NODE_ENV === "development") {
      return;
    } */
    function saveValues(values: Default<T>): void {
      const newSearchParams = new URLSearchParams(location.search);

      Utils.objectMap(args.map, (conversion, _key) => {
        const key = _key.toString();
        const value = values[key];

        if (value === undefined || conversion === undefined) {
          return;
        }

        let strValue: string;
        switch (conversion.treatAs) {
          case "string":
            strValue = value as string;
            break;
          case "number":
            strValue = (value as number).toString();
            break;
          case "boolean":
            strValue = (value as boolean).toString();
            break;
          case "array":
            /* 
              This is very limited as it only supports string arrays
            */
            strValue = (value as any[]).join(conversion.separator);

            break;
          case "custom":
            strValue = conversion.convertToString(value) ?? "";

            break;
        }

        if (strValue === "") {
          newSearchParams.delete(conversion.key);
        } else {
          newSearchParams.set(conversion.key, strValue);
        }
      });

      const previousState = location.state as object | undefined;
      navigate(
        {
          ...location,
          search: newSearchParams.toString(),
        },
        {
          state: { internalNavigation: true, ...previousState },
          replace: true,
        }
      );
    }

    let values: any;
    if (args.valueSanitizer) {
      try {
        values = args.valueSanitizer.parse(formValues);
      } catch (er) {
        console.log({ er, formValues });
        return;
      }
    } else {
      values = formValues;
    }
    const filteredValues = Utils.objectFilter(values, (val, key) => {
      return key in args.map;
    });

    /*  console.log({
      formValues,
      values,
      filteredValues,
      savedValues,
      isEqual: isEqual(filteredValues, savedValues),
    });
 */
    if (!isEqual(filteredValues, savedValues)) {
      saveValues(values);
    }
  }, [
    formValues,
    savedValues,
    args.map,
    navigate,
    location,
    args.valueSanitizer,
  ]);

  return form;
}

export function useSearchParamState(
  key: string,
  args: {
    defaultValue: string | null;
  }
): [
  string | null,
  (
    param: Parameters<React.Dispatch<React.SetStateAction<string | null>>>[0],
    options?: {
      replace?: boolean | undefined;
    }
  ) => void
] {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentValue = searchParams.get(key);

  const defaultedValue =
    currentValue === null ? args.defaultValue : currentValue;

  function setValue(
    value: (string | null) | ((prevValue: string | null) => string | null),
    options?: { replace?: boolean }
  ) {
    const result = value instanceof Function ? value(defaultedValue) : value;
    const previousInternalNavigation =
      searchParams.get("internalNavigation") === "true";
    const newInternalNavigation = options?.replace
      ? previousInternalNavigation
      : true;

    if (result === "null" || result === null || result === undefined) {
      searchParams.delete(key);

      setSearchParams(
        {
          ...Object.fromEntries(searchParams.entries()),
          internalNavigation: newInternalNavigation.toString(),
        },
        {
          replace: options?.replace,
        }
      );
    } else {
      setSearchParams(
        {
          ...Object.fromEntries(searchParams.entries()),
          [key]: result,
          internalNavigation: newInternalNavigation.toString(),
        },
        {
          replace: options?.replace,
        }
      );
    }
  }

  return [defaultedValue, setValue];
}

export function useBundledSearchParamState(key: string, defaultValue: string) {
  const [value, set] = useSearchParamState(key, { defaultValue });
  return { value, set };
}

export function useCustomSearchParamState<T>(
  key: string,
  defaultValue: T | null,
  convert: {
    fromString: (str: string | null) => T;
    toString: (obj: T) => string | null;
  }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [stringValue, setStringValue] = useSearchParamState(key, {
    defaultValue: defaultValue === null ? null : convert.toString(defaultValue),
  });

  const currentValue = convert.fromString(stringValue);

  function setValue(value: T | ((prevValue: T) => T)) {
    const result = value instanceof Function ? value(currentValue) : value;
    setStringValue(convert.toString(result));
  }

  return [currentValue, setValue];
}

export function useBundledCustomSearchParamState<T>(
  key: string,
  defaultValue: T | null,
  convert: {
    fromString: (str: string | null) => T;
    toString: (obj: T) => string | null;
  }
): {
  value: T;
  set: React.Dispatch<React.SetStateAction<T>>;
} {
  const [value, set] = useCustomSearchParamState(key, defaultValue, convert);
  return { value, set };
}

export function useSearchParamStateNoHistory(
  key: string,
  args: {
    defaultValue: string | null;
  }
): [string | null, React.Dispatch<React.SetStateAction<string | null>>] {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const currentValue = searchParams.get(key);
  const defaultedValue =
    currentValue === null ? args.defaultValue : currentValue;

  const [value, setValue] = useState<string | null>(defaultedValue);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (currentValue === value) {
      return;
    }
    if (value === null || value === "null" || value === undefined) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, value);
    }
    const previousState = location.state as object | undefined;
    navigate(
      {
        ...location,
        search: searchParams.toString(),
      },
      {
        state: { internalNavigation: "true", ...previousState },
        replace: true,
      }
    );
  }, [navigate, key, location, value, currentValue, location.search]);

  return [value, setValue];
}

export function useBundledSearchParamStateNoHistory(
  key: string,
  defaultValue: string
) {
  const [value, set] = useSearchParamStateNoHistory(key, { defaultValue });
  return { value, set };
}

export function useCustomSearchParamStateNoHistory<T>(
  key: string,
  defaultValue: T | null,
  convert: {
    fromString: (str: string | null) => T;
    toString: (obj: T, prevValue: T | null) => string | null;
  }
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [stringValue, setStringValue] = useSearchParamStateNoHistory(key, {
    defaultValue:
      defaultValue === null ? null : convert.toString(defaultValue, null),
  });

  const currentValue = convert.fromString(stringValue);

  function setValue(value: T | ((prevValue: T) => T)) {
    const result = value instanceof Function ? value(currentValue) : value;
    setStringValue(convert.toString(result, currentValue));
  }

  return [currentValue, setValue];
}

export function useBundledCustomSearchParamStateNoHistory<T>(
  key: string,
  defaultValue: T | null,
  convert: {
    fromString: (str: string | null) => T;
    toString: (obj: T) => string | null;
  }
): {
  value: T;
  set: React.Dispatch<React.SetStateAction<T>>;
} {
  const [value, set] = useCustomSearchParamStateNoHistory(
    key,
    defaultValue,
    convert
  );
  return { value, set };
}
