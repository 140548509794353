import { MotionProps, motion } from "framer-motion";
import ClientUtils from "../../../../utils/ClientUtils";
import InvoiceViewCustomerServiceFooter from "../payment-method-components/InvoiceViewCustomerServiceFooter";

interface Props {
  motionProps: MotionProps;
  style: React.CSSProperties;
}

const InvoiceRefundedPaymentView = (props: Props) => {
  return (
    <motion.section
      {...props.motionProps}
      style={props.style}
      className={ClientUtils.twClassNames(
        "mx-auto grid h-full w-full max-w-lg grid-cols-1 grid-rows-[min-content,min-content,min-content] gap-8"
      )}
    >
      <header className="flex flex-col items-center gap-2">
        <h3 className="text-lg font-semibold sm:text-xl md:text-2xl">
          Din betalning har blivit återbetald
        </h3>
      </header>
      <main className="mx-auto flex w-full max-w-md justify-center">
        <p>Vänligen kontakta kundservice om du tror att det är ett fel.</p>
      </main>
      <InvoiceViewCustomerServiceFooter
        mainActionLabel="Ladda om"
        mainActionCallback={() => {
          window.location.reload();
        }}
      />
    </motion.section>
  );
};

export default InvoiceRefundedPaymentView;
