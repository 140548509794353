import React, { useContext } from "react";
import GroupedBookingSuggestion from "../single-booking-suggestion/GroupedBookingSuggestion";
import BookingSuggestionsContext from "../BookingSuggestionsContext";
import { DateHelper, Utils } from "@eljouren/utils";

const BookingSuggestionsByDate = () => {
  const ctx = useContext(BookingSuggestionsContext);

  return (
    <>
      {ctx.sortedBookingSuggestions.groupedByDate.map((suggestionObj) => {
        const key = suggestionObj.date.getTime();

        const dateHelper = new DateHelper(suggestionObj.date);

        if (suggestionObj.slotsWithAdjacentHandymenGrouped.length === 0) {
          return <></>;
        }

        return (
          <section key={key} className="relative">
            <header className="sticky top-0  z-40 rounded-t bg-main-bg-light p-2 text-off-white shadow-lg">
              <h4>
                {Utils.capitalize(dateHelper.dayOfWeek)},{" "}
                {dateHelper.readableDate}
              </h4>
            </header>
            <main className="p-4">
              <ul className="flex flex-col gap-4">
                {suggestionObj.slotsWithAdjacentHandymenGrouped.map(
                  (suggestion, i) => {
                    return (
                      <GroupedBookingSuggestion key={key + i} {...suggestion} />
                    );
                  }
                )}
              </ul>
            </main>
          </section>
        );
      })}
    </>
  );
};

export default BookingSuggestionsByDate;
