"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdCollectResponse = void 0;
var zod_1 = require("zod");
var BankIdCollectResponse;
(function (BankIdCollectResponse) {
    BankIdCollectResponse.Pending = zod_1.z.object({
        orderRef: zod_1.z.string(),
        status: zod_1.z.literal("pending"),
        /*
          Can also be "userMrtd", but we don't support that.
        */
        hintCode: zod_1.z.enum([
            "outstandingTransaction",
            "noClient",
            "started",
            "userSign",
        ]),
    });
    BankIdCollectResponse.UnknownPending = zod_1.z.object({
        orderRef: zod_1.z.string(),
        status: zod_1.z.literal("pending"),
        hintCode: zod_1.z.string(),
    });
    BankIdCollectResponse.Failed = zod_1.z.object({
        orderRef: zod_1.z.string(),
        status: zod_1.z.literal("failed"),
        hintCode: zod_1.z.enum([
            "userCancel",
            "expiredTransaction",
            "certificateErr",
            "startFailed",
        ]),
    });
    BankIdCollectResponse.UnknownFailed = zod_1.z.object({
        orderRef: zod_1.z.string(),
        status: zod_1.z.literal("failed"),
        hintCode: zod_1.z.string(),
    });
    BankIdCollectResponse.Error = zod_1.z.object({
        errorCode: zod_1.z.enum([
            "cancelled",
            "alreadyInProgress",
            "requestTimeout",
            "maintenance",
            "internalError",
            "invalidParameters",
        ]),
        details: zod_1.z.string().optional(),
    });
    BankIdCollectResponse.UnknownError = zod_1.z.object({
        errorCode: zod_1.z.string(),
    });
    BankIdCollectResponse.Complete = zod_1.z.object({
        orderRef: zod_1.z.string(),
        status: zod_1.z.literal("complete"),
        completionData: zod_1.z.object({
            user: zod_1.z.object({
                personalNumber: zod_1.z.string(),
                name: zod_1.z.string(),
                givenName: zod_1.z.string(),
                surname: zod_1.z.string(),
            }),
            bankIdIssueDate: zod_1.z.string(),
            device: zod_1.z.object({
                ipAddress: zod_1.z.string(),
                uhi: zod_1.z.string(),
            }),
            /*   cert: z.object({
              notBefore: z.string(),
              notAfter: z.string(),
            }), */
            signature: zod_1.z.string(),
            ocspResponse: zod_1.z.string(),
        }),
    });
    BankIdCollectResponse.Schema = zod_1.z.union([
        BankIdCollectResponse.Pending,
        BankIdCollectResponse.UnknownPending,
        BankIdCollectResponse.Failed,
        BankIdCollectResponse.UnknownFailed,
        BankIdCollectResponse.Error,
        BankIdCollectResponse.UnknownError,
        BankIdCollectResponse.Complete,
    ]);
    BankIdCollectResponse.FailedOrError = zod_1.z.union([
        BankIdCollectResponse.Failed,
        BankIdCollectResponse.UnknownFailed,
        BankIdCollectResponse.Error,
        BankIdCollectResponse.UnknownError,
    ]);
})(BankIdCollectResponse || (BankIdCollectResponse = {}));
exports.BankIdCollectResponse = BankIdCollectResponse;
