"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisLogoIconV2 = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var IpisLogoIconV2 = function (props) {
    return ((0, jsx_runtime_1.jsx)("img", { style: {
            width: props.size,
            height: props.size,
        }, src: props.light
            ? props.logo.lightUrl
            : props.logo.darkUrl, alt: "Logga f\u00F6r IPIS Plattform" }));
};
exports.IpisLogoIconV2 = IpisLogoIconV2;
