import { MaterialReviewList } from "@eljouren/domain";
import { useState } from "react";
import { AppButton } from "../../../components/common/buttons/AppButton";
import BankIDLogo from "../../../components/external-brands/bank-id/BankIdLogo";

interface FooterProps {
  list: MaterialReviewList.Type;
  onSign: () => void;
  isSigning: boolean;
}

const ReviewListOverviewFooter = (props: FooterProps) => {
  const [listVerified, setListVerified] = useState(false);

  return (
    <footer className="flex flex-col gap-4 rounded">
      {/*  <section className="mx-auto flex flex-col rounded py-8">
        <header className="flex flex-col items-center">
          <h3 className="font-normal" id="totalCostHeading">
            Total tilläggskostnad
          </h3>
        </header>
		<span
            aria-labelledby="totalCostHeading"
            className="text-xl font-bold"
          >
            {list.totalPriceWithDiscount}:-
          </span>
      </section> */}

      <fieldset className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2 gap-y-8 pt-4">
        <input
          type="checkbox"
          id="reviewConfirmedCheckbox"
          className="h-6 w-6 cursor-pointer"
          onChange={(e) => setListVerified(e.target.checked)}
        />
        <label
          className="ml-2 cursor-pointer"
          htmlFor="reviewConfirmedCheckbox"
        >
          Jag accepterar dessa villkor och är redo att skriva under med
          BankID-appen
        </label>
      </fieldset>
      <AppButton
        contentClassName="flex items-center"
        onClick={props.onSign}
        loading={props.isSigning}
        disabled={!listVerified}
      >
        <BankIDLogo size={40} white />
        <span>Skriv under med BankID</span>
      </AppButton>
    </footer>
  );
};

export default ReviewListOverviewFooter;
