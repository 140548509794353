"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormElementState = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var FormElementAnswer_1 = require("./FormElementAnswer");
/*
    Needs a better name
*/
var FormElementState;
(function (FormElementState) {
    FormElementState.InformationElementSchema = centralized_zod_1.z.object({
        answerState: centralized_zod_1.z.literal("information"),
        isAnswered: centralized_zod_1.z.literal(false),
        meetsConditions: centralized_zod_1.z.boolean(),
        isValid: centralized_zod_1.z.literal(true),
        answer: centralized_zod_1.z.undefined().optional(),
    });
    FormElementState.UnvalidatedSchema = centralized_zod_1.z.object({
        answerState: centralized_zod_1.z.literal("unvalidated"),
        isAnswered: centralized_zod_1.z.literal(undefined).optional(),
        meetsConditions: centralized_zod_1.z.literal(undefined).optional(),
        isValid: centralized_zod_1.z.literal(undefined).optional(),
        answer: centralized_zod_1.z.any(),
    });
    FormElementState.UnansweredInvalidSchema = centralized_zod_1.z.object({
        answerState: centralized_zod_1.z.literal("unanswered"),
        isAnswered: centralized_zod_1.z.literal(false),
        meetsConditions: centralized_zod_1.z.boolean(),
        isValid: centralized_zod_1.z.literal(false),
        answer: centralized_zod_1.z.undefined().optional(),
    });
    FormElementState.UnansweredValidSchema = centralized_zod_1.z.object({
        answerState: centralized_zod_1.z.literal("unanswered"),
        isAnswered: centralized_zod_1.z.literal(false),
        meetsConditions: centralized_zod_1.z.boolean(),
        isValid: centralized_zod_1.z.literal(true),
        answer: centralized_zod_1.z.undefined().optional(),
    });
    FormElementState.AnsweredInvalidSchema = centralized_zod_1.z.object({
        answerState: centralized_zod_1.z.literal("answered-invalid"),
        isAnswered: centralized_zod_1.z.literal(true),
        meetsConditions: centralized_zod_1.z.literal(true),
        isValid: centralized_zod_1.z.literal(false),
        answer: centralized_zod_1.z.any(),
    });
    var AnsweredBaseSchema = centralized_zod_1.z.object({
        answerState: centralized_zod_1.z.literal("answered"),
        isAnswered: centralized_zod_1.z.literal(true),
        meetsConditions: centralized_zod_1.z.literal(true),
        isValid: centralized_zod_1.z.literal(true),
    });
    /* type AnsweredBaseType = z.infer<typeof AnsweredBaseSchema>; */
    FormElementState.AnsweredYesNoSchema = function () {
        return AnsweredBaseSchema.extend({
            answer: FormElementAnswer_1.FormElementAnswer.YesNoFormAnswerSchema(),
        });
    };
    FormElementState.AnsweredNumberSchema = function () {
        return AnsweredBaseSchema.extend({
            answer: FormElementAnswer_1.FormElementAnswer.NumberFormAnswer(),
        });
    };
    FormElementState.AnsweredTextSchema = function () {
        return AnsweredBaseSchema.extend({
            answer: FormElementAnswer_1.FormElementAnswer.TextFormAnswer(),
        });
    };
    FormElementState.AnsweredMultipleChoiceSchema = function () {
        return AnsweredBaseSchema.extend({
            answer: FormElementAnswer_1.FormElementAnswer.MultipleChoiceFormAnswer(),
        });
    };
    FormElementState.AnsweredRepeaterGroupSchema = function () {
        return AnsweredBaseSchema.extend({
            answer: FormElementAnswer_1.FormElementAnswer.RepeaterQuestionFormAnswer(),
        });
    };
    FormElementState.AnsweredImageGroupSchema = function () {
        return AnsweredBaseSchema.extend({
            answer: FormElementAnswer_1.FormElementAnswer.ImageGroupFormAnswer(),
        });
    };
    function createUnion(schema) {
        return centralized_zod_1.z.union([
            FormElementState.UnansweredInvalidSchema,
            FormElementState.UnansweredValidSchema,
            FormElementState.AnsweredInvalidSchema,
            schema,
        ]);
    }
    FormElementState.createUnion = createUnion;
    function yesNoUnion() {
        return createUnion(FormElementState.AnsweredYesNoSchema());
    }
    FormElementState.yesNoUnion = yesNoUnion;
    function numberUnion() {
        return createUnion(FormElementState.AnsweredNumberSchema());
    }
    FormElementState.numberUnion = numberUnion;
    function textUnion() {
        return createUnion(FormElementState.AnsweredTextSchema());
    }
    FormElementState.textUnion = textUnion;
    function multipleChoiceUnion() {
        return createUnion(FormElementState.AnsweredMultipleChoiceSchema());
    }
    FormElementState.multipleChoiceUnion = multipleChoiceUnion;
    function repeaterGroupUnion() {
        return createUnion(FormElementState.AnsweredRepeaterGroupSchema());
    }
    FormElementState.repeaterGroupUnion = repeaterGroupUnion;
    function imageGroupUnion() {
        return createUnion(FormElementState.AnsweredImageGroupSchema());
    }
    FormElementState.imageGroupUnion = imageGroupUnion;
    function createValidUnion(schema) {
        return centralized_zod_1.z.union([FormElementState.UnansweredValidSchema, schema]);
    }
    FormElementState.createValidUnion = createValidUnion;
    function yesNoValidUnion() {
        return createValidUnion(FormElementState.AnsweredYesNoSchema());
    }
    FormElementState.yesNoValidUnion = yesNoValidUnion;
    function numberValidUnion() {
        return createValidUnion(FormElementState.AnsweredNumberSchema());
    }
    FormElementState.numberValidUnion = numberValidUnion;
    function textValidUnion() {
        return createValidUnion(FormElementState.AnsweredTextSchema());
    }
    FormElementState.textValidUnion = textValidUnion;
    function multipleChoiceValidUnion() {
        return createValidUnion(FormElementState.AnsweredMultipleChoiceSchema());
    }
    FormElementState.multipleChoiceValidUnion = multipleChoiceValidUnion;
    function repeaterGroupValidUnion() {
        return createValidUnion(FormElementState.AnsweredRepeaterGroupSchema());
    }
    FormElementState.repeaterGroupValidUnion = repeaterGroupValidUnion;
    function imageGroupValidUnion() {
        return createValidUnion(FormElementState.AnsweredImageGroupSchema());
    }
    FormElementState.imageGroupValidUnion = imageGroupValidUnion;
})(FormElementState || (FormElementState = {}));
exports.FormElementState = FormElementState;
