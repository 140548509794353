"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdSignParams = void 0;
var zod_1 = require("zod");
var BankIdSignParams;
(function (BankIdSignParams) {
    BankIdSignParams.Schema = zod_1.z.object({
        personalNumber: zod_1.z.string().optional(),
        endUserIp: zod_1.z.string(),
        userVisibleData: zod_1.z.string(),
    });
})(BankIdSignParams || (BankIdSignParams = {}));
exports.BankIdSignParams = BankIdSignParams;
