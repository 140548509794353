import ClientUtils from "../../../utils/ClientUtils";
import AppHandshakeIcon from "../AppHandshakeIcon";
import { ExposedIconProps } from "../types/SvgIconProps";

interface Props extends ExposedIconProps<{}> {
  className?: string;
}

const ServiceContractIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <AppHandshakeIcon
      {...rest}
      className={ClientUtils.twClassNames("text-orange-400", props.className)}
    />
  );
};
export default ServiceContractIcon;
