"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormElementWithState = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var IpisFormElement_1 = require("./IpisFormElement");
var FormElementState_1 = require("./FormElementState");
var FormElementWithState;
(function (FormElementWithState) {
    FormElementWithState.AnsweredTextInputSchema = function () {
        return IpisFormElement_1.IpisFormElement.TextInputSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredTextSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredTextInputSchema = function () {
        return IpisFormElement_1.IpisFormElement.TextInputSchema.extend({
            state: FormElementState_1.FormElementState.textUnion(),
        });
    };
    FormElementWithState.ValidTextInputSchema = IpisFormElement_1.IpisFormElement.TextInputSchema.extend({
        state: FormElementState_1.FormElementState.textValidUnion(),
    });
    /*   export const UnvalidatedTextInputSchema = FormElement.TextInputSchema.extend({
      state: FormElementState.UnvalidatedSchema,
    });
  
    export type UnvalidatedTextInputType = z.infer<
      typeof UnvalidatedTextInputSchema
    >; */
    FormElementWithState.AnsweredNumberInputSchema = function () {
        return IpisFormElement_1.IpisFormElement.NumberInputSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredNumberSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredNumberInputSchema = function () {
        return IpisFormElement_1.IpisFormElement.NumberInputSchema.extend({
            state: FormElementState_1.FormElementState.numberUnion(),
        });
    };
    FormElementWithState.ValidNumberInputSchema = IpisFormElement_1.IpisFormElement.NumberInputSchema.extend({
        state: FormElementState_1.FormElementState.numberValidUnion(),
    });
    /* export const UnvalidatedNumberInputSchema =
      FormElement.NumberInputSchema.extend({
        state: FormElementState.UnvalidatedSchema,
      });
  
    export type UnvalidatedNumberInputType = z.infer<
      typeof UnvalidatedNumberInputSchema
    >; */
    FormElementWithState.AnsweredYesNoSchema = function () {
        return IpisFormElement_1.IpisFormElement.YesNoSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredYesNoSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredYesNoSchema = function () {
        return IpisFormElement_1.IpisFormElement.YesNoSchema.extend({
            state: FormElementState_1.FormElementState.yesNoUnion(),
        });
    };
    FormElementWithState.ValidYesNoSchema = IpisFormElement_1.IpisFormElement.YesNoSchema.extend({
        state: FormElementState_1.FormElementState.yesNoValidUnion(),
    });
    /*  export const UnvalidatedYesNoSchema = FormElement.YesNoSchema.extend({
      state: FormElementState.UnvalidatedSchema,
    });
   */
    FormElementWithState.AnsweredTextAreaSchema = function () {
        return IpisFormElement_1.IpisFormElement.TextAreaSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredTextSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredTextAreaSchema = function () {
        return IpisFormElement_1.IpisFormElement.TextAreaSchema.extend({
            state: FormElementState_1.FormElementState.textUnion(),
        });
    };
    FormElementWithState.ValidTextAreaSchema = IpisFormElement_1.IpisFormElement.TextAreaSchema.extend({
        state: FormElementState_1.FormElementState.textValidUnion(),
    });
    /*   export const UnvalidatedTextAreaSchema = FormElement.TextAreaSchema.extend({
      state: FormElementState.UnvalidatedSchema,
    });
  
    export type UnvalidatedTextAreaType = z.infer<
      typeof UnvalidatedTextAreaSchema
    >; */
    FormElementWithState.AnsweredRichTextSchema = function () {
        return IpisFormElement_1.IpisFormElement.RichTextSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredTextSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredRichTextSchema = function () {
        return IpisFormElement_1.IpisFormElement.RichTextSchema.extend({
            state: FormElementState_1.FormElementState.textUnion(),
        });
    };
    FormElementWithState.ValidRichTextSchema = IpisFormElement_1.IpisFormElement.RichTextSchema.extend({
        state: FormElementState_1.FormElementState.textValidUnion(),
    });
    /*  export const UnvalidatedRichTextSchema = FormElement.RichTextSchema.extend({
      state: FormElementState.UnvalidatedSchema,
    });
  
    export type UnvalidatedRichTextType = z.infer<
      typeof UnvalidatedRichTextSchema
    >; */
    FormElementWithState.AnsweredMultipleChoiceSchema = function () {
        return IpisFormElement_1.IpisFormElement.MultipleChoiceSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredMultipleChoiceSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredMultipleChoiceSchema = function () {
        return IpisFormElement_1.IpisFormElement.MultipleChoiceSchema.extend({
            state: FormElementState_1.FormElementState.multipleChoiceUnion(),
        });
    };
    FormElementWithState.ValidMultipleChoiceSchema = IpisFormElement_1.IpisFormElement.MultipleChoiceSchema.extend({
        state: FormElementState_1.FormElementState.multipleChoiceValidUnion(),
    });
    /*  export const UnvalidatedMultipleChoiceSchema =
      FormElement.MultipleChoiceSchema.extend({
        state: FormElementState.UnvalidatedSchema,
      });
  
    export type UnvalidatedMultipleChoiceType = z.infer<
      typeof UnvalidatedMultipleChoiceSchema
    >; */
    FormElementWithState.AnsweredRepeaterQuestionSchema = function () {
        return IpisFormElement_1.IpisFormElement.RepeaterSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredRepeaterGroupSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredRepeaterQuestionSchema = function () {
        return IpisFormElement_1.IpisFormElement.RepeaterSchema.extend({
            state: FormElementState_1.FormElementState.repeaterGroupUnion(),
        });
    };
    FormElementWithState.ValidRepeaterQuestionSchema = IpisFormElement_1.IpisFormElement.RepeaterSchema.extend({
        state: FormElementState_1.FormElementState.repeaterGroupValidUnion(),
    });
    /*  export const UnvalidatedRepeaterQuestionSchema =
      FormElement.RepeaterSchema.extend({
        state: FormElementState.UnvalidatedSchema,
      });
  
    export type UnvalidatedRepeaterQuestionType = z.infer<
      typeof UnvalidatedRepeaterQuestionSchema
    >; */
    FormElementWithState.AnsweredImageGroupSchema = function () {
        return IpisFormElement_1.IpisFormElement.ImageGroupSchema.extend({
            state: FormElementState_1.FormElementState.AnsweredImageGroupSchema(),
        });
    };
    FormElementWithState.MaybeAnsweredImageGroupSchema = function () {
        return IpisFormElement_1.IpisFormElement.ImageGroupSchema.extend({
            state: FormElementState_1.FormElementState.imageGroupUnion(),
        });
    };
    FormElementWithState.ValidImageGroupSchema = IpisFormElement_1.IpisFormElement.ImageGroupSchema.extend({
        state: FormElementState_1.FormElementState.imageGroupValidUnion(),
    });
    /*  export const UnvalidatedImageGroupSchema =
      FormElement.ImageGroupSchema.extend({
        state: FormElementState.UnvalidatedSchema,
      }); */
    FormElementWithState.InformationSchema = IpisFormElement_1.IpisFormElement.InformationSchema.extend({
        state: FormElementState_1.FormElementState.InformationElementSchema,
    });
    /*
      Think this is useless, since we are not actually interested if every question
      is answered - only if they are valid
    */
    FormElementWithState.AnsweredSchema = centralized_zod_1.z.union([
        FormElementWithState.AnsweredTextInputSchema(),
        FormElementWithState.AnsweredNumberInputSchema(),
        FormElementWithState.AnsweredYesNoSchema(),
        FormElementWithState.AnsweredTextAreaSchema(),
        FormElementWithState.AnsweredMultipleChoiceSchema(),
        FormElementWithState.AnsweredRepeaterQuestionSchema(),
        FormElementWithState.AnsweredImageGroupSchema(),
        FormElementWithState.AnsweredRichTextSchema(),
        FormElementWithState.InformationSchema,
    ]);
    FormElementWithState.MaybeSchema = centralized_zod_1.z.union([
        FormElementWithState.MaybeAnsweredTextInputSchema(),
        FormElementWithState.MaybeAnsweredNumberInputSchema(),
        FormElementWithState.MaybeAnsweredYesNoSchema(),
        FormElementWithState.MaybeAnsweredTextAreaSchema(),
        FormElementWithState.MaybeAnsweredMultipleChoiceSchema(),
        FormElementWithState.MaybeAnsweredRepeaterQuestionSchema(),
        FormElementWithState.MaybeAnsweredImageGroupSchema(),
        FormElementWithState.MaybeAnsweredRichTextSchema(),
        FormElementWithState.InformationSchema,
    ]);
    FormElementWithState.ValidSchema = centralized_zod_1.z.union([
        FormElementWithState.ValidTextInputSchema,
        FormElementWithState.ValidNumberInputSchema,
        FormElementWithState.ValidYesNoSchema,
        FormElementWithState.ValidTextAreaSchema,
        FormElementWithState.ValidMultipleChoiceSchema,
        FormElementWithState.ValidRepeaterQuestionSchema,
        FormElementWithState.ValidImageGroupSchema,
        FormElementWithState.ValidRichTextSchema,
        FormElementWithState.InformationSchema,
    ]);
    /* export const UnvalidatedSchema = z.union([
      UnvalidatedTextInputSchema,
      UnvalidatedNumberInputSchema,
      UnvalidatedYesNoSchema,
      UnvalidatedTextAreaSchema,
      UnvalidatedMultipleChoiceSchema,
      UnvalidatedRepeaterQuestionSchema,
      UnvalidatedImageGroupSchema,
      UnvalidatedRichTextSchema,
      UnvalidatedInformationSchema,
    ]);
  
    export type UnvalidatedType = z.infer<typeof UnvalidatedSchema>; */
})(FormElementWithState || (FormElementWithState = {}));
exports.FormElementWithState = FormElementWithState;
