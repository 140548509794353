"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useMutableQuery = useMutableQuery;
var react_1 = require("react");
var react_query_1 = require("react-query");
var use_query_wrapper_1 = require("./use-query-wrapper");
function useMutableQuery(options) {
    var _this = this;
    function saveOptimisticUpdate(data) {
        queryClient.setQueryData(options.queryKey, data);
        lastOptimisticUpdateRef.current = { data: data, date: new Date() };
    }
    var mutationCountRef = (0, react_1.useRef)(0);
    var queryClient = (0, react_query_1.useQueryClient)();
    var query = (0, use_query_wrapper_1.useQueryWrapper)(__assign(__assign({}, options), { queryFn: options.queryFn
            ? function (ctx) { return __awaiter(_this, void 0, void 0, function () {
                var data;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (mutationCountRef.current > 0 && lastOptimisticUpdateRef.current) {
                                return [2 /*return*/, lastOptimisticUpdateRef.current.data];
                            }
                            return [4 /*yield*/, options.queryFn(ctx)];
                        case 1:
                            data = _a.sent();
                            return [2 /*return*/, data];
                    }
                });
            }); }
            : undefined }));
    var lastOptimisticUpdateRef = (0, react_1.useRef)(undefined);
    /*
      For use in timeouts
    */
    var currentDataRef = (0, react_1.useRef)(undefined);
    (0, react_1.useEffect)(function () {
        currentDataRef.current = query.data;
    }, [query.data]);
    function startMutation() {
        mutationCountRef.current++;
    }
    function endMutation() {
        mutationCountRef.current--;
    }
    var isInMutationProcess = mutationCountRef.current > 0;
    var mutation = (0, react_query_1.useMutation)(function (args) { return __awaiter(_this, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, args.callback()];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); }, {
        onMutate: function (args) {
            startMutation();
            var mutateOptions = args.mutationOptions;
            handleOptimisticUpdate(mutateOptions);
        },
        onSettled: function (_, __, args) { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if ((_a = args.mutationOptions) === null || _a === void 0 ? void 0 : _a.onSettled) {
                            args.mutationOptions.onSettled();
                        }
                        endMutation();
                        return [4 /*yield*/, query.refetch()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onError: function (err, args) {
            var _a;
            if ((_a = args.mutationOptions) === null || _a === void 0 ? void 0 : _a.onError) {
                args.mutationOptions.onError(err);
            }
        },
    });
    function getCurrentData() {
        var data = currentDataRef.current;
        if (data !== undefined) {
            //Copy
            return structuredClone(data);
        }
        return undefined;
    }
    function mutate(args) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                queryClient.cancelQueries({ queryKey: options.queryKey });
                return [2 /*return*/, mutation.mutateAsync({
                        callback: args.callback,
                        mutationOptions: args,
                    })];
            });
        });
    }
    function handleOptimisticUpdate(mutateOptions) {
        if (mutateOptions === null || mutateOptions === void 0 ? void 0 : mutateOptions.optimisticUpdate) {
            saveOptimisticUpdate(mutateOptions.optimisticUpdate);
        }
        else if (mutateOptions === null || mutateOptions === void 0 ? void 0 : mutateOptions.mutableOptimisticUpdate) {
            var oldData = getCurrentData();
            mutateOptions.mutableOptimisticUpdate(oldData);
            saveOptimisticUpdate(oldData);
        }
        else if (mutateOptions === null || mutateOptions === void 0 ? void 0 : mutateOptions.immutableOptimisticUpdate) {
            var oldData = getCurrentData();
            var newData = mutateOptions.immutableOptimisticUpdate(oldData);
            saveOptimisticUpdate(newData);
        }
    }
    var mutationDebounceRef = (0, react_1.useRef)({});
    /*
      This won't catch errors properly
    */
    function mutateDebounced(args) {
        return __awaiter(this, void 0, void 0, function () {
            var current, accumulator, timeout;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                current = mutationDebounceRef.current;
                accumulator = [];
                if (args.mutationKey in current) {
                    clearTimeout(current[args.mutationKey].timeout);
                    accumulator = current[args.mutationKey].accumulator;
                }
                timeout = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                    var element, options;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                element = current[args.mutationKey];
                                delete current[args.mutationKey];
                                options = __assign({}, args.options);
                                // Delete optimistic update info as that is already handled
                                delete options.optimisticUpdate;
                                delete options.mutableOptimisticUpdate;
                                delete options.immutableOptimisticUpdate;
                                return [4 /*yield*/, mutate(__assign({ callback: function () { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, args.callback(element.accumulator)];
                                                    case 1:
                                                        _a.sent();
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); } }, options))];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); }, (_a = args.debounceTime) !== null && _a !== void 0 ? _a : 1000);
                current[args.mutationKey] = {
                    timeout: timeout,
                    accumulator: __spreadArray(__spreadArray([], accumulator, true), [args.args], false),
                };
                mutationDebounceRef.current = current;
                handleOptimisticUpdate(args.options);
                return [2 /*return*/];
            });
        });
    }
    var ignoredMutate = mutation.mutate, rest = __rest(mutation, ["mutate"]);
    return {
        query: query,
        mutation: rest,
        isInMutationProcess: isInMutationProcess,
        isLoading: mutation.isLoading || query.isLoading || query.isManuallyRefetching,
        mutate: mutate,
        mutateDebounced: mutateDebounced,
        getCurrentData: getCurrentData,
    };
}
