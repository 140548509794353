import { Control, Controller, FieldValues, Path } from "react-hook-form";

import isEqual from "lodash.isequal";
import ClientUtils from "../../../utils/ClientUtils";

type Button = {
  label: string;
  value: any;
};

type Props<T extends FieldValues> = {
  name: Path<T>;
  id: string;
  heading?: string | ((props: { style: React.CSSProperties }) => JSX.Element);
  headingClassName?: string;
  subheading?:
    | string
    | ((props: { style: React.CSSProperties }) => JSX.Element);
  subheadingClassName?: string;
  buttonFlow?: "row" | "column";
  //errorMessage?: string;
  //errors?: FieldErrors<T>;
  //className?: string;
  control: Control<T>;
  //htmlAttributes?: Omit<JSX.IntrinsicElements["input"], "ref">;
  //labelClassName?: string;
  buttons: Button[];
};

export function AppFormRadioButtons<T extends FieldValues>(props: Props<T>) {
  /*   const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError(); */

  const headingStyle: React.CSSProperties = {
    gridColumn: `1 / span ${props.buttons.length}`,
    paddingBottom: props.subheading ? undefined : "1rem",
  };
  const subheadingStyle: React.CSSProperties = {
    gridColumn: `1 / span ${props.buttons.length}`,
    fontSize: "0.9rem",
    fontWeight: 400,
    paddingBottom: "1rem",
  };
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={(controllerProps) => {
        return (
          <fieldset
            className={ClientUtils.classNames(
              "w-full gap-x-4 py-2",
              props.buttonFlow !== "row" && "flex flex-col"
            )}
            onBlur={controllerProps.field.onBlur}
            ref={controllerProps.field.ref}
            style={
              props.buttonFlow === "row"
                ? {
                    display: "grid",
                    gridTemplateRows: `repeat(2, auto)`,
                    gridTemplateColumns: `repeat(${
                      props.buttons.length - 1
                    },min-content) 1fr`,
                  }
                : undefined
            }
          >
            {typeof props.heading === "string" && (
              <h3 className={props.headingClassName} style={headingStyle}>
                {props.heading}
              </h3>
            )}
            {typeof props.heading === "function" &&
              props.heading({
                style: headingStyle,
              })}
            {typeof props.subheading === "string" && (
              <h4 className={props.subheadingClassName} style={subheadingStyle}>
                {props.subheading}
              </h4>
            )}
            {typeof props.subheading === "function" &&
              props.subheading({
                style: headingStyle,
              })}
            {props.buttons.map((btn) => {
              const value = controllerProps.field.value;
              const isSelected = isEqual(value, btn.value);
              const id = props.id + btn.value;
              return (
                <p key={id} className="flex items-center gap-4">
                  <input
                    className="h-6 w-6 cursor-pointer accent-black"
                    id={id}
                    type="radio"
                    name={props.name}
                    onChange={(e) => {
                      if (e.target.checked) {
                        controllerProps.field.onChange(btn.value);
                      }
                    }}
                    checked={isSelected}
                  />
                  <label htmlFor={id} className="cursor-pointer">
                    {btn.label}
                  </label>
                </p>
              );
            })}
          </fieldset>
        );
      }}
    />
  );
}
