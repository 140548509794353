"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisUnexpectedError = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var client_utils_1 = require("../../utils/client-utils");
var IpisUnexpectedError = function (props) {
    var _process = typeof process === "undefined"
        ? {
            env: {},
        }
        : process;
    var nodeEnv = _process.env["NODE_ENV"];
    (0, react_1.useEffect)(function () {
        if (nodeEnv === "development" || nodeEnv === "test") {
            console.error(props.error);
        }
    }, [props.error, nodeEnv]);
    return ((0, jsx_runtime_1.jsx)("section", { className: (0, client_utils_1.classNames)("mx-auto flex max-w-md flex-col gap-4 p-4", props.className), children: (0, jsx_runtime_1.jsx)("header", { className: "flex flex-col gap-2 text-lg font-bold", children: "Ok\u00E4nt fel" }) }));
};
exports.IpisUnexpectedError = IpisUnexpectedError;
