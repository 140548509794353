import { FieldValues, Path, UseFormReturn } from "react-hook-form";
import { IpisTextInputCompact } from "@ipis/client-essentials";
import { useAddress } from "./editable-address-hooks";

export type FormEditableFieldsetAddress = {
  street: string;
  city: string;
  postalCode: string;
  floor?: string;
  doorCode?: string;
  country?: string;
};

type FieldMapping<T extends FieldValues> = Partial<
  Record<keyof FormEditableFieldsetAddress, Path<T>>
>;

export interface FormEditableAddressFieldProps<T extends FieldValues> {
  id: string;
  //address: Partial<FormEditableFieldsetAddress>;
  legend: string;
  isEditing: boolean;
  fieldMapping?: FieldMapping<T>;
  form: UseFormReturn<T>;
  registerNested?: Path<T>;
  readOnly?: boolean;
}

const FormEditableAddressFieldset = <T extends FieldValues>(
  props: FormEditableAddressFieldProps<T>
) => {
  function getName(key: keyof FormEditableFieldsetAddress): Path<T> {
    const mappedKey = props.fieldMapping?.[key] ?? key;
    if (props.registerNested) {
      return `${props.registerNested}.${mappedKey}` as Path<T>;
    } else {
      return mappedKey as Path<T>;
    }
  }

  const a = useAddress(props);

  function formatAddress() {
    const parts: string[] = [];
    if (a.street) {
      parts.push(a.street);
    }
    if (a.city) {
      parts.push(a.city);
    }
    if (a.country) {
      parts.push(a.country);
    }
    return parts.join(", ");
  }

  const showEdit = props.isEditing;

  if (showEdit) {
    return (
      <fieldset className="grid grid-cols-[40%,30%,30%] gap-x-2 gap-y-4 rounded pt-2">
        <IpisTextInputCompact
          required={true}
          id="street"
          label="Gata"
          form={props.form}
          name={getName("street")}
          className="col-span-3"
        />
        <IpisTextInputCompact
          required={true}
          id="city"
          label="Stad"
          form={props.form}
          name={getName("city")}
        />
        <IpisTextInputCompact
          required={true}
          id="postalCode"
          label="Postnummer"
          form={props.form}
          name={getName("postalCode")}
        />
        <IpisTextInputCompact
          required={true}
          id="country"
          label="Land"
          form={props.form}
          name={getName("country")}
        />

        <IpisTextInputCompact
          required={false}
          id="doorCode"
          label="Portkod"
          form={props.form}
          name={getName("doorCode")}
        />

        <IpisTextInputCompact
          required={false}
          id="floor"
          label="Våning"
          form={props.form}
          name={getName("floor")}
        />
      </fieldset>
    );
  }

  return (
    <fieldset className="rounded border border-border-color px-4 pb-4 pt-2">
      <legend className="px-2">{props.legend}</legend>
      <input
        readOnly
        aria-label="Adress"
        value={formatAddress()}
        className="w-full"
        tabIndex={-1}
      />
      {a.postalCode && (
        <div className="flex items-center gap-1">
          <label htmlFor={`${props.id}-postal-code`}>Postnummer:</label>
          <input
            id={`${props.id}-postal-code`}
            readOnly
            aria-label="Postnummer"
            value={a.postalCode}
            tabIndex={-1}
          />
        </div>
      )}
      {a.floor && (
        <div className="flex items-center gap-1">
          <label htmlFor={`${props.id}-floor`}>Våning:</label>
          <input
            id={`${props.id}-floor`}
            readOnly
            aria-label="Våning"
            value={a.floor}
            tabIndex={-1}
          />
        </div>
      )}
      {a.doorCode && (
        <div className="flex items-center gap-1">
          <label htmlFor={`${props.id}-door-code`}>Portkod:</label>
          <input
            id={`${props.id}-door-code`}
            readOnly
            aria-label="Portkod"
            value={a.doorCode}
            tabIndex={-1}
          />
        </div>
      )}
    </fieldset>
  );
};

export default FormEditableAddressFieldset;
