import ClientUtils from './../../../../utils/ClientUtils';
import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import HandymanCalendar from "../../../../components/calendar/HandymanCalendar";
import LoadingContent from "../../../../components/common/loaders/LoadingContent";
import TabPanel from "../../../../components/common/tabs/TabPanel";
import HandymanContextProvider from "../../../../components/handyman-context/HandymanContextProvider";
import AppPage from "../../../../components/layout/AppPage";
import SomethingWentWrong from "../../../../components/on-error/SomethingWentWrong";
import { useSearchParamStateNoHistory } from "../../../../hooks/use-search-params";

import { HandymanLandingPageContext } from "../HandymanRoute";
import HandymanDashboard from "../dashboard/HandymanDashboard";
import HandymanWorkingHoursTab from "../workings-hours/HandymanWorkingHoursTab";

interface Props {
  className?: string;
}

export enum HandymanLandingPageTab {
  dashboard = "Översikt",
  workingHours = "Arbetstider",
  calendar = "Kalender",
}

const HandymanMobileLandingPage = (props: Props) => {
  const ctx = useContext(HandymanLandingPageContext);

  const [searchParamTab, setSearchParamTab] = useSearchParamStateNoHistory(
    "sida",
    {
      defaultValue: HandymanLandingPageTab.dashboard,
    }
  );
  const tab = searchParamTab ?? HandymanLandingPageTab.dashboard;

  return (
    <AppPage
      className={ClientUtils.twClassNames(
        "mx-auto grid h-full w-full md:drop-shadow-sm",
        props.className
      )}
      headerProps={{
        heading: tab,
        subheading: !ctx.isViewingOwnProfile
          ? `För ${ctx.handyman.firstName} ${ctx.handyman.lastName}`
          : undefined,
      }}
      tabProps={{
        tabs: Object.values(HandymanLandingPageTab),
        selectedTab: tab,
        onSelect: (tab) => {
          setSearchParamTab(tab);
        },
      }}
    >
      <LoadingContent renderContentSeparately loading={ctx.isLoading}>
        <AnimatePresence exitBeforeEnter initial={false}>
          <TabPanel key={tab} className="grid h-full grid-rows-1">
            {tab === HandymanLandingPageTab.dashboard && <HandymanDashboard />}
            {tab === HandymanLandingPageTab.workingHours && (
              <HandymanContextProvider
                handymanId={ctx.handyman.id}
                pageIndexSearchParamKey="whIndex"
              >
                <HandymanWorkingHoursTab />
              </HandymanContextProvider>
            )}
            {tab === HandymanLandingPageTab.calendar && (
              <ErrorBoundary
                fallbackRender={(props) => (
                  <SomethingWentWrong
                    error={props.error}
                    description="Något gick fel när vi skulle visa kalendern. Vänligen försök igen senare!"
                  />
                )}
              >
                <HandymanContextProvider
                  handymanId={ctx.handyman.id}
                  pageIndexSearchParamKey="cIndex"
                >
                  <HandymanCalendar />
                </HandymanContextProvider>
              </ErrorBoundary>
            )}
          </TabPanel>
        </AnimatePresence>
      </LoadingContent>
    </AppPage>
  );
};

export default HandymanMobileLandingPage;
