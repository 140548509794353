import { useContext, useEffect } from "react";
import BackButtonStackContext from "./BackButtonStackContext";

/* 
  Buggy!

  Needs to be used as an effect instead so that we can cleanup the stack when the component unmounts.
*/
export const useAddToBackButtonStack = (callback: () => void) => {
  const stack = useContext(BackButtonStackContext);
  useEffect(() => {
    const remove = stack.push(callback);
    return () => {
      remove();
    };
  });
};

export const useManipulateBackButtonStack = () => {
  const stack = useContext(BackButtonStackContext);

  async function handle(
    args: {
      onlyHandleBeforeBack?: boolean;
    } = {}
  ): Promise<boolean> {
    if (!stack.beforeBackIsEmpty()) {
      const shouldGoBack = await stack.popBeforeBack()();
      if (!shouldGoBack) {
        // We've handled the back button press, so we don't want to go back.
        return true;
      }
    }

    if (!stack.isEmpty()) {
      const callback = stack.pop();
      if (args.onlyHandleBeforeBack) {
        return false;
      }
      callback();
      return true;
    }

    return false;
  }

  return { handle, stack };
};
