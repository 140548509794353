import { IpisForm } from "@eljouren/domain";
import React from "react";

export type FormContextType = {
  byPassSchemaValidation: boolean;
  byPassConditionalValidation: boolean;
  form: IpisForm.ShellType;
};

export const FormContext = React.createContext<FormContextType>(
  {} as never
);

export type FormRenderContextType = {
  useCompactLayout?: boolean;
};

export const FormRenderContext = React.createContext<
  FormRenderContextType | undefined
>(undefined);
