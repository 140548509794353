"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUrl_GetMultipleInput = void 0;
var IpisFileRelation_1 = require("../../../file/IpisFileRelation");
var CollectionType_1 = require("../../../shared-schemas/CollectionType");
var UploaderSource_1 = require("../../../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var SignUrl_GetMultipleInput;
(function (SignUrl_GetMultipleInput) {
    SignUrl_GetMultipleInput.Schema = zod_1.z.object({
        recordIds: zod_1.z.union([zod_1.z.string(), zod_1.z.string().array()]).transform(function (value) {
            return Array.isArray(value) ? value : [value];
        }),
        uploaderSource: zod_1.z
            .union([
            UploaderSource_1.UploaderSource.Schema,
            UploaderSource_1.UploaderSource.Schema.array(),
            zod_1.z.literal("all"),
        ])
            .optional()
            .transform(function (value) {
            if (!value) {
                return "all";
            }
            if (Array.isArray(value) && value.length === 0) {
                return "all";
            }
            if (value === "all") {
                return "all";
            }
            var arr = Array.isArray(value)
                ? value
                : [value];
            return arr;
        }),
        collectionType: CollectionType_1.CollectionType.Schema.optional(),
        recordToRelationMap: zod_1.z
            .record(zod_1.z.string(), zod_1.z.union([IpisFileRelation_1.IpisFileRelation.Schema, IpisFileRelation_1.IpisFileRelation.Schema.array()]))
            .optional(),
    });
})(SignUrl_GetMultipleInput || (SignUrl_GetMultipleInput = {}));
exports.SignUrl_GetMultipleInput = SignUrl_GetMultipleInput;
