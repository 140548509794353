"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormElementIsolatedAnswer = void 0;
var zod_1 = require("zod");
var IpisFormElement_1 = require("./IpisFormElement");
var FormElementAnswer_1 = require("./FormElementAnswer");
var IpisFormElementIsolatedAnswer;
(function (IpisFormElementIsolatedAnswer) {
    IpisFormElementIsolatedAnswer.MultipleChoiceSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.MultipleChoiceSchema.shape.typeOfQuestion,
        answer: FormElementAnswer_1.FormElementAnswer.MultipleChoiceFormAnswer(),
    });
    IpisFormElementIsolatedAnswer.YesNoSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.YesNoSchema.shape.typeOfQuestion,
        answer: FormElementAnswer_1.FormElementAnswer.YesNoFormAnswerSchema(),
    });
    IpisFormElementIsolatedAnswer.TextInputSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.TextInputSchema.shape.typeOfQuestion,
        answer: FormElementAnswer_1.FormElementAnswer.TextFormAnswer(),
    });
    IpisFormElementIsolatedAnswer.TextAreaSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.TextAreaSchema.shape.typeOfQuestion,
        answer: FormElementAnswer_1.FormElementAnswer.TextFormAnswer(),
    });
    IpisFormElementIsolatedAnswer.NumberSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.NumberInputSchema.shape.typeOfQuestion,
        answer: FormElementAnswer_1.FormElementAnswer.NumberFormAnswer(),
    });
    /*
      Not sure how to type this...
      The images will be fetched using presigned links, generated on the server but used on the client.
      Thus, the answer will not be known until returned to the client.
  
      For now, I will set the answer to null.
    */
    IpisFormElementIsolatedAnswer.ServerSideImageGroupSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.ImageGroupSchema.shape.typeOfQuestion,
        answer: zod_1.z.object({
            value: zod_1.z.null(),
            imageUploadComment: zod_1.z.string().nullable(),
        }),
    });
    IpisFormElementIsolatedAnswer.ClientSideImageGroupSchema = zod_1.z.object({
        id: zod_1.z.string(),
        formElementId: zod_1.z.string(),
        title: zod_1.z.string(),
        indexWithinPage: zod_1.z.number(),
        type: IpisFormElement_1.IpisFormElement.ImageGroupSchema.shape.typeOfQuestion,
        answer: zod_1.z.object({
            imageUploadComment: zod_1.z.string().nullable().optional(),
            images: zod_1.z
                .union([
                FormElementAnswer_1.FormElementAnswer.PreUploadImageSchema,
                FormElementAnswer_1.FormElementAnswer.PostUploadImageSchema,
            ])
                .array(),
        }),
    });
    IpisFormElementIsolatedAnswer.ServerSideSchema = zod_1.z.union([
        IpisFormElementIsolatedAnswer.MultipleChoiceSchema,
        IpisFormElementIsolatedAnswer.YesNoSchema,
        IpisFormElementIsolatedAnswer.TextInputSchema,
        IpisFormElementIsolatedAnswer.TextAreaSchema,
        IpisFormElementIsolatedAnswer.NumberSchema,
        IpisFormElementIsolatedAnswer.ServerSideImageGroupSchema,
    ]);
    IpisFormElementIsolatedAnswer.ClientSideSchema = zod_1.z.union([
        IpisFormElementIsolatedAnswer.MultipleChoiceSchema,
        IpisFormElementIsolatedAnswer.YesNoSchema,
        IpisFormElementIsolatedAnswer.TextInputSchema,
        IpisFormElementIsolatedAnswer.TextAreaSchema,
        IpisFormElementIsolatedAnswer.NumberSchema,
        IpisFormElementIsolatedAnswer.ClientSideImageGroupSchema,
    ]);
})(IpisFormElementIsolatedAnswer || (IpisFormElementIsolatedAnswer = {}));
exports.IpisFormElementIsolatedAnswer = IpisFormElementIsolatedAnswer;
