import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ChecklistMultipleChoiceInputBuilder from "./ChecklistMultipleChoiceInputBuilder";

interface Props {
  className?: string;
  closeForm: () => void;
}

const ChecklistMultipleChoiceInputCreator = (props: Props) => {
  const ctx = useFormEditor();

  return (
    <ChecklistMultipleChoiceInputBuilder
      closeForm={props.closeForm}
      onSubmit={(values) => {
        ctx.controls.addElement({ element: values });
        props.closeForm();
      }}
    />
  );
};

export default ChecklistMultipleChoiceInputCreator;
