import { IconPlus } from "@tabler/icons-react";
//import { PlusCircleIcon, PlusIcon } from "@heroicons/react/24/outline";
import ClientUtils from "../../utils/ClientUtils";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "default" | "circle";
}

const AppPlusIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "circle") {
    return (
      <IconPlus
        {...rest}
        className={ClientUtils.classNames(props.className, "rounded-full")}
      />
    );
  }

  return <IconPlus {...rest} className={props.className} />;
};
export default withIconSize(AppPlusIcon);
