"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MaterialReviewList = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderLineItem_1 = require("./WorkOrderLineItem");
var MaterialReviewList;
(function (MaterialReviewList) {
    MaterialReviewList.LineItemSummary = centralized_zod_1.z.object({
        lineItems: WorkOrderLineItem_1.WorkOrderLineItem.CustomerSchema.array(),
        totalPriceWithoutTaxReduction: centralized_zod_1.z.number(),
        totalPriceWithTaxReduction: centralized_zod_1.z.number(),
    });
    MaterialReviewList.Schema = centralized_zod_1.z.object({
        hash: centralized_zod_1.z.string(),
        workOrderId: centralized_zod_1.z.string(),
        createdDate: centralized_zod_1.z.date(),
        //nameOfAssignedHandyman: z.string(),
        pendingLineItemSummary: MaterialReviewList.LineItemSummary,
        previousLineItemSummary: MaterialReviewList.LineItemSummary,
        inactivatedLineItemSummary: MaterialReviewList.LineItemSummary,
        discountType: centralized_zod_1.z.enum(["rot", "green"]).nullable(),
        treatingNotApprovedAsPending: centralized_zod_1.z.boolean(),
        totalPriceWithoutTaxReduction: centralized_zod_1.z.number(),
        totalPriceWithTaxReduction: centralized_zod_1.z.number(),
        taxReductionAmount: centralized_zod_1.z.number(),
        userReadableConfirmation: centralized_zod_1.z.object({
            text: centralized_zod_1.z.string(),
            terms: centralized_zod_1.z.string().array().min(1),
        }),
    });
})(MaterialReviewList || (MaterialReviewList = {}));
exports.MaterialReviewList = MaterialReviewList;
