import ClientUtils from "../../utils/ClientUtils";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";
import { IconEdit } from "@tabler/icons-react";

interface Props extends InternalIconProps {
  className?: string;
}

const AppEditIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <IconEdit
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default withIconSize(AppEditIcon);
