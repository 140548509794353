"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Presigned_GetMultipleOutput = void 0;
var IpisFileV2_1 = require("../../domain/IpisFileV2");
var zod_1 = require("zod");
var Presigned_GetMultipleOutput;
(function (Presigned_GetMultipleOutput) {
    Presigned_GetMultipleOutput.Schema = zod_1.z.object({
        files: IpisFileV2_1.IpisFileV2.Schema.array(),
    });
})(Presigned_GetMultipleOutput || (Presigned_GetMultipleOutput = {}));
exports.Presigned_GetMultipleOutput = Presigned_GetMultipleOutput;
