import { WorkOrder } from "@eljouren/domain/build";
import React from "react";
import { UseMutableQueryResult } from "../../hooks/use-mutatable-query";

export enum CustomerWorkOrderTabs {
  overview = "Översikt",
  files = "Filer",
}

const CustomerWorkOrderContext = React.createContext<{
  workOrder: WorkOrder.CustomerType;
  //form: UseFormReturn<WorkOrder.Type>;
  workOrderRes: UseMutableQueryResult<WorkOrder.CustomerType>;
  tab: CustomerWorkOrderTabs;
}>({} as never);

export default CustomerWorkOrderContext;
