import { FileMetaInput, IpisFile } from "@eljouren/file-schemas";
import { useRef } from "react";
import { AppButton } from "../common/buttons/AppButton";
import { UUID } from "@eljouren/utils";

export type CustomFileInputFile = {
  native: File;
  guid: string;
  dataUrl: string;
  meta: FileMetaInput.Type;
};

interface Props {
  className?: string;
  label: string;
  onChange: (files: CustomFileInputFile[]) => any;
  maxSize?: {
    inBytes: number;
    stringRepresentation: string;
  };
  onlyImages?: boolean;
  id: string;
  previousFiles?: CustomFileInputFile[];
  maxFileNameLength: number;
}

const FileInputButton = (props: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  /* 
     Defaults to 10mb with some leeway
  	*/
  const maxSizeInBytes = props.maxSize?.inBytes ?? 11 * Math.pow(10, 6);
  const maxSizeStr = props.maxSize?.stringRepresentation ?? "10mb";

  const accept = props.onlyImages
    ? IpisFile.ImageExtensions.options.map((opt) => `.${opt}`).join(",")
    : IpisFile.AllowedExtensions.options.map((opt) => `.${opt}`).join(",");

  async function toDataUrl(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  async function onFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    try {
      function resetInput() {
        if (!inputRef.current) {
          return;
        }
        inputRef.current.value = "";
      }

      const files = e.target.files;
      if (!files?.length) {
        return resetInput();
      }

      const filesArray = Array.from(files);

      const tooLargeFiles: File[] = [];
      const filesWithinSizeLimit: File[] = [];

      filesArray.forEach((file) => {
        if (file.size > maxSizeInBytes) {
          tooLargeFiles.push(file);
        } else {
          filesWithinSizeLimit.push(file);
        }
      });

      if (tooLargeFiles.length > 0) {
        let title: string;
        if (filesArray.length === 1) {
          title = `1 fil är större än maxstorleken på ${maxSizeStr}`;
        } else if (tooLargeFiles.length === 1) {
          title = `Filen är större än maxstorleken på ${maxSizeStr}`;
        } else {
          title = `${tooLargeFiles.length} filer är över maxstorleken på ${maxSizeStr}`;
        }

        window.modal.alert({
          title,
          prompt: `Följande filer är för stora: ${tooLargeFiles
            .map((file) => file.name)
            .join(", ")}`,
          typeOfAlert: "error",
        });
      }

      if (filesWithinSizeLimit.length === 0) {
        return resetInput();
      }

      const customFiles: CustomFileInputFile[] = await Promise.all(
        filesWithinSizeLimit.map(async (file) => {
          const split = file.name.split(".");
          const name = split
            .slice(0, -1)
            .join(".")
            .substring(0, props.maxFileNameLength);
          const meta: FileMetaInput.Type = {
            description: "",
            name: name,
          };
          return {
            native: file,
            dataUrl: await toDataUrl(file),
            guid: UUID.generate().value,
            meta,
          };
        })
      );

      const previous = props.previousFiles ?? [];
      const duplicateMap: Record<string, true> = Object.fromEntries(
        previous.map((file) => [file.dataUrl, true])
      );

      const duplicates: CustomFileInputFile[] = [];
      const uniqueFiles: CustomFileInputFile[] = [];

      customFiles.forEach((file) => {
        if (duplicateMap[file.dataUrl]) {
          duplicates.push(file);
        } else {
          uniqueFiles.push(file);
          duplicateMap[file.dataUrl] = true;
        }
      });

      if (duplicates.length > 0) {
        window.modal.alert({
          title: "Filer med samma innehåll hittades",
          prompt: `Dubletterna kommer att ignoreras`,
          typeOfAlert: "notification",
        });
      }

      if (uniqueFiles.length === 0) {
        return resetInput();
      }

      props.onChange(uniqueFiles);
      resetInput();
    } catch (error) {
      window.modal.alert({
        title: "Något gick fel",
        prompt:
          "Det gick inte att läsa filerna just nu. Vänligen försök igen senare.",
        typeOfAlert: "error",
      });
    }
  }

  const buttonId = props.id + "-file-input-button";
  return (
    <>
      <input
        id={props.id + "-file-input"}
        aria-describedby={buttonId}
        ref={inputRef}
        type="file"
        className="hidden"
        onChange={onFileChange}
        accept={accept}
        multiple
      />
      <AppButton
        id={buttonId}
        className={props.className}
        onClick={() => {
          inputRef.current?.click();
        }}
        variant="outline-default"
      >
        {props.label}
      </AppButton>
    </>
  );
};

export default FileInputButton;
