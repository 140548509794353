import { ErrorBoundary } from "react-error-boundary";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../hooks/use-media-query";
import HandymanWorkOrderDesktopPage from "./desktop-specific/HandymanWorkOrderDesktopPage";
import HandymanWorkOrderMobilePage from "./mobile-specific/HandymanWorkOrderMobilePage";

const HandymanWorkOrderPage = () => {
  const isDesktop = useMediaQuery(TailwindBreakpoint.xl);

  return (
    <ErrorBoundary
      fallbackRender={(props) => (
        <SomethingWentWrong
          error={props.error}
          description="Det gick inte att hämta detta uppdrag just nu."
        />
      )}
    >
      {isDesktop && <HandymanWorkOrderDesktopPage />}
      {!isDesktop && <HandymanWorkOrderMobilePage />}
    </ErrorBoundary>
  );
};

export default HandymanWorkOrderPage;
