import { WorkOrderFailedReport } from "@eljouren/domain";
import { zodResolver } from "@hookform/resolvers/zod";
import { motion } from "framer-motion";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import GoBackButton from "../../../components/GoBackButton";
import { AppButton } from "../../../components/common/buttons/AppButton";
import { AppFormSelect } from "../../../components/common/select/AppFormSelect";
import { AppFormTextArea } from "../../../components/common/text-areas/AppFormTextArea";
import { useRepos } from "../../../hooks/use-repos";
import ClientUtils from "./../../../utils/ClientUtils";
import HandymanWorkOrderRouteContext from "./contexts/HandymanWorkOrderRouteContext";

interface Props {
  className?: string;
  onClose(): any;
}

const WorkOrderFailedWorkOrderModal = (props: Props) => {
  const { orderRes, order } = useContext(HandymanWorkOrderRouteContext);
  const form = useForm<WorkOrderFailedReport.Type>({
    mode: "onChange",
    defaultValues: {
      workOrderId: order.orderId,
    },
    resolver: zodResolver(WorkOrderFailedReport.Schema),
  });
  const { workOrderRepo } = useRepos();

  form.watch();

  async function onSubmit(values: WorkOrderFailedReport.Type) {
    try {
      props.onClose();
      await orderRes.mutate({
        callback: () => workOrderRepo.reportAsFailed(values),
        optimisticUpdate: {
          ...order,
          isFinished: true,
          //apiStatus: "Klarmarkerad av hantverkare",
        },
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte markera arbetet som misslyckat just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={ClientUtils.twClassNames(
        props.className,
        "flex h-full w-full flex-col bg-white/90 p-4 backdrop-blur md:pt-32"
      )}
    >
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="mx-auto flex h-full w-full max-w-screen-lg flex-col gap-2 overflow-auto"
      >
        <header className="flex w-full justify-between gap-2 py-2">
          <h2 className="text-xl">Arbetet gick ej att utföra</h2>
        </header>
        <main className="flex grow flex-col gap-4 overflow-auto p-2">
          <AppFormSelect
            register={form.register}
            name="reason"
            label="Varför gick inte arbetet att utföra?"
            options={[
              ...order.workOrderStatus.unsuccessfulOptions.map((opt) => ({
                value: opt,
                label: opt,
              })),
              {
                label: "Annan anledning",
                value: "other",
              },
            ]}
          />

          <motion.fieldset
            className="flex w-full flex-col gap-4"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <AppFormTextArea
              register={form.register}
              name="comment"
              label={
                form.watch("reason") === "other"
                  ? "Ange anledning"
                  : "Eventuell kommentar"
              }
              htmlAttributes={{
                //required: form.watch("reason") === "other",
                rows: 3,
              }}
            />
          </motion.fieldset>
        </main>
        <footer className="flex flex-col items-end gap-2">
          <AppButton
            requiresNetworkConnection
            type="submit"
            contentClassName="w-72"
            disabled={!form.formState.isValid}
          >
            Bekräfta ej utfört arbete
          </AppButton>
          <GoBackButton
            onClick={props.onClose}
            appButtonProps={{
              className: "w-72",
            }}
          />
        </footer>
      </form>
    </motion.section>
  );
};

export default WorkOrderFailedWorkOrderModal;
