import { IpisBrand } from "@eljouren/domain";
import { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../top-level-contexts";

type ExternalBrandConstants = {
  logoUrl: string;
  brandName: string;
};

const eonMockConstants: ExternalBrandConstants = {
  logoUrl: "https://upload.wikimedia.org/wikipedia/commons/9/93/EON_Logo.svg",
  brandName: "E.ON",
};

export function useStaticExternalBrand(): string | undefined {
  const params = useParams();
  const brand = params["externalBrand"];
  return brand?.toLowerCase();
}

export function useExternalBrandConstants():
  | ExternalBrandConstants
  | undefined {
  const brand = useStaticExternalBrand();
  if (brand === "eon") {
    return eonMockConstants;
  }
  return undefined;
}

export function useBrand(): IpisBrand.Type {
  const { signInState } = useContext(GlobalContext);

  /* if (process.env.NODE_ENV === "development") {
    return "Rörmokare.nu";
  } */

  if (!signInState.isSignedIn) {
    return "Aii";
  }

  if (signInState.signedInAs === "worker") {
    return "Aii";
  } else {
    return signInState.brand;
  }
}

export const useBrandLogic = () => {
  const brand = useBrand();

  useEffect(() => {
    let linkHref: string;
    let title: string;
    switch (brand) {
      case "Svenska Eljouren":
        title = "Svenska Eljouren – Sveriges modernaste elektriker";
        linkHref = "/favicon-eljouren.svg";
        break;
      case "Rörjour 247":
        title = "Rörjour 24/7 – Effektiva rörmokare & rörjour 24/7";
        linkHref = "/favicon-rorjouren.svg";
        break;
      case "Rörmokare.nu":
        title = "Rörmokare - Enkelt att boka en rörmokare!";
        linkHref = "/favicon-rormokare-nu.svg";
        break;
      case "IPIS":
      case "IPIS Contract":
      default:
        title = "Aii – Allt inom installation";
        linkHref = "/favicon-aii.svg";
        break;
    }

    document.title = title;

    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      //@ts-ignore
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    //@ts-ignore
    link.href = linkHref;
  }, [brand]);
};

export function useBrandName(): string {
  const brand = useBrand();

  switch (brand) {
    case "Svenska Eljouren":
      return "Svenska Eljouren";
    case "Rörjour 247":
      return "Rörjour 24/7";
    case "Rörmokare.nu":
      return "Rörmokare.nu";
    case "IPIS":
      return "IPIS";
    case "IPIS Contract":
      return "IPIS Contract";
    default:
      return "Aii";
  }
}

export function useBrandWebsite(): string {
  const brand = useBrand();

  switch (brand) {
    case "Svenska Eljouren":
      return "https://svenskaeljouren.se/";
    case "Rörjour 247":
      return "https://rorjour247.se/";
    case "Rörmokare.nu":
      return "https://xn--rrmokare-n4a.nu/";
    case "IPIS":
    case "IPIS Contract":
    default:
      return "https://ipis.se";
  }
}
