import { useRef } from "react";
import { UseContextMenuReturn } from "../../../hooks/use-context-menu";
import AppMenu, { Menu } from "./AppMenu";
import { useOnOutsideClick } from "../../../hooks/hooks";

interface Props {
  className?: string;
  id: string;
  variant: "light" | "dark";
  menu: Menu;
  ctxMenu: UseContextMenuReturn;
  handleSelectedStateInternally?: boolean;
}

const AppContextMenu = (props: Props) => {
  const ref = useRef<any>(null);

  useOnOutsideClick(ref, props.ctxMenu.close);

  if (!props.ctxMenu.isVisible) {
    return <></>;
  }

  return (
    <aside style={props.ctxMenu.style} ref={ref}>
      <AppMenu
        id={props.id}
        variant={props.variant}
        menu={props.menu}
        handleSelectedStateInternally={props.handleSelectedStateInternally}
      />
    </aside>
  );
};

export default AppContextMenu;
