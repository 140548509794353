"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStaticExternalBrand = useStaticExternalBrand;
exports.useExternalBrandTheme = useExternalBrandTheme;
exports.useExternalBrandConstants = useExternalBrandConstants;
var layout_context_1 = require("../contexts/layout-context");
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var eonMockConstants = {
    logoUrl: "https://upload.wikimedia.org/wikipedia/commons/9/93/EON_Logo.svg",
    brandName: "E.ON",
};
function useStaticExternalBrand() {
    var params = (0, react_router_dom_1.useParams)();
    var brand = params["externalBrand"];
    return brand === null || brand === void 0 ? void 0 : brand.toLowerCase();
}
function useExternalBrandTheme(props) {
    if (props === void 0) { props = {}; }
    var ctx = (0, layout_context_1.useLayout)();
    var setExternalBrandTheme = ctx.setExternalBrandTheme;
    var externalBrandTheme = props.externalBrandTheme;
    (0, react_1.useEffect)(function () {
        if (externalBrandTheme) {
            setExternalBrandTheme(externalBrandTheme);
        }
        if (externalBrandTheme === null) {
            setExternalBrandTheme(undefined);
        }
    }, [externalBrandTheme, setExternalBrandTheme]);
    return ctx;
}
function useExternalBrandConstants() {
    var brand = useStaticExternalBrand();
    if (brand === "eon") {
        return eonMockConstants;
    }
    return undefined;
}
