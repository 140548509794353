import InvoiceOverviewAdditionalInformationMain from "../form-steps/overview-form-step/InvoiceOverviewAdditionalInformationMain";
import InvoiceOverviewList from "../form-steps/overview-form-step/InvoiceOverviewList";

interface Props {
  className?: string;
}

const InvoiceOverview = (props: Props) => {
  return (
    <main className="flex flex-col gap-16">
      <section className="flex flex-col gap-4">
        <header className="flex justify-center py-4 sm:px-2 md:px-4">
          <h3 className="text-sm xs:text-base">
            Sammanställning av utfört uppdrag:
          </h3>
        </header>
        <main className="xs:px-2">
          <InvoiceOverviewList />
        </main>
      </section>
      <section className="flex flex-col gap-4">
        <header className="flex justify-center">
          <h3 className="text-sm xs:text-base">Ytterligare information:</h3>
        </header>
        <InvoiceOverviewAdditionalInformationMain />
      </section>
    </main>
  );
};

export default InvoiceOverview;
