import { IpisFormElement } from "@eljouren/domain";
import { z } from "@ipis/centralized-zod";

namespace EditFormElementEventSchema {
  //function OmitDefault<Raw extends z.ZodRawShape,  Obj extends z.ZodObject<Raw> >() {}

  const defaultOmitValues = {
    id: true,
    clientSideId: true,
    conditionGroups: true,
    hasAnswers: true,
    isInactive: true,
  } as const;
  export const TextQuestionSchema =
    IpisFormElement.TextInputSchema.omit(defaultOmitValues);
  export const TextAreaQuestionSchema =
    IpisFormElement.TextAreaSchema.omit(defaultOmitValues);
  export const NumberQuestionSchema =
    IpisFormElement.NumberInputSchema.omit(defaultOmitValues);
  export const YesNoQuestionSchema =
    IpisFormElement.YesNoSchema.omit(defaultOmitValues);
  export const MultipleChoiceSchema =
    IpisFormElement.MultipleChoiceSchema.omit(defaultOmitValues);
  export const ImagePromptSchema =
    IpisFormElement.ImageGroupSchema.omit(defaultOmitValues);
  export const InformationSchema =
    IpisFormElement.InformationSchema.omit(defaultOmitValues);

  export const ValuesSchema = z.union([
    TextQuestionSchema,
    TextAreaQuestionSchema,
    NumberQuestionSchema,
    YesNoQuestionSchema,
    MultipleChoiceSchema,
    ImagePromptSchema,
    InformationSchema,
  ]);

  export type NewValuesType = z.infer<typeof ValuesSchema>;

  export const Schema = z.object({
    discriminator: z.literal("editFormElementEvent"),
    formId: z.string(),
    elementId: z.string(),
    newValues: ValuesSchema,
  });

  export type Type = z.infer<typeof Schema>;
}

export default EditFormElementEventSchema;
