import { FormElementAnswer } from "@eljouren/domain";
import { IpisFormAnswerValidator } from "@eljouren/domain-utils";
import React from "react";
import { UseFormReturn } from "react-hook-form";

export type ChecklistFormAnswer =
  | string
  | boolean
  | number
  | FormElementAnswer.ImageGroupFormAnswerType;

export type ChecklistFormValues = {
  [questionId: string]: {
    answer: ChecklistFormAnswer;
    repeaterItems?: {
      [groupId: string]: {
        [repeaterItemId: string]: {
          answer: ChecklistFormAnswer;
        };
      };
    };
    options?: {
      [optionId: string]: {
        clarification?: string;
      };
    };
    imageUploadComment?: string;
  };
};

export type ChecklistPageContextType = {
  form: UseFormReturn<ChecklistFormValues>;
  validatedChecklist: IpisFormAnswerValidator;
};

const ChecklistPageContext = React.createContext<ChecklistPageContextType>(
  {} as never
);

export default ChecklistPageContext;
