import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../hooks/use-media-query";
import SalesTeamWorkOrderDesktopPage from "./desktop-specific/SalesTeamWorkOrderDesktopPage";
import SalesTeamWorkOrderMobilePage from "./mobile-specific/SalesTeamWorkOrderMobilePage";

interface Props {
  className?: string;
}

const SalesTeamWorkOrderPage = (props: Props) => {
  const isDesktop = useMediaQuery(TailwindBreakpoint.xl);

  if (isDesktop) {
    return <SalesTeamWorkOrderDesktopPage />;
  } else {
    return <SalesTeamWorkOrderMobilePage />;
  }
};

export default SalesTeamWorkOrderPage;
