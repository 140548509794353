"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormInformationElement = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var IpisFormInputDescription_1 = require("../components/IpisFormInputDescription");
var InformationTooltip_1 = require("../../../InformationTooltip");
var client_utils_1 = require("../../../../utils/client-utils");
var ControlledSlideUpModal_1 = require("../../../modals/ControlledSlideUpModal");
var RichTextPreTag_1 = require("../components/RichTextPreTag");
var IpisFormInputNote_1 = require("../components/IpisFormInputNote");
var IpisFormInputLabel_1 = require("../components/IpisFormInputLabel");
/*
    This is not really a form input, but a component that displays information about the form.
*/
var IpisFormInformationElement = function (props) {
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { layout: true, className: (0, client_utils_1.classNames)("grid w-full grid-cols-[minmax(0,1fr),auto] gap-2", props.className), children: [(0, jsx_runtime_1.jsx)(IpisFormInputLabel_1.IpisFormInputLabel, { id: props.id, label: props.label, bold: true, required: props.required }), !!props.tooltip && ((0, jsx_runtime_1.jsx)(InformationTooltip_1.InformationTooltip, { open: "left", content: props.tooltip, contentClassName: "w-[200px]" })), !!props.richTextDescription && ((0, jsx_runtime_1.jsx)(IpisFormInputDescription_1.IpisFormInputDescription, { className: "col-span-2 w-full", html: props.richTextDescription })), !!props.slideUpElement && ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: (0, jsx_runtime_1.jsx)(ControlledSlideUpModal_1.ControlledSlideUpModal, { heading: props.slideUpElement.prompt, className: "col-span-2", anchorToId: props.slideUpAnchorId, children: (0, jsx_runtime_1.jsx)(RichTextPreTag_1.RichTextPreTag, { html: props.slideUpElement.content }) }) })), !!props.richTextNote && ((0, jsx_runtime_1.jsx)(IpisFormInputNote_1.IpisFormInputNote, { className: "col-span-2", html: props.richTextNote }))] }));
};
exports.IpisFormInformationElement = IpisFormInformationElement;
