import { AnimatePresence } from "framer-motion";
import { useContext, useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import withSalesCredentials from "../../../components/auth/hocs/withSalesCredentials";
import { SignedInContext } from "../../../components/auth/hocs/withWorkerCredentials";
import HandymanCalendar from "../../../components/calendar/HandymanCalendar";
import LoadingContent from "../../../components/common/loaders/LoadingContent";
import TabPanel from "../../../components/common/tabs/TabPanel";
import HandymanContextProvider from "../../../components/handyman-context/HandymanContextProvider";
import AppPage from "../../../components/layout/AppPage";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import HandymanWorkingHoursTab from "../../worker/landing/workings-hours/HandymanWorkingHoursTab";
import { HandymanLandingPageContext } from "../../worker/landing/HandymanRoute";

enum Tab {
  workingHours = "Arbetstider",
  calendar = "Kalender",
}

interface Props extends TSignedInWorkerState {
  className?: string;
}

const SalesTeamPartnerListSingleRoute = (props: Props) => {
  const { workerId } = useParams();
  const navigate = useNavigate();
  const { handymanRepo } = useRepos();

  const workerRes = useQuery(["handyman", workerId], () =>
    handymanRepo.fetchHandyman(workerId || "")
  );
  const ctx = useContext(SignedInContext);

  const [params] = useSearchParams();

  function getTab() {
    const page = params.get("sida");
    switch (page) {
      case "kalender":
        return Tab.calendar;
      default:
        return Tab.workingHours;
    }
  }

  const tab = getTab();

  useEffect(() => {
    if (!workerId) {
      navigate("/");
    }
  }, [workerId, navigate]);

  return (
    <LoadingContent renderContentSeparately loading={workerRes.isLoading}>
      <AppPage
        className="h-full"
        headerProps={{
          heading: workerRes.data
            ? `${workerRes.data?.firstName} ${workerRes.data?.lastName}`
            : "",
        }}
        tabProps={{
          mainClassName: "",
          tabs: Object.values(Tab),
          selectedTab: tab,
          searchParams: (tab) => {
            let page: string;
            switch (tab) {
              case Tab.calendar:
                page = "kalender";
                break;
              case Tab.workingHours:
              default:
                page = "arbetstider";
                break;
            }

            // "sida" is Swedish for page
            return {
              sida: page,
            };
          },
        }}
      >
        {workerRes.isError && (
          <p className="p-4">
            Det gick inte att hämta denna hantverkare just nu. Vänligen försök
            igen senare!
          </p>
        )}

        {workerRes.data && !workerRes.isError && (
          <HandymanLandingPageContext.Provider
            value={{
              isViewingOwnProfile: false,
              handyman: workerRes.data,
              signedInHandyman: ctx.handyman,
              isLoading: false,
            }}
          >
            <AnimatePresence exitBeforeEnter>
              <TabPanel key={tab}>
                {tab === Tab.workingHours && (
                  <ErrorBoundary
                    fallbackRender={(props) => (
                      <SomethingWentWrong
                        error={props.error}
                        description="Något gick fel när vi skulle visa arbetstiderna. Vänligen försök igen senare!"
                      />
                    )}
                  >
                    <HandymanContextProvider
                      handymanId={workerRes.data.id}
                      pageIndexSearchParamKey="whsIndex"
                    >
                      <HandymanWorkingHoursTab noEdit />
                    </HandymanContextProvider>
                  </ErrorBoundary>
                )}
                {tab === Tab.calendar && (
                  <ErrorBoundary
                    fallbackRender={(props) => (
                      <SomethingWentWrong
                        error={props.error}
                        description="Något gick fel när vi skulle visa kalendern. Vänligen försök igen senare!"
                      />
                    )}
                  >
                    <HandymanContextProvider
                      handymanId={workerRes.data.id}
                      pageIndexSearchParamKey="csIndex"
                    >
                      <HandymanCalendar defaultView="grid" />
                    </HandymanContextProvider>
                  </ErrorBoundary>
                )}
              </TabPanel>
            </AnimatePresence>
          </HandymanLandingPageContext.Provider>
        )}
      </AppPage>
    </LoadingContent>
  );
};

export default withSalesCredentials(SalesTeamPartnerListSingleRoute);
