"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Scheduler = void 0;
var UUID_1 = require("../general/UUID");
var Scheduler = /** @class */ (function () {
    function Scheduler() {
    }
    Scheduler.prototype.findGaps = function (args) {
        var events = args.currentEvents;
        if (!events.length) {
            return this.findGapsWhenNoEventsExist(args);
        }
        /*
            There are events, start by sorting them by start date
        */
        events.sort(function (a, b) { return a.start.getTime() - b.start.getTime(); });
        /*
            Initialise arrays to hold the gaps and the timeline
        */
        var gaps = [];
        var timeline = [];
        /*
            Now check if the first event starts after the boundaries start
            If it does, create a gap between the boundaries start and the first event start
        */
        var firstEvent = events[0];
        var firstEventStartsAfterBoundariesStart = firstEvent.start.getTime() > args.boundaries.start.getTime();
        if (firstEventStartsAfterBoundariesStart) {
            var gap = this.createScheduleGap({
                start: args.boundaries.start,
                end: firstEvent.start,
                eventBefore: undefined,
                eventAfter: firstEvent,
            }, args);
            if (gap) {
                if (gap.isValid) {
                    gaps.push(gap);
                }
                timeline.push(gap);
            }
        }
        /*
        Now we can loop through the events and check if there are gaps between them
      */
        for (var i = 0; i < events.length; i++) {
            var currentEvent = events[i];
            timeline.push(currentEvent);
            var nextEvent = events[i + 1];
            var gapStart = void 0;
            var gapEnd = void 0;
            if (nextEvent) {
                /*
                    We're not at the last event, check if the current event ends before the next event starts
                */
                var currentEventEndsBeforeNextEventStarts = currentEvent.end.getTime() < nextEvent.start.getTime();
                if (currentEventEndsBeforeNextEventStarts) {
                    gapStart = currentEvent.end;
                    gapEnd = nextEvent.start;
                }
            }
            else {
                /*
                    We're at the last event, check if the current event ends before the boundaries end
                */
                var currentEventEndsBeforeBoundariesEnd = currentEvent.end.getTime() < args.boundaries.end.getTime();
                if (currentEventEndsBeforeBoundariesEnd) {
                    gapStart = currentEvent.end;
                    gapEnd = args.boundaries.end;
                }
            }
            if (gapStart && gapEnd) {
                var gap = this.createScheduleGap({
                    start: gapStart,
                    end: gapEnd,
                    eventBefore: currentEvent,
                    eventAfter: nextEvent,
                }, args);
                if (gap) {
                    if (gap.isValid) {
                        gaps.push(gap);
                    }
                    timeline.push(gap);
                }
            }
        }
        return {
            gaps: gaps,
            timeline: timeline,
        };
    };
    /*
      When no events exist, we can create a gap between the boundaries start and end
      Precondition: currentEvents.length === 0
    */
    Scheduler.prototype.findGapsWhenNoEventsExist = function (args) {
        var gap = this.createScheduleGap({
            start: args.boundaries.start,
            end: args.boundaries.end,
            eventBefore: undefined,
            eventAfter: undefined,
        }, args);
        if (!gap) {
            return {
                gaps: [],
                timeline: [],
            };
        }
        return {
            gaps: gap.isValid ? [gap] : [],
            timeline: [gap],
        };
    };
    Scheduler.prototype.createScheduleGap = function (gap, args) {
        /*
        I think this was done to fix something with the booking suggestions,
        but makes tests fail when i.e. testing for second-long gaps.
        */
        /* let start = new DateHelper(gap.start).set("seconds", 0).set("ms", 0).date;
        let end = new DateHelper(gap.end).set("seconds", 0).set("ms", 0).date; */
        var start = gap.start;
        var end = gap.end;
        if (args.roundUpGapStartToTheNearestMs) {
            var remainderStart = start.getTime() % args.roundUpGapStartToTheNearestMs;
            if (remainderStart > 0) {
                start = new Date(start.getTime() + args.roundUpGapStartToTheNearestMs - remainderStart);
            }
        }
        if (args.roundDownGapEndToTheNearestMs) {
            var remainderEnd = end.getTime() % args.roundDownGapEndToTheNearestMs;
            if (remainderEnd > 0) {
                end = new Date(end.getTime() - remainderEnd);
            }
        }
        // Check if rounding has caused the gap to no longer be a gap
        if (start.getTime() >= end.getTime()) {
            console.log("start.getTime() >= end.getTime()");
            return null;
        }
        var isValid = this.isValidGap(start, end, args.minimumLengthOfValidGapInMs);
        var durationInMs = end.getTime() - start.getTime();
        var newGap = __assign(__assign({}, gap), { start: start, end: end, durationInMs: durationInMs, type: "gap", isValid: isValid });
        return newGap;
    };
    Scheduler.prototype.isValidGap = function (start, end, minLength) {
        return end.getTime() - start.getTime() >= minLength;
    };
    /*
      Although it's probably not the most effcient, we can find slots by finding gaps and then filtering them
    */
    Scheduler.prototype.findSlots = function (args) {
        var roundToFiveMinutes = function (args) {
            var fiveMinInMs = 1000 * 60 * 5;
            var remainder = args.date.getTime() % fiveMinInMs;
            if (args.round === "up" && remainder > 0) {
                return new Date(args.date.getTime() + fiveMinInMs - remainder);
            }
            if (args.round === "down" && remainder > 0) {
                return new Date(args.date.getTime() - remainder);
            }
            return args.date;
        };
        var gaps = this.findGaps(__assign(__assign({}, args), { minimumLengthOfValidGapInMs: 0 })).gaps;
        var slots = [];
        gaps.forEach(function (gap) {
            var _a, _b;
            var prev = gap.eventBefore;
            var getTravelTime = function (args) {
                var _a, _b;
                if (args.callback) {
                    try {
                        return ((_b = args.callback({
                            destination: (_a = args.destination) !== null && _a !== void 0 ? _a : false,
                        })) !== null && _b !== void 0 ? _b : null);
                    }
                    catch (er) {
                        /* console.log({
                          context:
                            "Scheduler.findSlots(), getTravelTime local function, catch clause",
                          error: er,
                        }); */
                        return null;
                    }
                }
                else {
                    return null;
                }
            };
            var travelTimeTo = getTravelTime({
                destination: prev,
                callback: args.travelTimeCallback,
            });
            var next = gap.eventAfter;
            var travelTimeFrom = getTravelTime({
                destination: next,
                callback: args.travelTimeCallback,
            });
            var travelTimeToValue = (_a = travelTimeTo === null || travelTimeTo === void 0 ? void 0 : travelTimeTo.duration.valueInMilliseconds) !== null && _a !== void 0 ? _a : 0;
            var travelTimeFromValue = (_b = travelTimeFrom === null || travelTimeFrom === void 0 ? void 0 : travelTimeFrom.duration.valueInMilliseconds) !== null && _b !== void 0 ? _b : 0;
            var totalDurationNeeded = travelTimeToValue +
                travelTimeFromValue +
                args.eventToSchedule.durationInMs;
            var gapDuration = gap.end.getTime() - gap.start.getTime();
            var gapIsLongEnough = gapDuration >= totalDurationNeeded;
            /* const normalisedGap = normaliseGap({
              gap,
              totalDurationNeeded,
            }); */
            if (gapIsLongEnough) {
                var earliestStart = new Date(gap.start.getTime() + travelTimeToValue);
                var latestEnd = new Date(gap.end.getTime() - travelTimeFromValue);
                var roundedStart = roundToFiveMinutes({
                    date: earliestStart,
                    round: "up",
                });
                var roundedEnd = roundToFiveMinutes({
                    date: latestEnd,
                    round: "down",
                });
                var roundedDuration = roundedEnd.getTime() - roundedStart.getTime();
                if (roundedDuration >= args.eventToSchedule.durationInMs) {
                    earliestStart = roundedStart;
                    latestEnd = roundedEnd;
                }
                var latestStart = new Date(latestEnd.getTime() - args.eventToSchedule.durationInMs);
                var before = void 0;
                if (prev) {
                    before = {
                        type: "event",
                        event: prev,
                        travelInfo: travelTimeTo,
                    };
                }
                else {
                    before = {
                        type: "origin",
                        travelInfo: travelTimeTo,
                    };
                }
                var after = void 0;
                if (next) {
                    after = {
                        type: "event",
                        event: next,
                        travelInfo: travelTimeFrom,
                    };
                }
                else {
                    after = {
                        type: "origin",
                        travelInfo: travelTimeFrom,
                    };
                }
                var totalTravelTimeInMs = null;
                if (travelTimeTo && travelTimeFrom) {
                    totalTravelTimeInMs =
                        travelTimeTo.duration.valueInMilliseconds +
                            travelTimeFrom.duration.valueInMilliseconds;
                }
                var id = UUID_1.UUID.generate().value;
                var slot = {
                    id: id,
                    boundaries: {
                        start: earliestStart,
                        end: latestEnd,
                    },
                    scheduleBoundaries: args.boundaries,
                    earliestStart: earliestStart,
                    latestStart: latestStart,
                    before: before,
                    after: after,
                    totalTravelTimeInMs: totalTravelTimeInMs,
                    event: args.eventToSchedule,
                    ifEventWasSkippedScenario: null,
                };
                slots.push(slot);
            }
        });
        return { slots: slots };
    };
    Scheduler.prototype.fillSlotsWithPreviousToNextEventTravelInformation = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            var fromToPairs, allOrigins, allDestinations, _i, _a, slot, previousPlace, nextPlace, travelInfoResults_1, er_1;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        fromToPairs = [];
                        allOrigins = [];
                        allDestinations = [];
                        for (_i = 0, _a = args.slots; _i < _a.length; _i++) {
                            slot = _a[_i];
                            previousPlace = args.adressCallback({
                                event: (_b = slot.before.event) !== null && _b !== void 0 ? _b : null,
                                slot: slot,
                            });
                            nextPlace = args.adressCallback({
                                event: (_c = slot.after.event) !== null && _c !== void 0 ? _c : null,
                                slot: slot,
                            });
                            if (previousPlace && nextPlace) {
                                fromToPairs.push({
                                    from: previousPlace,
                                    to: nextPlace,
                                });
                                allOrigins.push(previousPlace);
                                allDestinations.push(nextPlace);
                            }
                        }
                        if (!fromToPairs.length) {
                            return [2 /*return*/, true];
                        }
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, args.previousToNextTravelInfoCallback({
                                fromToPairs: fromToPairs,
                                allOrigins: allOrigins,
                                allDestinations: allDestinations,
                            })];
                    case 2:
                        travelInfoResults_1 = _d.sent();
                        args.slots.forEach(function (slot) {
                            var _a, _b;
                            var previousPlace = args.adressCallback({
                                event: (_a = slot.before.event) !== null && _a !== void 0 ? _a : null,
                                slot: slot,
                            });
                            var nextPlace = args.adressCallback({
                                event: (_b = slot.after.event) !== null && _b !== void 0 ? _b : null,
                                slot: slot,
                            });
                            if (previousPlace && nextPlace) {
                                var travelInfo = travelInfoResults_1
                                    .from(previousPlace)
                                    .to(nextPlace);
                                var additionalTravelTimeInMs = null;
                                if (slot.totalTravelTimeInMs) {
                                    additionalTravelTimeInMs =
                                        slot.totalTravelTimeInMs -
                                            travelInfo.duration.valueInMilliseconds;
                                }
                                slot.ifEventWasSkippedScenario = {
                                    travelInfo: travelInfo,
                                    additionalTravelTimeInMs: additionalTravelTimeInMs,
                                };
                            }
                        });
                        return [2 /*return*/, true];
                    case 3:
                        er_1 = _d.sent();
                        /*  console.log({
                          context:
                            "fillSlotsWithPreviousToNextEventTravelInformation catch clause",
                          error: er,
                        }); */
                        return [2 /*return*/, false];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    return Scheduler;
}());
exports.Scheduler = Scheduler;
