import { WorkOrder } from "@eljouren/domain";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useParams } from "react-router-dom";
import useMutableQuery, {
  UseMutableQueryResult,
} from "../../../hooks/use-mutatable-query";
import { useRepos } from "../../../hooks/use-repos";
import SomethingWentWrong from "../../on-error/SomethingWentWrong";

export const CustomerAuthContext = React.createContext<{
  workOrderGuid: string;
  isError: boolean;
  isLoading: boolean;
  workOrder: WorkOrder.CustomerType | undefined;
  workOrderRes: UseMutableQueryResult<WorkOrder.CustomerType>;
  refetch: () => void;
}>({} as never);

const withCustomerGuidV2 =
// Is this bad practice?
  <T extends {}>(WrappedComponent: React.ComponentType<T>) => (props: T) => {
    const { workOrderRepo } = useRepos();
    const params = useParams();
    const workOrderGuid = params["workOrderGuid"] || "";

    const res = useMutableQuery({
      queryKey: ["customerSignInState", workOrderGuid],
      queryFn: async () => {
        const res = await workOrderRepo.authenticateCustomerWithWorkOrder({
          guid: workOrderGuid,
        });
        return res;
      },
    });

    const workOrder = res.query.data;
    /* 
    if (process.env.NODE_ENV === "development" && workOrder?.paymentDetails) {
      workOrder.paymentDetails.externalPaymentFlowAllowed = false;
    } */

    return (
      <CustomerAuthContext.Provider
        value={{
          workOrderGuid,
          isError: res.query.isError,
          isLoading: res.isLoading,
          workOrder,
          refetch: res.query.refetch,
          workOrderRes: res,
        }}
      >
        <ErrorBoundary
          fallbackRender={(props) => {
            return <SomethingWentWrong error={props.error} />;
          }}
        >
          <WrappedComponent 
            {...props}
          />
        </ErrorBoundary>
      </CustomerAuthContext.Provider>
    );
  };

export default withCustomerGuidV2;
