import { AnimatePresence, motion } from "framer-motion";
import React, { ReactElement, useCallback, useState } from "react";
import { createPortal } from "react-dom";

interface MyDialogProps {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  setOpen: (open: boolean) => void;
}

interface MyDialogComponentProps {
  children: (props: MyDialogProps) => ReactElement;
  content: (props: MyDialogProps) => ReactElement;
  controlledOpen?: boolean;
}

const MyDialogWithToggle: React.FC<MyDialogComponentProps> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((isOpen) => !isOpen), []);

  const dialogProps: MyDialogProps = {
    isOpen,
    open,
    close,
    toggle,
    setOpen: setIsOpen,
  };

  return (
    <>
      {props.children(dialogProps)}
      <Content {...dialogProps} {...props} />
    </>
  );
};

const Content = (props: MyDialogComponentProps & MyDialogProps) => {
  return createPortal(
    <AnimatePresence>
      {props.isOpen && (
        <motion.div
          className="fixed inset-0 left-0 top-0 z-[9999] flex h-full w-full items-center justify-center bg-black bg-opacity-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          role="dialog"
          aria-modal="true"
        >
          {props.content(props)}
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default MyDialogWithToggle;
