"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdAuthParams = void 0;
var zod_1 = require("zod");
var BankIdAuthParams;
(function (BankIdAuthParams) {
    BankIdAuthParams.Schema = zod_1.z.object({
        personalNumber: zod_1.z.string(),
        endUserIp: zod_1.z.string(),
    });
})(BankIdAuthParams || (BankIdAuthParams = {}));
exports.BankIdAuthParams = BankIdAuthParams;
