import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ChecklistNumericInputBuilder from "./ChecklistNumericInputBuilder";

interface Props {
  className?: string;
  closeForm: () => void;
}

const ChecklistNumericInputCreator = (props: Props) => {
  const ctx = useFormEditor();

  return (
    <ChecklistNumericInputBuilder
      closeForm={props.closeForm}
      onSubmit={(values) => {
        ctx.controls.addElement({ element: values });
        props.closeForm();
      }}
    />
  );
};

export default ChecklistNumericInputCreator;
