import { ClockIcon } from "@heroicons/react/24/outline";
import ClientUtils from "../../utils/ClientUtils";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppScheduleIcon = (props: Props) => {
  const { className, ...rest } = props;

  /* 
    Same as the clock icon for now, not sure what to use otherwise
  */
  return (
    <ClockIcon
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default withIconSize(AppScheduleIcon);
