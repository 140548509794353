import {
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  direction: "enter" | "exit";
}

const AppDoorIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.direction === "enter") {
    return <ArrowLeftOnRectangleIcon {...rest} className={props.className} />;
  }

  return <ArrowRightOnRectangleIcon {...rest} className={props.className} />;
};
export default withIconSize(AppDoorIcon);
