import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import ClientUtils from "../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext, {
  PaymentOptionsFormValues,
} from "./CustomerWorkOrderInvoiceContext";
import InvoicePaymentOptionFormElement from "./payment-method-components/InvoicePaymentRegisteredFormElement";

interface Props {
  form: UseFormReturn<PaymentOptionsFormValues>;
  skeletonCount?: number;
}

const InvoicePaymentOptionsFormMain = (props: Props) => {
  const paymentProcessCtx = useContext(CustomerWorkOrderInvoiceContext);
  const res = paymentProcessCtx.paymentInitRes;

  const errors = props.form.formState.errors;
  const hasErrors = Object.keys(errors).length > 0;
  const isInvalid = !props.form.formState.isValid && hasErrors;

  const isLoading = res.isLoading;
  const isError =
    (!isLoading && res.isError) ||
    paymentProcessCtx.paymentOptions.length === 0;

  return (
    <main
      className={ClientUtils.classNames(
        "border-2 border-transparent",
        "grid w-full grid-cols-1 gap-4",
        isInvalid && "rounded focus-within:border-red-400"
      )}
    >
      {isLoading && (
        <Skeleton
          count={props.skeletonCount ?? 3}
          height={80}
          className="mb-4"
        />
      )}
      {res.isError && (
        <span className="p-2">
          Det gick inte att hämta betalningsalternativ just nu. Vänligen försök
          igen senare.
        </span>
      )}
      {!isLoading &&
        !isError &&
        paymentProcessCtx.paymentOptions.map((option) => {
          return (
            <InvoicePaymentOptionFormElement
              key={option.id}
              option={option}
              form={props.form}
            />
          );
        })}
    </main>
  );
};

export default InvoicePaymentOptionsFormMain;
