import ClientUtils from './../../../../utils/ClientUtils';
import { DateHelper, Utils } from "@eljouren/utils";
import { formatDuration, intervalToDuration } from "date-fns";
import { sv } from "date-fns/locale";
import { useContext, useRef, useState } from "react";
import { useOnOutsideClick } from "../../../../hooks/hooks";
import SingleBookingSuggestionContext from "./SingleBookingSuggestionContext";

interface Props {
  className?: string;
}

const BookingSuggestionSlot = (props: Props) => {
  const ctx = useContext(SingleBookingSuggestionContext);
  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const mainDivRef = useRef<HTMLDivElement>(null);
  const moreInfoRef = useRef<HTMLDivElement>(null);

  const slot = ctx.slot;

  useOnOutsideClick(
    moreInfoRef,
    () => {
      setShowMoreInfo(false);
    },
    mainDivRef
  );

  function toggleShowMoreInfo() {
    setShowMoreInfo(!showMoreInfo);
  }

  function getSimpleIntervalFormat() {
    const totalDurationInMs =
      slot.boundaries.end.getTime() - slot.boundaries.start.getTime();

    const hours = Math.floor(totalDurationInMs / 1000 / 60 / 60);
    const minutes = Math.floor(
      (totalDurationInMs / 1000 / 60 / 60 - hours) * 60
    );
    let formatedTotalDuration: string;

    if (minutes >= 5) {
      formatedTotalDuration = `${hours}h ${minutes}m`;
    } else {
      formatedTotalDuration = `${hours}h`;
    }
    return formatedTotalDuration;
  }

  function getDateFnsIntervalFormat() {
    const totalDurationInMs =
      slot.boundaries.end.getTime() - slot.boundaries.start.getTime();
    const duration = intervalToDuration({ start: 0, end: totalDurationInMs });
    const formatedTotalDuration = formatDuration(duration, { locale: sv });
    return formatedTotalDuration;
  }

  const simpleIntervalFormat = getSimpleIntervalFormat();

  const dateFnsIntervalFormat = getDateFnsIntervalFormat();

  /* 
			  Format total duration to readable format
			*/
  const startHelper = new DateHelper(slot.boundaries.start);
  const endHelper = new DateHelper(slot.boundaries.end);
  const earliestStartHelper = new DateHelper(slot.earliestStart);
  const latestStartHelper = new DateHelper(slot.latestStart);

  const dateHelper = new DateHelper(ctx.slot.boundaries.start);
  const dateString = `${Utils.capitalize(dateHelper.dayOfWeek)} ${
    dateHelper.readableDate
  }`;

  return (
    <div
      className={ClientUtils.twClassNames(
        props.className,
        "relative flex cursor-pointer flex-col rounded border-2 border-brand-blue-100 bg-brand-blue-100 p-2 hover:border-brand-blue-300",
        showMoreInfo && "border-brand-blue-300"
      )}
      onClick={toggleShowMoreInfo}
      ref={mainDivRef}
    >
      {ctx.showDate && (
        <span className="text-sm text-dark-gray">{dateString}</span>
      )}

      <span className="text-base font-bold">
        {startHelper.timeInputFormat}-{endHelper.timeInputFormat} (
        {simpleIntervalFormat})
      </span>

      {showMoreInfo && (
        <div
          className="pointer-events-none absolute left-0 top-full mt-2 flex w-96 cursor-default flex-col gap-2 bg-brand-blue-100 p-4"
          ref={moreInfoRef}
        >
          <ul className="list-disc gap-2 pl-4 text-dark-gray">
            <li className="text-sm">
              Lucka mellan {startHelper.timeInputFormat} och{" "}
              {endHelper.timeInputFormat} ({dateFnsIntervalFormat})
            </li>
            <li className="text-sm">
              <p className="text-sm">
                Kan bokas in{" "}
                <b>tidigast {earliestStartHelper.timeInputFormat}</b> och{" "}
                <b>senast {latestStartHelper.timeInputFormat}</b>
              </p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default BookingSuggestionSlot;
