"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SplashScreen = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var client_utils_1 = require("../../utils/client-utils");
var brand_hooks_1 = require("../../hooks/brand-hooks");
var AnimatedIpisLogoType_1 = require("./AnimatedIpisLogoType");
var SplashScreen = function (props) {
    var externalBrand = (0, brand_hooks_1.useExternalBrandConstants)();
    var theme = (0, brand_hooks_1.useExternalBrandTheme)();
    function onDone() {
        if (props.externalBrandTheme) {
            theme.setExternalBrandTheme(props.externalBrandTheme);
        }
        if (props.onSplashScreenDone) {
            props.onSplashScreenDone();
        }
    }
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { className: (0, client_utils_1.classNames)("z-10 h-full w-full bg-black", props.fixed && "fixed left-0 top-0", !props.fixed && "flex", props.className), exit: {
            opacity: 0,
        }, transition: {
            duration: 0.5,
        }, children: [externalBrand && ((0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { initial: true, children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "m-auto flex h-full w-full max-w-screen-sm items-center justify-center", initial: {
                        opacity: 0,
                        y: 100,
                    }, animate: {
                        opacity: 1,
                        y: 0,
                    }, transition: {
                        duration: 1,
                        ease: "easeInOut",
                    }, onAnimationComplete: onDone, children: (0, jsx_runtime_1.jsx)("img", { src: externalBrand.logoUrl, alt: "".concat(externalBrand.brandName, " logga") }) }) })), !externalBrand && ((0, jsx_runtime_1.jsx)("div", { className: "m-auto flex h-full w-full max-w-screen-sm items-center justify-center", children: (0, jsx_runtime_1.jsx)(AnimatedIpisLogoType_1.AnimatedIpisLogoType, { onAnimationComplete: onDone, variant: "white" }) }))] }));
};
exports.SplashScreen = SplashScreen;
