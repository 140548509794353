"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormProgressIndicator = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var client_utils_1 = require("../../../utils/client-utils");
var FormProgressIndicatorListItem_1 = require("./FormProgressIndicatorListItem");
/*
  Needs accessbility improvements
*/
var FormProgressIndicator = function (props) {
    return ((0, jsx_runtime_1.jsx)("ol", { className: (0, client_utils_1.classNames)("grid w-full gap-x-2", !props.fixedAtTop && "p-2"), style: {
            gridTemplateColumns: "repeat(".concat(props.pages.length, ", minmax(0, 1fr))"),
        }, children: props.pages.map(function (page, index) {
            return ((0, jsx_runtime_1.jsx)(FormProgressIndicatorListItem_1.FormProgressIndicatorListItem, { label: page.label, index: index, currentStep: props.currentStep, fixedAtTop: props.fixedAtTop }, page.label));
        }) }));
};
exports.FormProgressIndicator = FormProgressIndicator;
