"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormProgressIndicatorListItem = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var tailwind_hooks_1 = require("../../../hooks/tailwind-hooks");
var client_utils_1 = require("../../../utils/client-utils");
var FormProgressIndicatorListItem = function (props) {
    var _a;
    var index = props.index, label = props.label, currentStep = props.currentStep;
    var colors = (0, tailwind_hooks_1.useTailwindColors)();
    /*
      Need to make more accessible: Look at the former form progress indicator for direction
    */
    var isCurrentOrPast = index <= currentStep;
    return ((0, jsx_runtime_1.jsxs)(Wrapper, { as: (_a = props.as) !== null && _a !== void 0 ? _a : "li", className: "flex flex-col gap-1 text-center", children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.span, { "aria-hidden": true, className: (0, client_utils_1.classNames)("h-1 sm:h-2 md:h-3 w-full", !props.fixedAtTop && "rounded"), initial: {
                    background: isCurrentOrPast ? colors.cta : colors["input-background"],
                }, animate: {
                    background: isCurrentOrPast ? colors.cta : colors["input-background"],
                } }), (0, jsx_runtime_1.jsx)("span", { className: "text-2xs sm:text-1xs md:text-base text-semi-faded", children: label })] }, "checklist-page-indicator-".concat(index)));
};
exports.FormProgressIndicatorListItem = FormProgressIndicatorListItem;
var Wrapper = function (props) {
    if (props.as === "li") {
        return (0, jsx_runtime_1.jsx)(framer_motion_1.motion.li, { className: props.className, children: props.children });
    }
    else {
        return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: props.className, children: props.children }));
    }
};
