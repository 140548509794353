import { useEffect, useRef } from "react";
import { useGooglePlaces } from "../hooks/use-google-places";
import ClientUtils from "../utils/ClientUtils";
import CheckmarkIcon from "./icons/AppCheckmarkIcon";
import AppErrorIcon from "./icons/AppErrorIcon";

type TGoogleAdressInputResponse = {
  formattedAddress: string;
  latitude: number;
  longitude: number;
};

interface AddressInputProps extends React.HTMLProps<HTMLInputElement> {
  onAddressSelected?: (address: TGoogleAdressInputResponse) => void;
  className?: string;
  defaultValue?: string;
}

const GoogleAddressInput = (props: AddressInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const places = useGooglePlaces();
  const hasValue = !!props.defaultValue;

  const { className, onAddressSelected, ...rest } = props;

  useEffect(() => {
    if (!places || !window.google || !inputRef.current) {
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current
    );

    const listener = autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      if (place.geometry && place.geometry.location) {
        const formattedAddress = place.formatted_address ?? "";
        const latitude = place.geometry.location.lat();
        const longitude = place.geometry.location.lng();

        onAddressSelected?.({
          formattedAddress,
          latitude,
          longitude,
        });
      } else {
        window.alert("Inga detaljer hittades för den här adressen");
      }
    });

    return () => {
      // Remove the listener when the component is unmounted
      window.google.maps.event.removeListener(listener);
    };
  }, [onAddressSelected, places]);

  return (
    <span
      className={ClientUtils.twClassNames(
        props.className,
        "grid grid-cols-[minmax(0,1fr),auto]"
      )}
    >
      <input
        {...rest}
        /* 
          Override the ref intentionally        
        */
        ref={inputRef}
        className="w-full rounded px-4 py-2"
        type="text"
        placeholder="Skriv in address"
        defaultValue={props.defaultValue}
      />
      <span className="flex items-center justify-center px-2">
        {hasValue && <CheckmarkIcon size={24} className="text-green-600" />}
        {!hasValue && <AppErrorIcon size={24} className="text-red-600" />}
      </span>
    </span>
  );
};

export default GoogleAddressInput;
