"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisSelectCompact = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var client_utils_1 = require("../../../../utils/client-utils");
var IpisCaretIcon_1 = require("../../../icons/IpisCaretIcon");
var FauxFieldsetWrapper_1 = require("../components/FauxFieldsetWrapper");
var IpisSelectCompact = function (props) {
    var _a;
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var readOnly = props.readOnly || !!(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly);
    var registerProps = (0, form_hooks_1.useFormRegisterProps)({
        props: props,
        type: "select",
    });
    var currentValue = (0, form_hooks_1.useFormValue)(props, {
        defaultValue: (_a = props.options[0]) === null || _a === void 0 ? void 0 : _a.value,
    });
    var _b = (0, react_1.useState)(false), isFocused = _b[0], setIsFocused = _b[1];
    var _c = (0, react_1.useState)(false), isHovered = _c[0], setIsHovered = _c[1];
    return ((0, jsx_runtime_1.jsx)(FauxFieldsetWrapper_1.FauxFieldsetWrapper, { required: props.required, isFocused: isFocused, isHovered: isHovered, label: {
            text: props.label,
            htmlFor: props.id,
        }, size: "md", leftSidePaddingInPixels: 8, readOnly: readOnly, className: (0, client_utils_1.classNames)(props.className, "relative"), children: (0, jsx_runtime_1.jsxs)("div", { className: "col-span-3 col-start-1 row-start-1 grid grid-cols-[minmax(0,1fr)]", children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.select, __assign({ id: props.id, className: "appearance-none gap-2  rounded bg-transparent px-4 py-4 pr-8 focus:outline-none" }, registerProps, { onFocus: function (e) {
                        var _a;
                        setIsFocused(true);
                        (_a = props.onFocus) === null || _a === void 0 ? void 0 : _a.call(props, e);
                    }, onBlur: function (e) {
                        var _a, _b;
                        setIsFocused(false);
                        (_a = registerProps.onBlur) === null || _a === void 0 ? void 0 : _a.call(registerProps, e);
                        (_b = props === null || props === void 0 ? void 0 : props.onBlur) === null || _b === void 0 ? void 0 : _b.call(props, e);
                    }, onHoverStart: function () { return setIsHovered(true); }, onHoverEnd: function () { return setIsHovered(false); }, disabled: props.disabled || readOnly, tabIndex: readOnly ? -1 : undefined, children: props.options.map(function (option, i) {
                        var key = "".concat(props.id, "-").concat(option.value, "-").concat(i);
                        return ((0, jsx_runtime_1.jsx)(OptionComponent, { index: i, option: option, id: props.id, currentValue: currentValue, name: props.name }, key));
                    }) })), (0, jsx_runtime_1.jsx)(IpisCaretIcon_1.IpisCaretIcon, { direction: "down", className: "absolute right-2 top-1/2 -translate-y-1/2" })] }) }));
};
exports.IpisSelectCompact = IpisSelectCompact;
var OptionComponent = function (props) {
    var id = "".concat(props.id, "-").concat(props.option.value, "-").concat(props.index);
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.option, { className: "focus:outline-4 focus:outline-red-400", value: props.option.value, label: props.option.label }, id));
};
