import React, { useContext, useEffect } from "react";
import { GlobalContext } from "../../../top-level-contexts";
import { useNavigate } from "react-router-dom";

/*
	Placeholder
*/
const withAdminPermissions =
  (WrappedComponent: () => React.ReactElement) => () => {
    const { signInState } = useContext(GlobalContext);

    const isLoading = signInState.isLoading;
    const isAdmin =
      (signInState.isSignedIn && signInState.isAdmin) ||
      process.env.NODE_ENV === "development";

    const navigate = useNavigate();

    useEffect(() => {
      if (!isAdmin && !isLoading) {
        navigate("/");
      }
    }, [isAdmin, isLoading, navigate]);

    if (!isAdmin) {
      return <></>;
    }

    return (
      <>
        <WrappedComponent />
      </>
    );
  };

export default withAdminPermissions;
