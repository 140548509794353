import { ChecklistItemProps } from "./ChecklistItem";
import ChecklistAnsweredIndicator from "./ChecklistAnsweredIndicator";

interface Props extends ChecklistItemProps {
  className?: string;
}

const ChecklistItemText = (props: Props) => {
  const item = props.item;
  const id = item.id + ".answer";
  const name = item.id + ".answer";
  const currentValue = props.form.watch(name);
  const isAnswered =
    typeof currentValue === "string" && currentValue.length > 0;

  const isRequired = item.required;

  return (
    <p className="flex w-full flex-col gap-2">
      <label
        className="grid grid-cols-[auto,1fr] grid-rows-1 items-center gap-2 text-sm font-semibold md:text-base"
        htmlFor={id}
      >
        <span>{item.name}</span>
        <ChecklistAnsweredIndicator
          isRequired={isRequired}
          isAnswered={isAnswered}
        />
      </label>

      <textarea
        className="w-full resize-none rounded-sm border border-gray-300/40 bg-white/20 p-2 placeholder:text-sm placeholder:italic placeholder:text-black/60"
        {...props.form.register(name)}
        data-checklist-item-type="text"
        id={id}
        readOnly={props.readOnly}
        placeholder="Ditt svar på frågan..."
        rows={item.rows ?? 1}
        maxLength={3000}
      />
    </p>
  );
};

export default ChecklistItemText;
