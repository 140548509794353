import {
  motion,
  MotionProps,
  TargetAndTransition,
  Transition
} from "framer-motion";
import { useRef } from "react";
import { usePrevious } from "../../../hooks/hooks";
import { useOnEnterClick } from "../../../hooks/keyboard-event-hooks";
import { useTailwindColors } from "../../../hooks/tailwind-hooks";
import ClientUtils from "../../../utils/ClientUtils";
import AppCaretIcon from "../../icons/AppCaretIcon";
import AppPlusIcon from "../../icons/AppPlusIcon";
import AppTrashIcon from "../../icons/AppTrashIcon";

export type AppMenuItemButtonIconType = "plus" | "trash";
type TPublicIcon = {
  type: AppMenuItemButtonIconType;
};

type PrivateIconType = AppMenuItemButtonIconType | "caret-right";
type TPrivateIcon = {
  type: PrivateIconType;
};

type Variant = "light-scheme" | "dark-scheme";

export type AppListItemButtonV2Props = {
  loading?: boolean | number;
  variant?: Variant;
  disabled?: boolean;
  type?: "button" | "submit";
  selected: boolean;
  onClick?: () => void;
  label: string;
  leadingIcon?: TPublicIcon;
  expandable?: {
    isExpanded: boolean;
  };
  trailingIcon?: TPublicIcon;
  nestedCount?: number;
  onContextMenu?: (e: React.MouseEvent) => void;
};

export function AppMenuItemButtonV2(props: AppListItemButtonV2Props) {
  const nestedCount = props.nestedCount ?? 0;
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const previouslySelected = usePrevious(props.selected);

  const colors = useTailwindColors();

  useOnEnterClick({
    ref: buttonRef,
    callback: () => {
      buttonRef.current?.click();
    },
  });

  const {
    loading,
    disabled,
    type,
    trailingIcon,
    leadingIcon,
    expandable,
    nestedCount: _nestedCount,
    ...rest
  } = props;

  const actAsDisabled = disabled;
  const variant = props.variant ?? "light-scheme";

  const makeAnimationInstant = props.selected || previouslySelected;

  const hoverTransition: Transition = {
    duration: makeAnimationInstant ? 0 : 0.2,
    ease: "easeInOut",
    color: { duration: makeAnimationInstant ? 0 : 0.1 },
  };

  const animateTransition: Transition = {
    duration: 0,
  };

  function getAnimations(): {
    initial: MotionProps["initial"];
    animate?: TargetAndTransition;
    whileHover: TargetAndTransition;
  } {
    if (variant === "light-scheme") {
      const animate = {
        backgroundColor: actAsDisabled
          ? "#ffffff00"
          : props.selected
          ? colors.secondary[200]
          : colors.primary[100],
        color: actAsDisabled ? colors.dark[300] : colors.dark[950],
        //empty drop shadow to make animation work
        transition: animateTransition,
      };
      const initial = animate;
      return {
        initial,
        animate,
        whileHover: {
          backgroundColor: props.selected
            ? colors.secondary["200"]
            : colors.secondary["100"],
          transition: hoverTransition,
        },
      };
    }

    if (variant === "dark-scheme") {
      const bgDisabled = colors.dark[800];
      const bgSelected = colors.dark[950];
      const bgDefault = colors.dark[800];
      const bgHover = colors.dark[900];
      const animate = {
        backgroundColor: actAsDisabled
          ? bgDisabled
          : props.selected
          ? bgSelected
          : bgDefault,
        color: actAsDisabled
          ? colors.secondary[100]
          : colors["light-background"],
        transition: animateTransition,
      };
      const initial = animate;

      return {
        initial,
        animate,
        whileHover: {
          transition: hoverTransition,
          backgroundColor: props.selected ? bgSelected : bgHover,
        },
      };
    }

    return {
      initial: {},
      animate: {},
      whileHover: {},
    };
  }
  return (
    <motion.button
      className={ClientUtils.classNames(
        "relative flex items-center gap-2 px-4 py-2 font-semibold focus:outline-1 focus:outline-focus disabled:pointer-events-none",
        props.selected && "border-primary-400",
        !props.selected && "border-transparent",
        actAsDisabled && "pointer-events-none"
      )}
      onContextMenu={props.onContextMenu}
      disabled={actAsDisabled}
      type={type ?? "button"}
      style={{
        paddingLeft: `${nestedCount * 1 + 1}rem`,
        borderLeftWidth: `${nestedCount * 4}px`,
      }}
      {...getAnimations()}
      ref={buttonRef}
      onClick={props.onClick}
      {...rest}
    >
      {props.leadingIcon && <Icon icon={props.leadingIcon} />}
      <motion.span
        className={ClientUtils.twClassNames(
          "flex gap-2 text-start font-light transition-all",
          !!loading && "opacity-40"
        )}
      >
        {props.label}
      </motion.span>
      {(trailingIcon || expandable) && (
        <Icon
          icon={trailingIcon ?? { type: "caret-right" }}
          className={ClientUtils.classNames(
            "ml-auto transition-all",
            props.expandable?.isExpanded && "rotate-90"
          )}
        />
      )}
      {/* <AnimatePresence>
        {!!props.loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50"
          >
            <AppLoader className="h-5 max-h-full w-5" />
          </motion.div>
        )}
      </AnimatePresence> */}
    </motion.button>
  );
}

const Icon = (props: { icon: TPrivateIcon; className?: string }) => {
  const type = props.icon.type;
  switch (type) {
    case "plus":
      return <AppPlusIcon size="sm" className={props.className} />;
    case "caret-right":
      return (
        <AppCaretIcon direction="right" size="sm" className={props.className} />
      );
    case "trash":
      return <AppTrashIcon size="sm" className={props.className} />;
  }
};
