"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormConditionGroup = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var FormCondition_1 = require("./FormCondition");
var IpisFormConditionGroup;
(function (IpisFormConditionGroup) {
    IpisFormConditionGroup.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        clientSideId: centralized_zod_1.z.string().uuid().nullable(),
        conditions: FormCondition_1.FormCondition.Schema.array(),
        relationship: centralized_zod_1.z.enum(["and", "or"]),
    });
    IpisFormConditionGroup.QuestionSchema = IpisFormConditionGroup.Schema.extend({
        id: centralized_zod_1.z.string(),
        clientSideId: centralized_zod_1.z.string().uuid().nullable(),
        conditions: FormCondition_1.FormCondition.QuestionSchema.array(),
        relationship: centralized_zod_1.z.enum(["and", "or"]),
    });
})(IpisFormConditionGroup = exports.IpisFormConditionGroup || (exports.IpisFormConditionGroup = {}));
