import { WorkOrderLineItem } from "@eljouren/domain";

export default class WorkOrderReimbursementCalculator {
  constructor(
    private readonly props: {
      hoursData?: WorkOrderLineItem.HandymanType[];
      materialData?: WorkOrderLineItem.HandymanType[];
    }
  ) {}

  get forHours(): number {
    const { hoursData } = this.props;
    if (!hoursData) {
      return 0;
    }
    return hoursData
      .filter(
        (row): row is WorkOrderLineItem.HandymanType & { unitPrice: number } =>
          !!row.compensationDetails.compensationPerUnit && !row.isInactive
      )
      .reduce(
        (acc, row) =>
          acc + row.compensationDetails.compensationPerUnit! * row.quantity,
        0
      );
  }

  get forMaterial(): number {
    const { materialData } = this.props;
    if (!materialData) {
      return 0;
    }
    return materialData
      .filter(
        (row): row is WorkOrderLineItem.HandymanType & { unitPrice: number } =>
          !!row.compensationDetails.compensationPerUnit && !row.isInactive
      )
      .reduce(
        (acc, row) =>
          acc + row.compensationDetails.compensationPerUnit! * row.quantity,
        0
      );
  }

  get forAll(): number {
    return Number((this.forHours + this.forMaterial).toFixed(2));
  }
}
