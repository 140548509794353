import { IpisFormElement } from "@eljouren/domain";
import { useChecklistPage } from "../../../../../hooks/checklist-hooks";
import ChecklistDecoupledTextAreaQuestion from "./ChecklistDecoupledTextAreaQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.TextAreaType;
}

const ChecklistTextAreaQuestion = (props: Props) => {
  const ctx = useChecklistPage();
  const q = props.question;
  return <ChecklistDecoupledTextAreaQuestion question={q} form={ctx.form} />;
};

export default ChecklistTextAreaQuestion;
