"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackButtonStackContext = void 0;
exports.useBackButtonStack = useBackButtonStack;
var react_1 = require("react");
/*
  Buggy!
*/
exports.BackButtonStackContext = (0, react_1.createContext)({});
function useBackButtonStack() {
    return (0, react_1.useContext)(exports.BackButtonStackContext);
}
