import AppCaretIcon from "./AppCaretIcon";
import AppEditIcon from "./AppEditIcon";
import AppImageGroupFieldIcon from "./AppImageGroupFieldIcon";
import AppInformationIcon from "./AppInformationIcon";
import AppMultipleChoiceFieldIcon from "./AppMultipleChoiceFieldIcon";
import AppNumberFieldIcon from "./AppNumberFieldIcon";
import AppPlusIcon from "./AppPlusIcon";
import AppTextFieldIcon from "./AppTextFieldIcon";
import AppTrashIcon from "./AppTrashIcon";
import AppXIcon from "./AppXIcon";
import AppYesNoFieldIcon from "./AppYesNoFieldIcon";
import { ExposedIconProps } from "./types/SvgIconProps";

export type AppIconIdentifier =
  | "plus"
  | "trash"
  | "information"
  | "close"
  | "text-field"
  | "number-field"
  | "multiple-choice-field"
  | "image-group-field"
  | "yes-no-field"
  | "edit"
  | "caret-left";

interface Props extends ExposedIconProps<{}> {
  icon: AppIconIdentifier;
}

const AppIconRouter = (props: Props) => {
  const { icon, ...rest } = props;

  switch (icon) {
    case "plus":
      return <AppPlusIcon {...rest} />;
    case "trash":
      return <AppTrashIcon {...rest} />;
    case "information":
      return <AppInformationIcon {...rest} />;
    case "close":
      return <AppXIcon {...rest} />;
    case "text-field":
      return <AppTextFieldIcon {...rest} />;
    case "number-field":
      return <AppNumberFieldIcon {...rest} />;
    case "multiple-choice-field":
      return <AppMultipleChoiceFieldIcon {...rest} />;
    case "image-group-field":
      return <AppImageGroupFieldIcon {...rest} />;
    case "yes-no-field":
      return <AppYesNoFieldIcon {...rest} />;
    case "edit":
      return <AppEditIcon {...rest} />;
    case "caret-left":
      return <AppCaretIcon {...rest} direction="left" />;
  }
};

export default AppIconRouter;
