import ClientUtils from './../../../../utils/ClientUtils';
import React from "react";

interface Props extends React.HTMLAttributes<HTMLElement> {
  className?: string;
  children?: React.ReactNode;
}

const WorkOrderOverviewCard = (props: Props) => {
  const { className, children, ...rest } = props;

  return (
    <section
      className={ClientUtils.twClassNames(
        "rounded bg-off-white p-2 shadow lg:p-4",
        className
      )}
      {...rest}
    >
      {children}
    </section>
  );
};

export default WorkOrderOverviewCard;
