import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import A11yDropdown from "../../../../../components/common/dropdown/A11YDropdown";
import CustomerWorkOrderInvoiceContext from "../../CustomerWorkOrderInvoiceContext";

const InvoiceOverviewAdditionalInformationMain = () => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);

  const workOrder = ctx.workOrder;

  if (!workOrder) {
    return (
      <main className="flex flex-col gap-2 text-sm sm:p-4 sm:text-base">
        <Skeleton count={3} height={40} />
      </main>
    );
  }

  let actionDescription = workOrder.actionDescription;
  if (process.env.NODE_ENV === "development" && !actionDescription) {
    actionDescription =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget ultricies ultrices, nunc nisl ultricies nunc, eget luctus nisl nisl eget nisl.";
  }

  return (
    <main className="flex flex-col gap-2 text-sm sm:p-4 sm:text-base">
      <A11yDropdown
        preventHideOnOutsideClick
        contentId="action-description-dropdown"
        title={"Uppdragssammanfattning"}
        buttonClassName="border-b py-2"
        contentWrapperClassName="m-2 p-2 text-sm bg-slate-200 rounded"
      >
        <p>{workOrder.description}</p>
      </A11yDropdown>
      <A11yDropdown
        preventHideOnOutsideClick
        contentId="action-description-dropdown"
        title={"Uppdragsbeskrivning"}
        buttonClassName="border-b py-2"
        contentWrapperClassName="m-2 p-2 text-sm bg-slate-200 rounded"
      >
        <p>{workOrder.additionalInformation}</p>
      </A11yDropdown>
      {actionDescription && (
        <A11yDropdown
          preventHideOnOutsideClick
          contentId="action-description-dropdown"
          title={"Kommentar från hantverkare"}
          buttonClassName="border-b py-2"
          contentWrapperClassName="m-2 p-2 text-sm bg-slate-200 rounded"
        >
          <blockquote>{actionDescription}</blockquote>
        </A11yDropdown>
      )}

      {/* <A11yDropdown
		  preventHideOnOutsideClick
		  id="file-download-dropdown"
		  title={"Filer för nedladdning"}
		  caretSize={20}
		  contentWrapperClassName="p-2 text-sm flex flex-col gap-2"
		>
		  <a className="flex items-center justify-between rounded bg-slate-200 p-2">
			<span>Ladda ner faktura</span>
			<DownloadIcon size={20} />
		  </a>
		  <a className="flex items-center justify-between bg-slate-200 p-2">
			<span>Ladda ner installationsprotokoll</span>
			<DownloadIcon size={20} />
		  </a>
		</A11yDropdown> */}
    </main>
  );
};

export default InvoiceOverviewAdditionalInformationMain;
