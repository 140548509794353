import {
  IpisFormElementIsolatedAnswer,
  IpisForm,
  FormElementAnswer,
} from "@eljouren/domain";
import {
  FormInputContext,
  IpisButton,
  IpisTextArea,
  IpisTextInput,
  IpisTextInputCompact,
  IpisToggleInput,
  classNames,
} from "@ipis/client-essentials";
import { UseFormReturn, useForm } from "react-hook-form";

type Sections = {
  id: string;
  title: string;
  index: number;
  answers: IpisFormElementIsolatedAnswer.ClientSideType[];
};

interface Props {
  className?: string;
  goBack: () => void;
  currentFormVersion: IpisForm.ShellType | null;
  answers: IpisFormElementIsolatedAnswer.ClientSideType[];
}

const ChecklistAnswerSummary = (props: Props) => {
  const form = useForm({
    defaultValues: getDefaultValues(),
  });

  function getDefaultValues() {
    const entries = props.answers.map((answerElement) => {
      return [answerElement.id, answerElement.answer];
    });
    return Object.fromEntries(entries);
  }

  function buildSections(): Sections[] {
    const other: IpisFormElementIsolatedAnswer.ClientSideType[] = [];
    const sections: Sections[] = [];
    const answers = props.answers;

    answers.forEach((answer) => {
      const foundReference = props.currentFormVersion?.pages.some(
        (page, pageIndex) => {
          return page.elements.some((element) => {
            if (element.id === answer.formElementId) {
              const previousSection = sections.find(
                (section) => section.id === page.id
              );
              if (previousSection) {
                previousSection.answers.push(answer);
              } else {
                sections.push({
                  id: page.id,
                  title: page.pageTitle,
                  index: pageIndex,
                  answers: [answer],
                });
              }

              return true;
            }

            return false;
          });
        }
      );
      if (!foundReference) {
        other.push(answer);
      }
    });

    sections.sort((a, b) => a.index - b.index);
    if (other.length > 0) {
      sections.push({
        id: "other",
        title: "Övrigt",
        index: sections.length,
        answers: other,
      });
    }

    sections.forEach((section) => {
      section.answers.sort((a, b) => a.indexWithinPage - b.indexWithinPage);
    });

    return sections;
  }

  const sections = buildSections();

  return (
    <FormInputContext.Provider
      value={{
        omitDescription: true,
        omitError: true,
        omitTooltip: true,
        omitNote: true,
        omitSlideUpElement: true,
        readOnly: true,
      }}
    >
      <section
        className={classNames(
          "grid h-full w-full grid-rows-[auto,minmax(0,1fr),auto] gap-4 py-8",
          props.className
        )}
      >
        <header className="px-2">
          <h2 className="text-semi-faded">
            {props.currentFormVersion?.name ?? "Förberedelseformulär"}
          </h2>
        </header>
        <div className="relative flex h-full flex-col gap-8 overflow-auto px-2">
          {sections.map((section) => {
            return (
              <section key={section.id} className="flex flex-col gap-4">
                <header className=" sticky top-0 z-10 border-b border-border-color bg-white">
                  <h3 className="text-lg">{section.title}</h3>
                </header>
                <ul className="flex flex-col gap-8">
                  {section.answers.map((answer) => {
                    return (
                      <li key={answer.id}>
                        <AnswerInputRouter
                          key={answer.id}
                          form={form}
                          answer={answer}
                        />
                      </li>
                    );
                  })}
                </ul>
              </section>
            );
          })}
        </div>
        <footer className="flex flex-col">
          <IpisButton label="Gå tillbaka" onClick={props.goBack} />
        </footer>
      </section>
    </FormInputContext.Provider>
  );
};

const AnswerInputRouter = (props: {
  answer: IpisFormElementIsolatedAnswer.ClientSideType;
  form: UseFormReturn<any>;
}) => {
  const ans = props.answer;

  if (ans.type === "text") {
    return (
      <IpisTextInput
        id={ans.id}
        name={`${ans.id}.value`}
        required={false}
        label={ans.title}
        form={props.form}
      />
    );
  }

  if (ans.type === "textarea") {
    return (
      <IpisTextArea
        id={ans.id}
        name={`${ans.id}.value`}
        label={ans.title}
        required={false}
        rows={3}
        form={props.form}
      />
    );
  }

  if (ans.type === "number") {
    return (
      <IpisTextInput
        id={ans.id}
        name={`${ans.id}.value`}
        required={false}
        label={ans.title}
        type="number"
        form={props.form}
      />
    );
  }

  /* 
    ToDo: Show comment
  */
  if (ans.type === "image-group") {
    const images = Object.values(ans.answer.images).flat();
    return (
      <section className="flex w-full flex-col gap-4">
        {/* <header>
          <h3>Bilder</h3>
        </header> */}
        {ans.answer.imageUploadComment && (
          <IpisTextArea
            id={`${ans.id}.imageUploadComment`}
            name={`${ans.id}.imageUploadComment`}
            required={false}
            label={"Kommentar"}
            form={props.form}
            rows={3}
          />
        )}
        {images.length === 0 && (
          <p className="text-semi-faded">Inga bilder uppladdade</p>
        )}
        {images.length > 0 && (
          <ul className="grid w-full grid-cols-[repeat(auto-fill,minmax(150px,1fr))] gap-2">
            {images.map((image) => {
              if (image.state === "postupload") {
                return (
                  <li key={image.guid}>
                    <img
                      src={image.src}
                      alt={image.name}
                      className="h-40 w-full object-cover"
                    />
                  </li>
                );
              } else {
                return (
                  <li key={image.guid}>
                    <img
                      src={image.dataUrl}
                      alt={image.meta.name}
                      className="h-40 w-full object-cover"
                    />
                  </li>
                );
              }
            })}
          </ul>
        )}
      </section>
    );
  }

  if (ans.type === "yes/no") {
    return (
      <IpisToggleInput
        id={ans.id}
        name={`${ans.id}.value`}
        label={ans.title}
        required={false}
        form={props.form}
      />
    );
  }

  if (ans.type === "multiple-choice") {
    /* 
      Bit of a hack ngl
    */
    const value =
      ans.answer.value === FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION
        ? "Övrigt"
        : ans.answer.value;
    if (ans.answer.specification) {
      return (
        <fieldset className="flex flex-col gap-4">
          <IpisTextInput
            id={ans.id}
            name={`${ans.id}.value`}
            required={false}
            label={ans.title}
            controls={{
              value,
            }}
          />
          <IpisTextInputCompact
            id={ans.id}
            name={`${ans.id}.specification`}
            required={false}
            label={"Specifikation"}
            controls={{
              value: ans.answer.specification,
            }}
          />
        </fieldset>
      );
    }

    return (
      <IpisTextInput
        id={ans.id}
        name={`${ans.id}.value`}
        required={false}
        label={ans.title}
        controls={{
          value,
        }}
      />
    );
  }

  return <></>;
};

export default ChecklistAnswerSummary;
