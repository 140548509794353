import { IconBaseProps } from "react-icons/lib";
import { PiVanLight } from "react-icons/pi";
import ClientUtils from "../../utils/ClientUtils";

interface Props extends IconBaseProps {
  className?: string;
}

const VanIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <PiVanLight
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default VanIcon;
