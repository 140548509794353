import { useContext, useEffect } from "react";
import ProcessTask, { ProcessTaskArgs } from "../utils/process/ProcessTask";
import QueueContext from "../components/queue/QueueContext";
import Process from "../utils/process/Process";

type UseQueueProps = {
  id: string;
  name: string;
  onFinished?: () => void;
};

type UseQueueResult = {
  addTasks: (...taskArgs: ProcessTaskArgs<any>[]) => void;
} & (
  | {
      isInProgress: true;
      process: Process;
    }
  | {
      isInProgress: false;
      process: null | Process;
    }
);

const useQueue = (props: UseQueueProps): UseQueueResult => {
  const { queue, addToQueue } = useContext(QueueContext);

  const process = queue?.findProcessById(props.id);
  const isInProgress = process ? !process.isFinished() : false;

  const addTasks = (...taskArgs: ProcessTaskArgs<any>[]) => {
    const tasks = taskArgs.map((args) => new ProcessTask(args));
    const newProcess = new Process({ id: props.id, name: props.name, tasks });

    addToQueue(newProcess);
  };

  useEffect(() => {
    let unsubscribe: () => void;
    if (isInProgress && process && props.onFinished) {
      unsubscribe = process.onFinished(() => {
        props.onFinished?.();
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  });

  if (isInProgress && process) {
    return {
      addTasks,
      isInProgress,
      process,
    };
  } else {
    return {
      addTasks,
      isInProgress: false,
      process: null,
    };
  }
};

export default useQueue;
