interface Props {
  housingType:
    | "house"
    | "condomonium"
    | "rentalApartment"
    | "insuranceMatter"
    | undefined;
}

const RotAvdragImportantInformationSectionV2 = (props: Props) => {
  return (
    <section className="">
      <header className="rounded-t bg-input-background p-2 font-semibold sm:p-4">
        <h4 className="text-base">Viktig information angående rotavdraget</h4>
      </header>
      <main className="flex flex-col gap-4 rounded-b bg-input-background px-2 pb-2 sm:px-4 sm:pb-4">
        <ul className="flex list-disc flex-col gap-2 pl-6 text-sm sm:text-base">
          <li>Du ska äga fastigheten när arbetet utförs</li>
          <li>Du ska ha rotavdrag kvar att utnyttja</li>
          {props.housingType === "house" && (
            <li>Viktigt att det är rätt ifylld fastighetsbeteckning</li>
          )}
          {props.housingType === "condomonium" && (
            <li>
              Viktigt att det är rätt ifyllt organisations- och lägenhetsnummer
            </li>
          )}
        </ul>
      </main>
      <footer className="flex flex-col gap-4 pt-4">
        <p className="text-sm">
          Om skatteverket skulle neka rotavdraget har vi rätten att fakturera
          den delen till dig som kund. Så dubbelkolla att ni har fyllt i rätt
          uppgifter.
        </p>
        <p className="text-sm">
          Om vi behöver ändra uppgifter, skapa en ny faktura eller skicka nya
          uppgifter till skatteverket tar vi ut en{" "}
          <strong>administrativ avgift på 250:- inkl. moms.</strong>
        </p>
      </footer>
    </section>
  );
};

export default RotAvdragImportantInformationSectionV2;
