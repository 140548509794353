import React from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { useRepos } from "../../../hooks/use-repos";
import { TSignUpIdType } from "../../../_model/repos/interfaces/IAuthRepo";
import LoadingContent from "../../common/loaders/LoadingContent";
import PageHeader from "../../layout/PageHeader";
import SomethingWentWrong from "../../on-error/SomethingWentWrong";
import ClientUtils from "../../../utils/ClientUtils";

const withSignUpId =
  (type: TSignUpIdType, WrappedComponent: () => React.ReactElement) => () => {
    const params = useParams();
    const salesGuid = params["signUpId"] || "";
    const { authRepo } = useRepos();

    const res = useQuery(["signUpIdAuth", salesGuid, type], () =>
      authRepo.signUpIdAuthentication(salesGuid, type)
    );

    return (
      <LoadingContent loading={res.isLoading} renderContentSeparately>
        {ClientUtils.renderOne(res, [
          (res) =>
            res.isError && (
              <section className="mx-auto flex">
                <main>
                  <SomethingWentWrong
                    error={res.error}
                    description="Vi kan för tillfället inte säkerställa att du har rätt att se den här sidan. Vänligen försök igen senare!"
                  />
                </main>
              </section>
            ),
          (res) =>
            res.data === 404 && (
              <section className="flex w-full flex-col">
                <PageHeader heading="Ogiltig länk" />
                <main>
                  <SomethingWentWrong
                    error={res.error}
                    description="Denna länk är tyvärr inte giltig."
                  />
                </main>
              </section>
            ),
          (res) => res.data === 200 && <WrappedComponent />,
        ])}
      </LoadingContent>
    );
  };

export default withSignUpId;
