import { PaymentOption } from "@eljouren/domain";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { useContext } from "react";
import MidDot from "../../../../components/common/MidDot";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../../hooks/use-media-query";
import ClientUtils from "../../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext from "../CustomerWorkOrderInvoiceContext";
import PaymentMethodLogoAndTerms from "./PaymentMethodLogo";
import PaymentMethodUtils from "./PaymentMethodUtils";

interface Props {
  id: string;
  inputProps: HTMLMotionProps<"input">;
  isSelected: boolean;
  method: PaymentOption.Type;
  select: () => void;
  AdditionalInput?: () => React.ReactNode;
}

const InvoicePaymentOptionUnboundFormElement = (props: Props) => {
  const { heading, subheading } = PaymentMethodUtils.getHeadings(props.method);
  const ctx = useContext(CustomerWorkOrderInvoiceContext);
  const isXs = useMediaQuery(TailwindBreakpoint.xs);
  const isSm = useMediaQuery(TailwindBreakpoint.sm);
  const priceToPay = ctx.paymentSummary?.priceToPay;

  return (
    <>
      <motion.fieldset
        className={ClientUtils.classNames(
          "grid grid-cols-1 grid-rows-4 rounded border sm:grid-cols-[minmax(0,1fr),auto]",
          props.isSelected && "border-main-bg-dark bg-slate-100/50"
        )}
      >
        <motion.p
          className={ClientUtils.classNames(
            "row-span-full grid grid-cols-1 items-center gap-4 pl-4 sm:grid-cols-[auto,minmax(0,1fr)]"
            /* props.isSelected && "bg-slate-200" */
          )}
        >
          <motion.input
            {...props.inputProps}
            className="sr-only cursor-pointer accent-black sm:not-sr-only sm:h-6 sm:w-6"
            type="radio"
            id={props.id}
          />
          <motion.label
            htmlFor={props.id}
            className={ClientUtils.classNames(
              "flex grow cursor-pointer flex-col py-4 pr-2 sm:pr-4",
              props.isSelected && "border-green-700"
            )}
          >
            <motion.span className="flex flex-col font-bold sm:flex-row sm:items-center">
              <motion.span className="text-sm sm:text-base">
                {heading}
              </motion.span>
              {!!props.method.linkToTerms && (
                <>
                  <MidDot className="hidden sm:flex" />
                  <motion.a
                    target="_blank"
                    rel="noreferrer"
                    className="mr-auto text-xs text-blue-600 hover:underline"
                    href={props.method.linkToTerms}
                  >
                    Visa villkor
                  </motion.a>
                </>
              )}
            </motion.span>
            {!!subheading && (
              <motion.span className="pt-2 text-sm font-semibold text-dark-gray text-opacity-70 sm:pt-0">
                <motion.span>{subheading}</motion.span>
              </motion.span>
            )}
          </motion.label>
        </motion.p>

        {isSm && (
          <PaymentMethodLogoAndTerms
            method={props.method}
            className="col-start-2 row-span-2 row-start-2 flex items-center justify-center pr-4"
          />
        )}
        {!isSm && (
          <motion.div className="col-span-2 flex justify-between px-2 py-2 xs:px-4">
            <PaymentMethodLogoAndTerms
              method={props.method}
              className="hidden items-center justify-center pr-4 xs:flex"
            />
            <AppButton
              key={`select-${props.id}`}
              className="ml-auto h-12 max-w-full"
              type={props.isSelected ? "submit" : "button"}
              onClick={(e) => {
                if (!props.isSelected) {
                  e.preventDefault();
                  props.select();
                }
              }}
              motionProps={{
                animate: {
                  width: !isXs ? "100%" : props.isSelected ? "300px" : "200px",
                  backgroundColor: props.isSelected ? "#324b4f" : "#324b4fcc",
                },
                transition: {
                  // Remove bouncy effect
                  type: "tween",
                },
              }}
            >
              <AnimatePresence exitBeforeEnter>
                {!props.isSelected && (
                  <motion.span
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    //exit={{ opacity: 0 }}
                    key={`non-selected-text-${props.id}`}
                  >
                    Välj alternativ
                  </motion.span>
                )}
                {props.isSelected && (
                  <motion.span
                    className="flex items-center gap-2 whitespace-nowrap"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    //exit={{ opacity: 0 }}
                    key={`selected-text-${props.id}`}
                  >
                    <motion.span>Genomför köp</motion.span>
                    {!!priceToPay && (
                      <motion.span>({priceToPay}:-)</motion.span>
                    )}
                  </motion.span>
                )}
              </AnimatePresence>
            </AppButton>
          </motion.div>
        )}
        {props.AdditionalInput && (
          <div className="col-span-2 px-4 py-2">{props.AdditionalInput()}</div>
        )}
      </motion.fieldset>
    </>
  );
};

export default InvoicePaymentOptionUnboundFormElement;
