import {
  AiOutlineFileImage,
  AiOutlineFilePdf,
  AiOutlineFileUnknown,
} from "react-icons/ai";

interface Props {
  className?: string;
  size: number;
  mimeType: string | undefined | null;
}

const IpisFileIcon = (props: Props) => {
  const mimeType = props.mimeType;
  if (mimeType?.includes("image")) {
    return <AiOutlineFileImage className={props.className} size={props.size} />;
  }

  if (mimeType?.includes("pdf")) {
    return <AiOutlineFilePdf className={props.className} size={props.size} />;
  }

  return <AiOutlineFileUnknown className={props.className} size={props.size} />;
};

export default IpisFileIcon;
