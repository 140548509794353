"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileServerUploadSingleFileResponse = void 0;
var CollectionType_1 = require("../../shared-schemas/CollectionType");
var UploaderSource_1 = require("../../shared-schemas/UploaderSource");
var zod_1 = require("zod");
var FileServerUploadSingleFileResponse;
(function (FileServerUploadSingleFileResponse) {
    FileServerUploadSingleFileResponse.Schema = zod_1.z.object({
        originalName: zod_1.z.string().optional(),
        originalExt: zod_1.z.string().optional(),
        name: zod_1.z.string(),
        ext: zod_1.z.string(),
        src: zod_1.z.string(),
        guid: zod_1.z.string(),
        mimeType: zod_1.z.string(),
        uploadedAs: UploaderSource_1.UploaderSource.Schema,
        collectionType: CollectionType_1.CollectionType.Schema,
        recordId: zod_1.z.string(),
        createdDate: zod_1.z.string(),
    });
})(FileServerUploadSingleFileResponse || (FileServerUploadSingleFileResponse = {}));
exports.FileServerUploadSingleFileResponse = FileServerUploadSingleFileResponse;
