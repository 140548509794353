import { ErrorLog } from "@eljouren/domain/build/";
import trpcClient from "../../trpc-setup";
import IErrorLogRepo from "./interfaces/IErrorLogRepo";
import { EnumDictionary } from "@eljouren/utils";

export default class ErrorLogRepo implements IErrorLogRepo {
  async getMultiple(args: {
    page: number;
    perPage: number;
    previousTotalCount: number | "firstCall";
    logLevels: EnumDictionary<ErrorLog.Type["logLevel"], boolean>;
  }): Promise<{
    totalCount: number;
    errorLogs: ErrorLog.Type[];
  }> {
    const res = await trpcClient.errorLog.multiple.query(args);
    return res;
  }
  async getSingle(args: { id: string }): Promise<ErrorLog.Type> {
    const res = await trpcClient.errorLog.single.query(args);
    return res;
  }
  async search(args: {
    query: string;
    limit: number;
  }): Promise<ErrorLog.Type[]> {
    const res = await trpcClient.errorLog.search.query(args);
    return res;
  }
}
