import ClientUtils from "./../../../../utils/ClientUtils";
import { WorkOrderConfirmation } from "@eljouren/domain/build";
import { zodResolver } from "@hookform/resolvers/zod";
import { useContext, useEffect } from "react";
import { FieldErrors, useForm } from "react-hook-form";
import { useBundledState } from "../../../../hooks/hooks";

import CustomerWorkOrderContext from "../../CustomerWorkOrderContext";
import CustomerFormFooter from "../components/CustomerFormFooter";
import CustomerFormMainSection from "../components/CustomerFormMainSection";
import HousingFormSection from "./HousingFormSection";
import InsuranceMatterFormSection from "./InsuranceMatterFormSection";
import InvoiceInformationFormSection from "./InvoiceInformationFormSection";
import PaymentAndHousingFormContext from "./PaymentAndHousingFormContext";

interface Props {
  className?: string;
  defaultValues?: WorkOrderConfirmation.PaymentInfoType;
  onSubmit(values: WorkOrderConfirmation.PaymentInfoType): void;
  onGoBack(): void;
  defaultInvoiceMail?: string;
}

const CustomerWorkOrderPaymentAndHousingFormStep = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const form = useForm<WorkOrderConfirmation.PaymentInfoType>({
    defaultValues: getDefaultValues(),
    resolver: zodResolver(
      WorkOrderConfirmation.PaymentInfoSchema({
        paymentFlowAllowed:
          ctx.workOrder.paymentDetails.externalPaymentFlowAllowed,
      })
    ),
  });

  function getDefaultValues(): Partial<WorkOrderConfirmation.PaymentInfoType> {
    /*  if (process.env.NODE_ENV === "development") {
      return undefined;
    } */
    const values: Partial<WorkOrderConfirmation.PaymentInfoType> =
      props.defaultValues
        ? {
            ...props.defaultValues,
            isBusinessCustomer: ctx.workOrder.isBusinessCustomer,
            paymentFlowAllowed:
              ctx.workOrder.paymentDetails.externalPaymentFlowAllowed,
          }
        : {
            invoice: {
              email:
                props.defaultInvoiceMail ??
                ctx.workOrder.customerDetails?.assumedInvoiceEmail ??
                undefined,
              invoiceMethod: "email",
            },
            housing: {
              housingType: "house",
            },
            isBusinessCustomer: ctx.workOrder.isBusinessCustomer,
            customerHasActivelyChosenToSkipDiscount: false,
            paymentFlowAllowed:
              ctx.workOrder.paymentDetails.externalPaymentFlowAllowed,
          };

    return values;
  }

  const showErrorsBundle = useBundledState<
    false | FieldErrors<WorkOrderConfirmation.PaymentInfoType>
  >(false);

  useEffect(() => {
    form.watch();
  }, [form]);

  function onSubmit(values: WorkOrderConfirmation.PaymentInfoType) {
    props.onSubmit(values);
  }

  function onError(errors: FieldErrors<WorkOrderConfirmation.PaymentInfoType>) {
    console.log(errors);
    showErrorsBundle.set(errors);
    window.modal.toast({
      id: "invoiceInformationErrorToast",
      title: "Information saknas",
      prompt:
        "Vänligen fyll i både faktureringsinformation och information för rotavdrag",
      toastType: "error",
    });
  }

  return (
    <PaymentAndHousingFormContext.Provider
      value={{
        form,
        showErrors: showErrorsBundle.value,
      }}
    >
      <CustomerFormMainSection
        className={ClientUtils.twClassNames(
          "flex h-full flex-col",
          props.className
        )}
      >
        <form
          className="flex flex-col gap-12 lg:p-8"
          onSubmit={form.handleSubmit(onSubmit, onError)}
        >
          <InvoiceInformationFormSection />
          <InsuranceMatterFormSection />
          <HousingFormSection />
          <CustomerFormFooter state="middle" onGoBack={props.onGoBack} />
        </form>
      </CustomerFormMainSection>
    </PaymentAndHousingFormContext.Provider>
  );
};

export default CustomerWorkOrderPaymentAndHousingFormStep;
