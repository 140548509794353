import ClientUtils from "./../../utils/ClientUtils";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorUtil from "../../utils/errors/ErrorUtil";

import Logo from "../common/brand/Logo";
import AppTextButton from "../common/buttons/AppTextButton";
import AppRefreshIcon from "../icons/AppRefreshIcon";

interface Props {
  className?: string;
  description?: string;
  error: any;
  tryAgainLabel?: string;
  onTryAgain?(): void;
}

const SomethingWentWrong = (props: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test"
    ) {
      console.error(props.error);
    }
  }, [props.error]);

  function onTryAgain() {
    if (props.onTryAgain) {
      props.onTryAgain();
    } else {
      navigate(0);
    }
  }
  const errorProps = ErrorUtil.getErrorProps(props.error);

  let description = props.description || "Okänt fel.";
  if (errorProps) {
    description += ` Vänligen uppge ovan felkod om du kontaktar support.`;
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        "mx-auto flex max-w-md flex-col gap-4 p-4",
        props.className
      )}
    >
      <header className="flex flex-col gap-2">
        <Logo wrapperClassName="mx-auto w-40" withColor />
        <h2 className="pt-2 text-center text-2xl">Något gick tyvärr fel</h2>
        {errorProps && (
          <h3 className="text-center text-base font-semibold">
            {errorProps.publicIdShorthand}
          </h3>
        )}
      </header>
      <main className="mx-auto flex min-w-[250px] flex-col">
        <p className="text-lg">{description}</p>
      </main>
      <footer className="flex justify-end p-2">
        <AppTextButton
          onClick={onTryAgain}
          className="flex items-center gap-2 py-2"
        >
          <AppRefreshIcon size={35} />
          {props.tryAgainLabel || "Ladda om"}
        </AppTextButton>
      </footer>
    </section>
  );
};

export default SomethingWentWrong;
