import WorkOrderFileUploadForm from "../../../../components/work-order/files/WorkOrderFileUploadForm";
import ClientUtils from "./../../../../utils/ClientUtils";
import { CustomFileInputFile } from "../../../../components/files/FileInputButton";
import CustomerFormMainSection from "../components/CustomerFormMainSection";

interface Props {
  className?: string;
  onSubmit(images: CustomFileInputFile[]): void;
  onGoBack(): void;
  defaultValues?: CustomFileInputFile[];
}

const CustomerWorkOrderFilesFormStep = (props: Props) => {
  return (
    <CustomerFormMainSection
      className={ClientUtils.twClassNames("", props.className)}
    >
      <main className="lg:p-8">
        <WorkOrderFileUploadForm
          uploadLabel="Gå till nästa steg"
          arrowRight
          prompt="Ju mer information våra hantverkare får av dig som kund desto bättre kvalitet på utfört arbete. Så ladda hellre upp för många bilder/filer än för få! "
          defaultValues={props.defaultValues}
          onSubmit={props.onSubmit}
          onGoBack={props.onGoBack}
        />
      </main>
    </CustomerFormMainSection>
  );
};

export default CustomerWorkOrderFilesFormStep;
