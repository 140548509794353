import { useContext } from "react";
import { AppButton } from "../../../components/common/buttons/AppButton";
import ClientUtils from "./../../../utils/ClientUtils";

import AppDoorIcon from "../../../components/icons/AppDoorIcon";
import HandymanWorkOrderCheckInOutContext from "./contexts/HandymanWorkOrderCheckInOutContext";
import HandymanWorkOrderRouteContext from "./contexts/HandymanWorkOrderRouteContext";

interface Props {
  className?: string;
}

const ConditionalCheckInOutButton = (props: Props) => {
  const routeCtx = useContext(HandymanWorkOrderRouteContext);
  const checkInOutCtx = useContext(HandymanWorkOrderCheckInOutContext);

  if (!checkInOutCtx.canCheckInOut) {
    return <></>;
  }

  return (
    <AppButton
      requiresNetworkConnection={true}
      loading={routeCtx.orderRes.isLoading}
      contentClassName={ClientUtils.twClassNames(
        "mt-auto flex w-full items-center justify-center gap-2 text-sm",
        checkInOutCtx.isCheckingOut && "bg-orange-700 hover:bg-orange-800"
      )}
      type="button"
      disabled={
        routeCtx.orderRes.isLoading || routeCtx.orderRes.query.isFetching
      }
      onClick={checkInOutCtx.onCheckInOut}
    >
      <span>
        {checkInOutCtx.isCheckingIn
          ? checkInOutCtx.isCheckingInAnew
            ? "Checka in på nytt"
            : "Checka in"
          : "Checka ut"}
      </span>
      <span>
        <AppDoorIcon
          direction={checkInOutCtx.isCheckingIn ? "enter" : "exit"}
          size={24}
        />
      </span>
    </AppButton>
  );
};

export default ConditionalCheckInOutButton;
