"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderPaymentSyncResult = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderPaymentStatus_1 = require("./WorkOrderPaymentStatus");
var WorkOrderPaymentSyncResult;
(function (WorkOrderPaymentSyncResult) {
    WorkOrderPaymentSyncResult.ExpectedStatusSchema = centralized_zod_1.z.union([
        WorkOrderPaymentStatus_1.WorkOrderPaymentStatus.StatusEnumSchema,
        centralized_zod_1.z.literal("ANY"),
    ]);
    WorkOrderPaymentSyncResult.Schema = centralized_zod_1.z.object({
        workOrderId: centralized_zod_1.z.string(),
        expectedStatus: WorkOrderPaymentSyncResult.ExpectedStatusSchema,
        externalStatus: centralized_zod_1.z.union([
            centralized_zod_1.z.object({
                hasValidSession: centralized_zod_1.z.literal(false),
            }),
            centralized_zod_1.z.object({
                hasValidSession: centralized_zod_1.z.literal(true),
                status: WorkOrderPaymentStatus_1.WorkOrderPaymentStatus.StatusEnumSchema,
            }),
        ]),
        /* lastChangeWithinFiveMinutes: z.boolean(),
        millisecondsSinceLastChange: z.number(), */
        savedStatus: WorkOrderPaymentStatus_1.WorkOrderPaymentStatus.ClientSafeSchema,
        statusAsExpected: centralized_zod_1.z.boolean(),
        isSynchronized: centralized_zod_1.z.boolean(),
        allSuccessful: centralized_zod_1.z.boolean(),
    });
})(WorkOrderPaymentSyncResult || (WorkOrderPaymentSyncResult = {}));
exports.WorkOrderPaymentSyncResult = WorkOrderPaymentSyncResult;
