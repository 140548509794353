import { ErrorLog, ErrorLogLevel, ErrorLogSignInInfo } from "@eljouren/domain";
import { EnumDictionary, UUID, Utils } from "@eljouren/utils";
import { useEffect, useRef, useState } from "react";
import Paginator from "../../_model/helpers/Paginator";
import withAdminPermissions from "../../components/auth/hocs/withAdminPermissions";
import PageSection from "../../components/common/PageSection";
import { AppLoader } from "../../components/common/loaders/AppLoader";
import PaginationControls from "../../components/common/pagination/PaginationControls";
import SearchFieldSlideDown from "../../components/common/search/SearchFieldSlideDown";
import ErrorLogListItem from "../../components/error-logs/ErrorLogListItem";
import AppPage from "../../components/layout/AppPage";
import useQueryWrapper from "../../hooks/use-query-wrapper";
import { useRepos } from "../../hooks/use-repos";
import ClientUtils from "./../../utils/ClientUtils";
import { useLsJson } from "../../hooks/local-storage-hooks";
import isEqual from "lodash.isequal";
import ErrorLogGroupListItem from "../../components/error-logs/ErrorLogGroupListItem";

export type ErrorLogUserGroup = {
  id: string;
  groupedBy: "user";
  signInInfo: ErrorLogSignInInfo.Type | null;
  errorLogTypeGroups: ErrorLogTypeGroup[];
};

export type ErrorLogTypeGroup = {
  id: string;
  groupedBy: "type";
  commonLogLevel: ErrorLogLevel.Type;
  commonTitle: string;
  commonOrigin: string;
  errorLogs: ErrorLog.Type[];
};

function groupErrorLogs(errorLogs: ErrorLog.Type[]): ErrorLogUserGroup[] {
  const groups: ErrorLogUserGroup[] = [];

  errorLogs.forEach((log) => {
    if (groups.length === 0) {
      groups.push({
        id: UUID.generate().value,
        groupedBy: "user",
        signInInfo: log.signInInfo,
        errorLogTypeGroups: [
          {
            id: UUID.generate().value,
            commonLogLevel: log.logLevel,
            commonOrigin: log.origin,
            groupedBy: "type",
            commonTitle: log.title,
            errorLogs: [log],
          },
        ],
      });
    } else {
      const lastElement = groups.at(-1)!;
      const shouldAppendLog = isEqual(lastElement.signInInfo, log.signInInfo);
      if (shouldAppendLog) {
        const lastTypeGroup = lastElement.errorLogTypeGroups.at(-1)!;
        const shouldAppendToTypeGroup =
          lastTypeGroup.commonTitle === log.title &&
          lastTypeGroup.commonOrigin === log.origin &&
          lastTypeGroup.commonLogLevel === log.logLevel;
        if (shouldAppendToTypeGroup) {
          lastTypeGroup.errorLogs.push(log);
        } else {
          lastElement.errorLogTypeGroups.push({
            id: UUID.generate().value,
            commonLogLevel: log.logLevel,
            commonOrigin: log.origin,
            groupedBy: "type",
            commonTitle: log.title,
            errorLogs: [log],
          });
        }
      } else {
        groups.push({
          id: UUID.generate().value,
          groupedBy: "user",
          signInInfo: log.signInInfo,
          errorLogTypeGroups: [
            {
              id: UUID.generate().value,
              commonLogLevel: log.logLevel,
              commonOrigin: log.origin,
              groupedBy: "type",
              commonTitle: log.title,
              errorLogs: [log],
            },
          ],
        });
      }
    }
  });

  return groups;
}

const ErrorLogsRoute = () => {
  const { errorLogRepo } = useRepos();
  const perPage = 50;
  const [page, setPage] = useState(0);
  const previousTotalCount = useRef<number | null>(null);
  const { value: selectedLogLevels, set: _setSelectedLogLevels } = useLsJson<
    EnumDictionary<ErrorLogLevel.Type, boolean>
  >("selectedLogLevels", {
    error: true,
    warn: true,
    info: true,
    debug: true,
  });
  const res = useQueryWrapper({
    queryKey: ["errorsLogs", page, JSON.stringify(selectedLogLevels)],
    queryFn: () =>
      errorLogRepo.getMultiple({
        perPage,
        page,
        previousTotalCount: previousTotalCount.current ?? "firstCall",
        logLevels: selectedLogLevels,
      }),
  });

  useEffect(() => {
    if (res.data) {
      previousTotalCount.current = res.data.totalCount;
    }
  }, [res.data]);

  const paginator = new Paginator({
    pageIndex: page,
    totalCountOfElements:
      res.data?.totalCount ?? previousTotalCount.current ?? 0,
    data: res.data?.errorLogs ?? [],
    elementsPerPage: perPage,
  });

  function setSelectedLogLevels(
    newLevels: EnumDictionary<ErrorLogLevel.Type, boolean>
  ) {
    previousTotalCount.current = null;
    setPage(0);
    _setSelectedLogLevels(newLevels);
  }

  const groups = groupErrorLogs(res.data?.errorLogs ?? []);

  return (
    <AppPage
      className={ClientUtils.twClassNames(
        "mx-auto flex max-w-screen-xl flex-col gap-4 p-4"
      )}
      headerProps={{ heading: "Errorlogg" }}
    >
      <PageSection as="section">
        <aside>
          <SearchFieldSlideDown
            id="errorLogSearch"
            fetch={async (searchTerm: string) => {
              return errorLogRepo.search({ query: searchTerm, limit: 5 });
            }}
            placeholder="Sök efter errorloggar"
            Render={(props) => {
              if (!props.data.length) {
                return <p className="p-2">Inga errorloggar hittades</p>;
              }
              return (
                <ul className="mx-auto flex w-full max-w-screen-xl flex-col gap-2">
                  {props.data.map((log) => {
                    return <ErrorLogListItem key={log.id} errorLog={log} />;
                  })}
                </ul>
              );
            }}
          />
        </aside>
        {paginator.needsPagination && (
          <header className="flex justify-end p-4">
            <PaginationControls
              className="ml-auto"
              canGoBack={paginator.canGoBack}
              canGoForward={paginator.canGoForward}
              pageIndex={paginator.pageIndex}
              maxPageIndex={paginator.limits.maxPageIndex}
              onGoBack={() => setPage(page - 1)}
              onGoForward={() => setPage(page + 1)}
            />
          </header>
        )}
        <div className="grid grid-cols-[auto,minmax(0,1fr)] p-2">
          {res.isLoading && <AppLoader />}
          {res.isError && (
            <p className="col-span-2 p-2 text-red-600">
              Det gick inte att hämta loggarna just nu
            </p>
          )}
          {res.data && (
            <>
              <Sidebar
                selectedLogLevels={selectedLogLevels}
                setSelectedLogLevels={setSelectedLogLevels}
              />
              <ul className="mx-auto flex w-full max-w-screen-xl flex-col gap-2 p-2">
                {groups.map((group) => {
                  const key =
                    group.errorLogTypeGroups
                      .flatMap((group) => group.errorLogs)
                      .join("-") + "-group";
                  return <ErrorLogGroupListItem key={key} group={group} />;
                })}
              </ul>
            </>
          )}
        </div>
      </PageSection>
    </AppPage>
  );
};

const Sidebar = (props: {
  selectedLogLevels: EnumDictionary<ErrorLogLevel.Type, boolean>;
  setSelectedLogLevels: (
    newLevels: EnumDictionary<ErrorLogLevel.Type, boolean>
  ) => void;
}) => {
  return (
    <ul className="flex flex-col gap-2 border">
      {ErrorLogLevel.Schema.options.map((level) => {
        const isSelected = props.selectedLogLevels[level];
        return (
          <li
            className={ClientUtils.classNames(
              "cursor-pointer border-y px-4 py-2",
              isSelected && "bg-gray-200"
            )}
            key={level + "-filter"}
            onClick={() => {
              props.setSelectedLogLevels({
                ...props.selectedLogLevels,
                [level]: !props.selectedLogLevels[level],
              });
            }}
          >
            {Utils.capitalize(level)}
          </li>
        );
      })}
    </ul>
  );
};

export default withAdminPermissions(ErrorLogsRoute);
