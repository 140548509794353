import { Handyman } from "@eljouren/domain/build";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useQuery } from "react-query";
import { useLocation, useSearchParams } from "react-router-dom";
import { TWorkerSignInData } from "../../../_model/schemas-and-types/repo-schemas";
import withHandymanCredentials from "../../../components/auth/hocs/withWorkerCredentials";
import SomethingWentWrong from "../../../components/on-error/SomethingWentWrong";
import { useRepos } from "../../../hooks/use-repos";
import HandymanLandingPage from "./HandymanLandingPage";

export const HandymanLandingPageContext = React.createContext<{
  handyman: Handyman.Type;
  signedInHandyman: Handyman.Type;
  isViewingOwnProfile: boolean;
  isLoading: boolean;
}>({} as never);
interface Props extends TWorkerSignInData {}

const HandymanRoute = (props: Props) => {
  const [params] = useSearchParams();
  const handymanId: string | null = params.get("id");

  const { state: unknownState } = useLocation();
  const state = (unknownState as any) || {};
  const { handymanRepo } = useRepos();

  const res = useQuery(
    ["handyman", handymanId],
    async () => {
      if (handymanId) {
        return handymanRepo.fetchHandyman(handymanId);
      } else {
        return undefined;
      }
    },
    { initialData: state.handyman }
  );

  const signedInHandyman = props.handyman;
  const handyman = res.data ?? signedInHandyman;

  return (
    <ErrorBoundary
      fallbackRender={(props) => <SomethingWentWrong error={props.error} />}
    >
      <HandymanLandingPageContext.Provider
        value={{
          signedInHandyman,
          handyman,
          isViewingOwnProfile: handyman.id === signedInHandyman.id,
          isLoading: res.isLoading,
        }}
      >
        <HandymanLandingPage />
      </HandymanLandingPageContext.Provider>
    </ErrorBoundary>
  );
};

export default withHandymanCredentials(HandymanRoute);
