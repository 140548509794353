"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdCollectParams = void 0;
var zod_1 = require("zod");
var BankIdCollectParams;
(function (BankIdCollectParams) {
    BankIdCollectParams.Schema = zod_1.z.object({
        orderRef: zod_1.z.string(),
    });
})(BankIdCollectParams || (BankIdCollectParams = {}));
exports.BankIdCollectParams = BankIdCollectParams;
