import { Route, Routes } from "react-router-dom";
import App from "./App";
import MaintenancePage from "./MaintenancePage";
import FormBuilder from "./components/checklist/v2/builder/FormBuilder";
import AppRoutes from "./routes";
import ErrorLogsRoute from "./routes/admin/ErrorLogsRoute";
import CustomerWorkOrderRoute from "./routes/customer/CustomerWorkOrderRoute";
import CustomerMaterialReviewListRoute from "./routes/customer/material-review-list/CustomerMaterialReviewListRoute";
import CustomerWorkOrderPaymentRoute from "./routes/customer/work-order-payment/CustomerWorkOrderPaymentRoute";
import CustomerWorkOrderRouteV2 from "./routes/customer/work-order-route-v2/CustomerWorkOrderRouteV2";
import NotFoundRoute from "./routes/not-found/NotFoundRoute";
import ResetPasswordRoute from "./routes/reset/ResetPasswordRoute";
import SalesTeamBookingSuggestionsRoute from "./routes/sales/adress-search/SalesTeamBookingSuggestionsRoute";
import SalesTeamWorkOrderRoute from "./routes/sales/order/SalesTeamWorkOrderRoute";
import SalesTeamPartnerListAssociationRoute from "./routes/sales/partner-list/SalesTeamPartnerListAssociationRoute";
import SalesTeamPartnerListRoute from "./routes/sales/partner-list/SalesTeamPartnerListRoute";
import SalesTeamPartnerListSingleRoute from "./routes/sales/partner-list/SalesTeamPartnerListSingleRoute";
import SignUpRoute from "./routes/sign-up/SignUpRoute";
import StaffFormBuilderRoute from "./routes/staff/StaffFormBuilderRoute";
import StaffRoot from "./routes/staff/StaffRoot";
import HandymanCompanyRoute from "./routes/worker/company/HandymanCompanyRoute";
import HandymanRoute from "./routes/worker/landing/HandymanRoute";
import HandymanWorkOrderRoute from "./routes/worker/order/HandymanWorkOrderRoute";
import HandymanProfileRoute from "./routes/worker/profile/HandymanProfileRoute";
import CustomerOrderLineReviewRouteV2 from "./routes/customer/order-line-review-route-v2/CustomerOrderLineReviewRouteV2";
import CustomerWorkOrderPreparationsRouteV2 from "./routes/customer/preparations-route/CustomerWorkOrderPreparationsRouteV2";

interface Props {
  className?: string;
}

const AppRoutesComponent = (props: Props) => {
  /*  if (process.env.NODE_ENV === "development") {
    return (
      <App>
        <Test />
      </App>
    );
  } */

  const useMainteanancePage = false;

  if (useMainteanancePage) {
    return <MaintenancePage />;
  }

  return (
    <Routes>
      <Route
        path="*"
        element={
          <App>
            <NotFoundRoute />
          </App>
        }
      />

      <Route path={AppRoutes.root()} element={<App />}>
        <Route path={AppRoutes.staff.root()} element={<StaffRoot />} />
        <Route
          path={AppRoutes.staff.formBuilder()}
          element={<StaffFormBuilderRoute />}
        />
        <Route path={AppRoutes.signUp.root()} element={<SignUpRoute />} />
        <Route
          path={AppRoutes.resetPassword.root()}
          element={<ResetPasswordRoute />}
        />
        <Route
          path={AppRoutes.customer.root()}
          element={<CustomerWorkOrderRoute />}
        />
        <Route
          path={AppRoutes.customer.v2.confirmationFlow()}
          element={<CustomerWorkOrderRouteV2 />}
        />
        <Route
          path={AppRoutes.externalCustomer.orderLineReviewList()}
          element={<CustomerMaterialReviewListRoute />}
        />
        <Route
          path={AppRoutes.externalCustomer.v2.orderLineReview()}
          element={<CustomerOrderLineReviewRouteV2 />}
        />
        <Route
          path={AppRoutes.externalCustomer.v2.brandedOrderLineReview()}
          element={<CustomerOrderLineReviewRouteV2 />}
        />
        <Route
          path={AppRoutes.customer.v2.preparationsFlow()}
          element={<CustomerWorkOrderPreparationsRouteV2 />}
        />
        <Route
          path={AppRoutes.customer.v2.brandedPreparationsFlow()}
          element={<CustomerWorkOrderPreparationsRouteV2 />}
        />
        <Route
          path={AppRoutes.customer.workOrderPayment()}
          element={<CustomerWorkOrderPaymentRoute />}
        />
        <Route path={AppRoutes.partner.root()} element={<HandymanRoute />} />

        <Route
          path={AppRoutes.partner.profile()}
          element={<HandymanProfileRoute />}
        />
        <Route
          path={AppRoutes.partner.customerOrder()}
          element={<HandymanWorkOrderRoute />}
        />
        <Route
          path={AppRoutes.partner.company()}
          element={<HandymanCompanyRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.customerOrder()}
          element={<SalesTeamWorkOrderRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.partnerList()}
          element={<SalesTeamPartnerListRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.partnerListWithAssociation()}
          element={<SalesTeamPartnerListAssociationRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.singlePartner()}
          element={<SalesTeamPartnerListSingleRoute />}
        />
        <Route
          path={AppRoutes.salesTeam.bookingSuggestions()}
          element={<SalesTeamBookingSuggestionsRoute />}
        />
        <Route
          path={AppRoutes.admin.errorLogs()}
          element={<ErrorLogsRoute />}
        />
        <Route
          path={AppRoutes.test.checklistBuilder()}
          element={<FormBuilder usePage />}
        />
        <Route path={AppRoutes.test.navigation()} element={<FormBuilder />} />

        <Route index element={<NotFoundRoute />} />
      </Route>
    </Routes>
  );
};

export default AppRoutesComponent;
