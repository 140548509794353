"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisPagination = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var client_utils_1 = require("../../utils/client-utils");
var general_hooks_1 = require("../../hooks/general-hooks");
var variants = {
    enter: function (options) {
        var x;
        if (options.reducedMotion) {
            x = 0;
        }
        else {
            x = options.forward ? 1000 : -1000;
        }
        return {
            x: x,
            opacity: 0,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
    },
    exit: function (options) {
        var x;
        if (options.reducedMotion) {
            x = 0;
        }
        else {
            x = !options.forward ? 1000 : -1000;
        }
        return {
            zIndex: 0,
            x: x,
            opacity: 0,
        };
    },
};
var IpisPagination = function (props) {
    var _a, _b;
    var ref = (0, react_1.useRef)(null);
    var previousIndex = (0, general_hooks_1.usePrevious)(props.pageIndex);
    var reducedMotion = (0, framer_motion_1.useReducedMotion)();
    function custom() {
        return {
            forward: props.pageIndex >= (previousIndex || 0),
            reducedMotion: !!reducedMotion,
        };
    }
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.AnimatePresence, { initial: false, custom: custom(), children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { ref: ref, className: (0, client_utils_1.classNames)("col-start-1 col-end-1 h-full", props.className), style: {
                gridRowStart: (_a = props.gridRow) !== null && _a !== void 0 ? _a : 1,
                gridRowEnd: (_b = props.gridRow) !== null && _b !== void 0 ? _b : 1,
            }, custom: custom(), variants: variants, initial: "enter", animate: "center", exit: "exit", transition: {
                x: { type: "spring", stiffness: 220, damping: 30, duration: 0.35 },
                opacity: { duration: 0.2 },
            }, children: props.children }, props.pageIndex) }));
};
exports.IpisPagination = IpisPagination;
