"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FauxFieldsetWrapper = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var tailwind_hooks_1 = require("../../../../hooks/tailwind-hooks");
var client_utils_1 = require("../../../../utils/client-utils");
var FauxFieldsetWrapper = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var className = props.className, children = props.children;
    var colors = (0, tailwind_hooks_1.useTailwindColors)();
    var borderWidth;
    var borderColor;
    var treatAsHovered = props.isHovered && !props.readOnly;
    if (props.isFocused) {
        borderWidth = 1.2;
        borderColor = props.onDarkBackground
            ? colors["border-color"] + "bb"
            : colors.dark[950];
    }
    else if (treatAsHovered) {
        borderWidth = 0.75;
        borderColor = props.onDarkBackground
            ? colors["border-color"] + "bb"
            : colors.dark[900];
    }
    else {
        borderWidth = 0.75;
        borderColor = colors["border-color"];
    }
    var inputBackground = (_a = props.inputBackground) !== null && _a !== void 0 ? _a : "transparent";
    var _h = (_b = props.motionProps) !== null && _b !== void 0 ? _b : {}, style = _h.style, restMotionProps = __rest(_h, ["style"]);
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div
    /*
      I don't understand why the mb-auto is needed for it to not grow in height
     */
    , __assign({ 
        /*
          I don't understand why the mb-auto is needed for it to not grow in height
         */
        className: (0, client_utils_1.classNames)(className, ""), style: __assign({ display: "grid", gridTemplateColumns: "".concat(props.leftSidePaddingInPixels, "px auto 1fr"), gridTemplateRows: "repeat(".concat((_c = props.rows) !== null && _c !== void 0 ? _c : 1, ", min-content)") }, (_d = props.motionProps) === null || _d === void 0 ? void 0 : _d.style) }, restMotionProps, { children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { transition: {
                    duration: 0.2,
                }, "aria-hidden": true, className: "col-span-1 col-start-1 h-full w-full rounded-l", animate: {
                    borderLeftWidth: borderWidth,
                    borderTopWidth: borderWidth,
                    borderBottomWidth: borderWidth,
                    borderColor: borderColor,
                }, style: {
                    gridRow: "1 / span ".concat((_e = props.rows) !== null && _e !== void 0 ? _e : 1),
                } }), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { transition: {
                    duration: 0.2,
                }, "aria-hidden": true, className: "col-start-3 h-full w-full rounded-r", animate: {
                    borderRightWidth: borderWidth,
                    borderTopWidth: borderWidth,
                    borderBottomWidth: borderWidth,
                    borderColor: borderColor,
                }, style: {
                    gridRow: "1 / span ".concat((_f = props.rows) !== null && _f !== void 0 ? _f : 1),
                } }), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { transition: {
                    duration: 0.2,
                }, "aria-hidden": true, className: "col-start-2 h-full w-full", animate: {
                    borderBottomWidth: borderWidth,
                    borderColor: borderColor,
                }, style: {
                    gridRow: "1 / span ".concat((_g = props.rows) !== null && _g !== void 0 ? _g : 1),
                } }), !!props.label && ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.label, { className: (0, client_utils_1.classNames)("z-10 col-start-2 row-start-1 mb-auto flex px-2", !props.onDarkBackground && "text-dark-950", props.onDarkBackground && "text-light-background", props.size === "sm" && "text-sm", props.size === "md" && "text-md", props.size === "lg" && "text-lg"), htmlFor: props.label.htmlFor, style: {
                    transform: "translateY(calc(-50% + 1px))",
                    background: "linear-gradient(to bottom, transparent calc(50%), ".concat(inputBackground, " calc(50%))"),
                }, children: [(0, jsx_runtime_1.jsx)("span", { children: props.label.text }), props.required && (0, jsx_runtime_1.jsx)("span", { children: "*" })] })), children] })));
};
exports.FauxFieldsetWrapper = FauxFieldsetWrapper;
