import { useContext } from "react";
import MidDot from "../../../../components/common/MidDot";
import AppStarIcon from "../../../../components/icons/AppStarIcon";
import AppUserIcon from "../../../../components/icons/AppUserIcon";
import MyLink from "../../../../components/routing/MyLink";
import AppRoutes from "../../../../routes";
import BookingSuggestionsContext from "../BookingSuggestionsContext";
import { HandymanWithPriorizitation } from "../SortedBookingSuggestions";
import ClientUtils from "./../../../../utils/ClientUtils";

interface Props {
  className?: string;
  handyman: HandymanWithPriorizitation;
}

const SingleBookingSuggestionHeader = (props: Props) => {
  const handyman = props.handyman;
  const parentCtx = useContext(BookingSuggestionsContext);
  const hasAddress = handyman.street && handyman.city;
  let addressText: string;
  if (hasAddress) {
    if (handyman.postalCode) {
      addressText = `${handyman.street}, ${handyman.postalCode} ${handyman.city}`;
    } else {
      addressText = `${handyman.street}, ${handyman.city}`;
    }
  } else {
    addressText = "Adress saknas";
  }

  let internalDescription: string | null = handyman.internalDescription;
  if (!internalDescription && process.env.NODE_ENV === "development") {
    internalDescription =
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, nisl eget aliquam ultricies, nunc nisl ultricies nisl, eget ultricies nisl nisl eget nisl.";
  }

  return (
    <header
      className={ClientUtils.twClassNames(
        "grid grid-cols-[auto,minmax(0,1fr),auto] gap-2 border-b bg-gradient-to-br from-brand-blue-50 to-brand-blue-100",
        !parentCtx.simplifiedView && "px-4 py-6",
        parentCtx.simplifiedView && "px-2 py-4",
        props.className
      )}
    >
      <span className="my-auto">
        <AppUserIcon size={24} />
      </span>
      <span className="my-auto flex flex-col">
        <span className="flex items-center">
          <MyLink
            to={
              AppRoutes.salesTeam.singlePartner(handyman.id) + "?sida=kalender"
            }
            blue
            blank
            className="flex items-center gap-2 font-semibold"
          >
            <span>{`${handyman.firstName} ${handyman.lastName}`}</span>
          </MyLink>
          <MidDot />
          <span className="text-sm italic text-opacity-80">
            {handyman.companyName}
          </span>
          {props.handyman.isPrioritized && (
            <AppStarIcon className="ml-4 text-orange-400" size={24} />
          )}
        </span>

        <span className="text-xs text-opacity-80">
          <span>{addressText}</span>
        </span>
        {!!handyman.phone && (
          <a
            className="text-xs text-brand-blue-600"
            href={`tel:${handyman.phone}`}
          >
            {handyman.phone}
          </a>
        )}
      </span>
      {internalDescription && (
        <pre className="max-w-[500px] whitespace-pre-wrap rounded border bg-white/20 p-2 text-sm">
          {internalDescription}
        </pre>
      )}
    </header>
  );
};

export default SingleBookingSuggestionHeader;
