import { WorkOrderLineItem } from "@eljouren/domain";
import { useContext } from "react";
import HandymanWorkOrderRouteContext from "../../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "../../../../../../utils/ClientUtils";
import LineItemApprovalStateIcon from "./LineItemApprovalStateIcon";

interface Props {
  className?: string;
  lineItem: WorkOrderLineItem.HandymanType;
}

const LineItemApprovalStateComponent = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const order = ctx.order;
  if (!order.requiresMaterialToBeReviewed) {
    return <></>;
  }

  const className = (additional: string) =>
    ClientUtils.twClassNames(
      "grid grid-cols-[auto,minmax(0,1fr)] items-center gap-2 pt-1 text-xs font-semibold",
      additional,
      props.className
    );

  const state = props.lineItem.approvalState;

  if (
    state === "addedBeforeApprovalRequirements" &&
    props.lineItem.addedByHandyman
  ) {
    return (
      <span className={className("text-green-700")}>
        <LineItemApprovalStateIcon lineItem={props.lineItem} />
        <span>Tillagd innan krav på godkännande från kund</span>
      </span>
    );
  }

  if (
    state === "addedBeforeApprovalRequirements" &&
    !props.lineItem.addedByHandyman
  ) {
    return (
      <span className={className("text-green-700")}>
        <LineItemApprovalStateIcon lineItem={props.lineItem} />
        <span>Tillagd av backoffice</span>
      </span>
    );
  }

  if (state === "notApproved") {
    return (
      <span className={className("text-red-700")}>
        <LineItemApprovalStateIcon lineItem={props.lineItem} />
        <span>Väntar på att bli skickad till kund för godkännande</span>
      </span>
    );
  }

  if (state === "pending") {
    return (
      <span className={className("text-yellow-600")}>
        <LineItemApprovalStateIcon lineItem={props.lineItem} />
        <span>Bekräftelse skickad till kund</span>
      </span>
    );
  }

  if (
    state === "approved" &&
    props.lineItem.linkedMaterialApprovalImages.length > 0
  ) {
    return (
      <span className={className("text-green-700")}>
        <LineItemApprovalStateIcon lineItem={props.lineItem} />
        <span>Godkänd av kund via bild</span>
      </span>
    );
  }

  if (state === "approved") {
    return (
      <span className={className("text-green-700")}>
        <LineItemApprovalStateIcon lineItem={props.lineItem} />
        <span>Godkänd av kund via BankID</span>
      </span>
    );
  }

  return <></>;
};

export default LineItemApprovalStateComponent;
