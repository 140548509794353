import { useContext } from "react";
import { UseFormReturn } from "react-hook-form";
import HandymanWorkOrderRouteContext from "../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import MyDialogWithToggle from "../../common/MyDialogWithToggle";
import { AppButton } from "../../common/buttons/AppButton";
import { AppFormCheckbox } from "../../common/checkboxes/AppFormCheckbox";
import {
  HandymanWorkOrderFileContext,
  THandymanFileFilters,
} from "./DecoupledWorkOrderFileContextProvider";
import AppFilterIcon from "../../icons/AppFilterIcon";

interface Props {
  className?: string;
  form: UseFormReturn<THandymanFileFilters>;
}

const HandymanWorkOrderFileFilterIconDialog = (props: Props) => {
  const routeCtx = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  const workOrderFilesLength =
    (fileCtx.workOrderDocuments?.length ?? 0) +
    (fileCtx.workOrderImages?.length ?? 0);
  const workOrderLineItemFilesLength =
    (fileCtx.workOrderLineItemDocuments?.length ?? 0) +
    (fileCtx.workOrderLineItemImages?.length ?? 0);
  const opportunityLineItemFilesLength =
    (fileCtx.opportunityLineItemDocuments?.length ?? 0) +
    (fileCtx.opportunityLineItemImages?.length ?? 0);
  const productPackageFilesLength =
    (fileCtx.productPackageDocuments?.length ?? 0) +
    (fileCtx.productPackageImages?.length ?? 0);
  const serviceContractFilesLength =
    (fileCtx.serviceContractDocuments?.length ?? 0) +
    (fileCtx.serviceContractImages?.length ?? 0);

  return (
    <MyDialogWithToggle
      content={(dialogProps) => {
        return (
          <div className="flex h-full w-full flex-col items-center justify-center gap-4 bg-white/90 px-8">
            <form className="flex flex-col gap-2 p-2">
              <AppFormCheckbox
                labelClassName="text-lg"
                register={props.form.register}
                name="workOrderFiles"
                label={`Kopplad till arbetsorder (${workOrderFilesLength})`}
                type="checkbox"
                htmlAttributes={{
                  disabled: !workOrderFilesLength,
                }}
              />
              <AppFormCheckbox
                labelClassName="text-lg"
                register={props.form.register}
                name="workOrderLineItemFiles"
                label={`Kopplad till material (${workOrderLineItemFilesLength})`}
                type="checkbox"
                htmlAttributes={{
                  disabled: !workOrderLineItemFilesLength,
                }}
              />
              <AppFormCheckbox
                labelClassName="text-lg"
                register={props.form.register}
                name="opportunityLineItemFiles"
                label={`Kopplad till faktura (${opportunityLineItemFilesLength})`}
                type="checkbox"
                htmlAttributes={{
                  disabled: !opportunityLineItemFilesLength,
                }}
              />
              <AppFormCheckbox
                labelClassName="text-lg"
                register={props.form.register}
                name="productPackageFiles"
                label={`Kopplad till produktpaket (${productPackageFilesLength})`}
                type="checkbox"
                htmlAttributes={{
                  disabled: !productPackageFilesLength,
                }}
              />

              {!!routeCtx.order.serviceContract && (
                <AppFormCheckbox
                  labelClassName="text-lg"
                  register={props.form.register}
                  name="serviceContractFiles"
                  label={`Kopplad till serviceavtal (${serviceContractFilesLength})`}
                  type="checkbox"
                  htmlAttributes={{
                    disabled: !serviceContractFilesLength,
                  }}
                />
              )}
              <AppButton
                variant="outline-default"
                className="ml-auto mt-8"
                onClick={dialogProps.close}
              >
                Gå tillbaka
              </AppButton>
            </form>
          </div>
        );
      }}
    >
      {(props) => (
        <button
          onClick={props.open}
          className="flex h-16 w-16 items-center justify-center rounded-full bg-brand-blue-300 text-white"
        >
          <AppFilterIcon size={24} className="mr-[2px]" />
        </button>
      )}
    </MyDialogWithToggle>
  );
};

export default HandymanWorkOrderFileFilterIconDialog;
