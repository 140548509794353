import { motion, useAnimation } from "framer-motion";
import {
  KeyboardShortcutOptions,
  useKeyboardShortcuts,
} from "../../../hooks/keyboard-event-hooks";
import { AppTileButtonV2, AppTileButtonV2Props } from "./AppTileButtonV2";

interface Props {
  className?: string;
  buttonProps: Omit<AppTileButtonV2Props, "sublabel">;
  keyboardShortcut: Omit<KeyboardShortcutOptions, "callback">;
}

const KeyboardShortcutAppTileButton = (props: Props) => {
  const animControls = useAnimation();

  useKeyboardShortcuts({
    ...props.keyboardShortcut,
    callback: () => {
      animControls.start({
        opacity: [1, 0.5, 1],
        transition: { duration: 0.75 },
      });
      props.buttonProps.onClick?.();
    },
  });

  let keyboardShortcutText = "";
  if (props.keyboardShortcut.ctrlKey) {
    keyboardShortcutText += "Ctrl + ";
  }
  if (props.keyboardShortcut.altKey) {
    keyboardShortcutText += "Alt + ";
  }
  if (props.keyboardShortcut.shiftKey) {
    keyboardShortcutText += "Shift + ";
  }
  keyboardShortcutText += props.keyboardShortcut.key;

  return (
    <motion.div animate={animControls} className={props.className}>
      <AppTileButtonV2 {...props.buttonProps} sublabel={keyboardShortcutText} />
    </motion.div>
  );
};

export default KeyboardShortcutAppTileButton;
