"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormInputLabel = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var client_utils_1 = require("../../../../utils/client-utils");
var IpisFormInputLabel = function (props) {
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.label, { className: (0, client_utils_1.classNames)("items-center gap-1 text-base text-dark-950", props.bold && "font-bold"), htmlFor: props.id, children: [props.label, props.required && (0, jsx_runtime_1.jsx)("span", { className: "pl-2 text-base", children: "*" })] }));
};
exports.IpisFormInputLabel = IpisFormInputLabel;
