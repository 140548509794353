"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderInvoice = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderInvoice;
(function (WorkOrderInvoice) {
    var EmailSchema = centralized_zod_1.z.object({
        invoiceMethod: centralized_zod_1.z.literal("email"),
        email: centralized_zod_1.z.string().email(),
        additionalInformation: centralized_zod_1.z.string().optional(),
    });
    var PostalSchema = centralized_zod_1.z.object({
        invoiceMethod: centralized_zod_1.z.literal("postal"),
        additionalInformation: centralized_zod_1.z.string().optional(),
    });
    WorkOrderInvoice.Schema = centralized_zod_1.z.union([EmailSchema, PostalSchema]);
    WorkOrderInvoice.PartialSchema = centralized_zod_1.z.union([
        EmailSchema.partial(),
        PostalSchema.partial(),
    ]);
})(WorkOrderInvoice || (WorkOrderInvoice = {}));
exports.WorkOrderInvoice = WorkOrderInvoice;
