import { BookingSuggestions } from "@eljouren/domain";
import { DateHelper, Utils } from "@eljouren/utils";
import { formatDuration, intervalToDuration } from "date-fns";
import { sv } from "date-fns/locale";
import { useState } from "react";
import AppCaretIcon from "../../../../components/icons/AppCaretIcon";
import MyLink from "../../../../components/routing/MyLink";
import AppRoutes from "../../../../routes";
import ClientUtils from "./../../../../utils/ClientUtils";

interface Props {
  className?: string;
  slots: BookingSuggestions.SlotType[] | BookingSuggestions.SlotType;
}

const SingleBookingSuggestionSimplifiedMain = (props: Props) => {
  const slots = Utils.asArray(props.slots);

  return (
    <main className={ClientUtils.twClassNames("py-2", props.className)}>
      <div role="table" className="flex w-full flex-col gap-2 p-4">
        <div className="border-b p-1 font-semibold">
          <div className="grid grid-cols-[repeat(13,1fr)]">
            <span className="col-span-2">Lucka</span>
            <span className="col-span-2">Datum</span>
            <span className="col-span-2">Restid dit</span>
            <span className="col-span-2">Utgångspunkt</span>
            <span className="col-span-2">Restid iväg</span>
            <span className="col-span-2">Efteråt</span>
          </div>
        </div>
        <div className="">
          {slots.map((slot, index) => {
            return (
              <TableRow
                key={
                  index +
                  "SingleBookingSUggestionSimplifiedMain" +
                  slot.earliestStart.getTime()
                }
                slot={slot}
              />
            );
          })}
        </div>
      </div>
    </main>
  );
};

const TableRow = (props: { slot: BookingSuggestions.SlotType }) => {
  const [expanded, setExpanded] = useState(false);

  function toggleExpanded() {
    setExpanded(!expanded);
  }

  const slot = props.slot;

  const startHelper = new DateHelper(slot.boundaries.start);
  const endHelper = new DateHelper(slot.boundaries.end);

  return (
    <div>
      <div
        className={ClientUtils.twClassNames(
          "grid cursor-pointer grid-cols-[repeat(13,1fr)] rounded p-1 transition-colors duration-300 hover:bg-brand-blue-100",
          expanded && "rounded-b-none bg-brand-blue-100"
        )}
        onClick={toggleExpanded}
      >
        <span className="col-span-2 flex items-center gap-2">
          <span className="">
            {startHelper.timeInputFormat} - {endHelper.timeInputFormat}
          </span>
          <span className="text-sm text-dark-gray text-opacity-80">
            {startHelper.dayOfWeek}
          </span>
        </span>
        <span className="col-span-2">{startHelper.readableDate}</span>
        <span className="col-span-2">
          {slot.before.travelInfo && slot.before.travelInfo.duration.text}
          {!slot.before.travelInfo && "Okänd"}
        </span>
        <span className="col-span-2">
          {slot.before.event && (
            <span
              className={ClientUtils.twClassNames(
                !!slot.before.event.serviceContract && "text-orange-600"
              )}
            >
              Arbetsorder
            </span>
          )}
          {!slot.before.event && <span>Hemifrån</span>}
        </span>
        <span className="col-span-2">
          {slot.after.travelInfo && slot.after.travelInfo.duration.text}
          {!slot.after.travelInfo && "Okänd"}
        </span>
        <span className="col-span-2">
          {slot.after.event && (
            <span
              className={ClientUtils.twClassNames(
                !!slot.after.event.serviceContract && "text-orange-600"
              )}
            >
              Arbetsorder
            </span>
          )}
          {!slot.after.event && <span>Inget planerat</span>}
        </span>
        <span>
          <AppCaretIcon
            direction="down"
            className={ClientUtils.twClassNames(
              "ml-auto transition-transform duration-300",
              expanded && "rotate-180 transform"
            )}
          />
        </span>
      </div>
      {expanded && (
        <div className="mb-4 grid grid-cols-3 gap-4 rounded rounded-t-none border border-t-0 border-brand-blue-100 bg-brand-blue-100/50 p-2">
          <ExpandedSlotInformation slot={props.slot} />
          <ExpandedEventInformation slot={props.slot} timing="before" />
          <ExpandedEventInformation slot={props.slot} timing="after" />
        </div>
      )}
    </div>
  );
};

const ExpandedSlotInformation = (props: {
  slot: BookingSuggestions.SlotType;
}) => {
  const slot = props.slot;
  function getDateFnsIntervalFormat() {
    const totalDurationInMs =
      slot.boundaries.end.getTime() - slot.boundaries.start.getTime();
    const duration = intervalToDuration({ start: 0, end: totalDurationInMs });
    const formatedTotalDuration = formatDuration(duration, { locale: sv });
    return formatedTotalDuration;
  }

  const dateFnsIntervalFormat = getDateFnsIntervalFormat();

  let formattedTotalTravelTime: string | null = null;
  if (slot.totalTravelTimeInMs) {
    const hours = Math.floor(slot.totalTravelTimeInMs / 1000 / 60 / 60);
    const minutes =
      Math.floor(slot.totalTravelTimeInMs / 1000 / 60) - hours * 60;
    formattedTotalTravelTime = formatDuration(
      { minutes, hours },
      {
        locale: sv,
      }
    );
  }

  let formattedExtraTime: string | null = null;
  if (slot.ifEventWasSkippedScenario?.additionalTravelTimeInMs) {
    const additional = slot.ifEventWasSkippedScenario.additionalTravelTimeInMs;
    const hours = Math.floor(additional / 1000 / 60 / 60);
    const minutes = Math.floor(additional / 1000 / 60) - hours * 60;

    formattedExtraTime = formatDuration(
      { minutes, hours },
      {
        locale: sv,
      }
    );
  }

  /* 
			  Format total duration to readable format
			*/
  const startHelper = new DateHelper(slot.boundaries.start);
  const endHelper = new DateHelper(slot.boundaries.end);
  const earliestStartHelper = new DateHelper(slot.earliestStart);
  const latestStartHelper = new DateHelper(slot.latestStart);

  const wh = props.slot.scheduleBoundaries;
  const start = new DateHelper(wh.start).timeInputFormat;
  const end = new DateHelper(wh.end).timeInputFormat;
  const formattedWhText = `${start} - ${end}`;

  return (
    <section className="flex flex-col gap-2 rounded border bg-off-white p-2 shadow">
      <header className="border-b">
        <h3 className="font-semi-bold text-sm text-dark-gray text-opacity-70">
          Lucka
        </h3>
      </header>
      <main>
        <ul className="list-disc gap-2 pl-4 text-dark-gray">
          <li className="text-sm">
            mellan {startHelper.timeInputFormat} och {endHelper.timeInputFormat}{" "}
            ({dateFnsIntervalFormat})
          </li>
          <li className="text-sm">
            <p className="text-sm">
              kan bokas in <b>tidigast {earliestStartHelper.timeInputFormat}</b>{" "}
              och <b>senast {latestStartHelper.timeInputFormat}</b>
            </p>
          </li>
          <li className="text-sm">
            arbetstid beräknad till <b>{formattedWhText}</b>
          </li>
          <li className="text-sm">
            {formattedTotalTravelTime && (
              <>total restid {formattedTotalTravelTime}</>
            )}
            {!formattedTotalTravelTime && <>total restid gick ej att beräkna</>}
          </li>
          {formattedExtraTime && (
            <li className="text-sm">
              inbokningen av denna arbetsorder inför{" "}
              <strong>en extra restid på ungefär {formattedExtraTime}</strong>
            </li>
          )}
        </ul>
      </main>
    </section>
  );
};

const ExpandedEventInformation = (props: {
  slot: BookingSuggestions.SlotType;
  timing: "before" | "after";
}) => {
  const event =
    props.timing === "before"
      ? props.slot.before.event
      : props.slot.after.event;
  return (
    <section className="flex flex-col gap-2 rounded border bg-off-white p-2 shadow">
      <header className="border-b">
        <h3 className="font-semi-bold text-sm text-dark-gray text-opacity-70">
          {props.timing === "before" ? "Före" : "Efter"}
        </h3>
      </header>
      <main className="text-sm">
        {!!event && (
          <MyLink
            to={AppRoutes.partner.customerOrder(event.workerGuid)}
            blank
            className={ClientUtils.twClassNames()}
          >
            <header>
              <h3 className="text-base">{event.description}</h3>
              <h4 className="whitespace-nowrap text-sm font-normal italic text-dark-gray text-opacity-80">
                {new DateHelper(event.startDate).timeInputFormat}-
                {new DateHelper(event.endDate).timeInputFormat}
              </h4>
            </header>
            <main>
              <span className="whitespace-nowrap text-xs">
                {event.location.street}, {event.location.city}{" "}
                {event.location.postalCode}
              </span>
            </main>
          </MyLink>
        )}
        {!event && <span>Inget planerat</span>}
      </main>
    </section>
  );
};

export default SingleBookingSuggestionSimplifiedMain;
