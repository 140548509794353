import ClientUtils from "../../utils/ClientUtils";
import { IconBaseProps } from "react-icons/lib";
import { FaSignOutAlt } from "react-icons/fa";

interface Props extends IconBaseProps {
  className?: string;
}

/* 
  Cant find a sign out icon in heroicons, so we use the sign out icon from react-icons temporarily
*/
const SignOutIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <FaSignOutAlt
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default SignOutIcon;
