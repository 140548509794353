import { v4 as uuidv4 } from "uuid";
import ProcessTask from "./ProcessTask";

type ProcessArgs = {
  name: string;
  tasks: ProcessTask[];
  id?: string;
};

type ProcessState = {
  isRunning: boolean;
  currentTask: ProcessTask | null;
};

// Process class represents a process composed of multiple tasks
export default class Process {
  readonly id: string;
  readonly name: string;
  private _tasks: ProcessTask[];
  private _currentTaskIndex: number;
  private _isRunning: boolean;
  private observers: ((isRunning: boolean) => void)[];
  private taskSubscribers: ((task: ProcessTask | null) => void)[];
  private finishedObservers: (() => void)[];

  constructor(args: ProcessArgs) {
    this.id = args.id || uuidv4();
    this.name = args.name;
    this._tasks = args.tasks;
    this._currentTaskIndex = 0;
    this._isRunning = false;
    this.observers = [];
    this.taskSubscribers = [];
    this.finishedObservers = [];
  }

  // Updates the isRunning state and notifies observers
  private setIsRunning(value: boolean) {
    this._isRunning = value;
    this.observers.forEach((observer) => observer(value));
  }

  // Allows subscription to the isRunning state
  public subscribeToIsRunning(
    observer: (isRunning: boolean) => void
  ): () => void {
    this.observers.push(observer);
    observer(this._isRunning);
    return () => {
      this.observers = this.observers.filter((obs) => obs !== observer);
    };
  }

  // Allows subscription to the current task
  public subscribeToCurrentTask(
    observer: (task: ProcessTask | null) => void
  ): () => void {
    this.taskSubscribers.push(observer);
    observer(this._tasks[this._currentTaskIndex] || null);
    return () => {
      this.taskSubscribers = this.taskSubscribers.filter(
        (obs) => obs !== observer
      );
    };
  }

  // Allows subscription to the finished event
  onFinished(callback: () => void): () => void {
    this.finishedObservers.push(callback);

    return () => {
      this.finishedObservers = this.finishedObservers.filter(
        (obs) => obs !== callback
      );
    };
  }

  // Notifies the current task subscribers of a task update
  private notifyTaskSubscribers(): void {
    this.taskSubscribers.forEach((subscriber) =>
      subscriber(this._tasks[this._currentTaskIndex] || null)
    );
  }

  // Advances the process to the next task
  public async next(
    args: { preventChangingRunningStatus?: boolean } = {}
  ): Promise<void> {
    if (this.isFinished()) {
      return;
    }

    if (!args.preventChangingRunningStatus) {
      this.setIsRunning(true);
    }
    const task = this._tasks[this._currentTaskIndex];
    await task.run();

    this._currentTaskIndex++;
    if (!args.preventChangingRunningStatus) {
      this.setIsRunning(false);
    }
    this.notifyTaskSubscribers();

    if (this.isFinished()) {
      this.notifyFinishedObservers();
    }
  }

  // Notifies the finished event observers
  private notifyFinishedObservers(): void {
    this.finishedObservers.forEach((observer) => observer());
  }

  // Checks if the process has finished all tasks
  public isFinished(): boolean {
    return this._currentTaskIndex >= this._tasks.length;
  }

  // Runs the process until all tasks are finished
  public async runUntilFinished(): Promise<void> {
    this.setIsRunning(true);
    while (!this.isFinished()) {
      await this.next({ preventChangingRunningStatus: true });
    }
    this.setIsRunning(false);
  }

  // Adds new tasks to the process
  public addTasks(tasks: ProcessTask[]): void {
    if (this.isFinished()) {
      return;
    }
    this._tasks = this._tasks.concat(tasks);
  }

  // Returns a copy of the current tasks
  getTasks(): ProcessTask[] {
    return [...this._tasks];
  }

  // Returns the current task index
  getCurrentTaskIndex() {
    return this._currentTaskIndex;
  }

  // Returns the current state of the process
  getState(): ProcessState {
    return {
      isRunning: this._isRunning,
      currentTask: this._tasks[this._currentTaskIndex] || null,
    };
  }

  // Subscribes to the state updates
  subscribeToState(observer: (state: ProcessState) => void): () => void {
    const notifyObserver = () => observer(this.getState());
    const unsubscribeRunning = this.subscribeToIsRunning(notifyObserver);
    const unsubscribeTask = this.subscribeToCurrentTask(notifyObserver);
    return () => {
      unsubscribeRunning();
      unsubscribeTask();
    };
  }

  //Get failed tasks
  getFailedTasks(): ProcessTask[] {
    return this._tasks.filter((task) => {
      const result = task.getResult();
      return result && result.state !== "succeeded";
    });
  }
}
