import ClientUtils from './../../utils/ClientUtils';
interface Props {
  id: string;
  className?: string;
  label: string;
  text: string;
  href?: string;
}

const LabelAndText = (props: Props) => {
  return (
    <div
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2",
        props.className
      )}
    >
      <label
        className="text-sm font-medium tracking-wide text-gray-900"
        htmlFor={props.id}
      >
        {props.label}
      </label>
      <pre
        className="whitespace-pre-wrap break-words rounded bg-bg-base-layer p-2 lg:bg-white"
        id={props.id}
      >
        {props.text.length === 0 ? "-" : props.text}
      </pre>
    </div>
  );
};

export default LabelAndText;
