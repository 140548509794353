import { useContext } from "react";
import { useForm } from "react-hook-form";
import WorkOrderMobileFileTab from "../../../../components/work-order/files/WorkOrderMobileFileTab";
import {
  HandymanWorkOrderFileContext,
  THandymanFileFilters,
} from "../../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import HandymanWorkOrderFileFilterIconDialog from "../../../../components/work-order/files/DecoupledWorkOrderFileFilterIconDialog";

interface Props {
  className?: string;
}

const HandymanWorkOrderMobileFileTab = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderFileContext);

  const form = useForm<THandymanFileFilters>({
    defaultValues: ctx.defaultFilterValues,
  });

  const { images, documents } = ctx.applyFilters(form.watch());

  return (
    <WorkOrderMobileFileTab
      images={images}
      documents={documents}
      deleteFile={ctx.deleteFile}
      uploadFiles={ctx.uploadFiles}
      permissions={ctx.permissions}
    >
      <HandymanWorkOrderFileFilterIconDialog form={form} />
    </WorkOrderMobileFileTab>
  );
};

export default HandymanWorkOrderMobileFileTab;
