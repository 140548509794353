import { HTTPHeaders } from "@trpc/client";
import { z } from "@ipis/centralized-zod";
import SearchParams from "./SearchParams";
import jwtDecode from "jwt-decode";

const HandymanCredentialsSchema = z.object({
  authenticatedAs: z.literal("handyman"),
  jwt: z.string(),
  userId: z.string(),
  isAdmin: z.boolean(),
});

const StaffCredentialsSchema = z.object({
  authenticatedAs: z.literal("staff"),
  staffGuid: z.string(),
});

const CustomerCredentialsSchema = z.object({
  authenticatedAs: z.literal("customer"),
  jwt: z.string(),
});

const CredentialsSchema = z.union([
  HandymanCredentialsSchema,
  StaffCredentialsSchema,
  CustomerCredentialsSchema,
]);

export type Credentials = z.infer<typeof CredentialsSchema>;

export namespace API {
  export function base(): string {
    return process.env.REACT_APP_API_URL || "";
  }

  export function endpoint(url: string, search?: SearchParams) {
    const withoutLeadingAndTrailingSlashes = url.replace(/^\/|\/$/g, "");

    if (search) {
      return `${base()}/${withoutLeadingAndTrailingSlashes}/?${search}`;
    } else {
      return `${base()}/${withoutLeadingAndTrailingSlashes}`;
    }
  }
  export function fileBase(): string {
    return process.env.REACT_APP_PROXY_URL || "";
    //return process.env.REACT_APP_FILE_URL || "";
  }

  export function fileEndpoint(url: string, search?: URLSearchParams) {
    const withoutLeadingAndTrailingSlashes = url.replace(/^\/|\/$/g, "");

    if (search) {
      return `${fileBase()}/${withoutLeadingAndTrailingSlashes}/?${search}`;
    } else {
      return `${fileBase()}/${withoutLeadingAndTrailingSlashes}`;
    }
  }

  export function getJwt(): string {
    const creds = getCredentials();
    if (!creds || creds.authenticatedAs === "staff") {
      throw new Error("Not authenticated or JWT not saved in local storage");
    }
    return creds.jwt;
  }

  export function nonSignedInAuthHeader(): HTTPHeaders & HeadersInit {
    const jwt = localStorage.getItem("noCredentialsJwt");
    if (!jwt) {
      throw new Error("Customer not authenticated");
    }

    return {
      Authorization: `Bearer ${jwt}`,
    };
  }
  export function isAuthenticatedWithoutUser(): boolean {
    return !!localStorage.getItem("authenticatedWithoutCredentials");
  }

  function saveCredentials(credentials: Credentials): void {
    localStorage.setItem("credentials", JSON.stringify(credentials));
  }
  export function removeCredentials(): void {
    localStorage.removeItem("credentials");
  }
  export function getCredentials(): Credentials | undefined {
    const credentials = localStorage.getItem("credentials");
    if (!credentials) {
      return undefined;
    }
    return CredentialsSchema.parse(JSON.parse(credentials));
  }

  export function onCustomerSignIn(jwt: string): void {
    const credentials: Credentials = {
      authenticatedAs: "customer",
      jwt,
    };
    saveCredentials(credentials);
  }
  export function onStaffSignIn(args: { staffGuid: string }): void {
    const credentials: Credentials = {
      authenticatedAs: "staff",
      staffGuid: args.staffGuid,
    };
    saveCredentials(credentials);
  }

  export function handymanAuthHeader(): HTTPHeaders & HeadersInit {
    const creds = getCredentials();
    if (!creds || creds.authenticatedAs !== "handyman") {
      throw new Error("Worker not authenticated");
    }

    return {
      Authorization: `Bearer ${creds.jwt}`,
    };
  }
  export function handymanIsAuthenticated(): boolean {
    const creds = getCredentials();
    const isAuthenticated = !!creds && creds.authenticatedAs === "handyman";
    return isAuthenticated;
  }

  export function isAuthenticated(): boolean {
    const creds = getCredentials();
    const isAuthenticated = !!creds;
    return isAuthenticated;
  }

  export function onHandymanSignIn(args: {
    jwt: string;
    isAdmin: boolean;
  }): void {
    const token = jwtDecode<{ userId: string }>(args.jwt);
    const creds: Credentials = {
      authenticatedAs: "handyman",
      jwt: args.jwt,
      userId: token.userId,
      isAdmin: args.isAdmin,
    };
    saveCredentials(creds);
  }

  export function getUserId(): string {
    const creds = getCredentials();
    if (!creds || creds.authenticatedAs !== "handyman") {
      return "";
    }
    return creds.userId;
  }

  export function getOptionalAuthHeader() {
    const creds = getCredentials();
    if (!creds || creds.authenticatedAs === "staff") {
      return undefined;
    }

    return {
      Authorization: `Bearer ${creds.jwt}`,
    };
  }

  export function onFailedAuth() {
    removeCredentials();
  }
  export function getAuthHeader() {
    const authHeader = getOptionalAuthHeader();
    if (!authHeader) {
      throw new Error("Not authenticated or JWT not saved in local storage");
    }
    return authHeader;
  }
}
