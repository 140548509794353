import { WrenchIcon } from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
}

const AppHandymanIcon = (props: Props) => {
  const { className, ...rest } = props;

  return <WrenchIcon {...rest} className={props.className} />;
};
export default withIconSize(AppHandymanIcon);
