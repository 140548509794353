"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Proxied_UploadInput = void 0;
var SignUrl_UploadInput_1 = require("./SignUrl_UploadInput");
var Proxied_UploadInput;
(function (Proxied_UploadInput) {
    /*
      This is not entirely correct, as you have to attach the files as well,
      but that's not easily represented by this schema.
    */
    Proxied_UploadInput.Schema = SignUrl_UploadInput_1.SignUrl_UploadInput.Schema;
})(Proxied_UploadInput || (Proxied_UploadInput = {}));
exports.Proxied_UploadInput = Proxied_UploadInput;
