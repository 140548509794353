import { Loader } from "@googlemaps/js-api-loader";
import { useCallback, useEffect, useMemo, useState } from "react";

const loader = new Loader({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
  version: "weekly",
  language: "sv",
  region: "SE",
  //...additionalOptions,
});
const STATE: {
  loading: boolean;
  places: Places | null;
} = {
  loading: false,
  places: null,
};

type Places = google.maps.places.PlacesService;

function loadPlaces(callback: (places: Places) => void) {
  if (STATE.loading || STATE.places) {
    if (STATE.places) {
      callback(STATE.places);
    }
    return;
  }
  STATE.loading = true;
  loader.importLibrary("places").then((places) => {
    STATE.places = places;
    STATE.loading = false;
    callback(places);
  });
}

export const useGooglePlaces = () => {
  const [places, setPlaces] = useState<Places | null>(STATE.places);

  useEffect(() => {
    loadPlaces(setPlaces);
  }, [places]);

  return places;
};

export const useGoogleGeocode = () => {
  const places = useGooglePlaces();
  const geocoder = useMemo(() => {
    console.log("geocoder use memo");
    if (!places) {
      return null;
    }
    return new google.maps.Geocoder();
  }, [places]);

  const getGeocode = useCallback(
    async (address: string) => {
      if (!geocoder) {
        throw new Error("Geocoder not initialized");
      }

      return new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results) {
            resolve(results);
          } else {
            reject("Geocoding failed: " + status);
          }
        });
      });
    },
    [geocoder]
  );

  return getGeocode;
};
