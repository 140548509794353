import React from "react";

interface Props {
  width?: number;
  height?: number;
}

const TrustlyLogo: React.FC<Props> = (props) => {
  return (
    <img
      style={{
        width: props.width ?? 60,
        height: props.height ?? 40,
      }}
      src={`external-logos/trustly/trustly_logo.svg`}
      alt="Logga för Trustly"
    />
  );
};

export default TrustlyLogo;
