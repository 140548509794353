"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisTextInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var FormInputShell_1 = require("../components/FormInputShell");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var IpisTextInput = function (props) {
    var _a;
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var regProps = (0, form_hooks_1.useFormRegisterProps)({
        props: props,
        type: props.type === "number" ? "number" : "text",
    });
    return ((0, jsx_runtime_1.jsx)(FormInputShell_1.FormInputShell, __assign({}, props, { children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.input, __assign({ type: (_a = props.type) !== null && _a !== void 0 ? _a : "text", id: props.id, placeholder: props.placeholder, className: "col-span-2 w-full border-b border-border-color bg-input-background p-4 placeholder-semi-faded" }, regProps, { disabled: !!props.disabled, readOnly: !!(ctx === null || ctx === void 0 ? void 0 : ctx.readOnly) }, props.inputProps)) })));
};
exports.IpisTextInput = IpisTextInput;
