import ClientUtils from './../../../../utils/ClientUtils';
import { useContext, useEffect, useRef, useState } from "react";
import DatePeriod from "../../../../_model/helpers/AvailabilityPeriod";
import AppPages from "../../../../components/common/pages/AppPages";
import HandymanContext from "../../../../components/handyman-context/HandymanContext";
import { useImmutable } from "../../../../hooks/hooks";

import HandymanWorkingHoursFormSection from "./HandymanWorkingHoursFormSection";
import HandymanWorkingHoursSelectDatesSection from "./HandymanWorkingHoursSelectDatesSection";

enum Page {
  selectDates,
  form,
}

interface Props {
  className?: string;
  noEdit?: boolean;
}

const HandymanWorkingHoursTab = (props: Props) => {
  const ctx = useContext(HandymanContext);
  const period = useRef(new DatePeriod({ weeksInPeriod: 2 })).current;
  const selectedDates = useImmutable<Record<string, true>>({});

  const [page, setPage] = useState(Page.selectDates);

  /*
  Including selectedDates in dependency array will cause infinite loop as the reference will be new after overriding  
  */
  useEffect(() => {
    selectedDates.override({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctx.page]);

  return (
    <div
      className={ClientUtils.twClassNames(
        "grid w-full grid-cols-1 grid-rows-1 overflow-hidden",
        props.className
      )}
    >
      <AppPages
        onlyMain
        mainGridRow={1}
        className={ClientUtils.twClassNames(
          "grid h-full w-full grid-cols-1 grid-rows-1"
        )}
        pageIndex={page}
      >
        {page === Page.selectDates && (
          <HandymanWorkingHoursSelectDatesSection
            period={period}
            noEdit={!!props.noEdit}
            selectedDates={selectedDates}
            nextPage={() => setPage(Page.form)}
          />
        )}
        {page === Page.form && (
          <HandymanWorkingHoursFormSection
            selectedDates={selectedDates}
            goBack={() => setPage(Page.selectDates)}
          />
        )}
      </AppPages>
    </div>
  );
};

export default HandymanWorkingHoursTab;
