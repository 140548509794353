import { DateHelper } from "@eljouren/utils";
import trpcClient from "../../trpc-setup";
import IBookingsRepo from "./interfaces/IBookingsRepo";

export default class BookingsRepo implements IBookingsRepo {
  async findWorkOrderBookingSuggestions(
    args: Parameters<IBookingsRepo["findWorkOrderBookingSuggestions"]>[0]
  ): Promise<any> {
    const defaultStart = new DateHelper().setHours(7).setMinutes(0).date;
    const defaultEnd = new DateHelper().setHours(16).setMinutes(0).date;

    return trpcClient.bookingsRouter.findWorkOrderBookingSuggestions.query({
      ...args,
      defaultWorkingHours: {
        start: defaultStart.toISOString(),
        end: defaultEnd.toISOString(),
      },
    });
  }
}
