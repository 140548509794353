"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentStore = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var PaymentStore;
(function (PaymentStore) {
    PaymentStore.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
    });
})(PaymentStore || (PaymentStore = {}));
exports.PaymentStore = PaymentStore;
