"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SingleActionDialog = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var keyboard_event_hooks_1 = require("../../../hooks/keyboard-event-hooks");
var client_utils_1 = require("../../../utils/client-utils");
var IpisButton_1 = require("../../buttons/IpisButton");
var IpisCheckmarkIcon_1 = require("../../icons/IpisCheckmarkIcon");
var IpisErrorIcon_1 = require("../../icons/IpisErrorIcon");
var SingleActionDialog = function (props) {
    var ref = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(function () {
        var _a;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.focus();
    });
    (0, keyboard_event_hooks_1.useOnEnterClick)({
        ref: ref,
        callback: function () {
            props.confirm();
        },
    });
    var args = props.args;
    return ((0, jsx_runtime_1.jsx)(framer_motion_1.motion.div, { className: "fixed z-[70] flex h-full w-full flex-col items-center justify-center bg-black/40 p-2 xs:p-4 sm:p-8", initial: {
            opacity: 0,
        }, animate: { opacity: 1 }, exit: {
            opacity: 0,
        }, role: "alertdialog", "aria-labelledby": "alertTitle", "aria-describedby": "alertPrompt", children: (0, jsx_runtime_1.jsxs)("div", { className: (0, client_utils_1.classNames)("pointer-events-auto flex w-[450px] max-w-full flex-col rounded border bg-light-background p-4 shadow-lg"), role: "document", tabIndex: 0, ref: ref, children: [(0, jsx_runtime_1.jsx)("h2", { id: "alertTitle", className: "break-words text-xl font-bold text-dark-950", children: args.title }), args.icon === "success" && ((0, jsx_runtime_1.jsx)(IpisCheckmarkIcon_1.IpisCheckmarkIcon, { variant: "circle", success: true, size: 42, className: "mx-auto mt-8" })), args.icon === "error" && ((0, jsx_runtime_1.jsx)(IpisErrorIcon_1.IpisErrorIcon, { size: 42, className: "mx-auto mt-8 text-error" })), (0, jsx_runtime_1.jsx)("p", { id: "alertPrompt", className: "py-8 text-base text-dark-800", children: args.body }), (0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { label: args.actionLabel, onClick: props.confirm, className: "mx-auto mt-2 min-w-[200px] max-w-full", variant: "secondary-on-light-background" })] }) }));
};
exports.SingleActionDialog = SingleActionDialog;
