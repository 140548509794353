import ClientUtils from "./../../../../utils/ClientUtils";
import { motion } from "framer-motion";
import { useContext } from "react";
import { AppLoader } from "../../../../components/common/loaders/AppLoader";
import SelectableFileGridItem from "../../../../components/files/SelectableFileGridItem";
import HandymanConditionalFileErrorDropdown from "../../../../components/work-order/handyman/files/HandymanConditionalFileErrorDropdown";
import { HandymanWorkOrderFileContext } from "../../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import { HandyManWorkOrderDesktopFileProps } from "./HandymanWorkOrderDesktopFileSection";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import AppImageIcon from "../../../../components/icons/AppImageIcon";
import AppMaximizeIcon from "../../../../components/icons/AppMaximizeIcon";
import AppDocumentIcon from "../../../../components/icons/AppDocumentIcon";

const FadeInWithDelayProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { delay: 0.3 },
};

type Props = Omit<HandyManWorkOrderDesktopFileProps, "isExpanded">;

const HandymanWorkOrderDesktopFilePreviewSection = (props: Props) => {
  const ctx = useContext(HandymanWorkOrderFileContext);
  return (
    <>
      <header className={ClientUtils.twClassNames("flex items-center px-2")}>
        <motion.span
          {...FadeInWithDelayProps}
          className="flex gap-2 font-semibold"
        >
          <AppDocumentIcon size={24} />
          <span>{ctx.allDocuments.length}</span>
        </motion.span>
        <motion.span {...FadeInWithDelayProps} className="mx-2">
          |
        </motion.span>

        <motion.span
          {...FadeInWithDelayProps}
          className="flex gap-2 font-semibold"
        >
          <AppImageIcon size={24} />
          <span>{ctx.allImages.length}</span>
        </motion.span>

        <HandymanConditionalFileErrorDropdown absolute openTop />

        {ctx.isLoading && <AppLoader className="ml-4 mr-0 h-6 w-6" />}
        <AppButton
          {...FadeInWithDelayProps}
          onClick={props.onToggle}
          className="ml-auto"
        >
          <AppMaximizeIcon size={24} />
          <span>Se alla filer</span>
        </AppButton>
      </header>
      <main
        className={ClientUtils.twClassNames(
          "relative flex items-center overflow-hidden"
        )}
      >
        {ctx.workOrderFileRes.query.isLoading && <AppLoader />}

        {!!ctx.allImages.length && (
          <motion.ul
            className={ClientUtils.twClassNames(
              "no-scrollbar flex h-full w-full overflow-hidden"
            )}
          >
            {ctx.allImages
              .map((file) => ({
                ...file,
                alt: "Fil uppladdad till arbetsorder",
              }))
              .map((file) => {
                return (
                  <SelectableFileGridItem
                    className={"h-full w-60 flex-shrink-0 flex-grow-0"}
                    key={file.src}
                    file={file}
                    customDimensions
                  />
                );
              })}
          </motion.ul>
        )}
      </main>
    </>
  );
};

export default HandymanWorkOrderDesktopFilePreviewSection;
