import { MotionProps, motion } from "framer-motion";
import { useContext } from "react";
import ResursbankLogo from "../../../../components/external-brands/resursbank/ResursbankLogo";
import AppExternalLinkIcon from "../../../../components/icons/AppExternalLinkIcon";
import CustomerWorkOrderInvoiceContext from "../CustomerWorkOrderInvoiceContext";

interface Props {
  className?: string;
  motionProps: MotionProps;
  style: React.CSSProperties;
}

const InvoiceInspectionView = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);

  const savedStatus = ctx.syncRes.data?.savedStatus;
  const redirectUrl = savedStatus?.initiated
    ? savedStatus?.customerRedirectUrl
    : undefined;

  return (
    <motion.section
      className="mx-auto flex h-full w-full flex-col items-center gap-8 overflow-auto pt-8"
      {...props.motionProps}
      style={props.style}
    >
      <header className="flex max-w-screen-sm flex-col gap-x-8 children-w-screen-sm md:grid md:grid-cols-[minmax(0,1fr),auto]">
        <h3>Vi behöver mer information för att kunna bevilja din ansökan</h3>
        <figure className="col-start-2 row-span-2 row-start-1 my-auto ml-auto py-2">
          <ResursbankLogo width={120} />
        </figure>
      </header>
      {!!redirectUrl && (
        <a
          className="flex items-center gap-2 rounded border bg-button-color  px-4 py-2 text-off-white"
          href={redirectUrl}
          target="_blank"
          rel="noreferrer"
        >
          <span>Ta mig till ResursBank</span>
          <AppExternalLinkIcon />
        </a>
      )}
      {!redirectUrl && (
        <p>
          Vid frågor,{" "}
          <a className="text-blue-600 hover:underline" href="tel:+46108887407">
            ring oss på 010-888 74 07!
          </a>
        </p>
      )}
    </motion.section>
  );
};

export default InvoiceInspectionView;
