"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddress = useAddress;
exports.useHasAddressErrors = useHasAddressErrors;
function getName(key, props) {
    var _a, _b;
    var mappedKey = (_b = (_a = props.fieldMapping) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : key;
    if (props.registerNested) {
        return "".concat(props.registerNested, ".").concat(mappedKey);
    }
    else {
        return mappedKey;
    }
}
function useAddress(props) {
    function watch(key) {
        var name = getName(key, props);
        return props.form.watch(name);
    }
    function getCurrentAddress() {
        var _a, _b, _c;
        return {
            street: (_a = watch("street")) !== null && _a !== void 0 ? _a : "",
            city: (_b = watch("city")) !== null && _b !== void 0 ? _b : "",
            postalCode: (_c = watch("postalCode")) !== null && _c !== void 0 ? _c : "",
            floor: watch("floor"),
            doorCode: watch("doorCode"),
            country: watch("country"),
        };
    }
    var a = getCurrentAddress();
    return a;
}
function useHasAddressErrors(props) {
    props.form.watch();
    function getName(key, props) {
        var _a, _b;
        var mappedKey = (_b = (_a = props.fieldMapping) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : key;
        if (props.registerNested) {
            return "".concat(props.registerNested, ".").concat(mappedKey);
        }
        else {
            return mappedKey;
        }
    }
    function hasErrors() {
        var errors = props.form.formState.errors;
        var paths = [
            getName("street", props),
            getName("city", props),
            getName("postalCode", props),
            getName("country", props),
            getName("doorCode", props),
            getName("floor", props),
        ];
        return paths.some(function (p) {
            var parts = p.split(".");
            if (parts.length === 0) {
                return false;
            }
            var error = errors;
            do {
                var first = parts.shift();
                if (first) {
                    error = error[first];
                }
            } while (error && parts.length > 0);
            return !!error;
        });
    }
    return hasErrors();
}
