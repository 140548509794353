"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InformationTooltip = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var keyboard_event_hooks_1 = require("../hooks/keyboard-event-hooks");
var user_interaction_hooks_1 = require("../hooks/user-interaction-hooks");
var media_hooks_1 = require("../hooks/media-hooks");
var client_utils_1 = require("../utils/client-utils");
var RichTextPreTag_1 = require("./form/form-inputs/components/RichTextPreTag");
var IpisCaretIcon_1 = require("./icons/IpisCaretIcon");
var IpisHelpIcon_1 = require("./icons/IpisHelpIcon");
var InformationTooltip = function (props) {
    var _a;
    var className = props.className, content = props.content, open = props.open, iconProps = props.iconProps;
    var activeBreakPoint = (0, media_hooks_1.useMediaQueries)(["md", "lg", "xl"]).activeBreakPoint;
    var isMd = activeBreakPoint !== "default";
    var toggleRef = (0, react_1.useRef)(null);
    var contentRef = (0, react_1.useRef)(null);
    var _b = (0, react_1.useState)(false), isOpen = _b[0], setIsOpen = _b[1];
    var _c = (0, react_1.useState)(false), isHovering = _c[0], setIsHovering = _c[1];
    (0, user_interaction_hooks_1.useOnOutsideClick)({
        ref: contentRef,
        callback: function () {
            setIsOpen(false);
        },
        exclude: [toggleRef],
    });
    (0, keyboard_event_hooks_1.useOnEscapeClick)(function () {
        setIsOpen(false);
    });
    function toggleOpen() {
        setIsOpen(!isOpen);
    }
    function onMouseOver() {
        if (isMd) {
            setIsHovering(true);
        }
    }
    function onMouseOut() {
        if (isMd) {
            setIsHovering(false);
        }
    }
    var showTooltip = isOpen || isHovering;
    var isString = typeof content === "string";
    var isRichText = typeof content === "object";
    var isFunction = typeof content === "function";
    var openMap;
    if (typeof open === "string") {
        openMap = {
            default: open,
        };
    }
    else {
        openMap = open;
    }
    var currentDirection = (_a = openMap[activeBreakPoint]) !== null && _a !== void 0 ? _a : openMap.default;
    var classNamesMap = {
        left: "right-0 top-full mt-2",
        right: "left-0 top-full mt-2",
        "top-left": "right-0 bottom-full mb-2",
        "top-right": "left-0 bottom-full mb-2",
    };
    var currentClassNames = classNamesMap[currentDirection];
    var contentClassName = (0, client_utils_1.classNames)("absolute whitespace-pre-wrap rounded bg-dark-900 p-2 text-sm text-light-background overflow-visible", currentClassNames, props.contentClassName);
    return ((0, jsx_runtime_1.jsxs)("div", { className: (0, client_utils_1.classNames)("relative z-30 flex aspect-square items-center justify-center rounded", props.customIcon && "border", "cursor-pointer", className), onClick: toggleOpen, ref: toggleRef, onMouseOver: onMouseOver, onMouseOut: onMouseOut, role: "tooltip", id: props.id, children: [(0, jsx_runtime_1.jsxs)("span", { className: (0, client_utils_1.classNames)(props.iconWrapperClassName), children: [!props.customIcon && ((0, jsx_runtime_1.jsx)(IpisHelpIcon_1.IpisHelpIcon, __assign({}, iconProps, { className: (0, client_utils_1.classNames)("hover:fill-dark-200", isOpen && "fill-dark-200") }))), props.customIcon && props.customIcon()] }), showTooltip && (isString || isRichText) && ((0, jsx_runtime_1.jsxs)("div", { className: contentClassName, ref: contentRef, children: [(0, jsx_runtime_1.jsx)(TriangleSpan, { open: currentDirection }), (0, jsx_runtime_1.jsx)(RichTextPreTag_1.RichTextPreTag, { html: content })] })), showTooltip && isFunction && ((0, jsx_runtime_1.jsx)("div", { className: contentClassName, ref: contentRef, children: content() }))] }));
};
exports.InformationTooltip = InformationTooltip;
var TriangleSpan = function (props) {
    var top = props.open.startsWith("top");
    return ((0, jsx_runtime_1.jsx)("span", { className: (0, client_utils_1.classNames)("absolute -z-10 flex text-dark-900", props.open === "left" && "bottom-full right-0", props.open === "right" && "bottom-full left-0", props.open === "top-left" && "right-0 top-full", props.open === "top-right" && "left-0 top-full"), "aria-hidden": true, children: (0, jsx_runtime_1.jsx)(IpisCaretIcon_1.IpisCaretIcon, { direction: top ? "down" : "up", filled: true, size: "sm", viewBox: top ? "5 12 14 14" : "5 -2 14 14" }) }));
};
