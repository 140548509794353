"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormInputError = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var client_utils_1 = require("../../../../utils/client-utils");
var IpisFormInputError = function (props) {
    return ((0, jsx_runtime_1.jsx)("span", { 
        /* aria-label={`Error message for ${props.humanReadableName}`} */
        className: (0, client_utils_1.classNames)("text-sm text-red-600", props.className), children: props.error }));
};
exports.IpisFormInputError = IpisFormInputError;
