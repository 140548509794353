import { useState } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";
import ClientUtils from "./../../../utils/ClientUtils";

import { UUID, Utils } from "@eljouren/utils";

interface Props<T extends FieldValues> {
  name: Path<T>;
  label?: string;
  errorMessage?: string;
  errors?: FieldErrors<T>;
  className?: string;
  boldLabel?: boolean;
  register: UseFormRegister<T>;
  options?: RegisterOptions<T>;
  htmlAttributes?: Omit<JSX.IntrinsicElements["textarea"], "ref">;
  data?: Record<string, string>;
  labelClassName?: string;
  textAreaClassName?: string;
}

export function AppFormTextArea<T extends FieldValues>(props: Props<T>) {
  const [id] = useState(UUID.generate().value);

  const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError();
  let srOnlyLabel: string | undefined;
  if (!props.label && props.htmlAttributes?.placeholder) {
    srOnlyLabel = props.htmlAttributes.placeholder;
  }

  const data = Utils.objectKeyMap(props.data ?? {}, (key) => {
    return `data-${key}`;
  });

  return (
    <p
      className={ClientUtils.twClassNames(
        "flex w-full flex-col gap-1",
        props.className
      )}
    >
      {props.label && (
        <label
          htmlFor={id}
          className={ClientUtils.twClassNames(
            props.boldLabel && "font-bold",
            props.labelClassName,
            !props.labelClassName && "text-sm"
          )}
        >
          {props.label}
        </label>
      )}
      {srOnlyLabel && (
        <label htmlFor={id} className="sr-only">
          {srOnlyLabel}
        </label>
      )}
      <textarea
        className={ClientUtils.twClassNames(
          "w-full resize-none rounded-sm border p-2 placeholder:text-sm placeholder:italic placeholder:text-black/60",
          props.htmlAttributes?.readOnly
            ? "border-form-border-disabled"
            : "border-form-border",
          props.textAreaClassName
        )}
        id={id}
        {...props.register(props.name, props.options)}
        {...props.htmlAttributes}
        {...data}
      ></textarea>
      {error && <span>{error}</span>}
    </p>
  );
}
