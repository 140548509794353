"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BankIdAuthResponse = void 0;
var zod_1 = require("zod");
var BankIdAuthResponse;
(function (BankIdAuthResponse) {
    BankIdAuthResponse.Schema = zod_1.z.object({
        orderRef: zod_1.z.string(),
        autoStartToken: zod_1.z.string(),
        qrStartToken: zod_1.z.string(),
        qrStartSecret: zod_1.z.string(),
    });
})(BankIdAuthResponse || (BankIdAuthResponse = {}));
exports.BankIdAuthResponse = BankIdAuthResponse;
