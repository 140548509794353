import { Utils } from "@eljouren/utils";
import ClientUtils from "../utils/ClientUtils";

interface Props {
  className?: string;
  quantity: number;
  price: number;
  preventBoldTotal?: boolean;
  label?: string;
  unit?: string;
  useVerbosePricing?: boolean;
}

const PriceCalculationComponent = (props: Props) => {
  const total = Utils.formatPrice(props.quantity * props.price, {
    useVerbosePricing: props.useVerbosePricing,
  });
  const unit = props.unit ?? "";

  return (
    <span
      className={ClientUtils.classNames(
        "grid grid-cols-[auto,minmax(0,1fr)] grid-rows-1 items-center gap-x-2"
        //showBoth && "border-b border-gray-200/50"
      )}
    >
      <span className="flex items-center gap-1">
        <span className="text-sm italic">
          {props.quantity}
          {unit}
        </span>
        <span className="">&times;</span>
        <span className="text-sm italic">{props.price.toFixed(2)}</span>
        <span>=</span>
        <span
          className={ClientUtils.classNames(
            "text-sm",
            !props.preventBoldTotal && "font-semibold"
          )}
        >
          {total}
        </span>
      </span>
      {!!props.label && <span className="text-xs">({props.label})</span>}
    </span>
  );
};

export default PriceCalculationComponent;
