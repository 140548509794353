import { isValid, parseJSON } from "date-fns";
import isEqual from "lodash.isequal";
import { useEffect, useState } from "react";
import {
  FieldValues,
  UseFormProps,
  UseFormReturn,
  useForm,
} from "react-hook-form";
import { TUseBundledStateReturn } from "./hooks";
/*
This files is essentially a duplicate of local-storage-hooks, with only chaning localstorage to sessionstorage.

These could be generalised, but not sure if it's worth the lack of flexibility
*/

type TFallback<T> = T | (() => T);
type UseLsOptions<T> = {
  decode?: (str: string) => T;
  encode?: (obj: T) => string;
};

type Default<T extends FieldValues> = UseFormProps<T>["defaultValues"];

export function useSsForm<T extends FieldValues>(
  key: string,
  props: UseFormProps<T> = {}
): UseFormReturn<T> {
  const form = useForm<T>({
    ...props,
    defaultValues: getSavedValues() ?? props.defaultValues,
  });

  function getSavedValues(): Default<T> | undefined {
    const saved = sessionStorage.getItem(key);
    if (saved !== null) {
      try {
        const parsed = JSON.parse(saved);
        return parsed as Default<T>;
      } catch (ex) {
        console.error("Error parsing saved values", ex);
        return undefined;
      }
    }
    return undefined;
  }

  const values = form.watch();
  const savedValues = getSavedValues();

  useEffect(() => {
    function saveValues(values: Default<T>): void {
      sessionStorage.setItem(key, JSON.stringify(values));
    }
    if (!isEqual(values, savedValues)) {
      saveValues(values as Default<T>);
    }
  }, [values, savedValues, key]);

  return form;
}

/*
sessionStorage
*/
export function useSsState<T>(
  key: string,
  fallback: TFallback<T>,
  options: UseLsOptions<T> = {}
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState<T>(initialValue());

  function initialValue(): T {
    const saved = sessionStorage.getItem(key);
    if (saved !== null) {
      if (options?.decode) {
        return options.decode(saved);
      } else {
        return saved as any;
      }
    }
    if (fallback instanceof Function) {
      return fallback();
    } else {
      return fallback;
    }
  }

  const save: React.Dispatch<React.SetStateAction<T>> = (
    value: T | ((prevValue: T) => T)
  ) => {
    const val = value instanceof Function ? value(state) : value;
    setState(val);
    if (options?.encode) {
      sessionStorage.setItem(key, options.encode(val));
    } else if (typeof val === "object") {
      sessionStorage.setItem(key, JSON.stringify(val));
    } else {
      sessionStorage.setItem(key, val as any);
    }
  };

  return [state, save];
}
export function useBundledSs<T>(
  key: string,
  fallback: T | (() => T),
  options: UseLsOptions<T> = {}
): TUseBundledStateReturn<T> {
  const [value, set] = useSsState(key, fallback, options);
  return {
    value,
    set,
  };
}

export function useBundledSsDate<T extends Date | null>(
  key: string,
  fallback: T | (() => T)
): TUseBundledStateReturn<T> {
  const bundle = useBundledSs<T>(key, fallback, {
    decode: (str) => {
      const date = parseJSON(str);
      if (isValid(date)) {
        return date as T;
      } else if (fallback instanceof Function) {
        return fallback();
      } else {
        return fallback;
      }
    },
  });

  return bundle;
}
