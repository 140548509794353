import React from "react";

interface BankIDLogoProps {
  size: number;
  //white?: boolean;
}

const SwishLogo: React.FC<BankIDLogoProps> = (props) => {
  return (
    <img
      style={{
        width: props.size,
        height: props.size,
      }}
      src={`external-logos/swish/swish.svg`}
      alt="Logga för Swish"
    />
  );
};

export default SwishLogo;
