import {
  IpisFileRelation,
  IpisFileV2,
  UploaderSource,
} from "@eljouren/file-schemas/build";
import ClientUtils from "./../../../../utils/ClientUtils";

function getTranslatedUploaderSource(source: UploaderSource.Type): string {
  switch (source) {
    case "staff":
      return "personal";
    case "worker":
      return "hantverkare";
    case "customer":
      return "kund";
  }
}

interface Props {
  className?: string;
  relation: IpisFileRelation.Type;
  file: IpisFileV2.Type;
}

const IpisFileRelationLabel = (props: Props) => {
  const relation = props.relation;

  const translatedUploaderSource = getTranslatedUploaderSource(
    props.file.uploadedBy
  );

  if (relation.type === "workOrder") {
    return (
      <span
        className={ClientUtils.twClassNames(
          props.className,
          !props.className && "text-sm"
        )}
      >
        Uppladdad till arbetsorder av {translatedUploaderSource}
      </span>
    );
  }

  /* 
    Should be a link to the work order line item
  */
  if (relation.type === "workOrderLineItem") {
    return (
      <span
        className={ClientUtils.twClassNames(
          props.className,
          !props.className && "text-sm"
        )}
      >
        Kopplad till {relation.name ?? "okänd produkt"}
      </span>
    );
  }

  if (relation.type === "opportunityLineItem") {
    return (
      <span
        className={ClientUtils.twClassNames(
          props.className,
          !props.className && "text-sm"
        )}
      >
        Kopplad till {relation.name ?? "faktura"}
      </span>
    );
  }

  if (relation.type === "serviceContract") {
    return (
      <span
        className={ClientUtils.twClassNames(
          props.className,
          !props.className && "text-sm"
        )}
      >
        Kopplad till avtalskontrakt
      </span>
    );
  }

  if (relation.type === "productPackage") {
    return (
      <span
        className={ClientUtils.twClassNames(
          props.className,
          !props.className && "text-sm"
        )}
      >
        Kopplad till produktpaket
      </span>
    );
  }

  return <></>;
};

export default IpisFileRelationLabel;
