import { IpisFormElement, FormElementAnswer } from "@eljouren/domain";
import { WizardFormElement } from "../../wizard-form/wizard-form-types";

export namespace WizardFormInference {
  export function inferQuestion<
    S extends string,
    Options extends string,
    T extends WizardFormElement & {
      id: S;
      options?: {
        value: Options;
      }[];
    }
  >(question: T): T {
    return question;
  }
  export function inferQuestionArray<
    S extends string,
    Options extends string,
    T extends WizardFormElement & {
      id: S;
      options?: {
        value: Options;
      }[];
    }
  >(questions: T[]): T[] {
    return questions;
  }

  export type Config = WizardFormElement[][];

  type UnionToIntersection<U> = (
    U extends any ? (k: U) => void : never
  ) extends (k: infer I) => void
    ? I
    : never;

  type ExtractQuestions<T extends Config> = UnionToIntersection<
    T[number][number] extends infer Q
      ? Q extends IpisFormElement.Type & {
          id: infer ID;
        }
        ? { [K in Extract<ID, string>]: Q }
        : never
      : never
  >;

  export type FormValues<C extends Config> =
    ExtractQuestions<C> extends infer Ext
      ? {
          [K in keyof Ext]: Ext[K] extends IpisFormElement.YesNoType
            ? FormElementAnswer.YesNoFormAnswerType
            : Ext[K] extends IpisFormElement.NumberInputType
            ? FormElementAnswer.NumberFormAnswerType
            : Ext[K] extends IpisFormElement.MultipleChoiceType
            ? Ext[K]["multiple"] extends true
              ? { value: Ext[K]["options"][number]["value"][] }
              : { value: Ext[K]["options"][number]["value"] }
            : Ext[K] extends IpisFormElement.RepeaterType
            ? FormElementAnswer.RepeaterQuestionFormAnswerType
            : Ext[K] extends IpisFormElement.TextInputType
            ? FormElementAnswer.TextFormAnswerType
            : Ext[K] extends IpisFormElement.TextAreaType
            ? FormElementAnswer.TextFormAnswerType
            : Ext[K] extends IpisFormElement.RichTextType
            ? FormElementAnswer.RichTextFormAnswerType
            : never;
        }
      : never;
}

export const WizardRequiredField = WizardFormInference.inferQuestion({
  typeOfQuestion: "yes/no",
  type: "question",
  id: "required",
  clientSideId: null,
  title: "Är detta en obligatorisk fråga?",
  walkthroughHeading: "Is this question required?",
  walkthroughSubheading: "This is optional and can be left blank.",
  renderAs: "toggle",
  required: true,
});

export const WizardTitleField = WizardFormInference.inferQuestion({
  typeOfQuestion: "text",
  type: "question",
  required: true,
  id: "title",
  clientSideId: null,
  title: "Titel",
  walkthroughHeading: "Skriv in en titel",
  walkthroughSubheading: () => {
    return (
      <p className="text-dark-gray/80">
        Var vänlig <strong>håll denna kort.</strong>
        Du kommer få chansen att ge mer detaljer i beskrivningen.
      </p>
    );
  },
  minLength: 5,
  maxLength: 150,
});

export const WizardDescriptionField = WizardFormInference.inferQuestion({
  typeOfQuestion: "rich-text",
  required: false,
  type: "question",
  id: "richTextBody",
  clientSideId: null,
  title: "Beskrivning",
  walkthroughHeading: "Enter description to your question.",
  walkthroughSubheading: () => {
    return (
      <p className="text-dark-gray/80">
        This is optional and can be left blank. Use{" "}
        <strong>Ctrl+B to bold text</strong>, <i>Ctrl+I to italicize text</i>,
        and <u>Ctrl+U to underline text</u>. You can also use the toolbar.
      </p>
    );
  },
});

export const WizardPlaceHolderField = WizardFormInference.inferQuestion({
  typeOfQuestion: "text",
  required: false,
  type: "question",
  id: "placeholder",
  clientSideId: null,
  title: "Platshållare",
  walkthroughHeading: "Enter placeholder",
  walkthroughSubheading:
    "This is the text the user will see in the input field before they start typing. Used as a hint to the user of what type of information is expected.",
  maxLength: 50,
});

export const WizardHelperTextField = WizardFormInference.inferQuestion({
  typeOfQuestion: "rich-text",
  required: false,
  type: "question",
  id: "helperText",
  clientSideId: null,
  title: "Tooltip",
  //optional: true,
  walkthroughHeading: "Enter helper text",
  walkthroughSubheading: "This is optional and can be left blank.",
});

export const WizardNoteField = WizardFormInference.inferQuestion({
  typeOfQuestion: "rich-text",
  required: false,
  type: "question",
  id: "note",
  clientSideId: null,
  title: "Viktig information",
  //optional: true,
  walkthroughHeading: "Enter note",
  walkthroughSubheading:
    "Some extra information that can help the user answer the question. Can be left blank. 'Note:' will be added to the beginning of the note and should not be added manually.",
});

export const WizardMinLengthField = WizardFormInference.inferQuestion({
  typeOfQuestion: "number",
  required: false,
  type: "question",
  id: "minLength",
  clientSideId: null,
  title: "Minst antal tecken",
  walkthroughHeading: "Enter minimum length",
  walkthroughSubheading: "This is optional and can be left blank.",
});

export const WizardMaxLengthField = WizardFormInference.inferQuestion({
  typeOfQuestion: "number",
  required: false,
  type: "question",
  id: "maxLength",
  clientSideId: null,
  title: "Max antal tecken",
  walkthroughHeading: "Enter maximum length",
  walkthroughSubheading:
    "This is optional and can be left blank. Will default to 500.",
});

export const WizardMinValueField = WizardFormInference.inferQuestion({
  typeOfQuestion: "number",
  required: false,
  type: "question",
  id: "min",
  clientSideId: null,
  title: "Minsta värde",
  walkthroughHeading: "Enter minimum value",
  walkthroughSubheading: "This is optional and can be left blank.",
});

export const WizardMaxValueField = WizardFormInference.inferQuestion({
  typeOfQuestion: "number",
  required: false,
  type: "question",
  id: "max",
  clientSideId: null,
  title: "Max värde",
  walkthroughHeading: "Enter maximum value",
  walkthroughSubheading:
    "This is optional and can be left blank. Will default to 100.",
});
