"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormElement = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var IpisFormConditionGroup_1 = require("./IpisFormConditionGroup");
var IpisFormElementOption_1 = require("./IpisFormElementOption");
var IpisFormElement;
(function (IpisFormElement) {
    IpisFormElement.BaseSchema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        clientSideId: centralized_zod_1.z.string().uuid().nullable(),
        type: centralized_zod_1.z.literal("question"),
        title: centralized_zod_1.z.string(),
        tooltip: centralized_zod_1.z.string().optional(),
        description: centralized_zod_1.z.string().optional(),
        note: centralized_zod_1.z.string().optional(),
        conditionGroups: IpisFormConditionGroup_1.IpisFormConditionGroup.QuestionSchema.array()
            .optional()
            .nullable(),
        required: centralized_zod_1.z.boolean(),
        hasAnswers: centralized_zod_1.z.boolean().optional(),
        isInactive: centralized_zod_1.z.boolean().optional(),
        slideUpElement: centralized_zod_1.z
            .object({
            prompt: centralized_zod_1.z.string(),
            content: centralized_zod_1.z.string(),
        })
            .optional()
            .nullable(),
    });
    IpisFormElement.TextInputSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("text"),
        placeholder: centralized_zod_1.z.string().optional(),
        minLength: centralized_zod_1.z.number().optional(),
        maxLength: centralized_zod_1.z.number().optional(),
    });
    IpisFormElement.NumberInputSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("number"),
        min: centralized_zod_1.z.number().optional(),
        max: centralized_zod_1.z.number().optional(),
    });
    IpisFormElement.TextAreaSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("textarea"),
        rows: centralized_zod_1.z.number(),
        placeholder: centralized_zod_1.z.string().optional(),
        minLength: centralized_zod_1.z.number().optional(),
        maxLength: centralized_zod_1.z.number().optional(),
    });
    /*
      This is currenly only allowed for internal use
    */
    IpisFormElement.RichTextSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("rich-text"),
        placeholder: centralized_zod_1.z.string().optional(),
    });
    IpisFormElement.YesNoSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("yes/no"),
        renderAs: centralized_zod_1.z.enum(["toggle", "buttons"]),
    });
    IpisFormElement.MultipleChoiceSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("multiple-choice"),
        renderAs: centralized_zod_1.z.enum(["always-visible", "dropdown"]),
        allowOther: centralized_zod_1.z.boolean(),
        multiple: centralized_zod_1.z.boolean(),
        options: IpisFormElementOption_1.IpisFormElementOption.MultipleChoiceSchema.array(),
    });
    IpisFormElement.ImageGroupSchema = IpisFormElement.BaseSchema.omit({
        //richTextBody: true,
        note: true,
        //helperText: true,
        title: true,
    }).extend({
        typeOfQuestion: centralized_zod_1.z.literal("image-group"),
        imagePrompts: IpisFormElementOption_1.IpisFormElementOption.ImagePromptSchema.array(),
    });
    IpisFormElement.InformationSchema = IpisFormElement.BaseSchema.omit({
        required: true,
    }).extend({
        /*
          This should rather have a different type instead of "question".
        */
        typeOfQuestion: centralized_zod_1.z.literal("information"),
        /*
          This doesn't have any effect, just to make it so that you can check if an element
          is required without first having to check if it's an information element.
        */
        required: centralized_zod_1.z.boolean().optional(),
    });
    IpisFormElement.RepeaterItemSchema = centralized_zod_1.z.union([
        IpisFormElement.TextInputSchema.omit({
            type: true,
            title: true,
            placeholder: true,
            conditionGroups: true,
        }).extend({
            type: centralized_zod_1.z.literal("repeater-item"),
            placeholder: centralized_zod_1.z.string(),
            conditionGroups: IpisFormConditionGroup_1.IpisFormConditionGroup.Schema.array().optional(),
        }),
        IpisFormElement.TextAreaSchema.omit({
            type: true,
            title: true,
            placeholder: true,
            conditionGroups: true,
        }).extend({
            type: centralized_zod_1.z.literal("repeater-item"),
            placeholder: centralized_zod_1.z.string(),
            conditionGroups: IpisFormConditionGroup_1.IpisFormConditionGroup.Schema.array().optional(),
        }),
        IpisFormElement.YesNoSchema.omit({ type: true, conditionGroups: true }).extend({
            type: centralized_zod_1.z.literal("repeater-item"),
            conditionGroups: IpisFormConditionGroup_1.IpisFormConditionGroup.Schema.array().optional(),
        }),
        IpisFormElement.MultipleChoiceSchema.omit({ type: true, conditionGroups: true }).extend({
            type: centralized_zod_1.z.literal("repeater-item"),
            conditionGroups: IpisFormConditionGroup_1.IpisFormConditionGroup.Schema.array().optional(),
        }),
        IpisFormElement.NumberInputSchema.omit({ type: true, conditionGroups: true }).extend({
            type: centralized_zod_1.z.literal("repeater-item"),
            conditionGroups: IpisFormConditionGroup_1.IpisFormConditionGroup.Schema.array().optional(),
        }),
    ]);
    IpisFormElement.RepeaterSchema = IpisFormElement.BaseSchema.extend({
        typeOfQuestion: centralized_zod_1.z.literal("repeater"),
        repeaterGroup: centralized_zod_1.z.object({
            name: centralized_zod_1.z.string(),
            items: IpisFormElement.RepeaterItemSchema.array(),
        }),
    });
    IpisFormElement.Schema = centralized_zod_1.z.union([
        IpisFormElement.TextInputSchema,
        IpisFormElement.TextAreaSchema,
        IpisFormElement.YesNoSchema,
        IpisFormElement.MultipleChoiceSchema,
        IpisFormElement.ImageGroupSchema,
        IpisFormElement.InformationSchema,
        IpisFormElement.NumberInputSchema,
        IpisFormElement.RepeaterSchema,
        IpisFormElement.RichTextSchema,
    ]);
})(IpisFormElement = exports.IpisFormElement || (exports.IpisFormElement = {}));
