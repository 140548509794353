import WorkingHoursForm from "../../../../components/forms/WorkingHoursForm";
import { TUseImmutableReturn } from "../../../../hooks/hooks";

interface Props {
  selectedDates: TUseImmutableReturn<Record<string, true>>;
  goBack?(): void;
}

const HandymanWorkingHoursFormSection = (props: Props) => {
  function onFormSubmitted() {
    props.selectedDates.override({});
    props.goBack && props.goBack();
  }

  return (
    <section className="flex grow flex-col">
      <main className="flex h-full grow  flex-col">
        <WorkingHoursForm
          goBack={props.goBack}
          dates={props.selectedDates.dict}
          onFormSubmitted={() => onFormSubmitted()}
        />
      </main>
    </section>
  );
};

export default HandymanWorkingHoursFormSection;
