import { IpisFormElement } from "@eljouren/domain";
import { motion } from "framer-motion";
import { UseFormReturn } from "react-hook-form";
import ClientUtils from "../../../../../utils/ClientUtils";
import { IpisTextArea } from "@ipis/client-essentials";

interface Props {
  className?: string;
  question: IpisFormElement.TextAreaType;
  form: UseFormReturn<any>;
  disabled?: boolean;
}

const ChecklistDecoupledTextAreaQuestion = (props: Props) => {
  const q = props.question;

  const maxLength = q.maxLength || 1000;

  return (
    <motion.div className={ClientUtils.classNames(props.className)}>
      <IpisTextArea
        id={q.id}
        name={`${q.id}.value`}
        required={q.required}
        label={q.title}
        tooltip={q.tooltip}
        richTextDescription={q.description}
        richTextNote={q.note}
        form={props.form}
        disabled={props.disabled}
        minLength={q.minLength}
        maxLength={maxLength}
        placeholder={q.placeholder}
        rows={q.rows}
      />
    </motion.div>
  );
};

export default ChecklistDecoupledTextAreaQuestion;
