import { MotionProps, motion } from "framer-motion";
import React, { useState } from "react";
import AppPages from "../../../../components/common/pages/AppPages";
import FormBreadcrumb from "../../../../components/forms/breadcrumb/FormBreadcrumb";
import ClientUtils from "../../../../utils/ClientUtils";
import InvoiceOverviewFormStep from "../form-steps/overview-form-step/InvoiceOverviewFormStep";
import InvoicePaymentFormStep from "../form-steps/payment-form-step/InvoicePaymentFormStep";

enum Page {
  overview,
  paymentOptions,
}

interface Props {
  className?: string;
  motionProps: MotionProps;
  style: React.CSSProperties;
}

const InvoiceFormView = (props: Props) => {
  const [page, setPage] = useState(Page.overview);

  function proceed() {
    setPage(Page.paymentOptions);
  }

  function goBack() {
    setPage(Page.overview);
  }
  return (
    <motion.section
      className={ClientUtils.twClassNames(
        "mx-auto grid w-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)] gap-4",
        props.className
      )}
      {...props.motionProps}
      style={props.style}
    >
      <header className="mx-auto w-full max-w-screen-lg">
        <FormBreadcrumb
          steps={["Sammanställning", "Välj betalsätt"]}
          atIndex={page}
          onActiveClick={setPage}
        />
      </header>
      <main className="grid w-full grid-cols-1 grid-rows-1 justify-center">
        <AppPages
          onlyMain
          mainGridRow={1}
          pageIndex={page}
          className="col-start-1 row-start-1 flex h-full w-full justify-center overflow-y-auto"
        >
          {page === Page.overview && (
            <InvoiceOverviewFormStep
              className="col-start-1 row-start-1 w-full"
              proceed={proceed}
            />
          )}
          {page === Page.paymentOptions && (
            <InvoicePaymentFormStep
              className="col-start-1 row-start-1 w-full"
              goBack={goBack}
            />
          )}
        </AppPages>
      </main>
    </motion.section>
  );
};

export default InvoiceFormView;
