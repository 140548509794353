import { ErrorLogUserGroup } from "../../routes/admin/ErrorLogsRoute";
import ErrorLogGroupTypeListItem from "./ErrorLogGroupTypeListItem";

interface Props {
  group: ErrorLogUserGroup;
}

const ErrorLogGroupListItem = (props: Props) => {
  function getHeading() {
    const info = props.group.signInInfo;
    if (!info || info.signedInAs === "none") {
      return "Unauthenticated";
    } else if (info.signedInAs === "worker") {
      return `Same handyman: ${info.userId}`;
    } else if (info.signedInAs === "customer") {
      return "Same customer";
    } else {
      return "Same staff login";
    }
  }

  return (
    <li className="flex flex-col gap-3 rounded border p-2">
      <h3 className="text-sm font-semibold">{getHeading()}</h3>
      <ul className="flex flex-col gap-1">
        {props.group.errorLogTypeGroups.map((typeGroup) => {
          return (
            <ErrorLogGroupTypeListItem
              key={typeGroup.id}
              typeGroup={typeGroup}
            />
          );
        })}
      </ul>
    </li>
  );
};

export default ErrorLogGroupListItem;
