import { z } from "@ipis/centralized-zod";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useKeyboardShortcuts } from "../../../hooks/keyboard-event-hooks";
import ClientUtils from "../../../utils/ClientUtils";

type As = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";

type EditProps = {
  width: number;
  height: number;
};

interface Props {
  className?: string;
  as: As;
  value: string;
  defaultValue?: string;
  minLength: number;
  maxLength: number;
  onSubmit?: (value: string) => void;
}

const EditableText = (props: Props) => {
  const textRef = useRef<any>(null);
  const [isEditing, setIsEditing] = useState<false | EditProps>(false);

  //On double click, make the text editable
  function handleDoubleClick() {
    if (!textRef.current) {
      return;
    }

    if (!props.onSubmit) {
      return;
    }

    const { width, height } = textRef.current.getBoundingClientRect();
    setIsEditing({ width, height });
  }

  if (isEditing) {
    let defaultValue: string;
    if (props.defaultValue !== undefined) {
      defaultValue = props.defaultValue;
    } else {
      defaultValue = props.value;
    }
    return (
      <Editor
        className={props.className}
        editProps={isEditing}
        defaultValue={defaultValue}
        onCancel={() => {
          setIsEditing(false);
        }}
        onSubmit={(value) => {
          setIsEditing(false);
          props.onSubmit?.(value);
        }}
        minLength={props.minLength}
        maxLength={props.maxLength}
      />
    );
  }

  const Tag = props.as;
  return (
    <Tag
      className={ClientUtils.classNames(
        "overflow-hidden break-words",
        !!props.onSubmit && "cursor-pointer",
        props.className
      )}
      onDoubleClick={handleDoubleClick}
      ref={textRef}
    >
      {props.value}
    </Tag>
  );
};

const Editor = (props: {
  className?: string;
  editProps: EditProps;
  defaultValue: string;
  onSubmit: (value: string) => void;
  onCancel: () => void;
  minLength: number;
  maxLength: number;
}) => {
  const form = useForm<{ value: string }>({
    defaultValues: {
      value: props.defaultValue,
    },
  });

  useEffect(
    () => {
      form.setFocus("value");
    },
    // eslint-disable-next-line
    []
  );

  function onSubmit() {
    const schema = z.string().min(props.minLength).max(props.maxLength);
    const raw = form.getValues().value;
    const safeParse = schema.safeParse(raw);
    if (!safeParse.success) {
      props.onCancel();
      return;
    }
    props.onSubmit(safeParse.data);
  }

  useKeyboardShortcuts({
    key: "Enter",
    preventDefault: true,
    callback: () => {
      onSubmit();
    },
  });

  const currentValue = form.watch("value");
  const tooShort = currentValue.length < props.minLength;
  const tooLong = currentValue.length > props.maxLength;

  return (
    <input
      {...form.register("value", {
        required: true,
        minLength: props.minLength,
        maxLength: props.maxLength,
      })}
      maxLength={props.maxLength}
      className={ClientUtils.twClassNames(
        "rounded outline",
        props.className,
        tooShort && "text-error outline-error",
        tooLong && "text-error outline-error"
      )}
      style={{
        minWidth: props.editProps.width,
        minHeight: props.editProps.height,
      }}
      defaultValue={props.defaultValue}
      onBlur={() => {
        onSubmit();
      }}
    />
  );
};

/* const Wrapper = (props: { children: React.ReactNode; as: As }) => {
  const { children, as } = props;
  const Tag = as;

  return <Tag className="flex flex-col gap-2">{children}</Tag>;
}; */

export default EditableText;
