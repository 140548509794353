import { DateHelper } from "@eljouren/utils";
import { isToday } from "date-fns";
import { useContext } from "react";
import Skeleton from "react-loading-skeleton";
import HandymanContext from "../../../../components/handyman-context/HandymanContext";
import WorkOrderIcon from "../../../../components/icons/WorkOrderIcon";
import { HandymanLandingPageContext } from "../HandymanRoute";
import ClientUtils from "./../../../../utils/ClientUtils";
import AppScheduleIcon from "../../../../components/icons/AppScheduleIcon";

interface Props {
  className?: string;
}

const LandingPageStatsWidget = (props: Props) => {
  const { isViewingOwnProfile, handyman } = useContext(
    HandymanLandingPageContext
  );

  let subject: string;
  if (isViewingOwnProfile) {
    subject = "Du";
  } else {
    subject = handyman.firstName;
  }

  return (
    <div
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2",
        props.className
      )}
    >
      <span className="flex items-center gap-4">
        <WorkOrderIcon
          size={40}
          className="rounded-full border bg-slate-100 p-2"
        />
        <WorkOrdersSpan subject={subject} />
      </span>
      <span className="flex items-center gap-4">
        <AppScheduleIcon
          size={40}
          className="rounded-full border bg-slate-100 p-2"
        />
        <WorkingHoursSpan subject={subject} />
      </span>
    </div>
  );
};

const WorkOrdersSpan = (props: { subject: string }) => {
  const ctx = useContext(HandymanContext);
  const todaysOrders = ctx.workOrderRes.data?.filter((wo) =>
    isToday(wo.startDate)
  );

  if (ctx.workOrderRes.isError) {
    return (
      <span className="text-sm text-red-400">
        Det gick inte att hämta arbetsordrar
      </span>
    );
  }

  if (ctx.workOrderRes.isLoading) {
    return (
      <span className="w-full max-w-[300px] text-sm">
        <Skeleton />
      </span>
    );
  }

  const length = todaysOrders?.length ?? 0;
  if (length === 0) {
    return (
      <span className="text-sm">
        {props.subject} har inga arbetsordrar idag
      </span>
    );
  }

  if (length === 1) {
    return (
      <span className="text-sm">
        {props.subject} har <b>1 arbetsorder</b> idag
      </span>
    );
  }

  return (
    <span className="text-sm">
      {props.subject} har <b>{length} arbetsordrar</b> idag
    </span>
  );
};

const WorkingHoursSpan = (props: { subject: string }) => {
  const ctx = useContext(HandymanContext);
  const today = new DateHelper().startOfDay.date.getTime();
  const todaysWorkingHours = ctx.workingHoursRes.data?.[today];

  if (ctx.workingHoursRes.isError) {
    return (
      <span className="text-sm text-red-400">
        Det gick inte att hämta arbetstider
      </span>
    );
  }

  if (ctx.workingHoursRes.isLoading) {
    return (
      <span className="w-full max-w-[300px] text-sm">
        <Skeleton />
      </span>
    );
  }

  if (!todaysWorkingHours) {
    return (
      <span className="text-sm">
        {props.subject} har inte rapporterat in arbetstider för idag
      </span>
    );
  }

  if (todaysWorkingHours.status === "reported") {
    const start = new DateHelper(todaysWorkingHours.start).timeInputFormat;
    const end = new DateHelper(todaysWorkingHours.end).timeInputFormat;
    return (
      <span className="text-sm">
        {props.subject} jobbar från{" "}
        <b>
          {start}-{end}
        </b>
      </span>
    );
  }

  return <span className="text-sm">{props.subject} är otillgänglig idag</span>;
};

export default LandingPageStatsWidget;
