import { AnimatePresence, motion } from "framer-motion";
import { useDelayRendering } from "../../../hooks/use-delay-rendering";
import ClientUtils from "../../../utils/ClientUtils";

interface Props {
  delayInMs: number;
  src: string;
  alt: string;
  className?: string;
  placeholderHeightInPx?: number;
  staggerIndex?: number;
  staggerDelay?: number;
}

const DelayedRenderImage = (props: Props) => {
  const index = props.staggerIndex ?? 0;
  const staggerDelay = props.staggerDelay ?? 0;
  const staggerAmount = index * staggerDelay;
  const shouldRender = useDelayRendering(props.delayInMs + staggerAmount);
  const { delayInMs, ...rest } = props;

  return (
    <div
      aria-live="polite"
      aria-label={
        shouldRender ? `Bild med beskrivning '${props.alt}'` : "Bild som laddas"
      }
      className={ClientUtils.classNames(
        "grid h-full w-full grid-cols-1 grid-rows-1",
        !shouldRender && "border border-dashed"
      )}
    >
      <AnimatePresence>
        {!shouldRender && (
          <motion.div
            key={props.alt + "-skeleton"}
            aria-hidden
            className={ClientUtils.classNames(
              "skeleton z-30 col-start-1 row-start-1 h-full w-full"
            )}
            style={
              props.placeholderHeightInPx
                ? {
                    height: `calc(${props.placeholderHeightInPx}px - 2px)`,
                  }
                : undefined
            }
            initial={{
              clipPath: "inset(0 0 0 0)",
            }}
            animate={{
              clipPath: "inset(0 0 0 0)",
            }}
            exit={{
              clipPath: "inset(0 0 0 100%)",
            }}
            transition={{
              duration: 0.4,
            }}
          />
        )}
        {shouldRender && (
          <motion.span
            key={props.alt + "-image"}
            className="col-start-1 row-start-1"
            initial={{
              clipPath: "inset(0 100% 0 0)",
              opacity: 0,
            }}
            animate={{
              clipPath: "inset(0 0% 0 0)",
              opacity: 1,
            }}
            transition={{
              duration: 0.4,
            }}
          >
            <motion.img
              {...rest}
              className={ClientUtils.classNames(props.className)}
            />
          </motion.span>
        )}
      </AnimatePresence>
    </div>
  );
};

export default DelayedRenderImage;
