import { IpisButton } from "@ipis/client-essentials";
import { motion } from "framer-motion";
import { useContext, useState } from "react";
import { useOptionalFormEditor } from "../../../../../../hooks/checklist-hooks";
import ClientUtils from "../../../../../../utils/ClientUtils";
import AppMenu, { MenuItem } from "../../../../../__v2__/menu/AppMenu";
import MyDialog from "../../../../../common/MyDialog";
import FormBuilderContext from "../../FormBuilderContext";
import { IpisForm } from "@eljouren/domain";
import NewFormPageForm from "../../input-builders/checklist/NewFormPageForm";

interface Props {
  setShowNewChecklistForm: (show: boolean) => void;
  isLoading: boolean;
}

const FormSelectionSidebar = (props: Props) => {
  const ctx = useContext(FormBuilderContext);
  const maybeFormEditor = useOptionalFormEditor();
  const currentForms = ctx.forms;
  const form = currentForms;

  // If weant to show deleted checklists before actually deleting them
  /* const deletedChecklists = currentChecklists.filter(
    (checklist) => checklist.isDeleted
  ); */

  const [showNewPageDialog, setShowNewPageDialog] = useState(false);

  const menuItems: MenuItem[] = form.map((_form) => {
    let form: IpisForm.ShellType;
    if (_form.id === maybeFormEditor?.form?.id) {
      form = maybeFormEditor!.form;
    } else {
      form = _form;
    }

    const pageItems: MenuItem[] = form.pages.map((page) => {
      let selected: boolean = false;
      if (maybeFormEditor?.page?.id) {
        selected = maybeFormEditor.page.id === page.id;
      }

      return {
        id: page.id,
        type: "button",
        selected,
        label: page.pageTitleShorthand,
        onContextMenu: [
          {
            id: page.id + "-remove",
            type: "button",
            label: "Ta bort sida",
            disabled: form.pages.length === 1,
            onClick: () => {
              if (form.pages.length === 1) {
                return;
              }
              maybeFormEditor?.controls.removePage({ pageId: page.id });
            },
          },
        ],
        onClick: () => {
          if (!maybeFormEditor) {
            return;
          }
          console.log("Setting page", { page });
          maybeFormEditor.setPage(page);
        },
      };
    });
    const menuItem: MenuItem = {
      id: form.id,
      type: "button",
      selected: maybeFormEditor?.form?.id === form.id,
      label: form.name,
      onClick: () => ctx.setSelectedForm(form),
      nestedMenu: {
        onReorder: (items: MenuItem[]) => {
          if (!maybeFormEditor) {
            return;
          }

          const ids = items
            .filter((item) => !!form.pages.find((page) => page.id === item.id))
            .map((item) => item.id);
          maybeFormEditor.controls.reorderPages({
            pages: ids.map((id) => {
              return form.pages.find((page) => page.id === id)!;
            }),
          });
        },
        items: [
          ...pageItems,
          {
            id: "new-page-button",
            type: "button",
            label: "Lägg till sida",
            icon: "plus",
            onClick: () => {
              setShowNewPageDialog(true);
            },
          },
        ],
      },
    };
    return menuItem;
  });

  /* menuItems.push(
    ...deletedChecklists.map((checklist): MenuItem => {
      return {
        id: checklist.id,
        type: "button",
        selected: ctx.selectedChecklist?.id === checklist.id,
        label: checklist.name,
        onClick: () => ctx.setSelectedChecklist(checklist),
        nestedMenu: {
          items: [
            {
              id: "remove-checklist",
              type: "button",
              label: "Bekräfta borttagning",
              onClick: () => {
                window.alert("Confirm deletion of checklist");
                
              },
            },
            {
              id: "restore-checklist",
              type: "button",
              label: "Återställ checklista",
              onClick: () => {
                window.alert("Restore checklist");
              },
            },
          ],
        },
      };
    })
  ); */

  return (
    <motion.aside
      className={ClientUtils.classNames(
        "grid h-full w-full grid-rows-[auto,minmax(0,1fr)] border-r-2 bg-dark-800",
        props.isLoading && "skeleton"
      )}
    >
      <motion.header className="flex items-center justify-between gap-2 p-2 text-light-background">
        <motion.h3 className="p-2 text-base">Formulär</motion.h3>
        <IpisButton
          label="Lägg till checklista"
          onlyIcon
          icon={{ type: "plus", position: "leading" }}
          variant="secondary-on-dark-background"
          onClick={() => props.setShowNewChecklistForm(true)}
        />
      </motion.header>
      <div className="overflow-auto">
        <AppMenu
          id="checklist-menu"
          variant="dark"
          menu={{
            // Add reorder functionality here
            items: menuItems,
          }}
        />
      </div>
      <MyDialog
        isOpen={showNewPageDialog}
        cancel={() => setShowNewPageDialog(false)}
      >
        <NewFormPageForm
          closeForm={() => setShowNewPageDialog(false)}
          checklist={maybeFormEditor?.form!}
        />
      </MyDialog>
    </motion.aside>
  );
};

export default FormSelectionSidebar;
