import {
  PaymentOption,
  WorkOrder,
  WorkOrderPaymentSummary,
  WorkOrderPaymentSyncResult,
} from "@eljouren/domain";
import React from "react";
import { FieldErrors } from "react-hook-form";
import { z } from "@ipis/centralized-zod";
import IWorkOrderPaymentRepo from "../../../_model/repos/interfaces/IWorkOrderPaymentRepo";
import { UseQueryWrapperResult } from "../../../hooks/use-query-wrapper";

/* 
  Obviously not good that this is hardcoded but I can't
  be bothered right now.
*/
export const InternalPaymentIdString = "IPIS_INTERNAL_INVOICE";
export const InternalPaymentId = z.literal(InternalPaymentIdString);

const BaseFormValues = z.object({
  simulatePayment: z.enum(["success", "fail", "inspection"]),
});

const InternalFormValues = BaseFormValues.extend({
  selectedOptionId: InternalPaymentId,
  invoiceEmail: z.string().email(),
});

const ExternalFormValues = BaseFormValues.extend({
  selectedOptionId: z.string().refine((val) => val !== InternalPaymentIdString),
  invoiceEmail: z.union([z.string().email().optional(), z.literal("")]),
});

export const PaymentOptionsFormSchema = z.union([
  InternalFormValues,
  ExternalFormValues,
]);

export type PaymentOptionsFormValues = z.infer<typeof PaymentOptionsFormSchema>;

export enum InvoiceRouteView {
  /* 
    Loading states
  */
  expectingAny = "expectingAny",
  expectingFailure = "expectingFailure",
  expectingSuccess = "expectingSuccess",
  /*
    When the expected state doesn't match the actual state
  */
  failedToConfirmFailure = "failedToConfirmFailure",
  failedToConfirmSuccess = "failedToConfirmSuccess",
  /*
    When the expected state matches the actual state
  */
  confirmedFailure = "confirmedFailure",
  confirmedSuccess = "confirmedSuccess",
  confirmedRefunded = "confirmedRefunded",
  confirmedCanceled = "confirmedCanceled",
  /* confirmedPending = "confirmedPending", */
  /* 
    The default form
  */
  paymentForm = "paymentForm",
  /* 
  Error
  */
  authenticationError = "authenticationError",
  failedToFetchRequiredData = "failedToFetchRequiredData",
  paymentFlowNotSupported = "paymentFlowNotSupported",
  workOrderNotReadyToPay = "workOrderNotReadyToPay",
  /* 
    When the deadline is within 1 hour
  */
  outOfTime = "outOfTime",
  /* 
  When the payment is stuck at ResursBank
  */
  inspectionRequired = "inspectionRequired",
  creditApproved = "creditApproved",
}

const CustomerWorkOrderInvoiceContext = React.createContext<{
  workOrderGuid: string;
  workOrder: WorkOrder.CustomerType | undefined;
  paymentSummary: WorkOrderPaymentSummary.Type | undefined;
  paymentInitRes: UseQueryWrapperResult<null | Awaited<
    ReturnType<IWorkOrderPaymentRepo["initialiseWithPaymentOptions"]>
  >>;
  syncRes: UseQueryWrapperResult<WorkOrderPaymentSyncResult.Type>;
  view: InvoiceRouteView;
  paymentOptions: PaymentOption.Type[];
  onSubmit: (values: PaymentOptionsFormValues) => Promise<void>;
  onError: (errors: FieldErrors<PaymentOptionsFormValues>) => void;
  isSubmitting: boolean;
  restartPaymentFlow: () => void;
  cancelPendingPayment: () => void;
  isCancelingPendingPayment: boolean;
}>({} as never);

export default CustomerWorkOrderInvoiceContext;
