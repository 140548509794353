import { IpisFormElement, IpisFormPage, IpisForm } from "@eljouren/domain";
import { UUID } from "@eljouren/utils";
import { Observable, ReadonlyObservable } from "@ipis/client-essentials";
import isEqual from "lodash.isequal";
import {
  AddFormElementEvent,
  AddFormPageEvent,
  EditFormElementEvent,
  FormBuilderEvent,
  MoveElementToAnotherPageEvent,
  RemoveFormElementEvent,
  RemoveFormPageEvent,
  RenameFormEvent,
  RenameFormPageEvent,
  ReorderFormElementsEvent,
  ReorderFormPagesEvent,
  ResetToLatestSnapshotEvent,
  SetConditionGroupsEvent,
  UninitializedFormBuilderEvent,
  UpdateFormNameEvent,
} from "./events/form-builder-events";
import EditFormElementEventSchema from "./events/EditFormElementEventSchema";

export type FormBuilderState = {
  form: IpisForm.ShellType;
};

export type FormBuilderStateHandlerSnapshot = {
  date: Date;
  state: FormBuilderState;
};

/* 
  There's a bit of confusion regarding mutability vs immutability in this class.

  The functions that handles a specific event both mutates the state and returns a new state.
  This will technically work, as the state passed to these functions are a copy, and thus the original state is not mutated.
  However, it's inconsistent to both mutate the state and return a new state. It would be better to choose one pattern and stick with it.
*/
export default class FormBuilderStateHandler {
  private initialState: FormBuilderState;
  private pastEvents: FormBuilderEvent[];
  private futureEvents: FormBuilderEvent[]; // For redo functionality
  private observable: Observable<FormBuilderState>;
  private _snapshots: FormBuilderStateHandlerSnapshot[] = [];

  constructor(initial: FormBuilderState) {
    this.initialState = initial;
    this.pastEvents = [];
    this.futureEvents = [];
    this.observable = new Observable(initial, {
      comparisonType: "alwaysNotify",
    });
    this.saveSnapshot();
  }

  private get state(): FormBuilderState {
    return this.observable.value;
  }

  public get form(): IpisForm.ShellType {
    return this.state.form;
  }

  public saveSnapshot() {
    const snapshot = {
      date: new Date(),
      state: this.state,
    };
    this._snapshots.push(snapshot);
  }

  public getLatestSnapshot(): FormBuilderStateHandlerSnapshot {
    return this._snapshots.at(-1)!;
  }

  private latestSnapshotOrInitialState(): FormBuilderState {
    if (this._snapshots.length > 0) {
      return this._snapshots.at(-1)!.state;
    }
    return this.initialState;
  }

  public isDirty(): boolean {
    const compareTo = this.latestSnapshotOrInitialState();
    const initialState = compareTo.form;
    const currentState = this.state.form;
    return !isEqual(initialState, currentState);
  }

  public getObservable(): ReadonlyObservable<FormBuilderState> {
    return this.observable.readonlyObs;
  }

  private copy(state: FormBuilderState): FormBuilderState {
    return structuredClone(state);
  }

  private buildEvent<T extends UninitializedFormBuilderEvent>(
    newEvent: T
  ): FormBuilderEvent & {
    discriminator: T["discriminator"];
  } {
    const id = UUID.generate().value;
    const date = new Date();
    const fullEvent: Record<string, any> = {
      id,
      date,
      checklistName: this.state.form.name,

      ...newEvent,
    };

    if ("pageId" in newEvent) {
      const page = this.findPageById(this.state.form, newEvent.pageId);
      fullEvent.pageTitleShorthand = page.pageTitleShorthand;
    }

    return fullEvent as FormBuilderEvent & {
      discriminator: T["discriminator"];
    };
  }

  public processEvent(event: UninitializedFormBuilderEvent) {
    const completedEvent = this.buildEvent(event);
    const newState = this._processEvent(completedEvent, this.observable.value);
    this.observable.set(newState);

    const lastEvent = this.peekPastEvents();
    if (event.discriminator === "reorderFormElementsEvent") {
      if (lastEvent?.discriminator === "reorderFormElementsEvent") {
        if (event.pageId === lastEvent.pageId) {
          this.pastEvents.pop();
        }
      }
    }

    if (event.discriminator === "reorderFormPagesEvent") {
      if (lastEvent?.discriminator === "reorderFormPagesEvent") {
        this.pastEvents.pop();
      }
    }

    this.pastEvents.push(completedEvent);
  }

  private _processEvent(
    event: UninitializedFormBuilderEvent,
    state: FormBuilderState
  ): FormBuilderState {
    const copy = this.copy(state);
    switch (event.discriminator) {
      case "updateFormNameEvent":
        return this.updateFormName(event, copy);
      case "addFormElementEvent":
        return this.addElement(event, copy);
      case "reorderFormElementsEvent":
        return this.reorderQuestions(event, copy);
      case "removeFormElementEvent":
        return this.removeElement(event, copy);
      case "moveElementToAnotherPageEvent":
        return this.moveElementToAnotherPage(event, copy);
      case "reorderFormPagesEvent":
        return this.reorderPages(event, copy);
      case "editFormElementEvent":
        return this.editElement(event, copy);
      case "renameFormPageEvent":
        return this.renameFormPage(event, copy);
      case "renameFormEvent":
        return this.renameForm(event, copy);
      case "removeFormPageEvent":
        return this.removePage(event, copy);
      case "addFormPageEvent":
        return this.addPage(event, copy);
      case "resetToLatestSnapshotEvent":
        return this.resetToLatestSnapshot(event, copy);
      case "setConditionGroupsEvent":
        return this.setConditionGroups(event, copy);
    }
  }

  public peekPastEvents(): FormBuilderEvent | undefined {
    return this.pastEvents.at(-1);
  }

  public peekFutureEvents(): FormBuilderEvent | undefined {
    return this.futureEvents.at(-1);
  }

  public undo() {
    if (this.pastEvents.length === 0) {
      return;
    }

    const eventToUndo = this.pastEvents.pop()!;
    this.futureEvents.push(eventToUndo);
    this.rebuildStateFromEvents();
  }

  public redo() {
    if (this.futureEvents.length === 0) {
      return;
    }

    const eventToRedo = this.futureEvents.pop()!;
    this.processEvent(eventToRedo);
  }

  private rebuildStateFromEvents(): void {
    let state = { ...this.initialState };
    this.pastEvents.forEach((event) => {
      state = this._processEvent(event, state);
    });
    this.observable.set(state);
  }

  public getLatestEvent(): FormBuilderEvent | null {
    if (this.pastEvents.length === 0) {
      return null;
    }
    return {
      ...this.pastEvents[this.pastEvents.length - 1],
    };
  }

  public getEvents(): FormBuilderEvent[] {
    return structuredClone(this.pastEvents);
  }

  private updateFormName(event: UpdateFormNameEvent, state: FormBuilderState) {
    const form = state.form;
    form.name = event.newName;
    return {
      ...state,
      form,
    };
  }

  private addElement(
    event: AddFormElementEvent,
    state: FormBuilderState
  ): FormBuilderState {
    const id = UUID.generate().value;
    const question: IpisFormElement.Type = {
      ...event.element,
      id,
      clientSideId: id,
    } as IpisFormElement.Type;

    const form = state.form;
    const page = form.pages.find((p) => p.id === event.pageId);

    if (!page) {
      throw new Error("Page not found");
    }

    page.elements.push(question);
    return {
      ...state,
      form,
    };
  }

  private reorderQuestions(
    event: ReorderFormElementsEvent,
    state: FormBuilderState
  ) {
    const form = state.form;
    const page = this.findPageById(form, event.pageId);
    const sortedElements = page.elements.sort((a, b) => {
      return (
        event.elementIdsInNewOrder.indexOf(a.id) -
        event.elementIdsInNewOrder.indexOf(b.id)
      );
    });

    page.elements = sortedElements;
    return {
      ...state,
      form,
    };
  }

  private removeElement(
    event: RemoveFormElementEvent,
    state: FormBuilderState
  ) {
    const form = state.form;
    const page = this.findPageById(form, event.pageId);
    page.elements = page.elements.filter((q) => q.id !== event.elementId);

    return {
      ...state,
      form,
    };
  }

  private moveElementToAnotherPage(
    event: MoveElementToAnotherPageEvent,
    state: FormBuilderState
  ) {
    const form = state.form;
    const fromPage = this.findPageById(form, event.fromPageId);
    const toPage = this.findPageById(form, event.toPageId);
    const element = this.findElementInArray(fromPage.elements, event.elementId);

    fromPage.elements = fromPage.elements.filter(
      (q) => q.id !== event.elementId
    );
    toPage.elements.push(element);

    return {
      ...state,
      form,
    };
  }

  private reorderPages(event: ReorderFormPagesEvent, state: FormBuilderState) {
    const form = state.form;
    const sortedPages = form.pages.sort((a, b) => {
      return (
        event.pageIdsInNewOrder.indexOf(a.id) -
        event.pageIdsInNewOrder.indexOf(b.id)
      );
    });

    form.pages = sortedPages;
    return {
      ...state,
      form,
    };
  }

  private editElement(
    _event: EditFormElementEvent,
    state: FormBuilderState
  ): FormBuilderState {
    const form = state.form;

    /* 
      This shouldn't be needed, but we need to make sure not to override the ids
      as they shouldn't be edited, and currently there's a bug in other parts of the code
      where these values are being sent in the event.
    */
    const safeParse = EditFormElementEventSchema.Schema.safeParse(_event);
    if (!safeParse.success) {
      console.warn("Failed to parse event", safeParse.error);
      return state;
    }
    const event = safeParse.data;

    form.pages = form.pages.map((p) => {
      return {
        ...p,
        elements: p.elements.map((q) => {
          if (q.id === event.elementId) {
            if (q.typeOfQuestion === "multiple-choice") {
              const values =
                EditFormElementEventSchema.MultipleChoiceSchema.parse(
                  event.newValues
                );
              const newOptions = values.options;
              const options: IpisFormElement.MultipleChoiceType["options"] = [];

              newOptions.forEach((option) => {
                const { id, clientSideId, ...rest } = option;
                const previous = q.options.find((prev) => {
                  const {
                    id: prevId,
                    clientSideId: prevClientSideId,
                    ...prevRest
                  } = prev;
                  return isEqual(prevRest, rest);
                });
                options.push(previous ?? option);
              });

              const editedQuestion: IpisFormElement.MultipleChoiceType = {
                ...q,
                ...values,
                options,
              };
              return editedQuestion;
            } else if (q.typeOfQuestion === "image-group") {
              const values = EditFormElementEventSchema.ImagePromptSchema.parse(
                event.newValues
              );
              const newPrompts = values.imagePrompts;
              const imagePrompts: IpisFormElement.ImageGroupType["imagePrompts"] =
                [];

              newPrompts.forEach((prompt) => {
                const { id, clientSideId, ...rest } = prompt;
                const previous = q.imagePrompts.find((prev) => {
                  const {
                    id: prevId,
                    clientSideId: prevClientSideId,
                    ...prevRest
                  } = prev;
                  return isEqual(prevRest, rest);
                });
                imagePrompts.push(previous ?? prompt);
              });

              const editedQuestion: IpisFormElement.ImageGroupType = {
                ...q,
                ...values,
                imagePrompts,
              };
              return editedQuestion;
            } else {
              const editedQuestion = {
                ...q,
                ...event.newValues,
              } as IpisFormElement.Type;
              return editedQuestion;
            }
          }
          return q;
        }),
      };
    });
    return {
      ...state,
      form,
    };
  }

  private renameFormPage(event: RenameFormPageEvent, state: FormBuilderState) {
    const form = state.form;
    const page = this.findPageById(form, event.pageId);
    page.pageTitle = event.newTitle ?? page.pageTitle;
    page.pageDescription = event.newDescription ?? page.pageDescription;
    page.pageTitleShorthand = event.newShorthand ?? page.pageTitleShorthand;
    page.preparations = event.newPreparations ?? page.preparations;

    return {
      ...state,
      form,
    };
  }

  private renameForm(event: RenameFormEvent, state: FormBuilderState) {
    const form = state.form;
    form.name = event.newName ?? form.name;
    return {
      ...state,
      form,
    };
  }

  private removePage(event: RemoveFormPageEvent, state: FormBuilderState) {
    const form = state.form;
    const currentPageCount = form.pages.length;

    if (currentPageCount === 1) {
      throw new Error("Cannot remove the last page");
    }

    form.pages = form.pages.filter((p) => p.id !== event.pageId);

    return {
      ...state,
      form,
    };
  }

  private addPage(event: AddFormPageEvent, state: FormBuilderState) {
    const form = state.form;
    const id = UUID.generate().value;
    form.pages.push({
      id,
      clientSideId: id,
      pageTitle: event.pageTitle,
      pageTitleShorthand: event.pageTitleShorthand,
      pageDescription: event.pageDescription,
      preparations: event.pagePreparations,
      elements: [],
    });
    return {
      ...state,
      form,
    };
  }

  private resetToLatestSnapshot(
    event: ResetToLatestSnapshotEvent,
    state: FormBuilderState
  ) {
    return {
      ...state,
      form: this.latestSnapshotOrInitialState().form,
    };
  }

  private setConditionGroups(
    event: SetConditionGroupsEvent,
    state: FormBuilderState
  ) {
    const form = state.form;
    const element = form.pages
      .flatMap((p) => p.elements)
      .find((q) => q.id === event.elementId);

    if (!element) {
      throw new Error("Element not found");
    }

    console.log({
      event,
    });

    element.conditionGroups = event.conditionGroups;

    return {
      ...state,
      form,
    };
  }

  /* 
    Util
  */

  private findPageById(
    form: IpisForm.ShellType,
    pageId: string
  ): IpisFormPage.WithoutAnswersType {
    const page = form.pages.find((p) => p.id === pageId);
    if (!page) {
      throw new Error("Page not found");
    }
    return page;
  }

  /* 
    Why some events include the pageId and some do not, I don't have a good answer for
  */
  /* private findElementById(
    form: IpisForm.ShellType,
    elementId: string
  ): FormElement.Type {
    const elements = form.pages.flatMap((p) => p.elements);
    const element = elements.find((q) => q.id === elementId);
    if (!element) {
      throw new Error("Element not found");
    }
    return element;
  }

  private findElementInPageById(
    form: IpisForm.ShellType,
    pageId: string,
    elementId: string
  ): FormElement.Type {
    const page = this.findPageById(form, pageId);
    const element = page.elements.find((q) => q.id === elementId);
    if (!element) {
      throw new Error("Element not found");
    }
    return element;
  } */

  private findElementInArray(
    elements: IpisFormElement.Type[],
    elementId: string
  ): IpisFormElement.Type {
    const element = elements.find((q) => q.id === elementId);
    if (!element) {
      throw new Error("Element not found");
    }
    return element;
  }
}
