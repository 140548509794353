import ClientUtils from "./../../../utils/ClientUtils";
import { useContext } from "react";
import LabelAndText from "../../../components/common/LabelAndText";

import CustomerWorkOrderOverviewContext from "./CustomerWorkOrderOverviewContext";
import CustomerWorkOrderContext from "../CustomerWorkOrderContext";

interface Props {
  className?: string;
}

const CustomerWorkOrderOverviewInvoiceAndHousingSection = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderOverviewContext);

  if (
    !ctx.workOrder.selectedInvoiceInformation &&
    !ctx.workOrder.selectedHousingInformation
  ) {
    return <></>;
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        "flex flex-col gap-4",
        props.className
      )}
    >
      <header>
        <h3 className="text-lg">Faktura och bostad</h3>
      </header>
      <main className="flex flex-col gap-4">
        <InvoiceFields {...props} />
        <HousingFields {...props} />
      </main>
    </section>
  );
};

const InvoiceFields = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const formCtx = useContext(CustomerWorkOrderOverviewContext);

  const { selectedInvoiceInformation } = formCtx.workOrder;
  if (
    !selectedInvoiceInformation ||
    ctx.workOrder.paymentDetails.externalPaymentFlowAllowed
  ) {
    return <></>;
  }

  const method = selectedInvoiceInformation.invoiceMethod;
  const isEmail = method === "email" && selectedInvoiceInformation.email;
  return (
    <>
      <LabelAndText
        id="invoiceMethod"
        label="Metod för faktura"
        text={isEmail ? "E-post" : "Post"}
      />
      {isEmail && (
        <LabelAndText
          id="invoiceEmail"
          label="E-post för faktura"
          text={selectedInvoiceInformation.email || ""}
        />
      )}
      {selectedInvoiceInformation.additionalInformation && (
        <LabelAndText
          id="invoiceInfo"
          label="Övrig faktureringsinformation"
          text={selectedInvoiceInformation.additionalInformation}
        />
      )}
    </>
  );
};

const HousingFields = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderOverviewContext);
  const { selectedHousingInformation } = ctx.workOrder;
  if (!selectedHousingInformation) {
    return <></>;
  }

  const housingType = selectedHousingInformation.housingType;

  switch (housingType) {
    case "condomonium":
      return (
        <>
          <LabelAndText
            id="housingType"
            label="Typ av bostad"
            text="Bostadsrätt"
          />
          {selectedHousingInformation.housingCooperativeOrgNo && (
            <LabelAndText
              id="housingCooperativeOrgNo"
              label="Org. nr för bostadsrättsförening"
              text={selectedHousingInformation.housingCooperativeOrgNo}
            />
          )}
          {selectedHousingInformation.apartmentNumber && (
            <LabelAndText
              id="apartmentNumber"
              label="Lägenhetsnummer"
              text={selectedHousingInformation.apartmentNumber}
            />
          )}
        </>
      );
    case "house":
      return (
        <>
          <LabelAndText id="housingType" label="Typ av bostad" text="Villa" />
          {selectedHousingInformation.propertyDesignation && (
            <LabelAndText
              id="propertyDesignation"
              label="Fastighetsbeteckning"
              text={selectedHousingInformation.propertyDesignation}
            />
          )}
        </>
      );
    case "rentalApartment":
      return (
        <>
          <LabelAndText
            id="housingType"
            label="Typ av bostad"
            text="Hyresrätt"
          />
        </>
      );
    case "insuranceMatter":
      return (
        <>
          <LabelAndText id="housingType" label="Försäkringsärende" text="Ja" />
        </>
      );
  }

  return <></>;
};

export default CustomerWorkOrderOverviewInvoiceAndHousingSection;
