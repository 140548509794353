import { isPast } from "date-fns";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useBundledLs } from "../../../hooks/local-storage-hooks";
import { useRepos } from "../../../hooks/use-repos";
import { DateHelper } from "@eljouren/utils";
import { AppLoader } from "../../common/loaders/AppLoader";
import HandymanWorkOrderListItem from "./HandymanWorkOrderListItem";
import { HandymanLandingPageContext } from "../../../routes/worker/landing/HandymanRoute";

interface Props {
  className?: string;
  onlyForToday?: boolean;
  goBackTo?: string;
  goBackToLabel?: string;
}

const HandymanWorkOrderListSection = (props: Props) => {
  const showPassedDates = useBundledLs<boolean>("showPassedDates", false, {
    decode: (str) => !!str,
  });

  const ctx = useContext(HandymanLandingPageContext);
  const { workOrderRepo } = useRepos();
  const todaysWorkOrders = useQuery(
    ["workOrders", "forToday", ctx.handyman.id],
    () => {
      return workOrderRepo.getForHandymanBetween({
        handymanId: ctx.handyman.id,
        interval: {
          start: new DateHelper().startOfDay.date,
          end: new DateHelper().endOfDay.date,
        },
      });
    }
  );

  const orders = todaysWorkOrders.data?.filter((order) => {
    if (!showPassedDates.value && isPast(order.endDate)) {
      return false;
    }
    return true;
  });

  return (
    <section className="flex flex-col gap-2">
      <header className="flex flex-col justify-between p-2 md:p-4">
        <h3 className="text-lg">Dagens arbetsordrar</h3>
        <p className="flex items-center gap-2">
          <label className="cursor-pointer" htmlFor="showPassedOrdersCheckbox">
            Visa passerade ordrar
          </label>
          <input
            className="cursor-pointer"
            type="checkbox"
            id="showPassedOrdersCheckbox"
            checked={showPassedDates.value}
            onChange={(e) => showPassedDates.set(e.target.checked)}
          ></input>
        </p>
      </header>
      <main>
        {todaysWorkOrders.isLoading && <AppLoader className="h-16 w-16" />}
        {todaysWorkOrders.isError && (
          <p className="p-2 text-red-600">
            Det gick inte att hämta arbetsordrarna just nu
          </p>
        )}
        {!todaysWorkOrders.isError &&
          !todaysWorkOrders.isLoading &&
          !todaysWorkOrders.data?.length && (
            <p className="p-2">Det finns inga arbetsordrar att visa</p>
          )}
        {!todaysWorkOrders.isError &&
          !todaysWorkOrders.isLoading &&
          !!todaysWorkOrders.data?.length &&
          !orders?.length && (
            <p className="p-2">
              Det finns inga arbetsordrar att visa som inte redan har passerats
            </p>
          )}
        {!todaysWorkOrders.isError && !!orders?.length && (
          <ul className="flex flex-col gap-2 md:p-2">
            {orders.map((order) => (
              <HandymanWorkOrderListItem
                key={order.orderId}
                order={order}
                goBackTo={props.goBackTo}
                goBackToLabel={props.goBackToLabel}
              />
            ))}
          </ul>
        )}
      </main>
    </section>
  );
};

export default HandymanWorkOrderListSection;
