"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileServerUploadMultipleFilesResponse = void 0;
var zod_1 = require("zod");
var FileServerUploadSingleFileResponse_1 = require("./FileServerUploadSingleFileResponse");
var FileServerUploadMultipleFilesResponse;
(function (FileServerUploadMultipleFilesResponse) {
    FileServerUploadMultipleFilesResponse.Schema = zod_1.z.object({
        results: zod_1.z
            .union([FileServerUploadSingleFileResponse_1.FileServerUploadSingleFileResponse.Schema, zod_1.z.literal(false)])
            .array(),
    });
})(FileServerUploadMultipleFilesResponse || (FileServerUploadMultipleFilesResponse = {}));
exports.FileServerUploadMultipleFilesResponse = FileServerUploadMultipleFilesResponse;
