import { IconBaseProps } from "react-icons/lib";
import { AiOutlineSave } from "react-icons/ai";
import ClientUtils from "../../utils/ClientUtils";

interface Props extends IconBaseProps {
  className?: string;
}

/* 
  HeroIcons doesn't have a save icon, so we use the save icon from react-icons temporarily
*/
const SaveIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <AiOutlineSave
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default SaveIcon;
