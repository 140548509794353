import { AriaAttributes } from "react";
import ClientUtils from "./../../utils/ClientUtils";
import { Link, LinkProps } from "react-router-dom";

export type MyLinkProps = Omit<LinkProps, "to"> & {
  to: string;
  state?: object;
  blue?: boolean;
  blank?: boolean;
  aria?: AriaAttributes;
};

const MyLink: React.FC<MyLinkProps> = (props) => {
  const { to, state, blue, blank, className, aria, ...rest } = props;
  const linkProps: LinkProps = {
    ...rest,
    to: {
      pathname: to,
    },
    state: {
      ...state,
      previousLocation: window.location.href,
    },
  };

  let blankProps = {};

  if (blank) {
    blankProps = {
      target: "_blank",
      rel: "noopener noreferrer",
    };
  }

  return (
    <Link
      {...linkProps}
      {...blankProps}
      {...props.aria}
      className={ClientUtils.twClassNames(
        props.className,
        props.blue && "text-brand-blue-600 "
      )}
    />
  );
};

export default MyLink;
