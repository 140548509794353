"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFormElementOption = void 0;
var zod_1 = require("zod");
var IpisFormElementOption;
(function (IpisFormElementOption) {
    IpisFormElementOption.MultipleChoiceSchema = zod_1.z.object({
        id: zod_1.z.string(),
        clientSideId: zod_1.z.string().uuid().nullable(),
        value: zod_1.z.string(),
    });
    IpisFormElementOption.ImagePromptSchema = zod_1.z.object({
        id: zod_1.z.string(),
        clientSideId: zod_1.z.string().uuid().nullable(),
        promptText: zod_1.z.string(),
        required: zod_1.z.boolean(),
    });
    IpisFormElementOption.Schema = zod_1.z.union([IpisFormElementOption.MultipleChoiceSchema, IpisFormElementOption.ImagePromptSchema]);
})(IpisFormElementOption = exports.IpisFormElementOption || (exports.IpisFormElementOption = {}));
