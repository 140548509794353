import { WorkOrder } from "@eljouren/domain";
import { DateHelper } from "@eljouren/utils";
import BankIDLogo from "../../../external-brands/bank-id/BankIdLogo";
import AppToolsIcon from "../../../icons/AppToolsIcon";

interface Props {
  workOrder: WorkOrder.CustomerType;
  className?: string;
}

/* 
	ToDo: Add section about prechecklists, when that is implemented
*/
const WhatHappensNextSection = (props: Props) => {
  return (
    <section className="flex flex-col gap-2">
      <header>
        <h3 className="text-base">Vad händer härnäst?</h3>
      </header>
      <ol className="flex flex-col gap-2">
        <li className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2 rounded bg-secondary-50 p-2 text-sm">
          <div className="p-1">
            <AppToolsIcon />
          </div>
          <h3 className="font-normal">
            Arbetsordern utförs som planerat vid{" "}
            <span className="text-dark-700">
              {new DateHelper(props.workOrder.startDate).readableDateTime}
            </span>
          </h3>
          <p className="col-start-2 pt-2 text-sm text-dark-700">
            Om allt går som det ska så kommer vår hantverkare att snabbt och
            effektivt utföra arbetet enligt plan.
          </p>
        </li>
        {props.workOrder.requiresMaterialToBeReviewed && (
          <li className="grid grid-cols-[auto,minmax(0,1fr)] items-center gap-x-2 rounded bg-secondary-50 p-2 text-sm">
            <BankIDLogo size={32} />
            <h3 className="font-normal">
              Godkänn eventuellt extramaterial via BankID
            </h3>
            <p className="col-start-2 pt-2 text-sm text-dark-700">
              Om det visar sig att vi behövs mer material för att slutföra
              arbetet så kommer vi att skicka en förfrågan om godkännande via
              BankID till dig via SMS för att försäkra att du är med på noterna.
            </p>
          </li>
        )}
      </ol>
    </section>
  );
};

export default WhatHappensNextSection;
