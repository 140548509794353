import { IpisForm, IpisFormBoundaries } from "@eljouren/domain";
import {
  IpisFormAnswerValidator,
  IpisFormTypeInference,
} from "@eljouren/domain-utils";
import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ClientUtils from "../../../../../../utils/ClientUtils";
import ChecklistV2Component from "../../../ChecklistV2Component";

const NewChecklistPageMetaChecklist = IpisFormTypeInference.inferForm({
  id: "new-checklist-page-meta",
  clientSideId: null,
  name: "Skapa ny sida",
  type: "internal-form",
  hasAnswers: false,
  isInactive: false,
  pages: [
    {
      id: "new-checklist-page-meta-page",
      clientSideId: null,
      pageTitleShorthand: "Meta",
      pageTitle: "Skapa ny sida",
      elements: [
        {
          id: "page-title-shorthand",
          clientSideId: null,
          type: "question",
          required: true,
          typeOfQuestion: "text",
          title: "Ett kort namn för sidan som syns i navigeringen",
          placeholder: "T.ex 'Installation'",
          minLength: IpisFormBoundaries.checklistPage.shorthandTitle.minLength,
          maxLength: IpisFormBoundaries.checklistPage.shorthandTitle.maxLength,
        },
        {
          id: "page-title",
          clientSideId: null,
          type: "question",
          required: true,
          typeOfQuestion: "text",
          title: "Vad ska sidan heta?",
          placeholder: "T.ex 'Detaljer Kring Installation'",
          minLength: IpisFormBoundaries.checklistPage.title.minLength,
          maxLength: IpisFormBoundaries.checklistPage.title.maxLength,
        },
        {
          id: "page-title-description",
          clientSideId: null,
          type: "question",
          required: false,
          typeOfQuestion: "text",
          title: "Ska sidan ha en beskrivning?",
          placeholder:
            "T.ex 'Här har du möjlighet att fylla i detaljer kring installationen'",
        },
        {
          id: "page-title-preparations",
          clientSideId: null,
          type: "question",
          required: false,
          typeOfQuestion: "text",
          title: "Förberedelser",
          placeholder:
            "T.ex 'Här kan det vara bra att ha ett måttband till hands",
        },
      ],
    },
  ],
});

interface Props {
  className?: string;
  closeForm: () => void;
  checklist: IpisForm.ShellType;
}

const NewFormPageForm = (props: Props) => {
  const editor = useFormEditor();

  function onSubmit(
    values: IpisFormAnswerValidator<typeof NewChecklistPageMetaChecklist>
  ) {
    editor.controls.addFormPage({
      pageTitle: values.getAnswer("page-title")?.value ?? "Okänd sida",
      pageTitleShorthand:
        values.getAnswer("page-title-shorthand")?.value ?? "Okänd sida",
      pageDescription: values.getAnswer("page-title-description")?.value,
      pagePreparations: values.getAnswer("page-title-preparations")?.value,
    });

    props.closeForm();
  }

  return (
    <section
      className={ClientUtils.classNames(
        "flex w-full max-w-screen-md flex-col gap-4 p-8",
        props.className
      )}
    >
      <ChecklistV2Component
        onSubmit={onSubmit}
        checklist={NewChecklistPageMetaChecklist}
      />
    </section>
  );
};

export default NewFormPageForm;
