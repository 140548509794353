"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisToggleInput = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var FormInputShell_1 = require("../components/FormInputShell");
var IpisToggle_1 = require("./IpisToggle");
var react_1 = require("react");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var IpisToggleInput = function (props) {
    var _a, _b;
    var name = props.name;
    var currentValue = (0, form_hooks_1.useFormValue)(props);
    var setValue = (_a = props.form) === null || _a === void 0 ? void 0 : _a.setValue;
    var onChange = (_b = props.controls) === null || _b === void 0 ? void 0 : _b.onChange;
    (0, react_1.useEffect)(function () {
        if (typeof currentValue !== "boolean") {
            if (setValue) {
                setValue(props.name, false);
            }
            else if (onChange) {
                onChange(false);
            }
        }
    }, [name, currentValue, setValue, props.name, onChange]);
    return ((0, jsx_runtime_1.jsx)(FormInputShell_1.FormInputShell, __assign({}, props, { placeChildren: "top-right", children: (0, jsx_runtime_1.jsx)(IpisToggle_1.IpisToggle, { label: props.label, name: props.name, form: props.form, controls: props.controls }) })));
};
exports.IpisToggleInput = IpisToggleInput;
