import { IpisFormElementIsolatedAnswer, IpisForm } from "@eljouren/domain";
import { IpisButton } from "@ipis/client-essentials";
import { motion } from "framer-motion";
import { useState } from "react";
import WhatHappensNextSection from "../../../__v2__/form-components/customer-forms/WhatHappensNextSection";
import AppCheckmarkIcon from "../../../icons/AppCheckmarkIcon";
import ChecklistAnswerSummary from "../ChecklistAnswerSummary";
import { PreChecklistWorkOrder } from "./PreChecklistComponent";

interface Props {
  currentFormVersion: IpisForm.ShellType;
  answers: IpisFormElementIsolatedAnswer.ClientSideType[];
  workOrder: PreChecklistWorkOrder;
  onFinished?: () => void;
}

const PreChecklistPostSummary = (props: Props) => {
  const [showAnswerSummary, setShowAnswerSummary] = useState(false);

  if (showAnswerSummary) {
    return (
      <ChecklistAnswerSummary
        currentFormVersion={props.currentFormVersion}
        answers={props.answers}
        goBack={() => setShowAnswerSummary(false)}
        className="mx-auto max-w-screen-md"
      />
    );
  }

  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="mx-auto flex h-full w-full max-w-screen-md flex-col gap-8 p-4 pb-8 pt-8"
    >
      <header className="grid grid-cols-[minmax(0,1fr),auto] gap-2 border-b-2 border-border-color pb-6 text-dark-950">
        <h2 className="text-2xl font-bold text-dark-950">
          Tack så mycket för informationen
        </h2>
        <AppCheckmarkIcon
          size="lg"
          variant="circle"
          className="fill-success text-light-background"
        />
        <p className="col-span-2 text-sm">
          Vi har tagit emot alla detaljer vi behöver för att kunna hjälpa dig på
          bästa sätt.
        </p>
        <button
          type="button"
          className="flex text-sm underline"
          onClick={() => setShowAnswerSummary(!showAnswerSummary)}
        >
          Visa detaljer
        </button>
      </header>
      <WhatHappensNextSection workOrder={props.workOrder} />
      {!!props.onFinished && (
        <footer className="mt-auto flex flex-col">
          <IpisButton label="Slutför" onClick={props.onFinished} />
        </footer>
      )}
    </motion.section>
  );
};

export default PreChecklistPostSummary;
