"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormEditableAddressFieldset = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var editable_address_hooks_1 = require("./editable-address-hooks");
var IpisTextInputCompact_1 = require("../../form-inputs/text-input/IpisTextInputCompact");
var client_utils_1 = require("../../../../utils/client-utils");
var FormEditableAddressFieldset = function (props) {
    function getName(key) {
        var _a, _b;
        var mappedKey = (_b = (_a = props.fieldMapping) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : key;
        if (props.registerNested) {
            return "".concat(props.registerNested, ".").concat(mappedKey);
        }
        else {
            return mappedKey;
        }
    }
    var a = (0, editable_address_hooks_1.useAddress)(props);
    function formatAddress() {
        var parts = [];
        if (a.street) {
            parts.push(a.street);
        }
        if (a.city) {
            parts.push(a.city);
        }
        if (a.country) {
            parts.push(a.country);
        }
        return parts.join(", ");
    }
    var showEdit = props.isEditing;
    if (showEdit) {
        return ((0, jsx_runtime_1.jsxs)("fieldset", { className: (0, client_utils_1.classNames)("grid w-full grid-cols-[4fr,3fr,3fr] gap-x-2 gap-y-4 overflow-auto rounded pt-2", props.className), children: [(0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: "street", label: "Gata", form: props.form, name: getName("street"), className: "col-span-3" }), (0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: "city", label: "Stad", form: props.form, name: getName("city") }), (0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: "postalCode", label: "Postnummer", form: props.form, name: getName("postalCode") }), (0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: "country", label: "Land", form: props.form, name: getName("country") }), (0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: false, id: "doorCode", label: "Portkod", form: props.form, name: getName("doorCode") }), (0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: false, id: "floor", label: "V\u00E5ning", form: props.form, name: getName("floor") })] }));
    }
    return ((0, jsx_runtime_1.jsxs)("fieldset", { className: (0, client_utils_1.classNames)("rounded border border-border-color px-4 pb-4 pt-2", props.className), children: [(0, jsx_runtime_1.jsx)("legend", { className: "px-2", children: props.legend }), (0, jsx_runtime_1.jsx)("input", { readOnly: true, "aria-label": "Adress", value: formatAddress(), className: "w-full", tabIndex: -1 }), a.postalCode && ((0, jsx_runtime_1.jsxs)("div", { className: "flex items-center gap-1", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "".concat(props.id, "-postal-code"), children: "Postnummer:" }), (0, jsx_runtime_1.jsx)("input", { id: "".concat(props.id, "-postal-code"), readOnly: true, "aria-label": "Postnummer", value: a.postalCode, tabIndex: -1 })] })), a.floor && ((0, jsx_runtime_1.jsxs)("div", { className: "flex items-center gap-1", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "".concat(props.id, "-floor"), children: "V\u00E5ning:" }), (0, jsx_runtime_1.jsx)("input", { id: "".concat(props.id, "-floor"), readOnly: true, "aria-label": "V\u00E5ning", value: a.floor, tabIndex: -1 })] })), a.doorCode && ((0, jsx_runtime_1.jsxs)("div", { className: "flex items-center gap-1", children: [(0, jsx_runtime_1.jsx)("label", { htmlFor: "".concat(props.id, "-door-code"), children: "Portkod:" }), (0, jsx_runtime_1.jsx)("input", { id: "".concat(props.id, "-door-code"), readOnly: true, "aria-label": "Portkod", value: a.doorCode, tabIndex: -1 })] }))] }));
};
exports.FormEditableAddressFieldset = FormEditableAddressFieldset;
