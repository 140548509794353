import ClientUtils from "./../../../../utils/ClientUtils";
import { useContext } from "react";
import { Controller } from "react-hook-form";

import CustomerWorkOrderContext from "../../CustomerWorkOrderContext";
import PaymentAndHousingFormContext from "./PaymentAndHousingFormContext";

interface Props {
  className?: string;
}

const InsuranceMatterFormSection = (props: Props) => {
  const workOrderCtx = useContext(CustomerWorkOrderContext);
  const formCtx = useContext(PaymentAndHousingFormContext);
  const housingType = formCtx.form.getValues("housing.housingType");

  return (
    <section
      className={ClientUtils.twClassNames(
        props.className,
        "flex flex-col gap-2"
      )}
    >
      <header className="border-b pb-2">
        <h2 className="text-lg sm:text-xl">Försäkringsärende</h2>
      </header>
      <main className="flex flex-col gap-2">
        <Controller
          name="housing.housingType"
          control={formCtx.form.control}
          render={(props) => {
            const { onChange, ...rest } = props.field;
            return (
              <p className="flex items-center gap-2">
                <label
                  className="cursor-pointer"
                  htmlFor="insuranceMatterCheckbox"
                >
                  Är ditt arbete ett försäkringsarbete?
                </label>
                <input
                  id="insuranceMatterCheckbox"
                  className="h-6 w-6 cursor-pointer"
                  type="checkbox"
                  checked={housingType === "insuranceMatter"}
                  {...rest}
                  onChange={(e) => {
                    if (e.target.checked) {
                      formCtx.form.setValue(
                        "customerHasActivelyChosenToSkipDiscount",
                        true
                      );
                      onChange("insuranceMatter");
                    } else {
                      onChange("house");
                    }
                  }}
                />
              </p>
            );
          }}
        />
        {!workOrderCtx.workOrder.isBusinessCustomer && (
          <p>
            Försäkringsbolagen tar ej emot fakturor med rotavdrag. Om ditt
            arbete är ett försäkringsarbete klicka i rutan nedan och hoppa över
            delen om rotavdrag under.
          </p>
        )}
      </main>
    </section>
  );
};

export default InsuranceMatterFormSection;
