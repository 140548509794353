import ClientUtils from './../../../utils/ClientUtils';
interface Props {
  className?: string;
  useShadow?: boolean;
  onlyIcon?: boolean;
  center?: boolean;
  withColor?: boolean;
}

const RorjourenLogo = (props: Props) => {
  return (
    <img
      className={ClientUtils.twClassNames(
        "rounded",
        props.center && "mx-auto",
        !props.className && "h-full",
        props.className
      )}
      src={
        props.withColor
          ? "/logos/rorjour-logo-color.png"
          : "/logos/rorjour-logo.png"
      }
      alt="Logga för Rörjour 24/7"
      data-testid="rorjouren-logo"
    />
  );
};

export default RorjourenLogo;
