import ClientUtils from "../utils/ClientUtils";
import GoBackButton from "./GoBackButton";
import { AppButton } from "./common/buttons/AppButton";
import SaveIcon from "./icons/SaveIcon";

interface Props {
  className?: string;
  onSave?(): void;
  isLoading?: boolean;
  isDisabled?: boolean;
  onGoBack(): void;
  readonly?: boolean;
}

const SaveAndGoBackFooter = (props: Props) => {
  const showSave = !props.readonly && !!props.onSave;

  return (
    <footer
      className={ClientUtils.classNames(
        "flex items-center justify-between gap-2 border-t bg-gradient-to-br from-slate-100/50 to-slate-200/50 p-2 sm:grid sm:grid-cols-2",
        props.className
      )}
    >
      <GoBackButton
        onClick={props.onGoBack}
        className={ClientUtils.classNames("grow", !showSave && "sm:col-span-2")}
      />
      {showSave && (
        <AppButton
          className="grow"
          requiresNetworkConnection
          disabled={props.isDisabled}
          loading={props.isLoading}
          type="submit"
          contentClassName="gap-2"
        >
          <SaveIcon size={20} />
          <span>Spara</span>
        </AppButton>
      )}
    </footer>
  );
};

export default SaveAndGoBackFooter;
