import ClientUtils from "../../../../../utils/ClientUtils";
import PaymentMethodForm from "../../payment-method-components/PaymentMethodForm";

interface Props {
  goBack: () => void;
  className?: string;
}

const InvoicePaymentFormStep = (props: Props) => {
  return (
    <PaymentMethodForm
      goBack={props.goBack}
      className={ClientUtils.classNames(
        "max-w-xl gap-16 pb-8",
        props.className
      )}
    >
      {/*  <header
        className={ClientUtils.classNames(
          "flex flex-col items-center gap-4 transition-all"
        )}
      >
        <h2 className="text-base sm:text-xl md:text-2xl">
          Du väljer själv hur du vill betala!
        </h2>
        <p className="max-w-lg text-sm font-semibold md:text-center md:text-base">
          Du kan välja att dela upp din betalning, betala direkt via din bank
          eller få en faktura med upp till 60 dagars betalningstid. Oavsett
          vilket alternativ du väljer, är det alltid räntefritt hos oss.
        </p>
      </header> */}
    </PaymentMethodForm>
  );
};

export default InvoicePaymentFormStep;
