import ClientUtils from './../../utils/ClientUtils';
import { BiTask } from "react-icons/bi";
import { IconBaseProps } from "react-icons/lib";

interface Props extends IconBaseProps {
  className?: string;
}

const WorkOrderIcon = (props: Props) => {
  const { className, ...rest } = props;

  return (
    <BiTask
      {...rest}
      className={ClientUtils.twClassNames("", props.className)}
    />
  );
};
export default WorkOrderIcon;
