import { useContext, useState } from "react";
import MyDialog from "../../../../components/common/MyDialog";
import { AppButton } from "../../../../components/common/buttons/AppButton";
import ClientUtils from "../../../../utils/ClientUtils";
import CustomerWorkOrderInvoiceContext from "../CustomerWorkOrderInvoiceContext";
import PaymentFlowCustomerServiceModal from "./PaymentFlowCustomerServiceModal";

interface Props {
  className?: string;
  mainActionLabel: string;
  mainActionCallback: (() => void) | "restart";
}

const InvoiceViewCustomerServiceFooter = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);
  const [showModal, setShowModal] = useState(false);

  function toggleModal() {
    setShowModal(!showModal);
  }

  function closeModal() {
    setShowModal(false);
  }

  function onClick() {
    if (props.mainActionCallback === "restart") {
      ctx.restartPaymentFlow();
    } else {
      props.mainActionCallback();
    }
  }

  let showActionButton = true;
  const savedStatus = ctx.syncRes.data?.savedStatus;
  if (
    props.mainActionCallback === "restart" &&
    !savedStatus?.canStartProcessFromBeginning
  ) {
    showActionButton = false;
  }

  return (
    <footer
      className={ClientUtils.classNames(
        "grid grid-cols-1 items-end gap-2 pb-4 pt-8 sm:grid-cols-2 sm:pt-8",
        props.className
      )}
    >
      <AppButton
        className="w-full"
        onClick={toggleModal}
        variant="outline-default"
      >
        Kontakta kundservice
      </AppButton>
      {showActionButton && (
        <AppButton className="w-full" onClick={onClick}>
          {props.mainActionLabel}
        </AppButton>
      )}
      <MyDialog isOpen={showModal} cancel={closeModal}>
        <PaymentFlowCustomerServiceModal close={closeModal} />
      </MyDialog>
    </footer>
  );
};

export default InvoiceViewCustomerServiceFooter;
