"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderBrand = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderBrand;
(function (WorkOrderBrand) {
    WorkOrderBrand.Schema = centralized_zod_1.z.enum([
        "Svenska Eljouren",
        "Rörmokare.nu",
        "Rörjour 247",
        "IPIS Contract",
        "IPIS",
    ]);
})(WorkOrderBrand || (WorkOrderBrand = {}));
exports.WorkOrderBrand = WorkOrderBrand;
