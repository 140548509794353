export type MarginOptions = {
  m?: string;
  mt?: string;
  mr?: string;
  mb?: string;
  ml?: string;
  mx?: string;
  my?: string;
};

export function useMargin(options?: MarginOptions): React.CSSProperties {
  const { m, mt, mr, mb, ml, mx, my } = options ?? {};

  return {
    margin: m,
    marginTop: mt ?? my,
    marginRight: mr ?? mx,
    marginBottom: mb ?? my,
    marginLeft: ml ?? mx,
  };
}
