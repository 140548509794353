import { Handyman } from "@eljouren/domain/build";
import { useLocation } from "react-router-dom";
import AppArrowIcon from "../../../components/icons/AppArrowIcon";
import MyLink from "../../../components/routing/MyLink";
import AppRoutes from "../../../routes";

interface Props {
  className?: string;
  handyman: Handyman.Type;
}

const SalesTeamPartnerListItem = (props: Props) => {
  const location = useLocation();
  const params = new URLSearchParams({
    sida: "kalender",
    goBackTo: location.pathname + location.search,
  });

  return (
    <li
      className="flex w-full cursor-pointer items-center justify-between rounded border bg-bg-base-layer transition-all hover:bg-main-bg-light/10"
      key={props.handyman.id}
    >
      <MyLink
        to={AppRoutes.salesTeam.singlePartner(props.handyman.id) + `?${params}`}
        className="grid w-full grid-cols-[minmax(0,1fr),auto] items-center justify-between gap-x-2 gap-y-1 p-2"
      >
        <span className="text-xl font-semibold">
          {props.handyman.firstName} {props.handyman.lastName}
        </span>
        <span className="text-lg">{props.handyman.companyName}</span>
        <AppArrowIcon
          direction="right"
          size={24}
          className="col-start-2 row-span-3 row-start-1 "
        />
      </MyLink>
    </li>
  );
};

export default SalesTeamPartnerListItem;
