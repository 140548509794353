import { useContext, useState } from "react";
import HandymanWorkOrderRouteContext from "../../../../../../routes/worker/order/contexts/HandymanWorkOrderRouteContext";
import ClientUtils from "../../../../../../utils/ClientUtils";
import SaveAndGoBackFooter from "../../../../../SaveAndGoBackFooter";
import { AppButton } from "../../../../../common/buttons/AppButton";
import { AppLoader } from "../../../../../common/loaders/AppLoader";
import { useAddToBackButtonStack } from "../../../../../common/navigation/use-back-button-stack";
import AppEditIcon from "../../../../../icons/AppEditIcon";
import AppMaximizeIcon from "../../../../../icons/AppMaximizeIcon";
import AppPlusIcon from "../../../../../icons/AppPlusIcon";
import AppRefreshIcon from "../../../../../icons/AppRefreshIcon";
import HandymanLineItemContext from "../../../line-items/HandymanLineItemContext";
import HandymanExpandedLineItemListSection from "../components/HandymanExpandedLineItemList";
import HandymanLineItemList from "../components/HandymanLineItemList";
import ExtraMaterialQuickAddSection from "./ExtraMaterialQuickAddSection";
import SearchAndManualMaterialSection from "./SearchAndManualMaterialModal";
import WorkOrderMaterialReviewSection from "../WorkOrderMaterialReviewSection";
import AppCustomerSignIcon from "../../../../../icons/AppCustomerSignIcon";
import { AnimatePresence, motion } from "framer-motion";

interface Props {
  className?: string;
  goBack(): void;
}

const WorkOrderLineItemsSection = (props: Props) => {
  const routeCtx = useContext(HandymanWorkOrderRouteContext);
  const order = routeCtx.order;
  const lineItemCtx = useContext(HandymanLineItemContext);
  const lineItemRes = lineItemCtx.lineItemRes;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showMaterialReviewModal, setShowMaterialReviewModal] = useState(false);

  useAddToBackButtonStack(props.goBack);

  function reload() {
    lineItemRes.query.refetch();
  }

  const showSearchAndManual =
    !order.isFinished &&
    order.lineItemPermissions.canUseFreeSearchAndCustomMaterial;

  const quickAddProducts = lineItemCtx.quickAddRes.data ?? [];

  const showQuickAdd = !order.isFinished && !!quickAddProducts.length;

  const hasMaterial = lineItemRes.query.data?.length;
  const canEdit = lineItemRes.query.data?.some((el) => {
    return (
      el.permissions.canBeCanceled ||
      el.permissions.canBeRemoved ||
      el.permissions.canEditQuantity
    );
  });

  const showEditButton = hasMaterial || canEdit;
  const showMaterialReviewButton =
    routeCtx.enableMaterialReview && order.requiresMaterialToBeReviewed;

  const showButtonSection =
    showEditButton || showSearchAndManual || showMaterialReviewButton;

  let rowCount = 1;
  if (showQuickAdd) {
    rowCount++;
  }

  if (showButtonSection) {
    rowCount++;
  }

  return (
    <section className="relative grid h-full grid-cols-1 grid-rows-[minmax(0,1fr),auto]">
      <main
        className={ClientUtils.classNames(
          "grid h-full grid-cols-1 overflow-y-auto px-2 pb-2 xs:px-4",
          rowCount === 2 && "grid-rows-[auto,minmax(0,1fr)]",
          rowCount === 3 && "grid-rows-[auto,auto,minmax(0,1fr)]"
        )}
      >
        {!!showQuickAdd && <ExtraMaterialQuickAddSection className=" py-4" />}

        {showButtonSection && (
          <section className="flex flex-col gap-2 py-2">
            {showEditButton && (
              <AppButton onClick={() => setShowEditModal(true)}>
                <span>{canEdit ? "Redigera orderrader" : "Visa detaljer"}</span>
                {canEdit ? <AppEditIcon /> : <AppMaximizeIcon />}
              </AppButton>
            )}
            {showSearchAndManual && (
              <AppButton onClick={() => setShowAddModal(true)}>
                <span>Lägg till material</span>
                <AppPlusIcon />
              </AppButton>
            )}
            {showMaterialReviewButton && (
              <AppButton
                onClick={() => setShowMaterialReviewModal(true)}
                disabled={
                  !lineItemCtx.getWithApprovalState("pending", "notApproved")
                    .exists || order.isFinished
                }
              >
                <span>Få godkänt av kund</span>
                <AppCustomerSignIcon />
              </AppButton>
            )}
          </section>
        )}

        <section className="grid h-full grid-cols-1 grid-rows-[auto,minmax(0,1fr),auto] gap-2 rounded-b-lg">
          <header className="grid grid-cols-[minmax(0,1fr),auto] items-center justify-between gap-x-2 border-b py-4 pb-1">
            <h4 className="text-sm">Tillagda orderrader</h4>
            <button onClick={reload} className="row-span-2 my-auto">
              <AppRefreshIcon size={24} className="text-blue-600" />
            </button>
            {routeCtx.enableMaterialReview && !!routeCtx.order.assignedTo && (
              <p className="text-sm">
                Priserna nedan visar ersättning för{" "}
                {routeCtx.order.assignedTo.companyName} och ska inte delas med
                kund.
              </p>
            )}
          </header>
          <main className="flex h-full w-full flex-col gap-2 rounded">
            {lineItemRes.query.data && !!lineItemRes.query.data.length && (
              <>
                <HandymanLineItemList
                  data={lineItemRes.query.data}
                  isLoading={lineItemRes.isLoading}
                />
              </>
            )}
            {!lineItemRes.query.isError &&
              !lineItemRes.isLoading &&
              (!lineItemRes.query.data || !lineItemRes.query.data.length) && (
                <p>Inget material inrapporterat</p>
              )}
            {lineItemRes.isLoading && !lineItemRes.query.data && <AppLoader />}
            {lineItemRes.query.isError && !lineItemRes.isLoading && (
              <p>Det gick inte att hämta material just nu</p>
            )}
          </main>
        </section>
      </main>
      <SaveAndGoBackFooter onGoBack={props.goBack} />
      {/* 
        The data check should probably not be used here, instead show a loader or something.
      */}
      <AnimatePresence>
        {showEditModal && lineItemRes.query.data && (
          <motion.aside
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute z-50 grid h-full w-full grid-rows-1 gap-2 bg-off-white/80 p-2 backdrop-blur"
          >
            <main className="h-full w-full overflow-auto">
              <HandymanExpandedLineItemListSection
                data={lineItemRes.query.data}
                isLoading={lineItemRes.isLoading}
                onClose={() => setShowEditModal(false)}
              />
            </main>
          </motion.aside>
        )}
        {showAddModal && lineItemRes.query.data && (
          <SearchAndManualMaterialSection
            onClose={() => setShowAddModal(false)}
          />
        )}
        {showMaterialReviewModal && (
          <WorkOrderMaterialReviewSection
            goBack={() => setShowMaterialReviewModal(false)}
            asModal
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default WorkOrderLineItemsSection;
