import { WorkOrder } from "@eljouren/domain";
import { DateHelper } from "@eljouren/utils";
import { IconCalendarTime } from "@tabler/icons-react";
import { IpisButton } from "@ipis/client-essentials";
import CustomerFormSchedulingAndHandymanWrapper from "./CustomerFormSchedulingAndHandymanWrapper";
import { useContext } from "react";
import { FormInputContext } from "@ipis/client-essentials";

interface Props {
  workOrder: WorkOrder.Type;
  readOnly?: boolean;
}

const CustomerFormScheduledOnComponent = (props: Props) => {
  const ctx = useContext(FormInputContext);

  const readOnly = props.readOnly || ctx?.readOnly;

  return (
    <CustomerFormSchedulingAndHandymanWrapper ariaLabelledBy="scheduledFor">
      <IconCalendarTime size="36" className="row-span-2" />
      <span id="scheduledFor" className="text-sm font-semibold text-dark-950">
        Inbokat för
      </span>
      <DateTimeDisplay workOrder={props.workOrder} />
      {!readOnly && (
        <IpisButton
          variant="text"
          size="sm"
          label="Vill du ändra tid eller datum?"
          className="col-span-2 ml-auto"
          underline
        />
      )}
    </CustomerFormSchedulingAndHandymanWrapper>
  );
};

const DateTimeDisplay = (props: { workOrder: WorkOrder.Type }) => {
  const wo = props.workOrder;

  function isSameDay() {
    const start = new DateHelper(wo.startDate);
    return start.isSameDay(wo.endDate);
  }

  function getSameDateFormat(): {
    date: string;
    time: string;
  } {
    const start = new DateHelper(wo.startDate);
    const end = new DateHelper(wo.endDate);
    const date = start.format("d MMMM yyyy");
    const time = `${start.format("HH:mm")} - ${end.format("HH:mm")}`;
    return {
      date,
      time,
    };
  }

  function getDifferentDateFormat(): {
    dateString: string;
  } {
    const start = new DateHelper(wo.startDate);
    const end = new DateHelper(wo.endDate);
    const dateString = `${start.format("D/M/yyyy, HH:mm")} - ${end.format(
      "D/M/yyyy, HH:mm"
    )}`;
    return {
      dateString,
    };
  }

  if (isSameDay()) {
    const { date, time } = getSameDateFormat();
    return (
      <p className="flex items-center gap-1 text-sm text-dark-500">
        <span>{date}</span>
        <span aria-hidden className="font-bold">
          |
        </span>
        <span>{time}</span>
      </p>
    );
  } else {
    const { dateString } = getDifferentDateFormat();
    return <p className="text-sm text-dark-500">{dateString}</p>;
  }
};

export default CustomerFormScheduledOnComponent;
