import React from "react";

interface Props {
  className?: string;
  accept: string;
  maxSizeString: string;
}

const ExtensionsAndFileSizeSpan = (props: Props) => {
  const { accept, maxSizeString } = props;

  return (
    <span
      aria-label="Accepterade filtyper och storlekar"
      className="ml-auto text-xs text-dark-400"
    >
      {`${accept
        .toUpperCase()
        .replaceAll(".", "")
        .replaceAll(",", ", ")} (${maxSizeString})`}
    </span>
  );
};

export default ExtensionsAndFileSizeSpan;
