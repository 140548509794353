"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingSuggestions = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var HandymanWorkingHours_1 = require("../handyman/HandymanWorkingHours");
var BookingSuggestions;
(function (BookingSuggestions) {
    BookingSuggestions.TravelInformationSchema = centralized_zod_1.z.object({
        distance: centralized_zod_1.z.object({
            text: centralized_zod_1.z.string(),
            valueInMeters: centralized_zod_1.z.number(),
        }),
        duration: centralized_zod_1.z.object({
            text: centralized_zod_1.z.string(),
            valueInMilliseconds: centralized_zod_1.z.number(),
        }),
    });
    /*
        The IScheduler.EventSlot type does not guarantee the events to be WorkOrders,
        but since they use a generic type they will be inferred to work orders in this context
    */
    /* export const SurroundingEventSchema = <Event extends z.ZodTypeAny>(
      EventSchema: Event
    ) =>
      z.union([
        z.object({
          type: z.literal("origin"),
          event: z.literal(undefined).optional(),
          travelInfo: TravelInformationSchema.nullable(),
        }),
        z.object({
          type: z.literal("event"),
          event: EventSchema,
          travelInfo: TravelInformationSchema.nullable(),
        }),
      ]); */
    BookingSuggestions.SurroundingEventSchema = centralized_zod_1.z.union([
        centralized_zod_1.z.object({
            type: centralized_zod_1.z.literal("origin"),
            event: centralized_zod_1.z.literal(undefined).optional(),
            travelInfo: BookingSuggestions.TravelInformationSchema.nullable(),
        }),
        centralized_zod_1.z.object({
            type: centralized_zod_1.z.literal("event"),
            event: centralized_zod_1.z.any(),
            travelInfo: BookingSuggestions.TravelInformationSchema.nullable(),
        }),
    ]);
    /*
    Travel info from previous to next event,
    as well as how much additional travel time booking this event would add
      */
    BookingSuggestions.IfEventWasSkippedScenarioSchema = centralized_zod_1.z.object({
        travelInfo: BookingSuggestions.TravelInformationSchema.nullable(),
        additionalTravelTimeInMs: centralized_zod_1.z.number().nullable(),
    });
    /*
    export const SlotSchema = <Event extends z.ZodTypeAny>(
      EventSchema: Event
    ) =>
    */
    BookingSuggestions.SlotSchema = function () {
        return centralized_zod_1.z.object({
            id: centralized_zod_1.z.string(),
            /*
            Copied from IScheduler.EventSlot
          */
            boundaries: centralized_zod_1.z.object({
                start: centralized_zod_1.z.date(),
                end: centralized_zod_1.z.date(),
            }),
            /*
            The boundaries from the schedule that this slot is a part of
          */
            scheduleBoundaries: centralized_zod_1.z.object({
                start: centralized_zod_1.z.date(),
                end: centralized_zod_1.z.date(),
            }),
            earliestStart: centralized_zod_1.z.date(),
            latestStart: centralized_zod_1.z.date(),
            /*
              This has worked for YEARS, but suddenly Typescript is complaining about a circular reference
            */
            /* before: SurroundingEventSchema(EventSchema),
            after: SurroundingEventSchema(EventSchema), */
            before: BookingSuggestions.SurroundingEventSchema,
            after: BookingSuggestions.SurroundingEventSchema,
            totalTravelTimeInMs: centralized_zod_1.z.number().nullable(),
            ifEventWasSkippedScenario: BookingSuggestions.IfEventWasSkippedScenarioSchema.nullable(),
            // Not sure what this will be yet
            event: centralized_zod_1.z.object({
                durationInMs: centralized_zod_1.z.number(),
            }),
            /*
            Unique to this schema
          */
            resourceId: centralized_zod_1.z.string(),
            // Not sure how this will be calculated yet
            score: centralized_zod_1.z.number(),
        });
    };
    BookingSuggestions.Schema = function (args) {
        return centralized_zod_1.z.object({
            address: centralized_zod_1.z.object({
                name: centralized_zod_1.z.string(),
                latitude: centralized_zod_1.z.number(),
                longitude: centralized_zod_1.z.number(),
            }),
            timeEstimateInMinutes: centralized_zod_1.z.number(),
            week: centralized_zod_1.z.number(),
            year: centralized_zod_1.z.number(),
            defaultWorkingHours: centralized_zod_1.z.object({
                start: centralized_zod_1.z.date(),
                end: centralized_zod_1.z.date(),
            }),
            resourceInformation: centralized_zod_1.z.record(centralized_zod_1.z.string(), centralized_zod_1.z.object({
                /*
                  This should extend to have an "isPrioritized status"
                */
                resource: args.ResourceSchema.and(centralized_zod_1.z.object({
                    isPrioritized: centralized_zod_1.z.boolean().optional(),
                })),
                travelInfoFromHome: BookingSuggestions.TravelInformationSchema.nullable(),
                workingHours: HandymanWorkingHours_1.HandymanWorkingHours.Schema.array(),
            })),
            orderedSuggestions: centralized_zod_1.z
                .object({
                date: centralized_zod_1.z.date(),
                //slots: SlotSchema(args.EventTypeSchema).array(),
                slots: BookingSuggestions.SlotSchema().array(),
            })
                .array(),
        });
    };
})(BookingSuggestions || (BookingSuggestions = {}));
exports.BookingSuggestions = BookingSuggestions;
