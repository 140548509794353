import { AnimatePresence } from "framer-motion";
import withCustomerGuidV2, {
  CustomerAuthContext,
} from "../../../components/auth/hocs/withCustomerGuidV2";
import CustomerWorkOrderFormSectionV2 from "./form/CustomerWorkOrderFormSectionV2";
import { SplashScreen } from "@ipis/client-essentials";
import { useContext, useState } from "react";
import CustomerWorkOrderFileContextProvider from "../CustomerWorkOrderFileContextProvider";
import IpisPageWithLogo from "../../../components/__v2__/layout-v2/IpisPageWithLogo";

const CustomerWorkOrderRouteV2 = () => {
  const ctx = useContext(CustomerAuthContext);
  const [splashScreenDone, setSplashScreenDone] = useState(false);

  const workOrder = ctx.workOrder;

  /* 
    ToDo: Error page!
  */
  const showPage = !!workOrder && splashScreenDone;

  return (
    <IpisPageWithLogo
      headerProps={{
        subHeading: !!workOrder
          ? `Arbetsordernr. ${workOrder.serialNumber}`
          : undefined,
        heading: "Bekräfta arbetsorder",
      }}
    >
      <AnimatePresence>
        {!showPage && (
          <SplashScreen
            fixed
            key="splash-screen"
            onSplashScreenDone={() => setSplashScreenDone(true)}
          />
        )}
        {showPage && (
          <CustomerWorkOrderFileContextProvider workOrder={workOrder}>
            <CustomerWorkOrderFormSectionV2
              key="work-order-form"
              workOrder={workOrder}
            />
          </CustomerWorkOrderFileContextProvider>
        )}
      </AnimatePresence>
    </IpisPageWithLogo>
  );
};

export default withCustomerGuidV2(CustomerWorkOrderRouteV2);
