import { FieldValues, Path } from "react-hook-form";
import {
  FormEditableAddressFieldProps,
  FormEditableFieldsetAddress,
} from "./FormEditableAddressFieldset";

type Props<T extends FieldValues> = Omit<
  FormEditableAddressFieldProps<T>,
  "isEditing"
>;

function getName<T extends FieldValues>(
  key: keyof FormEditableFieldsetAddress,
  props: Props<T>
): Path<T> {
  const mappedKey = props.fieldMapping?.[key] ?? key;
  if (props.registerNested) {
    return `${props.registerNested}.${mappedKey}` as Path<T>;
  } else {
    return mappedKey as Path<T>;
  }
}

export function useAddress<T extends FieldValues>(
  props: Props<T>
): FormEditableFieldsetAddress {
  function watch(key: keyof FormEditableFieldsetAddress): string | undefined {
    const name = getName(key, props);
    return props.form.watch(name);
  }

  function getCurrentAddress(): FormEditableFieldsetAddress {
    return {
      street: watch("street") ?? "",
      city: watch("city") ?? "",
      postalCode: watch("postalCode") ?? "",
      floor: watch("floor"),
      doorCode: watch("doorCode"),
      country: watch("country"),
    };
  }

  const a = getCurrentAddress();
  return a;
}

export function useHasAddressErrors<T extends FieldValues>(props: Props<T>) {
  props.form.watch();

  function getName<T extends FieldValues>(
    key: keyof FormEditableFieldsetAddress,
    props: Props<T>
  ): Path<T> {
    const mappedKey = props.fieldMapping?.[key] ?? key;
    if (props.registerNested) {
      return `${props.registerNested}.${mappedKey}` as Path<T>;
    } else {
      return mappedKey as Path<T>;
    }
  }

  function hasErrors() {
    const errors = props.form.formState.errors;
    const paths = [
      getName("street", props),
      getName("city", props),
      getName("postalCode", props),
      getName("country", props),
      getName("doorCode", props),
      getName("floor", props),
    ];

    return paths.some((p) => {
      const parts = p.split(".");
      if (parts.length === 0) {
        return false;
      }

      let error: any = errors;
      do {
        const first = parts.shift();
        if (first) {
          error = error[first];
        }
      } while (error && parts.length > 0);

      return !!error;
    });
  }

  return hasErrors();
}
