import { Checklist } from "@eljouren/domain";
import { useContext } from "react";
import { FieldErrors, UseFormReturn } from "react-hook-form";
import A11yDropdown from "../../../../components/common/dropdown/A11YDropdown";
import ClientUtils from "../../../../utils/ClientUtils";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import ChecklistItem from "./ChecklistItem";
import { TChecklistFormValues } from "./HandymanWorkOrderChecklistModule";
interface Props {
  className?: string;
  form: UseFormReturn<TChecklistFormValues>;
  onSubmit(values: TChecklistFormValues): void;
  onError(errors: FieldErrors<TChecklistFormValues>): void;
  checklist: Checklist.Type;
}

const ChecklistQuestionFormMain = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const readOnly = !order.allowedToHandleOrder || order.isFinished;

  return (
    <main className="flex h-full flex-col overflow-x-auto">
      {props.checklist.questions.sections.map((section, i) => {
        return (
          <section
            key={section.id}
            className="border-b border-gray-400 last:border-b-0"
          >
            <A11yDropdown
              scrollIntoView
              data={{
                "data-sort-order": `${section.sortOrder}`,
              }}
              initiallyOpen={i === 0}
              preventHideOnOutsideClick
              wrapperId={`checklist-section-dropdown-${section.id}`}
              contentId={`checklist-section-dropdown-${section.id}-content`}
              buttonClassName="grid grid-cols-[1fr,50px] p-2 bg-brand-blue-100"
              contentWrapperClassName="flex flex-col"
              title={() => {
                const percentageAnswered =
                  (section.totalNumberOfAnsweredElements /
                    section.totalNumberOfElements) *
                  100;
                const percentageAnsweredRounded =
                  Math.round(percentageAnswered);
                const progressValue = `${section.totalNumberOfAnsweredElements}/${section.totalNumberOfElements}`;
                const progressId = `${section.id}-progress`;

                return (
                  <span
                    className={ClientUtils.classNames(
                      "grid w-full grow grid-cols-[minmax(0,1fr),auto] items-center gap-4"
                    )}
                  >
                    <h3 className="break-words text-start text-base">
                      {section.name}
                    </h3>
                    <p className="grid items-center gap-2 font-semibold tracking-wider sm:grid-cols-[minmax(0,1fr),25px] ">
                      <progress
                        id={progressId}
                        className="hidden h-2.5 w-32 overflow-hidden rounded-full sm:flex"
                        max={100}
                        value={percentageAnsweredRounded}
                      >
                        {progressValue}
                      </progress>
                      <label className="ml-auto text-sm" htmlFor={progressId}>
                        {progressValue}
                      </label>
                    </p>
                  </span>
                );
              }}
            >
              {section.items.map((item) => {
                return (
                  <ChecklistItem
                    key={item.id}
                    item={item}
                    form={props.form}
                    readOnly={readOnly}
                  />
                );
              })}
            </A11yDropdown>
          </section>
        );
      })}
    </main>
  );
};

export default ChecklistQuestionFormMain;
