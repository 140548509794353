import { WorkOrderPaymentSummary } from "@eljouren/domain";
import { useContext } from "react";
import MidDot from "../../../../../components/common/MidDot";
import RotAvdragImportantInformationSection from "../../../../../components/rot-avdrag/RotAvdragImportantInformationSection";
import CustomerWorkOrderInvoiceContext from "../../CustomerWorkOrderInvoiceContext";
import InvoiceOverviewListItem from "./InvoiceOverviewListItem";
import Skeleton from "react-loading-skeleton";

const InvoiceOverviewList = () => {
  const ctx = useContext(CustomerWorkOrderInvoiceContext);
  const summary = ctx.paymentSummary;
  const workOrder = ctx.workOrder;
  if (!summary || !workOrder) {
    return (
      <div className="flex flex-col gap-2">
        <Skeleton count={5} height={40} />
      </div>
    );
  }

  let discountLabel: string;
  const isRotAvdrag = summary.discount?.type === "rot";
  switch (summary.discount?.type) {
    case "green":
      discountLabel = "Grön teknik";
      break;
    case "rot":
      discountLabel = "Rotavdrag";
      break;
    default:
      discountLabel = "Rabatt";
      break;
  }

  const hoursAndMaterial = [
    ...summary.lineItems.hours,
    ...summary.lineItems.materials,
  ];

  return (
    <ul className="flex flex-col gap-2">
      <InvoiceOverviewListItem
        label="Totalt"
        price={summary.totalWithVAT}
        subheading="Klicka för att visa detaljer"
      >
        <LineItemList items={hoursAndMaterial} />
      </InvoiceOverviewListItem>
      {summary.discount && (
        <InvoiceOverviewListItem
          label={discountLabel}
          price={summary.discount.negativeAmount}
        >
          {isRotAvdrag && (
            <RotAvdragImportantInformationSection
              housingType={workOrder.selectedHousingInformation?.housingType}
            />
          )}
        </InvoiceOverviewListItem>
      )}
      {summary.pennyRounding !== 0 && (
        <InvoiceOverviewListItem
          label="Öresavrundning"
          price={summary.pennyRounding}
        />
      )}
      <InvoiceOverviewListItem
        label="Att betala"
        price={summary.priceToPay}
        final
      />
    </ul>
  );
};

const LineItemList = (props: {
  items: WorkOrderPaymentSummary.LineItemType[];
}) => {
  return (
    <ul className="flex list-disc flex-col gap-1 pl-4 text-xs sm:text-sm">
      {props.items.map((item) => {
        return (
          <li key={item.name + item.quantity + item.type}>
            <div className="flex flex-col">
              <span>{item.name}</span>
              <span className="flex items-center">
                <span>
                  {item.quantity}
                  st{" "}
                  {/* Hardcoded to 'st' as some of the 'working hours' are actually packages that includes several hours and in some cases material, which makes the unit 'h' not always make sense */}
                </span>
                <MidDot />
                <span>{item.totalPriceWithVAT}:-</span>
              </span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default InvoiceOverviewList;
