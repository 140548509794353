"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderQuickAddLineItem = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderLineItem_1 = require("./WorkOrderLineItem");
/*
 The billable hour you can add to a work order when it takes longer
 than expected
*/
var WorkOrderQuickAddLineItem;
(function (WorkOrderQuickAddLineItem) {
    WorkOrderQuickAddLineItem.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        name: centralized_zod_1.z.string(),
        unitPrice: centralized_zod_1.z.number().optional(),
        type: WorkOrderLineItem_1.WorkOrderLineItem.TypeEnum,
    });
})(WorkOrderQuickAddLineItem || (WorkOrderQuickAddLineItem = {}));
exports.WorkOrderQuickAddLineItem = WorkOrderQuickAddLineItem;
