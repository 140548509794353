import { AppButton } from "../../../../components/common/buttons/AppButton";
import ClientUtils from "./../../../../utils/ClientUtils";

import React from "react";
import GoBackButton from "../../../../components/GoBackButton";
import AppArrowIcon from "../../../../components/icons/AppArrowIcon";

type Base = {
  className?: string;
  children?: React.ReactNode;
};

type First = Base & {
  state: "first";
};

type NotFirst = Base & {
  state: "middle" | "last";
  onGoBack: () => void;
};

type Props = First | NotFirst;

const CustomerFormFooter = (props: Props) => {
  const isFirst = props.state === "first";
  const isLast = props.state === "last";

  return (
    <footer className={ClientUtils.twClassNames("flex flex-col gap-2 pb-2")}>
      {props.children}
      {!isFirst && (
        <GoBackButton onClick={props.onGoBack}>
          Gå tillbaka ett steg
        </GoBackButton>
      )}
      <AppButton requiresNetworkConnection={isLast} type="submit">
        <span>{isLast ? "Bekräfta uppgifter" : "Gå till nästa steg"}</span>
        {!isLast && <AppArrowIcon direction="right" size={20} />}
      </AppButton>
    </footer>
  );
};

export default CustomerFormFooter;
