"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProxyDeleteFileResponse = void 0;
var FileServerDeleteFileResponse_1 = require("../../file-server-routes/output/FileServerDeleteFileResponse");
var OperationResults_1 = require("../../shared-schemas/OperationResults");
var zod_1 = require("zod");
var ProxyDeleteFileResponse;
(function (ProxyDeleteFileResponse) {
    ProxyDeleteFileResponse.Schema = FileServerDeleteFileResponse_1.FileServerDeleteFileResponse.Schema.extend({
        operations: OperationResults_1.OperationResults.DeleteSchema,
        message: zod_1.z.string().optional(),
        metaErrorMessage: zod_1.z.string().optional(),
    });
})(ProxyDeleteFileResponse || (ProxyDeleteFileResponse = {}));
exports.ProxyDeleteFileResponse = ProxyDeleteFileResponse;
