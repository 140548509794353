import React from "react";
import { BaseEditor } from "slate";
import { ReactEditor } from "slate-react";

type RichTextEditorContextType = {
  editors: Record<string, BaseEditor & ReactEditor>;
};

const RichTextEditorContext = React.createContext<RichTextEditorContextType>(
  {} as never
);

export default RichTextEditorContext;
