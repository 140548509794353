import {
  FormCondition,
  IpisFormConditionGroup,
  IpisFormElement,
} from "@eljouren/domain";
import { useFormEditor } from "../../../../../hooks/checklist-hooks";

interface Props {
  className?: string;
  question: IpisFormElement.Type;
}

const ConditionalOverlay = (props: Props) => {
  return (
    <div
      className={
        "absolute left-0 top-0 z-10 flex h-full w-full flex-col items-center justify-center bg-light-background/90 backdrop-blur"
      }
    >
      <header>
        <h3 className="underline">Frågan har följande villkor:</h3>
      </header>
      <ul className="flex list-disc flex-col pl-4">
        {props.question.conditionGroups?.map((group, i) => {
          const isLast = i === props.question.conditionGroups!.length - 1;
          return (
            <li key={group.id}>
              {!isLast && <div className="flex gap-2">och</div>}
              <Group group={group} key={group.id} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

function Group(props: { group: IpisFormConditionGroup.Type }) {
  return (
    <div className="flex gap-2">
      {props.group.conditions.map((condition) => (
        <Condition condition={condition} key={condition.id} />
      ))}
    </div>
  );
}

function Condition(props: { condition: FormCondition.Type }) {
  const ctx = useFormEditor();
  const checklist = ctx.form;
  let reference:
    | IpisFormElement.Type
    | IpisFormElement.RepeaterItemType
    | undefined;
  checklist.pages.some((el) => {
    const c = props.condition;
    if (c.referenceType === "question") {
      const ref = el.elements.find((q) => q.id === c.reference);
      if (ref) {
        reference = ref;
        return true;
      }
    } else {
      const repeaterQuestions: IpisFormElement.RepeaterType[] =
        el.elements.filter(
          (q): q is IpisFormElement.RepeaterType =>
            q.typeOfQuestion === "repeater"
        );
      const repeaterItem = repeaterQuestions
        .flatMap((q) => q.repeaterGroup.items)
        .find((item) => item.id === c.reference);
      if (repeaterItem) {
        reference = repeaterItem;
        return true;
      }
    }

    return false;
  });

  if (!reference) {
    throw new Error("Could not find reference");
  }

  const actionLabel = "visas";
  switch (reference.typeOfQuestion) {
    case "multiple-choice":
      if (props.condition.comparison === "equals") {
        return (
          <div className="flex gap-2">
            <span>{actionLabel} när</span>
            <i>'{reference.title}'</i> är satt på alternativet
            <strong>
              {
                reference.options.find(
                  (opt) => opt.id === props.condition.value
                )?.value
              }
            </strong>
          </div>
        );
      } else {
        return (
          <div className="flex gap-2">
            <span>{actionLabel} när</span>
            <i>'{reference.title}'</i> inte är satt på alternativet
            <strong>
              {
                reference.options.find(
                  (opt) => opt.id === props.condition.value
                )?.value
              }
            </strong>
          </div>
        );
      }

    case "yes/no":
      if (props.condition.comparison === "equals") {
        const label = props.condition.value ? "sann" : "falsk";

        return (
          <div className="flex gap-2">
            <span>{actionLabel} när</span>
            <i>'{reference.title}'</i> är
            <strong>{label}</strong>
          </div>
        );
      } else {
        return (
          <div className="flex gap-2">
            <span>{actionLabel} när </span>
            <i>'{reference.title}'</i> inte är
            <strong>{props.condition.value ? "sann" : "falsk"}</strong>
          </div>
        );
      }

    default:
      throw new Error("Unsupported question type");
  }
}

export default ConditionalOverlay;
