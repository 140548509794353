import { useEffect, useState } from "react";

type ConnectivityCallbacks = {
  onOnline?: () => void;
  onOffline?: () => void;
  onChange?: (isOnline: boolean) => void;
};

export function useIsOnlineWithCallbacks(args: ConnectivityCallbacks): void {
  function handleOnline() {
    args.onOnline?.();
    args.onChange?.(true);
  }

  function handleOffline() {
    args.onOffline?.();
    args.onChange?.(false);
  }

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  });
}

export function useIsOnline(args?: ConnectivityCallbacks): boolean {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  function handleOnline() {
    setIsOnline(true);
    args?.onOnline?.();
    args?.onChange?.(true);
  }

  function handleOffline() {
    setIsOnline(false);
    args?.onOffline?.();
    args?.onChange?.(false);
  }

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  });

  return isOnline;
}
