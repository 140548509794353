"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LiveBookingSuggestions = void 0;
var WorkOrder_1 = require("../work-order/WorkOrder");
var BookingSuggestions_1 = require("./BookingSuggestions");
var Handyman_1 = require("../handyman/Handyman");
var centralized_zod_1 = require("@ipis/centralized-zod");
var LiveBookingSuggestions;
(function (LiveBookingSuggestions) {
    LiveBookingSuggestions.Schema = BookingSuggestions_1.BookingSuggestions.Schema({
        EventTypeSchema: WorkOrder_1.WorkOrder.HandymanWithPermissionsSchema,
        ResourceSchema: Handyman_1.Handyman.Schema,
    }).extend({
        qualification: centralized_zod_1.z.string(),
        isOnDuty: centralized_zod_1.z.boolean(),
    });
})(LiveBookingSuggestions || (LiveBookingSuggestions = {}));
exports.LiveBookingSuggestions = LiveBookingSuggestions;
