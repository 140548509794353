import { AnimatePresence, motion } from "framer-motion";
import { useContext, useState } from "react";
import { IpisButton } from "@ipis/client-essentials";
import WhatHappensNextSection from "../../../../../components/__v2__/form-components/customer-forms/WhatHappensNextSection";
import { FormInputContext } from "@ipis/client-essentials";
import AppCheckmarkIcon from "../../../../../components/icons/AppCheckmarkIcon";
import { CustomerWorkOrderFileContext } from "../../../CustomerWorkOrderFileContextProvider";
import { CustomerWorkOrderFormContext } from "../CustomerWorkOrderFormSectionV2";
import CustomerWorkOrderFormInformationPage from "../information-page/CustomerWorkOrderFormInformationPage";
import CustomerWorkOrderFormInvoicePage from "../invoice-page/CustomerWorkOrderFormInvoicePage";

interface Props {
  onReset: () => void;
}

const CustomerWorkOrderFormConfirmationPageV2 = (props: Props) => {
  const [shouldShowDetails, setShouldShowDetails] = useState(false);
  const ctx = useContext(CustomerWorkOrderFormContext);

  function showDetails() {
    setShouldShowDetails(true);
  }

  function hideDetails() {
    setShouldShowDetails(false);
  }

  return (
    <motion.section layout className="relative flex h-full flex-col gap-8">
      <header className="grid grid-cols-[1fr,auto] gap-2 border-b border-border-color pb-8">
        <h2 className="text-xl text-dark-950">
          Din arbetsorder är nu bekräftad
        </h2>
        <AppCheckmarkIcon
          variant="circle"
          size="lg"
          className="fill-success text-light-background"
        />
        <p className="col-span-2 py-2 text-dark-800">
          Tack så mycket! Dina detaljer har tagits emot. Om vi behöver mer
          information kommer vi att kontakta dig.
        </p>
        <IpisButton
          variant="text"
          label="Visa detaljer"
          onClick={showDetails}
          underline
          className="col-span-2 mr-auto"
          size="sm"
        />
        {process.env.NODE_ENV === "development" && (
          <IpisButton
            variant="text"
            label="Återställ"
            underline
            className="col-span-2 mr-auto"
            size="sm"
            onClick={props.onReset}
          />
        )}
      </header>
      <WhatHappensNextSection workOrder={ctx.workOrder} />
      <AnimatePresence>
        {shouldShowDetails && <Details hideDetails={hideDetails} />}
      </AnimatePresence>
    </motion.section>
  );
};

const Details = (props: { hideDetails: () => void }) => {
  const ctx = useContext(CustomerWorkOrderFormContext);
  const fileCtx = useContext(CustomerWorkOrderFileContext);
  const wo = ctx.workOrder;

  return (
    <FormInputContext.Provider
      value={{
        readOnly: true,
      }}
    >
      <motion.aside
        layout
        className="absolute left-0 top-0 grid h-full w-full grid-rows-[minmax(0,1fr),auto] gap-4 bg-white"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="flex h-full flex-col gap-4 overflow-y-auto p-4">
          <h3 className="text-2xl text-dark-950">Din arbetsorder</h3>
          <CustomerWorkOrderFormInformationPage
            omit={{ introduction: true }}
            defaultValues={{
              contact: wo.contact,
              location: wo.location,
              images: fileCtx.images.map((file) => {
                return {
                  ...file,
                  state: "postupload",
                };
              }),
            }}
          />
          <CustomerWorkOrderFormInvoicePage
            defaultValues={{
              gdprConfirmed: wo.customerDetails.gdprConfirmed || undefined,
              customerActivelyChoseToSkipTaxReduction:
                wo.discountDetails.customerHasActivelyChosenToSkipDiscount,
              /* invoice: {
                  email: wo.
                } */
            }}
          />
          {/* <CustomerFormScheduledOnComponent workOrder={ctx.workOrder} readOnly />
        <CustomerFormDescriptionComponent workOrder={ctx.workOrder} readOnly />
        {!!fileCtx.images.length && (
          <SimpleImageUploadComponent
            id="work-order-images"
            heading="Bilder"
            as="div"
            fileUploadProps={{
              currentFiles: fileCtx.images.map((file) => {
                return {
                  ...file,
                  state: "postupload",
                };
              }),
              maxFileNameLength: 100,
            }}
          />
        )} */}
        </div>

        <footer className="flex justify-end p-2">
          <IpisButton
            variant="text"
            label="Gå tillbaka"
            onClick={props.hideDetails}
            icon={{
              type: "caret-left",
              position: "leading",
            }}
          />
        </footer>
      </motion.aside>
    </FormInputContext.Provider>
  );
};

export default CustomerWorkOrderFormConfirmationPageV2;
