import {
  motion,
  MotionProps,
  TargetAndTransition,
  Transition,
} from "framer-motion";
import React, { useContext, useRef } from "react";
import { useOnEnterClick } from "../../../hooks/keyboard-event-hooks";
import { useTailwindColors } from "../../../hooks/tailwind-hooks";
import { MarginOptions, useMargin } from "../../../hooks/use-margin";
import { GlobalContext } from "../../../top-level-contexts";
import ClientUtils from "../../../utils/ClientUtils";
import AppIconRouter, { AppIconIdentifier } from "../../icons/AppIconRouter";

export type AppButtonIconType = AppIconIdentifier;
type IconPosition = "leading" | "trailing";
type TIcon = {
  type: AppButtonIconType;
  position: IconPosition;
};

export type AppTileButtonV2Props = {
  loading?: boolean | number;
  disabled?: boolean;
  requiresNetworkConnection?: boolean;
  type?: "button" | "submit";
  onClick?: () => void;
  label: string;
  sublabel?: string;
  margin?: MarginOptions;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  ref?: React.RefObject<HTMLButtonElement>;
  onlyIcon?: boolean;
  icon?: TIcon;
};

export function AppTileButtonV2(props: AppTileButtonV2Props) {
  const { isOnline } = useContext(GlobalContext);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const margin = useMargin(props.margin);
  const colors = useTailwindColors();

  useOnEnterClick({
    ref: buttonRef,
    callback: () => {
      buttonRef.current?.click();
    },
  });

  const {
    loading,
    requiresNetworkConnection,
    disabled,
    type,
    onlyIcon,
    className,
    ...rest
  } = props;

  const failsNetworkCheck = requiresNetworkConnection && !isOnline;
  const actAsDisabled = disabled || failsNetworkCheck;


  const transition: Transition = {
    duration: 0.05,
    ease: "easeInOut",
    color: { duration: 0.03 },
  };

  function getAnimations(): {
    initial: MotionProps["initial"];
    animate: TargetAndTransition;
    whileHover: TargetAndTransition;
    whileTap: TargetAndTransition;
  } {
    const animate = {
      opacity: actAsDisabled ? 0.5 : 1,
      backgroundColor: "#ffffff00",
      color: actAsDisabled ? colors.dark[300] : colors.dark[950],
      transition,
    };
    const initial = animate;
    return {
      initial,
      animate,
      whileHover: {
        backgroundColor: colors.secondary[50],
        transition,
      },
      whileTap: {
        backgroundColor: colors.secondary[100],
        transition,
      },
    };
  }

  const icon = props.icon;

  let ariaProps = {};
  if (props.onlyIcon) {
    ariaProps = {
      "aria-label": props.label,
    };
  }

  const hasIcon = !!icon;

  return (
    <motion.button
      className={ClientUtils.classNames(
        "relative flex items-center gap-2 rounded border border-dark-900 p-2 focus:outline-1 focus:outline-focus disabled:pointer-events-none",
        props.onlyIcon && "h-8 w-8",
        actAsDisabled && "pointer-events-none",
        props.className
        //props.className,
      )}
      disabled={actAsDisabled}
      type={type ?? "button"}
      style={margin}
      {...getAnimations()}
      ref={buttonRef}
      onClick={props.onClick}
      {...ariaProps}
      {...rest}
    >
      {icon && icon.position === "leading" && (
        <AppIconRouter icon={icon.type} size="sm" />
      )}
      {!props.onlyIcon && (
        <motion.div className="grid w-full grid-cols-[minmax(0,1fr),auto] gap-2">
          <motion.span
            className={ClientUtils.twClassNames(
              "flex items-center gap-2 truncate transition-all",
              !!loading && "opacity-40"
            )}
          >
            {props.label}
          </motion.span>
          {!!props.sublabel && (
            <motion.span
              className={ClientUtils.twClassNames(
                "flex items-center gap-2 text-xs italic text-dark-400",
                !!loading && "opacity-40",
                hasIcon && "justify-end",
                !hasIcon && "justify-center"
              )}
            >
              {props.sublabel}
            </motion.span>
          )}
        </motion.div>
      )}
      {icon && icon.position === "trailing" && (
        <AppIconRouter icon={icon.type} size="sm" />
      )}
      {/* <AnimatePresence>
        {!!props.loading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white/50"
          >
            <AppLoader className="h-5 max-h-full w-5" />
          </motion.div>
        )}
      </AnimatePresence> */}
    </motion.button>
  );
}
