import { useContext, useState } from "react";
import HandymanWorkOrderRouteContext from "./contexts/HandymanWorkOrderRouteContext";
import { useRepos } from "../../../hooks/use-repos";
import useQueryWrapper from "../../../hooks/use-query-wrapper";
import { classNames } from "@ipis/client-essentials";
import { AppLoader } from "../../../components/common/loaders/AppLoader";
import { AppButton } from "../../../components/common/buttons/AppButton";
import MyDialog from "../../../components/common/MyDialog";
import ChecklistAnswerSummary from "../../../components/checklist/v2/ChecklistAnswerSummary";

interface Props {
  className?: string;
}

const HandymanWorkOrderPreparationFormComponent = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);

  const [showModal, setShowModal] = useState(false);
  const repos = useRepos();
  const form = order.externalCustomerPreparationForm;
  const res = useQueryWrapper({
    queryKey: ["preparationForm", order.orderId],
    queryFn: () =>
      repos.ipisFormRepo.getPreparationsFormForHandyman({
        workOrderId: order.orderId,
        fileRepo: repos.workOrderFileRepo,
      }),
    enabled: !!form && !!form.completedDate,
  });

  if (!form) {
    return <></>;
  }

  if (!form.completedDate) {
    return (
      <div className={classNames(props.className)}>
        <span className="text-sm text-semi-faded">
          Kundchecklista ej ifylld
        </span>
      </div>
    );
  }

  if (res.isError) {
    return (
      <div className={classNames(props.className)}>
        <span className="text-sm text-error">
          Okänt fel vid hämtning av svar på kundchecklista
        </span>
      </div>
    );
  }

  return (
    <div className={classNames(props.className)}>
      {res.isLoading && <AppLoader className="h-8 w-8" />}
      {res.data && (
        <AppButton className="w-full" onClick={() => setShowModal(true)}>
          Visa svar på kundchecklista
        </AppButton>
      )}
      <MyDialog
        isOpen={showModal && !!res.data}
        cancel={() => setShowModal(false)}
        addCloseButton
      >
        {!!res.data && (
          <div className="mx-auto h-full w-full max-w-screen-lg overflow-y-auto p-4 md:p-8">
            <ChecklistAnswerSummary
              currentFormVersion={res.data.form}
              answers={res.data.answers}
              goBack={() => setShowModal(false)}
            />
          </div>
        )}
      </MyDialog>
    </div>
  );
};

export default HandymanWorkOrderPreparationFormComponent;
