import ClientUtils from "./../../../utils/ClientUtils";
import React from "react";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { useBundledState } from "../../../hooks/hooks";
import ProfileFormSingleInput, {
  ProfileFormButtonPosition,
  ProfileFormSingleInputVariant,
} from "./ProfileFormSingleInput";

type StringKeyOf<T> = Extract<keyof T, string>;

type Props<T extends FieldValues> = {
  variant?: ProfileFormSingleInputVariant;
  label: string;
  id: string;
  canEdit: boolean;
  buttonPosition?: Omit<ProfileFormButtonPosition, "top-right">;
  children?: React.ReactNode;
  value: string;
  editText?: string;
  submitText?: string;
  htmlAttributes?: Omit<
    JSX.IntrinsicElements["textarea"],
    "ref" | "className" | "id" | "value"
  >;
} & (
  | {
      paths: (StringKeyOf<T> | { path: StringKeyOf<T>; label?: string })[];
      form: UseFormReturn<T>;
      onEdit?: undefined;
      onSave?(): void;
    }
  | { onEdit(): void; paths?: undefined; onSave?: undefined }
);

/* 
    This component as well as the ProfileFormSingleInput is really messy and should be refactored.
*/
const ProfileFormMergedInput = <T extends FieldValues>(props: Props<T>) => {
  const editBundle = useBundledState(false);
  const variant = props.variant ?? "default";

  const buttonRight = !props.buttonPosition || props.buttonPosition === "right";
  const buttonBelow = props.buttonPosition === "bottom-right";

  function getStaticInputLabel(): string | undefined {
    if (props.paths?.length === 1) {
      return props.label;
    }

    return undefined;
  }

  return (
    <div
      className={ClientUtils.twClassNames(
        buttonRight && "grid grid-cols-[minmax(0,1fr),auto]",
        buttonBelow && "flex flex-col",
        "gap-3 text-sm last:border-b-0",
        variant === "default" && "border-b pb-2"
      )}
    >
      <span className="flex flex-col">
        {!editBundle.value && (
          <ProfileFormSingleInput
            id={props.id}
            label={props.label}
            editable={false}
            variant={props.variant}
            htmlAttributes={{
              value: props.value,
              ...props.htmlAttributes,
            }}
          />
        )}

        {editBundle.value &&
          props.paths &&
          props.paths.map((el) => {
            let label: string | undefined;
            let path: StringKeyOf<T>;
            if (typeof el === "string") {
              path = el;
            } else {
              path = el.path;
              label = el.label;
            }

            if (!label) {
              label = getStaticInputLabel();
            }

            const id = "profileFormInputMap" + path;

            return (
              <ProfileFormSingleInput
                key={`merged-input-${id}`}
                label={label}
                id={id}
                variant={props.variant}
                isEditing
                editable={false}
                form={props.form}
                path={path}
                htmlAttributes={props.htmlAttributes}
              />
            );
          })}
      </span>

      {props.canEdit && !editBundle.value && (
        <button
          className="mb-1 ml-auto mt-auto font-semibold"
          onClick={(e) => {
            e.preventDefault();
            if (props.onEdit) {
              props.onEdit();
            } else {
              editBundle.set(true);
            }
          }}
        >
          {props.editText ?? "Ändra"}
        </button>
      )}
      {editBundle.value && (
        <button
          className="mb-1 ml-auto mt-auto font-semibold"
          onClick={(e) => {
            e.preventDefault();
            editBundle.set(false);

            if (props.onSave) {
              props.onSave();
            }
          }}
        >
          {props.submitText ?? "Spara"}
        </button>
      )}
    </div>
  );
};

export default ProfileFormMergedInput;
