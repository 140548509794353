import { WorkOrderConfirmation } from "@eljouren/domain/build";
import { useContext, useRef } from "react";
import AppPages from "../../../components/common/pages/AppPages";
import FormBreadcrumb from "../../../components/forms/breadcrumb/FormBreadcrumb";
import { useBundledState } from "../../../hooks/hooks";
import { useRepos } from "../../../hooks/use-repos";
import ClientUtils from "./../../../utils/ClientUtils";

import { CustomFileInputFile } from "../../../components/files/FileInputButton";
import CustomerWorkOrderContext from "../CustomerWorkOrderContext";
import CustomerWorkOrderBaseFieldsFormStep from "./base-fields/CustomerWorkOrderBaseFieldsFormStep";
import CustomerWorkOrderFilesFormStep from "./files/CustomerWorkOrderImagesFormStep";
import CustomerWorkOrderOverviewFormStep from "./overview/CustomerWorkOrderOverviewFormStep";
import CustomerWorkOrderPaymentAndHousingFormStep from "./payment-and-housing/CustomerWorkOrderPaymentAndHousingFormStep";
import { CustomerWorkOrderFileContext } from "../CustomerWorkOrderFileContextProvider";

export type WorkOrderStepFormValues = [
  WorkOrderConfirmation.BaseFieldsType | undefined,
  WorkOrderConfirmation.PaymentInfoType | undefined,
  CustomFileInputFile[] | undefined,
  Pick<WorkOrderConfirmation.Type, "gdprConfirmed"> | undefined
];

enum Step {
  confirmBaseFields,
  paymentAndHousing,
  images,
  overviewAndAgreement,
}

interface Props {
  className?: string;
}

const CustomerWorkOrderStepBasedForm = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderContext);
  const fileCtx = useContext(CustomerWorkOrderFileContext);
  const { workOrderRepo, workOrderFileRepo } = useRepos();
  const stepBundle = useBundledState(Step.confirmBaseFields);
  const valuesRef = useRef<WorkOrderStepFormValues>([
    undefined,
    undefined,
    undefined,
    undefined,
  ]);

  function confirmBaseFieldsSubmit(
    baseFields: WorkOrderConfirmation.BaseFieldsType
  ) {
    valuesRef.current[0] = baseFields;
    stepBundle.set(1);
  }

  function paymentAndHousingSubmit(
    fields: WorkOrderConfirmation.PaymentInfoType
  ) {
    valuesRef.current[1] = fields;
    stepBundle.set(2);
  }

  function filesSubmit(files: CustomFileInputFile[]) {
    valuesRef.current[2] = files;
    stepBundle.set(3);
  }

  function overviewAndAgreementSubmit(
    values: Pick<WorkOrderConfirmation.Type, "gdprConfirmed">
  ) {
    valuesRef.current[3] = values;
    confirm();
  }

  async function confirm() {
    try {
      await ctx.workOrderRes.mutate({
        callback: async () => {
          const [base, paymentAndHousing, files, gdpr] = valuesRef.current;
          if (!base || !paymentAndHousing || !files || !gdpr) {
            throw new Error("Invalid values");
          }

          const [confirmationPromise, fileUploadPromise] =
            await Promise.allSettled([
              workOrderRepo.confirm({
                workOrderId: ctx.workOrder.orderId,
                values: {
                  ...base,
                  ...paymentAndHousing,
                  ...gdpr,
                },
              }),
              new Promise(async (res, rej) => {
                if (files.length === 0) {
                  res("No files to upload");
                  return;
                }

                try {
                  const result = await workOrderFileRepo.uploadAsCustomer({
                    files,
                  });
                  res(result);
                } catch (er) {
                  rej(er);
                }
              }),
            ]);

          if (confirmationPromise.status === "rejected") {
            throw confirmationPromise.reason;
          }

          if (fileUploadPromise.status === "rejected") {
            window.modal.alert({
              title: "Det gick inte att ladda upp bilderna just nu",
              prompt:
                "Uppdraget blev bekräftat, men bilderna kunde inte laddas upp. Vi kontaktar dig om vi behöver mer information.",
              typeOfAlert: "error",
              error: fileUploadPromise.reason,
            });
          }

          fileCtx.fileRes.query.refetch();
        },
      });
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att bekräfta uppdraget just nu",
        prompt: "Vänligen försök igen senare",
        typeOfAlert: "error",
        error: er,
      });
    }
  }

  function onGoBack() {
    stepBundle.set(stepBundle.value - 1);
  }

  function getOverviewProps() {
    const base = valuesRef.current[0];
    const paymentAndHousing = valuesRef.current[1];
    const files = valuesRef.current[2];

    if (!base || !files || !paymentAndHousing) {
      throw new Error("");
    }

    const values: Omit<WorkOrderConfirmation.Type, "gdprConfirmed"> = {
      ...base,
      ...paymentAndHousing,
    };

    return { values, files };
  }

  function getDefaultInvoiceMail(): string | undefined {
    if (
      ctx.workOrder.contact.email ===
      ctx.workOrder.customerDetails.assumedInvoiceEmail
    ) {
      const step1Email = valuesRef.current[0]?.contact.email;
      return step1Email;
    }

    return undefined;
  }

  return (
    <section
      className={ClientUtils.twClassNames(
        "relative mx-auto grid h-full w-full max-w-2xl grid-cols-1 grid-rows-[auto,minmax(0,1fr)] overflow-hidden",
        props.className
      )}
      id="customer-work-order-step-based-form-section"
      data-payment-flow-allowed={
        ctx.workOrder.paymentDetails.externalPaymentFlowAllowed
      }
    >
      <aside className="flex flex-col">
        <FormBreadcrumb
          steps={["Order", "Faktura", "Bilder", "Översikt"]}
          atIndex={stepBundle.value}
          onActiveClick={stepBundle.set}
        />
      </aside>

      <AppPages
        pageIndex={stepBundle.value}
        onlyMain
        mainGridRow={2}
        className="h-full overflow-auto py-4"
      >
        {stepBundle.value === Step.confirmBaseFields && (
          <CustomerWorkOrderBaseFieldsFormStep
            defaultValues={valuesRef.current[0]}
            onSubmit={confirmBaseFieldsSubmit}
          />
        )}
        {stepBundle.value === Step.paymentAndHousing && (
          <CustomerWorkOrderPaymentAndHousingFormStep
            defaultValues={valuesRef.current[1]}
            onSubmit={paymentAndHousingSubmit}
            onGoBack={onGoBack}
            defaultInvoiceMail={getDefaultInvoiceMail()}
          />
        )}
        {stepBundle.value === Step.images && (
          <CustomerWorkOrderFilesFormStep
            onSubmit={filesSubmit}
            onGoBack={onGoBack}
            defaultValues={valuesRef.current[2]}
          />
        )}
        {stepBundle.value === Step.overviewAndAgreement && (
          <CustomerWorkOrderOverviewFormStep
            {...getOverviewProps()}
            onSubmit={overviewAndAgreementSubmit}
            onGoBack={onGoBack}
          />
        )}
      </AppPages>
    </section>
  );
};

export default CustomerWorkOrderStepBasedForm;
