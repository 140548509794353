"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisRadioGroup = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var domain_1 = require("@eljouren/domain");
var framer_motion_1 = require("framer-motion");
var react_1 = require("react");
var react_hook_form_1 = require("react-hook-form");
var form_input_context_1 = require("../../../../contexts/form-input-context");
var form_hooks_1 = require("../../../../hooks/form-hooks");
var keyboard_event_hooks_1 = require("../../../../hooks/keyboard-event-hooks");
var tailwind_hooks_1 = require("../../../../hooks/tailwind-hooks");
var client_utils_1 = require("../../../../utils/client-utils");
var FormInputShell_1 = require("../components/FormInputShell");
var IpisTextInputCompact_1 = require("../text-input/IpisTextInputCompact");
var OTHER = domain_1.FormElementAnswer.MULTIPLE_CHOICE_OTHER_OPTION;
var IpisRadioGroup = function (props) {
    var options = __spreadArray([], props.options, true);
    var allowOther = props.allowOther && !!props.otherName;
    if (allowOther) {
        options.push({ value: OTHER, label: "Annat" });
    }
    var currentValue = (0, form_hooks_1.useFormValue)(props);
    var currentOption = options.find(function (o) { return o.value === currentValue; });
    var clarificationName = props.otherName;
    var otherIsSelected = (currentOption === null || currentOption === void 0 ? void 0 : currentOption.value) === OTHER;
    var requireClarification = !!clarificationName && allowOther && otherIsSelected;
    var style = props.useUniformWidth
        ? {
            display: "grid",
            gridTemplateColumns: "repeat(".concat(options.length, ", 1fr)"),
        }
        : {};
    return ((0, jsx_runtime_1.jsx)(FormInputShell_1.FormInputShell, __assign({}, props, { children: (0, jsx_runtime_1.jsxs)(framer_motion_1.motion.section, { className: "col-span-2 flex w-full flex-col gap-4", children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.fieldset, { className: "flex flex-wrap gap-2", onBlur: props.onBlur, onFocus: props.onFocus, style: style, children: options.map(function (option, i) {
                        var key = "".concat(props.id, "-").concat(option.value, "-").concat(i);
                        return ((0, jsx_runtime_1.jsx)(OptionDiv, { option: option, id: props.id, currentValue: currentValue, name: props.name, form: props.form, controls: props.controls, readOnly: props.readOnly }, key));
                    }) }), requireClarification && ((0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: false, id: clarificationName, name: clarificationName, label: "Vänligen specificera", form: props.form, className: "col-span-2" }))] }) })));
};
exports.IpisRadioGroup = IpisRadioGroup;
var OptionDiv = function (props) {
    var ctx = (0, form_input_context_1.useFormInputContext)();
    var id = "".concat(props.id, "-").concat(props.option.value);
    var readOnly = props.readOnly || (ctx === null || ctx === void 0 ? void 0 : ctx.readOnly);
    var isSelected = props.currentValue === props.option.value;
    if (props.form) {
        return ((0, jsx_runtime_1.jsx)(react_hook_form_1.Controller, { name: props.name, control: props.form.control, render: function (_a) {
                var field = _a.field;
                return ((0, jsx_runtime_1.jsx)(DecoupledOptionDiv, { id: id, option: props.option, name: props.name, label: props.option.label, readOnly: readOnly, isSelected: isSelected, inputProps: __assign(__assign({}, field), { onChange: function (e) {
                            if (e.target.checked) {
                                field.onChange(props.option.value);
                            }
                        } }) }));
            } }));
    }
    else if (props.controls) {
        return ((0, jsx_runtime_1.jsx)(DecoupledOptionDiv, { id: id, option: props.option, name: props.name, label: props.option.label, readOnly: readOnly, isSelected: isSelected, inputProps: {
                name: props.name,
                onChange: function (e) {
                    var _a, _b;
                    if (e.target.checked) {
                        (_b = (_a = props.controls).onChange) === null || _b === void 0 ? void 0 : _b.call(_a, props.option.value);
                    }
                },
            } }));
    }
    throw new Error("No form or controls provided");
};
var DecoupledOptionDiv = function (props) {
    var ref = (0, react_1.useRef)(null);
    var colors = (0, tailwind_hooks_1.useTailwindColors)();
    (0, keyboard_event_hooks_1.useOnEnterClick)({
        ref: ref,
        callback: function () {
            var _a;
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.click();
        },
    });
    return ((0, jsx_runtime_1.jsxs)(framer_motion_1.motion.div, { className: "focus:outline-4 focus:outline-red-400", children: [(0, jsx_runtime_1.jsx)(framer_motion_1.motion.label, { className: (0, client_utils_1.classNames)("col-start-2 flex items-center justify-center  gap-2 border border-border-color px-[12px] py-[6px] text-center text-base font-semibold", !props.readOnly && "cursor-pointer focus:outline focus:outline-focus"), htmlFor: props.id, tabIndex: 0, animate: {
                    backgroundColor: props.isSelected ? "#000000" : "#00000000",
                    color: props.isSelected
                        ? colors["light-background"]
                        : colors.dark[950],
                }, whileHover: {
                    backgroundColor: props.readOnly
                        ? undefined
                        : props.isSelected
                            ? "#000000"
                            : "#00000010",
                }, ref: ref, children: (0, jsx_runtime_1.jsx)(framer_motion_1.motion.span, { children: props.option.label }) }), (0, jsx_runtime_1.jsx)(framer_motion_1.motion.input, __assign({ className: "sr-only", checked: props.isSelected, type: "radio", readOnly: props.readOnly, id: props.id }, props.inputProps))] }));
};
