import { useContext } from "react";
import WorkOrderMobileFileTab from "../../components/work-order/files/WorkOrderMobileFileTab";
import { CustomerWorkOrderFileContext } from "./CustomerWorkOrderFileContextProvider";

interface Props {
  className?: string;
}

const CustomerWorkOrderFileTab = (props: Props) => {
  const ctx = useContext(CustomerWorkOrderFileContext);

  const { images, documents } = ctx;
  return (
    <WorkOrderMobileFileTab
      images={images}
      documents={documents}
      deleteFile={ctx.deleteFileMutation}
      uploadFiles={ctx.uploadFileMutation}
      permissions={ctx.permissions}
    />
  );
};

export default CustomerWorkOrderFileTab;
