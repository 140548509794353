import ClientUtils from "./../../../utils/ClientUtils";
import { AnimatePresence, motion, useReducedMotion } from "framer-motion";
import React from "react";
import { useBrand } from "../../../hooks/brand-hooks";

import EljourenLogo from "../brand/EljourenLogo";
import IpisLogo from "../brand/IpisLogo";
import RorjourenLogo from "../brand/RorjourenLogo";
import "./AppLoaderStyling.css";
import RormokareNuLogo from "../brand/RormokareNuLogo";
import AiiLogo from "../brand/AiiLogo";
interface Props {
  className?: string;
  children?: React.ReactNode;
  loading?: boolean;
  renderContentSeparately?: boolean;
}

const LoadingContent: React.FC<Props> = (props) => {
  const reducedMotion = useReducedMotion();
  const brand = useBrand();

  if (!props.loading && props.renderContentSeparately) {
    return <>{props.children}</>;
  }

  return (
    <>
      <motion.div
        className={ClientUtils.twClassNames(
          "relative h-full w-full",
          props.loading && "app-loader-section",
          props.className
        )}
        initial={{
          opacity: 0.5,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 0.5,
        }}
      >
        {!props.renderContentSeparately && props.children}
        <AnimatePresence exitBeforeEnter initial>
          {props.loading && (
            <motion.span
              data-testid="loading-content"
              className={ClientUtils.twClassNames(
                "absolute left-1/2 z-50 flex h-[clamp(125px,60vmin,250px)] w-[clamp(125px,60vmin,250px)] items-center justify-center",
                props.loading && "app-loader-wrapper"
              )}
              initial={{
                opacity: 0,
                x: "-50%",
                y: "10%",
              }}
              animate={{
                opacity: 1,
                x: "-50%",
                y: "10%",
                scale: [1.1, 1],
              }}
              exit={{
                opacity: 0,
                x: "-50%",
                y: "10%",
                transition: { duration: 5 },
              }}
            >
              <motion.div
                className={ClientUtils.twClassNames(
                  "app-loader flex items-center justify-center"
                )}
                animate={
                  reducedMotion
                    ? { opacity: [0.7, 1, 0.7] }
                    : { rotate: 360, opacity: 1 }
                }
                transition={{
                  repeat: Infinity,
                  repeatDelay: 0.5,
                }}
              />
              {brand === "Svenska Eljouren" && (
                <EljourenLogo className="absolute w-9/12 translate-x-[4%] translate-y-[-6%]" />
              )}
              {brand === "Rörjour 247" && (
                <RorjourenLogo className="absolute w-1/2" />
              )}
              {brand === "Rörmokare.nu" && (
                <RormokareNuLogo className="absolute w-1/2" />
              )}
              {(brand === "IPIS" || brand === "IPIS Contract") && (
                <IpisLogo className="absolute w-1/2" />
              )}
              {brand === "Aii" && <AiiLogo className="absolute w-1/2" />}
            </motion.span>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
};

export default LoadingContent;
