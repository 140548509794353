import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { useState } from "react";
import { FilePermissions } from "../../../types/file-permissions";
import ScrollIndicatorContainer from "../../ScrollIndicatorContainer";
import AppPages from "../../common/pages/AppPages";
import { CustomFileInputFile } from "../../files/FileInputButton";
import SelectableFileGrid from "../../files/SelectableFileGrid";
import AppDocumentIcon from "../../icons/AppDocumentIcon";
import AppImageIcon from "../../icons/AppImageIcon";
import AppUploadIcon from "../../icons/AppUploadIcon";
import IpisFileDataList from "../handyman/files/IpisFileDataList";
import ClientUtils from "./../../../utils/ClientUtils";
import WorkOrderFileUploadForm from "./WorkOrderFileUploadForm";

enum Page {
  view,
  upload,
}

enum FileType {
  images,
  documents,
}

interface Props {
  className?: string;
  images: IpisFileV2.Type[];
  documents: IpisFileV2.Type[];
  deleteFile: (file: IpisFileV2.Type) => void;
  uploadFiles: (files: CustomFileInputFile[]) => void;

  permissions: FilePermissions;
  children?: React.ReactNode;
}

const WorkOrderMobileFileTab = (props: Props) => {
  const [page, setPage] = useState<Page>(Page.view);
  const [fileType, setFileType] = useState<FileType>(FileType.images);

  async function onDelete(file: IpisFileV2.Type): Promise<boolean> {
    try {
      const confirm = await window.modal.confirm({
        title: "Ta bort fil",
        prompt: `Är du säker på att du vill ta bort '${file.nameWithExtension}'?`,
      });
      if (confirm) {
        props.deleteFile(file);
      }
    } catch (er) {
      window.modal.alert({
        title: "Det gick inte att ta bort filen just nu",
        prompt: "Vänligen försök igen senare.",
        typeOfAlert: "error",
        error: er,
      });
    }

    return false;
  }

  function switchFileType() {
    if (fileType === FileType.images) {
      setFileType(FileType.documents);
    } else {
      setFileType(FileType.images);
    }
  }

  const { images, documents } = props;
  /* 
 	Hmm 
  */
  const imagesWithAlt = images.map((image) => {
    return {
      ...image,
      alt: `Uppladdad till arbetsorder med beskrivning '${
        image.description ?? "Ingen beskrivning"
      }'}`,
    };
  });

  const includeFooter = props.permissions.canUpload || !!props.children;

  const documentCount = documents.length;
  const imageCount = images.length;

  return (
    <section
      className={ClientUtils.twClassNames(
        "grid h-full grid-rows-1",
        props.className
      )}
    >
      <AppPages
        pageIndex={page}
        onlyMain
        mainGridRow={1}
        className={ClientUtils.twClassNames(
          "relative grid",
          page === Page.upload && "grid-rows-1",
          page === Page.view && "grid-rows-[auto,minmax(0,1fr)]"
        )}
      >
        {page === Page.view && (
          <>
            <header className="flex items-center gap-2 border-b p-2">
              <span className="flex items-center gap-2">
                {fileType === FileType.images && <AppImageIcon size={25} />}
                {fileType === FileType.documents && (
                  <AppDocumentIcon size={25} />
                )}
                <h3 className="text-base">
                  {fileType === FileType.images ? `Bilder` : `Dokument`}
                </h3>
              </span>
              <button
                onClick={switchFileType}
                className="ml-auto rounded border px-2 py-2 text-sm"
              >
                {fileType === FileType.images
                  ? `Visa dokument (${documentCount})`
                  : `Visa bilder (${imageCount})`}
              </button>
            </header>
            <main className="h-full overflow-auto">
              {fileType === FileType.images && (
                <SelectableFileGrid
                  filePermissionCallback={props.permissions.forFile}
                  files={imagesWithAlt}
                  onDelete={onDelete}
                />
              )}
              {fileType === FileType.documents && (
                <ScrollIndicatorContainer
                  as="div"
                  assumeScrollable={documents.length >= 6}
                >
                  <IpisFileDataList
                    onDelete={onDelete}
                    data={documents}
                    filePermissionCallback={props.permissions.forFile}
                  />
                </ScrollIndicatorContainer>
              )}
            </main>
            {includeFooter && (
              <footer className="absolute bottom-0 right-0 z-10 flex flex-row-reverse gap-2 p-2 sm:p-4">
                {props.permissions.canUpload && (
                  <button
                    aria-label="Ladda upp filer"
                    className="ml-auto flex h-16 w-16  items-center justify-center rounded-full bg-brand-blue-800 text-white shadow"
                    onClick={() => setPage(Page.upload)}
                  >
                    <AppUploadIcon size={24} />
                  </button>
                )}
                {props.children}
              </footer>
            )}
          </>
        )}

        {page === Page.upload && (
          <WorkOrderFileUploadForm
            requireAtLeastOneFile
            onSubmit={props.uploadFiles}
            uploadLabel="Ladda upp filer"
            onGoBack={() => setPage(Page.view)}
            afterSubmit={() => setPage(Page.view)}
          />
        )}
      </AppPages>
    </section>
  );
};

export default WorkOrderMobileFileTab;
