import { useContext } from "react";
import BookingSuggestionsContext from "../BookingSuggestionsContext";
import SingleBookingSuggestionHeader from "../single-booking-suggestion/SingleBookingSuggestionHeader";
import SingleBookingSuggestionSimplifiedMain from "../single-booking-suggestion/SingleBookingSuggestionSimplifiedMain";
import SingleBookingSuggestionContext from "../single-booking-suggestion/SingleBookingSuggestionContext";
import SingleBookingSuggestionMain from "../single-booking-suggestion/SingleBookingSuggestionMain";
import SingleBookingSuggestionFooter from "../single-booking-suggestion/SingleBookingSuggestionFooter";

const BookingSuggestionsByHandyman = () => {
  const ctx = useContext(BookingSuggestionsContext);

  if (ctx.simplifiedView) {
    return (
      <>
        {ctx.sortedBookingSuggestions.groupedByHandyman.map((suggestionObj) => {
          const handyman = suggestionObj.handyman;
          return (
            <section key={handyman.id} className="relative rounded border">
              <SingleBookingSuggestionHeader handyman={handyman} />
              <SingleBookingSuggestionSimplifiedMain
                slots={suggestionObj.slots}
              />
            </section>
          );
        })}
      </>
    );
  }

  return (
    <>
      {ctx.sortedBookingSuggestions.groupedByHandyman.map((suggestionObj) => {
        const handyman = suggestionObj.handyman;
        return (
          <section key={handyman.id} className="relative rounded border">
            <SingleBookingSuggestionHeader handyman={handyman} />
            {suggestionObj.slots.map((slot, i) => {
              return (
                <SingleBookingSuggestionContext.Provider
                  key={slot.id}
                  value={{
                    slot,
                    handyman,
                    showDate: true,
                  }}
                >
                  <div>
                    <SingleBookingSuggestionMain />
                    <SingleBookingSuggestionFooter />
                  </div>
                </SingleBookingSuggestionContext.Provider>
              );
            })}
          </section>
        );
      })}
    </>
  );
};

export default BookingSuggestionsByHandyman;
