import ClientUtils from "../../../utils/ClientUtils";
import CheckmarkIcon from "../AppCheckmarkIcon";
import { ExposedIconProps } from "../types/SvgIconProps";

interface Props extends ExposedIconProps<{}> {
  className?: string;
  status: "success" | "failure";
}

const CompletedWorkOrderIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.status === "failure") {
    return (
      <CheckmarkIcon
        {...rest}
        className={ClientUtils.twClassNames("text-red-600", props.className)}
      />
    );
  }

  return (
    <CheckmarkIcon
      {...rest}
      className={ClientUtils.twClassNames("text-green-600", props.className)}
    />
  );
};
export default CompletedWorkOrderIcon;
