"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisBackButton = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_router_dom_1 = require("react-router-dom");
var IpisArrowIcon_1 = require("../../components/icons/IpisArrowIcon");
var nav_hooks_1 = require("../../hooks/nav-hooks");
var client_utils_1 = require("../../utils/client-utils");
var IpisBackButton = function (props) {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var params = (0, react_router_dom_1.useSearchParams)()[0];
    var goBackTo = params.get("goBackTo");
    var handle = (0, nav_hooks_1.useManipulateBackButtonStack)().handle;
    return ((0, jsx_runtime_1.jsxs)("button", { className: (0, client_utils_1.classNames)("group flex items-center gap-2 p-2", props.className), onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var handled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        return [4 /*yield*/, handle()];
                    case 1:
                        handled = _a.sent();
                        if (handled) {
                            return [2 /*return*/];
                        }
                        console.log("overrideLink", props.overrideLink);
                        console.log("goBackTo", goBackTo);
                        if (props.overrideLink) {
                            navigate(props.overrideLink);
                        }
                        else if (goBackTo) {
                            navigate(goBackTo);
                        }
                        else {
                            navigate(-1);
                        }
                        return [2 /*return*/];
                }
            });
        }); }, children: [(0, jsx_runtime_1.jsx)(IpisArrowIcon_1.IpisArrowIcon, { direction: "left", size: 30, className: "scale-90 group-hover:scale-100" }), props.label] }));
};
exports.IpisBackButton = IpisBackButton;
