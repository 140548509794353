import { DateHelper } from "@eljouren/utils";
import { formatDuration } from "date-fns";
import { sv } from "date-fns/locale";
import { useContext } from "react";
import ClientUtils from "./../../../../utils/ClientUtils";
import SingleBookingSuggestionContext from "./SingleBookingSuggestionContext";

interface Props {
  className?: string;
}

const SingleBookingSuggestionFooter = (props: Props) => {
  const ctx = useContext(SingleBookingSuggestionContext);
  const slot = ctx.slot;

  let formatedTotalTravelTime: string | null = null;

  if (slot.totalTravelTimeInMs) {
    const hours = Math.floor(slot.totalTravelTimeInMs / 1000 / 60 / 60);
    const minutes =
      Math.floor(slot.totalTravelTimeInMs / 1000 / 60) - hours * 60;
    formatedTotalTravelTime = formatDuration(
      { minutes, hours },
      {
        locale: sv,
      }
    );
  }

  const wh = ctx.slot.scheduleBoundaries;
  const start = new DateHelper(wh.start).timeInputFormat;
  const end = new DateHelper(wh.end).timeInputFormat;
  const formattedWhText = `${start} - ${end}`;

  return (
    <footer
      className={ClientUtils.twClassNames(
        "flex gap-2 bg-gradient-to-br from-brand-blue-300 to-brand-blue-200  p-4 text-xs text-black",
        props.className
      )}
    >
      <span className="rounded border p-2">
        {formatedTotalTravelTime && (
          <>
            <b className="font-semibold">Total restid </b>
            {formatedTotalTravelTime}
          </>
        )}
        {!formatedTotalTravelTime && (
          <>
            <b className="font-semibold">Total restid</b> gick ej att beräkna
          </>
        )}
      </span>
      <span className="rounded border p-2">
        <b className="font-semibold">Arbetstid</b> {formattedWhText}
      </span>

      {/* 
        Hide this until we have refined the presentation
      */}
      {/*  <span className="rounded border p-2">
        {travelTimeFromPreviousToNextEventText}
      </span>
      <span className="rounded border p-2">{extraTravelTimeText}</span> */}
    </footer>
  );
};

export default SingleBookingSuggestionFooter;
