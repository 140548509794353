import { Checklist } from "@eljouren/domain";
import { motion } from "framer-motion";
import { useContext } from "react";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import ChecklistImageItemUpload from "./ChecklistImageItemUpload";
interface Props {
  checklist: Checklist.Type;
}

const FadeInOutProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

/* 
	Not actually a form
*/
const ChecklistImageUploadView = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const readOnly = !order.allowedToHandleOrder || order.isFinished;

  return (
    <section
      id="checklistImageUploadView"
      className="mx-auto flex w-full max-w-screen-lg flex-col gap-4"
    >
      <motion.main key="display" {...FadeInOutProps}>
        {props.checklist.images.items.map((el) => (
          <ChecklistImageItemUpload key={el.id} item={el} readOnly={readOnly} />
        ))}
      </motion.main>
    </section>
  );
};

export default ChecklistImageUploadView;
