export type PaginatorProps<T> = {
  elementsPerPage: number;
  totalCountOfElements: number | "countData";
  data: T[];
  pageIndex?: number;
};

export type PaginatorLimits = {
  minPageIndex: number;
  maxPageIndex: number;
};

export default class Paginator<T> {
  readonly pageIndex: number;
  readonly totalCountOfElements: number;
  readonly limits: PaginatorLimits;
  readonly currentData: T[];
  readonly needsPagination: boolean;

  constructor(private props: PaginatorProps<T>) {
    this.pageIndex = props.pageIndex || 0;
    this.totalCountOfElements =
      props.totalCountOfElements === "countData"
        ? props.data.length
        : props.totalCountOfElements;
    this.limits = this.calculateLimits();
    this.currentData = this.initCurrentData();
    this.needsPagination = this.totalCountOfElements > props.elementsPerPage;
  }

  private calculateLimits(): PaginatorLimits {
    return {
      minPageIndex: 0,
      maxPageIndex: Math.max(
        Math.ceil(this.totalCountOfElements / this.props.elementsPerPage) - 1,
        0
      ),
    };
  }

  get pageNumber() {
    return this.pageIndex + 1;
  }

  get canGoBack() {
    return this.pageIndex > this.limits.minPageIndex;
  }

  get maxPageNumber() {
    return this.maxPageIndex + 1;
  }

  get maxPageIndex() {
    return this.limits.maxPageIndex;
  }

  get canGoForward() {
    return this.pageIndex < this.limits.maxPageIndex;
  }

  private initCurrentData(): T[] {
    const offset = this.props.elementsPerPage * this.pageIndex;
    return this.props.data.slice(offset, this.props.elementsPerPage + offset);
  }
}
