"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TravelInfoResults = void 0;
var AbstractTravelInfoRepo_1 = require("./AbstractTravelInfoRepo");
var TravelInfoRetriever = /** @class */ (function () {
    function TravelInfoRetriever(map) {
        this.map = map;
    }
    TravelInfoRetriever.prototype.to = function (destinationB) {
        var key = AbstractTravelInfoRepo_1.AbstractTravelInfoRepo.placeToKey(destinationB);
        if (key in this.map) {
            return this.map[key];
        }
        throw new Error("No travel info found for ".concat(key));
    };
    return TravelInfoRetriever;
}());
var TravelInfoResults = /** @class */ (function () {
    function TravelInfoResults(aToBMap, bToAMap) {
        this.aToBMap = aToBMap;
        this.bToAMap = bToAMap;
    }
    TravelInfoResults.prototype.from = function (destinationA, options) {
        if (options === void 0) { options = {}; }
        var key = AbstractTravelInfoRepo_1.AbstractTravelInfoRepo.placeToKey(destinationA);
        if (key in this.aToBMap) {
            var map = this.aToBMap[key];
            return new TravelInfoRetriever(map);
        }
        if (key in this.bToAMap) {
            var map = this.bToAMap[key];
            return new TravelInfoRetriever(map);
        }
        if (options.logError) {
            console.error({
                message: "No travel info found",
                key: key,
                aToBMap: this.aToBMap,
                bToAMap: this.bToAMap,
            });
        }
        throw new Error("No travel info found for ".concat(key));
    };
    TravelInfoResults.prototype.merge = function (other) {
        var aToBMap = __assign(__assign({}, this.aToBMap), other.aToBMap);
        var bToAMap = __assign(__assign({}, this.bToAMap), other.bToAMap);
        return new TravelInfoResults(aToBMap, bToAMap);
    };
    TravelInfoResults.createFromTravelInfoArray = function (travelInfoArray) {
        var originToDestinationMap = {};
        var destinationToOriginMap = {};
        for (var _i = 0, travelInfoArray_1 = travelInfoArray; _i < travelInfoArray_1.length; _i++) {
            var travelInfo = travelInfoArray_1[_i];
            var origin_1 = travelInfo.destinationA;
            var destination = travelInfo.destinationB;
            var originKey = AbstractTravelInfoRepo_1.AbstractTravelInfoRepo.placeToKey(origin_1);
            var destinationKey = AbstractTravelInfoRepo_1.AbstractTravelInfoRepo.placeToKey(destination);
            if (!originToDestinationMap[originKey]) {
                originToDestinationMap[originKey] = {};
            }
            if (!destinationToOriginMap[destinationKey]) {
                destinationToOriginMap[destinationKey] = {};
            }
            originToDestinationMap[originKey][destinationKey] = travelInfo;
            destinationToOriginMap[destinationKey][originKey] = travelInfo;
        }
        return new TravelInfoResults(originToDestinationMap, destinationToOriginMap);
    };
    return TravelInfoResults;
}());
exports.TravelInfoResults = TravelInfoResults;
