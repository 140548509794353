import { TSignedInWorkerState } from "../../../_model/repos/interfaces/IAuthRepo";
import withSalesCredentials from "../../../components/auth/hocs/withSalesCredentials";
import AppArrowIcon from "../../../components/icons/AppArrowIcon";
import AppPage from "../../../components/layout/AppPage";
import MyLink from "../../../components/routing/MyLink";
import AppRoutes from "../../../routes";

interface Props extends TSignedInWorkerState {
  className?: string;
}

const SalesTeamPartnerListRoute = (props: Props) => {
  return (
    <AppPage
      className="p-4 lg:p-6"
      headerProps={{
        heading: "Partners",
      }}
    >
      <ul className="flex flex-col gap-2">
        <LinkListItem
          href={AppRoutes.salesTeam.partnerListWithAssociation("vvs")}
          label={"VVS"}
        />
        <LinkListItem
          href={AppRoutes.salesTeam.partnerListWithAssociation("spol")}
          label={"Spolbil"}
        />
        <LinkListItem
          href={AppRoutes.salesTeam.partnerListWithAssociation("el")}
          label={"El"}
        />
      </ul>
    </AppPage>
  );
};

const LinkListItem = (props: { href: string; label: string }) => {
  return (
    <li className="flex w-full cursor-pointer items-center justify-between rounded border bg-bg-base-layer transition-all  hover:bg-main-bg-light hover:text-white">
      <MyLink
        to={props.href}
        className="flex w-full items-center justify-between p-3"
      >
        <span className="text-lg font-semibold">{props.label}</span>
        <AppArrowIcon direction="right" size={20} />
      </MyLink>
    </li>
  );
};

export default withSalesCredentials(SalesTeamPartnerListRoute);
