"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractTravelInfoRepo = exports.TravelInfo = void 0;
var TravelInfoResults_1 = require("./TravelInfoResults");
var TravelInfo;
(function (TravelInfo) {
    var TravelMode;
    (function (TravelMode) {
        TravelMode["DRIVING"] = "driving";
        TravelMode["WALKING"] = "walking";
        TravelMode["BICYCLING"] = "bicycling";
        TravelMode["TRANSIT"] = "transit";
    })(TravelMode = TravelInfo.TravelMode || (TravelInfo.TravelMode = {}));
})(TravelInfo || (exports.TravelInfo = TravelInfo = {}));
var AbstractTravelInfoRepo = /** @class */ (function () {
    function AbstractTravelInfoRepo(props) {
        var _a;
        this.props = props;
        this.language = (_a = props.language) !== null && _a !== void 0 ? _a : "sv";
    }
    AbstractTravelInfoRepo.placeToKey = function (place) {
        if (typeof place === "string") {
            return place;
        }
        else if ("street" in place) {
            if (place.zipCode) {
                return "".concat(place.street, ", ").concat(place.zipCode, " ").concat(place.city);
            }
            else if (place.postalCode) {
                return "".concat(place.street, ", ").concat(place.postalCode, " ").concat(place.city);
            }
            else {
                return "".concat(place.street, ", ").concat(place.city);
            }
        }
        else if ("latitude" in place) {
            return "".concat(place.latitude, ",").concat(place.longitude);
        }
        else {
            return "".concat(place.lat, ",").concat(place.lng);
        }
    };
    AbstractTravelInfoRepo.prototype.placeToKey = function (place) {
        return AbstractTravelInfoRepo.placeToKey(place);
    };
    AbstractTravelInfoRepo.prototype.getMaxOriginDestinationPairs = function () {
        if (this.type === "matrix") {
            return Math.floor(Math.sqrt(this.maxElementsPerRequest));
        }
        else {
            return this.maxElementsPerRequest;
        }
    };
    AbstractTravelInfoRepo.prototype.chunkPairs = function (pairs) {
        var maxOriginDestinationPairs = this.getMaxOriginDestinationPairs();
        var chunks = [];
        for (var i = 0; i < pairs.length; i += maxOriginDestinationPairs) {
            chunks.push(pairs.slice(i, i + maxOriginDestinationPairs));
        }
        return chunks;
    };
    AbstractTravelInfoRepo.prototype.getTravelInfo = function (args) {
        return __awaiter(this, void 0, void 0, function () {
            var pairs, uniqueChecker, pairsWithSameOriginAndDestination, uniquePairs, sameDestinationTravelInfoArray, sameDestinationTravelInfo, chunks, results, travelInfo;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        pairs = args.pairs;
                        uniqueChecker = {};
                        pairsWithSameOriginAndDestination = [];
                        uniquePairs = [];
                        pairs.forEach(function (pair) {
                            var originKey = _this.placeToKey(pair.origin);
                            var destinationKey = _this.placeToKey(pair.destination);
                            var key = "".concat(originKey, "__").concat(destinationKey);
                            if (!uniqueChecker[key]) {
                                uniqueChecker[key] = true;
                                if (originKey === destinationKey) {
                                    pairsWithSameOriginAndDestination.push(pair);
                                }
                                else {
                                    uniquePairs.push(pair);
                                }
                            }
                        });
                        if (!uniquePairs.length && !pairsWithSameOriginAndDestination.length) {
                            /*
                              This error message doesn't make sense anymore, since we're using pairs of origin-destination instead of a list of origins and a list of destinations.
                            */
                            throw new Error("Both destinationsA and destinationsB must be non-empty arrays");
                        }
                        sameDestinationTravelInfoArray = pairsWithSameOriginAndDestination.map(function (pair) {
                            var originKey = _this.placeToKey(pair.origin);
                            var destinationKey = _this.placeToKey(pair.destination);
                            return {
                                destinationA: originKey,
                                destinationB: destinationKey,
                                distance: {
                                    text: "0 m",
                                    valueInMeters: 0,
                                },
                                duration: {
                                    text: "0 min",
                                    valueInMilliseconds: 0,
                                },
                            };
                        });
                        sameDestinationTravelInfo = TravelInfoResults_1.TravelInfoResults.createFromTravelInfoArray(sameDestinationTravelInfoArray);
                        if (!uniquePairs.length) {
                            return [2 /*return*/, sameDestinationTravelInfo];
                        }
                        chunks = this.chunkPairs(uniquePairs);
                        return [4 /*yield*/, Promise.all(chunks.map(function (chunk) {
                                return _this._getTravelInfo({
                                    uniquePairs: chunk,
                                    mode: args.mode,
                                });
                            }))];
                    case 1:
                        results = _a.sent();
                        travelInfo = results.reduce(function (acc, result) { return acc.merge(result); }, sameDestinationTravelInfo);
                        return [2 /*return*/, travelInfo];
                }
            });
        });
    };
    return AbstractTravelInfoRepo;
}());
exports.AbstractTravelInfoRepo = AbstractTravelInfoRepo;
