import { IpisForm } from "@eljouren/domain";
import {
  IpisFormTypeInference,
  IpisFormAnswerValidator,
} from "@eljouren/domain-utils";
import { useRef, useState } from "react";
import AppPages from "../../common/pages/AppPages";
import ChecklistPageComponent from "./checklist-page/ChecklistPageComponent";
import { FormProgressIndicator } from "@ipis/client-essentials";
import { motion } from "framer-motion";
import { FormContext } from "./ChecklistContexts";

export interface ChecklistV2Props<T extends IpisForm.ShellType> {
  className?: string;
  checklist: T;
  byPassValidation?: boolean;
  byPassConditionalValidation?: boolean;
  /* 
    Now, we only have the ValidatedChecklistDO which might contain non-valid values (might be a confusing name that needs to be changed).
    We should probably create a new class that first goes through the same process as the ValidatedChecklistDO but can only be created once
    all answers are valid.
  */
  onSubmit: (values: IpisFormAnswerValidator<T>) => void;
}

const ChecklistV2Component = <T extends IpisForm.ShellType>(
  props: ChecklistV2Props<T>
) => {
  const pageValues = useRef<{ [key: string]: any }>({});
  const [currentStep, setCurrentStep] = useState(0);
  const isFinalPage = currentStep === props.checklist.pages.length - 1;
  const page = props.checklist.pages[currentStep];

  /* function onSubmit(values: { [key: string]: any }) {
    props.onSubmit(values);
  } */

  function proceed(values: { [key: string]: any }) {
    pageValues.current = { ...pageValues.current, ...values };
    if (currentStep < props.checklist.pages.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      const values =
        pageValues.current as IpisFormTypeInference.InferredFormValues<T>;
      const validatedChecklist = new IpisFormAnswerValidator({
        form: props.checklist,
        currentFormValues: values,
      });
      props.onSubmit(validatedChecklist);
    }
  }

  function goBack() {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

  return (
    <FormContext.Provider
      value={{
        byPassSchemaValidation: !!props.byPassValidation,
        byPassConditionalValidation: !!props.byPassConditionalValidation,
        form: props.checklist,
      }}
    >
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="grid h-full w-full grid-cols-1 grid-rows-[auto,minmax(0,1fr)]"
      >
        {props.checklist.pages.length > 1 && (
          <header className="mx-auto w-full max-w-screen-md">
            <FormProgressIndicator
              pages={props.checklist.pages.map((page) => {
                return {
                  label: page.pageTitleShorthand,
                };
              })}
              currentStep={currentStep}
              fixedAtTop
            />
          </header>
        )}
        <AppPages
          pageIndex={currentStep}
          onlyMain
          mainGridRow={2}
          className="h-full overflow-y-auto children-w-screen-md"
        >
          <ChecklistPageComponent
            otherPageValues={pageValues.current}
            page={page}
            isFinalPage={isFinalPage}
            proceed={proceed}
            goBack={goBack}
            isFirstPage={currentStep === 0}
          />
        </AppPages>
      </motion.section>
    </FormContext.Provider>
  );
};

export default ChecklistV2Component;
