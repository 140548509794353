import { FileMetaInput } from "@eljouren/file-schemas/build";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ImageType } from "react-images-uploading";
import { z } from "@ipis/centralized-zod";
import {
  TailwindBreakpoint,
  useMediaQuery,
} from "../../../hooks/use-media-query";
import { GlobalContext } from "../../../top-level-contexts";
import PlaceholderGridList from "../../PlaceholderGridList";
import ScrollIndicatorContainer from "../../ScrollIndicatorContainer";
import MidDot from "../../common/MidDot";
import { AppButton } from "../../common/buttons/AppButton";
import { AppFormTextArea } from "../../common/text-areas/AppFormTextArea";
import { AppFormTextField } from "../../common/text-fields/AppFormTextField";
import FileInputButton, {
  CustomFileInputFile,
} from "../../files/FileInputButton";
import IpisFileIcon from "../../files/IpisFileIcon";
import AppArrowIcon from "../../icons/AppArrowIcon";
import AppEditIcon from "../../icons/AppEditIcon";
import AppXIcon from "../../icons/AppXIcon";
import SaveIcon from "../../icons/SaveIcon";
import ClientUtils from "./../../../utils/ClientUtils";

export const OrderFinalFormValuesSchema = z.object({
  gdprConfirmed: z.literal(true),
});

export type TOrderFinalFormValues = z.infer<typeof OrderFinalFormValuesSchema>;

type FileType = ImageType & {
  guid: string;
};

const customNameMinLength = 3;
const customNameMaxLength = 80;
const descriptionMaxLength = 250;

const SingleFormSchema = z.object({
  name: z.string().min(customNameMinLength).max(customNameMaxLength),
  description: z.string().max(descriptionMaxLength).optional(),
});

type SingleFormValues = z.infer<typeof SingleFormSchema>;

export type UploadedFileType = FileType & { meta: FileMetaInput.Type } & {
  file: File;
};

export interface WorkOrderFileUploadProps {
  className?: string;
  afterSubmit?(): void;
  onSubmit(images: CustomFileInputFile[]): void;
  onGoBack?(): void;
  requireAtLeastOneFile?: boolean;
  defaultValues?: CustomFileInputFile[];
  uploadLabel: string;
  arrowRight?: boolean;
  prompt?: string;
  resetAfterSubmit?: boolean;
  onlyImages?: boolean;
  omitName?: boolean;
  omitDescription?: boolean;
}
const WorkOrderFileUploadForm = (props: WorkOrderFileUploadProps) => {
  const { signInState } = useContext(GlobalContext);
  const isMd = useMediaQuery(TailwindBreakpoint.md);

  const [files, setFiles] = useState<CustomFileInputFile[]>(
    props.defaultValues ?? []
  );
  const [editFile, setEditFile] = useState<CustomFileInputFile | null>(null);

  /* function onImageRemoveAllClick(e: React.MouseEvent) {
    metaForm.reset({ meta: {} });
    e.preventDefault();
    setFiles([]);
  } */

  function onRemoveFile(file: CustomFileInputFile) {
    setFiles((prev) => prev.filter((f) => f.guid !== file.guid));
  }

  function onFilesAdded(files: CustomFileInputFile[]) {
    setFiles((prev) => [...prev, ...files]);
  }

  function onEditCancel() {
    setEditFile(null);
  }

  function onEditFileSubmit(args: {
    values: SingleFormValues;
    file: CustomFileInputFile;
  }) {
    setFiles((prev) => {
      return prev.map((f) => {
        if (f.guid === args.file.guid) {
          return {
            ...f,
            meta: args.values,
          };
        }
        return f;
      });
    });
    setEditFile(null);
  }

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    props.onSubmit(files);

    if (props.resetAfterSubmit) {
      setFiles([]);
    }
    props.afterSubmit?.();
  }

  const maxSizeInBytes = 10 * Math.pow(10, 6);
  const maxSizeStr = "10mb";
  const itemHeight = isMd ? 120 : 96;
  const allowEdit = !props.omitDescription || !props.omitName;

  return (
    <>
      <section className="relative grid h-full w-full grid-rows-[auto,minmax(0,1fr)]">
        {!!editFile && allowEdit && (
          <EditFileModal
            file={editFile}
            omitName={!!props.omitName}
            omitDescription={!!props.omitDescription}
            onCancel={onEditCancel}
            onSubmit={onEditFileSubmit}
          />
        )}

        <header className="flex flex-col p-2">
          <span className="flex items-center">
            <h3 className="text-base">Ladda upp filer</h3>
            <MidDot className="text-4xl" />
            <span className="flex items-center text-xs italic">{`Max ${maxSizeStr} per fil`}</span>
          </span>
          {(!!props.prompt || signInState.signedInAs === "customer") && (
            <p className="font-opacity-80 flex text-sm text-dark-gray">
              {props.prompt ??
                "Ladda gärna upp filer som är relevanta för uppdraget."}
            </p>
          )}
        </header>
        <form
          className={ClientUtils.twClassNames(
            "relative grid h-full grid-rows-[minmax(0,1fr),auto] gap-2 p-2 sm:p-4",
            props.className
          )}
          onSubmit={onSubmit}
        >
          <main className="grid grow grid-rows-[auto,minmax(0,1fr),auto] gap-2">
            <FileInputButton
              id="file-upload-form-input"
              label={"Klicka här för att välja filer"}
              maxSize={{
                inBytes: maxSizeInBytes,
                stringRepresentation: maxSizeStr,
              }}
              onlyImages={props.onlyImages}
              onChange={onFilesAdded}
              maxFileNameLength={customNameMaxLength}
            />
            <ScrollIndicatorContainer as="div">
              <PlaceholderGridList
                id={`file-upload-form-images`}
                items={files}
                gap={16}
                passThroughProps={{}}
                padding={{
                  top: 8,
                  right: 8,
                }}
                itemHeight={itemHeight}
                minItemWidth={125}
                RenderPlaceholder={(props) => {
                  return (
                    <li
                      className="h-full w-full rounded border border-dashed border-black/30"
                      aria-hidden="true"
                      style={props.style}
                    />
                  );
                }}
                RenderItem={(props) => {
                  const file = props.item;
                  const isImage = file.native.type.includes("image");
                  const isNonHeicImage =
                    isImage &&
                    !file.native.type.includes("heic") &&
                    !file.native.type.includes("heif");
                  /* 
                    "onRemoveFile" should be passed in via passThroughProps
                  */
                  return (
                    <li
                      data-test-class="uploaded-checklist-image"
                      key={file.guid}
                      className="relative flex w-full items-center justify-center"
                      style={props.style}
                    >
                      <button
                        aria-label="Ta bort fil"
                        className={ClientUtils.twClassNames(
                          "absolute -right-2 -top-2 z-10 rounded-full bg-main-bg-dark/80 p-1 text-white transition-colors hover:bg-red-300"
                        )}
                        onClick={() => onRemoveFile(file)}
                        type="button"
                      >
                        <AppXIcon size={30} />
                      </button>
                      {allowEdit && (
                        <button
                          aria-label="Redigera filinformation"
                          className={ClientUtils.twClassNames(
                            "absolute -right-2 top-8 z-10 rounded-full bg-main-bg-dark/80 p-1 text-white transition-colors hover:bg-red-300"
                          )}
                          onClick={() => setEditFile(file)}
                          type="button"
                        >
                          <AppEditIcon size={30} />
                        </button>
                      )}

                      {isNonHeicImage && (
                        <img
                          src={file.dataUrl}
                          alt="Förhandsvisning av bild"
                          className="h-full w-full overflow-hidden rounded object-cover"
                        />
                      )}
                      {!isNonHeicImage && (
                        <span className="flex h-full w-full items-center justify-center rounded border">
                          <IpisFileIcon size={60} mimeType={file.native.type} />
                        </span>
                      )}
                    </li>
                  );
                }}
              />
            </ScrollIndicatorContainer>

            {files.length > 0 && (
              <>
                {/* 
                Needs accessibility improvements, aria-label or sr-only, or something
              */}
                {allowEdit && (
                  <p className="text-sm">
                    Klicka på <AppEditIcon className="inline" />
                    för att ändra namn och beskrivning, eller på{" "}
                    <AppXIcon className="inline" />
                    för att ta bort en fil.
                  </p>
                )}
                {!allowEdit && (
                  <p className="text-sm">
                    Klicka på <AppXIcon className="inline" />
                    för att ta bort en fil.
                  </p>
                )}
              </>
            )}

            {/*  <AppTextButton
                onClick={onImageRemoveAllClick}
                className="ml-auto p-2 text-base"
              >
                Återställ filer
              </AppTextButton> */}
          </main>
          <footer className="mt-auto flex flex-col gap-2 pt-2">
            {!!props.onGoBack && (
              <AppButton
                requiresNetworkConnection={false}
                className="col-span-2 flex items-center gap-2 "
                onClick={props.onGoBack}
                variant="outline-default"
              >
                <AppArrowIcon direction="left" size={20} />
                Gå tillbaka ett steg
              </AppButton>
            )}
            <AppButton
              requiresNetworkConnection
              className="col-span-2"
              disabled={props.requireAtLeastOneFile && !files.length}
              type="submit"
            >
              <span>{props.uploadLabel}</span>
              {props.arrowRight && <AppArrowIcon direction="right" size={20} />}
            </AppButton>
          </footer>
        </form>
      </section>
    </>
  );
};

const EditFileModal = (props: {
  file: CustomFileInputFile;
  omitName: boolean;
  omitDescription: boolean;
  onSubmit: (args: {
    values: SingleFormValues;
    file: CustomFileInputFile;
  }) => void;
  onCancel: () => void;
}) => {
  const form = useForm<SingleFormValues>({
    defaultValues: {
      name: props.file.meta.name ?? "",
      description: props.file.meta.description ?? "",
    },
    resolver: zodResolver(SingleFormSchema),
  });

  function onSubmit(values: SingleFormValues) {
    props.onSubmit({ values, file: props.file });
  }

  const mimeType = props.file.native.type;

  const isNonHeicImage =
    mimeType.includes("image") &&
    !mimeType.includes("heic") &&
    !mimeType.includes("heif");

  const formErrors = form.formState.errors ?? {};

  return (
    <form
      className="absolute left-0 top-0 z-20 grid h-full w-full grid-cols-1 grid-rows-[minmax(0,1fr),auto,auto] gap-2 bg-white/80 backdrop-blur"
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <figure className="">
        {isNonHeicImage && (
          <img
            src={props.file.dataUrl}
            alt="Bild vald för att laddas upp"
            className="h-full w-full object-contain"
          />
        )}
        {!isNonHeicImage && (
          <span className="flex h-full w-full items-center justify-center rounded border">
            <IpisFileIcon size={90} mimeType={mimeType} />
          </span>
        )}
      </figure>
      {!props.omitName && (
        <AppFormTextField
          register={form.register}
          errorMessage={formErrors.name?.message}
          name={"name"}
          htmlAttributes={{
            placeholder: "Namn",
            minLength: customNameMinLength,
            maxLength: customNameMaxLength,
          }}
        />
      )}
      {!props.omitDescription && (
        <AppFormTextArea
          register={form.register}
          errorMessage={formErrors.description?.message}
          name={"description"}
          htmlAttributes={{
            placeholder: "Beskrivning",
            rows: 2,
            maxLength: descriptionMaxLength,
          }}
        />
      )}
      <AppButton
        onClick={(e) => {
          e.preventDefault();
          props.onCancel();
        }}
        variant="outline-error"
      >
        <span>Avbryt</span>
        <AppXIcon size={20} />
      </AppButton>
      <AppButton type="submit" requiresNetworkConnection>
        <span>Spara</span>
        <SaveIcon direction="right" size={20} />
      </AppButton>
    </form>
  );
};

export default WorkOrderFileUploadForm;
