import { IpisFormElement } from "@eljouren/domain";
import { useContext } from "react";
import ChecklistPageContext from "../../checklist-page/ChecklistPageContext";
import ChecklistDecoupledMultipleChoiceQuestion from "./ChecklistDecoupledMultipleChoiceQuestion";

interface Props {
  className?: string;
  question: IpisFormElement.MultipleChoiceType;
}
const ChecklistMultipleChoiceQuestion = (props: Props) => {
  const ctx = useContext(ChecklistPageContext);
  return (
    <ChecklistDecoupledMultipleChoiceQuestion
      question={props.question}
      form={ctx.form}
    />
  );
};

export default ChecklistMultipleChoiceQuestion;
