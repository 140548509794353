"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignUrl_GetMultipleOutput = void 0;
var zod_1 = require("zod");
var SignUrl_GetMultipleOutput;
(function (SignUrl_GetMultipleOutput) {
    SignUrl_GetMultipleOutput.Schema = zod_1.z.object({
        url: zod_1.z.string(),
        expires: zod_1.z.union([
            zod_1.z
                .string()
                .transform(function (val) { return new Date(val); })
                .refine(function (val) { return !isNaN(Number(val)); }),
            zod_1.z.date(),
        ]),
    });
})(SignUrl_GetMultipleOutput || (SignUrl_GetMultipleOutput = {}));
exports.SignUrl_GetMultipleOutput = SignUrl_GetMultipleOutput;
