"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileTag = void 0;
var zod_1 = require("zod");
var FileTag;
(function (FileTag) {
    FileTag.Schema = zod_1.z.union([
        zod_1.z.object({
            type: zod_1.z.enum(["checklistAnswer"]),
            reference: zod_1.z.string(),
        }),
        zod_1.z.object({
            type: zod_1.z.literal("additionalMaterialApprovalProof"),
            reference: zod_1.z.literal(undefined).optional(),
        }),
    ]);
})(FileTag || (FileTag = {}));
exports.FileTag = FileTag;
