declare global {
  interface Window {
    error: {
      publicId?: string;
      consume: () => string | undefined;
    };
  }
}

const defaultConsume = () => {
  /*  console.error("window.error.consume() was called, but no error was set"); */
  return undefined;
};

window.error = {
  consume: defaultConsume,
};

const { fetch: origFetch } = window;

namespace FetchInterceptor {
  let dateForLastHighLoadToast: Date | undefined;
  export async function func(
    ...args: Parameters<typeof origFetch>
  ): ReturnType<typeof origFetch> {
    function getCookie(cookieName: string) {
      const cookieArray = document.cookie.split(";");

      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i].trim();

        if (cookie.indexOf(cookieName + "=") === 0) {
          return cookie.substring((cookieName + "=").length);
        }
      }

      return null;
    }

    const [url, opts] = args;

    const options = opts ?? {};
    const headers = options.headers ?? {};
    const csrfToken = getCookie("csrf-token") ?? "";
    headers["x-csrf-token"] = csrfToken;

    options.headers = headers;

    const response = await origFetch(url, options);

    //const inTest = process.env.NODE_ENV === "development";
    const showHighLoadToast = response.status === 503;

    if (showHighLoadToast) {
      if (dateForLastHighLoadToast) {
        const now = new Date();
        const duration = Number(now) - Number(dateForLastHighLoadToast);
        const fiveMinutesInMs = 1000 * 60 * 5;
        if (duration < fiveMinutesInMs) {
          // Return early
          return response;
        }
      }

      dateForLastHighLoadToast = new Date();
      window.modal.toast({
        id: "high-load-toast",
        title: "Hög belastning",
        prompt:
          "Det är ovanligt hög belastning just nu och vissa saker kan därför tillfälligt sluta fungera.",
        toastType: "error",
        timeVisibleInMs: 5000,
      });
    }

    const publicId = response.headers.get("x-error-public-id");
    if (publicId) {
      console.log("publicId", publicId);
      window.error = {
        publicId,
        consume: () => {
          const result = window.error?.publicId;
          window.error = {
            consume: defaultConsume,
          };
          return result;
        },
      };
      console.log(window.error);
    }

    return response;
  }
}

export default FetchInterceptor;
