"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisFileRelation = void 0;
var zod_1 = require("zod");
var IpisFileRelation;
(function (IpisFileRelation) {
    var WorkOrderRelation = zod_1.z.object({
        type: zod_1.z.literal("workOrder"),
    });
    var CaseRelation = zod_1.z.object({
        type: zod_1.z.literal("case"),
    });
    var WorkOrderLineItemRelation = zod_1.z.object({
        type: zod_1.z.literal("workOrderLineItem"),
        lineItemId: zod_1.z.string(),
        productId: zod_1.z.string(),
        name: zod_1.z.string().nullable(),
    });
    var OpportunityLineItemRelation = zod_1.z.object({
        type: zod_1.z.literal("opportunityLineItem"),
        lineItemId: zod_1.z.string(),
        productId: zod_1.z.string(),
        name: zod_1.z.string().nullable(),
    });
    var ServiceContractRelation = zod_1.z.object({
        type: zod_1.z.literal("serviceContract"),
    });
    var ProductPackageRelation = zod_1.z.object({
        type: zod_1.z.literal("productPackage"),
    });
    IpisFileRelation.Schema = zod_1.z.discriminatedUnion("type", [
        WorkOrderRelation,
        CaseRelation,
        WorkOrderLineItemRelation,
        OpportunityLineItemRelation,
        ServiceContractRelation,
        ProductPackageRelation,
    ]);
})(IpisFileRelation || (IpisFileRelation = {}));
exports.IpisFileRelation = IpisFileRelation;
