import ClientUtils from './../../../../utils/ClientUtils';
import SingleBookingSuggestionHeader from "./SingleBookingSuggestionHeader";
import SingleBookingSuggestionMain from "./SingleBookingSuggestionMain";
import SingleBookingSuggestionFooter from "./SingleBookingSuggestionFooter";
import BookingSuggestionsContext from "../BookingSuggestionsContext";
import { useContext } from "react";
import SingleBookingSuggestionSimplifiedMain from "./SingleBookingSuggestionSimplifiedMain";
import { SlotsWithAdjacentHandymenGrouped } from "../SortedBookingSuggestions";
import SingleBookingSuggestionContext from "./SingleBookingSuggestionContext";

interface Props extends SlotsWithAdjacentHandymenGrouped {
  className?: string;
  showDate?: boolean;
}

const GroupedBookingSuggestion = (props: Props) => {
  const ctx = useContext(BookingSuggestionsContext);

  if (ctx.simplifiedView) {
    return (
      <li
        className={ClientUtils.twClassNames(
          "flex flex-col gap-2 overflow-hidden rounded border shadow",
          props.className
        )}
      >
        <SingleBookingSuggestionHeader handyman={props.handyman} />
        <SingleBookingSuggestionSimplifiedMain slots={props.slots} />
      </li>
    );
  }

  return (
    <li
      className={ClientUtils.twClassNames(
        "flex flex-col gap-2 overflow-hidden rounded border shadow",
        props.className
      )}
    >
      <SingleBookingSuggestionHeader handyman={props.handyman} />
      {props.slots.map((slot) => {
        return (
          <SingleBookingSuggestionContext.Provider
            key={slot.id}
            value={{
              slot,
              handyman: props.handyman,
              showDate: props.showDate,
            }}
          >
            <section>
              <SingleBookingSuggestionMain />
              <SingleBookingSuggestionFooter />
            </section>
          </SingleBookingSuggestionContext.Provider>
        );
      })}
    </li>
  );
};

export default GroupedBookingSuggestion;
