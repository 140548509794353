import { XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import withIconSize from "./hoc/withIconSize";
import { InternalIconProps } from "./types/SvgIconProps";

interface Props extends InternalIconProps {
  className?: string;
  variant?: "default" | "circle";
}

const AppXIcon = (props: Props) => {
  const { className, ...rest } = props;

  if (props.variant === "circle") {
    return <XCircleIcon {...rest} className={props.className} />;
  }

  return <XMarkIcon {...rest} className={props.className} />;
};
export default withIconSize(AppXIcon);
