"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryWrapper = useQueryWrapper;
var react_1 = require("react");
var react_query_1 = require("react-query");
function useQueryWrapper(options) {
    var isManuallyRefetchingRef = (0, react_1.useRef)(false);
    var queryClient = (0, react_query_1.useQueryClient)();
    var query = (0, react_query_1.useQuery)(options);
    (0, react_1.useEffect)(function () {
        isManuallyRefetchingRef.current = false;
    }, [query.isFetching]);
    function refetch(options) {
        isManuallyRefetchingRef.current = true;
        return query.refetch(options);
    }
    function cancel() {
        isManuallyRefetchingRef.current = false;
        queryClient.cancelQueries(options.queryKey);
    }
    function setData(data) {
        queryClient.setQueryData(options.queryKey, data);
    }
    /*
      Not sure why I have to check query.isFetching in
  
      isManuallyRefetching: query.isFetching && isManuallyRefetchingRef.current
  
      Checking the ref should be enough, as the ref will be set to true before refetching, and then immediately
      after rerender set to false in the useEffect since query.isFetching will change.
  
      On the next rerender - probably when the query has finished fetching - the ref should already have been changed to false
      and reflect the correct value. This doesn't work, however.
      */
    return __assign(__assign({}, query), { refetch: refetch, isManuallyRefetching: query.isFetching && isManuallyRefetchingRef.current, setData: setData, cancel: cancel });
}
