import { IpisFileV2 } from "@eljouren/file-schemas/build";
import { motion } from "framer-motion";
import ClientUtils from "./../../../../utils/ClientUtils";

import { SingleFilePermissionCallback } from "../../../../types/file-permissions";
import IpisFileListItem from "./IpisFileListItem";

interface Props {
  className?: string;
  data: IpisFileV2.Type[] | undefined;
  filePermissionCallback: SingleFilePermissionCallback;
  onDelete: (file: IpisFileV2.Type) => void;
}

const IpisFileDataList = (props: Props) => {
  if (!props.data?.length) {
    return (
      <span className={ClientUtils.twClassNames("flex p-1", props.className)}>
        Det finns inga filer att visa
      </span>
    );
  }

  /* 
    	ToDo: 
      		* Cool animations :sunglasses:
  	*/

  return (
    <motion.ul
      className={ClientUtils.twClassNames(
        "flex h-full w-full flex-col gap-2",
        props.className
      )}
      data-test-class="file-list"
    >
      {props.data.map((file) => (
        <IpisFileListItem
          onDelete={props.onDelete}
          filePermissionCallback={props.filePermissionCallback}
          key={file.guid}
          file={file}
        />
      ))}
    </motion.ul>
  );
};

export default IpisFileDataList;
