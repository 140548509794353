"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SupplierInvoice = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var SupplierInvoice;
(function (SupplierInvoice) {
    /*
      Domain
    */
    SupplierInvoice.Schema = centralized_zod_1.z.object({
        id: centralized_zod_1.z.string(),
        serialNumber: centralized_zod_1.z.string(),
        periodStart: centralized_zod_1.z.date(),
        periodEnd: centralized_zod_1.z.date(),
        documentHref: centralized_zod_1.z.string(),
    });
})(SupplierInvoice || (SupplierInvoice = {}));
exports.SupplierInvoice = SupplierInvoice;
