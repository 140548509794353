import ClientUtils from "./../../../../utils/ClientUtils";
import { AnimatePresence } from "framer-motion";
import { useContext } from "react";
import TabPanel from "../../../../components/common/tabs/TabPanel";
import AppPage from "../../../../components/layout/AppPage";
import WorkOrderControls from "../../../../components/work-order/handyman/handle-tab/WorkOrderControls";

import HandymanWorkOrderRouteContext, {
  WorkOrderControlModule,
  WorkOrderTabs,
} from "../contexts/HandymanWorkOrderRouteContext";
import HandymanWorkOrderMobileFileTab from "./HandymanWorkOrderMobileFileTab";
import HandymanWorkOrderMobileHeadings from "./HandymanWorkOrderMobileHeadings";
import HandymanWorkOrderMobileOverviewSection from "./HandymanWorkOrderMobileOverviewSection";
import AppRoutes from "../../../../routes";

const HandymanWorkOrderMobilePage = () => {
  const ctx = useContext(HandymanWorkOrderRouteContext);
  const order = ctx.order;

  let overrideLink: string;
  if (ctx.order.assignedTo?.handymanId) {
    overrideLink =
      AppRoutes.partner.root({
        userId: ctx.order.assignedTo.handymanId,
      }) + "&sida=Kalender";
  } else {
    overrideLink = AppRoutes.partner.root() + "&sida=Kalender";
  }

  return (
    <AppPage
      tabProps={{
        tabs: order.allowedToHandleOrder
          ? Object.values(WorkOrderTabs)
          : [WorkOrderTabs.overview, WorkOrderTabs.files],

        selectedTab: ctx.currentView.tab,
        onSelect: (tab: WorkOrderTabs) => {
          if (tab === WorkOrderTabs.handle) {
            ctx.navigateTo({
              tab,
              module: WorkOrderControlModule.home,
            });
          } else {
            ctx.navigateTo({
              tab,
            });
          }
        },
      }}
      headerProps={{
        hideOnKeyboardOpen: true,
        overrideLinkWhenNoHistory: overrideLink,
        loading: ctx.orderRes.query.isFetching,
        RenderHeadings: (props) => {
          return <HandymanWorkOrderMobileHeadings {...props} context={ctx} />;
        },
      }}
      className={ClientUtils.twClassNames("grid grow grid-rows-1 md:gap-4")}
    >
      <AnimatePresence exitBeforeEnter>
        <TabPanel key={ctx.currentView.tab}>
          {ctx.currentView.tab === WorkOrderTabs.overview && (
            <HandymanWorkOrderMobileOverviewSection />
          )}
          {ctx.currentView.tab === WorkOrderTabs.handle && (
            <WorkOrderControls />
          )}
          {ctx.currentView.tab === WorkOrderTabs.files && (
            <HandymanWorkOrderMobileFileTab />
          )}
        </TabPanel>
      </AnimatePresence>
    </AppPage>
  );
};

export default HandymanWorkOrderMobilePage;
