import AppCheckmarkIcon from "../../../../components/icons/AppCheckmarkIcon";
import AppXIcon from "../../../../components/icons/AppXIcon";

interface Props {
  className?: string;
  isAnswered: boolean;
  isRequired: boolean;
}

const ChecklistAnsweredIndicator = (props: Props) => {
  if (!props.isAnswered) {
    if (props.isRequired) {
      return <AppXIcon size={24} className="text-red-600" />;
    }

    return <></>;
  }

  return <AppCheckmarkIcon size={24} className="text-green-600" />;
};

export default ChecklistAnsweredIndicator;
