import { useContext } from "react";
import ClientUtils from "./../../../../utils/ClientUtils";

import Dropdown from "../../../../components/common/dropdown/Dropdown";
import ServiceContractIcon from "../../../../components/icons/composed/ServiceContractIcon";
import { HandymanWorkOrderFileContext } from "../../../../components/work-order/files/DecoupledWorkOrderFileContextProvider";
import IpisFileDataList from "../../../../components/work-order/handyman/files/IpisFileDataList";
import HandymanWorkOrderRouteContext from "../contexts/HandymanWorkOrderRouteContext";
import WorkOrderOverviewCard from "./WorkOrderOverviewCard";

interface Props {
  className?: string;
}

const OverviewServiceContractSection = (props: Props) => {
  const { order } = useContext(HandymanWorkOrderRouteContext);
  const fileCtx = useContext(HandymanWorkOrderFileContext);

  if (!order.serviceContract) {
    return <></>;
  }

  return (
    <WorkOrderOverviewCard
      className={ClientUtils.twClassNames(
        "relative grid grid-cols-[auto,minmax(0,1fr)] items-center gap-y-2 "
      )}
    >
      <span className="my-auto flex rounded-full border bg-brand-blue-100 p-2 text-brand-blue-300">
        <ServiceContractIcon className="text-brand-blue-300" size={40} />
      </span>
      <fieldset className="flex flex-col p-4">
        <span className="text-xs italic text-dark-gray text-opacity-80">
          Serviceavtal
        </span>
        <h3 aria-label="Namn på serviceavtal" className="font-semibold">
          {order.serviceContract.name}
        </h3>
      </fieldset>

      <pre
        aria-label="Beskrivning av serviceavtal"
        className="col-span-2 whitespace-pre-wrap p-2"
      >
        {order.serviceContract.description || "Ingen beskrivning angedd"}
      </pre>
      <Dropdown
        title="Visa filer kopplade till avtalet"
        className="col-span-2 p-2"
        buttonClassName="bg-transparent p-2 border-b"
      >
        <IpisFileDataList
          onDelete={fileCtx.deleteFile}
          className="p-4"
          filePermissionCallback={fileCtx.permissions.forFile}
          data={[
            ...(fileCtx.serviceContractDocuments ?? []),
            ...(fileCtx.serviceContractImages ?? []),
          ]}
        />
      </Dropdown>
    </WorkOrderOverviewCard>
  );
};

export default OverviewServiceContractSection;
