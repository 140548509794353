import ClientUtils from "../../../utils/ClientUtils";
interface Props {
  className?: string;
  withColor?: boolean;
}

const RormokareNuLogo = (props: Props) => {
  return (
    <img
      className={ClientUtils.twClassNames(
        "rounded",
        //!props.className && "h-40 max-h-full rounded",
        props.className
      )}
      src={
        props.withColor
          ? "/logos/rormokare-nu-color.svg"
          : "/logos/rormokare-nu.svg"
      }
      data-testid="ipis-logo"
      alt="Logga för Ipis"
    />
  );
};

export default RormokareNuLogo;
