import { useFormEditor } from "../../../../../../hooks/checklist-hooks";
import ChecklistImageGroupBuilder from "./ChecklistImageGroupBuilder";

interface Props {
  className?: string;
  closeForm: () => void;
}

const ChecklistImageGroupCreator = (props: Props) => {
  const ctx = useFormEditor();

  return (
    <ChecklistImageGroupBuilder
      closeForm={props.closeForm}
      onSubmit={(values) => {
        ctx.controls.addElement({ element: values });
        props.closeForm();
      }}
    />
  );
};

export default ChecklistImageGroupCreator;
