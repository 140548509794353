"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Proxied_GetSingleInput = void 0;
var zod_1 = require("zod");
var Proxied_GetSingleInput;
(function (Proxied_GetSingleInput) {
    Proxied_GetSingleInput.Schema = zod_1.z.object({
        uuid: zod_1.z.string(),
        extension: zod_1.z.string(),
    });
})(Proxied_GetSingleInput || (Proxied_GetSingleInput = {}));
exports.Proxied_GetSingleInput = Proxied_GetSingleInput;
