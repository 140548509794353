import HandymanWorkOrderMobileFileTab from "../../worker/order/mobile-specific/HandymanWorkOrderMobileFileTab";

interface Props {
  className?: string;
}

const SalesTeamWorkOrderFileTab = (props: Props) => {
  return <HandymanWorkOrderMobileFileTab />;
};

export default SalesTeamWorkOrderFileTab;
