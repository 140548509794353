"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpisSignInScreen = IpisSignInScreen;
var jsx_runtime_1 = require("react/jsx-runtime");
var zod_1 = require("@hookform/resolvers/zod");
var react_hook_form_1 = require("react-hook-form");
var zod_2 = require("zod");
var IpisButton_1 = require("../../components/buttons/IpisButton");
var IpisFormInputError_1 = require("../../components/form/form-inputs/components/IpisFormInputError");
var IpisTextInputCompact_1 = require("../../components/form/form-inputs/text-input/IpisTextInputCompact");
var general_hooks_1 = require("../../hooks/general-hooks");
var client_utils_1 = require("../../utils/client-utils");
var FormSchema = zod_2.z.object({
    email: zod_2.z.string().trim().email(),
    password: zod_2.z.string().min(1),
});
/*
    ToDo: forgot password functionality
*/
function IpisSignInScreen(props) {
    var _this = this;
    var form = (0, react_hook_form_1.useForm)({
        resolver: (0, zod_1.zodResolver)(FormSchema),
    });
    var action = (0, general_hooks_1.useLoadingAction)(function (values) { return __awaiter(_this, void 0, void 0, function () {
        var _a;
        return __generator(this, function (_b) {
            return [2 /*return*/, (_a = props.onSubmit) === null || _a === void 0 ? void 0 : _a.call(props, values)];
        });
    }); });
    var is404 = action.latestValue === 404;
    var isNon404Error = action.isError;
    return ((0, jsx_runtime_1.jsxs)("section", { className: (0, client_utils_1.classNames)("h-full w-full p-8", props.fixed && "fixed left-0 top-0"), onSubmit: form.handleSubmit(action.triggerSafely), style: {
            backgroundImage: "url(".concat(props.bgUrl, ")"),
            backgroundSize: "cover",
            // Focus the right side
            backgroundPosition: "30% 50%",
        }, children: [(0, jsx_runtime_1.jsxs)("header", { className: "py-8", children: [(0, jsx_runtime_1.jsx)("h1", { className: "text-2xl text-light-background", children: "V\u00E4lkommen till IPIS Platform" }), (0, jsx_runtime_1.jsx)("h2", { className: "text-primary-500", children: "Din installationspartner i Sverige" })] }), (0, jsx_runtime_1.jsxs)("form", { className: "flex w-full max-w-[400px] flex-col gap-4 py-4", children: [(0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: "email", form: form, name: "email", label: "E-post", type: "email", onDarkBackground: true }), (0, jsx_runtime_1.jsx)(IpisTextInputCompact_1.IpisTextInputCompact, { required: true, id: "password", form: form, name: "password", label: "L\u00F6senord", type: "password", onDarkBackground: true }), is404 && (0, jsx_runtime_1.jsx)(IpisFormInputError_1.IpisFormInputError, { error: "Fel l\u00F6senord eller e-post" }), isNon404Error && ((0, jsx_runtime_1.jsx)(IpisFormInputError_1.IpisFormInputError, { error: "N\u00E5got gick fel, f\u00F6rs\u00F6k igen" })), (0, jsx_runtime_1.jsx)("footer", { className: "flex flex-col pt-8", children: (0, jsx_runtime_1.jsx)(IpisButton_1.IpisButton, { type: "submit", label: "Logga in", loading: action.isLoading, disabled: action.isLoading }) })] })] }));
}
