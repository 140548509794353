import ClientUtils from './../../../utils/ClientUtils';
import { useRef } from "react";
import {
  FieldErrors,
  FieldValues,
  Path,
  RegisterOptions,
  UseFormRegister,
} from "react-hook-form";

import { UUID } from "@eljouren/utils";

type TSelectOption = {
  value: string | number;
  label: string;
  disabled?: boolean;
};

// ToDo: Make options typesafe
interface Props<T extends FieldValues, P extends Path<T>> {
  name: P;
  label?: string;
  errorMessage?: string;
  errors?: FieldErrors<T>;
  className?: string;
  options: TSelectOption[];

  register: UseFormRegister<T>;
  registerOptions?: RegisterOptions<T>;
  htmlAttributes?: Omit<JSX.IntrinsicElements["select"], "ref">;
}
export function AppFormSelect<T extends FieldValues, P extends Path<T>>(
  props: Props<T, P>
) {
  const id = useRef(UUID.generate().value).current;

  const registerOptions = props.registerOptions || {};

  const getError = () => {
    if (props.errorMessage) {
      return props.errorMessage;
    } else if (props.errors) {
      const errors = props.errors as any;
      if (props.name in errors) {
        return errors[props.name]?.message;
      }
    }

    return null;
  };
  const error = getError();

  return (
    <p
      className={ClientUtils.twClassNames(
        "flex w-full flex-col gap-1",
        props.className
      )}
    >
      {props.label && (
        <label htmlFor={id} className="text-sm text-dark-gray">
          {props.label}
        </label>
      )}
      <select
        className={ClientUtils.twClassNames(
          "flex w-full cursor-pointer rounded border border-form-border p-2"
        )}
        id={id}
        {...props.register(props.name, registerOptions)}
        {...props.htmlAttributes}
      >
        {props.options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </select>
      {error && <span>{error}</span>}
    </p>
  );
}
