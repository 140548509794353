"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkOrderConfirmationV2 = void 0;
var centralized_zod_1 = require("@ipis/centralized-zod");
var WorkOrderContactInfo_1 = require("./WorkOrderContactInfo");
var WorkOrderHousingType_1 = require("./WorkOrderHousingType");
var WorkOrderInvoice_1 = require("./WorkOrderInvoice");
var WorkOrderLocation_1 = require("./WorkOrderLocation");
/*
  Super verbose, but at least it's semi clear which variations are possible
  Needs testing!
*/
var WorkOrderConfirmationV2;
(function (WorkOrderConfirmationV2) {
    var AnyToUndefined = centralized_zod_1.z.any().transform(function () { return undefined; });
    var BaseFieldsSchema = centralized_zod_1.z.object({
        location: WorkOrderLocation_1.WorkOrderLocation.Schema,
        contact: WorkOrderContactInfo_1.WorkOrderContactInfo.Schema,
        gdprConfirmed: centralized_zod_1.z.literal(true),
    });
    WorkOrderConfirmationV2.UnvalidatedSchema = BaseFieldsSchema.extend({
        isBusinessCustomer: centralized_zod_1.z.boolean(),
        externalPaymentFlowAllowed: centralized_zod_1.z.boolean(),
        customerHasActivelyChosenToSkipTaxReduction: centralized_zod_1.z.boolean(),
        isInsuranceCase: centralized_zod_1.z.boolean(),
        housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema.optional(),
        internalInvoiceInformation: WorkOrderInvoice_1.WorkOrderInvoice.Schema.optional(),
    });
    WorkOrderConfirmationV2.Schema = function (factors) {
        var isBusinessCustomer = factors.isBusinessCustomer, externalPaymentFlowAllowed = factors.externalPaymentFlowAllowed, customerHasActivelyChosenToSkipTaxReduction = factors.customerHasActivelyChosenToSkipTaxReduction, isInsuranceCase = factors.isInsuranceCase;
        var schema = BaseFieldsSchema.extend({
            isBusinessCustomer: centralized_zod_1.z.literal(isBusinessCustomer),
            externalPaymentFlowAllowed: centralized_zod_1.z.literal(externalPaymentFlowAllowed),
            customerHasActivelyChosenToSkipTaxReduction: centralized_zod_1.z.literal(customerHasActivelyChosenToSkipTaxReduction),
            /*
              Not sure if a business customer can have an insurance case
            */
            isInsuranceCase: centralized_zod_1.z.literal(isInsuranceCase),
        });
        if (isBusinessCustomer) {
            /*
              A business customer can't choose to skip tax reduction
            */
            schema = schema.extend({
                customerHasActivelyChosenToSkipTaxReduction: centralized_zod_1.z.literal(false),
            });
        }
        /*
        Housing
        */
        if (isBusinessCustomer) {
            schema = schema.extend({
                housing: AnyToUndefined,
            });
        }
        else if (isInsuranceCase || customerHasActivelyChosenToSkipTaxReduction) {
            schema = schema.extend({
                housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema.optional(),
            });
        }
        else {
            schema = schema.extend({
                housing: WorkOrderHousingType_1.WorkOrderHousingType.Schema,
            });
        }
        /*
          Invoice
        */
        if (isBusinessCustomer) {
            schema = schema.extend({
                internalInvoiceInformation: WorkOrderInvoice_1.WorkOrderInvoice.Schema,
            });
        }
        else if (externalPaymentFlowAllowed) {
            schema = schema.extend({
                internalInvoiceInformation: AnyToUndefined,
            });
        }
        else {
            schema = schema.extend({
                internalInvoiceInformation: WorkOrderInvoice_1.WorkOrderInvoice.Schema,
            });
        }
        return schema;
    };
    /*  export const BusinessCustomerSchema = BaseFieldsSchema.extend({
      isBusinessCustomer: z.literal(true),
      externalPaymentFlowAllowed: z.literal(false),
      internalInvoiceInformation: WorkOrderInvoice.Schema,
      housing: AnyToUndefined,
      customerHasActivelyChosenToSkipTaxReduction: AnyToUndefined,
    });
  
    export const PrivateCustomerInternalInvoiceDiscountSchema =
      BaseFieldsSchema.extend({
        isBusinessCustomer: z.literal(false),
        externalPaymentFlowAllowed: z.literal(false),
        internalInvoiceInformation: WorkOrderInvoice.Schema,
        housing: WorkOrderHousingType.Schema,
        customerHasActivelyChosenToSkipTaxReduction: z.literal(false),
      });
  
    export const PrivateCustomerInternalInvoiceNonDiscountSchema =
      BaseFieldsSchema.extend({
        isBusinessCustomer: z.literal(false),
        externalPaymentFlowAllowed: z.literal(false),
        internalInvoiceInformation: WorkOrderInvoice.Schema,
        housing: WorkOrderHousingType.Schema.optional(),
        customerHasActivelyChosenToSkipTaxReduction: z.literal(true),
      });
  
    export const PrivateCustomerPaymentFlowDiscountSchema =
      BaseFieldsSchema.extend({
        isBusinessCustomer: z.literal(false),
        externalPaymentFlowAllowed: z.literal(true),
        internalInvoiceInformation: AnyToUndefined,
        housing: WorkOrderHousingType.Schema,
        customerHasActivelyChosenToSkipTaxReduction: z.literal(false),
      });
  
    export const PrivateCustomerPaymentFlowNonDiscountSchema =
      BaseFieldsSchema.extend({
        isBusinessCustomer: z.literal(false),
        externalPaymentFlowAllowed: z.literal(true),
        internalInvoiceInformation: AnyToUndefined,
        housing: WorkOrderHousingType.Schema.optional(),
        customerHasActivelyChosenToSkipTaxReduction: z.literal(true),
      });
  
    export const Schema = z.union([
      BusinessCustomerSchema,
      PrivateCustomerInternalInvoiceDiscountSchema,
      PrivateCustomerInternalInvoiceNonDiscountSchema,
      PrivateCustomerPaymentFlowDiscountSchema,
      PrivateCustomerPaymentFlowNonDiscountSchema,
    ]);
    export type Type = z.infer<typeof Schema>; */
})(WorkOrderConfirmationV2 || (WorkOrderConfirmationV2 = {}));
exports.WorkOrderConfirmationV2 = WorkOrderConfirmationV2;
