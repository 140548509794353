import React from "react";

interface BankIDLogoProps {
  size: number;
  white?: boolean;
}

const BankIDLogo: React.FC<BankIDLogoProps> = (props) => {
  return (
    <img
      style={{
        width: props.size,
        height: props.size,
      }}
      src={
        props.white
          ? `external-logos/bank-id/BankID_logo_white.svg`
          : `external-logos/bank-id/BankID_logo.svg`
      }
      alt="Logga för BankID"
    />
  );
};

export default BankIDLogo;
